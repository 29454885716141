import React from 'react'
import {Route} from 'react-router'

import authCheck from '../../../mixins/auth'
import MyS3BikeSelectorLayout from '../../../layouts/mys3/bike/bike-selector'

export default (
    <Route
        path='/:lang/mys3/bike-selector'
        component={MyS3BikeSelectorLayout}
        onEnter={(nextState, replaceState, callback) => authCheck(nextState, replaceState, callback)}
    />
)
