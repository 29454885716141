import PropTypes from 'prop-types'
import React from 'react'
import Reflux from 'reflux'
import CmsStore from '../../reflux/stores/cms-store'
import createReactClass from 'create-react-class'

export const cmsBlockContainer = (name, Component) => {
    const displayName = `CmsBlock(${Component.displayName || Component.name || 'Component'})`

    return createReactClass({
        displayName,

        mixins: [
            Reflux.listenTo(CmsStore, 'onCmsBlocksUpdated')
        ],

        contextTypes: {
            requireCmsBlocks: PropTypes.func
        },

        getInitialState () {
            return this._getStateFromStoreData(CmsStore.getInitialState())
        },

        onCmsBlocksUpdated (data) {
            this.setState(this._getStateFromStoreData(data))
        },

        _getStateFromStoreData (data) {
            return {
                loading: data.loading,
                cmsBlock: data.cmsBlocks && data.cmsBlocks.find(cmsBlock => cmsBlock.name === name)
            }
        },

        componentWillMount () {
            this.context.requireCmsBlocks([name])
        },

        render () {
            const {cmsBlock, loading} = this.state
            const props = {
                ...this.props,
                isCmsLoading: loading,
                content: cmsBlock && cmsBlock.content,
                title: cmsBlock && cmsBlock.title,
                cmsBlockName: cmsBlock && cmsBlock.name
            }

            return <Component {...props} />
        }
    })
}
