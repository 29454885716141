import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _ from 'lodash'
import classNames from 'classnames'
import _t from '../../translate'
import TextLabel from '../../element/text-label'

export default class SegmentProductInformation extends Component {
    static propTypes = {
        passenger: PropTypes.shape({
            first_name: PropTypes.string,
            last_name: PropTypes.string,
            passengerType: PropTypes.string.isRequired,
            id: PropTypes.string
        }).isRequired,
        product: PropTypes.shape({
            name: PropTypes.string.isRequired,
            description: PropTypes.string,
            tariff_code: PropTypes.string.isRequired,
            price: PropTypes.number.isRequired,
            cancelled: PropTypes.bool.isRequired,
            required: PropTypes.bool.isRequired,
            quantity: PropTypes.number
        })
    }

    render () {
        const productClassName = classNames(['text-label', 'product-type', {
            'not-available': this.props.product.cancelled
        }])
        const passengerClassName = classNames(['text-label', 'passenger-type', {
            'not-available': this.props.product.cancelled
        }])

        const firstName = _.get(this.props.passenger, 'first_name', '')
        const lastName = _.get(this.props.passenger, 'last_name', '')

        const passengerLabel = (firstName !== '' && lastName !== '')
            ? `${firstName} ${lastName}`
            : _t.message(`passenger-types.${this.props.passenger.passengerType.toLowerCase()}`)
        const productName = !this.props.product.required ? this.props.product.name
            : _t.message('booking-information.passenger', {
                number: _(this.props.passenger.id.split('_')).last()
            })

        return (
            <tr className='segment-product-information'>
                <td data-label={_t.getIntlMessage('booking-information.columns.product')}>
                    <span className={productClassName}>
                        <TextLabel text={_t.message('booking-information.product-quantity-name', {
                            quantity: this.props.product.quantity,
                            name: productName
                        })} />
                    </span>
                </td>
                <td data-label={_t.getIntlMessage('booking-information.columns.passenger')}>
                    <span className={passengerClassName}>
                        <TextLabel text={passengerLabel} />
                    </span>
                </td>
                <td data-label={_t.getIntlMessage('booking-information.columns.price')}>
                    &nbsp;
                </td>
            </tr>
        )
    }
}
