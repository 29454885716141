import _ from 'lodash'

export default class PaymentResultModel {
    static create (paymentResult) {
        return _.has(paymentResult, 'handled') ? new PaymentResultModel(paymentResult) : null
    }

    constructor (data) {
        if (!_.isObject(data)) {
            throw Error('Payment result should be an object')
        }
        this._data = _(data)
    }

    get redirectUrl () {
        return this._data.get('redirect_url')
    }

    get handled () {
        return this._data.get('handled')
    }

    get reference () {
        return this._data.get('reference')
    }

    get status () {
        return this._data.get('status')
    }

    get MD () {
        const data = this._data.get('redirect_post_data', [])
        const md = data.find(item => item.name === 'MD') || {}

        return md.value || ''
    }

    isSuccess () {
        return PaymentResultModel.statuses.SUCCESS === this.status
    }

    isPending () {
        return PaymentResultModel.statuses.PENDING === this.status
    }

    isFailed () {
        return PaymentResultModel.statuses.FAILED === this.status
    }
}

PaymentResultModel.statuses = {
    SUCCESS: 'success',
    FAILED: 'failed',
    PENDING: 'pending'
}
