// Libraries
import React, {Component} from 'react'
import PropTypes from 'prop-types'
import _t from '../../translate'
import {VSC_D_ENWW} from '../../data/affiliate-constants'

// Components
import FooterCommon from './footer-common'

import cmsComponent from '../../components/cms/cms-component'
import CmsBlockContent from '../../components/cms/cms-block-content'

class FooterVsc extends Component {
    static propTypes = {
        affiliateCode: PropTypes.string.isRequired
    }

    render () {
        const name = _t.getLocales() === 'en-GB' && this.props.affiliateCode === VSC_D_ENWW
            ? 'footer-vsc-ww' : 'footer-vsc'

        return (
            <FooterCommon {...this.props}>
                <CmsBlockContent name={name} />
            </FooterCommon>
        )
    }
}

export default cmsComponent('footer-vsc', 'footer-vsc-ww')(FooterVsc)
