// Libraries
import React from 'react'
import {Route} from 'react-router'

// Layouts
import BookingNotFoundLayout from '../../layouts/error/booking-not-found-layout'

export default (
    <Route path='/:lang/error/booking-not-found' component={BookingNotFoundLayout} />
)
