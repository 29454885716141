import React, {Component} from 'react'
import Translate from '../translate'
import ReactIntl from 'react-intl/src/main'

const FormattedHtmlMessageComponent = ReactIntl.FormattedHTMLMessage

export default class FormattedHTMLMessage extends Component {
    render () {
        return <FormattedHtmlMessageComponent
            {...this.props}
            locales={Translate.getLocales()}
        />
    }
}
