import PropTypes from 'prop-types'
import React, {Component} from 'react'
import {Link} from 'react-router'
import TextLabel from '../../element/text-label'
import _t from '../../translate'
import FormattedHTMLMessage from '../../translate/formatted-html-message'
import Icon from '../../element/icon'
import {cmsBlockContainer} from '../cms/cms-block-container'
import {CmsContentTextLabel, CmsTitleTextLabel} from '../cms/cms-text-label'
import BikeModal from './bike-modal'

const BikeCmsTitleTextLabel = cmsBlockContainer('additional-bike-selection', CmsTitleTextLabel)
const BikeCmsContentTextLabel = cmsBlockContainer('additional-bike-selection', CmsContentTextLabel)

class BikeSelection extends Component {
    static propTypes = {
        bikeSelectorRoute: PropTypes.string,
        price: PropTypes.number
    }

    state = {
        modalVisible: false
    }

    openModal = event => {
        event.preventDefault()
        this.setState({modalVisible: true})
    }

    closeModal = () => {
        this.setState({modalVisible: false})
    }

    render () {
        return (
            <div>
                <Link
                    className='bike-selection'
                    to={`/${_t.getLocales()}${this.props.bikeSelectorRoute}`}
                >
                    <div className='panel'>
                        <div className='bike-selection--image' />
                        <div className='bike-selection--content'>
                            <header className='grid-row'>
                                <div className='grid-column--1-8 grid-column--medium-1-12'>
                                    <Icon type='bike' className='medium align-left' />
                                </div>
                                <div className='grid-column--7-8 grid-column--medium-11-12'>
                                    <span className='text-label heading'>
                                        <BikeCmsTitleTextLabel />
                                    </span>
                                </div>
                            </header>
                            <div className='grid-row'>
                                <div className='grid-column--1-1'>
                                    <div className='text-label select-bike'>
                                        <BikeCmsContentTextLabel />
                                        <span
                                            className='text-label sub-label has-tooltip cvc-code'
                                            onClick={this.openModal}
                                        >
                                            <Icon id='cvc-tooltip-icon' className='small align-right' type='info' />
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className='grid-row'>
                                <div className='grid-column--1-1 grid-column--medium-3-4'>
                                    <div className='text-label bike-from'>
                                        <FormattedHTMLMessage
                                            message={_t.formatIntlMessage('bike-selection.from', {
                                                price: _t.formatCurrency(this.props.price)
                                            })} />
                                    </div>
                                </div>
                                <div className='grid-column--1-1 grid-column--medium-1-4'>
                                    <button className='button secondary open-bike-selector'>
                                        <TextLabel text={_t.message('bike-selection.choose')} />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Link>
                {this.state.modalVisible ? <BikeModal closeModal={this.closeModal} /> : null}
            </div>
        )
    }
}

export default BikeSelection
