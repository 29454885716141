import React from 'react'
import PropTypes from 'prop-types'
import Reflux from 'reflux'
import {withRouter} from 'react-router'
import _t from '../../translate'
import AccessManager from '../../data/access-manager'
import BookingParameterEncoder from '../../data/booking-parameter-encoder'
import BookingStore from '../../reflux/stores/booking-store'
import BookingModel from '../../models/booking-model'
import createReactClass from 'create-react-class'

export default Component => withRouter(createReactClass({

    displayName: 'BookingActions',

    propTypes: {
        router: PropTypes.object
    },

    mixins: [
        Reflux.connectFilter(BookingStore, 'booking', data => data.booking)
    ],

    onViewBooking () {
        this.props.router.push(this._getBookingUrl())
    },

    onInitiateAfterSales (aftersalesOperation) {
        const url = this._getBookingUrl()
        const glue = url.indexOf('?') >= 0 ? '&' : '?'

        this.props.router.push(`${url}${glue}aftersales=${aftersalesOperation}`)
    },

    _getBookingUrl () {
        const booking = BookingModel.create(this.state.booking)

        let url = `/${_t.getLocales()}`
        if (AccessManager.isCrmUser()) {
            url += `/mys3/booking/${booking.bookingNumber}`
        } else {
            url += `/booking-login?code=${BookingParameterEncoder.encode(booking)}`
        }

        return url
    },

    render () {
        return <Component
            {...this.props}
            onViewBooking={this.onViewBooking}
            onInitiateAfterSales={this.onInitiateAfterSales}
        />
    }
}))
