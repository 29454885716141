import React, {Component} from 'react'
import _t from '../../translate'
import CmsBlockContent from '../../components/cms/cms-block-content'
import HelpButton from '../../base/help-button'
import cmsComponent from '../../components/cms/cms-component'

class Help extends Component {
    render () {
        return _t.getLocales() === 'fr-FR' ? (
            <HelpButton />
        ) : (
            <div className='my-s3-after-sales-help'>
                <div className='panel'>
                    <header>
                        <h3>{_t.getIntlMessage('my-s3-booking.help.title')}</h3>
                    </header>
                    <div className='content'>
                        <CmsBlockContent name='my-s3-booking-help-content' />
                    </div>
                </div>
            </div>
        )
    }
}
export default cmsComponent('my-s3-booking-help-content')(Help)
