// Libraries
import React, {Component} from 'react'
import PropTypes from 'prop-types'
import _t from '../translate'

// Components
import ModalDialog from './modal-dialog'
import Button from './button'
import Icon from './icon'
import TextLabel from './text-label'

export default class ConfirmDialog extends Component {
    static propTypes = {
        onClose: PropTypes.func.isRequired,
        onCancelButtonClick: PropTypes.func.isRequired,
        onConfirmButtonClick: PropTypes.func.isRequired,
        labelPrefix: PropTypes.string.isRequired
    }

    render () {
        return (
            <ModalDialog
                headerTitle={_t.getIntlMessage(this.props.labelPrefix + '.title')}
                onClose={this.props.onClose}
            >
                <div className='content'>
                    {this.props.children ? this.props.children : (
                        <span className='text-label information'>
                            <Icon type='information' className='small align-left' />
                            <TextLabel text={_t.getIntlMessage(this.props.labelPrefix + '.information')} />
                        </span>
                    )}
                    <div className='actions'>
                        <ul className='horizontal-list'>
                            <li>
                                <Button
                                    className='button default'
                                    onClick={this.props.onCancelButtonClick}
                                    type='button'
                                >
                                    <Icon type='cross' className='small align-left' />
                                    <TextLabel text={_t.getIntlMessage(this.props.labelPrefix + '.cancel-button')} />
                                </Button>
                            </li>
                            <li>
                                <Button
                                    className='button primary'
                                    onClick={this.props.onConfirmButtonClick}
                                    type='button'
                                >
                                    <TextLabel text={_t.getIntlMessage(this.props.labelPrefix + '.confirm-button')} />
                                    <Icon type='chevron-right' className='small align-right' />
                                </Button>
                            </li>
                        </ul>
                    </div>
                </div>
            </ModalDialog>
        )
    }
}
