import React from 'react'
import Reflux from 'reflux'
import CmsStore from '../../reflux/stores/cms-store'
import Loader from '../../element/loader'
import createReactClass from 'create-react-class'

export default createReactClass({
    displayName: 'CmsLoader',

    mixins: [
        Reflux.connectFilter(CmsStore, 'loading', data => data.loading)
    ],

    render () {
        return this.state.loading ? <Loader /> : null
    }
})
