import PropTypes from 'prop-types'
import React, {Component} from 'react'
import _t from '../translate'
import classNames from 'classnames'
import Button from './button'
import Popover from './tether-popover'
import TextLabel from './text-label'
import Icon from './icon'

class Tooltip extends Component {
    static propTypes = {
        title: PropTypes.string,
        position: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
        visible: PropTypes.bool,
        showButtons: PropTypes.bool,
        onClose: PropTypes.func,
        onOk: PropTypes.func,
        target: PropTypes.string,
        className: PropTypes.string
    }

    static defaultProps = {
        title: null,
        position: 'bottom',
        visible: false,
        showButtons: false,
        onClose: function () {
        },
        onOk: function () {
        }
    }

    tooltipProps () {
        switch (this.props.position) {
            case 'top':
                return {
                    attachment: 'bottom center',
                    targetAttachment: 'top center',
                    targetOffset: '-10px 0'
                }
            case 'left':
                return {
                    attachment: 'middle right',
                    targetAttachment: 'middle left',
                    targetOffset: '0 -10px'
                }
            case 'right':
                return {
                    attachment: 'middle left',
                    targetAttachment: 'middle right',
                    targetOffset: '0 10px'
                }
            default:
                return {
                    attachment: 'top center',
                    targetAttachment: 'bottom center',
                    targetOffset: '10px 0'
                }
        }
    }

    render () {
        if (!this.props.visible) {
            return null
        }

        return (
            <Popover target={this.props.target} {...this.tooltipProps()}>
                {this.renderTooltip()}
            </Popover>
        )
    }

    renderTooltip () {
        const className = classNames('tooltip', this.props.className)
        return (
            <div className={className}>
                {this.renderTitle()}
                <div className='content'>
                    <span className='text-label tooltip-content'>
                        {this.props.children}
                    </span>
                    {this.renderButtons()}
                </div>
                {this.renderArrow()}
            </div>
        )
    }

    renderButtons () {
        if (!this.props.showButtons) {
            return null
        }

        return (
            <div className='button-group'>
                <ul>
                    <li>
                        <Button className='button default'
                            onClick={this.props.onClose}>
                            <TextLabel text={_t.getIntlMessage('tooltip.button.close')} />
                        </Button>
                    </li>
                    <li>
                        <Button className='button primary'
                            onClick={this.props.onOk}>
                            <TextLabel text={_t.getIntlMessage('tooltip.button.ok')} />
                        </Button>
                    </li>
                </ul>
            </div>
        )
    }

    renderTitle () {
        return (
            <header>
                <span className='text-label tooltip-title'>
                    {this.props.title ? <TextLabel text={this.props.title} /> : <span className='text'>&nbsp;</span>}
                </span>
                <Button className='button tooltip-close' onClick={this.props.onClose}>
                    <Icon type='cross' className='small align-left' />
                    <TextLabel text={_t.getIntlMessage('tooltip.button.close')} />
                </Button>
            </header>
        )
    }

    renderArrow () {
        switch (this.props.position) {
            case 'top':
                return (
                    <div className='arrow down' />
                )
            case 'left':
                return (
                    <div className='arrow right' />
                )
            case 'right':
                return (
                    <div className='arrow left' />
                )
            default:
                return (
                    <div className='arrow up' />
                )
        }
    }
}

class HelpTooltip extends Component {
    constructor (...args) {
        super(...args)
        this.state = {
            tooltipVisible: false
        }
        this.toggleVisibility = this.toggleVisibility.bind(this)
    }

    toggleVisibility (event) {
        event.preventDefault()
        this.setState({tooltipVisible: !this.state.tooltipVisible})
        return false
    }

    render () {
        const target = `${this.props.name}-icon`

        return (
            <react-wrapper class='react-wrapper'>
                <span className={`text-label ${this.props.name}`}>
                    <TextLabel text={this.props.text} />
                    <span onClick={this.toggleVisibility} className='is-clickable'>
                        <Icon type='help' className='small align-right' id={target} />
                    </span>
                </span>
                <Tooltip
                    {...(this.props.tooltipProps || {})}
                    title={this.props.title}
                    onClose={this.toggleVisibility}
                    target={target}
                    visible={this.state.tooltipVisible}
                >
                    {this.props.children}
                </Tooltip>
            </react-wrapper>
        )
    }
}

HelpTooltip.propTypes = {
    name: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    tooltipProps: PropTypes.object
}

Tooltip.Help = HelpTooltip

export default Tooltip
