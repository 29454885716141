import PropTypes from 'prop-types'
import React from 'react'
import Reflux from 'reflux'
import _t from '../../translate'
import device from '../../device'
import TextLabel from '../../element/text-label'
import BookingModel from '../../models/booking-model'
import SeatSelection from '../seat-selector/seat-selection'
import OfferStore from '../../reflux/stores/offer-store'
import SeatSummary from '../seat-summary/seat-summary'
import TaggingStore from '../../reflux/stores/tagging-store'
import scroller from '../../misc/scroller'
import {
    seatSelectionProductSelector
} from '../../models/selectors/components/booking/seat-selection'
import {getState} from '../../reflux/bridge/connect-state'
import BookingStore from '../../reflux/stores/booking-store'
import Icon from '../../element/icon'
import createReactClass from 'create-react-class'

export default createReactClass({
    displayName: 'SeatOptions',

    mixins: [
        Reflux.listenTo(OfferStore, 'updateOffer'),
        Reflux.connectFilter(BookingStore, 'booking', data => BookingModel.create(data.booking))
    ],

    propTypes: {
        rebookFlow: PropTypes.any,
        seatSelectorRoute: PropTypes.any,
        availableSections: PropTypes.array
    },

    getDefaultProps () {
        return {
            availableSections: []
        }
    },

    componentWillMount () {
        this.updateOffer()

        this.state.booking.hasManuallySelectedSeats
            ? TaggingStore.onShowSeatSelector('yes')
            : TaggingStore.onShowSeatSelector('no')
    },

    componentDidMount () {
        if (device.isMobile() && this.state.booking.hasManuallySelectedSeats) {
            scroller('seat-selection', {smooth: true})
        }
    },

    updateOffer () {
        const product = seatSelectionProductSelector(getState())
        if (product) {
            this.setState({price: product.passengerFares[0].price})
        }
    },

    renderSeatSummary () {
        return (
            <div className='panel' id='seat-selection'>
                <header>
                    <Icon type='seat-selector' className='medium align-left' />
                    <span className='text-label heading'>
                        <TextLabel text={_t.message('seat-summary.header')} />
                    </span>
                </header>
                <p>
                    <TextLabel text={_t.formatIntlMessage('seat-summary.sub-header')} />
                </p>
                <SeatSummary
                    seatSelectorRoute={this.props.seatSelectorRoute}
                    booking={this.state.booking}
                    passengers={this.state.booking.passengers.values().value()}
                    availableSections={this.props.availableSections}
                    rebookFlow={this.props.rebookFlow}
                    viewVertical={device.isMobile()}
                />
            </div>
        )
    },

    render () {
        return (
            <div className='row'>
                <div className='seat-options'>
                    {this.state.booking.hasProvisionalManuallySelectedSeats
                        ? this.renderSeatSummary()
                        : this.props.availableSections.length
                            ? <SeatSelection
                                seatSelectorRoute={this.props.seatSelectorRoute}
                                price={this.state.price}
                            />
                            : null
                    }
                </div>
            </div>
        )
    }
})
