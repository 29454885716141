import PropTypes from 'prop-types'
import React, { Component } from 'react'
import cmsContainer from './cms-container'
import Loader from '../../element/loader'
import CmsContent from './cms-content'

class CmsBlockContent extends Component {
    static propTypes = {
        content: PropTypes.any,
        showLoader: PropTypes.bool,
        loading: PropTypes.bool,
        className: PropTypes.any
    }

    static defaultProps = {
        showLoader: true
    }

    render () {
        if (!this.props.content && this.props.loading && this.props.showLoader) {
            return <Loader />
        }

        return this.props.content ? (
            <CmsContent className={this.props.className} content={this.props.content}>
                {this.props.children}
            </CmsContent>
        ) : null
    }
}

export default cmsContainer(CmsBlockContent)
