/* globals S3P_SETTINGS: true */
import moment from 'moment'
import actions from '../../reflux/actions'
import {dateStringToLocalizedDate} from '../../misc/date'
import {
    bundlesSelector,
    routesSelector
} from '../../models/selectors/api/v2/orientation/journey-search'
import {getState} from '../../reflux/bridge/connect-state'
import {createBookingRequestPayload} from '../../models/selectors/components/orientation/booking'
import {selectedBundlesSelector} from '../../models/selectors/components/orientation/journey-search'
import CalendarStore from '../../reflux/stores/calendar-store'
import oauth from '../../oauth'
import get from 'lodash/get'
import pick from 'lodash/pick'
import isEmpty from 'lodash/isEmpty'
import AccessManager from '../../data/access-manager'
import PassengersDetailsStore from '../../reflux/stores/passenger-details-store'
import {mapPassenger} from '../../misc/helpers'
import {passengersSelector} from '../../models/selectors/api/v2/orientation/passenger'
import {camelCaseKeys} from '../../misc/camelcase'
import {SALES_CHANNEL_LABELPARTNER} from '../../data/sales-channel-constants'

const isValidJourneySearch = ({origin, destination, outboundDate, passengers}) =>
    origin && destination && outboundDate && (passengers || []).length > 0

const findRouteByServiceNames = (direction, routes, serviceNames, prefOrigin, prefDestination, transferStations = []) => {
    let availableRoutes = routes.filter(route => (
        route.direction === direction &&
        route.legs &&
        route.legs.length === serviceNames.length &&
        serviceNames.every(
            serviceName => (
                route.legs.some(
                    (leg, index) => (
                        leg.serviceName === serviceName &&
                        (!(index in transferStations) || leg.arrivalStation.UICStationCode === transferStations[index])
                    ))
            )
        )
    ))

    if (availableRoutes.length === 0) {
        return null
    }

    if (availableRoutes.length === 1) {
        return availableRoutes[0]
    }

    if (transferStations.length === 0) {
        const routesWithoutTransfers = availableRoutes.filter(route => route.legs.length === 1)
        if (routesWithoutTransfers.length) {
            availableRoutes = routesWithoutTransfers
        }
    }

    if (prefOrigin && prefDestination) {
        const preferredRoute = availableRoutes.find(
            route => (
                route.departureStation.UICStationCode === prefOrigin &&
                route.arrivalStation.UICStationCode === prefDestination
            )
        )
        if (preferredRoute) {
            return preferredRoute
        }
    }

    return availableRoutes[0]
}

const findAdditionalItemsByBundle = (bundle, products) => bundle.items
    .filter(item => !item.required && products.includes(item.productCode))

const createAdditionalItemsByBundle = (bundle, products) => {
    const additionalItems = findAdditionalItemsByBundle(bundle, products)
    // book for every passenger their item
    return additionalItems.reduce((items, item) => {
        items.push(...item.passengerFares.map(fare => ({
            passengerId: fare.passengerId,
            quantity: 1,
            itemId: item.id
        })))
        return items
    }, [])
}

const findBundleByProducts = (routeBundles, bundles, products) => routeBundles.find(basicBundle => {
    const bundle = bundles.get(basicBundle.id)
    return products.some(productCode => bundle.items.some(item => item.productCode === productCode))
})

const isValidBundleSelection = (state, {inboundDate, outboundDate}) => {
    const bundles = selectedBundlesSelector(state)
    return (inboundDate && outboundDate && bundles.length === 2) || // both inbound & outbound, then two bundles
        (outboundDate && !inboundDate && bundles.length === 1) // only outbound and no inbound, then 1 bundle
}

export const processDeepLink = async data => {
    actions.clearOffer()

    const {
        origin,
        destination,
        outboundDate,
        outboundRoute,
        outboundProducts,
        outboundTransferStation,
        inboundDate,
        inboundRoute,
        inboundProducts,
        inboundTransferStation,
        passengers,
        salesChannel,
        currency,
        preferredOriginOutbound,
        preferredDestinationOutbound,
        preferredOriginInbound,
        preferredDestinationInbound,
        createProvisionalBooking,
        isForOfferPage,
        userUuid
    } = data

    if (!isValidJourneySearch(data)) {
        throw new Error('Invalid journey search provides')
    }

    if (!AccessManager.isCrmUser()) {
        const publicChannel = get(S3P_SETTINGS.s3Passenger.features.deepLink, 'publicChannel')
        await oauth.publicAccess(salesChannel || publicChannel)
    }

    // 1. getOffer -> origin, destination, inboundDate, outboundDate, passengers

    const journeySelection = {
        outboundDate: dateStringToLocalizedDate(outboundDate),
        inboundDate: inboundDate && dateStringToLocalizedDate(inboundDate),
        passengers,
        origin,
        destination,
        currency
    }

    const calls = [actions.getOffer(journeySelection)]

    if (isForOfferPage) { // reduce calls if not on booking page
        calls.push(actions.getCalendar(CalendarStore.createDateSwitcherRequestData(journeySelection)))
    }

    await Promise.all(calls)

    // 2. Find bundles by offer
    let state = getState()
    const routes = routesSelector(state)
    const bundles = bundlesSelector(state)

    let selectedOutboundRoute
    let selectedInboundRoute
    if (outboundRoute && outboundRoute.length > 0) {
        const routesArray = Array.from(routes.values())
        selectedOutboundRoute = findRouteByServiceNames(
            'outbound',
            routesArray,
            outboundRoute,
            preferredOriginOutbound,
            preferredDestinationOutbound,
            outboundTransferStation
        )
        if (inboundRoute && inboundRoute.length > 0) {
            selectedInboundRoute = findRouteByServiceNames(
                'inbound',
                routesArray,
                inboundRoute,
                preferredOriginInbound,
                preferredDestinationInbound,
                inboundTransferStation
            )
        }
    }

    // When there is inboundDate & createProvBooking then more validation

    // 3. Select Bundles by offer
    // 4. Additional products? -> Also add
    if (selectedOutboundRoute && outboundProducts && outboundProducts.length) {
        const selectedOutboundBundle = findBundleByProducts(selectedOutboundRoute.bundles, bundles, outboundProducts)
        const selectedInboundBundle = selectedInboundRoute &&
            findBundleByProducts(selectedInboundRoute.bundles, bundles, inboundProducts)

        if (selectedOutboundBundle) {
            actions.selectBundle(
                selectedOutboundBundle,
                createAdditionalItemsByBundle(selectedOutboundBundle, outboundProducts)
            )
        }

        if (selectedInboundBundle) {
            actions.selectBundle(
                selectedInboundBundle,
                createAdditionalItemsByBundle(selectedInboundBundle, inboundProducts)
            )
        }
    }

    state = getState()

    // 5. Create booking by bundles
    if (createProvisionalBooking && isValidBundleSelection(state, data)) {
        let createBookingPayload = createBookingRequestPayload(state)
        if (userUuid !== undefined && salesChannel === SALES_CHANNEL_LABELPARTNER) {
            createBookingPayload.additional_details.push({
                key: 'userUuid',
                value: userUuid
            })
        }

        await actions.createBooking({
            ...createBookingPayload,
            sales_channel: salesChannel
        })
    } else if (createProvisionalBooking) {
        throw new Error('Tried to create a booking without bundles')
    }

    // Prefill passenger form
    if (createProvisionalBooking) {
        const journeySearchPassengers = [...passengersSelector(state)]
        const prefillPassengersData = passengers.reduce((_passengers, p) => {
            // White list allowed params
            const passenger = pick(camelCaseKeys(p), ['firstName', 'lastName', 'dateOfBirth', 'type', 'discountCards'])
            if (!isEmpty(passenger) && passenger.type) {
                // Map Passenger id to deep link passenger
                const deepLinkPassengerIndex = journeySearchPassengers.findIndex(_passenger => passenger.type === _passenger.type)
                if (deepLinkPassengerIndex !== -1) {
                    const [journeySearchPassenger] = journeySearchPassengers.splice(deepLinkPassengerIndex, 1)
                    const dateOfBirth = moment(passenger.dateOfBirth, 'YYYY-MM-DD')
                    _passengers.push({
                        id: journeySearchPassenger.id,
                        ...mapPassenger(passenger),
                        dateOfBirth: (dateOfBirth.isValid() && dateOfBirth.format('DD/MM/YYYY')) || ''
                    })
                }
            }
            return _passengers
        }, [])
        prefillPassengersData.length && PassengersDetailsStore.prefillPassengers(prefillPassengersData)
    }

    // 6. Redirect to target
    return data
}
