import React, {Component} from 'react'
import PropTypes from 'prop-types'
import _t from '../../translate'

import Radio from '../../element/form/radio-field'

export default class JourneyOptions extends Component {
    static propTypes = {
        hasInboundDate: PropTypes.bool.isRequired,
        onChange: PropTypes.func.isRequired
    }

    render () {
        return (
            <div className='journey-options'>
                <Radio
                    id='return' name='hasInboundDate' onChange={this.props.onChange}
                    value='1' checked={this.props.hasInboundDate}
                >
                    <label htmlFor='return' className='text-label'>
                        {_t.getIntlMessage('journey-search.journey-options.return')}
                    </label>
                </Radio>
                <Radio
                    id='oneway' name='hasInboundDate' onChange={this.props.onChange}
                    value='0' checked={!this.props.hasInboundDate}
                >
                    <label htmlFor='oneway' className='text-label'>
                        {_t.getIntlMessage('journey-search.journey-options.oneway')}
                    </label>
                </Radio>
            </div>
        )
    }
}
