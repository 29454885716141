import React, {Component} from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import _t from '../../translate'
import BookingModel from '../../models/booking-model'
import TextLabel from '../../element/text-label'
import Icon from '../../element/icon'
import TriStateCheckboxField from '../../element/form/tri-state-checkbox-field'
import {CANCEL_OPTION_JOURNEY} from '../../constants'

export default class CancelPerJourney extends Component {
    static propTypes = {
        onAction: PropTypes.func.isRequired,
        cancelItemIds: PropTypes.arrayOf(PropTypes.number).isRequired,
        booking: PropTypes.instanceOf(BookingModel),
        disabled: PropTypes.bool,
        onSelectCancellationOption: PropTypes.func.isRequired,
        selectedCancellationOption: PropTypes.string
    }

    static defaultProps = {
        disabled: false
    }

    constructor (...args) {
        super(...args)
        this._getCheckboxState = this._getCheckboxState.bind(this)
        this._getCancelableProducts = this._getCancelableProducts.bind(this)
        this.onChange = this.onChange.bind(this)
        this._renderFormRow = this._renderFormRow.bind(this)
    }

    _getCheckboxState (direction) {
        const products = this._getCancelableProducts(direction)
        if (products.size() > 0 &&
            !products.find(product => _.indexOf(this.props.cancelItemIds, product.item_ref) < 0)
        ) {
            return 1
        }

        return -1
    }

    _getCancelableProducts (direction) {
        const allProducts = direction === 'outbound'
            ? this.props.booking.outboundProducts : this.props.booking.inboundProducts

        return allProducts.filter('can_be_cancelled')
    }

    onChange () {
        if (this.props.selectedCancellationOption !== CANCEL_OPTION_JOURNEY) {
            this.props.onSelectCancellationOption(CANCEL_OPTION_JOURNEY)
        }
    }

    render () {
        const selected = this.props.selectedCancellationOption === CANCEL_OPTION_JOURNEY

        return this.props.booking.isReturnTrip() &&
        !this.props.booking.isOutboundCancelled() && !this.props.booking.isInboundCancelled()
            ? (
                <div className='callout cancel-journey'>
                    <span className='text-label heading'>
                        <input
                            type='radio'
                            checked={selected}
                            onChange={this.onChange}
                            disabled={this.props.disabled}
                        />
                        <TextLabel text={_t.getIntlMessage('my-s3-cancellation.per-journey.header')} />
                    </span>
                    {selected ? (
                        <div className='grid-row'>
                            <div className='grid-column--1-1 grid-column--medium-1-2'>
                                {this._renderFormRow('outbound')}
                            </div>
                            <div className='grid-column--1-1 grid-column--medium-1-2'>
                                {this._renderFormRow('inbound')}
                            </div>
                        </div>
                    ) : null}
                </div>
            ) : null
    }

    _renderFormRow (direction) {
        const id = `cancel-${direction}`
        const iconType = 'half-arrow-' + (direction === 'outbound' ? 'right' : 'left')

        let travelDate, label
        if (direction === 'outbound') {
            label = _t.formatIntlMessage('my-s3-cancellation.per-journey.outbound', {
                origin: _.get(this.props.booking.outboundOrigin, 'name'),
                destination: _.get(this.props.booking.outboundDestination, 'name')
            })
            travelDate = this.props.booking.outboundTravelDate
        } else {
            label = _t.formatIntlMessage('my-s3-cancellation.per-journey.inbound', {
                origin: _.get(this.props.booking.inboundOrigin, 'name'),
                destination: _.get(this.props.booking.inboundDestination, 'name')
            })
            travelDate = this.props.booking.inboundTravelDate
        }

        return (
            <div className='form-row'>
                <span className='text-label title-label'>
                    <Icon type={iconType} className='medium align-left' />
                    <span className='text'>
                        {_t.getIntlMessage(`my-s3-cancellation.per-journey.${direction}-header`)}
                    </span>
                </span>
                <div className='check-radio-wrapper'>
                    <TriStateCheckboxField
                        id={id}
                        state={this._getCheckboxState(direction)}
                        disabled={this.props.disabled || this._getCancelableProducts(direction).size() === 0}
                        onChange={e => this.props.onAction(direction, e.target.checked)} />
                    <label htmlFor={id} className='text-label cancel-journey'>
                        {label}
                        <span className='text-label outbound-date'>
                            <TextLabel text={_t.formatDate(travelDate, 'short')} />
                        </span>
                    </label>
                </div>
            </div>
        )
    }
}
