import React from 'react'
import {Route} from 'react-router'
import authCheck from '../../../mixins/auth'
import MyS3SeatViewSeatLayout from '../../../layouts/mys3/seat/seat-view-seat-layout'

export default (
    <Route
        path='/:lang/mys3/view-seats/:id'
        component={MyS3SeatViewSeatLayout}
        onEnter={(nextState, replaceState, callback) => authCheck(nextState, replaceState, callback)}
    />
)
