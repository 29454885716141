import React from 'react'
import PropTypes from 'prop-types'
import Reflux from 'reflux'
import device from '../../../device'
import {withRouter} from 'react-router'
import _t from '../../../translate'
import RebookingLayoutContainer from '../../re-booking-layout-container'
import PaymentContainer from '../../../components/booking-payment/payment-container'
import ProgressNavigation from '../../../components/booking-payment/progress-navigation'
import BookingStore from '../../../reflux/stores/booking-store'
import actions from '../../../reflux/actions'
import Help from '../../../components/my-s3-booking/help'
import restoreBooking from '../../../components/restore/restore-booking'
import createReactClass from 'create-react-class'

export default withRouter(restoreBooking(createReactClass({

    displayName: 'ReBookingPaymentLayout',

    mixins: [
        Reflux.connectFilter(BookingStore, 'bookingNumber', data => data.booking.booking_number)
    ],

    propTypes: {
        router: PropTypes.object
    },

    componentDidMount () {
        actions.setPaymentReturnUrl(`/${_t.getLocales()}/mys3/re-booking/confirm`)
    },

    onPrev () {
        const url = `/${_t.getLocales()}/mys3/booking/${this.state.bookingNumber}`
        actions.revertBooking(this.state.bookingNumber).then(() => this.props.router.push(url))
    },

    onConfirm () {
        actions.confirmBooking(this.state.bookingNumber)
    },

    onConfirmed () {
        this.props.router.push(`/${_t.getLocales()}/mys3/re-booking/confirm?s3_status=success`)
    },

    render () {
        return (
            <RebookingLayoutContainer name='re-booking-payment'>
                <PaymentContainer />
                <ProgressNavigation onPrev={this.onPrev} onConfirm={this.onConfirm} onConfirmed={this.onConfirmed} />
                {device.isMobile() ? <Help /> : null}
            </RebookingLayoutContainer>
        )
    }

}), {withBlockingLoader: true}))
