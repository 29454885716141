import React, {Component} from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import device from '../../device'
import NewSearch from '../new-search'
import HelpButton from '../../base/help-button'

export default class Panel extends Component {
    static propTypes = {
        afterSales: PropTypes.bool,
        collapsable: PropTypes.bool
    }

    static defaultProps = {
        afterSales: false,
        collapsable: false
    }

    render () {
        const className = classNames('booking-specification', {
            collapsable: this.props.collapsable
        })

        return (
            <div className='booking-specification-container'>
                <div className={className}>
                    {this.props.children}
                </div>
                {!this.props.afterSales ? <NewSearch /> : null}
                {!this.props.afterSales && !device.isMobile() ? <HelpButton /> : null}
            </div>
        )
    }
}
