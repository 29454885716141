import StationStore from '../stores/stations-store'
import {camelCaseKeys} from '../../misc/camelcase'
import memoize from 'lodash/memoize'

const mapStations = memoize(stations => stations ? stations.map(station => ({
    ...camelCaseKeys(station),
    UICStationCode: station._u_i_c_station_code
})) : [])

export const stationsSelector = () => {
    const stations = StationStore.getStations()
    return mapStations(stations)
}
