import PropTypes from 'prop-types'
import React, {Component} from 'react'
import kebabCase from 'lodash/kebabCase'
import classNames from 'classnames'
import _t from '../../../../translate'
import device from '../../../../device'
import TextLabel from '../../../../element/text-label'
import Icon from '../../../../element/icon'
import Availability from './availability'
import CmsBlock from '../../../cms/cms-block-content'

export default class JourneyResultSetListOptionBundleSummary extends Component {
    static propTypes = {
        bundle: PropTypes.shape({
            price: PropTypes.number.isRequired,
            isPromo: PropTypes.bool.isRequired,
            availability: PropTypes.number
        }).isRequired,
        selected: PropTypes.bool.isRequired,
        isBestRoutePrice: PropTypes.bool.isRequired,
        isBestTravelPrice: PropTypes.bool.isRequired,
        showBestPrice: PropTypes.bool.isRequired,
        isSingleTariff: PropTypes.bool.isRequired,
        hasAgglomerationStation: PropTypes.bool.isRequired,
        isFlex: PropTypes.bool.isRequired,
        isNelproduct: PropTypes.bool,
        isAlsaproduct: PropTypes.bool
    }

    render () {
        const {
            isBestTravelPrice,
            selected,
            bundle,
            showBestPrice,
            isSingleTariff,
            hasAgglomerationStation
        } = this.props

        const classes = classNames({
            'journey-result-option-item': true,
            'bundle-summary': true,
            'best-price': isBestTravelPrice,
            'bundle-active': selected,
            'single-tariff': isSingleTariff,
            'agglomeration-station': hasAgglomerationStation
        })

        return (
            <div className={classes}>
                {!device.isDesktopOrTablet() && this.props.selected ? (
                    <div className='bundle-selected-tick'>
                        <Icon className='medium' type='tick' />
                    </div>
                ) : null}
                <div className='bundle-price-placeholder'>
                    {!bundle.hasMainProductBucketSeperation ? <Availability availability={bundle.availability} /> : null}
                    <div className='bundle-select-price'>
                        <span className='text-label bundle-summary-description dimmed text-transform-uppercase'>
                            {isBestTravelPrice && showBestPrice ? (
                                <TextLabel text={_t.message('journey-result-set.best-price')} />
                            ) : (
                                <TextLabel
                                    text={_t.message(
                                        `journey-result-set.product-type.${bundle.isPromo ? 'promo' : 'standard'}`
                                    )}
                                />
                            )}
                        </span>
                        <label className='text-label bundle-summary-price'>
                            <TextLabel text={_t.formatCurrency(bundle.price)} />
                        </label>
                        <input
                            id={kebabCase(`radio-${bundle.id}`)}
                            type='radio'
                            checked={selected}
                            onChange={() => {}}
                        />
                    </div>
                    {this.props.isNelproduct || this.props.isAlsaproduct
                        ? (
                            <CmsBlock
                                name={this.props.isAlsaproduct ? 'PRODUCT-STATIC' : 'PRODUCT-STATIC_2'}
                                showLoader={false}
                            />
                        ) : (
                            <CmsBlock
                                name={this.props.isFlex ? 'BOOKING-SS-FLEX-MARKET' : 'BOOKING-SS-UNFLEX-MARKET'}
                                showLoader={false}
                            />
                        )
                    }
                </div>
            </div>
        )
    }
}
