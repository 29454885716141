import Reflux from 'reflux'
import UserAwareMixin from '../../reflux/mixin/user-aware-mixin'
import actions from '../../reflux/actions'
import ResponseCodes from '../../api/response-codes'

export default Reflux.createStore({

    listenables: actions,

    mixins: [UserAwareMixin],

    init () {
        this._resetData()

        this.listenTo(actions.initiatePayment.completed, this.resetData)
        this.listenTo(actions.processPayment.completed, this.resetData)
        this.listenTo(actions.loadUserLoginInformation.completed, this.resetData)
    },

    _resetData () {
        this.data = {
            loading: false,
            success: true,
            error: '',
            bookings: [],
            messages: []
        }
    },

    resetData () {
        this._resetData()
        this.trigger(this.data)
    },

    getInitialState () {
        return this.data
    },

    onMyS3GetBookings () {
        this.data.loading = true
        this.data.success = true
        this.data.error = ''
        this.data.bookings = []
        this.trigger(this.data)
    },

    onMyS3GetBookingsCompleted (bookings) {
        this.data.loading = false
        this.data.bookings = bookings
        this.trigger(this.data)
    },

    onMyS3GetBookingsFailed (error) {
        this.data.loading = false
        this.data.success = false
        this.data.error = error
        this.trigger(this.data)
    },

    onApiError (error) {
        if ([ResponseCodes.BOOKING_CUSTOMER_SEARCH_NOT_SPECIFIC_ENOUGH, ResponseCodes.BOOKING_PASSENGER_SEARCH_NOT_SPECIFIC_ENOUGH].includes(error.code)) {
            this.data.messages = [{
                code: error.code,
                content: ResponseCodes.translate(error.code),
                severity: 'warning'
            }]
            this.data.error = true
            this.data.loading = false
            error.handled = true
            this.trigger(this.data)
        }
    }

})
