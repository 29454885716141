import React, {Component} from 'react'
import PropTypes from 'prop-types'
import device from '../device'
import GenericLayout from '../layouts/generic-layout'
import Row from '../element/row'
import PaymentContainer from '../components/booking-payment/payment-container'
import ProgressNavigation from '../components/booking-payment/progress-navigation'
import BookingSpecificationPanel from '../components/booking-specification/booking-panel'
import Help from '../components/my-s3-booking/help'
import AfterSalesNavigation from '../base/after-sales-navigation'
import restoreBooking from '../components/restore/restore-booking'
import BookingModel from '../models/booking-model'

class MyS3UpdatePassengersPymentLayoutContainer extends Component {
    static propTypes = {
        booking: PropTypes.instanceOf(BookingModel).isRequired,
        onPrev: PropTypes.func.isRequired,
        onConfirm: PropTypes.func.isRequired,
        onConfirmed: PropTypes.func.isRequired
    }

    render () {
        return (
            <GenericLayout className='update-passengers'>
                <AfterSalesNavigation />
                <Row className='row contains-update-passengers'>
                    <div className='grid-row'>
                        <div className='grid-column--1-1 grid-column--medium-1-3'>
                            {this.props.booking ? (
                                <BookingSpecificationPanel afterSales booking={this.props.booking} />
                            ) : null}
                            {!device.isMobile() ? <Help /> : null}
                        </div>
                        <div className='grid-column--1-1 grid-column--medium-2-3'>
                            <PaymentContainer />
                            <ProgressNavigation
                                onPrev={this.props.onPrev}
                                onConfirm={this.props.onConfirm}
                                onConfirmed={this.props.onConfirmed}
                            />
                            {device.isMobile() ? <Help /> : null}
                        </div>
                    </div>
                </Row>
            </GenericLayout>
        )
    }
}

export default restoreBooking(MyS3UpdatePassengersPymentLayoutContainer, {withBlockingLoader: true})
