import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from '../../../translate'
import Loader from '../../../element/loader'
import {
    BoardingInformationCmsBlock,
    ExchangeAndRefundAlsaCmsBlock,
    ExchangeAndRefundCmsBlock,
    ExchangeAndRefundNelCmsBlock,
    MoreInformationCmsBlock,
    SeatAllocationCmsBlock
} from './cms-block'

export default class BookingConfirmationInformation extends Component {
    static propTypes = {
        loading: PropTypes.bool.isRequired,
        hasALSAProduct: PropTypes.bool,
        hasNELProduct: PropTypes.bool
    }

    render () {
        return (
            <div className='booking-confirmation-information'>
                <div className='panel'>
                    <header>
                        <h2>{_t.message('booking-confirmation-information.header')}</h2>
                    </header>
                    <div className='content'>
                        <div className='custom-content'>
                            {this.props.loading
                                ? <Loader />
                                : (
                                    <div className='grid-row'>
                                        <BoardingInformationCmsBlock />
                                        <SeatAllocationCmsBlock />
                                        {this.props.hasALSAProduct
                                            ? (
                                                <ExchangeAndRefundAlsaCmsBlock />
                                            ) : this.props.hasNELProduct ? (
                                                <ExchangeAndRefundNelCmsBlock />
                                            ) : (
                                                <ExchangeAndRefundCmsBlock />
                                            )}
                                        <MoreInformationCmsBlock />
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
