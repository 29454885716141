import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from '../../../../translate'
import Button from '../../../../element/button'
import ModalDialog from '../../../../element/modal-dialog'
import {cancelProvisionalBookingAndCreateNew} from './cancel-booking'
import actions from '../../../../reflux/actions'

class DiscountCardModal extends Component {
    static propTypes = {
        name: PropTypes.string,
        title: PropTypes.string,
        onClose: PropTypes.func.isRequired,
        youthsWithInvalidCards: PropTypes.array
    }

    constructor (...args) {
        super(...args)

        this.handleClose = this.handleClose.bind(this)
        this.handleRebook = this.handleRebook.bind(this)

        this.state = {
            isLoading: false
        }
    }

    componentDidMount () {
        actions.trackEvent('boostOmission', {boost_omission: 'Opened'})
    }

    handleClose () {
        if (this.state.isLoading) {
            return
        }
        actions.trackEvent('boostOmission', {boost_omission: 'oversight'})
        this.props.onClose()
    }

    async handleRebook () {
        actions.trackEvent('boostOmission', {boost_omission: 'pursue'})
        const response = cancelProvisionalBookingAndCreateNew(this.props.youthsWithInvalidCards)
        this.setState({isLoading: true, response})
    }

    render () {
        return (
            <ModalDialog
                name='discount-card-modal'
                headerTitle={_t.formatIntlMessage('discount-card-modal.header')}
                onClose={this.handleClose}
            >
                <div className='content'>
                    <div className='grid-row'>
                        <div className='grid-column--1-1 grid-column--medium-1-2'>
                            <div className='discount-card-modal-column'>
                                <div className='discount-card-modal-description'>
                                    <h2>{_t.formatIntlMessage('discount-card-modal.forgot.title')}</h2>
                                    <p>{_t.formatIntlMessage('discount-card-modal.forgot.description')}</p>
                                </div>
                                <Button
                                    text={_t.formatIntlMessage('discount-card-modal.forgot.button')}
                                    disabled={this.state.isLoading} className='button primary next'
                                    onClick={this.handleClose}
                                />
                            </div>
                        </div>
                        <div className='grid-column--1-1 grid-column--medium-1-2'>
                            <div className='discount-card-modal-column'>
                                <div className='discount-card-modal-description'>
                                    <h2>{_t.formatIntlMessage('discount-card-modal.rebook.title')}</h2>
                                    <p>{_t.formatIntlMessage('discount-card-modal.rebook.description')}</p>
                                </div>
                                <Button
                                    text={_t.formatIntlMessage('discount-card-modal.rebook.button')}
                                    disabled={this.state.isLoading} className='button primary next'
                                    onClick={this.handleRebook}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </ModalDialog>
        )
    }
}

export default DiscountCardModal
