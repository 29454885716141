/* globals S3P_SETTINGS: true */

import PropTypes from 'prop-types'
import React, { Component } from 'react'
import get from 'lodash/get'
import classNames from 'classnames'
import _t from '../../../../translate'
import device from '../../../../device'
import TextLabel from '../../../../element/text-label'

export default class Availability extends Component {
    static propTypes = {
        availability: PropTypes.number
    }

    render () {
        const availabilityThreshold = get(S3P_SETTINGS.s3Passenger, 'features.availabilityThreshold', 5)
        if (!this.props.availability || this.props.availability > availabilityThreshold) {
            return null
        }

        const text = _t.message('journey-result-set.availability', {quantity: this.props.availability})
        const className = classNames('last-seat-availability-container', {
            'grid-column--3-3': !device.isDesktopOrTablet()
        })

        return (
            <div className={className}>
                <div className='last-seat-availability'>
                    <span className='text-label last-seat' title={text}>
                        <TextLabel text={text} />
                    </span>
                </div>
            </div>
        )
    }
}
