import sortBy from 'lodash/sortBy'
import indexOf from 'lodash/indexOf'
import isEmpty from 'lodash/isEmpty'
import isBoolean from 'lodash/isBoolean'
import isNumber from 'lodash/isNumber'
import {
    PASSENGER_TYPE_ADULT,
    PASSENGER_TYPE_CHILD,
    PASSENGER_TYPE_BABY,
    PASSENGER_TYPE_YOUTH,
    DISCOUNT_CARD_YOUTH,
    PASSENGER_TYPE_YOUTH_WITH_DISCOUNT,
    PRODUCT_CODE_SEAT_SELECTION,
    PRODUCT_CODE_SEAT_SELECTION_PLUS,
    SEAT_MANUAL_SELECTION_AVAILABLE,
    SEAT_PLUS_MANUAL_SELECTION_AVAILABLE
} from '../constants'
import _t from '../translate'

export const isTrue = value => value === '1' || value === true

export const isYouthWithDiscountPassenger = passenger => {
    if (!passenger) {
        return false
    }
    const discountCards = passenger.discount_cards || passenger.discountCards
    return (
        passenger.type === PASSENGER_TYPE_YOUTH &&
        discountCards && discountCards.some(({code}) => code === DISCOUNT_CARD_YOUTH)
    )
}

export const getPassengerDiscountCardCode = passenger => {
    if (passenger && passenger.passengerType === PASSENGER_TYPE_YOUTH_WITH_DISCOUNT) {
        return DISCOUNT_CARD_YOUTH
    }

    return ''
}

export const mapPassenger = passenger => {
    const isYouthWithDiscount = isYouthWithDiscountPassenger(passenger)
    return {
        ...passenger,
        isYouthWithDiscount,
        passengerType: isYouthWithDiscount ? PASSENGER_TYPE_YOUTH_WITH_DISCOUNT : passenger.type
    }
}

export const mapPassengers = passengers => passengers && sortPassengers(passengers.map(mapPassenger))

export const sortPassengers = passengers => {
    const order = [PASSENGER_TYPE_ADULT, PASSENGER_TYPE_BABY, PASSENGER_TYPE_CHILD, PASSENGER_TYPE_YOUTH]
    return sortBy(passengers, [passenger => indexOf(order + (isYouthWithDiscountPassenger(passenger) ? 1 : 0), passenger.type), 'id'])
}

export const sortPassengersDescending = passengers => {
    const order = [PASSENGER_TYPE_ADULT, PASSENGER_TYPE_YOUTH, PASSENGER_TYPE_CHILD, PASSENGER_TYPE_BABY]
    return sortBy(passengers, [passenger => indexOf(order + (isYouthWithDiscountPassenger(passenger) ? 1 : 0), passenger.type), 'id'])
}

export const trimString = value => typeof value === 'string' ? value.replace(/\s/g, '') : value

export const getUniqueBookingTariffSegmentIdentifier = tariffSegment => [
    tariffSegment.travel_date,
    tariffSegment.validity_service,
    tariffSegment.departure_station && tariffSegment.departure_station._u_i_c_station_code,
    tariffSegment.arrival_station && tariffSegment.arrival_station._u_i_c_station_code
].join('|')

/**
 * A value is 'blank' if it's null, undefined, an empty array [], empty object {}, empty set or empty map.
 * The difference with lodash's isEmpty, is that it evaluates numbers and booleans as truthy.
 * @example
 *
 * isBlank(null)
 * // => true
 *
 * isBlank(new Map())
 * // => true
 *
 * isBlank(false)
 * // => false
 *
 * isBlank(1);
 * // => false
 *
 * isBlank([1, 2, 3])
 * // => false
 *
 * isBlank({ 'a': 1 })
 * // => false
 *
 * @param value
 * @returns {boolean}
 */
export const isBlank = value => Boolean(isEmpty(value) && !isBoolean(value) && !isNumber(value))

export const isSeatProductCode = code => [PRODUCT_CODE_SEAT_SELECTION, PRODUCT_CODE_SEAT_SELECTION_PLUS].includes(code)

export const isSeatSelectionPropertyCode = code => [SEAT_MANUAL_SELECTION_AVAILABLE, SEAT_PLUS_MANUAL_SELECTION_AVAILABLE].includes(code)

export const hasManualSeatProperty = seat => seat.property_codes.includes(SEAT_MANUAL_SELECTION_AVAILABLE)
export const hasManualSeatPlusProperty = seat => seat.property_codes.includes(SEAT_PLUS_MANUAL_SELECTION_AVAILABLE)

export const seatSelectionAvailableSeats = carriageLayout => {
    const seatOptions = carriageLayout.seat_properties.filter(property => isSeatSelectionPropertyCode(property.code))

    return seatOptions.reduce((available, option) => {
        available += option.available_seats
        return available
    }, 0)
}

export const indexByKey = (items, propKey) => (items || []).reduce(
    (object, item) => {
        const key = item[propKey]
        object[key] = item
        return object
    },
    {}
)

export const getUrl = () => {
    const language = _t.getLocales()
    const languageShort = language === 'en-GB' ? 'co.uk' : language.substring(3, 5).toLowerCase()
    return process.env.NODE_ENV === 'development'
        ? `/${language}/orientation/offer`
        : `https://www.blablacar.${languageShort}/bus`
}
