import PropTypes from 'prop-types'
import React, { Component } from 'react'
import progressNavigation from './progress-navigation'
import {withRouter} from 'react-router'

class progressNavigationContainer extends Component {
    static propTypes = {
        previousButtonRow: PropTypes.object,
        nextButtonRow: PropTypes.object
    }

    render () {
        return (
            <div className='progress-navigation'>
                <div className='grid-row'>
                    <div className='grid-column--1-2 grid-column--medium-1-2'>
                        {this.props.previousButtonRow}
                    </div>
                    <div className='grid-column--1-2 grid-column--medium-1-2'>
                        {this.props.nextButtonRow}
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(progressNavigation(progressNavigationContainer))
