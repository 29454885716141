// Libraries
import React from 'react'
import {Route} from 'react-router'

// Layouts
import CrmLoginLayout from '../layouts/crm-login-layout'

export default (
    <Route path='/:lang/crm-login' component={CrmLoginLayout} />
)
