import {inboundTravelSelector, outboundTravelSelector} from '../models/selectors/api/v2/orientation/travel'
import actions from '../reflux/actions'
import {getState} from '../reflux/bridge/connect-state'
import {getJourneySearchFromBooking} from './journey-search-url-query'
import {addBreadcrumb, captureException} from '@sentry/browser'

const _setBundle = async (travel, tariffSegments) => {
    const route = travel.routes.find(({legs}) =>
        tariffSegments.journeySegments.every(
            ({
                arrival_date_time: _arrivalDateTime,
                departure_date_time: _departureDateTime,
                arrival_station: _arrivalStation,
                departure_station: _departureStation,
                service_name: _serviceName,
                service_type: _serviceType
            }) =>
                legs.find(({arrivalStation, serviceName, serviceType, departureStation}) =>
                    _arrivalStation._u_i_c_station_code === arrivalStation.UICStationCode &&
                    _departureStation._u_i_c_station_code === departureStation.UICStationCode &&
                    _serviceName === serviceName &&
                    _serviceType.code === serviceType.code &&
                    _departureDateTime === departureStation.departureTimestamp &&
                    _arrivalDateTime === arrivalStation.arrivalTimestamp
                )
        )
    )

    if (!route) {
        captureException(new Error('Deeplink - Cannot find a route matching the booking journey'))
    }

    const bundle = route && route.bundles.find(
        ({items}) => items.find(
            ({passengerFares}) => passengerFares.every(
                ({passengerId, productCode}) =>
                    tariffSegments.requiredProducts.find(({passenger_id: _passengerId, code}) =>
                        _passengerId === passengerId && code === productCode
                    )
            )
        )
    )
    if (!bundle) {
        captureException(new Error('Deeplink - Cannot find a bundle matching the booking products'))
    }

    bundle && await actions.selectBundle(bundle)
}

// try to set the selected bundles based on the (provisional) booking to be able to provide the seat selector to the customer
export const restoreSelectedBundlesFromBooking = async booking => {
    addBreadcrumb({message: 'Deeplink - Looking for bundles'})

    const {outboundDate, inboundDate, ...journeySearch} = getJourneySearchFromBooking(booking)
    await actions.getOffer({
        ...journeySearch,
        outboundDate: outboundDate && new Date(outboundDate),
        inboundDate: inboundDate && new Date(inboundDate)
    })
    const state = getState()

    const outboundTariffSegments = booking.outboundSegmentCollection.withoutCancelled().withRequiredProducts()
    if (outboundTariffSegments.length) {
        const outboundTravel = outboundTravelSelector(state)
        await _setBundle(outboundTravel, outboundTariffSegments)
    }
    const inboundTariffSegments = booking.inboundSegmentCollection.withoutCancelled().withRequiredProducts()
    if (inboundTariffSegments.length) {
        const inboundTravel = inboundTravelSelector(state)
        await _setBundle(inboundTravel, inboundTariffSegments)
    }

    if (!inboundTariffSegments.length && !outboundTariffSegments.length) {
        captureException(new Error('Deeplink - Cannot set bundle, no valid tariff segments found.'))
    }
}
