import React from 'react'
import Reflux from 'reflux'
import BookingStore from '../../../reflux/stores/booking-store'
import CarriageLayoutsStore from '../../../reflux/stores/carriage-layouts-store'
import Passenger from './passenger'
import createReactClass from 'create-react-class'

export default createReactClass({

    displayName: 'PassengerContainer',

    mixins: [
        Reflux.listenTo(CarriageLayoutsStore, 'updateLoading'),
        Reflux.listenTo(BookingStore, 'updateLoading')
    ],

    getInitialState () {
        return {
            isRemoveLoading: false
        }
    },

    updateLoading (data) {
        this.setState({
            isRemoveLoading: data.loading
        })
    },

    render () {
        return <Passenger isRemoveLoading={this.state.isRemoveLoading} {...this.props} />
    }
})
