export const BRANDING_NAME_BLABLACAR = 'BlaBlaCar'
export const BRANDING_NAME_BLABLACAR_BUS = 'BlaBlaCar Bus'

export const PRODUCT_CODE_PROMO = 'PROMO'
export const OUTBOUND_JOURNEY = 'outward'
export const INBOUND_JOURNEY = 'return'

export const BIG_GROUP_SIZE = 10
export const THRESHOLD_DAYS = 4
export const THRESHOLD_MINUTES = 30

export const SHOW_SEATS_THRESHOLD_HOURS = 1

export const SALES_CHANNEL_PROPERTY_CANCELLATION_REASONS = 'cancellation_reasons'
export const DEFAULT_OPTION_CMS_BLOCK_NAME = 'AFTERSALES-CANCEL-FLEX-REASON-CUSTOMER'
export const CMS_BLOCK_CANCEL_REASON_PREFIX = 'AFTERSALES-CANCEL-FLEX-REASON-'
export const CANCEL_REASON_MISTAKE = 'MISTAKE'
export const CANCEL_OPTION_ENTIRE_BOOKING = 'entire_booking'
export const CANCEL_OPTION_JOURNEY = 'journey'
export const CANCEL_OPTION_PASSENGER = 'passenger'

export const AFTER_SALES_SEAT_SELECTION = 'AFTER_SALES_SEAT_SELECTION'
export const AFTER_SALES_REBOOK = 'AFTER_SALES_REBOOK'
export const AFTER_SALES_UPDATE_PASSENGERS = 'AFTER_SALES_UPDATE_PASSENGERS'
export const AFTER_SALES_ADDITIONAL_PRODUCTS = 'AFTER_SALES_ADDITIONAL_PRODUCTS'
export const AFTER_SALES_ADDITIONAL_PRODUCTS_LUGGAGE = 'AFTER_SALES_ADDITIONAL_PRODUCTS_LUGGAGE'
export const AFTER_SALES_ADDITIONAL_PRODUCTS_ENTERTAINMENT = 'AFTER_SALES_ADDITIONAL_PRODUCTS_ENTERTAINMENT'

export const AFTER_SALES_CANCELLATION = 'AFTER_SALES_CANCELLATION'
export const CMS_BLOCK_AFTERSALES_VIEW_SEAT_NUMBER = 'AFTERSALES-VIEW-SEAT-NUMBER'
export const ADDITIONAL_PRODUCTS_FILTER_LUGGAGE = 'luggage'
export const ADDITIONAL_PRODUCTS_FILTER_ENTERTAINMENT = 'entertainment'

export const PRODUCT_CODE_SEAT_SELECTION = 'SEATSELECTION'
export const PRODUCT_CODE_SEAT_SELECTION_PLUS = 'SEATSELECTIONPLUS'

export const PRODUCT_CODE_LUGGAGE = 'ADD_LUGG'
export const PRODUCT_CODE_BIKE = 'ADD_BIKE'

export const PRODUCT_CODE_ALSA = 'STATIC'
export const PRODUCT_CODE_NEL = 'STATIC_2'

export const HTTP_CODE_ORIGIN_UNREACHABLE = 523
export const HTTP_CODE_UNAUTHORIZED = 401
export const HTTP_CODE_SERVICE_UNAVAILABLE = 503

export const SERVICE_TYPE_MODALITY_BUS = 'BUS'
export const SERVICE_TYPE_MODALITY_TER = 'STANDARD_RAIL'
export const SERVICE_TYPE_NAME_ALSA = 'ALSA'
export const SERVICE_TYPE_NAME_TRANSALTITUDE = 'TRANSALTITUDE'
export const SERVICE_TYPE_NAME_MARINO = 'MARINO'
export const SERVICE_TYPE_NAME_NATIONAL_EXPRESS = 'NATIONAL EXPRESS'
export const SERVICE_TYPE_NAME_BUS_VERTS = 'BUS VERTS'
export const SERVICE_TYPE_NAME_BLABLABUS_DE_LONG = 'BLABLABUS DEUTSCHLAND'
export const SERVICE_TYPE_NAME_BLABLABUS_DE_SHORT = 'BLABLABUS DE'

export const PASSENGER_TYPE_ADULT = 'A'
export const PASSENGER_TYPE_CHILD = 'K'
export const PASSENGER_TYPE_BABY = 'C'
export const PASSENGER_TYPE_YOUTH = 'Y'

export const DISCOUNT_CARD_YOUTH = 'JEU'

export const PASSENGER_TYPE_YOUTH_WITH_DISCOUNT = `${PASSENGER_TYPE_YOUTH}_${DISCOUNT_CARD_YOUTH}`

export const PASSENGER_GENDER_MALE = 'M'
export const PASSENGER_GENDER_FEMALE = 'F'

export const FIRST_PASSENGER = 'passenger_1'

export const CUSTOMER_TYPE_PAY_ONLY = 'pay-only'

export const NON_OUIBUS_SERVICE_TYPE_NAMES = [
    SERVICE_TYPE_NAME_ALSA,
    SERVICE_TYPE_NAME_TRANSALTITUDE,
    SERVICE_TYPE_NAME_MARINO,
    SERVICE_TYPE_NAME_NATIONAL_EXPRESS,
    SERVICE_TYPE_NAME_BUS_VERTS
]

export const SERVICE_TYPE_PROPERTY_OUIBUS_PRIVACY_TERMS = 'CDC_OUIBUS'
export const SERVICE_TYPE_PROPERTY_OUIBUS_GENERAL_TERMS = 'CGV_OUIBUS'

export const CMS_BLOCK_BOOKING_PREF_TRANSALTITUDE = 'BOOKING-PREF-TRANSALTITUDE'
export const CMS_BLOCK_BOOKING_PREF_MARINO = 'BOOKING-PREF-MARINO'
export const CMS_BLOCK_BOOKING_PREF_NEL = 'BOOKING-PREF-NEL'

export const PAYMENT_METHOD_FLOW_TYPE_BANCONTACT = 'BANCONTACT'
export const PAYMENT_METHOD_FLOW_TYPE_CREDIT_CARD_ENCRYPTED = 'CREDITCARD_ENCRYPTED'
export const PAYMENT_METHOD_FLOW_TYPE_CREDITCARD = 'CREDITCARD'
export const PAYMENT_METHOD_FLOW_TYPE_CREDITCARD_REDIRECT = 'CREDITCARD_REDIRECT'
export const PAYMENT_METHOD_FLOW_TYPE_DIRECT_DEBIT = 'DIRECT_DEBIT'
export const PAYMENT_METHOD_FLOW_TYPE_GIROPAY = 'GIROPAY'
export const PAYMENT_METHOD_FLOW_TYPE_IDEAL = 'IDEAL'
export const PAYMENT_METHOD_FLOW_TYPE_INVOICE = 'INVOICE'
export const PAYMENT_METHOD_FLOW_TYPE_PAYPAL = 'PAYPAL'
export const PAYMENT_METHOD_FLOW_TYPE_SOFORT = 'SOFORT'
export const PAYMENT_METHOD_FLOW_TYPE_VOUCHER = 'VOUCHER'
export const PAYMENT_METHOD_FLOW_TYPE_DEFAULT = 'DEFAULT'

export const VOUCHER_TYPE_DISCOUNT = 'DISCOUNT'

export const PAYMENT_METHOD_CODE_STATION_CASH = 'NONE_STATIONCASH'
export const PAYMENT_METHOD_CODE_VOUCHER = 'VOUCHER'
export const PAYMENT_METHOD_CODE_INVOICE = 'NONE_FREEPAYMENT'

export const PAYMENT_METHOD_STATION_CASH = 'STATIONCASH'
export const PAYMENT_METHOD_VOUCHER = 'voucher'

export const PAYMENT_PROVIDER_ADYEN = 'ADYEN'
export const PAYMENT_PROVIDER_WORLDPAY = 'WPAY'
export const PAYMENT_PROVIDER_NONE = 'NONE'

export const PAYMENT_FORM_ADYEN_CREDITCARD_ENCRYPTED = 'PAYMENT_FORM_ADYEN_CREDITCARD_ENCRYPTED'
export const PAYMENT_FORM_ADYEN_PAYPAL = 'PAYMENT_FORM_ADYEN_PAYPAL'
export const PAYMENT_FORM_ADYEN_SOFORT = 'PAYMENT_FORM_ADYEN_SOFORT'
export const PAYMENT_FORM_ADYEN_IDEAL = 'PAYMENT_FORM_ADYEN_IDEAL'
export const PAYMENT_FORM_WPAY_CREDITCARD = 'PAYMENT_FORM_WPAY_CREDITCARD'
export const PAYMENT_FORM_WPAY_CREDITCARD_ENCRYPTED = 'PAYMENT_FORM_WPAY_CREDITCARD_ENCRYPTED'
export const PAYMENT_FORM_WPAY_BANCONTACT = 'PAYMENT_FORM_WPAY_BANCONTACT'
export const PAYMENT_FORM_CASH = 'PAYMENT_FORM_CASH'
export const PAYMENT_FORM_VOUCHER = 'PAYMENT_FORM_VOUCHER'

export const PAYMENT_STATUS_S = 'S'
export const PAYMENT_STATUS_P = 'P'
export const PAYMENT_STATUS_F = 'F'

export const CURRENCY_EUR = 'EUR'
export const CURRENCY_GBP = 'GBP'
export const CURRENCY_HUF = 'HUF'
export const CURRENCY_CZK = 'CZK'

export const PROVIDER_REACH_FIVE = 'ReachFive'

export const NEW_FAVORITE_PASSENGER = 'NEW_FAVORITE_PASSENGER'
export const UPDATE_CRM = 'UPDATE_CRM'

export const SEAT_EXTRA_LEG = 'ELEG'
export const SEAT_MANUAL_SELECTION_AVAILABLE = 'SEATOPTION'
export const SEAT_PLUS_MANUAL_SELECTION_AVAILABLE = 'SEATOPTIONPLUS'
export const SEAT_MANUAL_SELECTION_NOT_AVAILABLE = 'NONSEATSELECT'

export const STORAGE_WEB_VIEW = 'STORAGE_WEB_VIEW'
export const STORAGE_INSURANCE_SELECTED = 'insuranceSelected'
export const STORAGE_PASSENGER_DETAILS = 'passengers_details'
export const STORAGE_TRACKTOR = 'tracktor'
export const STORAGE_TARGET = 'target'
export const STORAGE_ORIGINAL_SEATS = 'originalSeats'

export const NEWSLETTER_OPTIN = 'newsletter_selligent'

export const SALES_CHANNEL_INTERNET = 'INTERNET'
export const SALES_CHANNEL_LABELPARTNER = 'LABELPARTNER'

export const ADDITIONAL_DETAIL_DISCOUNT_CARD = 'JEU'
export const ADDITIONAL_DETAIL_EXTERNAL_PASSENGER_ID = 'External_passenger_ID'

export const PASSENGER_DETAILS_FORM_PREFIX = 'passenger-details-form'

export const INSURANCE_PRODUCT_1 = 'INS1'
export const INSURANCE_PRODUCT_2 = 'INS2'
export const INSURANCE_PRODUCT_3 = 'INS3'
export const INSURANCE_PRODUCT_4 = 'INS4'
export const INSURANCE_PRODUCT_5 = 'INS5'
export const INSURANCE_PRODUCT_6 = 'INS6'
export const INSURANCE_PRODUCT_7 = 'INS7'
export const INSURANCE_PRODUCT_8 = 'INS8'
export const INSURANCE_PRODUCT_9 = 'INS9'
export const INSURANCE_PRODUCT_SUFFIX_NATIONAL = 'N'
export const INSURANCE_PRODUCT_REGEX = /INS\d(N|I)/
export const INSURANCE_AVAILABLE_PRODUCT = `${INSURANCE_PRODUCT_1}${INSURANCE_PRODUCT_SUFFIX_NATIONAL}`

export const INSURANCE_MAX_BOOKING_VALUE = {
    [INSURANCE_PRODUCT_1]: 11,
    [INSURANCE_PRODUCT_2]: 21,
    [INSURANCE_PRODUCT_3]: 31,
    [INSURANCE_PRODUCT_4]: 41,
    [INSURANCE_PRODUCT_5]: 51,
    [INSURANCE_PRODUCT_6]: 76,
    [INSURANCE_PRODUCT_7]: 101,
    [INSURANCE_PRODUCT_8]: 151,
    [INSURANCE_PRODUCT_9]: Number.MAX_VALUE
}
