import _ from 'lodash';

(function () {
    var $ = require('jquery')
    require('jquery-ui/ui/widgets/autocomplete')

    $.widget('ui.autocomplete', $.ui.autocomplete, {

        options: {
            maxLength: 0
        },

        _renderItem (ul, item) {
            var label = item.label
            if (this.term.length > 0) {
                var matcher = new RegExp(_.escapeRegExp(this.term), 'i')
                if (matcher.test(item.label)) {
                    var match = matcher.exec(item.label)
                    label = item.label.replace(match, '<mark>' + match + '</mark>')
                }
            }

            return $('<li class="' + (item.isMain ? 'main' : 'sub') + '">').html(label).appendTo(ul)
        },

        _resizeMenu () {
            this._super()
            var ul = this.menu.element

            if (this.options.maxLength > 0) {
                this.menu.refresh()
                var height = 0
                ul.children().each(function (_, element) {
                    height = Math.max(height, $(element).outerHeight())
                })
                ul.css('max-height', height * this.options.maxLength)
            }
        }

    })
})()
