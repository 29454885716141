import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Icon from './icon'
import {
    PASSENGER_TYPE_ADULT,
    PASSENGER_TYPE_CHILD,
    PASSENGER_TYPE_BABY,
    PASSENGER_TYPE_YOUTH
} from '../constants'

export default class IconPassengerType extends Component {
    static propTypes = {
        passengerType: PropTypes.string.isRequired
    }

    iconMapping = {
        [PASSENGER_TYPE_ADULT]: 'adult',
        [PASSENGER_TYPE_CHILD]: 'child',
        [PASSENGER_TYPE_BABY]: 'baby',
        [PASSENGER_TYPE_YOUTH]: 'youngman'
    }

    render () {
        const {passengerType, ...props} = this.props

        return <Icon type={this.iconMapping[passengerType]} {...props} />
    }
}
