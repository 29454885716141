import AccessManager from '../data/access-manager'
import BookingStore from '../reflux/stores/booking-store'
import CrmUserStore from '../reflux/stores/crm-user-store'
import actions from '../reflux/actions'

const storeFavoriteStations = stations => {
    const crmCustomer = CrmUserStore.getCrmCustomer()
    const metaData = CrmUserStore.getCrmCustomerModel().mergeFavoriteStationsWithMetaData(stations)
    if (!metaData) {
        return false
    }
    const request = {
        ...crmCustomer,
        meta_data: metaData
    }

    return actions.updateCrmUserInformation(request)
}

const handleStations = () => {
    // Stations from tariff segments
    const booking = BookingStore.getBookingModel()
    if (!booking) {
        return false
    }

    const bookingStations = [
        booking.outboundOrigin, booking.outboundDestination,
        booking.inboundOrigin, booking.inboundDestination
    ].reduce((list, station) => {
        if (station && !list.includes(station._u_i_c_station_code)) {
            list.push(station._u_i_c_station_code)
        }
        return list
    }, [])

    // Crm customer stations from external reference
    const crmCustomer = CrmUserStore.getCrmCustomerModel()

    // Create unique list of stations
    const uniqueStations = bookingStations
        .concat(crmCustomer.favoriteStations)
        .reduce((list, station) => {
            if (station && !list.includes(station)) {
                list.push(station)
            }
            return list
        }, [])

    // Only store if the uniqueStations differ from crmFavoriteStations
    return uniqueStations.length !== crmCustomer.favoriteStations.length ? storeFavoriteStations(uniqueStations) : false
}

export const addFavoriteStations = () => {
    if (AccessManager.isCrmUser()) {
        return !CrmUserStore.getUser() ? actions.loadCrmUserInformation().then(handleStations) : handleStations()
    }
    return false
}
