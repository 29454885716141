import React from 'react'
import PropTypes from 'prop-types'
import {withRouter} from 'react-router'
import Reflux from 'reflux'
import _t from '../../../translate'
import MyS3ReBookingDetailsLayoutContainer from './details'
import BookingStore from '../../../reflux/stores/booking-store'
import BookingModel from '../../../models/booking-model'
import {restoreJourneySearchCompose} from '../../../components/restore/restore-journey-search'
import {restoreBookingCompose} from '../../../components/restore/restore-booking'
import compose from '../../../misc/compose'
import createReactClass from 'create-react-class'

const component = createReactClass({

    propTypes: {
        router: PropTypes.object
    },

    mixins: [
        Reflux.connectFilter(BookingStore, 'booking', data => BookingModel.create(data.booking))
    ],

    handleClickPrevious () {
        this.props.router.push(`/${_t.getLocales()}/mys3/re-booking/offer`)
    },

    handleClickNext () {
        this.props.router.push(`/${_t.getLocales()}/mys3/re-booking/options`)
    },

    render () {
        return (
            <MyS3ReBookingDetailsLayoutContainer
                booking={this.state.booking}
                onClickPrevious={this.handleClickPrevious}
                onClickNext={this.handleClickNext}
            />
        )
    }
})

const container = compose(
    restoreJourneySearchCompose({withBlockingLoader: true}),
    restoreBookingCompose({withBlockingLoader: true})
)

export default withRouter(container(component))
