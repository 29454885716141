import Reflux from 'reflux'
import UserAwareMixin from '../mixin/user-aware-mixin'
import actions from '../actions'
import FormValidationMixin from '../mixin/form-validation-mixin'

export default Reflux.createStore({

    listenables: actions,

    mixins: [FormValidationMixin, UserAwareMixin],

    init () {
        this._resetData()

        this.validators = {
            contactEmailAddress: [this._validateEmail]
        }
    },

    _resetData () {
        this.data = {
            loading: false,
            completed: false,
            isValid: false,
            fields: {
                partnerOrganisation: {
                    value: '',
                    errorText: null,
                    isRequired: true
                },
                contactPerson: {
                    value: '',
                    errorText: null,
                    isRequired: true
                },
                contactEmailAddress: {
                    value: '',
                    errorText: null,
                    isRequired: true
                },
                contactPhoneNumber: {
                    value: '',
                    errorText: null,
                    isRequired: false
                },
                applicationDescription: {
                    value: '',
                    errorText: null,
                    isRequired: true
                },
                grantCategory: {
                    value: '',
                    errorText: null,
                    isRequired: true,
                    options: [
                        {
                            text: 'request-json-api-key.grant-category.default',
                            value: ''
                        },
                        {
                            text: 'request-json-api-key.grant-category.orientation',
                            value: 'orientation'
                        },
                        {
                            text: 'request-json-api-key.grant-category.public',
                            value: 'public'
                        },
                        {
                            text: 'request-json-api-key.grant-category.all',
                            value: 'all'
                        }
                    ]
                }
            }
        }
    },

    resetData () {
        this._resetData()
        this.trigger(this.data)
    },

    getInitialState () {
        return this.data
    },

    onProcessRegisterJsonApiKeyData (newData) {
        newData.forEach(record => {
            this.data.fields[record.id].value = record.value
        })

        this._validateData(newData)
    },

    onRequestJsonApiKey () {
        this.data.loading = true
        this.data.completed = false
        this.trigger(this.data)
    },

    onRequestJsonApiKeyCompleted () {
        this.data.loading = false
        this.data.completed = true
        this.trigger(this.data)
    }

})
