import React, {Component} from 'react'
import PropTypes from 'prop-types'
import _t from '../translate'
import device from '../device'
import GenericLayout from '../layouts/generic-layout'
import Row from '../element/row'
import Icon from '../element/icon'
import Loader from '../element/loader'
import PassengerDetails from '../components/my-s3-update-passengers/details'
import UpdatePassengersInformation from '../components/my-s3-update-passengers/information'
import ProgressNavigation from '../base/main/progress-navigation-container'
import BookingSpecificationPanel from '../components/booking-specification/booking-panel'
import ConfirmDialog from '../element/confirm-dialog'
import Help from '../components/my-s3-booking/help'
import AfterSalesNavigation from '../base/after-sales-navigation'
import FormattedHTMLMessage from '../translate/formatted-html-message'
import BookingModel from '../models/booking-model'

export default class MyS3UpdatePassengerLayoutContainer extends Component {
    static propTypes = {
        loading: PropTypes.bool.isRequired,
        nextButtonLoading: PropTypes.bool.isRequired,
        showConfirmDialog: PropTypes.bool.isRequired,
        booking: PropTypes.instanceOf(BookingModel),
        editPassengers: PropTypes.array.isRequired,
        hasEditPassengers: PropTypes.func.isRequired,
        onEditPassenger: PropTypes.func.isRequired,
        onResetPassenger: PropTypes.func.isRequired,
        onClickPrev: PropTypes.func.isRequired,
        onClickNext: PropTypes.func.isRequired,
        confirmUpdate: PropTypes.func.isRequired,
        revertUpdate: PropTypes.func.isRequired
    }

    render () {
        return (
            <GenericLayout className='update-passengers'>
                {this.props.loading ? null : <AfterSalesNavigation />}
                {this.props.loading ? this._renderLoader() : this._renderContent()}
                {this.props.showConfirmDialog ? this._renderConfirmDialog() : null}
            </GenericLayout>
        )
    }

    _renderLoader () {
        return (
            <Row className='row contains-blocking-loader'>
                <Loader blocking />
            </Row>
        )
    }

    _renderContent () {
        const previousButtonProps = {
            onClick: this.props.onClickPrev,
            className: 'button previous default',
            icon: {
                type: 'chevron-left',
                className: 'xsmall align-left'
            }
        }

        const nextButtonProps = {
            onClick: this.props.onClickNext,
            loading: this.props.nextButtonLoading,
            className: 'button next primary',
            icon: {
                type: 'chevron-right',
                className: 'xsmall align-right'
            }
        }

        return this.props.booking ? (
            <Row>
                <div className='grid-row'>
                    <div className='grid-column--1-1 grid-column--medium-1-3'>
                        <BookingSpecificationPanel afterSales booking={this.props.booking} />
                        {!device.isMobile() ? <Help /> : null}
                    </div>
                    <div className='grid-column--1-1 grid-column--medium-2-3'>
                        <UpdatePassengersInformation booking={this.props.booking} />
                        <PassengerDetails
                            editPassengers={this.props.editPassengers}
                            onEditPassenger={this.props.onEditPassenger}
                            onResetPassenger={this.props.onResetPassenger}
                        />
                        <ProgressNavigation
                            previousButtonEnabled
                            previousButtonProps={previousButtonProps}
                            nextButtonEnabled={this.props.hasEditPassengers()}
                            nextButtonProps={nextButtonProps}
                        />
                        {device.isMobile() ? <Help /> : null}
                    </div>
                </div>
            </Row>
        ) : null
    }

    _renderConfirmDialog () {
        return (
            <ConfirmDialog
                onClose={this.props.revertUpdate}
                onCancelButtonClick={this.props.revertUpdate}
                onConfirmButtonClick={this.props.confirmUpdate}
                labelPrefix='update-passenger-details.confirm-dialog'>
                <span className='text-label information'>
                    <Icon type='information' className='small align-left' />
                    <FormattedHTMLMessage
                        message={_t.getIntlMessage('update-passenger-details.confirm-dialog.information')}
                    />
                </span>
            </ConfirmDialog>
        )
    }
}
