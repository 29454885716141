import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {Link} from 'react-router'
import TextLabel from '../../element/text-label'
import _t from '../../translate'
import FormattedHTMLMessage from '../../translate/formatted-html-message'
import {
    seatSelectionAvailableSelector,
    seatSelectionPlusProductSelector,
    seatSelectionProductSelector
} from '../../models/selectors/components/booking/seat-selection'
import {
    connectState,
    getState
} from '../../reflux/bridge/connect-state'
import actions from '../../reflux/actions'
import Icon from '../../element/icon'

class SeatSelection extends Component {
    static propTypes = {
        showSeatSelector: PropTypes.bool,
        seatSelectorRoute: PropTypes.string,
        price: PropTypes.number,
        seatSelectionProductFares: PropTypes.array,
        seatSelectionPlusProductFares: PropTypes.array
    }

    constructor (...args) {
        super(...args)
        this.trackEvent = this.trackEvent.bind(this)
    }

    trackEvent () {
        actions.trackEvent('SeatOption', {
            basket_seat_option_action: 'Opened',
            basket_seat_price: this.getLowestSeatPrice()
        })
    }

    getLowestSeatPrice () {
        const seatFares = [
            ...this.props.seatSelectionProductFares,
            ...this.props.seatSelectionPlusProductFares
        ]
        return seatFares.reduce((min, fare) => fare.price < min ? fare.price : min, seatFares[0].price)
    }

    render () {
        return this.props.showSeatSelector ? (
            <Link
                className='seat-selection'
                to={`/${_t.getLocales()}${this.props.seatSelectorRoute}`}
                onClick={this.trackEvent}
            >
                <div className='panel'>
                    <div className='seat-selection--image'>
                        <div className='text-label'>
                            <TextLabel text={_t.message('orientation-seat-selector.header')} />
                        </div>
                    </div>
                    <div className='seat-selection--content'>
                        <header className='grid-row'>
                            <div className='grid-column--1-8 grid-column--medium-1-12'>
                                <Icon type='seat-selector' className='medium align-left' />
                            </div>
                            <div className='grid-column--7-8 grid-column--medium-11-12' >
                                <span className='text-label heading'>
                                    <TextLabel text={_t.message('seat-selection.heading')} />
                                </span>
                            </div>
                        </header>
                        <div className='grid-row'>
                            <div className='grid-column--1-1'>
                                <div className='text-label select-seats'>
                                    <TextLabel text={_t.message('seat-selection.best-seats')} />
                                </div>
                            </div>
                        </div>
                        <div className='grid-row'>
                            <div className='grid-column--1-1 grid-column--medium-3-4'>
                                <div className='text-label seat-from'>
                                    <FormattedHTMLMessage
                                        message={_t.formatIntlMessage('seat-selection.from', {
                                            price: _t.formatCurrency(this.getLowestSeatPrice())
                                        })} />
                                </div>
                            </div>
                            <div className='grid-column--1-1 grid-column--medium-1-4'>
                                <button className='button secondary open-seat-selector'>
                                    <TextLabel text={_t.message('seat-selection.choose')} />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Link>
        ) : null
    }
}

const mapPropsToProps = () => {
    const state = getState()
    const seatSelectionProduct = seatSelectionProductSelector(state)
    const seatSelectionPlusProduct = seatSelectionPlusProductSelector(state)

    return {
        showSeatSelector: seatSelectionAvailableSelector(state),
        seatSelectionProductFares: seatSelectionProduct ? seatSelectionProduct.passengerFares : [],
        seatSelectionPlusProductFares: seatSelectionPlusProduct ? seatSelectionPlusProduct.passengerFares : []
    }
}

export default connectState(mapPropsToProps)(SeatSelection)
