import Reflux from 'reflux'
import find from 'lodash/find'
import isUndefined from 'lodash/isUndefined'
import get from 'lodash/get'
import moment from 'moment-timezone'
import {toUtcDateMoment} from '../../misc/date'
import storage from '../../storage'
import actions from '../actions'
import FormValidationMixin from '../mixin/form-validation-mixin'
import UserAwareMixin from '../mixin/user-aware-mixin'
import BookingStore from './booking-store'
import PaymentMethodsStore from './payment-methods-store'
import PaymentResultModel from '../../models/payment-result-model'
import ResponseCodes from '../../api/response-codes'
import {
    PAYMENT_METHOD_CODE_VOUCHER,
    PAYMENT_METHOD_FLOW_TYPE_VOUCHER
} from '../../constants'
import {GATrackEvent} from '../../misc/analytics'
import AffiliateStore from './affiliate-store'
import {
    getPaymentFormName,
    getPaymentFormFields
} from './payment-store/forms'
import {getPaymentPreferences} from './payment-store/preferences'
import {getProviderFromCode} from '../../misc/payment'
import GoogleTaggingStore from './google-tagging-store'
import DOMPurify from 'dompurify'

const expiryValidation = value => {
    const expiry = moment(value, 'MM/YY', true)
    if (!expiry.isValid()) {
        return 'invalid-value'
    }
    const currentMonth = toUtcDateMoment().day(1)
    if (expiry.add(1, 'months').isBefore(currentMonth)) {
        return 'is-expired'
    }

    return null
}

export default Reflux.createStore({

    listenables: actions,
    mixins: [FormValidationMixin, UserAwareMixin],

    init () {
        this._resetData()

        this.validators = {
            agree: [
                value => this._validateBoolean(value),
                value => (value !== true) ? 'is-required' : null
            ],
            cardNumber: [value => !value.match(/^\d{12,19}$/) ? 'invalid-value' : null],
            cvcCode: [value => !value.match(/^\d{3,4}$/) ? 'invalid-value' : null],
            cashAmount: [value => value > BookingStore.getTotalPriceToBePaid() ? 'invalid-value' : null],
            validUntil: [value => expiryValidation(value)]
        }

        this.normalizers = {
            firstName: DOMPurify.sanitize,
            cardHolder: DOMPurify.sanitize
        }

        this.listenTo(actions.initiatePayment, this._startLoading)
        this.listenTo(actions.processPayment, this._startLoading)
        this.listenTo(actions.loadPaymentDetails, this._startLoading)

        this.listenTo(actions.initiatePayment.failed, this._setPaymentResultFailed)
        this.listenTo(actions.processPayment.failed, this._setPaymentResultFailed)

        this.listenTo(actions.createBooking.completed, this.resetData)
        this.listenTo(actions.myS3RebookCreateBooking, this.resetData)
        this.listenTo(actions.addPayments, this.resetData)

        // Reset data (payment result) when performing an aftersales change
        this.listenTo(actions.updatePassengers, this.resetData)
        this.listenTo(actions.updateSeats, this.resetData)
        this.listenTo(actions.patchBooking, this.resetData)

        this.listenTo(actions.removeVoucher.completed, () => this._validateMethod())
        this.listenTo(actions.addVoucher.completed, () => this._validateMethod())
    },

    _resetData () {
        this.data = {
            isValid: false,
            errorText: null,
            loading: false,
            paymentPreferences: null,
            paymentResult: null,
            paymentDetails: null,
            paymentForm: null,
            paymentMethodCode: null,
            returnUrl: null,
            fields: {}
        }
    },

    resetData () {
        this._resetData()
        storage.clear('payment_reference')
        this.trigger(this.data)
    },

    getInitialState () {
        return this.data
    },

    onLoadPaymentDetailsCompleted (paymentDetails) {
        this.data.loading = false
        this.data.paymentDetails = paymentDetails.data.payment
        this.trigger(this.data)
    },

    onSetPaymentReturnUrl (returnUrl) {
        this.data.loading = false
        this.data.returnUrl = returnUrl
    },

    onBuildPaymentPreferences (bookingNumber) {
        let preferences = {
            booking_number: bookingNumber,
            payment_method_code: this.data.paymentMethodCode
        }

        const affiliateCode = AffiliateStore.getAffiliateCode()
        if (affiliateCode) {
            preferences.affiliate_code = affiliateCode
        }

        this.data.errorText = null
        preferences.return_url = `${window.location.protocol}//${window.location.hostname}${this.data.returnUrl}`

        try {
            preferences = {
                ...getPaymentPreferences(
                    this.data.paymentForm,
                    PaymentMethodsStore.getPaymentMethodByCode(this.data.paymentMethodCode),
                    this.data.fields
                ),
                ...preferences
            }
        } catch (error) {
            if (error instanceof TypeError) {
                throw error
            }
            this.data.isValid = false
            this.data.errorText = error.message
            GoogleTaggingStore.onBookingFailure(error.message)
            GATrackEvent('payment', 'pay button click', `payment fail ${error.message}`)
        }

        if (this.data.isValid) {
            this.data.paymentPreferences = preferences
        }

        this.trigger(this.data)
    },

    onProcessPaymentData (newData) {
        const method = find(newData, {id: 'method'})
        let shouldValidate = this._changeMethod(method)

        newData.forEach(record => {
            if (this.data.fields[record.id]) {
                shouldValidate = shouldValidate || this.data.fields[record.id].value !== record.value
                this.data.fields[record.id].value = this.normalizers[record.id]
                    ? this.normalizers[record.id](record.value)
                    : record.value
            }
        })

        if (shouldValidate) {
            this.data.errorText = null
            this._validateData(newData)
        }
    },

    _validatePaymentData () {
        this._validateData()
        if (!this.data.isValid) {
            Object.keys(this.data.fields).filter(key => !this.data.fields[key].isValid).map(key =>
                [GoogleTaggingStore.onBookingFailure(this.data.fields[key].errorText), GATrackEvent('payment', 'pay button click', `payment fail ${key}.${this.data.fields[key].errorText}`)]
            )
        }
    },

    _changeMethod (newMethodCode) {
        if ((!newMethodCode || newMethodCode.value === this.data.paymentMethodCode)) {
            return false
        }

        const methodCode = newMethodCode ? newMethodCode.value : this.data.paymentMethodCode

        let flowType
        if (methodCode === PAYMENT_METHOD_CODE_VOUCHER) {
            flowType = PAYMENT_METHOD_FLOW_TYPE_VOUCHER
        } else {
            flowType = get(PaymentMethodsStore.getPaymentMethodByCode(methodCode), 'flow_type')
        }

        const provider = getProviderFromCode(methodCode)
        const formName = getPaymentFormName(provider, flowType)

        if (this.data.paymentForm !== formName) {
            this.data.paymentForm = formName
            this.data.fields = getPaymentFormFields(formName)
        }

        this.data.paymentMethodCode = methodCode

        return true
    },

    onInitiatePaymentCompleted (response) {
        this._setPaymentResultData(response.data.payment_result)
    },

    onProcessPaymentCompleted (response) {
        this._setPaymentResultData(response.data.payment_result)
    },

    onApiError (error) {
        const isPaymentRequest = !isUndefined(error.request) &&
            error.request.method === 'POST' &&
            error.request.url.slice(-7) === 'payment'

        if (!error.code && isPaymentRequest) {
            error.handled = true
            this._setPaymentResultFailed('timeout', error.message)
        } else if (
            error.code === ResponseCodes.PAYMENT_UNKNOWN_INITIATE_PAYMENT_ERROR ||
            error.code === ResponseCodes.PAYMENT_CARD_TYPE_NOT_SUPPORTED
        ) {
            error.handled = true
            this.data.success = false
            this.data.loading = false
            GoogleTaggingStore.onBookingFailure(error)
            GATrackEvent('payment', 'pay button click', `payment fail; ${error.message}`)
            this.trigger(this.data)
        } else if (error.code === ResponseCodes.PAYMENT_IS_REFUSED) {
            error.handled = true
            this._setPaymentResultFailed('refused', error.message)
        } else if (error.code === ResponseCodes.PAYMENT_IS_CANCELLED) {
            error.handled = true
            this._setPaymentResultFailed('cancelled', error.message)
        } else if (error.code === ResponseCodes.PAYMENT_NOT_FOUND_FOR_REFERENCE) {
            error.handled = true
            this._setPaymentDetailsFailed('not found', error.message)
        }
    },

    _validateMethod () {
        if (!this._validateFields(this.data, ['method'])) {
            this.data.isValid = false
            this.trigger(this.data)
        } else if (BookingStore.isPaid()) {
            this._validateData()
        }
    },

    _startLoading () {
        this.data.loading = true
        this.trigger(this.data)
    },

    _setPaymentResultData (paymentResult) {
        if (paymentResult.handled) {
            this._resetData()
        }
        this.data.loading = false
        this.data.paymentResult = PaymentResultModel.create(paymentResult)
        this.data.paymentResult.isSuccess() && GATrackEvent('payment', 'pay button click', `payment succes`)
        this.data.paymentResult.isFailed() && GATrackEvent('payment', 'pay button click', `payment failed`)
        this.trigger(this.data)
    },

    _setPaymentResultFailed (error, errorMessage) {
        this.data.loading = false
        this.data.paymentResult = null
        this.data.errorText = error
        GoogleTaggingStore.onBookingFailure(error)
        GATrackEvent('payment', 'pay button click', `payment fail; ${errorMessage}`)
        this.trigger(this.data)
    },

    _setPaymentDetailsFailed (error, errorMessage) {
        this.data.loading = false
        this.data.paymentDetails = null
        this.data.errorText = error
        GoogleTaggingStore.onBookingFailure(error)
        GATrackEvent('payment', 'pay button click', `payment fail; ${errorMessage}`)
        this.trigger(this.data)
    }

})
