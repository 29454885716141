import {
    PRODUCT_CODE_PROMO,
    PRODUCT_CODE_ALSA,
    PRODUCT_CODE_NEL, PRODUCT_CODE_LUGGAGE,
    PRODUCT_CODE_BIKE
} from '../../../../constants'
import {createSelector} from 'reselect'
import {
    bundlesSelector as baseBundlesSelector,
    routesSelector as baseRoutesSelector,
    travelsSelector as baseTravelsSelector
} from '../../api/v2/orientation/journey-search'
import {selectedBundlesSelector as baseSelectedBundlesSelector} from '../../../../reflux/bridge/select-bundle'
import {journeySearchFromJourneyResultSelector} from '../../api/v2/orientation/journey-result'
import {currencySelector} from '../../../../reflux/bridge/currency'
import moment from 'moment-timezone'
import {
    TRAVEL_DIRECTION_INBOUND,
    TRAVEL_DIRECTION_OUTBOUND
} from '../../constants'

export const routesSelector = travelId => createSelector(
    [baseTravelsSelector, baseRoutesSelector, baseBundlesSelector],
    (travels, routes, bundles) => {
        const travel = travels.get(travelId)
        const travelRoutes = (travel && travel.routes) || []
        const fastestRoute = travelRoutes.reduce((min, route) => {
            const ms = route.duration.asMilliseconds()
            return ms < min ? ms : min
        }, Number.MAX_VALUE)

        const slowestRoute = travelRoutes.reduce((max, route) => {
            const ms = route.duration.asMilliseconds()
            return ms > max ? ms : max
        }, 0)

        const cheapestTravelBundlePrice = travelRoutes.reduce((min, route) => route.bundles.reduce(
            (minBundle, bundle) => bundle.price < minBundle ? bundle.price : minBundle, min
        ), Number.MAX_VALUE)

        return travelRoutes.map(route => {
            const fullRoute = routes.get(route.id)

            const cheapestBundlePrice = route.bundles.reduce(
                (min, bundle) => bundle.price < min ? bundle.price : min,
                Number.MAX_VALUE
            )
            const newBundles = fullRoute.bundles.map(bundle => ({
                ...bundles.get(bundle.id),
                availability: bundle.items
                    .find(item => item.isMainProduct)
                    .passengerFares
                    .reduce(
                        (min, fare) => fare.logicalAvailability < min ? fare.logicalAvailability : min,
                        Number.MAX_VALUE
                    ),
                isPromo: bundle.items.some(item => item.productCode === PRODUCT_CODE_PROMO),
                isCheapestRouteBundle: bundle.price === cheapestBundlePrice,
                isCheapestTravelBundle: bundle.price === cheapestTravelBundlePrice,
                isAlsaProduct: bundle.items.some(item => item.productCode === PRODUCT_CODE_ALSA),
                isNelProduct: bundle.items.some(item => item.productCode === PRODUCT_CODE_NEL)
            }))

            return {
                ...fullRoute,
                travelTime: fullRoute.duration,
                hasPromoBundle: newBundles.some(bundle => bundle.isPromo),
                isCheapestRoute: newBundles.some(bundle => bundle.isCheapestTravelBundle),
                bundles: newBundles,
                isSlowestRoute: fullRoute.duration.asMilliseconds() >= slowestRoute,
                isFastestRoute: fullRoute.duration.asMilliseconds() <= fastestRoute
            }
        })
    }
)

export const hasLuggagePayingOptionsSelector = state => {
    const bundleObjects = baseSelectedBundlesSelector(state)
    const bundles = baseBundlesSelector(state)
    const routes = baseRoutesSelector(state)
    return bundleObjects.length && bundleObjects.every(({id: bundleId}) => {
        const bundle = bundles.get(bundleId)
        const route = routes.get(bundle.routeId)
        const luggageProducts = bundle.items.filter(({productCode}) => productCode === PRODUCT_CODE_LUGGAGE)
        return luggageProducts && route.legs.every(({id}) => luggageProducts.find(({legIds}) => legIds.includes(id)))
    })
}

export const hasBikePayingOptionsSelector = state => {
    const bundleObjects = baseSelectedBundlesSelector(state)
    const bundles = baseBundlesSelector(state)
    const routes = baseRoutesSelector(state)
    return bundleObjects.length && bundleObjects.every(({id: bundleId}) => {
        const bundle = bundles.get(bundleId)
        const route = routes.get(bundle.routeId)
        const bikeProducts = bundle.items.filter(({productCode}) => productCode === PRODUCT_CODE_BIKE)
        return bikeProducts && route.legs.every(({id}) => bikeProducts.find(({legIds}) => legIds.includes(id)))
    })
}

export const selectedBundlesSelector = state => {
    const selectedBundles = baseSelectedBundlesSelector(state)
    const bundles = baseBundlesSelector(state)
    return selectedBundles
        .map(({id: bundleId}) => bundles.get(bundleId))
        .filter(bundle => Boolean(bundle))
}

export const isValidBundleSelectionSelector = state => {
    const selectedBundles = baseSelectedBundlesSelector(state)
    const bundles = baseBundlesSelector(state)
    return selectedBundles.every(({id: bundleId}) => Boolean(bundles.get(bundleId)))
}

export const totalPriceSelectedBundlesSelector = createSelector(
    [selectedBundlesSelector],
    selectedBundles => selectedBundles.reduce(
        (totalPrice, selectedBundle) => totalPrice + selectedBundle.items
            .filter(item => item.isMainProduct)
            .reduce(
                (amount, item) => amount + item.passengerFares
                    .reduce((value, fare) => value + fare.price, 0),
                0
            ),
        0.0
    )
)

export const inboundBeforeOutboundSelector = createSelector(
    [selectedBundlesSelector, baseRoutesSelector],
    (selectedBundles, routes) => {
        const outbound = selectedBundles.find(bundle => bundle.direction === TRAVEL_DIRECTION_OUTBOUND)
        const inbound = selectedBundles.find(bundle => bundle.direction === TRAVEL_DIRECTION_INBOUND)

        if (inbound && outbound) {
            const inboundRoute = routes.get(inbound.routeId)
            const outboundRoute = routes.get(outbound.routeId)
            return Boolean(
                inboundRoute &&
                outboundRoute &&
                inboundRoute.departureStation.departureTime.isBefore(outboundRoute.arrivalStation.arrivalTime)
            )
        } else {
            return false
        }
    }
)

const selectedBundleSelector = direction => createSelector(
    [selectedBundlesSelector],
    selectedBundles => selectedBundles.find(bundle => bundle.direction === direction) || null
)

export const outboundSelectedBundleSelector = selectedBundleSelector(TRAVEL_DIRECTION_OUTBOUND)
export const inboundSelectedBundleSelector = selectedBundleSelector(TRAVEL_DIRECTION_INBOUND)

export const hasOutboundAndInboundSelectedBundleSelector = createSelector(
    [outboundSelectedBundleSelector, inboundSelectedBundleSelector],
    (outbound, inbound) => Boolean(inbound && outbound)
)

export const outboundDepartureStationDifferentThanInboundArrivalStationSelector = createSelector(
    [outboundSelectedBundleSelector, inboundSelectedBundleSelector, baseRoutesSelector],
    (outbound, inbound, routes) => {
        if (inbound && outbound) {
            const inboundRoute = routes.get(inbound.routeId)
            const outboundRoute = routes.get(outbound.routeId)
            return Boolean(
                inboundRoute &&
                outboundRoute &&
                outboundRoute.departureStation.UICStationCode !== inboundRoute.arrivalStation.UICStationCode
            )
        } else {
            return false
        }
    }
)

export const getNewJourneySearchSelector = (nextTravelDate, direction) => state => {
    const journeySelection = journeySearchFromJourneyResultSelector(state)
    let outboundDate = direction === TRAVEL_DIRECTION_OUTBOUND ? nextTravelDate : journeySelection.outboundDate || null
    let inboundDate = direction === TRAVEL_DIRECTION_INBOUND ? nextTravelDate : journeySelection.inboundDate || null

    if (direction === TRAVEL_DIRECTION_OUTBOUND && inboundDate && inboundDate.isBefore(outboundDate)) {
        inboundDate = outboundDate.clone()
    }

    if (direction === TRAVEL_DIRECTION_INBOUND && inboundDate && inboundDate.isBefore(outboundDate)) {
        outboundDate = inboundDate.clone()
    }

    return {
        ...journeySelection,
        currency: currencySelector(state),
        outboundDate: outboundDate ? moment(outboundDate).toDate() : null,
        inboundDate: inboundDate ? moment(inboundDate).toDate() : null
    }
}

const directionSummarySelector = direction => createSelector(
    [selectedBundlesSelector, baseRoutesSelector], (selectedBundles, routes) => {
        const bundle = selectedBundles.find(bundle => bundle.direction === direction)
        const route = routes.get(bundle.routeId)

        return {
            departureStation: route.departureStation,
            arrivalStation: route.arrivalStation
        }
    }
)

export const getOutboundOriginDestinationSelector = directionSummarySelector(TRAVEL_DIRECTION_OUTBOUND)

export const getInboundOriginDestinationSelector = directionSummarySelector(TRAVEL_DIRECTION_INBOUND)
