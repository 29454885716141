/* global reach5 */
/* globals S3P_SETTINGS: true */
import Reflux from 'reflux'
import Reach5Store from '../reflux/stores/reach-5-store'
import reach5Login from '../misc/reach-5-login'
import actions from '../reflux/actions'

export default containerId => ({
    mixins: [
        Reflux.connectFilter(Reach5Store, 'reach5', data => data)
    ],

    getInitialState () {
        return {
            reach5ContainerId: containerId || ''
        }
    },

    componentDidMount () {
        if (!reach5.loaded && !window.loadingReach5Script) {
            window.loadingReach5Script = true
            const addScript = document.createElement('script')
            addScript.type = 'text/javascript'
            addScript.crossOrigin = true
            addScript.async = true
            const allScripts = document.getElementsByTagName('script')
            const lastParsedScript = allScripts[allScripts.length - 1]
            addScript.src = `https://${S3P_SETTINGS.reach5.domain}/js/v1/identity.js`
            lastParsedScript.parentNode.appendChild(addScript)
        }

        this.onAuthenticated()
        this.onAuthenticatedFailed()
        this.onSignupdFailed()
    },

    onAuthenticated () {
        reach5('on', 'authenticated', authResult => {
            Reach5Store.onAuthenticateCompleted(authResult)
            if (!authResult.idTokenPayload.email) {
                this.onShowProfileEditor()
            } else {
                reach5Login(authResult.state)
            }
        })
    },

    onAuthenticatedFailed () {
        reach5('on', 'authentication_failed', error => {
            actions.trackEvent('socialAuthentificationFail', {medium_connect: error.error})
            Reach5Store.onAuthenticateFailed()
        })
    },

    onSignupdFailed () {
        reach5('on', 'signup_failed', error => {
            if (error.error === 'email_already_exists') {
                actions.trackEvent('signUpFail', {sign_up_fail: 'email already exists'})
            }
        })
    },

    onShowProfileEditor () {
        reach5('showProfileEditor', {
            accessToken: Reach5Store.getTokenInformation().accessToken,
            container: this.state.reach5ContainerId,
            fields: [
                'email'
            ],
            onSuccess: () => {
                reach5('refreshTokens', {
                    accessToken: Reach5Store.getTokenInformation().accessToken
                }, (err, data) => {
                    if (!err) {
                        Reach5Store.onAuthenticateCompleted(data)
                    }
                })
            }
        })
    },

    getDefaultOptions () {
        return {
            auth: {
                responseType: 'token',
                redirectUri: window.location.href.split('/').slice(0, 3).join('/') + '/fr-FR/login/callback',
                state: window.location.pathname // use state param for redirect url
            },
            signupFields: ['email', 'password'],
            theme: {
                primaryColor: '#e11880',
                socialButton: {
                    inline: true,
                    textVisible: true
                }
            }
        }
    },

    onShowEmail () {
        reach5('showAuth', {
            container: this.state.reach5ContainerId,
            ...this.getDefaultOptions()
        })
    },

    onShowSocial () {
        reach5('showSocialLogin', {
            container: this.state.reach5ContainerId,
            ...this.getDefaultOptions()
        })
    },

    onShowOnlyEmail (containerId) {
        reach5('showAuth', {
            container: containerId,
            socialProviders: [],
            ...this.getDefaultOptions()
        })
    }
})
