import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Tether from 'tether'
import RenderOutsideApp from './render-outside-app'
import WatchDOM from './watch-dom'

export default class TetherComponent extends Component {
    static propTypes = {
        target: PropTypes.any,
        attachment: PropTypes.string,
        targetAttachment: PropTypes.string,
        constraints: PropTypes.oneOfType([PropTypes.func, PropTypes.array]),
        renderPlaceholder: PropTypes.bool,
        DOMWatcher: PropTypes.func,
        classes: PropTypes.any
    }

    static defaultProps = {
        constraints: [{
            to: 'window',
            attachment: 'together',
            pin: true
        }]
    }

    constructor (...args) {
        super(...args)
        this.handleUpdate = this.handleUpdate.bind(this)
    }

    componentDidMount () {
        if (this.props.target) {
            this.init(typeof this.props.target === 'function' ? this.props.target() : this.props.target)
        }
    }

    componentWillUnmount () {
        if (this.tether) {
            this.tether.destroy()
            this.tether = null
        }
        if (this.watchDOM) {
            this.watchDOM.stop()
            this.watchDOM = null
        }
    }

    init (target) {
        const {attachment, renderPlaceholder, targetAttachment, constraints, classes, DOMWatcher} = this.props
        const options = {
            target,
            element: this.element.element,
            attachment: attachment || (renderPlaceholder ? 'center center' : 'bottom center'),
            targetAttachment: targetAttachment || (renderPlaceholder ? 'center center' : 'top center'),
            targetModifier: 'visible',
            constraints: typeof constraints === 'function' ? constraints() : constraints
        }
        if (classes) {
            options.classes = classes
        }
        this.tether = new Tether(options)
        this.tether.position()

        if (DOMWatcher) {
            this.watchDOM = new WatchDOM(DOMWatcher)
            this.watchDOM.observe(this.tether.position)
        }
    }

    handleUpdate () {
        this.tether && this.tether.position()
    }

    render () {
        return (
            <RenderOutsideApp
                containerClass='tether'
                renderPlaceholder={this.props.renderPlaceholder}
                ref={element => {
                    this.element = element
                }}
                handleUpdate={this.handleUpdate}
            >
                {this.props.children}
            </RenderOutsideApp>
        )
    }
}
