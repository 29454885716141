import React, {Component} from 'react'
import PropTypes from 'prop-types'
import device from '../../device'
import {withRouter} from 'react-router'
import _t from '../../translate'
import BookingSpecificationPanelMobile from './panel-mobile'
import BookingSpecificationPanelDesktop from './panel-desktop'
import TariffSegmentSummary from './panel/tariff-segment-summary'
import Rebooking from './panel/rebooking'
import TotalPrice from './panel/total-price'
import BookingNumber from './panel/booking-number'
import AdditionalCosts from './panel/additional-costs'
import AmountDue from './panel/amount-due'
import ApplicableFee from './panel/applicable-fee'
import ManageVouchers from './panel/manage-vouchers'
import BookingModel from '../../models/booking-model'
import cmsComponent from '../cms/cms-component'
import {
    PAYMENT_STATUS_F,
    PAYMENT_METHOD_VOUCHER,
    PAYMENT_STATUS_P, PRODUCT_CODE_ALSA, PRODUCT_CODE_NEL
} from '../../constants'
import TextLabel from '../../element/text-label'

const cmsBlockNames = [
    'BOOKING-SS-UNFLEX-MARKET',
    'BOOKING-STATIC-STATIC_2-MARKET',
    'BOOKING-SS-FLEX-CONDITIONS'
]

class BookingPanel extends Component {
    static propTypes = {
        afterSales: PropTypes.bool,
        afterSalesOperation: PropTypes.string,
        booking: PropTypes.instanceOf(BookingModel).isRequired,
        orientation: PropTypes.bool,
        hideBookingNumber: PropTypes.bool,
        insuranceSelected: PropTypes.bool,
        router: PropTypes.object
    }

    static defaultProps = {
        afterSales: false,
        orientation: false
    }

    constructor (...args) {
        super(...args)
        this._renderDesktop = this._renderDesktop.bind(this)
        this._renderMobile = this._renderMobile.bind(this)
        this._shouldRenderManageVouchers = this._shouldRenderManageVouchers.bind(this)
        this._renderContent = this._renderContent.bind(this)
        this._renderPayments = this._renderPayments.bind(this)
        this._renderPaymentsList = this._renderPaymentsList.bind(this)
        this._renderBookingNumber = this._renderBookingNumber.bind(this)
        this._renderAdditionalCosts = this._renderAdditionalCosts.bind(this)
        this._renderRebooking = this._renderRebooking.bind(this)
    }

    render () {
        return device.isDesktopOrTablet() ? this._renderDesktop() : this._renderMobile()
    }

    _renderDesktop () {
        return (
            <BookingSpecificationPanelDesktop afterSales={this.props.afterSales}>
                {this._renderContent()}
            </BookingSpecificationPanelDesktop>
        )
    }

    _renderMobile () {
        return (
            <BookingSpecificationPanelMobile
                afterSales={this.props.afterSales}
                renderManageVouchers={this._shouldRenderManageVouchers()}
                booking={this.props.booking}
                insuranceSelected={this.props.insuranceSelected}
            >
                {this._renderContent()}
            </BookingSpecificationPanelMobile>
        )
    }

    _shouldRenderManageVouchers () {
        return (
            this.props.router.isActive(`/${_t.getLocales()}/booking/passenger-details`) ||
            this.props.router.isActive(`/${_t.getLocales()}/booking/options`) ||
            this.props.router.isActive(`/${_t.getLocales()}/booking/customer-details`) ||
            this.props.router.isActive(`/${_t.getLocales()}/booking/payment`)
        )
    }

    _renderContent () {
        const booking = this.props.booking

        let outboundSegments = booking.outboundSegmentCollection.withRequiredProducts()
        let inboundSegments = booking.inboundSegmentCollection.withRequiredProducts()

        const isAlsaProduct = booking.products.some(product => !product.cancelled && product.code === PRODUCT_CODE_ALSA)
        const isNelProduct = booking.products.some(product => !product.cancelled && product.code === PRODUCT_CODE_NEL)

        if (this.props.afterSales) {
            outboundSegments = outboundSegments.withoutCancelled()
            inboundSegments = inboundSegments.withoutCancelled()
        }
        const renderManageVouchers = this._shouldRenderManageVouchers()
        const showPriceToBePaid = (this.props.afterSales && booking.totalPriceToBePaid) ||
            booking.pendingPayments.length > 0

        return (
            <div className='content'>
                <TariffSegmentSummary
                    direction='outbound'
                    segments={outboundSegments}
                />
                <TariffSegmentSummary
                    direction='inbound'
                    segments={inboundSegments}
                />
                <div className='journey-specification totals'>
                    {this._renderRebooking(booking)}
                    {this._renderPayments(booking)}
                    {this._renderApplicableFee(booking)}
                    {!showPriceToBePaid && !this.props.afterSales && !device.isMobile() ? (
                        <TotalPrice
                            totalPrice={booking.totalPrice}
                            passengers={booking.passengers.values().value()}
                            inboundSegments={inboundSegments}
                            outboundSegments={outboundSegments}
                            insuranceProduct={booking.insuranceProduct}
                            fees={this.props.afterSales ? booking.applicableFeePrice : booking.allFeesPrice}
                            insuranceSelected={this.props.insuranceSelected}
                            isAlsaProduct={isAlsaProduct}
                            isNelProduct={isNelProduct}
                        />
                    ) : null}
                    {!renderManageVouchers ? this._renderAdditionalCosts(booking) : null}
                    {showPriceToBePaid ? <AmountDue amount={booking.totalPriceToBePaid} /> : null}
                    {this._renderBookingNumber(booking)}
                    {renderManageVouchers && !device.isMobile() ? <ManageVouchers /> : null}
                </div>
            </div>
        )
    }

    _renderPayments (booking) {
        const payments = booking.payments.reduce((filtered, payment) => {
            if (payment.payment_status === PAYMENT_STATUS_F) {
                return filtered
            }

            const voucher = booking.vouchers.find(voucher => voucher.payment_id === payment.id)
            if (payment.method === PAYMENT_METHOD_VOUCHER && voucher) {
                filtered.push({
                    ...payment,
                    amount: voucher.amount
                })
            } else {
                filtered.push(payment)
            }

            return filtered
        }, [])
        return (
            payments.length
                ? (
                    <div>
                        <TextLabel text={_t.getIntlMessage('booking-specification.payments.title')} />
                        {this._renderPaymentsList(payments)}
                    </div>
                ) : null
        )
    }

    _renderPaymentsList (payments) {
        return (
            payments.map(
                (payment, idx) => {
                    let amount = _t.formatCurrency(-payment.amount)
                    amount += payment.payment_status === PAYMENT_STATUS_P && payment.method !== PAYMENT_METHOD_VOUCHER
                        ? ` ${_t.formatIntlMessage('booking-specification.payment.status-pending')}`
                        : ''

                    const text = payment.description ||
                      _t.safeFormatIntlMessage(`payment.method-names.${payment.method.toLowerCase()}`) ||
                      _t.formatIntlMessage('payment.method-names.other')

                    return (
                        <div key={idx} className='journey-payments'>
                            <div className='grid-row'>
                                <div className='grid-column--2-3'>
                                    <span className='text-label journey-payments-description'>
                                        <TextLabel text={text} />
                                    </span>
                                </div>
                                <div className='grid-column--1-3'>
                                    <span className='text-label journey-payment-value'>
                                        <TextLabel text={amount} />
                                    </span>
                                </div>
                            </div>
                        </div>
                    )
                }
            )

        )
    }

    _renderBookingNumber (booking) {
        return this.props.orientation || this.props.hideBookingNumber
            ? null
            : <BookingNumber bookingNumber={booking.bookingNumber} />
    }

    _renderAdditionalCosts (booking) {
        return <AdditionalCosts payments={booking.pendingPayments} vouchers={booking.vouchers} />
    }

    _renderApplicableFee (booking) {
        const applicableFeePrice = this.props.afterSales ? booking.applicableFeePrice : booking.allFeesPrice

        return applicableFeePrice > 0
            ? <ApplicableFee price={applicableFeePrice} afterSales={this.props.afterSales} /> : null
    }

    _renderRebooking (booking) {
        const amountTicketsAdded = booking.amountAddedTickets
        const amountTicketsReplaced = booking.amountReplacedTickets
        const amountCancelledFees = booking.amountProvisionalCancelledFees

        if (this.props.afterSalesOperation !== 'rebook' ||
            amountTicketsAdded < 0 ||
            amountTicketsReplaced < 0
        ) {
            return null
        }

        return (
            <Rebooking
                amountCancelledFees={amountCancelledFees}
                amountTicketsAdded={amountTicketsAdded}
                amountTicketsReplaced={amountTicketsReplaced}
            />
        )
    }
}

export default withRouter(cmsComponent(...cmsBlockNames)(BookingPanel))
