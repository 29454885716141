import { createSelector } from 'reselect'
import { outboundTravelSelector, inboundTravelSelector } from './travel'
import { passengersSelector } from './passenger'

export const journeySearchFromJourneyResultSelector = createSelector([
  passengersSelector,
  outboundTravelSelector,
  inboundTravelSelector
], (passengers, outboundTravel, inboundTravel) => outboundTravel || inboundTravel ? ({
  passengers: passengers.map(passenger => ({
    type: passenger.type,
    disabilityType: passenger.disabilityType,
    discountCards: passenger.discountCards || []
  })),
  origin: (outboundTravel || inboundTravel).originStation.UICStationCode,
  destination: (outboundTravel || inboundTravel).destinationStation.UICStationCode,
  outboundDate: outboundTravel && outboundTravel.departureDate,
  inboundDate: inboundTravel && inboundTravel.departureDate
}) : null)
