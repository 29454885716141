import actions from '../actions'
import Reflux from 'reflux'
import get from 'lodash/get'
import pick from 'lodash/pick'
import isEqual from 'lodash/isEqual'

export const DISCOUNT_CARD_ERROR_CODE_CARD_EXPIRED = 'card_expired'
export const DISCOUNT_CARD_ERROR_CODE_CARD_NOT_ENABLED = 'card_not_enabled'
export const DISCOUNT_CARD_ERROR_CODE_NAME_DOES_NOT_NATCH = 'name_does_not_match'
export const DISCOUNT_CARD_ERROR_CODE_BIRTH_DATE_DOES_NOT_MATCH = 'birth_date_does_not_match'
export const DISCOUNT_CARD_ERROR_CODE_CARD_NOT_EXIST = 'card_not_exist'

// Map propertyPath property from json response to passenger field name.
const propertyPathToFieldName = {
    cardHolderBirthDate: 'dateOfBirth',
    cardHolderFirstName: 'firstName',
    cardHolderLastName: 'lastName',
    gender: 'gender',
    cardNumber: 'discountCardNumber'
}

// Possible error codes
const CHECK_DISCOUNT_CARD_ERROR_CODES = [
    DISCOUNT_CARD_ERROR_CODE_CARD_EXPIRED,
    DISCOUNT_CARD_ERROR_CODE_CARD_NOT_ENABLED,
    DISCOUNT_CARD_ERROR_CODE_NAME_DOES_NOT_NATCH,
    DISCOUNT_CARD_ERROR_CODE_BIRTH_DATE_DOES_NOT_MATCH,
    DISCOUNT_CARD_ERROR_CODE_CARD_NOT_EXIST
]

// Will be shown in modal
const HANDLED_ERRORS = [
    DISCOUNT_CARD_ERROR_CODE_CARD_EXPIRED,
    DISCOUNT_CARD_ERROR_CODE_CARD_NOT_ENABLED
]

export default Reflux.createStore({
    listenables: actions,

    init () {
        this._resetData()

        this.listenTo(actions.checkDiscountCard, this._startLoading)
        this.listenTo(actions.checkDiscountCard.completed, this._onComplete)
        this.listenTo(actions.checkDiscountCard.failed, this._setError)
        this.listenTo(actions.cancelBooking.completed, this.resetData)
        this.listenTo(actions.confirmBooking.completed, this.resetData)
    },

    _resetData () {
        this.data = {
            loading: false
        }
    },

    resetData () {
        this._resetData()
        this.trigger(this.data)
    },

    _onComplete (data) {
        this.data[data.passengerId] = data
        // There still can be additional checks loading.
        this.data.loading = Object.values(this.data).some(({loading}) => loading)
        this.trigger(this.data)
    },

    _startLoading (_, passengerId) {
        this.data[passengerId] = {
            loading: true
        }
        this.data.loading = true
        this.trigger(this.data)
    },

    isLoading () {
        return Boolean(this.data.loading)
    },

    /**
     * Filter out the response of a single passenger, clearing any errors and notices.
     * @param passengerId
     */
    resetPassenger (passengerId) {
        this.data = Object.values(this.data).reduce((newData, data) => {
            if (passengerId !== data.passengerId) {
                newData[data.passengerId] = data[data.passengerId]
            }
            return newData
        }, {})

        this.trigger(this.data)
    },

    /**
     * @typedef {Object} Violation
     * @property {String} propertyPath
     * @property {String} code
     * @property {String} level
     * @property {String} message
     */

    /**
     * Error callback after checkDiscountCard fails
     * @param {Error} error
     * @param {String} error.data.passengerId
     * @param {Object} error.data.request
     * @param {String} error.data.request.cardNumber
     * @param {String} error.data.request.cardHolderFirstName
     * @param {String} error.data.request.cardHolderLastName
     * @param {String} error.data.request.cardHolderBirthDate
     * @param {String} error.data.request.outboundDate
     * @param {String} error.data.response.title
     * @param {String} error.data.response.description
     * @param {Violation[]} error.data.response.violations
     * @private
     */
    _setError (error) {
        this.data[error.data.passengerId] = error.data
        this.data.loading = Object.values(this.data).some(({loading}) => loading)
        this.trigger(this.data)
    },

    /**
     * Get all violations from all passengers
     * @returns {Violation[]}
     * @private
     */
    _getViolations () {
        return Object.values(this.data).reduce((violations, data) => [...violations, ...get(data, 'response.violations', [])], [])
    },

    /**
     * Get all error codes from all passengers
     * @returns {String[]}
     */
    getViolationCodes () {
        return this._getViolations().map(({code}) => this._getCode(code))
    },

    /**
     * Has any error codes?
     * @returns {boolean}
     */
    hasViolationCodes () {
        return Boolean(this._getViolations().length)
    },

    /**
     * Get the last part of the violation error code gotten from checkDiscount api call
     * @param code
     * @returns {String[]}
     * @private
     */
    _getCode (code) {
        const codeParts = code.split('.')
        return codeParts[codeParts.length - 1]
    },

    /**
     * Get all violation error codes of a given passenger
     * @param passengerId
     * @returns {Object}
     * @private
     */
    _getPassengerViolations (passengerId) {
        const violations = get(this.data, `${passengerId}.response.violations`, [])
        return violations.reduce((errors, violation) => {
            const fieldName = propertyPathToFieldName[violation.propertyPath]

            errors[fieldName || violation.propertyPath] = this._getCode(violation.code)

            // Add firstName errors also to lastName
            if (fieldName === 'firstName') {
                errors['lastName'] = this._getCode(violation.code)
            }

            return errors
        }, {})
    },

    /**
     * Return all errors of a given passenger, mapped on passengerFieldName
     * @param passengerId
     * @returns {Object}
     */
    getPassengerErrors (passengerId) {
        const violations = this._getPassengerViolations(passengerId)
        return Object.entries(violations).reduce((errors, [fieldName, errorCode]) => {
            if (!HANDLED_ERRORS.includes(errorCode)) {
                errors[fieldName] = errorCode
            }
            return errors
        }, {})
    },

    hasPassengerViolations (passengerId) {
        return Boolean(get(this.data, `${passengerId}.response.violations`, []).length)
    },

    /**
     * Check if the given errorCode is an error that can be gotten from checkDiscountCard api call.
     * @param errorCode
     * @returns {boolean}
     */
    isCheckDiscountCardError (errorCode) {
        return CHECK_DISCOUNT_CARD_ERROR_CODES.includes(errorCode)
    },

    /**
     * Returns if any of the passengers have a disabled card
     * @returns {boolean}
     */
    isCardDisabled () {
        return this.getViolationCodes().includes(DISCOUNT_CARD_ERROR_CODE_CARD_NOT_ENABLED)
    },

    /**
     * Returns if any of the passengers have an expired card
     * @returns {boolean}
     */
    isCardExpired () {
        return this.getViolationCodes().includes(DISCOUNT_CARD_ERROR_CODE_CARD_EXPIRED)
    },

    /**
     * Compares a passenger request with the one in the store. Returns true if they're equal.
     * Used to determine if the passenger's code and info is already checked by Jollicode.
     * @param {string} passengerId
     * @param {Object} request
     * @returns {boolean}
     */
    hasCheckedPassenger (passengerId, request) {
        const passengerRequest = pick(this.data[passengerId].request, Object.keys(request))
        return Boolean(this.data[passengerId]) && isEqual(request, passengerRequest)
    }
})
