import React, {Component} from 'react'
import PropTypes from 'prop-types'
import JourneySummary from './segment-information/journey-summary'
import SegmentModel from '../../models/segment-model'

export default class SegmentInformation extends Component {
    static propTypes = {
        tariffSegment: PropTypes.object.isRequired
    }

    render () {
        const segment = new SegmentModel(this.props.tariffSegment)

        return (
            <tr className='segment-information'>
                <td colSpan='3'>
                    <JourneySummary tariffSegment={segment}>
                        {this.props.children}
                    </JourneySummary>
                </td>
            </tr>
        )
    }
}
