import PropTypes from 'prop-types'
import React, {Component} from 'react'
import kebabCase from 'lodash/kebabCase'
import _t from '../../../translate'
import TextLabel from '../../../element/text-label'
import TextField from '../../../element/form/text-field'
import EmailField from '../../../element/form/email-field'
import TelephoneField from '../../../element/form/telephone-field'
import SelectField from '../../../element/form/select-field'
import RadioGroup from '../../../element/form/radio-group'
import {
    CUSTOMER_TYPE_PAY_ONLY,
    NEWSLETTER_OPTIN
} from '../../../constants'
import actions from '../../../reflux/actions'
import OptOutOption from '../newsletter/opt-out-option'
import BookingStore from '../../../reflux/stores/booking-store'
import DOMPurify from 'dompurify'
import debounce from 'lodash/debounce'

const PropTypeField = PropTypes.shape({
    value: PropTypes.string.isRequired,
    errorText: PropTypes.string,
    isRequired: PropTypes.bool.isRequired
})

const PropTypeOptionField = PropTypes.shape({
    value: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({
        text: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired
    })).isRequired,
    errorText: PropTypes.string,
    isRequired: PropTypes.bool
})

export default class CustomerDetailsForm extends Component {
    static propTypes = {
        selectedPassengerId: PropTypes.string,
        crmEmailNotSet: PropTypes.bool.isRequired,
        crmFullNameNotSet: PropTypes.bool.isRequired,
        formData: PropTypes.shape({
            isValid: PropTypes.bool.isRequired,
            errorText: PropTypes.string,
            fields: PropTypes.shape({
                gender: PropTypeOptionField,
                firstName: PropTypeField.isRequired,
                lastName: PropTypeField.isRequired,
                emailAddress: PropTypeField.isRequired,
                repeatEmailAddress: PropTypeField,
                phone: PropTypeField.isRequired,
                countryCode: PropTypeOptionField.isRequired
            }).isRequired
        }).isRequired,
        additionalDetails: PropTypes.array,
        crmUser: PropTypes.object
    }

    constructor (...args) {
        super(...args)
        const additionalDetail = this.getOptInAdditionalDetail()

        this.state = {
            [NEWSLETTER_OPTIN]: !additionalDetail || additionalDetail.value !== '0',
            additionalDetailsLoading: false,
            bookingNumber: BookingStore.getBookingNumber()
        }
        this._emailIsAutoFilled = this._emailIsAutoFilled.bind(this)
        this._renderFormError = this._renderFormError.bind(this)
        this.onToggleOptIn = this.onToggleOptIn.bind(this)
        this.updateAdditionalDetails = this.updateAdditionalDetails.bind(this)
        this.selectFieldProps = this.selectFieldProps.bind(this)
        this.radioGroupProps = this.radioGroupProps.bind(this)
        this.textFieldProps = this.textFieldProps.bind(this)
        this.baseFieldProps = this.baseFieldProps.bind(this)
        this.getOptInAdditionalDetail = this.getOptInAdditionalDetail.bind(this)
    }

    getOptInAdditionalDetail () {
        return this.props.additionalDetails.find(additionalDetail => additionalDetail.key === NEWSLETTER_OPTIN)
    }

    componentDidMount () {
        this.updateAdditionalDetails()
    }

    autoFillEmail (checked) {
        const emailAddress = checked ? 'station@ouibus.com' : ''
        actions.processCustomerInfoData([{id: 'emailAddress', value: emailAddress}])
    }

    _emailIsAutoFilled () {
        return this.props.formData.fields.emailAddress.value === 'station@ouibus.com'
    }

    _renderFormError () {
        const {errorText} = this.props.formData
        if (!errorText || errorText.length === 0) {
            return null
        }

        return (
            <div className='feedback warning'>
                <span className='label feedback-message'>
                    {errorText}
                </span>
            </div>
        )
    }

    onToggleOptIn () {
        this.setState(
            {[NEWSLETTER_OPTIN]: !this.state[NEWSLETTER_OPTIN]},
            this.updateAdditionalDetails
        )
    }

    async updateAdditionalDetails () {
        if (!this.state.additionalDetailsLoading) {
            const additionalDetailOptInItem = this.getOptInAdditionalDetail()
            const value = this.state[NEWSLETTER_OPTIN] ? '1' : '0'
            if ((additionalDetailOptInItem || {}).value !== value) {
                this.setState({additionalDetailsLoading: true})
                const additionalDetails = [{key: NEWSLETTER_OPTIN, value}]

                const action = additionalDetailOptInItem ? actions.updateAdditionalDetails : actions.addAdditionalDetails
                await action(this.state.bookingNumber, additionalDetails)

                this.setState({additionalDetailsLoading: false})
            }
        }
    }

    render () {
        const {crmFullNameNotSet, crmEmailNotSet, formData, selectedPassengerId} = this.props
        const {gender, repeatEmailAddress, countryCode} = formData.fields

        return (
            <form action='#' noValidate>
                {this._renderFormError()}
                {selectedPassengerId === CUSTOMER_TYPE_PAY_ONLY && crmFullNameNotSet
                    ? (
                        <fieldset>
                            <div className='grid-row'>
                                {gender
                                    ? (
                                        <div className='grid-column--1-1 grid-column--medium-1-1'>
                                            <RadioGroup
                                                className='gender'
                                                data={gender.options}
                                                {...this.radioGroupProps('gender')}
                                            />
                                        </div>
                                    ) : null}
                                <div className='grid-column--1-1 grid-column--medium-1-2'>
                                    <TextField className='first-name' {...this.textFieldProps('firstName')} />
                                </div>
                                <div className='grid-column--1-1 grid-column--medium-1-2'>
                                    <TextField className='last-name' {...this.textFieldProps('lastName')} />
                                </div>
                                <div className='grid-column--1-1'>
                                    <EmailField
                                        readOnly={!crmEmailNotSet}
                                        className='email-address'
                                        {...this.textFieldProps('emailAddress')}
                                    />
                                </div>
                                {repeatEmailAddress
                                    ? (
                                        <div className='grid-column--1-1'>
                                            <EmailField
                                                className='email-address'{...this.textFieldProps('repeatEmailAddress')} />
                                        </div>
                                    ) : null}
                            </div>
                        </fieldset>
                    ) : (
                        <fieldset>
                            <TextLabel
                                className='text-label header'
                                text={_t.getIntlMessage('personal-information-form.content-header')}
                            />
                            <div className='grid-row'>
                                <div className='grid-column--1-1 grid-column--medium-2-3'>
                                    <EmailField
                                        className='email-address'
                                        required={false}
                                        readOnly={!crmEmailNotSet}
                                        {...this.textFieldProps('emailAddress')}
                                    />
                                </div>
                            </div>
                        </fieldset>
                    )}
                <fieldset>
                    <OptOutOption
                        checked={!this.state[NEWSLETTER_OPTIN]}
                        disabled={this.state.additionalDetailsLoading}
                        onClick={debounce(this.onToggleOptIn, 200)}
                    />
                </fieldset>
                <fieldset>
                    <TextLabel
                        className='text-label header'
                        text={_t.getIntlMessage('personal-information-form.sms-alert.title')}
                    />
                    <TextLabel
                        id={selectedPassengerId ? 'sms-alert' : null}
                        className='text-label description'
                        text={_t.getIntlMessage('personal-information-form.sms-alert.content')}
                    />
                    <div className='grid-row'>
                        <div className='grid-column--1-2 grid-column--medium-3-7'>
                            <SelectField
                                className='countryCode'
                                id='countryCode'
                                data={countryCode.options}
                                {...this.selectFieldProps('countryCode')}
                            />
                        </div>
                        <div className='grid-column--1-2 grid-column--medium-4-7 phone-number-container'>
                            <TelephoneField className='phone-number' {...this.textFieldProps('phone')} />
                        </div>
                    </div>
                </fieldset>
            </form>
        )
    }

    baseFieldProps (fieldName) {
        const _fieldName = kebabCase(fieldName)
        return {
            id: fieldName,
            ref: fieldName,
            required: this.props.formData.fields[fieldName].isRequired,
            errorText: this.prefixFieldLabel(_fieldName, this.props.formData.fields[fieldName].errorText)
        }
    }

    textFieldProps (fieldName) {
        const _fieldName = kebabCase(fieldName)
        return {
            ...this.baseFieldProps(fieldName),
            labelClassName: '',
            labelText: this.prefixFieldLabel(_fieldName, 'placeholder'),
            floatingLabel: true,
            value: this.props.formData.fields[fieldName].value,
            onChange: this.onBlur
        }
    }

    radioGroupProps (fieldName) {
        return {
            ...this.baseFieldProps(fieldName),
            selectedValue: this.props.formData.fields[fieldName].value,
            onChange: this.onRadioChecked
        }
    }

    selectFieldProps (fieldName) {
        return {
            ...this.baseFieldProps(fieldName),
            labelClassName: '',
            value: this.props.formData.fields[fieldName].value,
            onChange: this.onBlur
        }
    }

    prefixFieldLabel (field, label) {
        return label ? `personal-information-form.${field}.${label}` : null
    }

    onBlur (event) {
        actions.processCustomerInfoData([{
            id: event.target.id,
            value: event.target.id === 'lastName' || event.target.id === 'firstName' ? DOMPurify.sanitize(event.target.value) : event.target.value
        }])
    }

    onCheckboxClicked (event) {
        actions.processCustomerInfoData([{
            id: event.target.id,
            value: event.target.checked
        }])
    }

    onRadioChecked (id, value) {
        actions.processCustomerInfoData([{
            id: id,
            value: value
        }])
    }
}
