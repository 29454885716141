import PropTypes from 'prop-types'
import React, { Component } from 'react'
import BikeSelector from '../../../components/bike-selector/bike-selector'
import RebookingLayoutContainer from '../../re-booking-layout-container'
import restoreJourneySearch from '../../../components/restore/restore-journey-search'
import restoreBooking from '../../../components/restore/restore-booking'

const component = class BikeSelectorLayout extends Component {
    static propTypes = {
        location: PropTypes.object
    }

    render () {
        return (
            <RebookingLayoutContainer name='bike-selection-container'>
                <BikeSelector returnUrl='/mys3/re-booking/options' />
            </RebookingLayoutContainer>
        )
    }
}

export default restoreJourneySearch(
    restoreBooking(component, {withBlockingLoader: true}),
    {withBlockingLoader: true}
)
