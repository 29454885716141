import PropTypes from 'prop-types'
import React from 'react'
import _ from 'lodash'
import _t from '../translate'
import SegmentInformation from './booking-information/segment-information'
import SegmentInboundOutbound from './booking-information/segment-inbound-outbound'
import SegmentProduct from './booking-information/segment-product-information'
import SegmentFees from './booking-information/segment-fees'
import BookingModel from '../models/booking-model'

export default Component =>
    class BookingInformation extends React.Component {
        static propTypes = {
            booking: PropTypes.instanceOf(BookingModel)
        }

        constructor (...args) {
            super(...args)
            this._content = this._content.bind(this)
        }

        render () {
            return (
                <Component booking={this.props.booking}>
                    {this._content()}
                </Component>
            )
        }

        _content () {
            const productMapper = (key, product, required, quantity = 1) => (
                <SegmentProduct
                    key={key}
                    product={{...product, required, quantity}}
                    passenger={getPassengerById(product.passenger_id)}
                />
            )

            const getPassengerById = passengerId => this.props.booking.passengersWithCancelled.find(
                passenger => passenger.id === passengerId
            )

            const segmentInformationAndProducts = (tariffSegment, key) => {
                const requiredProducts = tariffSegment.required_products.filter(product => !product.cancelled)
                const additionalProducts = tariffSegment.additional_products.filter(product => !product.cancelled)

                const children = [
                    requiredProducts.map(
                        (product, index) => productMapper(`product_${index}`, product, true)
                    ),
                    _(additionalProducts)
                        .groupBy(product => `${product.passenger_id}|${product.code}|${product.cancelled}`)
                        .map((products, index) => {
                            const product = products[0]
                            return productMapper(
                                `addon_${index}`,
                                product,
                                false,
                                products.length
                            )
                        }).value()
                ]

                return (
                    <SegmentInformation
                        key={`segment_${key}`}
                        tariffSegment={tariffSegment}>
                        <table>
                            <colgroup>
                                <col className='col-product' />
                                <col className='col-passenger-type' />
                            </colgroup>
                            <tbody>
                                {children}
                            </tbody>
                        </table>
                    </SegmentInformation>
                )
            }

            const booking = this.props.booking
            const outboundSegmentCollection = booking.outboundSegmentCollection.withoutCancelled()
            const inboundSegmentCollection = booking.inboundSegmentCollection.withoutCancelled()

            let data = []
            data.push(
                <SegmentInboundOutbound
                    key='outbound-header'
                    type='outbound'
                    header={_t.getIntlMessage('booking-information.outbound-header')}
                />,
                _(outboundSegmentCollection.getRawData()).map(segmentInformationAndProducts).value()
            )

            if (inboundSegmentCollection.length > 0) {
                data.push(
                    <SegmentInboundOutbound
                        key='inbound-header'
                        type='inbound'
                        header={_t.getIntlMessage('booking-information.inbound-header')}
                    />,
                    _(inboundSegmentCollection.getRawData()).map(segmentInformationAndProducts).value()
                )
            }

            const feesPaid = booking.getFeesPaid().value()
            const feesToBePaid = booking.getFeesToBePaid().value()
            if (feesPaid.length > 0) {
                data.push(<SegmentFees key='segment_fees_paid' paid fees={feesPaid} />)
            }
            if (feesToBePaid.length > 0) {
                data.push(<SegmentFees key='segment_fees_to_be_paid' paid={false} fees={feesToBePaid} />)
            }

            return data
        }
    }
