import PropTypes from 'prop-types'
import React from 'react'
import {withRouter} from 'react-router'
import Reflux from 'reflux'
import moment from 'moment-timezone'
import Heading from '../../../../element/heading'
import PassengerRequireDateOfBirth from './passenger-require-date-of-birth'
import Notice from './notice'
import PassengerFields from './passenger-fields'
import SelectFavoritePassenger from './select-favorite-passenger'
import AccessManager from '../../../../data/access-manager'
import {UPDATE_CRM, NEW_FAVORITE_PASSENGER} from '../../../../constants'
import {goToOuibusSearchPage} from '../../../../misc/new-ouibus-helper'
import actions from '../../../../reflux/actions'
import BookingStore from '../../../../reflux/stores/booking-store'
import FavoritePassengerStore from '../../../../reflux/stores/favorite-passenger-store'
import PassengersDetailsStore from '../../../../reflux/stores/passenger-details-store'
import CrmUserStore from '../../../../reflux/stores/crm-user-store'
import JollicodeDiscountCardStore from '../../../../reflux/stores/jollicode-discount-card-store'
import BookingModel from '../../../../models/booking-model'
import createReactClass from 'create-react-class'

const component = createReactClass({

    propTypes: {
        loading: PropTypes.bool,
        title: PropTypes.string.isRequired,
        passenger: PropTypes.object.isRequired,
        isFirstPassenger: PropTypes.bool.isRequired
    },

    mixins: [
        Reflux.connectFilter(BookingStore, 'booking', data => BookingModel.create(data.booking)),
        Reflux.connectFilter(CrmUserStore, 'crmUser', data => data.user && data.user.customer),
        Reflux.listenTo(PassengersDetailsStore, 'updatePassengerFormData'),
        Reflux.listenTo(FavoritePassengerStore, 'updateFavoritePassengers'),
        Reflux.listenTo(actions.myS3GetFavoritePassengers.completed, 'checkForExistingFavoritePassenger')
    ],

    getInitialState () {
        return {
            showPassengerFields: !AccessManager.isCrmUser(),
            showAddFavoritePassenger: false,
            newFavoritePassenger: {}
        }
    },

    getDefaultProps () {
        return {loading: false}
    },

    componentWillReceiveProps () {
        this.checkForExistingFavoritePassenger()
        if (AccessManager.isCrmUser() && this.props.isFirstPassenger) {
            this.isPassengerCurrentCrmUser()
        }
    },

    componentDidMount () {
        this.setInitialFavoritePassengerSelection()
    },

    setInitialFavoritePassengerSelection () {
        if (!AccessManager.isCrmUser()) {
            return
        }

        if (this.getSelectedPassenger() === '' && this.props.isFirstPassenger && CrmUserStore.hasCustomerInformation()) {
            return this.handleSelectMe()
        } else if (this.getSelectedPassenger() === NEW_FAVORITE_PASSENGER) {
            return this.handleSelectNewFavoritePassenger()
        }
        return this.handleSelectExistingFavoritePassenger()
    },

    updateFavoritePassengers (data) {
        this.setState({
            favoritePassengers: data.favoritePassengers,
            newFavoritePassenger: (data.favoritePassengersChanges || []).find(
                favoritePassenger => favoritePassenger.passenger_id === this.props.passenger.id
            ) || {},
            mePassengerId: data.mePassenger.id
        })
    },

    updatePassengerFormData (data) {
        // Don't update favorite passenger if it's me or I'm not a CRM User.
        if (!AccessManager.isCrmUser() || this.getSelectedPassenger() === UPDATE_CRM) {
            return null
        }

        // Find the favorite passenger, and update it's values based on the field values.
        const passenger = this.props.passenger
        const favoritePassenger = this.state.newFavoritePassenger
        favoritePassenger.passenger_id = this.props.passenger.id
        favoritePassenger.first_name = passenger.fields.firstName.value
        favoritePassenger.last_name = passenger.fields.lastName.value
        favoritePassenger.birth_date = passenger.fields.dateOfBirth.value
        favoritePassenger.type = passenger.type
        favoritePassenger.gender = passenger.fields.gender && passenger.fields.gender.value
        actions.processFavoritePassenger(favoritePassenger)
        this.clearDiscountCardErrorsAndNotifications(data)
    },

    render () {
        return (
            <div className='content'>
                <Heading title={this.props.title} />
                {this.renderPassenger()}
            </div>
        )
    },

    getSelectedPassenger () {
        const passenger = this.props.passenger
        return (
            passenger &&
            passenger.fields.crmSelect &&
            passenger.fields.crmSelect.value
        )
    },

    renderPassenger () {
        const passenger = this.props.passenger
        const dateOfBirthNotice = passenger.fields.dateOfBirth.notice

        return (
            <form action='#' noValidate key={passenger.id}>
                <PassengerRequireDateOfBirth passenger={passenger} />
                <fieldset>
                    {AccessManager.isCrmUser() && this.state.crmUser
                        ? <SelectFavoritePassenger
                            passenger={this.props.passenger}
                            isFirstPassenger={this.props.isFirstPassenger}
                            favoritePassengers={this.state.favoritePassengers}
                            mePassengerId={this.state.mePassengerId}
                            selectedPassenger={this.getSelectedPassenger()}
                            onSelectedPassenger={this.onSelectedPassenger} />
                        : null
                    }
                    {!this.state.showPassengerFields && this.passengerIsEmpty(passenger)
                        ? null
                        : <PassengerFields
                            passenger={passenger}
                            showAddFavoritePassenger={this.state.showAddFavoritePassenger}
                            newFavoritePassenger={this.state.newFavoritePassenger}
                            onBlur={this.onBlur} />}
                    <Notice dateOfBirthNotice={dateOfBirthNotice} onNewSearch={this.onNewSearch} />
                </fieldset>
            </form>
        )
    },

    onSelectedPassenger (event) {
        const selected = event.target.value
        this.setSelectedPassengerFormData(selected)
    },

    setSelectedPassengerFormData (selected) {
        const passengerId = this.props.passenger.id

        // If 'me' was selected, but now a different value is selected, release 'me' again so it can be selected by other passengers
        if (selected !== UPDATE_CRM && passengerId === this.state.mePassengerId) {
            actions.changeMePassenger(null)
        }

        if (selected === NEW_FAVORITE_PASSENGER) {
            PassengersDetailsStore.resetPassenger(passengerId)
            this.handleSelectNewFavoritePassenger()
        } else if (this.state.favoritePassengers && parseInt(selected, 10)) {
            this.handleSelectExistingFavoritePassenger(parseInt(selected, 10))
            PassengersDetailsStore.validatePassengers()
        } else if (selected === UPDATE_CRM) {
            this.handleSelectMe()
        } else {
            // Reset fields back to default
            this.setState({
                showPassengerFields: false,
                showAddFavoritePassenger: false
            })
        }
    },

    handleSelectMe () {
        if (CrmUserStore.hasUserInformation()) {
            // Reset the first passenger field, clear errors and filled in fields.
            PassengersDetailsStore.resetPassenger(this.props.passenger.id)

            this.setState({
                showPassengerFields: true,
                showAddFavoritePassenger: false
            })

            const customer = CrmUserStore.getCrmCustomerModel()

            // Update the passenger form fields with crm user data.
            // Skip empty fields so we don't trigger validation errors.
            const passengerDetails = [
                {
                    id: `${this.props.passenger.id}.crmSelect`,
                    value: UPDATE_CRM
                }
            ]
            const passengerFields = this.props.passenger.fields
            if (customer.firstName && !passengerFields.firstName.value) {
                passengerDetails.push({
                    id: `${this.props.passenger.id}.firstName`,
                    value: customer.firstName
                })
            }
            if (customer.lastName && !passengerFields.lastName.value) {
                passengerDetails.push({
                    id: `${this.props.passenger.id}.lastName`,
                    value: customer.lastName
                })
            }
            if (customer.dateOfBirth && !passengerFields.dateOfBirth.value) {
                passengerDetails.push({
                    id: `${this.props.passenger.id}.dateOfBirth`,
                    value: moment(customer.dateOfBirth, ['YYYY-MM-DD']).format('DD/MM/YYYY')
                })
            }
            if (customer.gender && passengerFields.gender && !passengerFields.gender.value) {
                passengerDetails.push({
                    id: `${this.props.passenger.id}.gender`,
                    value: customer.gender
                })
            }
            if (customer.discountCardNumber && passengerFields.discountCardNumber && !passengerFields.discountCardNumber.value) {
                passengerDetails.push({
                    id: `${this.props.passenger.id}.discountCardNumber`,
                    value: customer.discountCardNumber
                })
            }
            actions.changeMePassenger(this.props.passenger.id)
            actions.processPassengerDetailsData(passengerDetails)
        }
    },

    clearDiscountCardErrorsAndNotifications (data) {
        if (data.isValid) {
            return null
        }

        // Clear the discountCard errors if the passenger is invalid.
        if (!PassengersDetailsStore.isPassengerByIdInternallyValid(this.props.passenger.id) && JollicodeDiscountCardStore.hasPassengerViolations(this.props.passenger)) {
            JollicodeDiscountCardStore.resetPassenger(this.props.passenger.id)
            actions.clearDiscountCardOfPassenger(this.props.passenger.id)
        }
    },

    handleSelectNewFavoritePassenger () {
        const passenger = this.props.passenger.id

        actions.processFavoritePassenger({passenger_id: passenger})

        this.setState({
            showPassengerFields: true,
            showAddFavoritePassenger: true
        })

        actions.processPassengerDetailsData([
            {
                id: `${passenger}.crmSelect`,
                value: NEW_FAVORITE_PASSENGER
            }
        ])
    },

    handleSelectExistingFavoritePassenger (refId) {
        const passenger = this.props.passenger.id
        const favoritePassenger = (this.state.favoritePassengers || []).find(
            favoritePassenger => favoritePassenger.ref_id === refId
        )

        if (!favoritePassenger) {
            return
        }

        this.setState({
            showPassengerFields: true,
            showAddFavoritePassenger: false
        })

        actions.processFavoritePassenger({passenger_id: passenger, ...favoritePassenger})
        actions.processPassengerDetailsData([
            {
                id: `${passenger}.firstName`,
                value: favoritePassenger.first_name
            },
            {
                id: `${passenger}.lastName`,
                value: favoritePassenger.last_name
            },
            {
                id: `${passenger}.dateOfBirth`,
                value: moment(favoritePassenger.birth_date, ['YYYY-MM-DD', 'DD/MM/YYYY']).format('DD/MM/YYYY')
            },
            {
                id: `${passenger}.gender`,
                value: favoritePassenger.gender || ''
            },
            {
                id: `${passenger}.crmSelect`,
                value: refId
            }
        ])
    },

    onNewSearch (event) {
        event.preventDefault()
        actions.clearOffer()
        goToOuibusSearchPage()
    },

    checkForExistingFavoritePassenger () {
        if (!AccessManager.isCrmUser()) {
            return null
        }

        const existingFavoritePassenger = this.isExistingFavoritePassenger()
        if (
            existingFavoritePassenger &&
            existingFavoritePassenger.ref_id !== this.getSelectedPassenger() &&
            this.getSelectedPassenger() !== UPDATE_CRM
        ) {
            actions.processPassengerDetailsData([
                {
                    id: `${this.props.passenger.id}.crmSelect`,
                    value: existingFavoritePassenger.ref_id
                }
            ])
        }
    },

    isExistingFavoritePassenger () {
        const passenger = this.props.passenger.fields
        return FavoritePassengerStore.isExistingFavoritePassenger({
            first_name: passenger.firstName.value,
            last_name: passenger.lastName.value,
            birth_date: passenger.dateOfBirth.value,
            gender: passenger.gender && passenger.gender.value
        })
    },

    passengerIsEmpty (passenger) {
        const fields = passenger.fields

        return (
            fields.firstName.value === '' &&
            fields.lastName.value === '' &&
            (!fields.gender || fields.gender.value === '')
        )
    },

    _isFieldChanged (passenger, _id, value) {
        const field = Object.values(passenger.fields).find(({id}) => id === _id)
        return field && field.value !== value
    },

    async onBlur (event) {
        const passenger = this.props.passenger
        const hasChanges = this._isFieldChanged(passenger, event.target.id, event.target.value)

        await actions.processPassengerDetailsData([{
            id: event.target.id,
            value: event.target.value
        }])

        if (
            hasChanges &&
            passenger.fields.discountCardNumber &&
            passenger.fields.discountCardNumber.value &&
            PassengersDetailsStore.isPassengerByIdInternallyValid(passenger.id)
        ) {
            const outboundDate = this.state.booking.isReturnTrip() ? this.state.booking.inboundTravelDate : this.state.booking.outboundTravelDate
            actions.checkDiscountCard({
                outboundDate: moment(outboundDate).format('YYYY-MM-DD'),
                cardNumber: passenger.fields.discountCardNumber.value,
                cardHolderFirstName: passenger.fields.firstName.value,
                cardHolderLastName: passenger.fields.lastName.value,
                cardHolderBirthDate: moment(passenger.fields.dateOfBirth.value, ['D/M/YYYY'], true).format('YYYY-MM-DD')
            }, passenger.id)
        }
    },

    isPassengerCurrentCrmUser () {
        const crmUser = this.state.crmUser

        if (this.getSelectedPassenger() === UPDATE_CRM || !crmUser) {
            return
        }

        const passenger = this.props.passenger

        const matchData = [
            (!crmUser.first_name || crmUser.first_name === passenger.fields.firstName.value),
            (!crmUser.lastName || crmUser.last_name === passenger.fields.lastName.value),
            (!crmUser.birth_date || moment(crmUser.birth_date, ['YYYY-MM-DD'])
                .isSame(moment(passenger.fields.dateOfBirth.value, ['DD/MM/YYYY', 'YYYY-MM-DD'])))
        ]

        const hasData = (
            crmUser.first_name ||
            crmUser.lastName ||
            crmUser.birth_date
        )

        // Check if crmUser fields match with the formFields.
        // If the crmUser field is empty, we skip the check as it may contain new data)
        // If all the crmUser data is empty, it's not 'me'
        if (passenger && matchData.every(Boolean) && hasData) {
            this.handleSelectMe()
        }
    }
})

export default withRouter(component)
