import PropTypes from 'prop-types'
import React, { Component } from 'react'
import classNames from 'classnames'

export default class CmsContent extends Component {
    static propTypes = {
        content: PropTypes.string,
        className: PropTypes.string
    }

    render () {
        return (
            <div className={classNames('cms-block', this.props.className)}>
                <span dangerouslySetInnerHTML={{__html: this.props.content}} />
                {this.props.children}
            </div>
        )
    }
}
