import Reflux from 'reflux'
import get from 'lodash/get'
import UserAwareMixin from '../mixin/user-aware-mixin'
import actions from '../actions'

export default Reflux.createStore({

    listenables: actions,

    mixins: [UserAwareMixin],

    init () {
        this._resetData()
    },

    _resetData () {
        this.data = {
            oauthRefreshError: null
        }
    },

    resetData () {
        this._resetData()
        this.trigger(this.data)
    },

    getInitialState () {
        return this.data
    },

    onApiError (error) {
        if (get(error, 'response.req._data.grant_type') === 'refresh_token') {
            this.data.oauthRefreshError = get(error, 'message', 'refresh token error')
            error.handled = true
            this.trigger(this.data)
        }

        if (get(error, 'response.body.error') === 'invalid_client') {
            error.handled = true
        }
    }
})
