import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from '../../../translate'
import Icon from '../../../element/icon'
import ConfirmationDialog from './wheelchair-panel/confirmation-dialog'
import SupportDialog from './wheelchair-panel/support-dialog'
import InventoryDialog from './wheelchair-panel/inventory-dialog'
import RadioGroup from '../../../element/form/radio-group'

const wheelchairOptions = [
    {id: 'yes', text: 'additional-product-selector.grid.wheelchair-yes', value: 1},
    {id: 'no', text: 'additional-product-selector.grid.wheelchair-no', value: 0}
]

export default class WheelchairPanel extends Component {
    static propTypes = {
        onChange: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        checked: PropTypes.bool,
        showSupportDialog: PropTypes.bool,
        showConfirmDialog: PropTypes.bool,
        showInventoryDialog: PropTypes.bool,
        onConfirm: PropTypes.func,
        onCancel: PropTypes.func,
        passengerCount: PropTypes.number
    }

    render () {
        return (
            <div className='grid-row'>
                <div className='grid-column--1-1'>
                    <div className='additional-product-per-pax-row'>
                        <div className='grid-row no-gutter'>
                            <div className='grid-column--1-1 grid-column--medium-3-5'>
                                <span
                                    className='text-label passenger-type wheelchair-access'
                                    title={_t.getIntlMessage('additional-product-selector.grid.wheelchair-access')}>
                                    <Icon type='disability' className='medium align-left' />
                                    <span className='text'>
                                        {_t.getIntlMessage('additional-product-selector.grid.wheelchair-access')}
                                    </span>
                                </span>
                            </div>
                            <div className='grid-column--1-1 grid-column--medium-2-5'>
                                <RadioGroup
                                    id='wheelchair-radio'
                                    selectedValue={this.props.checked ? 1 : 0}
                                    onChange={this.props.onChange}
                                    disabled={this.props.disabled}
                                    data={wheelchairOptions}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {this.props.showConfirmDialog
                    ? <ConfirmationDialog
                        onCancel={this.props.onCancel}
                        onConfirm={this.props.onConfirm}
                        passengerCount={this.props.passengerCount}
                    /> : null}
                {this.props.showSupportDialog ? <SupportDialog closeDialog={this.props.onCancel} /> : null}
                {this.props.showInventoryDialog ? <InventoryDialog closeDialog={this.props.onCancel} /> : null}
            </div>
        )
    }
}
