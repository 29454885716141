import PropTypes from 'prop-types'
import React, {Component} from 'react'
import classNames from 'classnames'
import Icon from '../../element/icon'
import TextLabel from '../../element/text-label'
import Button from '../../element/button'
import _t from '../../translate'

export default class OptionBox extends Component {
    static propTypes = {
        direction: PropTypes.oneOf(['inbound', 'outbound']).isRequired,
        onHideOptionBox: PropTypes.func.isRequired
    }

    render () {
        const classes = classNames({
            'modify-travel-results': true,
            inbound: this.props.direction === 'inbound',
            outbound: this.props.direction === 'outbound'
        })

        return (
            <div className={classes}>
                <Button className='button small default modify-travel' onClick={this.props.onHideOptionBox}>
                    <Icon
                        type={this.props.direction === 'outbound' ? 'half-arrow-right' : 'half-arrow-left'}
                        className='medium align-left'
                    />
                    <TextLabel
                        text={_t.formatIntlMessage(`journey-result-set.modify-travel.${this.props.direction}`)}
                    />
                </Button>
            </div>
        )
    }
}
