/* globals S3P_SETTINGS: true */

import BookingStore from '../reflux/stores/booking-store'
import actions from '../reflux/actions'
import _t from '../translate'

export const restoreBooking = () => {
    const bookingNumber = BookingStore.getStoredBookingNumber()
    const hasBooking = Boolean(BookingStore.getBooking() && BookingStore.getBooking().booking_number)

    if (!bookingNumber) {
        return this.history.pushState(
            null,
            `/${_t.getLocales()}${S3P_SETTINGS.s3Passenger.features.unauthorizedPages.default}`
        )
    } else if (!hasBooking) {
        return actions.getBooking(bookingNumber)
    }
}
