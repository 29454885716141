import {
    PAYMENT_METHOD_FLOW_TYPE_BANCONTACT,
    PAYMENT_METHOD_FLOW_TYPE_CREDIT_CARD_ENCRYPTED,
    PAYMENT_METHOD_FLOW_TYPE_CREDITCARD,
    PAYMENT_METHOD_FLOW_TYPE_IDEAL,
    PAYMENT_METHOD_FLOW_TYPE_INVOICE,
    PAYMENT_METHOD_FLOW_TYPE_PAYPAL,
    PAYMENT_METHOD_FLOW_TYPE_SOFORT,
    PAYMENT_METHOD_FLOW_TYPE_VOUCHER,
    PAYMENT_PROVIDER_ADYEN,
    PAYMENT_PROVIDER_NONE,
    PAYMENT_PROVIDER_WORLDPAY,
    PAYMENT_FORM_ADYEN_CREDITCARD_ENCRYPTED,
    PAYMENT_FORM_ADYEN_IDEAL,
    PAYMENT_FORM_ADYEN_PAYPAL,
    PAYMENT_FORM_ADYEN_SOFORT,
    PAYMENT_FORM_CASH,
    PAYMENT_FORM_VOUCHER,
    PAYMENT_FORM_WPAY_BANCONTACT,
    PAYMENT_FORM_WPAY_CREDITCARD,
    PAYMENT_FORM_WPAY_CREDITCARD_ENCRYPTED
} from '../../../constants'
import cloneDeep from 'lodash/cloneDeep'
import findKey from 'lodash/findKey'
import get from 'lodash/get'
import {isBlablabusDomain} from '../../../misc/blablabus-helpers'

const defaultFields = {
    agree: {
        value: isBlablabusDomain() || false,
        errorText: null,
        isRequired: true
    }
}

const paymentForms = {
    [PAYMENT_FORM_WPAY_CREDITCARD]: {
        provider: PAYMENT_PROVIDER_WORLDPAY,
        flowType: PAYMENT_METHOD_FLOW_TYPE_CREDITCARD,
        fields: {
            cardNumber: {
                value: null,
                errorText: null,
                isRequired: true
            },
            cardHolder: {
                value: null,
                errorText: null,
                isRequired: true
            },
            validUntil: {
                value: null,
                errorText: null,
                isRequired: true
            },
            cvcCode: {
                value: null,
                errorText: null,
                isRequired: true
            }
        }
    },
    [PAYMENT_FORM_WPAY_CREDITCARD_ENCRYPTED]: {
        provider: PAYMENT_PROVIDER_WORLDPAY,
        flowType: PAYMENT_METHOD_FLOW_TYPE_CREDIT_CARD_ENCRYPTED,
        fields: {
            selectCard: {
                value: null,
                errorText: null
            },
            cardNumber: {
                value: null,
                errorText: null,
                isRequired: true
            },
            cardHolder: {
                value: null,
                errorText: null,
                isRequired: true
            },
            validUntil: {
                value: null,
                errorText: null,
                isRequired: true
            },
            cvcCode: {
                value: null,
                errorText: null,
                isRequired: true
            }
        }
    },
    [PAYMENT_FORM_ADYEN_CREDITCARD_ENCRYPTED]: {
        provider: PAYMENT_PROVIDER_ADYEN,
        flowType: PAYMENT_METHOD_FLOW_TYPE_CREDIT_CARD_ENCRYPTED,
        fields: {
            data: {
                value: null,
                errorText: null
            }
        }
    },
    [PAYMENT_FORM_ADYEN_IDEAL]: {
        provider: PAYMENT_PROVIDER_ADYEN,
        flowType: PAYMENT_METHOD_FLOW_TYPE_IDEAL,
        fields: {
            bankCode: {
                value: null,
                errorText: null,
                isRequired: true
            }
        }
    },
    [PAYMENT_FORM_WPAY_BANCONTACT]: {
        provider: PAYMENT_PROVIDER_WORLDPAY,
        flowType: PAYMENT_METHOD_FLOW_TYPE_BANCONTACT
    },
    [PAYMENT_FORM_ADYEN_PAYPAL]: {
        provider: PAYMENT_PROVIDER_ADYEN,
        flowType: PAYMENT_METHOD_FLOW_TYPE_PAYPAL
    },
    [PAYMENT_FORM_ADYEN_SOFORT]: {
        provider: PAYMENT_PROVIDER_ADYEN,
        flowType: PAYMENT_METHOD_FLOW_TYPE_SOFORT
    },
    [PAYMENT_FORM_CASH]: {
        provider: PAYMENT_PROVIDER_NONE,
        flowType: PAYMENT_METHOD_FLOW_TYPE_INVOICE,
        fields: {
            cashAmount: {
                value: null,
                isRequired: true
            }
        }
    },
    [PAYMENT_FORM_VOUCHER]: {
        provider: null,
        flowType: PAYMENT_METHOD_FLOW_TYPE_VOUCHER
    }
}

export const getPaymentFormName = (provider, flowType) => findKey(paymentForms, form => form.flowType === flowType && form.provider === provider)

export const getPaymentFormFields = formName => ({
    ...cloneDeep(get(paymentForms, `${formName}.fields`, {})),
    ...cloneDeep(defaultFields)
})
