import React from 'react'
import Reflux from 'reflux'
import _t from '../../translate'
import Message from '../../element/message'
import PaymentOptions from './payment-container/payment-options'
import VoucherList from './payment-container/voucher-list'
import PaymentTerms from './payment-container/payment-terms'
import InteractiveFlashMessage from '../interactive-flash-message'
import BookingStore from '../../reflux/stores/booking-store'
import BookingModel from '../../models/booking-model'
import AccessManager from '../../data/access-manager'
import createReactClass from 'create-react-class'

export default createReactClass({

    displayName: 'PaymentContainer',

    mixins: [
        Reflux.listenTo(BookingStore, 'updateBooking')
    ],

    getInitialState () {
        return {booking: BookingStore.getBookingModel()}
    },

    updateBooking (data) {
        if (!data.loading) {
            this.setState({booking: BookingModel.create(data.booking)})
        }
    },

    componentDidUpdate (_, prevState) {
        if (this.state.booking && prevState.booking &&
            this.state.booking.vouchers.length !== prevState.booking.vouchers.length
        ) {
            const voucherPanel = document.querySelector('.redeem-voucher')
            if (voucherPanel) {
                const coordinates = voucherPanel.getBoundingClientRect()
                const bodyRect = document.body.getBoundingClientRect()
                const scrollOffset = coordinates.top - bodyRect.top
                window.scrollTo(0, scrollOffset - 50)
            }
        }
    },

    render () {
        if (!this.state.booking || !this.state.booking.customer || this.state.booking.passengers.length === 0) {
            return null
        }

        const message = this.state.booking.hasDiscountAfterPendingPayment
            ? {
                severity: 'warning',
                content: _t.getIntlMessage('payment.voucher.warning-previous-partial-payment-not-compatible')
            } : false

        return (
            <div>
                <InteractiveFlashMessage />
                {this.state.booking.pendingVouchers.length > 0 && !AccessManager.isCrmUser() ? (
                    <div className='redeem-voucher'>
                        <div className='panel'>
                            <header>
                                <h2>{_t.getIntlMessage('voucher.header')}</h2>
                            </header>
                            <div className='content'>
                                {message ? <Message message={message} /> : null}
                                <VoucherList />
                                {this.state.booking.requiresPayment ? null : <PaymentTerms />}
                            </div>
                        </div>
                    </div>
                ) : null}
                <PaymentOptions />
            </div>
        )
    }

})
