import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {cmsBlockContainer} from '../cms/cms-block-container'
import classNames from 'classnames'
import Icon from '../../element/icon'
import TextLabel from '../../element/text-label'

class BookingConfirmationTer extends Component {
    static propTypes = {
        title: PropTypes.any,
        content: PropTypes.any
    }

    render () {
        return this.props.content ? (
            <div className='booking-confirmation-ter'>
                <div className='panel'>
                    <div className='content'>
                        <div className='grid-row'>
                            <div className={classNames(
                                'grid-column--1-1',
                                'grid-column--medium-2-10',
                                'offset-grid-column--medium-1-10',
                                'ter-icon'
                            )}>
                                <Icon type='ter' className='xxlarge' />
                            </div>
                            <div className='grid-column--1-1 grid-column--medium-6-10'>
                                <span className='text-label title'>
                                    <TextLabel text={this.props.title} />
                                </span>
                                <span className='text-label'>
                                    <span dangerouslySetInnerHTML={{__html: this.props.content}} />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ) : null
    }
}

export default cmsBlockContainer('BOOKING-CONF-TER', BookingConfirmationTer)
