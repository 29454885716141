import PropTypes from 'prop-types'
import React, {Component} from 'react'
import _ from 'lodash'
import _t from '../../../translate'
import Panel from '../../../element/panel'
import OfferStore from '../../../reflux/stores/offer-store'
import Icon from '../../../element/icon'
import ModalityInformation from './seat-options/modality-information'
import SegmentInformation from './seat-options/segment-information'
import SelectionInformation from './seat-options/selection-information'
import ChangeSelection from './seat-options/change-selection'
import SelectionInformationButton from './seat-options/selection-information-button'
import cmsComponent from '../../cms/cms-component'
import {
    CMS_BLOCK_AFTERSALES_VIEW_SEAT_NUMBER,
    SERVICE_TYPE_MODALITY_TER
} from '../../../constants'
import BookSeats from './seat-options/book-seats'
import UserStore from '../../../reflux/stores/user-store'
import actions from '../../../reflux/actions'
import {lowestSeatPricePerSegmentSelector} from '../../../models/selectors/api/v2/orientation/product-search'
import {getState} from '../../../reflux/bridge/connect-state'
import {getAvailableLegIds} from '../../seat-selector/actions/seat-selector'
import CarriageLayoutsStore from '../../../reflux/stores/carriage-layouts-store'
import isUndefined from 'lodash/isUndefined'

class MyS3SeatOptions extends Component {
    static propTypes = {
        open: PropTypes.bool,
        seatSelectionOptions: PropTypes.arrayOf(PropTypes.shape({
            leg: PropTypes.shape({
                id: PropTypes.string.isRequired,
                departure_station: PropTypes.shape({
                    name: PropTypes.string.isRequired,
                    departure_timestamp: PropTypes.string.isRequired
                }).isRequired,
                arrival_station: PropTypes.shape({
                    name: PropTypes.string.isRequired
                }).isRequired,
                service_name: PropTypes.string.isRequired,
                service_type: PropTypes.shape({
                    code: PropTypes.string.isRequired,
                    modality: PropTypes.string.isRequired
                }).isRequired,
                show_seats_threshold: PropTypes.bool.isRequired,
                show_seats_threshold_moment: PropTypes.object.isRequired
            }).isRequired
        })).isRequired,
        selectedSeats: PropTypes.arrayOf(PropTypes.shape({
            leg_id: PropTypes.string.isRequired
        })),
        loading: PropTypes.bool.isRequired,
        booking: PropTypes.object.isRequired
    }

    static defaultProps = {open: true}

    constructor (...args) {
        super(...args)
        this.state = {
            offer: OfferStore.getOfferData(),
            panelOpen: this.props.open,
            salesChannelProperties: UserStore.getSalesChannelProperties(),
            lowestPrices: null,
            availableLegIds: null
        }
        this.togglePanel = this.togglePanel.bind(this)
    }

    togglePanel () {
        this.setState({panelOpen: !this.state.panelOpen})
    }

    componentDidMount () {
        this._setAvailability()
    }

    async _setAvailability () {
        const {segments, passengers} = this.props.booking.segmentsAndPassengersForProducts
        await actions.getProducts({segments, passengers, currency: this.props.booking.currency})

        const availableLegIds = await getAvailableLegIds(
            this.props.booking.tariffSegmentCollection.services,
            1
        )
        CarriageLayoutsStore.resetData()
        return this.setState({
            availableLegIds,
            lowestPrices: lowestSeatPricePerSegmentSelector(getState())
        })
    }

    componentWillReceiveProps (nextProps) {
        if (nextProps.open !== this.state.panelOpen) {
            this.setState({panelOpen: nextProps.open})
        }
    }

    _renderSeatSelectionOptions () {
        let seatSelectionOptions = _(this.props.seatSelectionOptions).map((seatSelection, index) => (
            {
                direction: seatSelection.direction,
                seatSelection: this._renderSelectionOptionBody(seatSelection.leg, seatSelection.segment_id, index)
            }
        ))

        seatSelectionOptions = seatSelectionOptions
            .partition(seatSelectionOption => _.indexOf(['outward', 'outbound'], seatSelectionOption.direction) > -1)
            .map(data => data.map(seatSelectionOption => seatSelectionOption.seatSelection))
            .value()

        return (
            <div className='grid-row'>
                {this._renderOutboundSeatSelectionOptions(seatSelectionOptions[0])}
                {this._renderInboundSeatSelectionOptions(seatSelectionOptions[1])}
            </div>
        )
    }

    _renderSelectionOptionBody (leg, segmentId, index) {
        const key = `seat-option:${leg.id}-${segmentId}-${index}`
        const manuallySelectedSeats = this.props.booking.tariffSegmentCollection.selectedManualSelectedSeats.filter(selectedSeat => selectedSeat.leg_id === leg.id)
        const hasManuallySelectedSeat = manuallySelectedSeats.length > 0
        const seatSelectionAvailable = (this.state.availableLegIds || []).includes(leg.id)
        const canBookSeats = seatSelectionAvailable && manuallySelectedSeats.length < this.props.booking.passengers.size()
        const showSeatsThreshold = leg.show_seats_threshold
        const lowestPrice = (this.state.lowestPrices || {})[segmentId]
        const isTer = leg.service_type.modality === SERVICE_TYPE_MODALITY_TER
        return (
            <div key={key} className='segment-row'>
                <ModalityInformation
                    serviceName={leg.service_name}
                    serviceModality={leg.service_type.modality}
                    serviceCode={leg.service_type.code}
                />
                <SegmentInformation
                    origin={leg.departure_station.name}
                    destination={leg.arrival_station.name}
                />
                <SelectionInformation
                    showSeatSelector={
                        !isUndefined(lowestPrice) &&
                        seatSelectionAvailable &&
                        !hasManuallySelectedSeat &&
                        this.props.booking.canSelectSeatByLeg(leg.id)
                    }
                    selectedSeats={this.props.selectedSeats.filter(seat => seat.leg_id === leg.id)}
                    manuallySelectedSeats={manuallySelectedSeats}
                    showSeatsThreshold={showSeatsThreshold}
                    legId={leg.id}
                    isTer={isTer}
                    lowestPrice={lowestPrice}
                />
                {!isTer ? [
                    <SelectionInformationButton
                        key='select-seats'
                        legId={leg.id}
                        isAvailable={showSeatsThreshold || hasManuallySelectedSeat}
                        showSeatsThresholdMoment={leg.show_seats_threshold_moment}
                    />,
                    <ChangeSelection
                        key='change-seats'
                        legId={leg.id}
                        isAvailable={hasManuallySelectedSeat && seatSelectionAvailable}
                    />,
                    <BookSeats
                        key='book-seats'
                        legId={leg.id}
                        isAvailable={hasManuallySelectedSeat && canBookSeats}
                    />
                ] : null}
            </div>
        )
    }

    _renderOutboundSeatSelectionOptions (seatSelectionOptions) {
        if (_.isEmpty(seatSelectionOptions)) {
            return null
        }

        return (
            <div className='grid-column--1-1 grid-column--medium-1-2'>
                <div className='outbound'>
                    <span className='text-label outbound-inbound-title'>
                        <span className='text'>
                            {_t.message('seat-selector.directions.outbound')}
                        </span>
                        <Icon type='half-arrow-right' className='half-arrow-right icon medium align-right' />
                    </span>
                    {seatSelectionOptions}
                </div>
            </div>
        )
    }

    _renderInboundSeatSelectionOptions (seatSelectionOptions) {
        if (_.isEmpty(seatSelectionOptions)) {
            return null
        }

        return (
            <div className='grid-column--1-1 grid-column--medium-1-2'>
                <div className='inbound'>
                    <span className='text-label outbound-inbound-title'>
                        <span className='text'>{_t.message('seat-selector.directions.inbound')}</span>
                        <Icon type='half-arrow-left' className='half-arrow-left icon medium align-right' />
                    </span>
                    {seatSelectionOptions}
                </div>
            </div>
        )
    }

    render () {
        return (
            <div className='seat-selector'>
                <Panel
                    icon={<Icon type='assigned-seat' className='large align-left' />}
                    title={_t.message('seat-selector.header')}
                    open={this.state.panelOpen}
                    onToggle={this.togglePanel}
                    name='toggle-booking-preference'
                >
                    {this.props.seatSelectionOptions ? this._renderSeatSelectionOptions() : null}
                </Panel>
            </div>
        )
    }
}

export default cmsComponent(CMS_BLOCK_AFTERSALES_VIEW_SEAT_NUMBER)(MyS3SeatOptions)
