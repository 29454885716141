import PropTypes from 'prop-types'
import React, {Component} from 'react'
import Icon from '../../../element/icon'
import Panel from '../../../element/panel'
import {restoreSelectedBundlesFromBooking} from '../../../misc/journey-search'
import {
    hasBikePayingOptionsSelector
} from '../../../models/selectors/components/orientation/journey-search'
import {getState} from '../../../reflux/bridge/connect-state'
import BookingStore from '../../../reflux/stores/booking-store'
import _t from '../../../translate'
import BikeOptions from '../../options/bike-options'

class MyS3BikeOptions extends Component {
    static propTypes = {
        open: PropTypes.bool
    }

    static defaultProps = {
        open: true
    }

    constructor (...args) {
        super(...args)
        this.state = {
            panelOpen: this.props.open
        }
        this.togglePanel = this.togglePanel.bind(this)
    }

    togglePanel () {
        this.setState({panelOpen: !this.state.panelOpen})
    }

    componentWillReceiveProps (nextProps) {
        if (nextProps.open !== this.state.panelOpen) {
            this.setState({panelOpen: nextProps.open})
        }
    }

    render () {
        return (
            <div className='my-s3-bike-selection'>
                <Panel
                    icon={<Icon type='bike' className='large align-left' />}
                    title={_t.message('my-s3-options.bike.header')}
                    open={this.state.panelOpen}
                    onToggle={this.togglePanel}
                    name='toggle-booking-preference'
                >
                    <BikeOptions bikeSelectorRoute='/mys3/bike-selector' myS3Options />
                </Panel>
            </div>
        )
    }
}

class MyS3BikeOptionsContainer extends Component {
    constructor (...args) {
        super(...args)
        this.state = {
            hasBikePayingOptions: false
        }
    }

    async componentDidMount () {
        const booking = BookingStore.getBookingModel()
        await restoreSelectedBundlesFromBooking(booking)
        this.setState({
            hasBikePayingOptions: hasBikePayingOptionsSelector(getState())
        })
    }

    render () {
        return this.state.hasBikePayingOptions ? <MyS3BikeOptions {...this.props} /> : null
    }
}

export default MyS3BikeOptionsContainer
