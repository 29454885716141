import PropTypes from 'prop-types'
import React, {Component} from 'react'
import Icon from '../../../../element/icon'
import TextLabel from '../../../../element/text-label'
import {SERVICE_TYPE_MODALITY_TER} from '../../../../constants'

export default class ModalityInformation extends Component {
    static propTypes = {
        serviceName: PropTypes.string.isRequired,
        serviceModality: PropTypes.string.isRequired,
        serviceCode: PropTypes.string.isRequired
    }

    render () {
        return (
            <span className='text-label modality-title'>
                <Icon
                    className='small align-left'
                    type={this.props.serviceModality === SERVICE_TYPE_MODALITY_TER ? 'ter' : 'bus'}
                />
                <TextLabel text={`${this.props.serviceCode} ${this.props.serviceName}`} />
            </span>
        )
    }
}
