import React, {Component} from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import snakeCase from 'lodash/snakeCase'
import sortBy from 'lodash/sortBy'

import _t from '../../translate'
import device from '../../device'
import {SERVICE_TYPE_MODALITY_TER} from '../../constants'
import TextLabel from '../../element/text-label'

export default class JourneySection extends Component {
    static propTypes = {
        type: PropTypes.oneOf(['origin', 'destination', 'transfer', 'other']),
        time: PropTypes.string.isRequired,
        station: PropTypes.shape({
            name: PropTypes.string.isRequired,
            UICStationCode: PropTypes.string.isRequired
        }).isRequired,
        serviceType: PropTypes.shape({
            modality: PropTypes.string.isRequired,
            code: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired
        }),
        serviceName: PropTypes.string,
        travelTime: PropTypes.string,
        showModalityLabel: PropTypes.bool,
        hasOtherService: PropTypes.bool,
        viaStations: PropTypes.array,
        collapsed: PropTypes.bool
    }

    static defaultProps = {
        showMapPin: true,
        showModalityLabel: true
    }

    render () {
        const classNames = classnames('journey-section', this.props.type)

        return (
            <div className={classNames}>
                <div className='journey-section--details-time-service-type'>
                    <div className='journey-section--details-time'>
                        <span className='text-label text-label--departure-time'>
                            <TextLabel text={this.props.time} />
                        </span>
                    </div>
                    {this._renderServiceType()}
                </div>
                <div className='journey-section--details-track'>
                    <div className='journey-section--marker'>
                        <div className='journey-section--dot' />
                    </div>
                </div>
                <div className='grid-column--8-12 journey-section--details-station-travel-time'>
                    <div className='journey-section--details-station'>
                        <span className='text-label text-label--station-name'>
                            <TextLabel text={this.props.station.name} />
                        </span>
                    </div>
                    {this.props.viaStations ? this._renderViaStations() : null}
                    {this._renderTravelTimeAvailability()}
                </div>
            </div>
        )
    }

    _renderServiceType () {
        if (!this.props.serviceType) {
            return null
        }
        return (
            <div className='journey-section--modality'>
                {this._renderModality()}
            </div>
        )
    }

    _renderModality () {
        const {
            name,
            modality
        } = this.props.serviceType

        return this.props.showModalityLabel
            ? (
                <span className={classnames(
                    'text-label',
                    'text-label--modality',
                    modality === SERVICE_TYPE_MODALITY_TER ? 'ter' : snakeCase(name)
                )}>
                    <TextLabel text={name} />
                </span>
            )
            : null
    }

    _renderViaStations () {
        return this.props.viaStations.length
            ? device.isMobile()
                ? this._renderViaStationsMobile()
                : this._renderViaStationsDesktop()
            : null
    }

    _renderViaStationsMobile () {
        return (
            <div className='journey-section--via-stations'>
                <div className='via-stations-container'>
                    <span className='text-label text-label--stopovers'>
                        <TextLabel className='small' text={_t.getIntlMessage('journey-section.via-stations-title')} />
                    </span>
                    <ul className='via-stations-list vertical-list'>
                        {sortBy(this.props.viaStations, 'arrival_timestamp')
                            .map((station, idx) => (
                                <li key={idx}>
                                    <span className='text-label via-station'>
                                        <TextLabel text={station.name} />
                                    </span>
                                </li>
                            )
                            )}
                    </ul>
                </div>
            </div>
        )
    }

    _renderViaStationsDesktop () {
        return (
            <div className='journey-section--via-stations'>
                {this.props.collapsed
                    ? (
                        <TextLabel
                            className='small'
                            text={_t.formatIntlMessage('journey-section.via-stations', {number: this.props.viaStations.length})}
                        />
                    )
                    : (
                        <div className='via-stations-container'>
                            <span className='text-label text-label--stopovers'>
                                <TextLabel className='small'
                                    text={_t.getIntlMessage('journey-section.via-stations-title')} />
                            </span>
                            <ul className='via-stations-list vertical-list'>
                                {sortBy(this.props.viaStations, 'arrival_timestamp')
                                    .map((station, idx) => (
                                        <li key={idx}>
                                            <span className='text-label via-station'>
                                                <TextLabel text={station.name} />
                                            </span>
                                        </li>
                                    )
                                    )}
                            </ul>
                        </div>
                    )
                }
            </div>
        )
    }

    _renderTravelTimeAvailability () {
        const {
            travelTime,
            type
        } = this.props
        const showTravelTime = travelTime && type === 'transfer'

        return showTravelTime ? (
            <div className='journey-section--section-time-availability'>
                {showTravelTime ? (
                    <span className='text-label text-label--section-time'>
                        <TextLabel text={travelTime} />
                    </span>
                ) : null}
            </div>
        ) : null
    }
}
