import Base64 from 'Base64'

export default class BookingParameterEncoder {
    static encode (booking) {
        return Base64.btoa(booking.bookingNumber + '|' + booking.customer.email + '|S3')
    }

    static decode (encodedString) {
        let result
        try {
            // Format: [bookingNumber]|[email]|S3
            result = Base64.atob(encodedString).match(/^([A-Z0-9_-]+)\|(.+)\|S3$/i)
        } catch (e) {
            return null
        }

        return result ? {bookingNumber: result[1], email: result[2]} : null
    }
}
