import React, {Component} from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import classNames from 'classnames'
import AutoComplete from '../element/jquery/auto-complete'
import countryData from 'country-data'
import _t from '../translate'

export default class StationSelector extends Component {
    static propTypes = {
        data: PropTypes.array,
        placeholder: PropTypes.string.isRequired,
        id: PropTypes.string,
        name: PropTypes.string,
        className: PropTypes.string,
        required: PropTypes.bool,
        groupByCountry: PropTypes.bool,
        onChange: PropTypes.func,
        onFocus: PropTypes.func,
        onBlur: PropTypes.func,
        matcherFields: PropTypes.array,
        errorText: PropTypes.string,
        autoCompleteOptions: PropTypes.object,
        value: PropTypes.string
    }

    static defaultProps = {
        required: false,
        groupByCountry: false,
        data: [],
        matcherFields: ['_u_i_c_station_code', 'name', 'short_code', 'synonyms']
    }

    constructor (...args) {
        super(...args)
        this.getErrorFeedback = this.getErrorFeedback.bind(this)
        this.onChange = this.onChange.bind(this)
        this.matcher = this.matcher.bind(this)
        this.groupMatcher = this.groupMatcher.bind(this)
        this.getEqualProps = this.getEqualProps.bind(this)
        this._getAutocompleteData = this._getAutocompleteData.bind(this)
        this._getGroupedAutocompleteData = this._getGroupedAutocompleteData.bind(this)
    }

    getErrorFeedback () {
        if (!this.props.errorText || this.props.errorText.length === 0) {
            return null
        }

        return <div className='input-feedback state--is-invalid'>{_t.getIntlMessage(this.props.errorText)}</div>
    }

    render () {
        return (
            <div>
                <AutoComplete
                    {...this.getEqualProps()}
                    autoCompleteOptions={this.props.autoCompleteOptions}
                    data={this._getAutocompleteData()}
                    placeholder={this.props.placeholder}
                    onClose={this.props.onBlur}
                    onChange={this.onChange}
                    matcher={this.matcher}
                    isValid={!this.props.errorText || this.props.errorText.length === 0}
                />
                {this.getErrorFeedback()}
            </div>
        )
    }

    onChange (value) {
        if (this.props.onChange) {
            this.props.onChange(value !== this.props.placeholder ? value : '')
        }
    }

    matcher (item, term) {
        if (item.isGroup) {
            return this.groupMatcher(item, term)
        }
        const matcher = new RegExp(_.deburr(term.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')), 'i')
        return _(this.props.matcherFields)
            .map(value => {
                return _.get(item, `data.${value}`)
            })
            .flatten()
            .find(value => matcher.test(_.deburr(value)))
    }

    groupMatcher (group, term) {
        let result = false
        _.forEach(group.items, item => {
            result = result || this.matcher(item, term)
        })

        return result
    }

    getEqualProps () {
        return {
            id: this.props.id,
            name: this.props.name,
            value: this.props.value,
            required: this.props.required,
            className: this.props.className
        }
    }

    _getAutocompleteData () {
        if (this.props.groupByCountry) {
            return this._getGroupedAutocompleteData()
        }

        return this.props.data.filter(function (item) {
            return item.disabled !== true
        })
    }

    _getGroupedAutocompleteData () {
        const groupedData = _.groupBy(this.props.data, item => item.data.country_code)

        let data = []
        _.forEach(groupedData, (items, countryCode) => {
            const country = _.get(countryData.countries, countryCode, null)
            if (country) {
                data.push({
                    isGroup: true,
                    text: country ? country.name : countryCode,
                    className: classNames('text-label', 'language', countryCode.toLowerCase()),
                    items: items
                })
            }

            _.forEach(items, item => {
                data.push(item)
            })
        })

        return data.filter(function (item) {
            return item.disabled !== true
        })
    }

    _getSelectData () {
        return [{
            value: null,
            countryCode: '',
            text: this.props.placeholder,
            isMain: false
        }].concat(this.props.data.map(item => {
            const countryCode = _.get(item, 'data.country_code', '')
            const country = _.get(countryData.countries, countryCode, null)

            return {
                text: item.text,
                value: item.value,
                country: country ? country.name : countryCode,
                isMain: item.isMain || false
            }
        }))
    }
}
