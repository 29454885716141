import React from 'react'
import PropTypes from 'prop-types'
import Reflux from 'reflux'
import device from '../../../device'
import {withRouter} from 'react-router'
import _t from '../../../translate'
import MyS3OptionsLayoutContainer from '../../my-s3-options-layout-container'
import PaymentContainer from '../../../components/booking-payment/payment-container'
import ProgressNavigation from '../../../components/booking-payment/progress-navigation'
import ProductsStore from '../../../reflux/stores/products-store'
import BookingStore from '../../../reflux/stores/booking-store'
import actions from '../../../reflux/actions'
import Help from '../../../components/my-s3-booking/help'
import restoreBooking from '../../../components/restore/restore-booking'
import BookingModel from '../../../models/booking-model'
import createReactClass from 'create-react-class'

export default withRouter(restoreBooking(createReactClass({
    displayName: 'BikeSelectorPaymentLayout',

    mixins: [
        Reflux.connectFilter(ProductsStore, 'products', data => data.products),
        Reflux.connectFilter(BookingStore, 'booking',
            data => BookingModel.create(data.booking)
        ),
        Reflux.listenTo(actions.getBooking.completed, 'onGetBookingCompleted')
    ],

    propTypes: {
        router: PropTypes.object
    },

    componentDidMount () {
        actions.setPaymentReturnUrl(`/${_t.getLocales()}/mys3/bike/confirm`)
    },

    onPrev () {
        const url = `/${_t.getLocales()}/mys3/my-options`
        actions.revertBooking(this.state.booking.bookingNumber).then(() => this.props.router.push(url))
    },

    onConfirm () {
        actions.confirmBooking(this.state.booking.bookingNumber)
    },

    onConfirmed () {
        this.props.router.push(`/${_t.getLocales()}/mys3/bike/confirm?s3_status=success`)
    },

    render () {
        return (
            <MyS3OptionsLayoutContainer name='bike-selector-payment'>
                <PaymentContainer />
                <ProgressNavigation onPrev={this.onPrev} onConfirm={this.onConfirm} onConfirmed={this.onConfirmed} />
                {device.isMobile() ? <Help /> : null}
            </MyS3OptionsLayoutContainer>
        )
    }
}), {withBlockingLoader: true}))
