import Reflux from 'reflux'
import Client from '../api/client'
import OAuth from '../oauth'
import {
    checkDiscountCard,
    validateBookingDiscountCards,
    validatePassengerFormDiscountCards
} from '../misc/jolli-code-client'

const actions = Reflux.createActions({
    apiError: {sync: true},
    changeCurrency: {asyncResult: false},
    changeLocale: {},
    changeAffiliateCode: {},
    loadStations: {sync: false, asyncResult: true},
    getOffer: {sync: false, asyncResult: true},
    getCalendar: {sync: false, asyncResult: true},
    calendarDisabled: {},
    clearOffer: {},
    clearBundles: {},
    clearRefundVoucher: {},
    clearBooking: {},
    selectBundle: {sync: true},
    processDeepLink: {},
    updateSelectBundle: {sync: false, asyncResult: true},
    loadUserLoginInformation: {sync: false, asyncResult: true},
    loadCrmUserInformation: {sync: false, asyncResult: true},
    updateCrmUserInformation: {sync: false, asyncResult: true},
    createBooking: {sync: false, asyncResult: true},
    patchBooking: {sync: false, asyncResult: true},
    updateCustomer: {sync: false, asyncResult: true},
    addCustomer: {sync: false, asyncResult: true},
    updatePassengers: {sync: false, asyncResult: true},
    getBooking: {sync: false, asyncResult: true},
    confirmBooking: {sync: false, asyncResult: true},
    cancelBooking: {sync: false, asyncResult: true},
    cancelItems: {sync: false, asyncResult: true},
    processCustomerInfoData: {asyncResult: false},
    processPassengerDetailsData: {asyncResult: false},
    processPaymentData: {asyncResult: false},
    processBookingLoginData: {asyncResult: false},
    processResendTicketsData: {asyncResult: false},
    processCrmLoginData: {asyncResult: false},
    processCrmRegisterData: {asyncResult: false},
    processCreateAgentData: {asyncResult: false},
    processBookingNoteData: {asyncResult: false},
    registerCrmUser: {sync: false, asyncResult: true},
    processUpdatePassengerDetailsData: {asyncResult: false},
    resetUpdatePassenger: {asyncResult: false},
    getPaymentMethods: {sync: false, asyncResult: true},
    buildPaymentPreferences: {asyncResult: false},
    setPaymentReturnUrl: {asyncResult: false},
    initiatePayment: {sync: false, asyncResult: true},
    processPayment: {sync: false, asyncResult: true},
    loadPaymentDetails: {sync: false, asyncResult: true},
    addVoucher: {sync: false, asyncResult: true},
    removeVoucher: {sync: false, asyncResult: true},
    requestRefundVoucher: {sync: false, asyncResult: true},
    requestRefundPsp: {sync: false, asyncResult: true},
    resendTickets: {sync: false, asyncResult: true},
    loadCmsBlocks: {sync: false, asyncResult: true},
    searchVouchers: {sync: false, asyncResult: true},
    processRegisterJsonApiKeyData: {},
    requestJsonApiKey: {sync: false, asyncResult: true},
    requestJourneyDetails: {sync: false, asyncResult: true},
    addPayments: {sync: false, asyncResult: true},
    updatePayments: {sync: false, asyncResult: true},
    requestCrmVoucher: {sync: false, asyncResult: true},
    requestCrmVouchers: {sync: false, asyncResult: true},
    addCrmVoucher: {sync: false, asyncResult: true},
    deleteCrmVoucher: {sync: false, asyncResult: true},
    processFavoritePassenger: {asyncResult: false},
    updateSeats: {sync: false, asyncResult: true},
    getProducts: {sync: false, asyncResult: true},
    deleteItems: {sync: false, asyncResult: true},
    // My S3
    myS3GetFavoritePassengers: {sync: false, asyncResult: true},
    myS3CreateFavoritePassengers: {sync: false, asyncResult: true},
    myS3UpdateFavoritePassengers: {sync: false, asyncResult: true},
    myS3GetBookings: {sync: false, asyncResult: true},
    myS3RebookCreateBooking: {sync: false, asyncResult: true},
    myS3RebookSuccessful: {asyncResult: false},
    myS3SeatChangeSuccessful: {asyncResult: false},
    myS3CancellationSuccessful: {asyncResult: false},
    myS3GetInvoiceUrl: {sync: false, asyncResult: true},
    myS3SaveBookingNote: {sync: false, asyncResult: true},
    updateRebookJourneySearchSelection: {asyncResult: false},
    getCarriageLayouts: {sync: false, asyncResult: true},
    myS3UpdatePassengersSuccessful: {asyncResult: false},
    processAgentShiftData: {sync: false, asyncResult: false},
    isProvisionalShiftReport: {sync: false, asyncResult: false},
    requiresShiftCheck: {sync: false, asyncResult: false},
    setOverrideAftersalesFees: {},
    getServices: {sync: false, asyncResult: true},
    revertBooking: {sync: false, asyncResult: true},
    addAdditionalDetails: {sync: false, asyncResult: true},
    updateAdditionalDetails: {sync: false, asyncResult: true},
    changeMePassenger: {},
    processLuggage: {sync: false, asyncResult: false},
    processBike: {sync: false, asyncResult: false},

    // Notification actions
    showFlashMessage: {asyncResult: false},
    hideFlashMessage: {asyncResult: false},
    hideAllFlashMessages: {asyncResult: false},

    // OAuth
    publicAccess: {sync: false, asyncResult: true},
    bookingLogin: {sync: false, asyncResult: true},
    bookingLoginByPassenger: {sync: false, asyncResult: true},
    crmLogin: {sync: false, asyncResult: true},
    logout: {sync: false, asyncResult: true},
    newToken: {asyncResult: false},
    failedToken: {asyncResult: false},

    // Third party
    checkDiscountCard: {sync: false, asyncResult: true},
    clearDiscountCardOfPassenger: {asyncResult: false},
    validateBookingDiscountCards: {sync: false, asyncResult: true},
    validatePassengerFormDiscountCards: {sync: false, asyncResult: true},

    // Tag commander
    logEvent: {},
    trackEvent: {},

    // Google Tag Manager
    updateGTMTags: {}
})

// Client actions
actions.loadStations.listenAndPromise(Client.loadStations, Client)
actions.getOffer.listenAndPromise(Client.getOffer, Client)
actions.getCalendar.listenAndPromise(Client.getCalendar, Client)
actions.updateSelectBundle.listenAndPromise(Client.updateSelectBundle, Client)
actions.loadUserLoginInformation.listenAndPromise(Client.loadUserLoginInformation, Client)
actions.loadCrmUserInformation.listenAndPromise(Client.loadCrmUserInformation, Client)
actions.updateCrmUserInformation.listenAndPromise(Client.updateCrmUserInformation, Client)
actions.createBooking.listenAndPromise(Client.createBooking, Client)
actions.cancelBooking.listenAndPromise(Client.cancelBooking, Client)
actions.cancelItems.listenAndPromise(Client.cancelItems, Client)
actions.patchBooking.listenAndPromise(Client.patchBooking, Client)
actions.updateCustomer.listenAndPromise(Client.updateCustomer, Client)
actions.addCustomer.listenAndPromise(Client.addCustomer, Client)
actions.updatePassengers.listenAndPromise(Client.updatePassengers, Client)
actions.confirmBooking.listenAndPromise(Client.confirmBooking, Client)
actions.getBooking.listenAndPromise(Client.getBooking, Client)
actions.getCarriageLayouts.listenAndPromise(Client.getCarriageLayouts, Client)
actions.registerCrmUser.listenAndPromise(Client.registerCrmUser, Client)
actions.getPaymentMethods.listenAndPromise(Client.getPaymentMethods, Client)
actions.initiatePayment.listenAndPromise(Client.initiatePayment, Client)
actions.processPayment.listenAndPromise(Client.processPayment, Client)
actions.loadPaymentDetails.listenAndPromise(Client.loadPaymentDetails, Client)
actions.addVoucher.listenAndPromise(Client.addVoucher, Client)
actions.removeVoucher.listenAndPromise(Client.removeVoucher, Client)
actions.requestRefundVoucher.listenAndPromise(Client.requestRefund.bind(Client, 'voucher'))
actions.requestRefundPsp.listenAndPromise(Client.requestRefund.bind(Client, 'psp'))
actions.resendTickets.listenAndPromise(Client.resendTickets, Client)
actions.loadCmsBlocks.listenAndPromise(Client.loadCmsBlocks, Client)
actions.searchVouchers.listenAndPromise(Client.searchVouchers, Client)
actions.requestJsonApiKey.listenAndPromise(Client.requestJsonApiKey, Client)
actions.addPayments.listenAndPromise(Client.addPayments, Client)
actions.updatePayments.listenAndPromise(Client.updatePayments, Client)
actions.requestCrmVoucher.listenAndPromise(Client.requestCrmVoucher, Client)
actions.requestCrmVouchers.listenAndPromise(Client.requestCrmVouchers, Client)
actions.addCrmVoucher.listenAndPromise(Client.addCrmVoucher, Client)
actions.deleteCrmVoucher.listenAndPromise(Client.deleteCrmVoucher, Client)
actions.requestJourneyDetails.listenAndPromise(Client.requestJourneyDetails, Client)
actions.getServices.listenAndPromise(Client.getServices, Client)
actions.updateSeats.listenAndPromise(Client.updateSeats, Client)
actions.getProducts.listenAndPromise(Client.getProducts, Client)
actions.deleteItems.listenAndPromise(Client.deleteItems, Client)
actions.revertBooking.listenAndPromise(Client.revertBooking, Client)
actions.addAdditionalDetails.listenAndPromise(Client.addAdditionalDetails, Client)
actions.updateAdditionalDetails.listenAndPromise(Client.updateAdditionalDetails, Client)

// My S3
actions.myS3GetFavoritePassengers.listenAndPromise(Client.myS3GetFavoritePassengers, Client)
actions.myS3CreateFavoritePassengers.listenAndPromise(Client.myS3CreateFavoritePassengers, Client)
actions.myS3UpdateFavoritePassengers.listenAndPromise(Client.myS3UpdateFavoritePassengers, Client)
actions.myS3GetBookings.listenAndPromise(Client.myS3GetBookings, Client)
actions.myS3RebookCreateBooking.listenAndPromise(Client.rebookBooking, Client)
actions.myS3GetInvoiceUrl.listenAndPromise(Client.myS3GetInvoiceUrl, Client)
actions.myS3SaveBookingNote.listenAndPromise(Client.myS3SaveBookingNote, Client)

// OAuth
actions.publicAccess.listenAndPromise(OAuth.publicAccess, OAuth)
actions.bookingLogin.listenAndPromise(OAuth.bookingLogin, OAuth)
actions.bookingLoginByPassenger.listenAndPromise(OAuth.bookingLoginByPassenger, OAuth)
actions.crmLogin.listenAndPromise(OAuth.crmLogin, OAuth)
actions.logout.listenAndPromise(OAuth.logout, OAuth)

// Third Party
actions.checkDiscountCard.listenAndPromise(checkDiscountCard)
actions.validateBookingDiscountCards.listenAndPromise(validateBookingDiscountCards)
actions.validatePassengerFormDiscountCards.listenAndPromise(validatePassengerFormDiscountCards)

export default actions
