import React, {Component} from 'react'
import ReactDOM from 'react-dom'
import classNames from 'classnames'
import Select from '../select'
import _t from '../../translate'
import device from '../../device'
import PropTypes from 'prop-types'

import MaskedInput from 'react-text-mask'

export default class InputField extends Component {
    static propTypes = {
        id: PropTypes.string.isRequired,
        labelText: PropTypes.string,
        labelClassName: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.array
        ]),
        type: PropTypes.oneOf([
            'checkbox',
            'color',
            'date',
            'datetime',
            'datetime-local',
            'email',
            'file',
            'hidden',
            'image',
            'month',
            'number',
            'password',
            'radio',
            'range',
            'reset',
            'search',
            'submit',
            'tel',
            'text',
            'textarea',
            'time',
            'url',
            'week',
            'select'
        ]).isRequired,
        labelPosition: PropTypes.oneOf([
            'before',
            'after'
        ]),
        required: PropTypes.bool,
        errorText: PropTypes.string,
        valueFilter: PropTypes.func,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
        disabled: PropTypes.bool,
        readOnly: PropTypes.bool,
        floatingLabel: PropTypes.bool,
        defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        toggleSlider: PropTypes.object,
        className: PropTypes.string,
        placeholder: PropTypes.string,
        mask: PropTypes.array,
        onFocus: PropTypes.func,
        onBlur: PropTypes.func,
        name: PropTypes.string,
        multiple: PropTypes.bool
    }

    static defaultProps = {
        errorText: null,
        required: false,
        labelPosition: 'before',
        onChange: () => true
    }

    constructor (...args) {
        super(...args)

        this.handleFocus = this.handleFocus.bind(this)
        this.handleBlur = this.handleBlur.bind(this)
        this.getClassName = this.getClassName.bind(this)

        this.getLabel = this.getLabel.bind(this)
        this.getElement = this.getElement.bind(this)

        this.getErrorFeedback = this.getErrorFeedback.bind(this)
        this.getValue = this.getValue.bind(this)
        this.getClassName = this.getClassName.bind(this)

        this.getLabel = this.getLabel.bind(this)
        this.getElement = this.getElement.bind(this)

        this.state = {
            active: false
        }
    }

    handleFocus () {
        this.props.onFocus && this.props.onFocus()
        this.setState({active: true})
    }

    handleBlur (event) {
        this.props.onBlur && this.props.onBlur(event)
        this.setState({active: false})
    }

    render () {
        const id = this.props.id || this.props.name

        const {value, disabled, readOnly, type, floatingLabel, defaultValue} = this.props

        const className = floatingLabel ? classNames(
            'floating-label-input-wrapper',
            {
                'state--is-focussed': this.state.active || value || defaultValue || disabled || readOnly || type === 'select',
                'state--is-disabled': disabled
            }
        ) : null

        return (
            <div className={className}>
                {this.getLabel('before')}
                {
                    this.props.toggleSlider
                        ? <label className='toggle-slider--switch' htmlFor={id}>
                            {this.getElement()}
                            {this.props.toggleSlider}
                        </label> : this.getElement()
                }
                {this.getLabel('after')}
                {this.getErrorFeedback()}
            </div>
        )
    }

    getClassName () {
        const isWarning = this.isWarning()
        const isValid = !this.props.errorText || this.props.errorText.length === 0
        return classNames({
            'state--is-invalid': !isValid && !isWarning,
            'state--is-valid': isValid && this.props.defaultValue,
            'state--is-warning': isWarning
        })
    }

    getLabel (position) {
        const hasChildren = React.Children.count(this.props.children) > 0
        if (position !== this.props.labelPosition || (!this.props.labelText && !hasChildren)) {
            return null
        }

        const id = this.props.id || this.props.name
        const className = classNames(this.getClassName(), this.props.labelClassName, {
            'text-label': true,
            'asterisk-after': this.props.required
        })
        const labelBody = hasChildren ? this.props.children : _t.getIntlMessage(this.props.labelText)

        return <label className={className} htmlFor={id}>{labelBody}</label>
    }

    getElement () {
        let {
            required,
            type,
            className,
            placeholder,
            mask,
            ...props
        } = this.props
        /* eslint-disable react/prop-types */
        delete props.errorText
        delete props.labelClassName
        delete props.labelPosition
        delete props.labelText
        delete props.children
        delete props.floatingLabel
        delete props.defaultValue
        delete props.toggleSlider
        delete props.valueFilter
        /* eslint-enable react/prop-types */

        className = classNames(className, this.getClassName())

        if (type === 'select') {
            return <Select ref='input' className={className} {...props} />
        }

        if (type === 'textarea') {
            return <textarea ref='input' className={className} {...props} />
        }

        placeholder = !placeholder ? '' : _t.getIntlMessage(placeholder) + (required ? '*' : '')

        if (mask && device.supportsInputMask()) {
            return (
                <MaskedInput
                    {...props}
                    type={type}
                    ref='input'
                    className={className}
                    placeholder={placeholder}
                    mask={mask}
                    onFocus={this.handleFocus}
                    onBlur={this.handleBlur}
                />
            )
        }

        return (
            <input
                {...props}
                ref='input'
                type={type}
                className={className}
                placeholder={placeholder}
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
            />
        )
    }

    getErrorFeedback () {
        if (!this.props.errorText || this.props.errorText.length === 0) {
            return null
        }

        const className = this.isWarning()
            ? 'input-feedback state--is-warning'
            : 'input-feedback state--is-invalid'

        return <div className={className}>{_t.getIntlMessage(this.props.errorText)}</div>
    }

    getInputElement () {
        return this._input || this.refs.input
    }

    getValue () {
        const prop = this.props.type === 'checkbox' ? 'checked' : 'value'

        if (this.props.type === 'select' && this.props.multiple) {
            const options = ReactDOM.findDOMNode(this.getInputElement()).options
            return [].filter.call(options, option => option.selected).map(option => option.value)
        }

        let value = ReactDOM.findDOMNode(this.getInputElement())[prop]
        if ('valueFilter' in this.props) {
            value = this.props.valueFilter(value)
        }

        return value
    }

    setValue (value) {
        let prop = this.props.type === 'checkbox' ? 'checked' : 'value'

        try {
            ReactDOM.findDOMNode(this.getInputElement())[prop] = value
        } catch (e) {
            return false
        }
        return true
    }

    isWarning () {
        const errorText = this.props.errorText
        if (!errorText || errorText.length === 0) {
            return false
        }
        const errorTextPath = errorText.split('.')
        return errorTextPath[errorTextPath.length - 1].indexOf('warning') === 0
    }

    focus () {
        this.getInputElement().focus()
    }
}

