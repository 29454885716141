import React, {Component} from 'react'
import PropTypes from 'prop-types'
import TextLabel from '../../../../element/text-label'

export default class ToInfo extends Component {
    static propTypes = {
        leg: PropTypes.shape({
            arrivalStation: PropTypes.shape({
                name: PropTypes.string.isRequired,
                arrivalTime: PropTypes.any
            }).isRequired,
            departureStation: PropTypes.shape({
                name: PropTypes.string.isRequired,
                departureTime: PropTypes.any
            })
        }).isRequired
    }

    _getArrivalTime () {
        const date = this.props.leg.arrivalStation.arrivalTime
        return date.format('LT')
    }

    render () {
        return (
            <div className='journey-result-option-item to-info'>
                <span className='text-label arrival-time'>
                    <TextLabel text={this._getArrivalTime()} />
                </span>
            </div>
        )
    }
}
