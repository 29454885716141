import PropTypes from 'prop-types'
import React, { Component } from 'react'
import TextLabel from '../../../element/text-label'
import CmsBlockContent from '../../cms/cms-block-content'
import {cmsBlockContainer} from '../../cms/cms-block-container'

class CmsBlock extends Component {
    static propTypes = {
        cmsBlockName: PropTypes.string,
        title: PropTypes.string,
        content: PropTypes.string
    }

    render () {
        const {cmsBlockName, title, content} = this.props
        return title && content
            ? (
                <div className='grid-column--1-1'>
                    <span className='text-label id-card-title'>
                        <TextLabel text={title} />
                    </span>
                    <span className='text-label'>
                        <CmsBlockContent name={cmsBlockName} />
                    </span>
                </div>
            )
            : null
    }
}

export const BoardingInformationCmsBlock = cmsBlockContainer('boarding-information', CmsBlock)
export const ExchangeAndRefundCmsBlock = cmsBlockContainer('BOOKING-CONF-ASR', CmsBlock)
export const ExchangeAndRefundAlsaCmsBlock = cmsBlockContainer('BOOKING-CONF-STATIC', CmsBlock)
export const ExchangeAndRefundNelCmsBlock = cmsBlockContainer('BOOKING-CONF-STATIC_2', CmsBlock)
export const MoreInformationCmsBlock = cmsBlockContainer('BOOKING-CONF-MORE', CmsBlock)
export const SeatAllocationCmsBlock = cmsBlockContainer('BOOKING-CONF-SEAT', CmsBlock)
