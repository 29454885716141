import React, {Component} from 'react'
import PropTypes from 'prop-types'
import Icon from '../../../../element/icon'
import _t from '../../../../translate'
import {withRouter} from 'react-router'
import Heading from '../../../../element/heading'
import TextLabel from '../../../../element/text-label'
import actions from '../../../../reflux/actions'
import FormattedHTMLMessage from '../../../../translate/formatted-html-message'

class SelectionInformation extends Component {
    static propTypes = {
        selectedSeats: PropTypes.arrayOf(PropTypes.shape({
            carriage_number: PropTypes.string.isRequired,
            leg_id: PropTypes.string.isRequired,
            passenger_id: PropTypes.string.isRequired,
            seat_number: PropTypes.string.isRequired
        })),
        manuallySelectedSeats: PropTypes.arrayOf(PropTypes.shape({
            seat_number: PropTypes.string.isRequired
        })),
        legId: PropTypes.string,
        showSeatsThreshold: PropTypes.bool.isRequired,
        lowestPrice: PropTypes.number,
        showSeatSelector: PropTypes.bool,
        isTer: PropTypes.bool,
        router: PropTypes.object
    }

    constructor (...args) {
        super(...args)
        this.onClick = this.onClick.bind(this)
    }

    _renderTer () {
        return (
            <div className='grid-column--1-1'>
                <div className='selected-seat'>
                    <span className='text-label seat-number'>
                        <Icon type='passenger' className='passenger icon medium align-left' />
                        <span>{_t.getIntlMessage('booking-information.seats-ter')}</span>
                    </span>
                </div>
            </div>
        )
    }

    _isManuallySelectedSeat (seatNumber) {
        return this.props.manuallySelectedSeats.some(seat => seat.seat_number === seatNumber)
    }

    _renderBus () {
        return this.props.selectedSeats.map(seat => (
            <div
                key={[seat.seat_number, seat.carriage_number, seat.leg_id, seat.passenger_id].join(':')}
                className='grid-column--1-3'
            >
                <div className='selected-seat'>
                    <span className='text-label seat-number'>
                        <Icon type='passenger' className='passenger icon medium align-left' />
                        <span className='text'>
                            {this.props.showSeatsThreshold || this._isManuallySelectedSeat(seat.seat_number)
                                ? seat.seat_number
                                : _t.getIntlMessage('booking-information.seats-numero')
                            }
                        </span>
                    </span>
                </div>
            </div>
        ))
    }

    onClick (event) {
        event.preventDefault()
        actions.trackEvent('SeatOption', {
            basket_seat_option_action: 'Opened',
            basket_seat_price: this.props.lowestPrice
        })
        this.props.router.push(`/${_t.getLocales()}/mys3/seat-selector/${this.props.legId}`)
    }

    _renderSeatSelection () {
        return this.props.showSeatSelector
            ? (
                <a href='' className='seat-selection' onClick={this.onClick}>
                    <div className='panel'>
                        <div className='seat-selection--content'>
                            <Heading title={_t.message('seat-selection.heading')} />
                            <div className='text-label select-seats'>
                                <TextLabel text={_t.message('seat-selection.best-seats')} />
                            </div>
                            <div className='grid-row'>
                                <div className='grid-column--1-1'>
                                    <div className='text-label price-per-seat'>
                                        <TextLabel>
                                            <div className='text-label seat-from'>
                                                {this.props.lowestPrice ? <FormattedHTMLMessage
                                                    message={_t.formatIntlMessage('seat-selection.from', {
                                                        price: _t.formatCurrency(this.props.lowestPrice)
                                                    })} /> : null}
                                            </div>
                                        </TextLabel>
                                    </div>
                                </div>
                            </div>
                            <div className='grid-row'>
                                <div className='grid-column--1-1'>
                                    <span className='button secondary open-seat-selector'>
                                        <TextLabel text={_t.message('seat-selection.choose')} />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </a>
            )
            : null
    }

    render () {
        return (
            <div className='selected-seat-overview'>
                <div className='grid-row'>
                    {this.props.isTer ? this._renderTer() : this._renderBus()}
                </div>
                {this._renderSeatSelection()}
            </div>
        )
    }
}

export default withRouter(SelectionInformation)
