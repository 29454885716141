import PropTypes from 'prop-types'
import React, { Component } from 'react'
import InputField from './input-field'

export default class NumberField extends Component {
    static propTypes = {
        onChange: PropTypes.func,
        value: PropTypes.number,
        defaultValue: PropTypes.number,
        decimals: PropTypes.number,
        min: PropTypes.number,
        max: PropTypes.number
    }

    static defaultProps = {
        decimals: 2
    }

    constructor (props) {
        super(props)

        this.state = {
            value: props.value || props.defaultValue
        }

        this.onChange = this.onChange.bind(this)
        this.getInputElement = this.getInputElement.bind(this)
        this.getValue = this.getValue.bind(this)
        this.setValue = this.setValue.bind(this)
        this.formatValue = this.formatValue.bind(this)
        this.focus = this.focus.bind(this)
    }

    componentWillReceiveProps (nextProps) {
        if (this.props.defaultValue !== nextProps.defaultValue && !this.props.value) {
            this.setValue(nextProps.defaultValue)
        } else if (this.props.value !== nextProps.value) {
            this.setValue(nextProps.value)
        }
    }

    onChange (event) {
        if (this.props.onChange) {
            this.props.onChange(event)
        }

        this.setValue(event.target.value)
    }

    render () {
        const props = {...this.props}
        delete props.type
        delete props.decimals

        return <InputField
            ref='inputField'
            type='text'
            {...props}
            onChange={this.onChange}
            value={this.state.value}
        />
    }

    getInputElement () {
        return this.refs.inputField && this.refs.inputField.getInputElement()
    }

    getValue () {
        return this.state.value
    }

    setValue (value) {
        this.setState({value: this.formatValue(value)})
    }

    formatValue (value) {
        const {min, max, decimals} = this.props
        const isValidRegex = new RegExp(`^\\d*(?:\\.\\d{0,${decimals}})?$`, 'g')
        const endWithDotRegex = new RegExp(`^\\d+\\.$`, 'g')

        let formattedValue = value
        formattedValue = formattedValue.toString()
        formattedValue = formattedValue.replace(',', '.')

        if (
            isNaN(parseFloat(formattedValue)) &&
            !endWithDotRegex.test(formattedValue) &&
            formattedValue !== ''
        ) {
            return this.state.value.toString() || ''
        } else if (!isValidRegex.test(formattedValue)) {
            return this.state.value.toString() || ''
        }

        if (min && formattedValue < min) {
            formattedValue = min
        } else if (max && formattedValue > max) {
            formattedValue = max
        }

        return formattedValue
    }

    focus () {
        return this.refs.inputField && this.refs.inputField.focus()
    }
}
