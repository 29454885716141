import ReactDOM from 'react-dom'
import _ from 'lodash'
import device from '../../device'
import _t from '../../translate'
import moment from 'moment-timezone'
import classNames from 'classnames'
import React, {Component} from 'react'
import PropTypes from 'prop-types'

var $ = require('jquery')
require('jquery-ui/ui/widgets/datepicker')

export default class jQueryUIDatePicker extends Component {
    static propTypes = {
        readOnly: PropTypes.bool,
        forceAppendToDiv: PropTypes.bool,
        value: PropTypes.any,
        placeholder: PropTypes.string,
        required: PropTypes.bool,
        datePickerOptions: PropTypes.object,
        autoWidth: PropTypes.bool,
        fixPosition: PropTypes.bool,
        appendToClassName: PropTypes.string,
        disabled: PropTypes.bool,
        onFocus: PropTypes.func,
        id: PropTypes.string,
        onChange: PropTypes.func,
        onBlur: PropTypes.func,
        errorText: PropTypes.string,
        className: PropTypes.string
    }

    _toDate (date) {
        return moment.isMoment(date) ? date.toDate() : date
    }

    static defaultProps = {
        readOnly: !device.isDesktopOrTablet(),
        required: false,
        forceAppendToDiv: !device.isDesktopOrTablet(),
        placeholder: '',
        disabled: false,
        autoWidth: true,
        fixPosition: true,
        id: '',
        onFocus: () => {
        },
        datePickerOptions: {}

    }

    constructor (...args) {
        super(...args)
        this.state = {value: this.getDateStringFromDate(this.props.value)}
        this.fixWidth = this.fixWidth.bind(this)
        this.getDatePickerOptions = this.getDatePickerOptions.bind(this)
        this.getClassName = this.getClassName.bind(this)
        this.getJQueryNode = this.getJQueryNode.bind(this)
        this.getErrorFeedback = this.getErrorFeedback.bind(this)
        this.getInput = this.getInput.bind(this)
        this.handleResize = this.handleResize.bind(this)
        this.fixPosition = this.fixPosition.bind(this)
        this.resetPosition = this.resetPosition.bind(this)
        this.resetWidth = this.resetWidth.bind(this)
        this.datePickerIsOpen = this.datePickerIsOpen.bind(this)
        this.datePickerBeforeShow = this.datePickerBeforeShow.bind(this)
        this.datePickerOnClose = this.datePickerOnClose.bind(this)
        this.datePickerOnSelect = this.datePickerOnSelect.bind(this)
        this.onChange = this.onChange.bind(this)
        this.onBlur = this.onBlur.bind(this)
        this.triggerChange = this.triggerChange.bind(this)
        this.getDateFromDateString = this.getDateFromDateString.bind(this)
        this.getDateStringFromDate = this.getDateStringFromDate.bind(this)
    }

    componentWillReceiveProps (nextProps) {
        if (this.getDateStringFromDate(nextProps.value) !== this.state.value) {
            this.setState({value: this.getDateStringFromDate(nextProps.value)})
        }

        if (!_.isEqual(nextProps.datePickerOptions, this.props.datePickerOptions)) {
            this.getJQueryNode().datepicker('option', this.getDatePickerOptions(nextProps.datePickerOptions))
        }
    }

    loadLocalization () {
        var language = _t.getLanguage()
        if (language) {
            delete require.cache[require.resolve(`jquery-ui/ui/i18n/datepicker-${language}`)]
            require(`jquery-ui/ui/i18n/datepicker-${language}`)
        }
    }

    componentDidMount () {
        window.addEventListener('resize', this.handleResize)

        this.getJQueryNode().datepicker(this.getDatePickerOptions(this.props.datePickerOptions))
        this.loadLocalization()

        if (this.props.forceAppendToDiv && this.props.appendToClassName) {
            this.appendDiv = $('<div class="' + this.props.appendToClassName + '">')
            this.getJQueryNode().parent().append(this.appendDiv)
        }
    }

    getDatePickerOptions (options) {
        return $.extend(
            {
                showButtonPanel: false,
                changeMonth: false,
                changeYear: false,
                dateFormat: 'dd-mm-yy',
                showAnim: ''
            },
            options,
            {
                minDate: options.minDate && this._toDate(options.minDate),
                maxDate: options.maxDate && this._toDate(options.maxDate),
                onSelect: this.datePickerOnSelect,
                beforeShow: this.datePickerBeforeShow,
                onClose: this.datePickerOnClose
            }
        )
    }

    getClassName () {
        const isValid = !this.props.errorText || this.props.errorText.length === 0
        return classNames(this.props.className, {
            'state--is-invalid': !isValid
        })
    }

    getJQueryNode () {
        return $(ReactDOM.findDOMNode(this.getInput()))
    }

    componentWillUpdate () {
        this.loadLocalization()
    }

    componentWillUnmount () {
        window.removeEventListener('resize', this.handleResize)
        this.getJQueryNode().datepicker('destroy')
    }

    getErrorFeedback () {
        if (!this.props.errorText || this.props.errorText.length === 0) {
            return null
        }

        return <div className='input-feedback state--is-invalid'>{_t.getIntlMessage(this.props.errorText)}</div>
    }

    render () {
        return (
            <div>
                <input
                    id={this.props.id}
                    type='text'
                    className={this.getClassName()}
                    value={this.state.value}
                    required={this.props.required}
                    disabled={this.props.disabled}
                    placeholder={this.props.placeholder}
                    autoComplete='off'
                    onBlur={this.onBlur}
                    onChange={this.onChange}
                    onFocus={this.props.onFocus}
                    readOnly={this.props.readOnly}
                    ref='dateField'
                />
                {this.getErrorFeedback()}
            </div>
        )
    }

    getInput () {
        return this.refs.dateField
    }

    handleResize () {
        if (device.isDesktopOrTablet()) {
            this.getJQueryNode().datepicker('hide')
        }
    }

    fixWidth () {
        if (device.isDesktopOrTablet() && this.props.autoWidth) {
            this.getJQueryNode().datepicker('widget').css('min-width', this.getJQueryNode().outerWidth() + 'px')
        }
    }

    fixPosition () {
        if (device.isDesktopOrTablet() && this.props.fixPosition) {
            this.backupCheckOffsetFunction = $.datepicker._checkOffset
            $.extend($.datepicker, {
                _checkOffset: (_, offset) => {
                    return offset
                }
            })
        }
    }

    resetPosition () {
        if (device.isDesktopOrTablet() && this.props.fixPosition) {
            $.datepicker._checkOffset = this.backupCheckOffsetFunction
        }
    }

    resetWidth () {
        if (device.isDesktopOrTablet() && this.props.autoWidth) {
            this.getJQueryNode().datepicker('widget').css('min-width', '')
        }
    }

    datePickerIsOpen () {
        return this.getJQueryNode().datepicker('widget').is(':visible')
    }

    datePickerBeforeShow (dateText, inst) {
        this.fixWidth()
        this.fixPosition()
        if (this.appendDiv) {
            $(inst.dpDiv).appendTo(this.appendDiv)
        }
        if (typeof this.props.datePickerOptions.beforeShow === 'function') {
            this.props.datePickerOptions.beforeShow(dateText, inst)
        }
    }

    datePickerOnClose (dateText, inst) {
        this.resetWidth()
        this.resetPosition()
        if (this.appendDiv) {
            setTimeout(function () {
                $(inst.dpDiv).appendTo($('body'))
            }, 100)
        }

        this.getJQueryNode().blur()

        if (typeof this.props.datePickerOptions.onClose === 'function') {
            this.props.datePickerOptions.onClose(dateText, inst)
        }
    }

    datePickerOnSelect (dateText) {
        this.setState({value: dateText}, this.triggerChange)
    }

    onChange (event) {
        this.setState({value: event.target.value})
    }

    onBlur () {
        if (!this.datePickerIsOpen()) {
            this.triggerChange()
        }
        if (typeof this.props.onBlur === 'function') {
            this.props.onBlur()
        }
    }

    triggerChange () {
        if (typeof this.props.onChange === 'function') {
            this.props.onChange(this.getDateFromDateString(this.state.value))
        }
    }

    /**
     * @param {string} dateString
     * @returns {null | Date}
     */
    getDateFromDateString (dateString) {
        const options = this.getDatePickerOptions(this.props.datePickerOptions)
        try {
            return $.datepicker.parseDate(options.dateFormat || 'dd-mm-yy', dateString)
        } catch (e) {
            return null
        }
    }

    /**
     * @param {Date} date
     * @returns {string}
     */
    getDateStringFromDate (date) {
        const options = this.getDatePickerOptions(this.props.datePickerOptions)
        return $.datepicker.formatDate(options.dateFormat || 'dd-mm-yy', this._toDate(date))
    }
}
