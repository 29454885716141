// Libraries
import React, {Component} from 'react'
import PropTypes from 'prop-types'
import _t from '../../../translate'
import BookingModel from '../../../models/booking-model'
// Components
import TextLabel from '../../../element/text-label'

export default class Footer extends Component {
    static propTypes = {
        booking: PropTypes.instanceOf(BookingModel).isRequired
    }

    constructor (...args) {
        super(...args)
        this._renderAmountDue = this._renderAmountDue.bind(this)
    }

    render () {
        const amount = -this.props.booking.amountReplacedTickets - this.props.booking.amountProvisionalCancelledFees

        return (
            <tfoot>
                <tr className='refund'>
                    <td>&nbsp;</td>
                    <td>
                        <span className='text-label refund'>
                            <TextLabel text={_t.getIntlMessage('my-s3-cancellation.confirm.refund')} />
                        </span>
                    </td>
                    <td>
                        <span className='text-label refund-price'>
                            <TextLabel text={_t.formatCurrency(amount)} />
                        </span>
                    </td>
                </tr>
                <tr>
                    <td>&nbsp;</td>
                    <td>
                        <span className='text-label cancellation-fees'>
                            <TextLabel text={_t.getIntlMessage('my-s3-cancellation.confirm.fee')} />
                        </span>
                    </td>
                    <td>
                        <span className='text-label cancellation-fees-price'>
                            <TextLabel text={_t.formatCurrency(this.props.booking.applicableFeePrice)} />
                        </span>
                    </td>
                </tr>
                {this._renderAmountDue()}
            </tfoot>
        )
    }

    _renderAmountDue () {
        const labelKey = this.props.booking.totalPriceToBePaid < 0
            ? 'my-s3-cancellation.confirm.price-to-be-refunded'
            : 'my-s3-cancellation.confirm.price-to-be-paid'

        return (
            <tr>
                <td>&nbsp;</td>
                <td>
                    <span className='text-label total-price'>
                        <TextLabel text={_t.getIntlMessage(labelKey)} />
                    </span>
                </td>
                <td>
                    <span className='text-label total-price'>
                        <TextLabel text={_t.formatCurrency(Math.abs(this.props.booking.totalPriceToBePaid))} />
                    </span>
                </td>
            </tr>
        )
    }
}
