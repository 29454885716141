import PropTypes from 'prop-types'
import React, {Component} from 'react'
import className from 'classnames'
import actions from '../../reflux/actions'
import SeatSummaryDirection from './seat-summary-direction'
import SeatSummaryTotals from './seat-summary-totals'
import {deconstructLegId} from '../../misc/segment'
import {
    sortPassengers
} from '../../misc/helpers'

export default class SeatSummary extends Component {
    static propTypes = {
        booking: PropTypes.object.isRequired,
        passengers: PropTypes.array.isRequired,
        seatSelectorRoute: PropTypes.string,
        availableSections: PropTypes.array,
        readOnly: PropTypes.bool,
        viewVertical: PropTypes.bool,
        rebookFlow: PropTypes.bool
    }

    static defaultProps = {
        readOnly: false,
        viewVertical: false
    }

    constructor (...args) {
        super(...args)

        this.handleClickRemove = this.handleClickRemove.bind(this)
    }

    async handleClickRemove (itemRef) {
        actions.deleteItems(this.props.booking.bookingNumber, {
            'item_refs': [
                itemRef
            ]
        })
    }

    async getCarriage (legId) {
        const leg = deconstructLegId(legId)
        const carriageLayout = await actions.getCarriageLayouts({
            serviceName: leg.serviceName,
            fromStationUIC: leg.originCode,
            toStationUIC: leg.destinationCode,
            travelDate: leg.serviceScheduleDate
        })
        return carriageLayout.carriages[0]
    }

    render () {
        const {booking, availableSections, readOnly, viewVertical, rebookFlow} = this.props
        const selectedSeats = booking.tariffSegmentCollection.selectedManualSelectedSeats.filter(seat => !seat.cancelled)
        const travels = booking.seatSelectionOptions(rebookFlow).groupBy('direction').value()
        const passengers = sortPassengers(this.props.passengers)
        return (
            <div className={className('seat-summary', {
                'seat-summary--vertical': viewVertical,
                'seat-summary--read-only': readOnly
            })}>
                {Object.keys(travels).map(direction => (
                    <SeatSummaryDirection
                        key={direction}
                        direction={direction}
                        travel={travels[direction]}
                        passengers={passengers}
                        selectedSeats={selectedSeats}
                        onClickRemove={this.handleClickRemove}
                        availableSections={availableSections}
                        readOnly={readOnly}
                        seatSelectorRoute={this.props.seatSelectorRoute}
                    />
                ))}
                <SeatSummaryTotals selectedSeats={selectedSeats} />
            </div>
        )
    }
}
