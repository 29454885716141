import AccessManager from '../data/access-manager'
import {PROVIDER_REACH_FIVE} from '../constants'
import actions from '../reflux/actions'
import Reach5Store from '../reflux/stores/reach-5-store'
import oauth from '../oauth'

let pendingRequest
const reach5Login = async redirectUrl => {
    if (
        (AccessManager.isPublicUser() || !AccessManager.isAuthenticated()) &&
        Reach5Store.hasIdToken() &&
        !oauth.hasPendingRequest() &&
        !Reach5Store.hasPendingRequest() &&
        !pendingRequest
    ) {
        pendingRequest = oauth.crmLogin({provider: PROVIDER_REACH_FIVE, token: Reach5Store.getIdToken()})
    } else if (!pendingRequest) {
        // No SSO Cookie or already signed in.
        return Promise.resolve()
    }

    try {
        await pendingRequest
    } catch (_) {
        Reach5Store.clearIdTokenCookie()
    }

    await actions.loadUserLoginInformation()
    if (AccessManager.isCrmUser()) {
        actions.loadCrmUserInformation()
    }
    pendingRequest = null
    return redirectUrl && window.reacthistory.push(redirectUrl)
}

export default reach5Login
