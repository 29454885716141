import React from 'react'
import PropTypes from 'prop-types'
import _t from '../../translate'
import Loader from '../../element/loader'
import TextLabel from '../../element/text-label'
import actions from '../../reflux/actions'
import {addFavoriteStations} from '../../misc/favorite-stations-helper'
import Reflux from 'reflux'
import Reach5Widget from '../../components/reach-5-widget/reach-5-widget'
import Reach5Store from '../../reflux/stores/reach-5-store'
import BookingStore from '../../reflux/stores/booking-store'
import CrmUserStore from '../../reflux/stores/crm-user-store'
import {isBlablabusDomain} from '../../misc/blablabus-helpers'
import createReactClass from 'create-react-class'

export default createReactClass({

    displayName: 'Reach5',

    listenables: actions,

    mixins: [
        Reflux.connectFilter(Reach5Store, 'userLoggedIn', data => data.userLoggedIn),
        Reflux.listenTo(actions.loadCrmUserInformation.completed, 'onLoadCrmUserInformationCompleted')
    ],

    propTypes: {
        loading: PropTypes.bool
    },

    onLoadCrmUserInformationCompleted () {
        if (!BookingStore.isLoading() && !BookingStore.hasCrmCustomerId()) {
            actions.updateCustomer(BookingStore.getBookingNumber(), {
                email: CrmUserStore.getCrmCustomer().email
            })
            addFavoriteStations()
        }
    },

    _renderCta () {
        return (
            <span className='text-label save-booking-information'>
                <TextLabel
                    text={_t.message('booking-confirmation.reach-5.booking-information')}
                />
            </span>
        )
    },

    render () {
        return !this.state.userLoggedIn && !isBlablabusDomain() ? (
            <div className='booking-confirmation.reach-5'>
                <div className='panel'>
                    <header>
                        <h2>{_t.message('booking-confirmation.reach-5.header')}</h2>
                    </header>
                    <div className='content'>
                        <div className='custom-content'>
                            {this.props.loading ? <Loader /> : (
                                <div className='grid-row'>
                                    <div className='grid-column--1-1'>
                                        <Reach5Widget>
                                            {this._renderCta()}
                                        </Reach5Widget>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        ) : null
    }
})
