import React, {Component} from 'react'
import PropTypes from 'prop-types'
import _t from '../../translate'
import _ from 'lodash'

import Button from '../../element/button'
import TextField from '../../element/form/text-field'
import TextLabel from '../../element/text-label'

import actions from '../../reflux/actions'

export default class PassengerFormContainer extends Component {
    static propTypes = {
        serviceError: PropTypes.string,
        errors: PropTypes.object,
        loading: PropTypes.bool.isRequired,
        onLogin: PropTypes.func.isRequired
    }

    static defaultProps = {
        serviceError: null,
        errors: {}
    }

    constructor (...args) {
        super(...args)
        this.onLogin = this.onLogin.bind(this)
        this.renderServiceError = this.renderServiceError.bind(this)
    }

    render () {
        return (
            <form action='#' noValidate>
                {this.renderServiceError()}
                <div className='grid-row'>
                    <div className='grid-column--1-3'>
                        <label className='asterisk-after inline-label' htmlFor='bookingReference'>
                            {_t.getIntlMessage('booking-login.login-booking-reference.label')}
                        </label>
                    </div>
                    <div className='grid-column--2-3'>
                        <TextField
                            ref='bookingReference'
                            id='bookingReference'
                            errorText={_.get(this.props.errors, 'bookingReference', null)}
                            placeholder={'booking-login.login-booking-reference.placeholder'}
                            className='login-booking-reference'
                            required
                            onBlur={this.onBlur}
                            autoComplete='off'
                        />
                        <span className='text-label sub-label'>
                            <TextLabel text={_t.getIntlMessage('booking-login.login-booking-reference.sub-label')} />
                        </span>
                    </div>
                </div>
                <div className='grid-row'>
                    <div className='grid-column--1-3'>
                        <label className='asterisk-after inline-label' htmlFor='firstName'>
                            {_t.getIntlMessage('booking-login.login-first-name.label')}
                        </label>
                    </div>
                    <div className='grid-column--2-3'>
                        <TextField
                            ref='firstName'
                            id='firstName'
                            errorText={_.get(this.props.errors, 'firstName', null)}
                            placeholder={'booking-login.login-first-name.placeholder'}
                            className='login-first-name'
                            required
                            onBlur={this.onBlur}
                            autoComplete='off'
                        />
                        <span className='text-label sub-label'>
                            <TextLabel text={_t.getIntlMessage('booking-login.login-first-name.sub-label')} />
                        </span>
                    </div>
                </div>
                <div className='grid-row'>
                    <div className='grid-column--1-3'>
                        <label className='asterisk-after inline-label' htmlFor='lastName'>
                            {_t.getIntlMessage('booking-login.login-last-name.label')}
                        </label>
                    </div>
                    <div className='grid-column--2-3'>
                        <TextField
                            ref='lastName'
                            id='lastName'
                            errorText={_.get(this.props.errors, 'lastName', null)}
                            placeholder={'booking-login.login-last-name.placeholder'}
                            className='login-last-name'
                            required
                            onBlur={this.onBlur}
                            autoComplete='off'
                        />
                        <span className='text-label sub-label'>
                            <TextLabel text={_t.getIntlMessage('booking-login.login-last-name.sub-label')} />
                        </span>
                    </div>
                </div>
                <div className='grid-row'>
                    <div className='grid-column--1-1'>
                        <Button
                            loading={this.props.loading} type='submit' className='button primary'
                            onClick={this.onLogin}
                        >
                            <TextLabel text={_t.getIntlMessage('booking-login.button')} />
                        </Button>
                    </div>
                </div>
            </form>
        )
    }

    onLogin (event) {
        event.preventDefault()
        this.props.onLogin([
            {id: 'bookingReference', value: this.refs.bookingReference.getValue() || ''},
            {id: 'firstName', value: this.refs.firstName.getValue() || ''},
            {id: 'lastName', value: this.refs.lastName.getValue() || ''}
        ])
    }

    onBlur (event) {
        actions.processBookingLoginData([{id: event.target.id, value: event.target.value}])
    }

    renderServiceError () {
        return this.props.serviceError ? <div className='feedback error'><p>{this.props.serviceError}</p></div> : null
    }
}
