import React, {Component} from 'react'
import PropTypes from 'prop-types'
import TextLabel from '../../../../element/text-label'

import _t from '../../../../translate'
import device from '../../../../device'

export default class Transfers extends Component {
    static propTypes = {
        transfers: PropTypes.array.isRequired
    }

    render () {
        return device.isDesktopOrTablet() ? (
            <div className='journey-result-option-item number-of-transfers'>
                <span className='text-label number-of-transfers'>
                    <TextLabel
                        title={_t.formatIntlMessage(
                            'journey-result-set.transfers-text',
                            {
                                count: this.props.transfers.length
                            }
                        )}
                        text={_t.formatIntlMessage(
                            'journey-result-set.transfers-text',
                            {
                                count: this.props.transfers.length
                            }
                        )}
                    />
                </span>
            </div>
        ) : (
            <div className='journey-result-option-item number-of-transfers'>
                <span className='text-label number-of-transfers'>
                    <TextLabel
                        text={_t.formatIntlMessage(
                            'journey-result-set.transfers-text',
                            {
                                count: this.props.transfers.length
                            }
                        )}
                    />
                </span>
            </div>
        )
    }
}
