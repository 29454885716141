import React, {Component} from 'react'
import PropTypes from 'prop-types'

import OriginDestinationContainer from './origin-destination-container'

export default class OriginDestination extends Component {
    static propTypes = {
        stations: PropTypes.array,
        origin: PropTypes.string,
        destination: PropTypes.string,
        onOriginSelected: PropTypes.func.isRequired,
        onDestinationSelected: PropTypes.func,
        onSwapOriginAndDestination: PropTypes.func
    }

    static defaultProps = {
        stations: [],
        origin: '',
        destination: ''
    }

    constructor (...args) {
        super(...args)
        this.isOriginAndDestinationSelected = this.isOriginAndDestinationSelected.bind(this)
        this.getStationSelectData = this.getStationSelectData.bind(this)
        this.getOriginStationData = this.getOriginStationData.bind(this)
        this.getDestinationStationData = this.getDestinationStationData.bind(this)
        this.onOriginSelected = this.onOriginSelected.bind(this)
        this.onDestinationSelected = this.onDestinationSelected.bind(this)
        this.onSwapOriginAndDestination = this.onSwapOriginAndDestination.bind(this)
    }

    isOriginAndDestinationSelected () {
        return this.props.origin.length > 0 && this.props.destination.length > 0
    }

    getStationSelectData () {
        return this.props.stations.map(function (station) {
            return {
                value: station._u_i_c_station_code,
                text: station.name,
                disabled: false,
                isMain: typeof station.parent === 'undefined',
                data: station
            }
        })
    }

    getOriginStationData () {
        return this.getStationSelectData().filter(item => item.value !== this.props.destination)
    }

    getDestinationStationData () {
        return this.getStationSelectData().filter(item => item.value !== this.props.origin)
    }

    render () {
        return (
            <OriginDestinationContainer
                getOriginStationData={this.getOriginStationData}
                origin={this.props.origin}
                destination={this.props.destination}
                getDestinationStationData={this.getDestinationStationData}
                onOriginSelected={this.onOriginSelected}
                onDestinationSelected={this.onDestinationSelected}
                onSwapOriginAndDestination={this.onSwapOriginAndDestination}
                isOriginAndDestinationSelected={this.isOriginAndDestinationSelected}
            />
        )
    }

    onOriginSelected (origin, callback) {
        if (typeof this.props.onOriginSelected === 'function') {
            this.props.onOriginSelected(origin, callback)
        }
    }

    onDestinationSelected (destination, callback) {
        if (typeof this.props.onDestinationSelected === 'function') {
            this.props.onDestinationSelected(destination, callback)
        }
    }

    onSwapOriginAndDestination (event) {
        event.preventDefault()
        if (typeof this.props.onSwapOriginAndDestination === 'function') {
            this.props.onSwapOriginAndDestination()
        }
    }
}
