import React, {Component} from 'react'
import PropTypes from 'prop-types'
import GridRow from './grid-row'

export default class AmountDue extends Component {
    static propTypes = {
        amount: PropTypes.number.isRequired
    }

    render () {
        return (
            <div className='amount-due'>
                <GridRow
                    messageLabel='booking-specification.amount-due'
                    value={this.props.amount}
                    className='text-label booking-specification-value journey-total-price-value'
                />
            </div>
        )
    }
}

