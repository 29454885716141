import PropTypes from 'prop-types'
import React from 'react'
import Reflux from 'reflux'
import CmsStore from '../../reflux/stores/cms-store'
import createReactClass from 'create-react-class'

export default Component => {
    const displayName = `CmsContainer(${Component.displayName || Component.name || 'Component'})`

    return createReactClass({
        displayName,

        propTypes: {
            name: PropTypes.string.isRequired,
            values: PropTypes.object
        },

        contextTypes: {
            requireCmsBlocks: PropTypes.func
        },

        mixins: [
            Reflux.listenTo(CmsStore, 'onCmsBlocksUpdated')
        ],

        getInitialState () {
            return this._getStateFromStoreData(CmsStore.getInitialState())
        },

        onCmsBlocksUpdated (data) {
            this.setState(this._getStateFromStoreData(data))
        },

        componentDidMount () {
            this.context.requireCmsBlocks && this.context.requireCmsBlocks([this.props.name])
        },

        componentWillReceiveProps (nextProps) {
            if (this.props.name !== nextProps.name) {
                this.context.requireCmsBlocks([nextProps.name])
            }
        },

        _getStateFromStoreData (data) {
            return {
                loading: data.loading,
                cmsBlock: data.cmsBlocks && data.cmsBlocks.find(cmsBlock => cmsBlock.name === this.props.name)
            }
        },

        _getContent () {
            const content = this.state.cmsBlock && this.state.cmsBlock.content
            if (content && this.props.values) {
                return Object.keys(this.props.values).reduce(
                    // eslint-disable-next-line no-useless-escape
                    (formattedContent, key) => formattedContent.replace(`$\{${key}\}`, this.props.values[key]),
                    content
                )
            }

            return content
        },

        render () {
            const props = {
                ...this.props,
                loading: this.state.loading,
                content: this._getContent(),
                title: this.state.cmsBlock && this.state.cmsBlock.title
            }

            return <Component {...props} />
        }
    })
}
