import moment from 'moment-timezone'
import {BIG_GROUP_SIZE, THRESHOLD_DAYS, THRESHOLD_MINUTES} from '../constants'

/**
 * @param {Object} bundle
 * @param {Moment} departureDateTime
 * @param {int} passengerCount
 * @returns {boolean}
 */
export const isFlexBundle = (bundle, departureDateTime, passengerCount) => !(
    bundle.isPromo ||
    departureDateTime.diff(moment(), 'minutes', true) <= THRESHOLD_MINUTES ||
    (isBigGroup(passengerCount) && departureDateTime.diff(moment(), 'days', true) <= THRESHOLD_DAYS)
)

/**
 * @param {SegmentCollection} segmentCollection
 * @param {int} passengerCount
 * @returns {boolean}
 */
export const isFlexSegmentCollection = (segmentCollection, passengerCount) => {
    const departureDateTime = moment(segmentCollection.departureDateTime)

    return !(
        segmentCollection.hasPromoTariff() ||
        departureDateTime.diff(moment(), 'minutes') <= THRESHOLD_MINUTES ||
        (isBigGroup(passengerCount) && departureDateTime.diff(moment(), 'days') <= THRESHOLD_DAYS)
    )
}

/**
 * @param {Moment|Date} departureDateTime
 * @param {int} passengerCount
 * @returns {Moment}
 */
export const getFlexUntilDate = (departureDateTime, passengerCount) => {
    const dateTime = moment(departureDateTime)
    const amount = isBigGroup(passengerCount) ? THRESHOLD_DAYS : THRESHOLD_MINUTES
    const unitOfTime = isBigGroup(passengerCount) ? 'day' : 'minutes'
    dateTime.add(amount, unitOfTime)

    return dateTime
}

/**
 * @param {int} passengerCount
 * @returns {boolean}
 */
export const isBigGroup = passengerCount => passengerCount >= BIG_GROUP_SIZE
