import PropTypes from 'prop-types'
import React, { Component } from 'react'
import SeatLegendProduct from './seat-legend-product'

export default class SeatLegend extends Component {
    static propTypes = {
        passengerFaresSeat: PropTypes.array,
        passengerFaresSeatPlus: PropTypes.array
    }

    getLowestSeatPrice (fares) {
        if (fares.length > 0) {
            return fares.reduce((min, fare) => fare.price < min ? fare.price : min, fares[0].price)
        }
    }

    render () {
        return (
            <div className='seat-legend'>
                {this.props.passengerFaresSeatPlus
                    ? <SeatLegendProduct
                        type='plus'
                        price={this.getLowestSeatPrice(this.props.passengerFaresSeatPlus)}
                    />
                    : null
                }
                {this.props.passengerFaresSeat
                    ? <SeatLegendProduct
                        type='standard'
                        price={this.getLowestSeatPrice(this.props.passengerFaresSeat)}
                    />
                    : null
                }
            </div>
        )
    }
}
