import React from 'react'
import {Route} from 'react-router'

import authCheck from '../../../mixins/auth'
import MyS3LuggageSelectorLayout from '../../../layouts/mys3/luggage/luggage-selector'

export default (
    <Route
        path='/:lang/mys3/luggage-selector'
        component={MyS3LuggageSelectorLayout}
        onEnter={(nextState, replaceState, callback) => authCheck(nextState, replaceState, callback)}
    />
)
