import isObject from 'lodash/isObject'
import { last } from 'lodash'

export const EXTERNAL_REFERENCE_FAVORITE_STATIONS = 'favoriteStations'
export const EXTERNAL_REFERENCE_DISCOUNT_CARD_CODE_DISCOUNT_CARD_NUMBER = 'MyCard'

export default class CrmCustomerModel {
    static create (data) {
        return new CrmCustomerModel(data)
    }

    constructor (data) {
        if (!isObject(data)) {
            throw Error('Customer should be an object')
        }

        this._data = data
    }

    buildMetaData (key, data) {
        const newMetaData = [...this.metaData]
        const metaObject = { key, value: data }

        const index = newMetaData.findIndex(data => data.key === key)
        if (newMetaData[index]) {
            newMetaData[index] = metaObject
        } else {
            newMetaData.push(metaObject)
        }

        return newMetaData
    }

    get rawData () {
        return this._data
    }

    get id () {
        return this._data.id
    }

    get firstName () {
        return this._data.first_name
    }

    get lastName () {
        return this._data.last_name
    }

    get dateOfBirth () {
        return this._data.birth_date
    }

    get gender () {
        return this._data.gender
    }

    get metaData () {
        return this._data.meta_data
    }

    getMetaDataValue (_key) {
        const externalReference = this.metaData && this.metaData.find(({key}) => key === _key)
        return externalReference && externalReference.value
    }

    getMetaDataObject (_key) {
        const value = this.getMetaDataValue(_key)
        try {
            return JSON.parse(value)
        } catch (error) {
            return null
        }
    }

    get favoriteStations () {
        return this.getMetaDataObject(EXTERNAL_REFERENCE_FAVORITE_STATIONS) || []
    }

    get discountCardNumber () {
        const myCard = this.getMetaDataValue(EXTERNAL_REFERENCE_DISCOUNT_CARD_CODE_DISCOUNT_CARD_NUMBER)

        return myCard && last(myCard.split('|'))
    }

    mergeFavoriteStationsWithMetaData (stations) {
        let stringifiedStations
        try {
            stringifiedStations = stations && stations.length > 0 && JSON.stringify(stations)
        } catch (error) {
            return null
        }

        if (!stringifiedStations) {
            return null
        }

        return this.buildMetaData(EXTERNAL_REFERENCE_FAVORITE_STATIONS, stringifiedStations)
    }
}
