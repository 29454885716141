import React from 'react'
import { Link } from 'react-router'
import _t from '../../../translate'

import TextLabel from '../../../element/text-label'
import AccessManager from '../../../data/access-manager'
import createReactClass from 'create-react-class'

export default createReactClass({

    displayName: 'UserNavigationBookingItem',

    render () {
        const props = {
            to: `/${_t.getLocales()}/booking-login`
        }
        if (AccessManager.isCrmUser()) {
            props.to = 'https://www.blablacar.com/bus'
            props.target = '_blank'
        }

        return (
            <li>
                <Link {...props} className='link'>
                    <span className='text-label'>
                        <TextLabel text={_t.getIntlMessage('user-navigation.my-bookings.label')} />
                    </span>
                </Link>
            </li>
        )
    }
})
