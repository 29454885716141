// Libraries
import React, {Component} from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

// Components
import TextLabel from '../../../element/text-label'
import CmsBlockContent from '../../../components/cms/cms-block-content'

export default class LegalIten extends Component {
    static propTypes = {
        url: PropTypes.string,
        onClick: PropTypes.func,
        label: PropTypes.string,
        cmsBlockName: PropTypes.string,
        className: PropTypes.string
    }

    render () {
        const {className, url, onClick, ...props} = this.props
        props.className = classNames('link', className)

        if (url) {
            props.href = url
            props.target = '_blank'
        } else if (onClick) {
            props.href = '#'
            props.onClick = onClick
        }

        return (
            <li>
                {this.props.cmsBlockName
                    ? <CmsBlockContent name={this.props.cmsBlockName} />
                    : (
                        <a {...props}>
                            <span className='text-label'>
                                <TextLabel text={this.props.label} />
                            </span>
                        </a>
                    )
                }
            </li>
        )
    }
}
