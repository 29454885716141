import React, {Component} from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import AccordionPanel from './accordion/panel'

export default class Accordion extends Component {
    static propTypes = {
        className: PropTypes.string
    }

    constructor (...args) {
        super(...args)
        this.state = {
            expandedPanel: null
        }
        this.onClick = this.onClick.bind(this)
        this.isPanelCollapsed = this.isPanelCollapsed.bind(this)
    }

    render () {
        const className = classNames('accordion', this.props.className)
        const children = React.Children.map(this.props.children, child => {
            if (child.type === AccordionPanel) {
                const props = {
                    collapsed: this.isPanelCollapsed(child.props.name),
                    onClick: this.onClick
                }
                return React.cloneElement(child, props)
            }
        })

        return <div className={className}>{children}</div>
    }

    onClick (_, panelName) {
        panelName = panelName || null
        if (this.state.expandedPanel === panelName) {
            panelName = null
        }

        this.setState({expandedPanel: panelName})
    }

    isPanelCollapsed (panelName) {
        return (
            this.state.expandedPanel === null ||
            panelName !== this.state.expandedPanel
        )
    }
}
