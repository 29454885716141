import _ from 'lodash'

export const prefixFieldLabel = (prefix, field, label) => {
    return label ? `${prefix}.${field}.${label}` : null
}

export const fieldPropsGenerator = (props, fieldName) => {
    const thisProps = Object.assign({}, {
        includeLabel: false,
        idPrefix: '',
        refPrefix: ''
    }, props)

    const {idPrefix, refPrefix, includeLabel, data, prefix, type, onBlur, onFocus, ref} = thisProps
    const kebabCase = _.kebabCase(fieldName)
    const createLabel = prefixFieldLabel.bind(this, prefix, kebabCase)

    let fieldProps = {
        id: `${idPrefix}${fieldName}`,
        ref: ref || `${refPrefix}${fieldName}`,
        required: data.fields[fieldName].isRequired,
        errorText: createLabel(data.fields[fieldName].errorText)
    }

    if (onFocus) {
        fieldProps.onFocus = onFocus
    }

    if (includeLabel) {
        fieldProps.labelText = createLabel('label')
    }

    switch (type || data.fields[fieldName].type) {
        case 'text':
            fieldProps = Object.assign({}, fieldProps, {
                placeholder: createLabel('placeholder'),
                defaultValue: data.fields[fieldName].value,
                onBlur: onBlur
            })
            break
        case 'select':
            fieldProps = Object.assign({}, fieldProps, {
                value: data.fields[fieldName].value,
                data: props.options ? props.options : data.fields[fieldName].options,
                onChange: onBlur
            })
            break
        case 'checkbox':
            fieldProps = Object.assign({}, fieldProps, {
                defaultChecked: data.fields[fieldName].value,
                onChange: onBlur
            })
            break
        case 'date':
            fieldProps = Object.assign({}, fieldProps, {
                value: data.fields[fieldName].value,
                placeholder: createLabel('placeholder'),
                onChange: onBlur
            })
            break
    }

    return fieldProps
}
