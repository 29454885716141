import PropTypes from 'prop-types'
import React, {Component} from 'react'
import _t from '../../../translate'
import _ from 'lodash'
import classNames from 'classnames'
import TextLabel from '../../../element/text-label'
import Button from '../../../element/button'
import Icon from '../../../element/icon'
import {isYouthWithDiscountPassenger} from '../../../misc/helpers'
import {
    PASSENGER_TYPE_YOUTH_WITH_DISCOUNT,
    PRODUCT_CODE_SEAT_SELECTION_PLUS
} from '../../../constants'
import actions from '../../../reflux/actions'

export default class Passenger extends Component {
    static propTypes = {
        passenger: PropTypes.object.isRequired,
        selectedPassenger: PropTypes.object.isRequired,
        selectedSeat: PropTypes.object,
        selectedLeg: PropTypes.string.isRequired,
        legId: PropTypes.string.isRequired,
        readOnly: PropTypes.bool.isRequired,
        editOnly: PropTypes.bool.isRequired,
        onClickSelect: PropTypes.func.isRequired,
        onClickRemove: PropTypes.func.isRequired,
        isRemoveLoading: PropTypes.bool.isRequired,
        passengerFaresSeat: PropTypes.array,
        passengerFaresSeatPlus: PropTypes.array,
        index: PropTypes.number.isRequired
    }

    constructor (props) {
        super(props)
        this.handleClickSelect = this.handleClickSelect.bind(this)
        this.handleClickRemove = this.handleClickRemove.bind(this)
    }

    handleClickSelect (event) {
        event && event.preventDefault()
        event && event.stopPropagation()
        this.props.onClickSelect(this.props.passenger)
    }

    handleClickRemove (event) {
        actions.trackEvent('SeatOption', {basket_seat_option_action: 'deleted', basket_seat_price: this.getSeatPrice()})

        event && event.preventDefault()
        event && event.stopPropagation()
        this.props.onClickRemove(this.props.passenger)
    }

    render () {
        const className = classNames(
            'passenger-item',
            {
                'state--is-selected':
                    !this.props.readOnly &&
                    this.props.selectedPassenger.id === this.props.passenger.id &&
                    this.props.selectedLeg === this.props.legId
            },
            {readOnly: this.props.readOnly}
        )
        return (
            <li onClick={this.handleClickSelect}>
                <div className={className}>
                    <div className='passenger'>
                        <span className='text-label passenger-name'>
                            {this.renderPassengerTextLabel()}
                        </span>
                        {this.props.selectedSeat
                            ? this.renderSeatLabel()
                            : this.renderBookSeatLabel()
                        }
                    </div>
                </div>
            </li>
        )
    }

    renderPassengerTextLabel () {
        const firstName = _.get(this.props.passenger, 'first_name', '')
        const lastName = _.get(this.props.passenger, 'last_name', '')
        const passengerType = isYouthWithDiscountPassenger(this.props.passenger)
            ? PASSENGER_TYPE_YOUTH_WITH_DISCOUNT : this.props.passenger.type
        const passengerTypeLabel = _t.message(`passenger-types.${passengerType.toLowerCase()}`)
        const text = (firstName !== '' && lastName !== '')
            ? `${firstName} ${lastName}`
            : `${passengerTypeLabel} ${this.props.index + 1}`
        return (
            <TextLabel text={text} />
        )
    }

    getSeatPrice () {
        const {selectedSeat, passenger} = this.props
        const fares = selectedSeat.seat_product_code === PRODUCT_CODE_SEAT_SELECTION_PLUS
            ? this.props.passengerFaresSeatPlus
            : this.props.passengerFaresSeat
        const fare = (fares || []).find(fare => fare.passengerId === passenger.id)

        return (fare && fare.price) || 0
    }

    renderSeatLabel () {
        const {readOnly, editOnly, selectedSeat} = this.props
        return !readOnly && !editOnly && selectedSeat.provisional !== false && selectedSeat.cancelled !== true
            ? (
                <div className='passenger-seat'>
                    <span key='seatDescription' className='text-label seat-description'>
                        <TextLabel text={_t.formatIntlMessage('passenger-selector.seat-description')} /> |
                    </span>
                    <span className='text-label selected-seat'>
                        <TextLabel
                            text={`${selectedSeat.seat_number} - ${_t.formatCurrency(this.getSeatPrice())}`} />
                    </span>
                    <Button
                        className='button remove-selected-seat clear'
                        onClick={this.handleClickRemove}
                        disabled={this.props.isRemoveLoading}
                        loading={this.props.isRemoveLoading}
                    >
                        <Icon className='trash icon small' type='trash' />
                    </Button>
                </div>
            )
            : (
                <div className='passenger-seat'>
                    <span key='seatDescription' className='text-label seat-description'>
                        <TextLabel text={_t.formatIntlMessage('passenger-selector.seat-description')} /> |
                    </span>
                    <span className='text-label selected-seat'>
                        <TextLabel text={selectedSeat.seat_number} />
                    </span>
                </div>
            )
    }

    renderBookSeatLabel () {
        return (
            <div className='book-seat'>
                {!this.props.readOnly && !this.props.editOnly
                    ? <a href='#' className='link'>{_t.getIntlMessage('seat-selector.book-seat')}</a>
                    : <TextLabel text='-'
                    />
                }
            </div>
        )
    }
}

