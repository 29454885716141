import React from 'react'
import PropTypes from 'prop-types'
import Reflux from 'reflux'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment-timezone'
import _t from '../translate'
import AccessManager from '../data/access-manager'
import ConfirmationLayoutContainer from './confirmation-layout-container'
import BookingStore from '../reflux/stores/booking-store'
import RefundVoucherStore from '../reflux/stores/refund-voucher-store'
import actions from '../reflux/actions'
import PrintWindow from '../misc/print-window'
import restoreBooking from '../components/restore/restore-booking'
import BookingModel from '../models/booking-model'
import createReactClass from 'create-react-class'

export default (afterSalesAction, options = {restoreBooking: false}) => {
    const component = createReactClass({

        displayName: 'AfterSalesConfirmationLayout',

        propTypes: {
            location: PropTypes.object
        },

        mixins: [
            Reflux.connectFilter(BookingStore, 'booking', data => BookingModel.create(data.booking)),
            Reflux.listenTo(RefundVoucherStore, 'onUpdateVoucherData')
        ],

        render () {
            return (
                <ConfirmationLayoutContainer
                    {...this.props}
                    booking={this.state.booking}
                    afterSalesAction={afterSalesAction}
                    filter={this.props.location.query ? this.props.location.query.filter : undefined}
                    handleRequestRefundVoucher={this._handleRequestRefundVoucher}
                    handlePrintRefundVoucher={this._handlePrintRefundVoucher}
                    handleCloseConfirmRefundVoucherDialog={this._handleCloseConfirmRefundVoucherDialog}
                    isLoadingRequestVoucher={this.state.voucherData.isLoadingRequestVoucher}
                    shouldPrint={this.state.voucherData.shouldPrint}
                    refundVoucher={this.state.voucherData.refundVoucher}
                />
            )
        },

        componentWillMount () {
            actions.clearRefundVoucher()
            if (this._isRefundVoucherAvailable(this.state.booking)) {
                actions.requestRefundVoucher(this.state.booking.bookingNumber)
            }

            this.printWindow = new PrintWindow(
                _t.message('booking-specification.refund.method-voucher.confirm-dialog.print-voucher-button')
            )
        },

        _isRefundVoucherAvailable (booking) {
            return (
                booking &&
                booking.openRefundAmount > 0 &&
                !isEmpty(booking.customer) &&
                AccessManager.hasAccessToAfterSalesRefundVoucher()
            )
        },

        getInitialState () {
            return {
                showRequestVoucherModalDialog: true,
                voucherData: RefundVoucherStore.getInitialState()
            }
        },

        onUpdateVoucherData (voucherData) {
            this.setState({
                voucherData: voucherData,
                showRequestVoucherModalDialog: (!voucherData.isLoadingRequestVoucher && voucherData.refundVoucher)
                    ? false
                    : this.state.showRequestVoucherModalDialog
            })
        },

        componentWillUpdate (_, nextState) {
            if (!nextState.voucherData.isLoadingRequestVoucher &&
                nextState.voucherData.refundVoucher &&
                nextState.voucherData.shouldPrint
            ) {
                this._printVoucher(nextState)
            }
        },

        _handleRequestRefundVoucher () {
            actions.requestRefundVoucher(this.state.booking.bookingNumber)
        },

        _handlePrintRefundVoucher () {
            this.printWindow.open()
            actions.requestRefundVoucher(this.state.booking.bookingNumber, true)
        },

        _handleCloseConfirmRefundVoucherDialog () {
            this.setState({showRequestVoucherModalDialog: false})
        },

        _printVoucher (state) {
            const voucherSalesEndDate = state.voucherData.refundVoucher.sales_period_end
                ? moment(state.voucherData.refundVoucher.sales_period_end).format('DD-MM-YYYY') : ''
            const customerName = `${state.booking.customer.first_name} ${state.booking.customer.last_name}`.trim()
            const customerTitle = `${state.booking.customer.title || ''} ${customerName}`.trim()

            this.printWindow.setContent(
                _t.message('booking-specification.refund.method-voucher.confirm-dialog.print-template', {
                    customerTitle,
                    voucherCode: state.voucherData.refundVoucher.code,
                    voucherAmount: state.voucherData.refundVoucher.amount,
                    voucherCurrencyCode: state.voucherData.refundVoucher.currency,
                    voucherSalesEndDate,
                    bookingNumber: state.booking.bookingNumber
                })
            )
        }
    })

    return options.restoreBooking ? restoreBooking(component, {withBlockingLoader: true}) : component
}
