import React, {Component} from 'react'
import _t from '../../../translate'
import PropTypes from 'prop-types'

// Components
import TextLabel from '../../../element/text-label'

export default class BookingNumber extends Component {
    static propTypes = {
        bookingNumber: PropTypes.string.isRequired
    }

    render () {
        return (
            <div className='booking-number'>
                <div className='grid-row'>
                    <div className='grid-column--2-3'>
                        <span className='text-label booking-number-description'>
                            <TextLabel text={_t.getIntlMessage('booking-specification.booking-number-description')} />
                        </span>
                    </div>
                    <div className='grid-column--1-3'>
                        <span className='text-label booking-number-value'>
                            <TextLabel text={this.props.bookingNumber} />
                        </span>
                    </div>
                </div>
            </div>
        )
    }
}

