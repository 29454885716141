import BookingModel from '../../models/booking-model'
import {
    luggageTotalPricePerPassengerPerDirectionSelector,
    provisionalLuggageProductsPerPassengerBookingForDirectionSelector,
    canOfferLuggageForDirectionSelector
} from '../../models/selectors/components/booking/luggage-selection'
import {TRAVEL_DIRECTION_INBOUND, TRAVEL_DIRECTION_OUTBOUND} from '../../models/selectors/constants'
import get from 'lodash/get'
import {getState} from '../bridge/connect-state'
import BookingStore from './booking-store'
import actions from '../actions'
import Reflux from 'reflux'

const createPassengerField = (direction, passenger) => {
    const firstName = get(passenger, 'first_name', '')
    const lastName = get(passenger, 'last_name', '')
    const fullName = `${firstName || ''} ${lastName || ''}`
    const products = provisionalLuggageProductsPerPassengerBookingForDirectionSelector(direction)(getState())

    return {
        id: `${direction}.${passenger.id}`,
        passengerId: passenger.id,
        value: products[passenger.id] ? products[passenger.id].amount : 0,
        fullName
    }
}

const LuggageStore = Reflux.createStore({

    listenables: actions,

    init () {
        this.listenTo(BookingStore, data => {
            if (!data.loading && data.error.length === 0 && data.booking.booking_number !== null) {
                this._resetData()
                this._loadFields(data.booking)
            }
        })

        this._resetData()
    },

    _resetData () {
        this.data = {
            passengerIds: [],
            hasOutbound: false,
            hasInbound: false,
            [TRAVEL_DIRECTION_OUTBOUND]: {},
            [TRAVEL_DIRECTION_INBOUND]: {}
        }
    },

    resetData () {
        this._resetData()
        this.trigger(this.data)
    },

    getInitialState () {
        return this.data
    },

    _loadFields (booking) {
        const bookingModel = BookingModel.create(booking)
        const passengers = bookingModel.passengers && bookingModel.passengers.values()
        this.data.hasOutbound = canOfferLuggageForDirectionSelector(TRAVEL_DIRECTION_OUTBOUND)(getState())
        this.data.hasInbound = canOfferLuggageForDirectionSelector(TRAVEL_DIRECTION_INBOUND)(getState())
        if (passengers) {
            this.data.passengerIds = passengers.value().map(({id}) => id)
            this.data[TRAVEL_DIRECTION_OUTBOUND] = this.data.hasOutbound
                ? passengers.reduce((_fields, passenger) => {
                    _fields[passenger.id] = createPassengerField(TRAVEL_DIRECTION_OUTBOUND, passenger)
                    return _fields
                }, {})
                : {}
            this.data[TRAVEL_DIRECTION_INBOUND] = this.data.hasInbound
                ? passengers.reduce((_fields, passenger) => {
                    _fields[passenger.id] = createPassengerField(TRAVEL_DIRECTION_INBOUND, passenger)
                    return _fields
                }, {})
                : {}
        }

        this.trigger(this.data)
    },

    onProcessLuggage (newData) {
        this.data[newData.direction] = {
            ...this.data[newData.direction],
            [newData.passengerId]: {
                ...this.data[newData.direction][newData.passengerId],
                value: newData.value
            }
        }

        this.trigger(this.data)
    },

    getPriceForDirectionAndPassengerId (direction, passengerId) {
        const luggagePricePerDirectionPerPassenger = luggageTotalPricePerPassengerPerDirectionSelector(getState())
        const price = get(luggagePricePerDirectionPerPassenger, `${direction}.${passengerId}`, 0)
        const amount = get(this.data, `${direction}.${passengerId}.value`, 0)

        return amount * price
    },

    getTotalPrice () {
        return this.data.passengerIds.reduce((total, passengerId) => {
            const outboundPrice = this.getPriceForDirectionAndPassengerId(TRAVEL_DIRECTION_OUTBOUND, passengerId)
            const inboundPrice = this.getPriceForDirectionAndPassengerId(TRAVEL_DIRECTION_INBOUND, passengerId)
            return total + outboundPrice + inboundPrice
        }, 0)
    }
})

export default LuggageStore
