import React, {Component} from 'react'
import PropTypes from 'prop-types'
import DatePicker from '../../element/jquery/date-picker'
import Button from '../../element/button'
import classNames from 'classnames'
import _t from '../../translate'

export default class JourneySearchOutboundInboundDate extends Component {
    static propTypes = {
        outboundDate: PropTypes.instanceOf(Date),
        inboundDate: PropTypes.instanceOf(Date),
        showInboundDate: PropTypes.bool,
        numberOfMonths: PropTypes.number,
        onChange: PropTypes.func,
        dateFormat: PropTypes.string
    }

    static defaultProps = {
        showInboundDate: true,
        numberOfMonths: 1,
        dateFormat: 'dd-mm-yy'
    }

    render () {
        return (
            <div className='outbound-inbound'>
                <div className='outbound'>
                    <label htmlFor='journey-search-outbound-date' className='text-label outbound-date'
                    >{_t.getIntlMessage('journey-search.outbound-date.label')}</label>
                    <DatePicker
                        value={this.props.outboundDate}
                        id='journey-search-outbound-date'
                        name='journey-search-outbound-date'
                        className='journey-search-outbound-date journey-search-date-picker'
                        placeholder={_t.getIntlMessage('journey-search.outbound-date.placeholder')}
                        autoComplete='off'
                        onChange={date => this.props.onChange({outboundDate: date})}
                        required
                        readOnly
                        appendToClassName='journey-search-outbound-datepicker'
                        datePickerOptions={{
                            minDate: 0,
                            numberOfMonths: this.props.numberOfMonths,
                            dateFormat: this.props.dateFormat
                        }}
                    />
                </div>
                {this.renderInbound()}
            </div>
        )
    }

    renderInbound () {
        if (!this.props.showInboundDate) {
            return null
        }
        const classesInbound = classNames({
            'journey-search-inbound-date': true,
            'journey-search-date-picker': true,
            'has-value': this.props.inboundDate !== null
        })

        return (
            <div className='inbound'>
                <label htmlFor='journey-search-inbound-date' className='text-label inbound-date'
                >{_t.getIntlMessage('journey-search.inbound-date.label')}</label>
                <DatePicker
                    value={this.props.inboundDate}
                    id='journey-search-inbound-date'
                    name='journey-search-inbound-date'
                    className={classesInbound}
                    placeholder={_t.getIntlMessage('journey-search.inbound-date.placeholder')}
                    autoComplete='off'
                    onChange={date => this.props.onChange({inboundDate: date})}
                    required={false}
                    readOnly
                    appendToClassName='journey-search-inbound-datepicker'
                    datePickerOptions={{
                        minDate: this.props.outboundDate || 0,
                        numberOfMonths: this.props.numberOfMonths,
                        dateFormat: this.props.dateFormat
                    }} />
                {this.clearButton()}
            </div>
        )
    }

    clearButton () {
        if (this.props.inboundDate !== null) {
            return <Button
                type='button'
                className='button secondary clear-input'
                onClick={() => this.props.onChange({inboundDate: null})}
                text='Clear input'
                icon={{type: 'cross', className: 'small align-left'}}
            />
        }
    }
}
