// Libraries
import React from 'react'
import {Route} from 'react-router'
import authCheck from '../../mixins/auth'
import OrientationOfferLayoutContainer from '../../layouts/orientation-offer-layout-container'

export default (
    <Route path='/:lang/orientation/offer'
           component={OrientationOfferLayoutContainer}
           onEnter={(nextState, replaceState, callback) => authCheck(nextState, replaceState, callback, {ensureToken: true, renewToken: true})}
        />
)
