import PropTypes from 'prop-types'
import React, {Component} from 'react'
import {PRODUCT_CODE_BIKE} from '../../constants'
import Icon from '../../element/icon'
import Loader from '../../element/loader'
import SegmentModel from '../../models/segment-model'
import {bikeMaxQuantityForDirectionAndPassengerSelector,
    bikeTariffCodeForDirectionAndPassengerSelector
} from '../../models/selectors/components/booking/bike-selection'
import {getState} from '../../reflux/bridge/connect-state'
import BookingStore from '../../reflux/stores/booking-store'
import _t from '../../translate'
import TextLabel from '../../element/text-label'
import {TRAVEL_DIRECTION_INBOUND, TRAVEL_DIRECTION_OUTBOUND} from '../../models/selectors/constants'
import Button from '../../element/button'
import TextField from '../../element/form/text-field'
import ProgressNavigation from '../../base/main/progress-navigation-container'
import createReactClass from 'create-react-class'
import Reflux from 'reflux'
import BikeStore from '../../reflux/stores/bike-store'
import actions from '../../reflux/actions'
import {withRouter} from 'react-router'
import isFinite from 'lodash/isFinite'
import {cmsBlockContainer} from '../cms/cms-block-container'
import {CmsContentTextLabel, CmsTitleTextLabel} from '../cms/cms-text-label'

const toNumeric = value => Number(parseFloat(value).toPrecision(12))
const clamp = (value = 0, minValue, maxValue) => Math.min(Math.max(toNumeric(value), minValue), maxValue)

class QuantityInput extends Component {
    static propTypes = {
        fieldName: PropTypes.string.isRequired,
        minValue: PropTypes.number.isRequired,
        maxValue: PropTypes.number.isRequired,
        onChange: PropTypes.func.isRequired,
        value: PropTypes.number,
        disabled: PropTypes.bool
    }

    defaultProps = {
        value: 0
    }

    getCappedValue = value => {
        return String(this.props.value).length ? clamp(value, this.props.minValue, this.props.maxValue) : value
    }

    // Disable Add button when input is disabled or maxValue is reached
    isAddDisabled = () => {
        if (this.props.disabled) {
            return this.props.disabled
        }

        return this.getCappedValue(this.props.value) === this.props.maxValue
    }

    // Disable Subtract button when input is disabled or maxValue is reached
    isSubtractDisabled = () => {
        if (this.props.disabled) {
            return this.props.disabled
        }

        return this.getCappedValue(this.props.value) === this.props.minValue
    }

    handleAdd = event => {
        event && event.preventDefault()
        if (!this.isAddDisabled()) {
            const oldValue = toNumeric(this.props.value)
            const newValue = this.getCappedValue(oldValue + 1)
            return this.handleChangeFromButtons(newValue, oldValue)
        }
    }

    handleSubtract = event => {
        event && event.preventDefault()
        if (!this.isSubtractDisabled()) {
            const oldValue = toNumeric(this.props.value)
            const newValue = this.getCappedValue(oldValue - 1)
            return this.handleChangeFromButtons(newValue, oldValue)
        }
    }

    handleChangeFromButtons = (newValue, oldValue) => {
        if (oldValue !== newValue) {
            this.props.onChange(newValue)
        }
    }

    handleChangeFromInput = event => {
        event && event.preventDefault()
        const newValue = toNumeric(event.target.value)
        this.props.onChange(isFinite(newValue) ? this.getCappedValue(newValue) : 0)
    }

    render () {
        const {fieldName, disabled, value} = this.props

        return (
            <div className='quantity-input'>
                <Button
                    type='button'
                    className='button default'
                    icon={{type: 'minus', className: 'small'}}
                    onClick={this.handleSubtract}
                    disabled={this.isSubtractDisabled()}
                />
                <TextField
                    id={fieldName}
                    value={value}
                    onChange={this.handleChangeFromInput}
                    className='bike-number-input'
                    disabled={disabled}
                />
                <Button
                    type='button'
                    className='button default'
                    icon={{type: 'plus', className: 'small'}}
                    onClick={this.handleAdd}
                    disabled={this.isAddDisabled()}
                />
            </div>
        )
    }
}

class Passenger extends Component {
    static propTypes = {
        id: PropTypes.string.isRequired,
        direction: PropTypes.string.isRequired,
        fullName: PropTypes.string.isRequired,
        passengerId: PropTypes.string.isRequired,
        value: PropTypes.number,
        disabled: PropTypes.bool
    }

    handleChange = value => {
        const {direction, passengerId} = this.props

        actions.processBike({value, direction, passengerId})
    }

    render () {
        const {id, fullName, disabled, value, direction, passengerId} = this.props
        const maxQuantity = bikeMaxQuantityForDirectionAndPassengerSelector(direction, passengerId)(getState())

        return (
            <li onClick={this.handleClickSelect}>
                <div className='passenger-list--item'>
                    <div className='passenger'>
                        <span className='text-label passenger-name'>
                            <TextLabel text={fullName} />
                        </span>
                    </div>
                    <QuantityInput
                        onChange={this.handleChange}
                        value={value}
                        fieldName={id}
                        minValue={0}
                        maxValue={maxQuantity}
                        disabled={disabled || maxQuantity === 0}
                    />
                </div>
            </li>
        )
    }
}

const BikeCmsTitleTextLabel = cmsBlockContainer('additional-bike-selector', CmsTitleTextLabel)
const BikeCmsContentTextLabel = cmsBlockContainer('additional-bike-selector', CmsContentTextLabel)

const BikeSelector = createReactClass({

    propTypes: {
        router: PropTypes.object.isRequired,
        returnUrl: PropTypes.string.isRequired
    },

    mixins: [
        Reflux.connectFilter(BikeStore, 'formData', data => data),
        Reflux.connectFilter(BookingStore, 'booking', _ => BookingStore.getBookingModel())
    ],

    getInitialState () {
        return {
            loading: false
        }
    },

    async handleClickNext (event) {
        event.preventDefault()

        this.setState({loading: true})

        let tariffSegments = this.state.booking.tariffSegmentCollection.hasProvisionalNonCancelledProducts()
            // Book and Rebook flow
            ? this.state.booking.tariffSegmentCollection.withProvisionalNonCancelledProducts().getData()
            // After sales flow
            : this.state.booking.tariffSegmentCollection.withoutCancelled().getData()

        // Determine which items to remove from the booking and which products to add to the booking
        // Loop all segments because per passenger per journey additional products must be added to each segment in the journey
        const {deleteItemRefs, segments} = tariffSegments.reduce((updates, tariffSegment) => {
            const tariffSegmentModel = new SegmentModel(tariffSegment)
            // base segment to which the new items have to be added for the patch booking request
            const segment = {
                id: tariffSegmentModel.id,
                origin: tariffSegmentModel.departureStation._u_i_c_station_code,
                destination: tariffSegmentModel.arrivalStation._u_i_c_station_code,
                start_validity_date: tariffSegmentModel.startValidityDate,
                direction: tariffSegmentModel.direction,
                service_name: tariffSegmentModel.validityService,
                service_identifier: tariffSegmentModel.serviceIdentifier || `${tariffSegmentModel.validityService}|${tariffSegmentModel.startValidityDate}`,
                items: []
            }
            const passengerIds = this.state.formData.passengerIds
            let _deleteItemRefs = []

            passengerIds.forEach(passengerId => {
                const tariffCode = bikeTariffCodeForDirectionAndPassengerSelector(tariffSegment.direction, passengerId)(getState())
                const item = {
                    passenger_id: passengerId,
                    tariff_code: tariffCode
                }
                const passengerDeleteItemRefs = []
                const amount = this.state.formData[tariffSegment.direction][passengerId].value
                const existingProducts = tariffSegmentModel.getProvisionalNonCancelledAdditionalProductsByPassengerAndCode(passengerId, PRODUCT_CODE_BIKE)

                // Compare the requested number of products, entered in the form, to be added to the booking with
                // the number of existing products in the booking for this tariffSegment
                // Remove or add items until both counts match
                let count = amount - existingProducts.length
                while (isFinite(count) && count !== 0) {
                    if (count < 0) {
                        // Determine which item to remove from the booking, using the passengerDeleteItemRefs length to
                        // retrieve the next element in line with every loop
                        passengerDeleteItemRefs.push(existingProducts[passengerDeleteItemRefs.length].item_ref)
                        count = count + 1
                    }
                    if (count > 0) {
                        segment.items.push(item)
                        count = count - 1
                    }
                }
                _deleteItemRefs = _deleteItemRefs.concat(passengerDeleteItemRefs)
            })

            const segments = segment.items.length ? [...updates.segments, segment] : [...updates.segments]
            return {
                deleteItemRefs: [...updates.deleteItemRefs, ..._deleteItemRefs],
                segments
            }
        }, {deleteItemRefs: [], segments: []})

        if (deleteItemRefs.length) {
            await actions.deleteItems(this.state.booking.bookingNumber, {'item_refs': deleteItemRefs})
        }

        if (segments.length) {
            await actions.patchBooking(this.state.booking.bookingNumber, {
                segments
            })
        }

        this.props.router.push(`/${_t.getLocales()}${this.props.returnUrl}`)
    },

    render () {
        const totalPrice = BikeStore.getTotalPrice()

        return !this.state.loading ? (
            <div className='bike-selector'>
                <div className='panel'>
                    <header>
                        <h2>
                            <BikeCmsTitleTextLabel />
                        </h2>
                    </header>
                    <div className='content'>
                        <span className='text-label seat-plus-info'>
                            <BikeCmsContentTextLabel />
                        </span>
                    </div>
                </div>
                {this.state.formData.hasOutbound && (
                    <div className='panel'>
                        <header>
                            <h2>
                                <Icon className='large align-left' type='fleche-right' />
                                {_t.getIntlMessage('bike-selector.header-outbound')}
                            </h2>
                        </header>
                        <div className='content'>
                            <div className='passenger-list'>
                                <ul>
                                    {this.state.formData.passengerIds.map(id => (
                                        <Passenger
                                            {...this.state.formData[TRAVEL_DIRECTION_OUTBOUND][id]}
                                            key={id}
                                            direction={TRAVEL_DIRECTION_OUTBOUND}
                                        />
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                )}
                {this.state.formData.hasInbound && (
                    <div className='panel'>
                        <header>
                            <h2>
                                <Icon className='large align-left' type='fleche-left' />
                                {_t.getIntlMessage('bike-selector.header-inbound')}
                            </h2>
                        </header>
                        <div className='content'>
                            <div className='passenger-list'>
                                <ul>
                                    {this.state.formData.passengerIds.map(id => (
                                        <Passenger
                                            {...this.state.formData[TRAVEL_DIRECTION_INBOUND][id]}
                                            key={id}
                                            direction={TRAVEL_DIRECTION_INBOUND}
                                        />
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                )}
                <div className='panel plain summary'>
                    <span className='text-label total-information'>
                        <TextLabel className='small' text={_t.getIntlMessage('bike-selector.total-price-mobile')} />
                        <TextLabel className='medium' text={_t.getIntlMessage('bike-selector.total-price')} />
                    </span>
                    <span className='text-label total-price'>
                        <TextLabel text={_t.formatCurrency(totalPrice)} />
                    </span>
                </div>
                <ProgressNavigation
                    onClickNext={this.handleClickNext}
                    nextButtonProps={
                        {
                            text: _t.getIntlMessage('bike-selector.progress-navigation.next'),
                            onClick: this.handleClickNext
                        }
                    }
                    previousButtonEnabled
                />
            </div>
        ) : <Loader />
    }
})

export default withRouter(BikeSelector)
