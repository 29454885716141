import PropTypes from 'prop-types'
import React from 'react'
import Reflux from 'reflux'
import {withRouter} from 'react-router'
import groupBy from 'lodash/groupBy'
import {
    PRODUCT_CODE_SEAT_SELECTION,
    PRODUCT_CODE_SEAT_SELECTION_PLUS, STORAGE_ORIGINAL_SEATS
} from '../../constants'
import SeatSelector from './seat-selector'
import BookingModel from '../../models/booking-model'
import BookingStore from '../../reflux/stores/booking-store'
import CarriageLayoutsStore from '../../reflux/stores/carriage-layouts-store'
import actions from '../../reflux/actions'
import SegmentModel from '../../models/segment-model'
import _t from '../../translate'
import Loader from '../../element/loader'
import Storage from '../../storage'
import {
    hasManualSeatPlusProperty,
    isSeatProductCode,
    isSeatSelectionPropertyCode,
    sortPassengers
} from '../../misc/helpers'
import {
    connectState,
    getState
} from '../../reflux/bridge/connect-state'
import {
    seatSelectionPlusProductSelector,
    seatSelectionProductSelector,
    seatSelectionServicesSelector,
    seatsWithPricesSelector
} from '../../models/selectors/components/booking/seat-selection'
import {loadAvailableSeatSelectorLegs} from './actions/seat-selector'
import createReactClass from 'create-react-class'

const component = createReactClass({
    mixins: [
        Reflux.connectFilter(CarriageLayoutsStore, 'carriageLayout', data => data.carriageLayout),
        Reflux.listenTo(BookingStore, 'updateBooking')
    ],

    propTypes: {
        availableSeatSelectorServices: PropTypes.array,
        seatSelectionProductFares: PropTypes.array,
        seatSelectionPlusProductFares: PropTypes.array,
        selectedLegPassenger: PropTypes.object,
        returnUrl: PropTypes.string,
        router: PropTypes.object
    },

    getInitialState () {
        return {
            loading: false,
            selectedSeats: [],
            hasDeletedSeats: false,
            price: 0,
            tariffCode: '',
            collapsed: {
                outbound: true,
                inbound: false
            },
            ...this._getBookingState(BookingStore.getBookingModel())
        }
    },

    componentWillMount () {
        const {selectedLegPassenger} = this.props
        const travels = this._getTravels()
        const {passengers} = this.state.booking.segmentsAndPassengersForProducts
        this._getDefaultSelection(travels)
        this._setAvailability().then(
            () => {
                if (Object.keys(selectedLegPassenger).length >= 1) {
                    const passenger = passengers.find(passenger => passenger.id === selectedLegPassenger.passengerId)
                    this.handleSelectPassenger(passenger, selectedLegPassenger.direction, selectedLegPassenger.legId)
                } else {
                    this._setNextPassenger(this.state.selectedSeats)
                }
            }
        )
    },

    _getTravels () {
        const seatSelectionOptions = this.state.booking.seatSelectionOptions().value()
        return groupBy(seatSelectionOptions, 'direction')
    },

    async updateBooking (data) {
        const booking = BookingModel.create(data.booking)

        this.setState(this._getBookingState(booking))
        if (data.messages.length > 0) {
            this._getCarriage(this.state.selected.legId)

            this.setState({
                loading: data.loading
            })
        }
    },

    _getBookingState (booking) {
        const newState = {
            booking
        }
        if (booking) {
            const selectedSeats = booking.tariffSegmentCollection.selectedManualSelectedSeats
            newState.selectedSeats = selectedSeats
            newState.bookingSeats = [...selectedSeats]
            newState.travels = booking.seatSelectionOptions().groupBy('direction').value()
        }

        return newState
    },

    collapseSection (direction) {
        let collapsed = this.state.collapsed
        collapsed[direction] = !this.state.collapsed[direction]
        this.setState({
            collapsed
        })
    },

    handleSelectSeat (seat) {
        const selectedSeats = this._filterSelectedSeatsByPassengerAndLegId(
            this.state.selectedPassenger,
            this.state.selected.legId
        )
        const _selectedPassenger = this.state.selectedPassenger
        const seatProductCode = hasManualSeatPlusProperty(seat)
            ? PRODUCT_CODE_SEAT_SELECTION_PLUS
            : PRODUCT_CODE_SEAT_SELECTION

        selectedSeats.push({
            passenger_id: _selectedPassenger.id,
            leg_id: this.state.selected.legId,
            seat_number: seat.seat_number,
            carriage_number: this.state.carriageLayout ? this.state.carriageLayout.carriages[0].carriage_number : null,
            seat_product_code: seatProductCode
        })

        this.setState(
            {selectedSeats},
            () => this._setNextPassenger(selectedSeats)
        )
    },

    _setNextPassenger (selectedSeats) {
        let direction = this.state.selected.direction
        let legId = this.state.selected.legId
        const newSelectedSeats = this._getNewSelectedSeats(selectedSeats)
        let nextPassenger
        if (this.state.availableSections.includes(legId)) {
            nextPassenger = this._getNextSelectionPassenger(selectedSeats, legId)
        }

        if (!nextPassenger) {
            for (let option of this.state.booking.seatSelectionOptions().value()) {
                if (this.state.availableSections.includes(option.leg.id)) {
                    nextPassenger = this._getNextSelectionPassenger(newSelectedSeats, option.leg.id)
                    if (nextPassenger) {
                        direction = option.direction
                        legId = option.leg.id
                        break
                    }
                }
            }
        }

        if (nextPassenger) {
            this.handleSelectPassenger(nextPassenger, direction, legId)
        }
    },

    _getNextSelectionPassenger (selectedSeats, legId) {
        return sortPassengers(this.state.booking.passengers.value()).find(
            passenger => !selectedSeats.some(
                seat => seat.passenger_id === passenger.id && seat.leg_id === legId
            )
        )
    },

    _getSelectedPassenger (selectedSeat) {
        return this.state.booking.passengers.find(passenger => selectedSeat.passenger_id === passenger.id)
    },

    handleRemoveSeatBySeat (selectedSeat) {
        const seat = this.state.selectedSeats.find(seat => seat.seat_number === selectedSeat.seat_number)
        const passenger = this._getSelectedPassenger(seat)
        this.handleSelectPassenger(passenger, this.state.selected.direction, this.state.selected.legId)
        this.setState({
            selectedSeats: this._filterSelectedSeatsByPassengerAndLegId(passenger, this.state.selected.legId)
        })
    },

    async handleRemoveSeatByPassenger (passenger, legId) {
        if (this._getSeatItemRef(passenger.id, legId)) {
            this._removePersistedSeats(passenger.id, legId)
        } else {
            this.setState({
                selectedSeats: this._filterSelectedSeatsByPassengerAndLegId(passenger, legId)
            })
        }
    },

    _getSeatItemRef (passengerId, legId) {
        const selectedSeats = this.state.booking.tariffSegmentCollection.selectedManualSelectedSeats
        const selectedSeat = selectedSeats.find(entry => entry.leg_id === legId && entry.passenger_id === passengerId)
        return selectedSeat ? selectedSeat.additionalProductItemRef : null
    },

    async _removePersistedSeats (passengerId, legId) {
        const originalSeats = Storage.get(STORAGE_ORIGINAL_SEATS)
        const originalSeat = originalSeats.find(seat =>
            seat.passenger === passengerId &&
            seat.legId === legId
        )
        const carriage = await this._getCarriage(legId)
        const availableSeats = carriage.seats.filter(seat =>
            seat.available &&
            !seat.property_codes.some(isSeatSelectionPropertyCode)
        )
        const originalSeatNumber = originalSeat && originalSeat.number
        const originalSeatAvailable = availableSeats.some(seat => seat.seat_number === originalSeatNumber)

        // Remove selected seats
        const itemRef = this._getSeatItemRef(passengerId, legId)
        await actions.deleteItems(this.state.booking.bookingNumber, {
            'item_refs': [
                itemRef
            ]
        })
        if (originalSeatAvailable) {
            actions.updateSeats(this.state.booking.bookingNumber, originalSeat)
        } else if (availableSeats.length) {
            const newSeat = {
                item_ref: originalSeat.item_ref,
                carriage: carriage.carriage_number,
                number: availableSeats[0].seat_number
            }
            actions.updateSeats(this.state.booking.bookingNumber, newSeat)
        }

        // Enable confirm button
        this.setState({hasDeletedSeats: true})
    },

    _filterSelectedSeatsByPassengerAndLegId (passenger, legId) {
        return this.state.selectedSeats.filter(
            selectedSeat => !(
                selectedSeat.passenger_id === passenger.id &&
                selectedSeat.leg_id === legId
            )
        )
    },

    handleSelectPassenger (passenger, direction, legId) {
        if (this.state.selected.legId !== legId || !this.state.carriageLayout) {
            this._getCarriage(legId)
        }

        const legIndex = this.state.travels[direction].findIndex(segment => segment.leg.id === legId)

        this.setState(state => ({
            collapsed: {
                ...state.collapsed,
                [direction]: true
            },
            selectedPassenger: passenger,
            selected: {
                direction,
                leg: legIndex + 1,
                legId,
                busNumber: legIndex + 1
            }
        }))
    },

    async _getCarriage (legId) {
        const journeySegment = this.state.booking.getJourneySegmentById(legId)
        const segmentAvailability = {
            serviceName: journeySegment.service_name,
            fromStationUIC: journeySegment.departure_station._u_i_c_station_code,
            toStationUIC: journeySegment.arrival_station._u_i_c_station_code,
            travelDate: journeySegment.travel_date
        }
        const carriageLayout = await actions.getCarriageLayouts(segmentAvailability)
        return carriageLayout.carriages[0]
    },

    _getDefaultSelection (travels) {
        const outbound = (travels.outbound || travels.inbound)[0]
        this.setState({
            selected: {
                direction: outbound.direction,
                leg: 1,
                legId: outbound.leg.id,
                busNumber: 1
            }
        })
    },

    _getSelectedSeatsForCurrentLeg () {
        return this.state.selectedSeats.filter(seat => seat.leg_id === this.state.selected.legId)
    },

    async handleClickNext () {
        const seatsWithPrice = seatsWithPricesSelector(this.state.selectedSeats)(getState())
        seatsWithPrice.forEach(seat => {
            actions.trackEvent('SeatOption', {basket_seat_option_action: 'booked', basket_seat_price: seat.price})
        })

        this.setState({loading: true})
        const segments = []
        const seats = []
        const originalSeats = []
        const deleteItemRefs = []
        this._getNewSelectedSeats(this.state.selectedSeats).forEach(seat => {
            const tariffSegment = new SegmentModel(this.state.booking.getTariffSegmentDataById(seat.leg_id).first().tariffSegment)
            const requiredProduct = tariffSegment.requiredProductsWithoutCancelled.find(
                product => product.passenger_id === seat.passenger_id
            )

            seats.push({
                carriage: seat.carriage_number,
                number: seat.seat_number,
                item_ref: requiredProduct.item_ref
            })

            originalSeats.push({
                legId: seat.leg_id,
                passenger: seat.passenger_id,
                carriage: requiredProduct.seat.carriage,
                number: requiredProduct.seat.number,
                item_ref: requiredProduct.item_ref
            })

            const tariffSegmentAdditionalProduct = this.state.booking.tariffSegments.find(segment =>
                segment.additional_products.length &&
                segment.departure_station &&
                segment.arrival_station &&
                tariffSegment.departureStation &&
                tariffSegment.arrivalStation &&
                segment.departure_station._u_i_c_station_code === tariffSegment.departureStation._u_i_c_station_code &&
                segment.arrival_station._u_i_c_station_code === tariffSegment.arrivalStation._u_i_c_station_code &&
                segment.validity_start_date === tariffSegment.startValidityDate &&
                segment.additional_products.some(product =>
                    isSeatProductCode(product.code) &&
                    product.passenger_id === seat.passenger_id &&
                    !product.cancelled
                )
            )

            let hasAdditionalProduct = false
            if (tariffSegmentAdditionalProduct) {
                const selectedSeatProduct = tariffSegmentAdditionalProduct.additional_products.find(product =>
                    isSeatProductCode(product.code) &&
                    product.passenger_id === seat.passenger_id &&
                    !product.cancelled
                )

                if (selectedSeatProduct.code !== seat.seat_product_code) {
                    deleteItemRefs.push(selectedSeatProduct.item_ref)
                } else {
                    hasAdditionalProduct = true
                }
            }

            if (!hasAdditionalProduct) {
                const fares = seat.seat_product_code === PRODUCT_CODE_SEAT_SELECTION_PLUS
                    ? this.props.seatSelectionPlusProductFares
                    : this.props.seatSelectionProductFares
                const fare = fares.find(passengerFare => passengerFare.passengerId === seat.passenger_id)
                const item = {
                    passenger_id: seat.passenger_id,
                    tariff_code: fare && fare.tariffCode
                }
                const segment = segments.find(_segment => _segment.id === tariffSegment.id)
                if (segment) {
                    segment.items.push(item)
                } else {
                    segments.push({
                        id: tariffSegment.id,
                        origin: tariffSegment.departureStation._u_i_c_station_code,
                        destination: tariffSegment.arrivalStation._u_i_c_station_code,
                        start_validity_date: tariffSegment.startValidityDate,
                        direction: tariffSegment.direction,
                        service_name: tariffSegment.validityService,
                        service_identifier: tariffSegment.serviceIdentifier || `${tariffSegment.validityService}|${tariffSegment.startValidityDate}`,
                        items: [item]
                    })
                }
            }
        })

        Storage.set(STORAGE_ORIGINAL_SEATS, originalSeats)

        if (deleteItemRefs.length) {
            await actions.deleteItems(this.state.booking.bookingNumber, {'item_refs': deleteItemRefs})
        }

        if (seats.length) {
            if (segments.length) {
                await actions.patchBooking(this.state.booking.bookingNumber, {
                    currency: this.state.booking.currency,
                    segments
                })
            }
            await actions.updateSeats(this.state.booking.bookingNumber, seats)
        }

        this.handleClickPrevious()
    },

    _getNewSelectedSeats (selectedSeats) {
        return selectedSeats.filter(seat =>
            !this.state.bookingSeats.some(_seat =>
                _seat.leg_id === seat.leg_id &&
                _seat.carriage_number === seat.carriage_number &&
                _seat.seat_number === seat.seat_number
            )
        )
    },

    handleClickPrevious (event) {
        if (this.props.returnUrl) {
            event && event.preventDefault()
            event && event.stopPropagation()
            this.props.router.push(`/${_t.getLocales()}${this.props.returnUrl}`)
        }
    },

    async _setAvailability () {
        this.setState({loading: true})

        const numberOfPassengers = this.state.booking.passengers.size()
        const availableLegIds = await loadAvailableSeatSelectorLegs(numberOfPassengers)
        const availableServices = seatSelectionServicesSelector(getState())

        const collapsed = availableServices.reduce((carrier, service) => {
            if (!availableLegIds.includes(service.legId)) {
                carrier[service.direction] = true
            }
            return carrier
        }, {})

        CarriageLayoutsStore.resetData()

        return this.setState(state => ({
            collapsed: {
                ...state.collapsed,
                ...collapsed
            },
            availableSections: availableLegIds,
            loading: false
        }))
    },

    render () {
        return !this.state.loading && this.state.carriageLayout && this.state.availableSections ? (
            <SeatSelector
                availableSections={this.state.availableSections}
                returnUrl={this.props.returnUrl}
                travels={this.state.travels}
                passengers={this.state.booking.passengers.value()}
                onSelectPassenger={this.handleSelectPassenger}
                onSelectSeat={this.handleSelectSeat}
                onRemoveSeatByPassenger={this.handleRemoveSeatByPassenger}
                onRemoveSeatBySeat={this.handleRemoveSeatBySeat}
                onClickNext={this.handleClickNext}
                onClickPrevious={this.handleClickPrevious}
                selected={this.state.selected}
                selectedPassenger={this.state.selectedPassenger || {}}
                selectedCarriage={this.state.carriageLayout.carriages[0] || {}}
                selectedSeats={this.state.selectedSeats || []}
                hasDeletedSeats={this.state.hasDeletedSeats || false}
                selectedSeatsCurrentLeg={this._getSelectedSeatsForCurrentLeg()}
                collapsed={this.state.collapsed}
                collapseSection={this.collapseSection}
                readOnly={false}
            />
        ) : <Loader />
    }
})

const mapPropsToProps = () => {
    const state = getState()
    const seatSelectionProduct = seatSelectionProductSelector(state)
    const seatSelectionPlusProduct = seatSelectionPlusProductSelector(state)

    return {
        availableSeatSelectorServices: seatSelectionServicesSelector(state),
        seatSelectionProductFares: seatSelectionProduct ? seatSelectionProduct.passengerFares : [],
        seatSelectionPlusProductFares: seatSelectionPlusProduct ? seatSelectionPlusProduct.passengerFares : []
    }
}

export default withRouter(connectState(mapPropsToProps)(component))
