import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {withRouter} from 'react-router'
import _t from '../../../../translate'
import Icon from '../../../../element/icon'
import TextLabel from '../../../../element/text-label'
import AfterSalesViewSeatNumber from '../../../aftersales-view-seat-number'

class SelectionInformationButton extends Component {
    static propTypes = {
        legId: PropTypes.string.isRequired,
        isAvailable: PropTypes.bool,
        router: PropTypes.object
    }

    constructor (...args) {
        super(...args)
        this.onClick = this.onClick.bind(this)
    }

    onClick (event) {
        event.preventDefault()
        this.props.router.push(`/${_t.getLocales()}/mys3/view-seats/${this.props.legId}`)
    }

    render () {
        return this.props.isAvailable ? (
            <a href='' className='text-label seat-selector-options my-seat' onClick={this.onClick}>
                <Icon type='eye' className='small align-left' />
                <TextLabel text={_t.message('seat-selector.view-seats-button')} />
            </a>
        ) : (
            <AfterSalesViewSeatNumber {...this.props} />
        )
    }
}

export default withRouter(SelectionInformationButton)
