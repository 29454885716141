import PropTypes from 'prop-types'
import React, { Component } from 'react'
import MyS3OptionsLayoutContainer from '../../my-s3-options-layout-container'
import MyS3SeatSelectorLayoutContainer from './seat-selector-layout-container'
import restoreBooking from '../../../components/restore/restore-booking'

class SeatSelectorLayout extends Component {
    static propTypes = {
        params: PropTypes.shape({
            id: PropTypes.string.isRequired
        }).isRequired
    }

    render () {
        return (
            <MyS3OptionsLayoutContainer name='seat-selection-container'>
                <MyS3SeatSelectorLayoutContainer
                    legId={this.props.params.id}
                    returnUrl='/mys3/my-options'
                />
            </MyS3OptionsLayoutContainer>
        )
    }
}

export default restoreBooking(SeatSelectorLayout, {withBlockingLoader: true})
