import React from 'react'
import {Route} from 'react-router'
import authCheck from '../../mixins/auth'
import BookingPaymentLayout from '../../layouts/booking/payment'

export default (
    <Route path='/:lang/booking/payment'
           component={BookingPaymentLayout}
           onEnter={(nextState, replaceState, callback) => authCheck(nextState, replaceState, callback)}
        />
)
