/* globals S3P_SETTINGS: true */

import PropTypes from 'prop-types'
import createReactClass from 'create-react-class'
import React from 'react'
import {withRouter} from 'react-router'
import Reflux from 'reflux'
import _t from '../../../translate'
import Loader from '../../../element/loader'
import Row from '../../../element/row'
import GenericLayout from '../../generic-layout'
import MyS3ReBookingOrientationOfferLayoutContainer from './orientation-offer-container'
import actions from '../../../reflux/actions'
import StationsStore from '../../../reflux/stores/stations-store'
import BookingStore from '../../../reflux/stores/booking-store'
import OfferStore from '../../../reflux/stores/offer-store'
import SelectBundleStore from '../../../reflux/stores/select-bundle-store'
import {
    bundleSelectionCompleteSelector,
    inboundBeforeOutboundWithBookingSelector
} from '../../../models/selectors/components/re-booking/journey-search'
import {sortedTravelsSelector} from '../../../models/selectors/api/v2/orientation/travel'
import {
    journeySearchSelector,
    journeySearchOfferSelector
} from '../../../reflux/bridge/journey-search'
import restoreJourneySearch from '../../../components/restore/restore-journey-search'
import {
    connectState,
    getState
} from '../../../reflux/bridge/connect-state'
import {createBookingRequestPayload} from '../../../models/selectors/components/orientation/booking'
import {selectedDisabilityProductsSelector} from '../../../models/selectors/components/orientation/wheelchair'
import {withProvisionalCancelledSelector} from '../../../models/selectors/api/v2/booking/products'
import {addWheelchairItems} from '../../../components/orientation-booking-preferences/wheelchair-container'
import {restoreBooking} from '../../../misc/booking'

const component = createReactClass({

    displayName: 'OrientattionOffer',

    propTypes: {
        hasOffer: PropTypes.bool,
        outboundBeforeInbound: PropTypes.bool,
        journeySearchValid: PropTypes.bool,
        travels: PropTypes.array,
        isBundleSelectionComplete: PropTypes.bool,
        hasWheelChairProducts: PropTypes.bool,
        router: PropTypes.object
    },

    mixins: [
        Reflux.listenTo(BookingStore, 'onUpdateBookingStore'),
        Reflux.listenTo(OfferStore, 'onGetOffer'),
        Reflux.listenTo(SelectBundleStore, 'onSelectBundles'),
        Reflux.connectFilter(StationsStore, 'stations', data => data.stations)
    ],

    onSelectBundles (data) {
        if (!data.loading) {
            this.setState({selectedBundles: data.bundles})
        }
    },

    getInitialState () {
        return {
            loading: false,
            bookingLoading: false,
            revertLoading: true,
            selectedBundles: SelectBundleStore.getSelectedBundles(),
            journeySearch: OfferStore.getJourneySearchData(),
            booking: BookingStore.getBookingModel()
        }
    },

    componentWillMount () {
        if (!this._hasBooking()) {
            this.setState({bookingLoading: true}, () => restoreBooking())
        }
    },

    _hasBooking () {
        return this.state.booking !== null && this.state.booking.bookingNumber !== null
    },

    onGetOffer (data) {
        if (data.error === '') {
            this.setState({
                loading: data.loading,
                journeySearch: data.journeySearch
            })
        }
    },

    onUpdateBookingStore (data) {
        this.setState({
            bookingLoading: data.loading,
            booking: BookingStore.getBookingModel()
        })
    },

    async componentDidMount () {
        if (!StationsStore.hasStations()) {
            actions.loadStations(S3P_SETTINGS.s3Passenger.features.stations)
        }
        if (this.state.booking && this.state.booking.provisionalProducts.value().length) {
            await actions.revertBooking(this.state.booking.bookingNumber)
            this.setState({
                revertLoading: false
            })
        } else {
            this.setState({
                revertLoading: false
            })
        }
    },

    render () {
        return this.state.bookingLoading
            ? this._renderLoader()
            : (
                <MyS3ReBookingOrientationOfferLayoutContainer
                    booking={this.state.booking}
                    journeySearch={this.state.journeySearch}
                    bookingLoading={this.state.bookingLoading}
                    travels={this.props.travels}
                    hasOffer={this.props.hasOffer}
                    outboundBeforeInbound={this.props.outboundBeforeInbound}
                    selectedBundles={this.state.selectedBundles}
                    loading={this.state.loading}
                    onClickNext={this.onClickNext}
                    onOptionClick={this.onOptionClick}
                    isBundleSelectionComplete={this.props.isBundleSelectionComplete}
                    enableSearchButton={this.props.journeySearchValid}
                />
            )
    },

    _renderLoader (useGenericLayout = true) {
        return useGenericLayout ? (
            <GenericLayout className='rebook-offer'>
                <Row className='row contains-blocking-loader'>
                    <Loader blocking />
                </Row>
            </GenericLayout>
        ) : <Loader blocking />
    },

    async onClickNext () {
        const createBookingPayload = createBookingRequestPayload(getState())
        await actions.myS3RebookCreateBooking(this.state.booking.bookingNumber, createBookingPayload)
        if (this.props.hasWheelChairProducts) {
            await addWheelchairItems(this.state.booking.bookingNumber)
        }
        this.props.router.push(`/${_t.getLocales()}/mys3/re-booking/details`)
    },

    onOptionClick (bundle) {
        actions.selectBundle(bundle)
    }
})

const mapPropsToProps = () => {
    const state = getState()
    const journeySearch = journeySearchSelector(state)
    return {
        travels: sortedTravelsSelector(state),
        hasOffer: Boolean(journeySearchOfferSelector(state)),
        journeySearchValid: Boolean(journeySearch.departure_date || journeySearch.return_date),
        isBundleSelectionComplete: bundleSelectionCompleteSelector(state),
        outboundBeforeInbound: inboundBeforeOutboundWithBookingSelector(state),
        hasWheelChairProducts: withProvisionalCancelledSelector(selectedDisabilityProductsSelector)(state).length > 0
    }
}

export default withRouter(connectState(mapPropsToProps, [OfferStore, SelectBundleStore, BookingStore])(restoreJourneySearch(component)))
