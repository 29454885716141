import React, {Component} from 'react'
import classNames from 'classnames'
import TextLabel from '../../../element/text-label'
import Icon from '../../../element/icon'
import SegmentModel from '../../../models/segment-model'
import PromoTariffBanner from '../../../base/promo-tariff-banner'
import PropTypes from 'prop-types'

export default class JourneySummary extends Component {
    static propTypes = {
        tariffSegment: PropTypes.instanceOf(SegmentModel).isRequired
    }

    _getClassNames (className, cancelled) {
        return classNames('text-label', className, {
            'not-available': cancelled
        })
    }

    render () {
        if (this.props.tariffSegment.journeySegments.size() === 0) {
            return null
        }

        const cancelled = this.props.tariffSegment.isFullyCancelled
        const departureMoment = this.props.tariffSegment.departureMoment
        const arrivalMoment = this.props.tariffSegment.arrivalMoment
        const isPromo = this.props.tariffSegment.hasPromoTariff()

        return (
            <div className='booking-detail-journey-summary'>
                <div className='grid-row no-gutter'>
                    <div className='grid-column--1-5 grid-column--medium-1-9 travel-date-block'>
                        <div className='travel-date'>
                            <div className='tester'>
                                <span className={this._getClassNames('travel-date-day-name', cancelled)}>
                                    <TextLabel text={departureMoment.format('ddd', 'L')} />
                                </span>
                                <span className={this._getClassNames('travel-date-day', cancelled)}>
                                    <TextLabel text={departureMoment.format('DD/MM')} />
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className='grid-column--4-5 grid-column--medium-8-9'>
                        <div className='grid-row no-gutter'>
                            <div className='grid-column--medium-1-1'>
                                <span className={this._getClassNames('bus-number', cancelled)}>
                                    <Icon
                                        type={this.props.tariffSegment.hasTer() ? 'ter' : 'bus'}
                                        className='small align-left'
                                    />
                                    <TextLabel text={this.props.tariffSegment.validityService} />
                                </span>
                            </div>
                        </div>
                        <div className='grid-row no-gutter'>
                            <div className='grid-column--1-1 grid-column--medium-1-2'>
                                <span className={this._getClassNames('departure-value', cancelled)}>
                                    <TextLabel text={this.props.tariffSegment.departureStation.name} />
                                    <Icon type='arrow-right' className='small align-right' />
                                </span>
                                <span className={this._getClassNames('departure-time', cancelled)}>
                                    <TextLabel text={departureMoment.format('HH:mm')} />
                                </span>
                            </div>
                            <div className='grid-column--1-1 grid-column--medium-1-2'>
                                <span className={this._getClassNames('arrival-value', cancelled)}>
                                    <TextLabel text={this.props.tariffSegment.arrivalStation.name} />
                                </span>
                                <span className={this._getClassNames('arrival-time', cancelled)}>
                                    <TextLabel text={arrivalMoment.format('HH:mm')} />
                                </span>
                            </div>
                        </div>
                        <div className='grid-row no-gutter'>
                            <div className='grid-column--1-1'>
                                {this.props.children}
                            </div>
                        </div>
                        {isPromo ? (
                            <div className='grid-row no-gutter'>
                                <div className='grid-column--1-1'>
                                    <PromoTariffBanner
                                        text={this.props.tariffSegment.getFirstPromoProduct().description}
                                    />
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        )
    }
}
