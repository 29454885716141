import Reflux from 'reflux'
import _ from 'lodash'
import actions from '../actions'
import storage from '../../storage'

export default Reflux.createStore({

    listenables: actions,

    salesChannelProperties: {
        allowAftersalesCancel: 'allow_aftersales_cancel',
        allowAftersalesRebook: 'allow_aftersales_rebook',
        allowAftersalesRefund: 'allow_aftersales_refund',
        allowAftersalesOverrideFees: 'allow_aftersales_override_fees',
        allowRefundVoucherCreation: 'allow_refund_voucher_creation',
        allowVoucherPayment: 'allow_voucher_payment'
    },

    init () {
        if (storage.has('user_information')) {
            this.data = {user: storage.get('user_information')}
        } else {
            this.data = {user: null}
        }
    },

    getInitialState () {
        return this.data
    },

    getUsername () {
        return _.get(this.data, 'user.username')
    },

    getSalesChannel () {
        return _.get(this.data, 'user.sales_channel')
    },

    getSalesChannelProperties () {
        return _.get(this.data, 'user.sales_channel_properties', {})
    },

    getIPAddress () {
        return _.get(this.data, 'user.ip_address', '')
    },

    getSalesChannelProperty (property, defaultValue) {
        return _.get(this.getSalesChannelProperties(), property, defaultValue)
    },

    onLogout () {
        storage.clear('user_information')
        this.data.user = null
        this.trigger(this.data)
    },

    onLoadUserLoginInformationCompleted (data) {
        storage.set('user_information', data)
        this.data.user = data
        this.trigger(this.data)
    },

    onLoadUserLoginInformationFailed () {
        this.onLogout()
    }
})
