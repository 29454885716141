import Reflux from 'reflux'
import actions from '../actions'
import UserAwareMixin from '../mixin/user-aware-mixin'
import sessionStorage from '../../storage'
import {bundlesSelector} from '../../models/selectors/api/v2/orientation/journey-search'
import {getState} from '../bridge/connect-state'

export default Reflux.createStore({

    listenables: actions,

    mixins: [UserAwareMixin],

    init () {
        this._resetData()
        this._restoreSelectedBundles()

        this.listenTo(actions.selectBundle, this.selectBundle)
        this.listenTo(actions.clearOffer, this.resetDataWithBundles)
        this.listenTo(actions.clearBundles, this.resetDataWithBundles)
        this.listenTo(actions.getOffer, this.resetData)
        this.listenTo(actions.initiatePayment.completed, this.resetDataWithBundles)
        this.listenTo(actions.processPayment.completed, this.resetDataWithBundles)
        this.listenTo(actions.confirmBooking.completed, this.resetDataWithBundles)
        this.listenTo(actions.processDeepLink, this.resetDataWithBundles)
    },

    _restoreSelectedBundles () {
        this.data.bundles = sessionStorage.get('selectedBundles', [])
    },

    _resetData () {
        this.data = {
            bundles: []
        }
    },

    onGetOfferCompleted () {
        this._restoreSelectedBundles()
        this.trigger(this.data)
    },

    resetDataWithBundles () {
        sessionStorage.set('selectedBundles', [])
        this.resetData()
    },

    resetData () {
        this._resetData()
        this._restoreSelectedBundles()
        this.trigger(this.data)
    },

    getInitialState () {
        return this.data
    },

    selectBundle (bundle, additionalItems = [/* array of {passengerId, quantity, itemId} */]) {
        const bundles = bundlesSelector(getState())

        const existingBundle = Array.from(bundles.values())
            .find(item => item.id === bundle.id)

        if (existingBundle) {
            this.data.bundles = this.data.bundles.filter(bundleObject => {
                const bundleId = bundleObject.id
                const itemBundle = bundles.get(bundleId)
                return itemBundle && itemBundle.direction !== existingBundle.direction
            }).concat({
                id: bundle.id,
                items: additionalItems
            })

            sessionStorage.set('selectedBundles', this.data.bundles)

            this.trigger(this.data)
        }
    },

    hasSpecification () {
        return this.data.specification !== null
    },

    getData () {
        return this.data
    },

    getSelectedBundles () {
        return this.data.bundles || []
    }
})
