import {SERVICE_TYPE_MODALITY_BUS, SERVICE_TYPE_MODALITY_TER, NON_OUIBUS_SERVICE_TYPE_NAMES} from '../constants'

/**
 * @param {Array} items
 * @param {string} modality
 * @param {Array} includeNames
 * @param {Array} excludeNames
 * @returns {boolean}
 */
export const hasModalityInItems = (items, modality, includeNames = [], excludeNames = []) =>
    items.some(item => {
        const serviceType = item.serviceType || item.service_type
        return serviceType.modality === modality && (
            includeNames.length === 0 || includeNames.includes(serviceType.name.toUpperCase())
        ) && !excludeNames.includes(serviceType.name.toUpperCase())
    })

/**
 * @param {Object} route
 * @param {string} modality
 * @param {Array} includeNames
 * @param {Array} excludeNames
 * @returns {boolean}
 */
export const hasModalityInRoute = (route, modality, includeNames = [], excludeNames = []) =>
    hasModalityInItems(route.legs, modality, includeNames, excludeNames)

/**
 * @param {Object} travel
 * @param {string} modality
 * @returns {boolean}
 */
export const hasModalityInTravel = (travel, modality) => (
    (travel.routes || []).some(route => hasModalityInRoute(route, modality))
)

/**
 * @param {Object} route
 * @returns {boolean}
 */
export const isOuibusOnlyRoute = route => !hasModalityInRoute(route, SERVICE_TYPE_MODALITY_TER) &&
    !hasModalityInRoute(route, SERVICE_TYPE_MODALITY_BUS, NON_OUIBUS_SERVICE_TYPE_NAMES)
