import React, {Component} from 'react'
import _t from '../../../../translate'
import _ from 'lodash'
import {PASSENGER_DETAILS_FORM_PREFIX} from '../../../../constants'
import {prefixFieldLabel} from '../../../../forms/field-props'
import actions from '../../../../reflux/actions'
import device from '../../../../device'
import TextField from '../../../../element/form/text-field'
import TextLabel from '../../../../element/text-label'
import ToggleSlider from '../../../../element/form/toggle-slider'
import RadioGroup from '../../../../element/form/radio-group'
import GoogleTaggingStore from '../../../../reflux/stores/google-tagging-store'
import PropTypes from 'prop-types'

export default class PassengerFields extends Component {
    static propTypes = {
        passenger: PropTypes.object.isRequired,
        showAddFavoritePassenger: PropTypes.bool.isRequired,
        newFavoritePassenger: PropTypes.object.isRequired,
        onBlur: PropTypes.func.isRequired
    }

    constructor (...args) {
        super(...args)
        this.handleAddFavoritePassenger = this.handleAddFavoritePassenger.bind(this)
        this.onChange = this.onChange.bind(this)
        this.state = {start: true}
        this.radioGroupProps = this.radioGroupProps.bind(this)
        this.textFieldProps = this.textFieldProps.bind(this)
        this.baseFieldProps = this.baseFieldProps.bind(this)
        this.maskedInputProps = this.maskedInputProps.bind(this)
    }

    onChange (event) {
        if (this.state.start) {
            GoogleTaggingStore.onStartPassengerDetails()
            this.setState({start: false})
        }
        this.props.onBlur(event)
    }

    render () {
        const {passenger, showAddFavoritePassenger} = this.props
        return (
            <div className='grid-row'>
                {passenger.fields.gender ? (
                    <div className='grid-column--1-1 grid-column--medium-1-1' key='gender'>
                        <RadioGroup
                            className='gender'
                            data={passenger.fields.gender.options}
                            {...this.radioGroupProps(passenger, 'gender')} />
                    </div>
                ) : null}
                <div className='grid-column--1-1 grid-column--medium-1-3' key='firstName'>
                    <TextField className='first-name' {...this.textFieldProps(passenger, 'firstName')} />
                </div>
                <div className='grid-column--1-1 grid-column--medium-1-3' key='lastName'>
                    <TextField className='last-name' {...this.textFieldProps(passenger, 'lastName')} />
                </div>
                <div className='grid-column--1-1 grid-column--medium-1-3' key='dateOfBirth'>
                    <TextField
                        className='date-of-birth'
                        {...this.maskedInputProps(passenger, 'dateOfBirth', [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/])}
                    />
                </div>
                {passenger.isYouthWithDiscount ? (
                    <div className='grid-column--1-1 grid-column--medium-1-3' key='discountCardNumber'>
                        <TextField
                            className='discount-card-number' {...this.textFieldProps(passenger, 'discountCardNumber')} />
                    </div>
                ) : null}
                {passenger.fields.customerProfessionalId ? (
                    <div className='grid-column--1-1 grid-column--medium-1-1' key='customerProfessionalId'>
                        <TextLabel text={_t.getIntlMessage('passenger-details-form.customer-professional-id.label')} />
                        <TextField
                            className='customer-professional-id' {...this.textFieldProps(passenger, 'customerProfessionalId')} />
                    </div>
                ) : null}
                {showAddFavoritePassenger && (
                    <div className='grid-column--1-1' key='addFavoritePassenger'>
                        <div className='check-radio-wrapper'>
                            <ToggleSlider
                                id={`add-favorite-passenger-${passenger.id}`}
                                ref='save-favorite-passenger'
                                className='save-favorite-passenger'
                                labelText='passenger-details-form.add-favorite-passenger.label'
                                labelPosition='before'
                                value
                                onChange={this.handleAddFavoritePassenger} />
                        </div>
                    </div>
                )}
            </div>
        )
    }

    radioGroupProps (passenger, fieldName) {
        let kebabCase = _.kebabCase(fieldName)
        return {
            id: passenger.fields[fieldName].id,
            ref: passenger.fields[fieldName].id,
            selectedValue: passenger.fields[fieldName].value,
            required: passenger.fields[fieldName].isRequired,
            errorText: prefixFieldLabel(
                PASSENGER_DETAILS_FORM_PREFIX,
                kebabCase,
                passenger.fields[fieldName].errorText
            ),
            onChange: this.onRadioChecked
        }
    }

    maskedInputProps (passenger, fieldName, mask) {
        if (!device.supportsInputMask()) {
            return this.textFieldProps(passenger, fieldName)
        }

        const props = this.baseFieldProps(passenger, fieldName)
        props.mask = mask
        props.value = passenger.fields[fieldName].value
        props.disabled = passenger.fields[fieldName].isDisabled
        props.defaultValue = passenger.fields[fieldName].value
        return props
    }

    textFieldProps (passenger, fieldName) {
        const props = this.baseFieldProps(passenger, fieldName)
        props.defaultValue = passenger.fields[fieldName].value
        props.value = passenger.fields[fieldName].value
        return props
    }

    baseFieldProps (passenger, fieldName) {
        let kebabCase = _.kebabCase(fieldName)
        return {
            id: passenger.fields[fieldName].id,
            ref: passenger.fields[fieldName].id,
            labelClassName: '',
            floatingLabel: true,
            labelText: prefixFieldLabel(PASSENGER_DETAILS_FORM_PREFIX, kebabCase, 'placeholder'),
            required: passenger.fields[fieldName].isRequired,
            errorText: prefixFieldLabel(
                PASSENGER_DETAILS_FORM_PREFIX,
                kebabCase,
                passenger.fields[fieldName].errorText
            ),
            onBlur: this.props.onBlur,
            onChange: this.onChange
        }
    }

    onRadioChecked (id, value) {
        actions.processPassengerDetailsData([{
            id: id,
            value: value
        }])
    }

    handleAddFavoritePassenger (event) {
        actions.processFavoritePassenger({
            ...this.props.newFavoritePassenger,
            passenger_id: this.props.passenger.id,
            add: !!event.target.checked
        })
    }
}
