import { createSelector } from 'reselect'
import { travelsSelector } from './journey-search'
import { TRAVEL_DIRECTION_OUTBOUND, TRAVEL_DIRECTION_INBOUND } from '../../../constants'

export const travelSelectorCreator = direction => createSelector(
  [travelsSelector],
  travels => travels && Array.from(travels.values()).find(travel => travel.direction === direction)
)

export const outboundTravelSelector = travelSelectorCreator(TRAVEL_DIRECTION_OUTBOUND)
export const inboundTravelSelector = travelSelectorCreator(TRAVEL_DIRECTION_INBOUND)

export const sortedTravelsSelector = createSelector([
    outboundTravelSelector,
    inboundTravelSelector
], (outboundTravel, inboundTravel) => [].concat(outboundTravel || [], inboundTravel || []))
