import UserStore from '../stores/user-store'
import actions from '../actions'
import _ from 'lodash'

export default {
    listenables: actions,

    init: function () {
        this.listenTo(UserStore, data => {
            if (data.user === null) {
                if (!_.isFunction(this.resetData)) {
                    throw Error('Store should implement resetData when using UserAwareMixin')
                }
                this.resetData()
            }
        })
    }
}
