import { createSelector } from 'reselect'
import omitBy from 'lodash/omitBy'
import { camelCaseKeys } from '../../../../../misc/camelcase'
import { toUtcDateMoment } from '../../../../../misc/date'
import { productsSelector, withoutCancelledSelector as withoutCancelledProductsSelector } from './products'
import {isBlank} from '../../../../../misc/helpers'

export const bookingPassengerSelector = bookingSelector => createSelector(
  [state => {
    const booking = bookingSelector(state)
    return booking ? booking.passengers : null
  }],
  passengers => passengers
)

export const basePassengersSelector = bookingSelector => createSelector(
  [
    bookingPassengerSelector(bookingSelector)
  ],
  passengers => passengers
    ? passengers.map(({email, ...passenger}, index) => omitBy({
      number: `${index + 1}`,
      emailAddress: email,
      ...camelCaseKeys(passenger),
      birthDate: passenger.birth_date && toUtcDateMoment(passenger.birth_date)
    }, isBlank))
    : null
)

export const passengersSelector = bookingSelector => createSelector(
  [
    basePassengersSelector(bookingSelector),
    withoutCancelledProductsSelector(productsSelector(bookingSelector))
  ],
  (passengers, products) => passengers
    ? passengers.map(passenger => ({
      ...passenger,
      cancelled: !products || !products.some(product => product.passenger && product.passenger.id === passenger.id)
    }))
    : null
)

export const protectPrivacySelector = bookingSelector => createSelector(
  [bookingPassengerSelector(bookingSelector)],
  passengers => Boolean(passengers && passengers.some(passenger => passenger.protect_privacy))
)

export const passengersCompareSelector = bookingSelector => createSelector(
  [basePassengersSelector(bookingSelector)],
  passengers => passengers
    ? passengers.reduce(
      (passengersCompare, passenger) => {
        passengersCompare[passenger.id] = passengerCompareString(passenger)
        return passengersCompare
      },
      {}
    )
    : {}
)

export const passengerCompareString = passenger =>
  [passenger.title, passenger.firstName, passenger.lastName, passenger.emailAddress, passenger.phoneNumber].join('|')

export const withoutCancelledSelector = _passengersSelector => createSelector(
  [_passengersSelector],
  passengers => passengers.filter(passenger => !passenger.cancelled)
)

export const hasDiscountCardSelector = (_passengersSelector, discountCard) => createSelector(
  [_passengersSelector],
  passengers => Boolean(
    passengers &&
    passengers.some(passenger =>
      !passenger.cancelled &&
      passenger.discountCards &&
      passenger.discountCards.some(({code}) => code === discountCard)
    )
  )
)
