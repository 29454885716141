import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from '../../translate'
import SeatSummaryLeg from './seat-summary-leg'
import TextLabel from '../../element/text-label'
import Icon from '../../element/icon'

export default class SeatSummaryDirection extends Component {
    static propTypes = {
        direction: PropTypes.string.isRequired,
        seatSelectorRoute: PropTypes.string,
        travel: PropTypes.array.isRequired,
        passengers: PropTypes.array.isRequired,
        selectedSeats: PropTypes.array.isRequired,
        onClickRemove: PropTypes.func.isRequired,
        availableSections: PropTypes.array,
        showPrice: PropTypes.bool,
        readOnly: PropTypes.bool
    }

    render () {
        const {direction, travel, selectedSeats, onClickRemove, showPrice, readOnly} = this.props
        return (
            <div className={`passenger-selector ${direction}`}>
                <div className='summary'>
                    <span className='text-label summary-title'>
                        <TextLabel text={_t.getIntlMessage(`seat-selector.directions.${direction}`)} />
                        {direction === 'inbound'
                            ? <Icon className='fleche-left medium align-right' type='fleche-left' />
                            : <Icon className='fleche-right medium align-right' type='fleche-right' />
                        }
                    </span>
                </div>
                <div className='schedule'>
                    {travel.map(
                        (direction, index) => (
                            <SeatSummaryLeg
                                key={index}
                                busNumber={`${_t.getIntlMessage('seat-selector.bus')} ${index + 1}`}
                                direction={direction}
                                legHasSeatSelection={this._legHasSeatSelection(direction.leg.id)}
                                passengers={this.props.passengers}
                                seatSelectorRoute={this.props.seatSelectorRoute}
                                selectedSeats={selectedSeats}
                                onClickRemove={onClickRemove}
                                showPrice={showPrice}
                                readOnly={readOnly}
                            />
                        )
                    )}
                </div>
            </div>
        )
    }

    _legHasSeatSelection (legId) {
        const {availableSections, selectedSeats} = this.props
        return (
            (availableSections && availableSections.length && availableSections.includes(legId)) ||
            selectedSeats.some(seat => legId === seat.leg_id)
        )
    }
}
