import PropTypes from 'prop-types'
import React from 'react'
import Reflux from 'reflux'
import BookingModel from '../../models/booking-model'
import BookingStore from '../../reflux/stores/booking-store'
import createReactClass from 'create-react-class'
import OfferStore from '../../reflux/stores/offer-store'
import {getState} from '../../reflux/bridge/connect-state'
import {hasBikePayingOptionsSelector} from '../../models/selectors/components/orientation/journey-search'
import BikeSelection from '../bike-options/bike-selection'
import MyS3BikeSummary from '../mys3/bike/bike-summary'
import BikeSummary from '../bike-options/bike-summary'
import {bikeTotalPricePerPassengerPerDirectionSelector} from '../../models/selectors/components/booking/bike-selection'

export default createReactClass({
    displayName: 'bikeOptions',

    mixins: [
        Reflux.listenTo(OfferStore, 'updateOffer'),
        Reflux.connectFilter(BookingStore, 'booking', data => BookingModel.create(data.booking))
    ],

    propTypes: {
        bikeSelectorRoute: PropTypes.any,
        myS3Options: PropTypes.bool
    },

    componentWillMount () {
        this.updateOffer()
    },

    updateOffer () {
        const bikePricePerDirectionPerPassenger = bikeTotalPricePerPassengerPerDirectionSelector(getState())
        if (Object.values(bikePricePerDirectionPerPassenger).length > 0) {
            const minPrice = Object.values(bikePricePerDirectionPerPassenger).reduce(
                (minPrice, direction) => Object.values(direction).reduce((min, price) => price < min ? price : min, minPrice),
                Number.MAX_VALUE
            )

            this.setState({price: minPrice})
        }
    },

    render () {
        const hasBikePayingOptions = hasBikePayingOptionsSelector(getState())
        return hasBikePayingOptions ? this.renderBikeOptions() : null
    },

    renderBikeOptions () {
        const hasSummaryBike = this.state.booking.tariffSegmentCollection.hasSummaryBikeProducts
        const BikeSummaryComponent = this.props.myS3Options ? MyS3BikeSummary : BikeSummary

        return (
            <div className='row'>
                <div className='bike-options'>
                    {
                        hasSummaryBike
                            ? <BikeSummaryComponent bikeSelectorRoute={this.props.bikeSelectorRoute} />
                            : <BikeSelection bikeSelectorRoute={this.props.bikeSelectorRoute} price={this.state.price} />
                    }
                </div>
            </div>
        )
    }
})
