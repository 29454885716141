import PropTypes from 'prop-types'
import React, {Component} from 'react'
import {Link} from 'react-router'
import TextLabel from '../../element/text-label'
import _t from '../../translate'
import FormattedHTMLMessage from '../../translate/formatted-html-message'
import Icon from '../../element/icon'
import {cmsBlockContainer} from '../cms/cms-block-container'
import {CmsContentTextLabel, CmsTitleTextLabel} from '../cms/cms-text-label'
import LuggageModal from './luggage-modal'

const LuggageCmsTitleTextLabel = cmsBlockContainer('additional-luggage-selection', CmsTitleTextLabel)
const LuggageCmsContentTextLabel = cmsBlockContainer('additional-luggage-selection', CmsContentTextLabel)

class LuggageSelection extends Component {
    static propTypes = {
        luggageSelectorRoute: PropTypes.string,
        price: PropTypes.number
    }

    state = {
        modalVisible: false
    }

    openModal = event => {
        event.preventDefault()
        this.setState({modalVisible: true})
    }

    closeModal = () => {
        this.setState({modalVisible: false})
    }

    render () {
        return (
            <div>
                <Link
                    className='luggage-selection'
                    to={`/${_t.getLocales()}${this.props.luggageSelectorRoute}`}
                >
                    <div className='panel'>
                        <div className='luggage-selection--image' />
                        <div className='luggage-selection--content'>
                            <header className='grid-row'>
                                <div className='grid-column--1-8 grid-column--medium-1-12'>
                                    <Icon type='luggage-selector' className='medium align-left' />
                                </div>
                                <div className='grid-column--7-8 grid-column--medium-11-12'>
                                    <span className='text-label heading'>
                                        <LuggageCmsTitleTextLabel />
                                    </span>
                                </div>
                            </header>
                            <div className='grid-row'>
                                <div className='grid-column--1-1'>
                                    <div className='text-label select-luggage'>
                                        <LuggageCmsContentTextLabel />
                                        <span
                                            className='text-label sub-label has-tooltip cvc-code'
                                            onClick={this.openModal}
                                        >
                                            <Icon id='cvc-tooltip-icon' className='small align-right' type='info' />
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className='grid-row'>
                                <div className='grid-column--1-1 grid-column--medium-3-4'>
                                    <div className='text-label luggage-from'>
                                        <FormattedHTMLMessage
                                            message={_t.formatIntlMessage('luggage-selection.from', {
                                                price: _t.formatCurrency(this.props.price)
                                            })} />
                                    </div>
                                </div>
                                <div className='grid-column--1-1 grid-column--medium-1-4'>
                                    <button className='button secondary open-luggage-selector'>
                                        <TextLabel text={_t.message('luggage-selection.choose')} />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Link>
                {this.state.modalVisible ? <LuggageModal closeModal={this.closeModal} /> : null}
            </div>
        )
    }
}

export default LuggageSelection
