import React, {Component} from 'react'
import PropTypes from 'prop-types'

// Components
import GridRow from './grid-row'

export default class Rebooking extends Component {
    static propTypes = {
        amountTicketsAdded: PropTypes.number.isRequired,
        amountTicketsReplaced: PropTypes.number.isRequired,
        amountCancelledFees: PropTypes.number.isRequired
    }

    constructor (...args) {
        super(...args)
        this._renderRebookingRemovedFees = this._renderRebookingRemovedFees.bind(this)
        this._renderRebookingAddedTickets = this._renderRebookingAddedTickets.bind(this)
    }

    render () {
        return (
            <div className='rebooking'>
                {this.props.amountCancelledFees > 0 ? this._renderRebookingRemovedFees() : null}
                {this._renderRebookingReplacedTickets()}
                {this._renderRebookingAddedTickets()}
            </div>
        )
    }

    _renderRebookingRemovedFees () {
        return (
            <GridRow
                messageLabel='booking-specification.removed-fees'
                value={-this.props.amountCancelledFees}
                className='text-label booking-specification-value'
            />
        )
    }

    _renderRebookingAddedTickets () {
        return (
            <GridRow
                messageLabel='booking-specification.added-tickets'
                value={this.props.amountTicketsAdded}
                className='text-label booking-specification-value'
            />
        )
    }

    _renderRebookingReplacedTickets () {
        return (
            <GridRow
                messageLabel='booking-specification.replaced-tickets'
                value={-this.props.amountTicketsReplaced}
                className='text-label booking-specification-value'
            />
        )
    }
}
