import Reflux from 'reflux'
import _t from '../../translate'
import FormValidationMixin from '../../reflux/mixin/form-validation-mixin'
import UserAwareMixin from '../../reflux/mixin/user-aware-mixin'
import CountryCollection from '../../models/country-collection'
import BookingStore from '../../reflux/stores/booking-store'
import actions from '../../reflux/actions'
import AccessManager from '../../data/access-manager'
import {PhoneNumberUtil} from 'google-libphonenumber'

const phoneUtil = PhoneNumberUtil.getInstance()
const defaultCountryCodeOption = {
    text: 'personal-information-form.country-code.placeholder',
    value: ''
}

const defaultCountryOption = {
    text: 'personal-information-form.country.placeholder',
    value: ''
}

export default Reflux.createStore({

    listenables: actions,

    mixins: [FormValidationMixin, UserAwareMixin],

    init () {
        this._resetData()

        this.listenTo(BookingStore, data => {
            if (!data.loading && data.error.length === 0 && data.booking.booking_number !== this.data.bookingNumber) {
                this.data.bookingNumber = data.booking.booking_number

                if (typeof data.booking.customer !== 'undefined') {
                    const infoData = [
                        {id: 'gender', value: data.booking.customer.gender || null},
                        {id: 'firstName', value: data.booking.customer.first_name},
                        {id: 'lastName', value: data.booking.customer.last_name},
                        {id: 'emailAddress', value: data.booking.customer.email},
                        {id: 'repeatEmailAddress', value: data.booking.customer.email}
                    ]

                    if (data.booking.customer.phone_number) {
                        const {phoneNumber, countryCodePhone} = this.parsePhoneNumber(data.booking.customer.phone_number)
                        infoData.push({id: 'phone', value: phoneNumber})
                        countryCodePhone && infoData.push({id: 'countryCode', value: countryCodePhone})
                    }
                    if (data.booking.customer.address && data.booking.customer.address.postal_code) {
                        infoData.push({id: 'postalCode', value: data.booking.customer.address.postal_code})
                    }
                    if (data.booking.customer.address && data.booking.customer.address.country_code) {
                        if (!infoData.find(record => record.id === 'countryCode')) {
                            infoData.push({id: 'countryCode', value: data.booking.customer.address.country_code})
                        }
                        infoData.push({id: 'country', value: data.booking.customer.address.country_code})
                    }
                    this.onProcessCustomerInfoData(infoData)
                }
            }
        })
        this.listenTo(actions.getOffer, this._resetData)
        this.listenTo(actions.processDeepLink, this._resetData)
        this.listenTo(actions.crmLogin.completed, this._genderRequired)

        this.validators = {
            title: [
                value => this._validateInOptionsList(value, this.data.fields.title.options)
            ],
            firstName: AccessManager.isCrmUser() ? [] : [
                value => this._validateMinLength(value, 1),
                value => this._validateMaxLength(value, 50)
            ],
            lastName: AccessManager.isCrmUser() ? [] : [
                value => this._validateMinLength(value, 1),
                value => this._validateMaxLength(value, 50)
            ],
            postalCode: [
                value => this._validateMaxLength(value, 8)
            ],
            countryCode: [
                value => this._validateInOptionsList(value, this.data.fields.countryCode.options)
            ],
            country: [
                value => this._validateInOptionsList(value, this.data.fields.country.options)
            ],
            phone: [
                value => {
                    if (value) {
                        return this._validPhoneNumber(value, this.data.fields.countryCode.value)
                    }
                    return null
                }
            ],
            emailAddress: [
                this._validateEmail,
                value => this._validateMaxLength(value, 50),
                this._validateGmail
            ],
            repeatEmailAddress: [
                this._validateEmail,
                value => this._compareValues(value, this.data.fields.emailAddress.value)
            ],
            agreeToTerms: [
                value => this._validateBoolean(value),
                value => (value !== true) ? 'is-required' : null
            ]
        }
    },

    _resetForm () {
        const bookingNumber = this.data.bookingNumber
        this._resetData()
        this.data.bookingNumber = bookingNumber
        this.trigger(this.data)
    },

    _resetData () {
        this.data = {
            isValid: false,
            errorText: null,
            fields: {
                firstName: {
                    value: '',
                    errorText: null,
                    isRequired: !AccessManager.isCrmUser()
                },
                lastName: {
                    value: '',
                    errorText: null,
                    isRequired: !AccessManager.isCrmUser()
                },
                postalCode: {
                    value: '',
                    errorText: null,
                    isRequired: false
                },
                countryCode: {
                    value: _t.getSetting('prefillCountry', ''),
                    options: [defaultCountryCodeOption].concat(CountryCollection.create().asCountryCodesOptionListWithPriorities),
                    errorText: null,
                    isRequired: false
                },
                country: {
                    value: _t.getSetting('prefillCountry', ''),
                    options: [defaultCountryOption].concat(CountryCollection.create().asOptionListWithPriorities),
                    errorText: null,
                    isRequired: false
                },
                phone: {
                    value: '',
                    errorText: null,
                    isRequired: false
                },
                emailAddress: {
                    value: '',
                    errorText: null,
                    isRequired: true
                }
            }
        }

        if (!AccessManager.isCrmUser()) {
            this.data.fields.gender = {
                value: null,
                options: [
                    {
                        id: 'female',
                        text: 'personal-information-form.gender.values.f',
                        value: 'F'
                    },
                    {
                        id: 'male',
                        text: 'personal-information-form.gender.values.m',
                        value: 'M'
                    }
                ],
                errorText: null
            }
        }
    },

    resetData () {
        this._resetData()
        this.trigger(this.data)
    },

    getInitialState () {
        return this.data
    },

    onProcessCustomerInfoData (newData) {
        if (typeof newData !== 'undefined') {
            newData = newData.filter(record => Boolean(this.data.fields[record.id]))

            // also re-validate repeatEmailAddress on emailAddress change
            this._processEmailAddressData(newData, this.data.fields)

            newData.forEach(record => (this.data.fields[record.id].value = record.value))
            this._validateData(newData)
        }
    },

    _processEmailAddressData (newData, oldData) {
        if (newData.some(record => record.id === 'emailAddress') &&
            !newData.some(record => record.id === 'repeatEmailAddress') &&
            oldData.repeatEmailAddress &&
            oldData.repeatEmailAddress.value
        ) {
            newData.push({
                id: 'repeatEmailAddress',
                value: oldData.repeatEmailAddress.value
            })
        }
    },

    _genderRequired () {
        this.data.fields.gender.isRequired = AccessManager.isCrmUser()
    },

    parsePhoneNumber (phoneNumber) {
        try {
            const phoneParsed = phoneUtil.parse(phoneNumber, '')
            const nationalPhoneNumber = phoneParsed.getNationalNumber()
            const countryCodePhone = phoneUtil.getRegionCodeForNumber(phoneParsed)

            return {phoneNumber: nationalPhoneNumber.toString(), countryCodePhone}
        } catch (err) {
            return {phoneNumber, countryCodePhone: null}
        }
    }
})
