import {createSelector} from 'reselect'
import intersectionBy from 'lodash/intersectionBy'
import moment from 'moment-timezone'
import {camelCaseKeys} from '../../../../../misc/camelcase'
import {toUtcDateMoment} from '../../../../../misc/date'
import {stationsSelector} from '../../../../../reflux/bridge/stations'
import {journeySearchOfferSelector as baseJourneySearchSelector} from '../../../../../reflux/bridge/journey-search'
import {enrichStation} from '../meta/stations'
import {MAIN_PRODUCT_TYPES} from '../../../constants'

const mapTravel = (travel, stations) => ({
    ...camelCaseKeys(travel),
    originStation: enrichStation(travel.origin_station, stations),
    destinationStation: enrichStation(travel.destination_station, stations),
    departureDate: toUtcDateMoment(travel.departure_date),
    routes: travel.routes.map(route => mapRoute(route, stations))
})

const mapPassengerFare = (passengerFare, item, route) => {
    const availability = item.leg_ids.reduce(
        (availability, legId) => {
            const leg = route.legs.find(leg => leg.id === legId)
            const bucket = leg && passengerFare.bucket_code &&
                leg.logical_availability.find(bucket => bucket.bucket_code === passengerFare.bucket_code)
            if (bucket) {
                availability.logical = Math.min(bucket.logical, availability.logical)
            }

            const inventory = leg && passengerFare.inventory_class &&
                leg.availability.find(inventory => inventory.inventory_class === passengerFare.inventory_class)
            if (inventory) {
                availability.physical = Math.min(inventory.physical, availability.physical)
            }

            return availability
        },
        {
            logical: Number.MAX_VALUE,
            physical: Number.MAX_VALUE
        }
    )

    return {
        ...camelCaseKeys(passengerFare),
        logicalAvailability: availability.logical,
        physicalAvailability: availability.physical
    }
}

const mapBundleItem = (item, route) => ({
    ...camelCaseKeys(item),
    isMainProduct: MAIN_PRODUCT_TYPES.includes(item.product_type),
    passengerFares: item.passenger_fares.map(passengerFare => mapPassengerFare(passengerFare, item, route)),
    afterSalesRuleSets: (item.after_sales_rule_sets || []).map(camelCaseKeys)
})

const mapBundle = (bundle, route) => {
    const items = bundle.items.map(item => mapBundleItem(item, route))
    const price = items.reduce(
        (_price, item) => item.isMainProduct ? item.passengerFares.reduce(
            (_itemPrice, passengerFare) => _itemPrice + passengerFare.price,
            _price
        ) : _price,
        0.0
    )

    return ({
        ...camelCaseKeys(bundle),
        hasMainProductBucketSeperation: items.some(item => item.isMainProduct && new Set(item.passengerFares.map(fare => fare.bucketCode)).size > 1),
        items,
        price
    })
}

const mapLeg = (leg, stations) => ({
    ...camelCaseKeys(leg),
    availability: leg.availability.map(camelCaseKeys),
    logicalAvailability: leg.logical_availability.map(camelCaseKeys),
    serviceProperties: leg.service_properties.map(camelCaseKeys),
    serviceType: camelCaseKeys(leg.service_type),
    departureStation: enrichStation(leg.departure_station, stations),
    arrivalStation: enrichStation(leg.arrival_station, stations),
    serviceScheduleDate: toUtcDateMoment(leg.service_schedule_date)
})

const mapTransfer = transfer => ({
    ...camelCaseKeys(transfer),
    departureTime: toUtcDateMoment(transfer.departure_date_time),
    arrivalTime: toUtcDateMoment(transfer.arrival_date_time),
    transferTime: moment.duration(transfer.transfer_time)
})

const mapRoute = (route, stations) => {
    const isAvailable = route.bundles.length > 0
    const legs = route.legs.map(leg => mapLeg(leg, stations))
    const departureStation = legs[0].departureStation
    const arrivalStation = legs[legs.length - 1].arrivalStation
    const duration = moment.duration(route.travel_duration)
    const servicePropertiesPerLeg = legs.map(leg => leg.serviceProperties)
    const serviceProperties = intersectionBy(...servicePropertiesPerLeg, 'code')

    return {
        ...camelCaseKeys(route),
        transfers: route.transfers.map(mapTransfer),
        transferCount: route.legs.length - 1,
        serviceProperties,
        isAvailable,
        departureStation,
        arrivalStation,
        duration,
        bundles: route.bundles.map(bundle => mapBundle(bundle, route)),
        legs
    }
}

export const journeySearchSelector = createSelector(
    [
        baseJourneySearchSelector,
        stationsSelector
    ],
    (journeySearch, stations) => {
        if (!journeySearch) {
            return null
        }

        return {
            ...camelCaseKeys(journeySearch),
            travels: journeySearch.travels.map(travel => mapTravel(travel, stations))
        }
    }
)

export const productFamiliesSelector = state => {
    const journeySearch = journeySearchSelector(state)
    return journeySearch && journeySearch.productFamilies.map(camelCaseKeys)
}

export const comfortZonesSelector = createSelector(
    [journeySearchSelector],
    journeySearch => (journeySearch && journeySearch.comfortZones.reduce(
        (map, comfortZone) => map.set(comfortZone.code, comfortZone),
        new Map()
    )) || new Map()
)

export const travelsSelector = createSelector(
    [journeySearchSelector],
    journeySearch => (journeySearch && journeySearch.travels.reduce(
        (travels, travel) => travels.set(travel.id, travel),
        new Map()
    )) || new Map()
)

export const isReturnJourney = state => {
    const travels = travelsSelector(state)
    return Boolean(travels && travels.size === 2)
}

export const routesSelector = createSelector(
    [travelsSelector, productFamiliesSelector],
    (travels, productFamilies) => (travels && Array.from(travels.values()).reduce(
        (routes, travel) => {
            travel.routes.forEach(route => routes.set(route.id, {
                travelId: travel.id,
                direction: travel.direction,
                ...route,
                bundles: route.bundles && route.bundles.map(bundle => ({
                    ...bundle,
                    productFamily: productFamilies.find(({id}) => id === bundle.productFamilyId)
                }))
            }))
            return routes
        },
        new Map()
    )) || new Map()
)

export const bundlesSelector = createSelector(
    [routesSelector],
    routes => (routes && Array.from(routes.values()).reduce(
        (bundles, route) => {
            route.bundles.forEach(bundle => bundles.set(bundle.id, {
                travelId: route.travelId,
                routeId: route.id,
                direction: route.direction,
                passengerPrices: bundle.items.reduce((prices, item) => {
                    if (item.required) {
                        item.passengerFares.forEach(fare => {
                            const sum = prices.get(fare.passengerId) || 0
                            prices.set(fare.passengerId, fare.price + sum)
                        })
                    }
                    return prices
                }, new Map()),
                ...bundle
            }))
            return bundles
        },
        new Map()
    )) || new Map()
)

export const itemsSelector = createSelector(
    [bundlesSelector],
    bundles => (bundles && Array.from(bundles.values()).reduce(
        (items, bundle) => {
            bundle.items.forEach(item => items.set(item.id, {
                travelId: bundle.travelId,
                routeId: bundle.routeId,
                bundleId: bundle.id,
                direction: bundle.direction,
                ...item
            }))
            return items
        },
        new Map()
    )) || new Map()
)
