import _ from 'lodash'
import {
    PRODUCT_CODE_PROMO,
    OUTBOUND_JOURNEY,
    INBOUND_JOURNEY,
    PASSENGER_TYPE_ADULT
} from '../constants'

export default class TagCommanderMapper {
}

TagCommanderMapper.Booking = {
    getAdultPassengers (booking) {
        return booking.passengers.filter(passenger => passenger.type === PASSENGER_TYPE_ADULT)
    },

    getOutboundTicketPricePassengerWithVAT (booking) {
        const adultPassengerIds = TagCommanderMapper.Booking.getAdultPassengers(booking).map(passenger => passenger.id)
        return TagCommanderMapper.SegmentCollection.getRequiredProductsAveragePrice(
            booking.outboundSegmentCollection.withoutCancelled(),
            adultPassengerIds)
    },

    getInboundTicketPricePassengerWithVAT (booking) {
        const adultPassengerIds = TagCommanderMapper.Booking.getAdultPassengers(booking).map(passenger => passenger.id)
        return TagCommanderMapper.SegmentCollection.getRequiredProductsAveragePrice(
            booking.inboundSegmentCollection.withoutCancelled(),
            adultPassengerIds
        )
    },

    getOutboundTicketPriceTotalWithVAT (booking) {
        return _.round(
            TagCommanderMapper.SegmentCollection.getRequiredProductsTotalPrice(booking.outboundSegmentCollection),
            2
        )
    },

    getInboundTicketPriceTotalWithVAT (booking) {
        return _.round(
            TagCommanderMapper.SegmentCollection.getRequiredProductsTotalPrice(booking.inboundSegmentCollection),
            2
        )
    },

    getTicketPriceTotalWithVAT (booking) {
        return _.round(
            TagCommanderMapper.SegmentCollection.getRequiredProductsTotalPrice(booking.tariffSegmentCollection),
            2
        )
    }
}

TagCommanderMapper.Offer = {
    getLowestPrices (offer) {
        let lowestPrices = {}
        let lowestPricesPP = {}

        const adultPassengerIds = (TagCommanderMapper.Offer.getAdultPassengers(offer) || offer.passengers)
            .map(passenger => passenger.id)

        for (let travel of offer.travels) {
            const route = travel.routes.find(route => route.id === travel.best_route_id)
            const bundle = route && route.bundles.find(bundle => bundle.id === route.best_bundle_id)

            const averageAdultPrice = bundle
                ? Array.from(bundle.passengerPrices.entries()).reduce(
                    (sum, [id, price]) => adultPassengerIds.includes(id) ? sum + price : sum, 0
                ) / (1.0 * (adultPassengerIds.length || 1)) : 0

            lowestPrices[travel.direction] = bundle ? bundle.price : undefined
            lowestPricesPP[travel.direction] = bundle ? averageAdultPrice : undefined
        }

        return {
            outward_total: OUTBOUND_JOURNEY in lowestPrices ? lowestPrices[OUTBOUND_JOURNEY] : 0,
            outward_pp: OUTBOUND_JOURNEY in lowestPricesPP ? lowestPricesPP[OUTBOUND_JOURNEY] : 0,
            return_total: INBOUND_JOURNEY in lowestPrices ? lowestPrices[INBOUND_JOURNEY] : 0,
            return_pp: INBOUND_JOURNEY in lowestPricesPP ? lowestPricesPP[INBOUND_JOURNEY] : 0
        }
    },

    getAdultPassengers (offer) {
        return offer.passengers.filter(passenger => passenger.type === PASSENGER_TYPE_ADULT)
    },

    getOutboundProductTypeCounts (offer) {
        return this._getProductTypeCounts(offer.outboundRoutes)
    },

    getInboundProductTypeCounts (offer) {
        return this._getProductTypeCounts(offer.inboundRoutes)
    },

    _getProductTypeCounts (routes) {
        return routes.reduce((productTypeCounts, route) => {
            if (route.bundles.some(this._isPromoBundle)) {
                productTypeCounts.promo++
            } else if (route.bundles.length) {
                productTypeCounts.standard++
            }
            return productTypeCounts
        }, {promo: 0, standard: 0})
    },

    _isPromoBundle (bundle) {
        return bundle.required_products.some(product => PRODUCT_CODE_PROMO === product.code)
    }
}

TagCommanderMapper.SegmentCollection = {
    canChangeSeatOnAnyRequiredProduct (segmentCollection) {
        return segmentCollection.requiredProducts.some(product => product.can_change_seat)
    },

    getRequiredProductsTotalPrice (segmentCollection) {
        return _.sum(segmentCollection.requiredProducts.map(product => product.price))
    },

    getMaxTransferCount (isReturnTrip, segmentCollection) {
        const maxTransfers = _(segmentCollection)
            .groupBy(segment =>
                `${segment.departure_station._u_i_c_station_code}|${segment.arrival_station._u_i_c_station_code}`
            )
            .values()
            .size()

        return isReturnTrip ? maxTransfers - 2 : maxTransfers - 1
    },

    getRequiredProductsAveragePrice (segmentCollection, passengerIds) {
        if (!passengerIds) {
            passengerIds = []
        } else if (!_.isArray(passengerIds)) {
            passengerIds = [passengerIds]
        }

        return segmentCollection.requiredProducts
                .reduce(
                    (sum, product) => {
                        return !passengerIds || passengerIds.includes(product.passenger_id)
                            ? sum + product.price
                            : sum
                    },
                    0
                ) /
            (1.0 * passengerIds.length)
    }
}
