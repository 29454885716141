// Libraries
import React, {Component} from 'react'
import _t from '../../../translate'
import classNames from 'classnames'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'

// Components
import Icon from '../../../element/icon'
import TextLabel from '../../../element/text-label'

const BOOKING_EXPIRED = 'expired'
const BOOKING_PROVISIONAL = 'pending'
const BOOKING_CANCELLED = 'cancelled'
const BOOKING_CONFIRMED = 'approved'

const getBookingStatus = booking => {
    if (booking) {
        if ((booking.expiry_timestamp && moment(booking.expiry_timestamp).isBefore()) || (booking.cancelled && !booking.confirmed_timestamp)) {
            return BOOKING_EXPIRED
        } else if (booking.provisional) {
            return BOOKING_PROVISIONAL
        } else if (booking.cancelled) {
            return BOOKING_CANCELLED
        } else if (!booking.cancelled && !booking.provisional) {
            return BOOKING_CONFIRMED
        }
    }
    return null
}

export default class BookingStatus extends Component {
    static propTypes = {
        booking: PropTypes.shape({
            cancelled: PropTypes.bool.isRequired,
            provisional: PropTypes.bool.isRequired,
            expiry_timestamp: PropTypes.string
        }).isRequired
    }

    constructor (...args) {
        super(...args)
        this._getClassName = this._getClassName.bind(this)
        this._getIconType = this._getIconType.bind(this)
        this._getText = this._getText.bind(this)
    }

    render () {
        return (
            <span className={this._getClassName()}>
                <Icon type={this._getIconType()} className='small align-left' />
                <TextLabel text={this._getText()} />
            </span>
        )
    }

    _getClassName () {
        const booking = this.props.booking

        return classNames('text-label', booking.cancelled ? 'danger' : booking.provisional ? '' : 'success')
    }

    _getIconType () {
        const status = getBookingStatus(this.props.booking)
        return status === BOOKING_CANCELLED || status === BOOKING_EXPIRED ? 'cross' : status === BOOKING_PROVISIONAL ? 'hourglass' : 'tick'
    }

    _getText () {
        const status = getBookingStatus(this.props.booking)
        return _t.getIntlMessage('my-s3-booking-overview.booking-status.' + status)
    }
}

