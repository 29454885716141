/* globals S3P_SETTINGS: true */

import request from 'superagent'
import moment from 'moment-timezone'
import storage from './storage'
import _t from './translate'
import actions from './reflux/actions'

const TOKEN_DELAY = 5000 // milliseconds
const TOKEN_MAX_RETRY = 5

export default {

    state: {
        pendingRequest: null,
        tokenError: null,
        token: null,
        tokenCount: 0,
        lastTokenRequest: null
    },

    _getToken () {
        if (!this.state.token) {
            this.state.token = storage.get('OAUTH_TOKEN')
        }

        return this.state.token
    },

    _setToken (token) {
        if (token) {
            this.state.token = token

            token.expires_date = moment().add(token.expires_in, 'seconds').toISOString()
            storage.set('OAUTH_TOKEN', token)
        }
    },

    reset () {
        this.logout()

        return this.process()
    },

    logout () {
        return new Promise(resolve => {
            storage.clear('OAUTH_TOKEN')
            this.state.token = null
            this.state.pendingRequest = null
            resolve()
        })
    },

    hasToken () {
        return Boolean(this.state.token || storage.has('OAUTH_TOKEN'))
    },

    hasValidToken () {
        let isValid = false
        if (this.hasToken()) {
            // add 5 seconds margin to avoid small time differences
            isValid = !moment().add(5, 's').isAfter(this._getToken().expires_date)
        }

        return isValid
    },

    hasPendingRequest () {
        return this.state.pendingRequest
    },

    publicAccess (code) {
        return this.process({
            grant_type: 'https://com.sqills.s3.oauth.public',
            code: code || ''
        })
    },

    bookingLogin (bookingNumber, email, code) {
        return this.process({
            grant_type: 'https://com.sqills.s3.oauth.booking',
            booking_number: bookingNumber,
            email,
            code
        })
    },

    bookingLoginByPassenger (bookingNumber, passengerFirstName, passengerLastName) {
        return this.process({
            grant_type: 'https://com.sqills.s3.oauth.booking',
            booking_number: bookingNumber,
            passenger_first_name: passengerFirstName,
            passenger_last_name: passengerLastName
        })
    },

    crmLogin (credentials) {
        let upgradeToken
        if (this.hasValidToken()) {
            upgradeToken = this._getToken().access_token
        }

        return this.process({
            grant_type: 'https://com.sqills.s3.oauth.crm',
            ...credentials,
            upgrade_token: upgradeToken
        })
    },

    process (postData) {
        // secret is base64_encode(client_id + ':' + client_secret)
        const postRequest = requestData => {
            this.state.lastTokenRequest = Date.now()
            return request
                .post(S3P_SETTINGS.s3Passenger.baseUri + '/oauth/v2/token')
                .set('Accept-Language', _t.getLocales())
                .set('Authorization', 'Basic ' + S3P_SETTINGS.s3Passenger.oAuth.secret)
                .send(requestData)
        }

        const delayRequest = callback => {
            if (this.state.lastTokenRequest && Date.now() - this.state.lastTokenRequest < TOKEN_DELAY) {
                setTimeout(callback, Date.now() - this.state.lastTokenRequest)
            } else {
                callback()
            }
        }

        const requestToken = () => {
            this.state.tokenCount++
            return new Promise((resolve, reject) => {
                delayRequest(
                    () => postRequest(postData)
                        .end((err, res) => {
                            if (err || !res.ok) {
                                reject(err)
                            } else {
                                resolve(res.body)
                            }
                        })
                )
            })
        }

        const refreshToken = () => {
            return new Promise((resolve, reject) => {
                delayRequest(
                    () => postRequest({
                        grant_type: 'refresh_token',
                        refresh_token: this._getToken().refresh_token
                    }).end((err, res) => {
                        if (err || !res.ok) {
                            this.state.lastTokenRequest = null
                            this.logout().then(() => reject(err))
                        } else {
                            resolve(res.body)
                        }
                    })
                )
            })
        }

        const handleRequest = promise => {
            promise.then(token => {
                this.state.pendingRequest = null
                this.state.tokenCount = 0
                this._setToken(token)
                actions.newToken()

                return token
            })
            promise.catch(err => {
                this.state.pendingRequest = null
                this.state.tokenError = err
                actions.failedToken(err)

                return err
            })

            return promise
        }

        if (this.state.tokenCount < TOKEN_MAX_RETRY) {
            if (!this.state.pendingRequest && postData) {
                this.state.pendingRequest = handleRequest(requestToken())
            } else if (!this.state.pendingRequest && this.hasToken() && !this.hasValidToken()) {
                this.state.pendingRequest = handleRequest(refreshToken())
            }

            if (this.state.pendingRequest) {
                return this.state.pendingRequest
            } else {
                return new Promise((resolve, reject) => {
                    if (this.hasToken()) {
                        resolve(this._getToken())
                    } else {
                        reject(this.state.tokenError)
                    }
                })
            }
        } else {
            this.state.pendingRequest = null
            window.reacthistory.push(`/${_t.getLocales()}${S3P_SETTINGS.s3Passenger.features.errorPage}`)

            return new Promise(() => {})
        }
    }
}
