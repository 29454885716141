import React, {Component} from 'react'
import CmsBlockContent from '../cms/cms-block-content'
import InformationTitle from './information-title'

export default class Information extends Component {
    render () {
        return (
            <div className='booking-options-information'>
                <div className='panel'>
                    <InformationTitle name='options-information' />
                    <div className='content'>
                        <CmsBlockContent name='options-information' />
                    </div>
                </div>
            </div>
        )
    }
}
