import React, {Component} from 'react'
import InputField from './input-field'

export default class TelephoneField extends Component {
    render () {
        const props = {...this.props}
        delete props.type

        return <InputField ref='input' type='tel' {...props} />
    }

    getInputElement () {
        return this.refs.input.getInputElement()
    }

    getValue () {
        return this.refs.input.getValue()
    }

    setValue (value) {
        return this.refs.input && this.refs.input.setValue(value)
    }
}
