// Libraries
import React, {Component} from 'react'
import PropTypes from 'prop-types'
import _t from '../../translate'

// Components
import Row from '../../element/row'
import ModalDialog from '../../element/modal-dialog'
import CmsBlockContent from '../../components/cms/cms-block-content'
import LegalItem from './footer/legal-item'
import FooterCommon from './footer-common'

import bookingStore from '../../reflux/stores/booking-store'
import {PRODUCT_CODE_ALSA, PRODUCT_CODE_NEL} from '../../constants'
import cmsComponent from '../../components/cms/cms-component'
import {isBlablabusDomain} from '../../misc/blablabus-helpers'

class LayoutFooter extends Component {
    constructor (props) {
        super(props)

        this.state = {
            showDialog: false
        }
        this.handleToggleDialog = this.handleToggleDialog.bind(this)
        this.isAlsa = this.isAlsa.bind(this)
        this.isNel = this.isNel.bind(this)
    }

    static propTypes = {
        title: PropTypes.string,
        cmsBlocks: PropTypes.array
    }

    isAlsa () {
        const booking = bookingStore.getBookingModel()
        return booking && booking.products.some(product => !product.cancelled && product.code === PRODUCT_CODE_ALSA)
    }

    isNel () {
        const booking = bookingStore.getBookingModel()
        return booking && booking.products.some(product => !product.cancelled && product.code === PRODUCT_CODE_NEL)
    }

    render () {
        return (
            <FooterCommon {...this.props}>
                <Row className='row contains-footer'>
                    <div className='grid-row'>
                        <div className='grid-column--1-1'>
                            <div className='legal'>
                                <ul className='horizontal-list'>
                                    <LegalItem
                                        key='cancel-return-conditions'
                                        onClick={this.handleToggleDialog}
                                        label={this.isAlsa() || this.isNel()
                                            ? _t.getIntlMessage('footer.legal.cancel-return-conditions-alsa-nel.label')
                                            : _t.getIntlMessage('footer.legal.cancel-return-conditions.label')
                                        }
                                    />
                                    <LegalItem
                                        key='passenger-rights'
                                        cmsBlockName='passenger-rights'
                                    />
                                    <LegalItem
                                        key='general-terms'
                                        cmsBlockName='terms-conditions'
                                    />
                                    <LegalItem
                                        key='legal-information'
                                        cmsBlockName='legal-notices'
                                    />
                                    <LegalItem
                                        key='privacy-policy'
                                        cmsBlockName='confidentiality-charter'
                                    />
                                    {isBlablabusDomain() &&
                                    <LegalItem
                                        key='terms-conditions-2'
                                        cmsBlockName='terms-conditions-2'
                                    />
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </Row>
                {this._renderDialog()}
            </FooterCommon>
        )
    }

    _renderDialog () {
        if (!this.state.showDialog) {
            return null
        }

        const name = this.isNel() ? 'CONDITIONS-STATIC' : this.isAlsa() ? 'CONDITIONS-STATIC_2' : 'CONDITIONS-APV'
        const block = this.props.cmsBlocks && this.props.cmsBlocks.find(block => block.name === name)
        const title = block ? block.title : ''
        return (
            <ModalDialog headerTitle={title} onClose={this.handleToggleDialog}>
                <CmsBlockContent name={name} className='content' showLoader />
            </ModalDialog>
        )
    }

    handleToggleDialog (event) {
        event.preventDefault()
        this.setState(prevState => ({showDialog: !prevState.showDialog}))
    }
}

const cmsBlockNames = [
    'CONDITIONS-APV',
    'CONDITIONS-STATIC',
    'CONDITIONS-STATIC_2',
    'terms-conditions',
    'legal-notices',
    'confidentiality-charter',
    'terms-conditions-2',
    'passenger-rights'
]

export default cmsComponent(...cmsBlockNames)(LayoutFooter)

