import React from 'react'
import PropTypes from 'prop-types'
import {withRouter} from 'react-router'
import Reflux from 'reflux'
import filter from 'lodash/filter'
import union from 'lodash/union'
import without from 'lodash/without'
import _t from '../translate'
import MyS3UpdatePassengersLayoutContainer from './my-s3-update-passengers-layout-container'
import actions from '../reflux/actions'
import UpdatePassengersDetailsStore from '../reflux/stores/update-passenger-details-store'
import restoreBooking from '../components/restore/restore-booking'
import BookingModel from '../models/booking-model'
import BookingStore from '../reflux/stores/booking-store'
import createReactClass from 'create-react-class'

export default withRouter(restoreBooking(createReactClass({

    displayName: 'MyS3UpdatePassengersLayout',

    propTypes: {
        router: PropTypes.object
    },

    mixins: [
        Reflux.connectFilter(BookingStore, 'booking', data => BookingModel.create(data.booking)),
        Reflux.connectFilter(BookingStore, 'loading', data => data.loading),
        Reflux.connect(UpdatePassengersDetailsStore, 'formData')
    ],

    getInitialState () {
        return {
            overrideAftersalesFees: false,
            editPassengers: [],
            showConfirmDialog: false,
            nextButtonLoading: false
        }
    },

    render () {
        return (
            <MyS3UpdatePassengersLayoutContainer
                loading={this.state.loading}
                nextButtonLoading={this.state.nextButtonLoading}
                showConfirmDialog={this.state.showConfirmDialog}
                booking={this.state.booking}
                editPassengers={this.state.editPassengers}
                hasEditPassengers={this.hasEditPassengers}
                onEditPassenger={this.onEditPassenger}
                onResetPassenger={this.onResetPassenger}
                onClickPrev={this.onClickPrev}
                onClickNext={this.onClickNext}
                confirmUpdate={this.confirmUpdate}
                revertUpdate={this.revertUpdate}
            />
        )
    },

    onClickPrev () {
        this.props.router.push(`/${_t.getLocales()}/mys3/booking/${this.state.booking.bookingNumber}`)
    },

    async onClickNext () {
        this.setState({nextButtonLoading: true})

        const passengers = filter(
            UpdatePassengersDetailsStore.getPassengersForApiCall(),
            passenger => this.state.editPassengers.indexOf(passenger.id) !== -1
        )

        const response = await actions.updatePassengers(this.state.booking.bookingNumber, {
            passengers,
            overrideAftersalesFees: this.state.overrideAftersalesFees
        })

        if (response.data && response.data.booking.booking_number) {
            if (response.data.booking.total_price_to_be_paid > 0) {
                this.props.router.push(`/${_t.getLocales()}/mys3/update-passengers/payment`)
            } else {
                this.setState({
                    showConfirmDialog: true,
                    nextButtonLoading: false
                })
            }
        }
    },

    revertUpdate () {
        this.setState({showConfirmDialog: false})
        actions.revertBooking(this.state.booking.bookingNumber)
    },

    confirmUpdate () {
        this.setState({showConfirmDialog: false})
        actions.confirmBooking(this.state.booking.bookingNumber).then(() => {
            actions.myS3UpdatePassengersSuccessful()
            this.props.router.push(`/${_t.getLocales()}/mys3/update-passengers/confirmation?s3_status=success`)
        })
    },

    hasEditPassengers () {
        return this.state.editPassengers.length > 0 && this.state.formData.isValid
    },

    onEditPassenger (id) {
        this.setState({
            editPassengers: union(this.state.editPassengers, [id])
        })
    },

    onResetPassenger (id) {
        actions.resetUpdatePassenger(id)
        this.setState({editPassengers: without(this.state.editPassengers, id)})
    }
}), {withBlockingLoader: true}))
