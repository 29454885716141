import PropTypes from 'prop-types'
import React, {Component} from 'react'
import isEmpty from 'lodash/isEmpty'
import device from '../device'
import BookingModel from '../models/booking-model'
import GenericLayout from './generic-layout'
import Row from '../element/row'
import BookingDetails from '../components/my-s3-booking/booking-details'
import AdPanel from '../components/my-s3-booking/ad-panel'
import Help from '../components/my-s3-booking/help'
import AfterSalesNavigation from '../base/after-sales-navigation'
import RelatedVoucherDetails from '../components/my-s3-booking/related-voucher-details'
import RefundMethod from '../components/my-s3-booking/refund-method'
import CustomerDetails from '../components/my-s3-booking/customer-details'
import cmsComponent from '../components/cms/cms-component'
import Loader from '../element/loader'
import AccessManager from '../data/access-manager'
import myS3BookingLayout from './my-s3-booking-layout'
import {withRouter} from 'react-router'

class MyS3BookingLayoutContainer extends Component {
    static propTypes = {
        booking: PropTypes.instanceOf(BookingModel),
        loading: PropTypes.bool.isRequired,
        onConfirmAfterSales: PropTypes.func.isRequired,
        onContinue: PropTypes.func.isRequired,
        onRevertAfterSales: PropTypes.func.isRequired,
        onRequestRefundPsp: PropTypes.func.isRequired,
        isLoadingRequestVoucher: PropTypes.bool.isRequired,
        handleRequestRefundVoucher: PropTypes.func.isRequired,
        handlePrintRefundVoucher: PropTypes.func.isRequired,
        shouldPrint: PropTypes.bool.isRequired,
        refundVoucher: PropTypes.object
    }

    render () {
        return (
            <GenericLayout className='booking-details'>
                {this.props.loading ? <Loader blocking overlay /> : null}
                {this._hasBooking() ? <AfterSalesNavigation /> : null}
                <Row className='row contains-my-s3-booking'>
                    {this._hasBooking() ? this._renderContent() : null}
                </Row>
            </GenericLayout>
        )
    }

    _renderContent () {
        return (
            <div className='grid-row'>
                <div className='grid-column--1-1 grid-column--medium-1-3'>
                    {this._renderCustomerDetails()}
                    {!device.isMobile() ? <Help /> : null}
                </div>
                <div className='grid-column--1-1 grid-column--medium-2-3'>
                    {this._renderRefundMethod()}
                    <BookingDetails
                        booking={this.props.booking}
                        onContinue={this.props.onContinue}
                        onConfirmAfterSales={this.props.onConfirmAfterSales}
                        onRevertAfterSales={this.props.onRevertAfterSales}
                    />
                    <AdPanel />
                    <RelatedVoucherDetails booking={this.props.booking} />
                    {device.isMobile() ? <Help /> : null}
                </div>
            </div>
        )
    }

    _renderRefundMethod () {
        return !isEmpty(this.props.booking.customer) && this.props.booking.openRefundAmount > 0 &&
            AccessManager.hasAccessToAfterSalesRefundVoucher()
            ? (
                <div className='panel'>
                    <RefundMethod
                        booking={this.props.booking}
                        isLoadingRequestVoucher={this.props.isLoadingRequestVoucher}
                        handleRequestRefundVoucher={this.props.handleRequestRefundVoucher}
                        handlePrintRefundVoucher={this.props.handlePrintRefundVoucher}
                        shouldPrint={this.props.shouldPrint}
                        refundVoucher={this.props.refundVoucher}
                        onRequestRefundPsp={this.props.onRequestRefundPsp}
                    />
                </div>
            ) : null
    }

    _renderCustomerDetails () {
        if (isEmpty(this.props.booking.customer)) {
            return null
        }

        return <CustomerDetails customer={this.props.booking.customer} />
    }

    _hasBooking () {
        return this.props.booking !== null && this.props.booking.bookingNumber !== null
    }
}

const blockNames = [
    'ad-panel-1',
    'ad-panel-2',
    'ad-panel-3',
    'AFTERSALES-FLEX-VOUCHER-POPUP'
]

export default withRouter(cmsComponent(...blockNames)(myS3BookingLayout(MyS3BookingLayoutContainer)))
