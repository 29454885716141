/* globals S3P_SETTINGS: true */

import moment from 'moment-timezone'

const parseDateString = date => {
    // Parse manually due to bug in moment::parseZone (moment v2.14.1)
    const matches = typeof date === 'string' && date.match(/^\d{4}-\d{1,2}-\d{1,2}/)

    return matches ? matches[0] : null
}

export const toUtcDateMoment = date => {
    const dateString = parseDateString(date)
    if (dateString) {
        return moment.utc(`${dateString}T00:00:00Z`)
    }

    if (!moment.isMoment(date)) {
        date = moment(date)
    }

    return date.isValid() ? moment.utc(`${date.format('YYYY-MM-DD')}T00:00:00Z`) : date
}

export const toTimezoneMoment = (dateTime, timezone = null) => {
    if (!timezone || !moment.tz.zone(timezone)) {
        timezone = S3P_SETTINGS.s3Passenger.features.defaultTimezone
    }

    return moment(dateTime).tz(timezone)
}

export const dateStringToLocalizedDate = date => {
    return moment(parseDateString(date)).toDate()
}
