import PropTypes from 'prop-types'
import React, {Component} from 'react'
import _t from '../../translate'
import _ from 'lodash'

import Radio from './radio-field'

export default class RadioGroup extends Component {
    static propTypes = {
        selectedValue: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.bool
        ]),
        data: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string.isRequired,
            text: PropTypes.string.isRequired,
            value: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
                PropTypes.bool
            ]).isRequired
        })),
        onChange: PropTypes.func,
        disabled: PropTypes.bool,
        className: PropTypes.string,
        id: PropTypes.string,
        errorText: PropTypes.string
    }

    _radioFields = {}

    static defaultProps = {
        data: [],
        onChange: () => {
        }
    }

    render () {
        const value = this.getValue()

        const radios = _.map(this.props.data, (item, i) => {
            const {id, ...other} = item

            return (
                <Radio
                    key={i}
                    checked={value === item.value}
                    id={`${this.props.id}-${id}`}
                    onChange={() => this.props.onChange(this.props.id, item.value)}
                    disabled={this.props.disabled}
                    ref={rf => {
                        if (rf) {
                            this._radioFields[rf.props.id] = rf
                        }
                    }}
                    {...other}
                >
                    <label htmlFor={`${this.props.id}-${id}`} className='text-label'>
                        {_t.getIntlMessage(item.text)}
                    </label>
                </Radio>
            )
        })

        return (
            <div className={this.props.className}>
                {radios}
                {this.getErrorFeedback()}
            </div>
        )
    }

    getErrorFeedback () {
        if (!this.props.errorText || this.props.errorText.length === 0) {
            return null
        }

        return <div className='input-feedback state--is-invalid'>{_t.getIntlMessage(this.props.errorText)}</div>
    }

    getValue () {
        return this.props.data.filter(item => {
            return this.props.selectedValue === item.value
        }).length > 0 ? this.props.selectedValue : ''
    }

    setValue (value) {
        const items = _.partition(this.props.data, item => value === item.value)
        for (let itemId in items) {
            if (itemId in this._radioFields) {
                this._radioFields[itemId].checked = (value === items[itemId].value)
            }
        }
    }

    focus () {
        const firstField = _.first(this._radioFields)
        return firstField && firstField.focus()
    }
}
