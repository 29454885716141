import PropTypes from 'prop-types'
import React, { Component } from 'react'
import LuggageSelector from '../../../components/luggage-selector/luggage-selector'
import {restoreBookingCompose} from '../../../components/restore/restore-booking'
import {restoreJourneySearchCompose} from '../../../components/restore/restore-journey-search'
import compose from '../../../misc/compose'
import InteractiveFlashMessage from '../../../components/interactive-flash-message'
import MyS3OptionsLayoutContainer from '../../my-s3-options-layout-container'

class LuggageSelectorContainer extends Component {
    static propTypes = {
        location: PropTypes.object
    }

    render () {
        return (
            <MyS3OptionsLayoutContainer name='luggage-selection-container'>
                <InteractiveFlashMessage />
                <LuggageSelector returnUrl='/mys3/luggage/payment' />
            </MyS3OptionsLayoutContainer>
        )
    }
}

const container = compose(
    restoreJourneySearchCompose({withBlockingLoader: true}),
    restoreBookingCompose({withBlockingLoader: true})
)

export default container(LuggageSelectorContainer)
