import _ from 'lodash'
import moment from 'moment-timezone'
import {PRODUCT_CODE_PROMO, SHOW_SEATS_THRESHOLD_HOURS, SERVICE_TYPE_MODALITY_TER} from '../constants'

export default class SegmentModel {
    static create (data) {
        return new SegmentModel(data)
    }

    constructor (data) {
        if (!_.isObject(data)) {
            throw Error('Booking should be an object')
        }
        this._data = _(data)
    }

    get id () {
        return this._data.get('id')
    }

    get direction () {
        return this._data.get('direction')
    }
    get requiredProducts () {
        return _(this._data.get('required_products', []))
    }

    get requiredProductsWithoutCancelled () {
        return this.requiredProducts.filter({cancelled: false})
    }

    get additionalProducts () {
        return _(this._data.get('additional_products', []))
    }

    getRequiredProductsByPassenger (passengerId) {
        return this.requiredProducts.filter({passenger_id: passengerId})
    }

    getAdditionalProductsByPassenger (passengerId) {
        return this.additionalProducts.filter({passenger_id: passengerId})
    }

    getProvisionalNonCancelledAdditionalProductsByPassengerAndCode (passengerId, code) {
        return this.additionalProducts.filter({passenger_id: passengerId, code: code, cancelled: false, provisional: true}).value()
    }

    get products () {
        return this.requiredProducts.concat(this.additionalProducts.value())
    }

    hasAdditionalProduct (productId) {
        return this.additionalProducts.map('item_id').includes(productId)
    }

    hasRequiredProduct (productId) {
        return this.requiredProducts.map('item_id').includes(productId)
    }

    get isFullyCancelled () {
        return this.products.filter({cancelled: false}).size() === 0
    }

    get journeySegments () {
        return _(this._data.get('booking_journey_segments', []))
    }

    get departureMoment () {
        const firstSegment = _(this.journeySegments).first()
        return moment.tz.zone(firstSegment.departure_station.timezone)
            ? moment(firstSegment.departure_date_time).tz(firstSegment.departure_station.timezone)
            : moment(firstSegment.departure_date_time)
    }

    get arrivalMoment () {
        const lastSegment = _(this.journeySegments).last()
        return moment.tz.zone(lastSegment.arrival_station.timezone)
            ? moment(lastSegment.arrival_date_time).tz(lastSegment.arrival_station.timezone)
            : moment(lastSegment.arrival_date_time)
    }

    get showSeatsThresholdMoment () {
        return this.departureMoment.subtract(SHOW_SEATS_THRESHOLD_HOURS, 'hours')
    }

    get serviceIdentifier () {
        return this._data.get('service_identifier')
    }

    get validityService () {
        return this._data.get('validity_service')
    }

    get departureStation () {
        return this._data.get('departure_station')
    }

    get arrivalStation () {
        return this._data.get('arrival_station')
    }

    get fees () {
        return this._data.get('fees')
    }

    get nonCancelledFees () {
        return this.fees.filter(fee => !fee.cancelled)
    }

    get feesToBePaid () {
        return this.nonCancelledFees.filter(fee => fee.provisional)
    }

    get feesPaid () {
        return this.nonCancelledFees.filter(fee => !fee.provisional)
    }

    get startValidityDate () {
        return this._data.get('validity_start_date')
    }

    hasSeats () {
        this.products.value().some(product => product.seat)
    }

    hasPromoTariff () {
        return this.requiredProducts.some(product => PRODUCT_CODE_PROMO === product.code)
    }

    getFirstPromoProduct () {
        return this.requiredProducts.find(product => PRODUCT_CODE_PROMO === product.code)
    }

    canViewSeats () {
        return this.departureMoment.diff(moment(), 'hours') < SHOW_SEATS_THRESHOLD_HOURS
    }

    hasTer () {
        return this.journeySegments.some(segment =>
            segment.service_type && segment.service_type.modality === SERVICE_TYPE_MODALITY_TER)
    }
}
