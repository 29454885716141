// Libraries
import React from 'react'
import {Route} from 'react-router'

// Layouts
import ErrorLayout from '../layouts/error-layout'

export default (
    <Route path='/:lang/error' component={ErrorLayout} />
)
