import PropTypes from 'prop-types'
import React from 'react'
import Reflux from 'reflux'
import device from '../../device'
import _t from '../../translate'
import moment from 'moment-timezone'
import actions from '../../reflux/actions'
import CurrencyStore from '../../reflux/stores/currency-store'
import OfferStore from '../../reflux/stores/offer-store'
import CalendarStore from '../../reflux/stores/calendar-store'
import TextLabel from '../../element/text-label'
import {PASSENGER_TYPE_ADULT} from '../../constants'
import {camelCaseKeys} from '../../misc/camelcase'
import createReactClass from 'create-react-class'

export default createReactClass({

    displayName: 'currencySelector',

    mixins: [
        Reflux.connect(CurrencyStore, 'currency')
    ],

    propTypes: {
        enabled: PropTypes.bool,
        currencies: PropTypes.array
    },

    getDefaultProps () {
        return {
            enabled: false
        }
    },

    _onChangeCurrency (event) {
        const journeySelection = {
            ...this._processJourneySearchData(camelCaseKeys(OfferStore.getJourneySearchData())),
            currency: event.target.value
        }
        actions.getOffer(journeySelection)
        actions.getCalendar(CalendarStore.createDateSwitcherRequestData(journeySelection))
    },

    render () {
        return (
            <div className='journey-result-set-filter currency-selector'>
                {device.isDesktopOrTablet() ? <div className='grid-row'>
                    <div className='grid-column--1-1'>
                        <label
                            htmlFor='currency-selector'
                            className='text-label select-currency-description'
                        >
                            <TextLabel text={_t.getIntlMessage('journey-result-set.currency-selector')}
                            />
                        </label>
                    </div>
                </div> : null}
                <div className='grid-row'>
                    <div className='grid-column--1-1'>
                        <select
                            id='currency-selector'
                            value={this.state.currency.currency}
                            onChange={this._onChangeCurrency}
                            disabled={!this.props.enabled}
                        >
                            {this._getOptions(this.props.currencies)}
                        </select>
                    </div>
                </div>
            </div>
        )
    },

    _getOptions (currencies) {
        return currencies.map(currency =>
            <option
                key={currency}
                value={currency}
            >
                {_t.getIntlMessage(`currency-selector.${currency.toLowerCase()}`)}
            </option>
        )
    },

    _processJourneySearchData (journeySearch) {
        return {
            origin: journeySearch.originStation ? journeySearch.originStation.UICStationCode : '',
            destination: journeySearch.destinationStation
                ? journeySearch.destinationStation.UICStationCode : '',
            outboundDate: journeySearch.departureDate
                ? journeySearch.departureDate : moment().startOf('day').toDate(),
            inboundDate: journeySearch.returnDate ? journeySearch.returnDate : '',
            passengers: journeySearch.passengers ? journeySearch.passengers : [{type: PASSENGER_TYPE_ADULT}]
        }
    }

})
