import React, {Component} from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import device from '../../device'
import Option from './list/option'
import OptionBox from './option-box'
import NoResults from './list/no-results'
import ListContainer from './list-container'
import {connectState, getState} from '../../reflux/bridge/connect-state'
import {travelsSelector, comfortZonesSelector} from '../../models/selectors/api/v2/orientation/journey-search'
import {routesSelector} from '../../models/selectors/components/orientation/journey-search'
import {passengersSelector} from '../../models/selectors/api/v2/orientation/passenger'
import sortBy from 'lodash/sortBy'

class List extends Component {
    static propTypes = {
        travelId: PropTypes.string.isRequired,
        onOptionClick: PropTypes.func.isRequired,
        onSearch: PropTypes.func.isRequired,
        comfortZones: PropTypes.array.isRequired,
        routes: PropTypes.array.isRequired,
        travel: PropTypes.object,
        selectedBundle: PropTypes.object,
        direction: PropTypes.string,
        passengers: PropTypes.array
    }

    constructor (...args) {
        super(...args)
        this.state = {
            selectedBundle: this.props.selectedBundle,
            sortBy: 'departureTime',
            routeTransfersFilter: null,
            showAsBox: this.props.selectedBundle && this.props.selectedBundle.travelId === this.props.travel.id
        }
        this._getListOptions = this._getListOptions.bind(this)
        this.onOptionClick = this.onOptionClick.bind(this)
    }

    componentWillReceiveProps (props) {
        if (this.state.selectedBundle !== props.selectedBundle) {
            this.setState({selectedBundle: props.selectedBundle})
        }
    }

    _getListOptions (shouldFilter = false) {
        if (!this.props.travel) {
            return null
        }

        let journeyResultSetListOptions
        let routes = this._filterRoutes(this.props.routes)
        if (shouldFilter && this.state.selectedBundle) {
            routes = routes.filter(route => route.bundles.some(bundle => this.state.selectedBundle.id === bundle.id))
        }

        if (routes.length > 0) {
            journeyResultSetListOptions = this._sortRoutes(routes).map(route => {
                return (
                    <Option
                        key={route.id}
                        route={route}
                        bundles={route.bundles}
                        selectedBundle={this.props.selectedBundle}
                        onClick={this.onOptionClick}
                        onExpand={this.onOptionExpand}
                        comfortZones={this.props.comfortZones}
                        travel={{
                            ...this.props.travel,
                            hasPromoBundle: this.props.routes.some(route => route.hasPromoBundle)
                        }}
                        passengers={this.props.passengers}
                        isBestTime={route.isFastestRoute}
                    />
                )
            })
        } else {
            journeyResultSetListOptions = <NoResults
                direction={this.props.travel.direction}
                onSearch={this.props.onSearch}
            />
        }

        return journeyResultSetListOptions
    }

    _getOptionBox () {
        return (
            <div>
                <ListContainer
                    routes={this.props.routes}
                    travel={this.props.travel}
                    onSearch={this.props.onSearch}
                    sortBy={this.state.sortBy}
                    onChangeSortBy={value => this.setState({sortBy: value})}
                    onChangeTransfersFilter={value => this.setState({routeTransfersFilter: value})}
                    routeTransfersFilterValue={this.state.routeTransfersFilter}
                >
                    {this._getListOptions(true)}
                </ListContainer>
                <OptionBox
                    direction={this.props.direction}
                    onHideOptionBox={this.hideOptionBox}
                />
            </div>
        )
    }

    _hasSelection () {
        return this.state.selectedBundle && this.state.selectedBundle.travel_id === this.props.travel.id
    }

    _filterRoutes (routes) {
        return routes.filter(route => {
            if (this.state.routeTransfersFilter === 'direct') {
                return route.transfers.length === 0
            }

            return true
        })
    }

    _sortRoutes (routes) {
        if (this.state.sortBy === 'price') {
            return sortBy(routes, route => {
                const bestBundle = route.bundles.find(bundle => bundle.isCheapestRouteBundle)
                return bestBundle ? bestBundle.price : null
            })
        }
        if (this.state.sortBy === 'departureTime') {
            return sortBy(routes, route => {
                const departureTime = route.departureStation.departureTime
                return departureTime ? departureTime.unix() : null
            })
        }
        if (this.state.sortBy === 'arrivalTime') {
            return _.sortBy(routes, route => {
                const arrivalTime = route.arrivalStation.arrivalTime
                return arrivalTime ? arrivalTime.unix() : null
            })
        }
        if (this.state.sortBy === 'transfers') {
            return sortBy(routes, route => route.transferCount)
        }
        if (this.state.sortBy === 'duration') {
            return sortBy(routes, route => route.duration.asMilliseconds())
        }

        return routes
    }

    render () {
        return (!device.isDesktopOrTablet() && this.state.showAsBox) ? this._getOptionBox() : (
            <ListContainer
                routes={this.props.routes}
                travel={this.props.travel}
                onSearch={this.props.onSearch}
                sortBy={this.state.sortBy}
                onChangeSortBy={value => this.setState({sortBy: value})}
                onChangeTransfersFilter={value => this.setState({routeTransfersFilter: value})}
                routeTransfersFilterValue={this.state.routeTransfersFilter}
            >
                {this._getListOptions()}
            </ListContainer>
        )
    }

    onOptionClick (bundle) {
        this.setState({selectedBundle: bundle, showAsBox: true}, () => this.props.onOptionClick(bundle))
    }

    hideOptionBox () {
        this.setState({showAsBox: false})
    }

    onOptionExpand (bundle) {
        // only selected bundle from the first row that is expanded
        if (_.isEmpty(this.state.selectedBundle)) {
            this.onOptionClick(bundle)
        }
    }
}

const mapPropsToProps = ownProps => {
    const state = getState()
    const travel = travelsSelector(state).get(ownProps.travelId)
    const passengers = passengersSelector(state)
    const comfortZones = comfortZonesSelector(state)
    const routes = routesSelector(ownProps.travelId)(state)
    return {
        travel,
        routes,
        comfortZones: Array.from(comfortZones.values()),
        passengers
    }
}

export default connectState(mapPropsToProps)(List)
