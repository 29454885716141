import React from 'react'
import Reflux from 'reflux'
import _t from '../../translate'
import Button from '../../element/button'
import TextLabel from '../../element/text-label'
import ModalDialog from '../../element/modal-dialog'
import Reach5 from '../../mixins/reach-5'
import Reach5Store from '../../reflux/stores/reach-5-store'
import createReactClass from 'create-react-class'

const REACH_5_SOCIAL_CONTAINER = 'reach-5-social-container'
const REACH_5_EMAIL_CONTAINER = 'reach-5-email-container'

const Reach5Widget = createReactClass({
    mixins: [
        Reach5(REACH_5_SOCIAL_CONTAINER),
        Reflux.connectFilter(Reach5Store, 'userLoggedIn', data => data.userLoggedIn)
    ],

    getInitialState () {
        return {
            showDialog: false
        }
    },

    componentDidMount () {
        if (!this.state.userLoggedIn) {
            this.onShowSocial()
        }
    },

    componentDidUpdate (_, prevState) {
        if (this.state.showDialog && prevState.showDialog !== this.state.showDialog) {
            this.onShowOnlyEmail(REACH_5_EMAIL_CONTAINER)
        }
    },

    render () {
        return !this.state.userLoggedIn ? (
            <div className='reach-5-widget'>
                <ul className='tick-list' key='bullet-list'>
                    <li>{_t.getIntlMessage('reach-5-widget.bullet-1')}</li>
                    <li>{_t.getIntlMessage('reach-5-widget.bullet-2')}</li>
                    <li>{_t.getIntlMessage('reach-5-widget.bullet-3')}</li>
                    <li>{_t.getIntlMessage('reach-5-widget.bullet-4')}</li>
                </ul>
                {this.props.children}
                <div className='grid-row'>
                    <div
                        className='grid-column--1-1 grid-column--medium-2-3'
                        id={REACH_5_SOCIAL_CONTAINER}
                        key='reach-5' />
                    <div className='grid-column--1-1 grid-column--medium-1-3'>
                        <Button
                            key='reach-5-email-button'
                            className='button secondary r5-email-login'
                            type='button'
                            onClick={this.showDialog}>
                            <TextLabel text={_t.message('reach-5-widget.email')} />
                        </Button>
                    </div>
                </div>
                {this._renderEmailModal()}
            </div>
        ) : null
    },

    showDialog (e) {
        e.preventDefault()
        this.setState({showDialog: true})
    },

    hideDialog () {
        this.setState({showDialog: false})
    },

    _renderEmailModal () {
        return this.state.showDialog ? (
            <ModalDialog
                key='reach-5-email-modal'
                headerTitle=' '
                onClose={this.hideDialog}
            >
                <div className='content'>
                    <div id={REACH_5_EMAIL_CONTAINER} />
                </div>
            </ModalDialog>
        ) : null
    }
})

export default Reach5Widget
