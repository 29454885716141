// Libraries
import React, {Component} from 'react'
// Components
import cmsComponent from '../../components/cms/cms-component'
import CmsBlockContent from '../../components/cms/cms-block-content'

class HeaderVscNew extends Component {
    render () {
        return <CmsBlockContent name='header-vsc-new' />
    }
}

export default cmsComponent('header-vsc-new')(HeaderVscNew)
