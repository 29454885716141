// libraries
import React, { Component } from 'react'
import _t from '../../translate'

// components
import TextLabel from '../../element/text-label'

export default class NoResults extends Component {
    render () {
        return (
            <div className='journey-no-result'>
                <header>
                    <span className='text-label heading'>
                        <TextLabel text={_t.getIntlMessage('journey-result-set.no-results.header')} />
                    </span>
                    <span className='text-label sub-heading'>
                        <TextLabel text={_t.getIntlMessage('journey-result-set.no-results.sub-header')} />
                    </span>
                </header>
                <p><strong>{_t.getIntlMessage('journey-result-set.no-results.suggestions.header')}</strong></p>
                <ul>
                    <li>{_t.getIntlMessage('journey-result-set.no-results.suggestions.1')}</li>
                    <li>{_t.getIntlMessage('journey-result-set.no-results.suggestions.2')}</li>
                </ul>
            </div>
        )
    }
}
