import PropTypes from 'prop-types'
import React, {Component} from 'react'
import device from '../device'
import _t from '../translate'
import GenericLayout from './generic-layout'
import Row from '../element/row'
import ProgressNavigation from '../base/main/progress-navigation-container'
import CancelEntireBooking from '../components/my-s3-cancellation/cancel-entire-booking'
import CancelPerJourney from '../components/my-s3-cancellation/cancel-per-journey'
import CancelPerPassenger from '../components/my-s3-cancellation/cancel-per-passenger'
import Help from '../components/my-s3-booking/help'
import AfterSalesNavigation from '../base/after-sales-navigation'
import AfterSalesSummary from '../components/after-sales-summary'
import BookingModel from '../models/booking-model'
import PanelCard from '../element/panel/card'
import cmsComponent from '../components/cms/cms-component'
import {cmsBlockContainer} from '../components/cms/cms-block-container'
import CancelReason from '../components/my-s3-cancellation/cancel-reason'
import ConfirmDialog from '../components/my-s3-cancellation/confirm-dialog'

const CmsBlockPanelCard = cmsBlockContainer('AFTERSALES-CANCEL-FLEX-HOME', PanelCard)
const CmsBlockAlsaPanelCard = cmsBlockContainer('AFTERSALES-CANCEL-STATIC', PanelCard)
const CmsBlockNelPanelCard = cmsBlockContainer('AFTERSALES-CANCEL-STATIC_2', PanelCard)

class MyS3CancellationLayoutContainer extends Component {
    static propTypes = {
        booking: PropTypes.instanceOf(BookingModel).isRequired,
        loading: PropTypes.bool.isRequired,
        cancelItemIds: PropTypes.array.isRequired,
        onEntireBookingAction: PropTypes.func.isRequired,
        onJourneyAction: PropTypes.func.isRequired,
        onPassengerAction: PropTypes.func.isRequired,
        onSelectCancelReason: PropTypes.func.isRequired,
        cancelReason: PropTypes.string,
        includeFees: PropTypes.bool,
        disableCancelPerJourney: PropTypes.bool,
        disableCancelPerPassenger: PropTypes.bool,
        showConfirmDialog: PropTypes.bool.isRequired,
        onProceed: PropTypes.func.isRequired,
        onCancel: PropTypes.func.isRequired,
        onConfirm: PropTypes.func.isRequired,
        onSelectCancellationOption: PropTypes.func.isRequired,
        selectedCancellationOption: PropTypes.string,
        nextButtonEnabled: PropTypes.bool
    }

    render () {
        const progressNavigationProps = {
            previousButtonProps: {
                className: 'button previous default',
                icon: {
                    type: 'chevron-left',
                    className: 'xsmall align-left'
                }
            },
            nextButtonEnabled: this.props.nextButtonEnabled,
            nextButtonProps: {
                loading: this.props.loading,
                className: 'button next primary',
                icon: {
                    type: 'chevron-right',
                    className: 'xsmall align-right'
                },
                onClick: this.props.onProceed
            }
        }

        return (
            <GenericLayout>
                <AfterSalesNavigation />
                <Row className='row contains-cancellation'>
                    <div className='grid-row'>
                        <div className='grid-column--1-1 grid-column--medium-1-3'>
                            <AfterSalesSummary booking={this.props.booking} />
                            {device.isDesktop() ? <Help /> : null}
                        </div>
                        <div className='grid-column--1-1 grid-column--medium-2-3'>
                            <div className='my-s3-cancellation-options'>
                                {this._renderCancelOptions()}
                            </div>
                            <ProgressNavigation {...progressNavigationProps} />
                            {!device.isDesktop() ? <Help /> : null}
                        </div>
                    </div>
                </Row>
                {this._renderConfirmDialog()}
            </GenericLayout>
        )
    }

    _renderCancelOptions () {
        const booking = this.props.booking

        return (
            <div className='panel'>
                <header>
                    <h1>{_t.message('my-s3-cancellation.individual-item.header')}</h1>
                </header>
                <div className='content'>
                    <div className='cancellation-option-information'>
                        <div className='cms-block'>
                            {booking.hasALSAProduct
                                ? (
                                    <CmsBlockAlsaPanelCard showLoader={false} />
                                ) : booking.hasNELProduct ? (
                                    <CmsBlockNelPanelCard showLoader={false} />
                                ) : (
                                    <CmsBlockPanelCard showLoader={false} />
                                )
                            }
                        </div>
                    </div>
                    <CancelReason
                        cancelReason={this.props.cancelReason}
                        onSelectCancelReason={this.props.onSelectCancelReason}
                    />
                    <CancelEntireBooking
                        booking={booking}
                        cancelItemIds={this.props.cancelItemIds}
                        includeFees={this.props.includeFees}
                        onAction={this.props.onEntireBookingAction}
                        onSelectCancellationOption={this.props.onSelectCancellationOption}
                        selectedCancellationOption={this.props.selectedCancellationOption}
                    />
                    {!booking.hasConfirmedStaticProducts
                        ? [
                            <CancelPerJourney
                                booking={booking}
                                cancelItemIds={this.props.cancelItemIds}
                                onAction={this.props.onJourneyAction}
                                disabled={this.props.disableCancelPerJourney}
                                onSelectCancellationOption={this.props.onSelectCancellationOption}
                                selectedCancellationOption={this.props.selectedCancellationOption}
                                key='cancelPerJourney'
                            />,
                            <CancelPerPassenger
                                booking={booking}
                                cancelItemIds={this.props.cancelItemIds}
                                onAction={this.props.onPassengerAction}
                                disabled={this.props.disableCancelPerPassenger}
                                onSelectCancellationOption={this.props.onSelectCancellationOption}
                                selectedCancellationOption={this.props.selectedCancellationOption}
                                key='cancelPerPassenger'
                            />
                        ]
                        : null}
                </div>
            </div>
        )
    }

    _renderConfirmDialog () {
        return this.props.showConfirmDialog ? (
            <ConfirmDialog
                booking={this.props.booking}
                cancelItemIds={this.props.cancelItemIds}
                onCancel={this.props.onCancel}
                onConfirm={this.props.onConfirm}
                loading={this.props.loading}
            />
        ) : null
    }
}

const cmsBlockNames = [
    'AFTERSALES-CANCEL-FLEX-HOME',
    'AFTERSALES-CANCEL-STATIC',
    'AFTERSALES-CANCEL-STATIC_2'
]
export default cmsComponent(...cmsBlockNames)(MyS3CancellationLayoutContainer)
