import PropTypes from 'prop-types'
import React from 'react'

export default notCloseClass => Component => {
    class CloseByClickOutside extends React.Component {
        constructor (...args) {
            super(...args)

            this.onCloseOutside = this.onCloseOutside.bind(this)
        }

        componentDidMount () {
            window.addEventListener('click', this.onCloseOutside, false)
        }

        componentWillUnmount () {
            window.removeEventListener('click', this.onCloseOutside, false)
        }

        onCloseOutside (event) {
            const hasClass = element =>
                typeof element.className === 'string' ? element.className.indexOf(notCloseClass) >= 0 : false

            let node = event.target
            let found = hasClass(node)
            while (!found) {
                node = node.parentNode
                found = node === null || node === document || hasClass(node)
            }

            if (node === document) {
                this.props.onHideElement()
            }
        }

        render () {
            return <Component {...this.props} />
        }
    };

    CloseByClickOutside.propTypes = {
        onHideElement: PropTypes.func.isRequired
    }

    return CloseByClickOutside
}
