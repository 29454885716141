import Reflux from 'reflux'
import actions from '../actions'
import FormValidationMixin from '../mixin/form-validation-mixin'
import UserAwareMixin from '../mixin/user-aware-mixin'

export default Reflux.createStore({

    listenables: actions,

    mixins: [FormValidationMixin, UserAwareMixin],

    init () {
        this._resetData()

        this.validators = {
            email: [this._validateEmail],
            password: []
        }
    },

    _resetData () {
        this.data = {
            isValid: false,
            errorText: null,
            fields: {
                email: {
                    value: null,
                    errorText: null,
                    isRequired: true
                },
                password: {
                    value: null,
                    errorText: null,
                    isRequired: true
                }
            }
        }
    },

    resetData () {
        this._resetData()
        this.trigger(this.data)
    },

    getInitialState () {
        return this.data
    },

    onProcessCrmLoginData (newData) {
        newData.forEach(record => {
            this.data.fields[record.id].value = record.value
        })

        this._validateData(newData)
    }
})
