import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from '../../translate'
import TextLabel from '../../element/text-label'

export default class BookingInformationFooter extends Component {
    static propTypes = {
        totalPrice: PropTypes.number.isRequired,
        totalVat: PropTypes.number.isRequired
    }

    render () {
        return (
            <tfoot>
                {this.props.children}
                <tr>
                    <td colSpan='2'>
                        <span className='text-label total-vat-included'>
                            <TextLabel text={_t.message('booking-information.total-vat')} />
                        </span>
                    </td>
                    <td>
                        <span className='text-label total-vat-price'>
                            <TextLabel text={_t.formatCurrency(this.props.totalVat)} />
                        </span>
                    </td>
                </tr>
                <tr>
                    <td colSpan='2'>
                        <span className='text-label total-price'>
                            <TextLabel text={_t.message('booking-information.total-price')} />
                        </span>
                    </td>
                    <td>
                        <span className='text-label total-price'>
                            <TextLabel text={_t.formatCurrency(this.props.totalPrice)} />
                        </span>
                    </td>
                </tr>
            </tfoot>
        )
    }
}
