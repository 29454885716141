// Libraries
import React from 'react'
import {Route} from 'react-router'

import authCheck from '../../mixins/auth'

// Layouts
import CancellationLayout from '../../layouts/my-s3-cancellation-layout'

export default (
    <Route path='/:lang/mys3/cancellation'
           component={CancellationLayout}
           onEnter={(nextState, replaceState, callback) => authCheck(nextState, replaceState, callback)}
        />
)
