import PropTypes from 'prop-types'
import React, { Component } from 'react'
import CmsContent from '../cms/cms-content'
import cmsContainer from '../cms/cms-container'

class Benefit extends Component {
    static propTypes = {
        content: PropTypes.string
    }

    render () {
        return this.props.content ? (
            <li>
                <CmsContent content={this.props.content} />
            </li>
        ) : null
    }
}

export default cmsContainer(Benefit)
