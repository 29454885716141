import PropTypes from 'prop-types'
import React, {Component} from 'react'
import Icon from './icon'

export default class IconPayment extends Component {
    static propTypes = {
        code: PropTypes.string.isRequired
    }

    iconMapping = {
        // payment flow types
        CREDITCARD: 'credit-card',
        CREDITCARD_ENCRYPTED: 'credit-card',
        INVOICE: 'invoice',
        IDEAL: 'ideal',
        PAYPAL: 'paypal',
        BANCONTACT: 'bancontact',

        // payment method codes
        cc: 'credit-card',
        inv: 'invoice',
        id: 'ideal',
        pp: 'paypal',
        bc: 'bancontact',
        voucher: 'voucher'
    }

    render () {
        const {code, ...props} = this.props
        const type = this.iconMapping[code] || 'information'
        return <Icon type={type} {...props} />
    }
}
