import React, {Component} from 'react'
import PropTypes from 'prop-types'
import actions from '../reflux/actions'
import _t from '../translate'
import {withRouter} from 'react-router'

import GenericLayout from './generic-layout'
import Row from '../element/row'
import Icon from '../element/icon'
import TextLabel from '../element/text-label'
import CrmLoginForm from '../components/crm-login/form'
import AccessManager from '../data/access-manager'

class CrmLoginLayout extends Component {
    static propTypes = {
        router: PropTypes.object
    }

    componentDidMount () {
        if (AccessManager.isAuthenticated() && !AccessManager.isPublicUser()) {
            actions.logout()
        }
    }

    onSuccess () {
        this.props.router.push(`/${_t.getLocales()}/mys3/bookings`)
    }

    render () {
        return (
            <GenericLayout className='crm-login'>
                <Row className='row'>
                    <div className='grid-row'>
                        <div className='grid-column--1-1 grid-column--small-1-2 grid-column--medium-1-3
                        center-grid-column--small'>
                            <div className='generic-login crm-login'>
                                <div className='panel'>
                                    <header>
                                        <span className='text-label heading'>
                                            <TextLabel text={_t.getIntlMessage('crm-login.header')} />
                                        </span>
                                    </header>
                                    <div className='content'>
                                        <span className='text-label information'>
                                            <Icon type='information' className='small align-left' />
                                            <TextLabel text={_t.getIntlMessage('crm-login.information')} />
                                        </span>
                                        <CrmLoginForm onSuccess={this.onSuccess} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Row>
            </GenericLayout>
        )
    }
}

export default withRouter(CrmLoginLayout)
