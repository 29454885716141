import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from '../../../../translate'
import ModalDialog from '../../../../element/modal-dialog'
import Button from '../../../../element/button'
import cmsComponent from '../../../../components/cms/cms-component'
import CmsBlockContent from '../../../../components/cms/cms-block-content'
import cmsContainer from '../../../cms/cms-container'
class BaseSupportDialog extends Component {
    static propTypes = {
        title: PropTypes.string,
        closeDialog: PropTypes.func.isRequired
    }
    render () {
        const labelPrefix = 'additional-product-selector.wheelchair-support-dialog'
        return (
            <ModalDialog headerTitle={this.props.title || ''} onClose={this.props.closeDialog}>
                <div className='content'>
                    <CmsBlockContent name='wheelchair-support-dialog' />
                    <div className='actions'>
                        <ul className='horizontal-list'>
                            <li>
                                <Button
                                    type='button'
                                    className='button default'
                                    text={_t.getIntlMessage(`${labelPrefix}.confirm`)}
                                    icon={{type: 'cross', className: 'small align-left'}}
                                    onClick={this.props.closeDialog}
                                />
                            </li>
                        </ul>
                    </div>
                </div>
            </ModalDialog>
        )
    }
}
const CmsSupportDialog = cmsContainer(BaseSupportDialog)
export default cmsComponent('wheelchair-support-dialog')(class SupportDialog extends Component {
    render () {
        return <CmsSupportDialog name='wheelchair-support-dialog' {...this.props} />
    }
})
