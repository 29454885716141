import React, {Component} from 'react'
import _t from '../../../translate'
import TextLabel from '../../../element/text-label'
import BookingModel from '../../../models/booking-model'
import AddVoucherForm from './manage-vouchers/add-voucher-form'
import ManageVoucherPanel from './manage-vouchers/manage-voucher-panel'
import {containVouchers} from '../../../base/vouchers'
import Accordion from '../../../element/accordion'
import AccordionPanel from '../../../element/accordion/panel'
import AccordionPanelHeader from '../../../element/accordion/panel/header'
import AccordionPanelContent from '../../../element/accordion/panel/content'
import Icon from '../../../element/icon'
import PropTypes from 'prop-types'

class ManageVouchers extends Component {
    static propTypes = {
        voucherAction: PropTypes.string,
        onAddVoucher: PropTypes.func.isRequired,
        onRemoveVoucher: PropTypes.func.isRequired,
        removedVoucherCode: PropTypes.string,
        addedVoucherCode: PropTypes.string,
        booking: PropTypes.instanceOf(BookingModel).isRequired,
        messages: PropTypes.array.isRequired,
        disabled: PropTypes.bool,
        loading: PropTypes.bool
    }

    constructor (...args) {
        super(...args)
        this.state = {expanded: Boolean(this.props.addedVoucherCode)}
        this._renderVoucher = this._renderVoucher.bind(this)
        this.onExpand = this.onExpand.bind(this)
    }

    componentWillReceiveProps (nextProps) {
        if (nextProps.booking.pendingVouchers.some(voucher => voucher.code === nextProps.addedVoucherCode)) {
            this.setState({expanded: false})
        }
    }

    render () {
        return (
            <div className='journey-specification manage-vouchers'>
                <Accordion>
                    <AccordionPanel name='vouchers'>
                        <AccordionPanelHeader showCollapsed>
                            <span className='text-label expand-details'>
                                <TextLabel text={_t.getIntlMessage('booking-specification.manage-vouchers.title')} />
                                <Icon className='chevron-down small align-right' type='chevron-down' />
                            </span>
                        </AccordionPanelHeader>
                        <AccordionPanelHeader showExpanded>
                            <span className='text-label collapse-details'>
                                <TextLabel text={_t.getIntlMessage('booking-specification.manage-vouchers.title')} />
                                <Icon className='chevron-up small align-right' type='chevron-up' />
                            </span>
                        </AccordionPanelHeader>
                        <AccordionPanelContent>
                            {this.props.booking.pendingVouchers.map(this._renderVoucher)}
                            <AddVoucherForm
                                onAddVoucher={this.props.onAddVoucher}
                                messages={this.props.messages}
                                loading={this.props.loading}
                                voucherAction={this.props.voucherAction}
                                addedVoucherCode={this.props.addedVoucherCode}
                            />
                        </AccordionPanelContent>
                    </AccordionPanel>
                </Accordion>
            </div>

        )
    }

    _renderVoucher (voucher, index) {
        const {onRemoveVoucher, loading, voucherAction, removedVoucherCode} = this.props

        return (
            <ManageVoucherPanel
                key={`voucher_${index}_${voucher.code}`}
                voucher={voucher}
                onRemoveVoucher={() => onRemoveVoucher(voucher.code)}
                loading={loading && voucherAction === 'remove' && removedVoucherCode === voucher.code}
            />
        )
    }

    onExpand (event) {
        event.preventDefault()
        !this.state.expanded && this.setState({expanded: true})
        return false
    }
}

export default containVouchers(ManageVouchers)
