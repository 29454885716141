import Reflux from 'reflux'
import UserAwareMixin from '../../reflux/mixin/user-aware-mixin'
import actions from '../../reflux/actions'

const RefundVoucherStore = Reflux.createStore({

    listenables: actions,

    mixins: [UserAwareMixin],

    init () {
        this._resetData()
    },

    _resetData () {
        this.data = {
            isLoadingRequestVoucher: false,
            shouldPrint: false,
            refundVoucher: null
        }
    },

    resetData () {
        this._resetData()
        this.trigger(this.data)
    },

    getInitialState () {
        return this.data
    },

    onRequestRefundVoucher (_, shouldPrint = false) {
        this.data.shouldPrint = shouldPrint
        this.data.isLoadingRequestVoucher = true
        this.data.refundVoucher = null
        this.trigger(this.data)
    },

    onRequestRefundVoucherCompleted (response) {
        this.data.isLoadingRequestVoucher = false
        this.data.refundVoucher = response.data.voucher
        this.trigger(this.data)
    },

    onClearRefundVoucher () {
        this.resetData()
    }
})

export default RefundVoucherStore
