import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from '../../../../translate'
import ModalDialog from '../../../../element/modal-dialog'
import Button from '../../../../element/button'

export default class ConfirmationDialog extends Component {
    static propTypes = {
        onCancel: PropTypes.func.isRequired,
        onConfirm: PropTypes.func.isRequired,
        passengerCount: PropTypes.number.isRequired
    }

    render () {
        const labelPrefix = 'additional-product-selector.wheelchair-confirmation-dialog'

        return (
            <ModalDialog headerTitle={_t.getIntlMessage(`${labelPrefix}.title`)} onClose={this.props.onCancel}>
                <div className='content'>
                    <p>{_t.formatIntlMessage(`${labelPrefix}.body`, {passengerCount: this.props.passengerCount})}</p>
                    <div className='actions'>
                        <ul className='horizontal-list'>
                            <li>
                                <Button
                                    type='button'
                                    className='button default'
                                    text={_t.getIntlMessage(`${labelPrefix}.cancel`)}
                                    onClick={this.props.onCancel}
                                />
                            </li>
                            <li>
                                <Button
                                    type='button'
                                    className='button primary'
                                    text={_t.getIntlMessage(`${labelPrefix}.confirm`)}
                                    onClick={this.props.onConfirm}
                                />
                            </li>
                        </ul>
                    </div>
                </div>
            </ModalDialog>
        )
    }
}
