import Reflux from 'reflux'
import {withRouter, browserHistory} from 'react-router'
import _ from 'lodash'
import uniq from 'lodash/uniq'
import _t from '../translate'
import moment from 'moment-timezone'
import device from '../device'
import storage from '../storage'
import AffiliateStore from '../reflux/stores/affiliate-store'
import BookingStore from '../reflux/stores/booking-store'
import OfferStore from '../reflux/stores/offer-store'
import CurrencyStore from '../reflux/stores/currency-store'
import CustomerInfoStore from '../reflux/stores/customer-info-store'
import CrmUserStore from '../reflux/stores/crm-user-store'
import actions from '../reflux/actions'
import AccessManager from '../data/access-manager'
import TagCommanderMapper from '../models/tag-commander-mapper'
import TaggingStore from '../reflux/stores/tagging-store'
import {isReturnJourney} from '../models/selectors/api/v2/orientation/journey-search'
import {passengersSelector} from '../models/selectors/api/v2/orientation/passenger'
import {
    inboundTravelSelector,
    outboundTravelSelector
} from '../models/selectors/api/v2/orientation/travel'
import {journeySearchFromJourneyResultSelector} from '../models/selectors/api/v2/orientation/journey-result'
import {routesSelector} from '../models/selectors/components/orientation/journey-search'
import {getState} from '../reflux/bridge/connect-state'
import {
    NEWSLETTER_OPTIN,
    PASSENGER_TYPE_ADULT,
    PASSENGER_TYPE_BABY,
    PASSENGER_TYPE_CHILD,
    PASSENGER_TYPE_YOUTH,
    PASSENGER_TYPE_YOUTH_WITH_DISCOUNT
} from '../constants'
import groupBy from 'lodash/groupBy'
import {alpha3ToAlpha2} from 'i18n-iso-countries'
import createReactClass from 'create-react-class'

let currentTags = {}

export const getCurrentTags = copy => {
    return copy ? _.clone(currentTags) : currentTags
}

export default withRouter(createReactClass({

    displayName: 'TagPusherCollector',

    render () {
        return null
    },

    _removeHistoryListener: () => true,

    mixins: [
        Reflux.listenTo(BookingStore, 'onUpdateBooking'),
        Reflux.listenTo(CurrencyStore, 'onUpdateCurrency'),
        Reflux.listenTo(OfferStore, 'onUpdateOffer'),
        Reflux.listenTo(CustomerInfoStore, 'onUpdateCustomerInfo'),
        Reflux.listenTo(CrmUserStore, 'onUpdateCrmUser'),
        Reflux.listenTo(actions.initiatePayment, 'onInitiatePayment'),
        Reflux.listenTo(TaggingStore, 'onUpdateTag')
    ],

    componentDidMount () {
        this._removeHistoryListener = browserHistory.listen(() => {
            this.onUpdateHistory(this.props)
        })

        this.initializeCurrentTags()
    },

    componentWillUnmount () {
        this._removeHistoryListener()
    },

    initializeCurrentTags () {
        this.onUpdateHistory(window)
        this.onUpdateBooking(BookingStore.data)
        this.onUpdateOffer(OfferStore.data)
        this.onUpdateCurrency({currency: _t.getCurrency()})
        this.onUpdateTag(TaggingStore.getInitialState())
    },

    onUpdateCurrency (data) {
        if (data.loading) {
            return false
        }

        currentTags.basket_currency = currentTags.search_currency = data.currency
    },

    onInitiatePayment () {
        const booking = BookingStore.getInitialState().booking
        storage.set('lastPaymentAmount', booking.total_price_to_be_paid)
    },

    onUpdateHistory (data) {
        const location = data.location || window.location

        const [lan, cnt] = _t.getLocales().split('-')

        this.onUpdateCrmUser()

        currentTags.site_language = lan
        currentTags.env_country = cnt
        currentTags.env_dnt = 'disabled'
        currentTags.env_template = 'Ticket booking'
        currentTags.page_error = '200'
        currentTags.platform = device.isDesktopOrTablet() ? 'Desktop' : 'Mobile'
        currentTags.env_org_code = AffiliateStore.getAffiliateCode() || ''
        currentTags.env_sales_channel = AccessManager.getSalesChannel() || ''

        currentTags.page_name = this._getPageName(location) === 'offer' && !_.isEmpty(OfferStore.getOfferData())
            ? location.pathname.replace('/offer', '/schedule-select') : location.pathname

        currentTags.trans_apv = location.pathname.indexOf('mys3') > 0 ? 1 : 0
    },

    onUpdateCrmUser () {
        currentTags.user_logged = Boolean(AccessManager.isCrmUser())
    },

    onUpdateTag (data) {
        currentTags = {...currentTags, ...data}
    },

    _getPageName (location) {
        return location ? location.pathname.substr(location.pathname.lastIndexOf('/') + 1) : ''
    },

    _getServiceTypes (tariffSegments) {
        return ((tariffSegments && tariffSegments.journeySegments) || []).reduce((codes, journeySegment) => {
            if (journeySegment.service_type && journeySegment.service_type.code) {
                codes.push(journeySegment.service_type.code)
            }

            return codes
        }, [])
    },

    _getServiceNames (tariffSegments) {
        return ((tariffSegments && tariffSegments.journeySegments) || []).reduce((names, journeySegment) => {
            if (journeySegment.service_type && journeySegment.service_type.name) {
                names.push(journeySegment.service_type.name)
            }

            return names
        }, [])
    },

    onUpdateBooking (data) {
        if (data.loading) {
            return false
        }

        const booking = (data.booking && BookingStore.getBookingModel()) || null
        const outboundTariffSegments = booking ? booking.outboundSegmentCollection.withoutCancelled() : null
        const inboundTariffSegments = booking ? booking.inboundSegmentCollection.withoutCancelled() : null
        const tariffSegments = booking ? booking.tariffSegmentCollection.withoutCancelled() : null

        const bookingDate = moment().startOf('day')
        const outboundDepartureDateTime = outboundTariffSegments && outboundTariffSegments.departureDateTime
            ? moment(outboundTariffSegments.departureDateTime) : null
        const outboundArrivalDateTime = outboundTariffSegments && outboundTariffSegments.arrivalDateTime
            ? moment(outboundTariffSegments.arrivalDateTime) : null
        const inboundDepartureDateTime = inboundTariffSegments && inboundTariffSegments.departureDateTime
            ? moment(inboundTariffSegments.departureDateTime) : null
        const inboundArrivalDateTime = inboundTariffSegments && inboundTariffSegments.arrivalDateTime
            ? moment(inboundTariffSegments.arrivalDateTime) : null

        const maxTransfers = TagCommanderMapper.SegmentCollection.getMaxTransferCount(
            booking ? booking.isReturnTrip() : false,
            tariffSegments ? tariffSegments.getRawData() : null
        )
        if (maxTransfers === 0) {
            currentTags.trans_route_type = 'direct'
        } else if (maxTransfers === 1) {
            currentTags.trans_route_type = 'changeover_1'
        } else if (maxTransfers > 1) {
            currentTags.trans_route_type = 'changeover_2'
        }
        currentTags.search_route_type = currentTags.trans_route_type
        currentTags.basket_route_type = currentTags.trans_route_type
        currentTags.env_sales_channel = booking ? booking.salesChannelCode : currentTags.env_sales_channel

        currentTags.trans_passenger = booking ? booking.passengers.size() : 0
        currentTags.trans_booking_confirmed = booking && booking.isConfirmed

        let passengerCount = {}
        if (booking && booking.passengers) {
            currentTags.trans_total_passengers = booking.passengers.value().length
            passengerCount = booking.passengers.reduce((count, passenger) => {
                count[passenger.passengerType] = (count[passenger.passengerType] || 0) + 1

                return count
            }, {})
        }
        currentTags.trans_adult_passengers = passengerCount[PASSENGER_TYPE_ADULT] || 0
        currentTags.trans_child_passengers = passengerCount[PASSENGER_TYPE_CHILD] || 0
        currentTags.trans_baby_passengers = passengerCount[PASSENGER_TYPE_BABY] || 0
        currentTags.trans_young_passengers = passengerCount[PASSENGER_TYPE_YOUTH] || 0
        currentTags.trans_young_with_card_passengers = passengerCount[PASSENGER_TYPE_YOUTH_WITH_DISCOUNT] || 0

        currentTags.trans_booking_number = booking && booking.bookingNumber
        currentTags.trans_booking_date = bookingDate ? bookingDate.format('DD/MM/YYYY') : ''
        currentTags.trans_change_seat = tariffSegments &&
        TagCommanderMapper.SegmentCollection.canChangeSeatOnAnyRequiredProduct(tariffSegments) ? 'y' : 'n'
        currentTags.trans_products = booking && booking.products.value()
        currentTags.trans_outbound_products = booking && booking.outboundSegmentCollection && booking.outboundSegmentCollection.products
        currentTags.trans_inbound_products = booking && booking.inboundSegmentCollection && booking.inboundSegmentCollection.products
        currentTags.trans_is_return_journey = booking && booking.isReturnTrip()
        currentTags.trans_service_name = outboundTariffSegments && outboundTariffSegments.journeySegments.length > 0 ? outboundTariffSegments.journeySegments[0].service_name : ''
        currentTags.trans_days_to_departure = outboundDepartureDateTime ? outboundDepartureDateTime.diff(bookingDate, 'days') : ''
        currentTags.trans_departure_date = outboundDepartureDateTime ? outboundDepartureDateTime.format('DD/MM/YYYY') : ''
        currentTags.trans_return_date = inboundArrivalDateTime ? inboundArrivalDateTime.format('DD/MM/YYYY') : ''
        currentTags.travel_startdate = outboundDepartureDateTime ? outboundDepartureDateTime.format('YYYY-MM-DD') : ''
        currentTags.travel_enddate = inboundArrivalDateTime ? inboundArrivalDateTime.format('YYYY-MM-DD') : currentTags.travel_startdate
        currentTags.trans_journey_length = outboundDepartureDateTime && inboundArrivalDateTime ? inboundArrivalDateTime.diff(outboundDepartureDateTime, 'days') : ''
        currentTags.trans_outbound_departure_time = outboundDepartureDateTime ? outboundDepartureDateTime.format('HH:mm') : ''
        currentTags.trans_outbound_arrival_time = outboundArrivalDateTime ? outboundArrivalDateTime.format('HH:mm') : ''
        currentTags.trans_inbound_departure_time = inboundDepartureDateTime ? inboundDepartureDateTime.format('HH:mm') : ''
        currentTags.trans_inbound_arrival_time = inboundArrivalDateTime ? inboundArrivalDateTime.format('HH:mm') : ''

        currentTags.trans_outgoing_adult_ticket_price_with_VAT = booking
            ? this._getAverageProductPrice(booking.getOutboundRequiredProductsByPassengerType(PASSENGER_TYPE_ADULT)) : 0
        currentTags.trans_outgoing_child_ticket_price_with_VAT = booking
            ? this._getAverageProductPrice(booking.getOutboundRequiredProductsByPassengerType(PASSENGER_TYPE_CHILD)) : 0
        currentTags.trans_outgoing_baby_ticket_price_with_VAT = booking
            ? this._getAverageProductPrice(booking.getOutboundRequiredProductsByPassengerType(PASSENGER_TYPE_BABY)) : 0
        currentTags.trans_outgoing_young_ticket_price_with_VAT = booking
            ? this._getAverageProductPrice(booking.getOutboundRequiredProductsByPassengerType(PASSENGER_TYPE_YOUTH)) : 0
        currentTags.trans_outgoing_young_with_card_ticket_price_with_VAT = booking
            ? this._getAverageProductPrice(booking.getOutboundRequiredProductsByPassengerType(PASSENGER_TYPE_YOUTH_WITH_DISCOUNT))
            : 0
        currentTags.trans_return_adult_ticket_price_with_VAT = booking
            ? this._getAverageProductPrice(booking.getInboundRequiredProductsByPassengerType(PASSENGER_TYPE_ADULT)) : 0
        currentTags.trans_return_child_ticket_price_with_VAT = booking
            ? this._getAverageProductPrice(booking.getInboundRequiredProductsByPassengerType(PASSENGER_TYPE_CHILD)) : 0
        currentTags.trans_return_baby_ticket_price_with_VAT = booking
            ? this._getAverageProductPrice(booking.getInboundRequiredProductsByPassengerType(PASSENGER_TYPE_BABY)) : 0
        currentTags.trans_return_young_ticket_price_with_VAT = booking
            ? this._getAverageProductPrice(booking.getInboundRequiredProductsByPassengerType(PASSENGER_TYPE_YOUTH)) : 0
        currentTags.trans_return_young_with_card_ticket_price_with_VAT = booking
            ? this._getAverageProductPrice(booking.getInboundRequiredProductsByPassengerType(PASSENGER_TYPE_YOUTH_WITH_DISCOUNT))
            : 0

        currentTags.trans_payment_type = booking
            ? _(booking.payments).reject(payment => (payment.method && payment.method.toLowerCase()) === 'voucher')
                .map('method').map(this.getCodeForPaymentMethod).value().join(',')
            : ''

        if (booking && booking.lastAftersalesOperation) {
            currentTags.trans_apv_action_date = moment().startOf('day').format('DD/MM/YYYY')

            if (booking.lastAftersalesOperation === 'AFTERSALES_REBOOK') {
                const calcPrice = this._getAverageProductRebookPrice

                const voucherRefundAmount = booking.pendingVouchers
                    .filter(v => v.type === 'REFUND')
                    .reduce((sum, v) => (sum += v.amount), 0)

                const apvTotalWithVat = Math.max(
                    booking.amountAddedTickets - booking.amountReplacedTickets - voucherRefundAmount, 0
                )

                currentTags.trans_apv_action = 'exchange'
                currentTags.trans_apv_total_with_VAT = apvTotalWithVat
                currentTags.trans_apv_outgoing_adult_ticket_price_with_VAT = apvTotalWithVat > 0
                    ? calcPrice(booking, 'outbound', PASSENGER_TYPE_ADULT) : 0
                currentTags.trans_apv_outgoing_child_ticket_price_with_VAT = apvTotalWithVat > 0
                    ? calcPrice(booking, 'outbound', PASSENGER_TYPE_CHILD) : 0
                currentTags.trans_apv_outgoing_baby_ticket_price_with_VAT = apvTotalWithVat > 0
                    ? calcPrice(booking, 'outbound', PASSENGER_TYPE_BABY) : 0
                currentTags.trans_apv_return_adult_ticket_price_with_VAT = apvTotalWithVat > 0
                    ? calcPrice(booking, 'inbound', PASSENGER_TYPE_ADULT) : 0
                currentTags.trans_apv_return_child_ticket_price_with_VAT = apvTotalWithVat > 0
                    ? calcPrice(booking, 'inbound', PASSENGER_TYPE_CHILD) : 0
                currentTags.trans_apv_return_baby_ticket_price_with_VAT = apvTotalWithVat > 0
                    ? calcPrice(booking, 'inbound', PASSENGER_TYPE_BABY) : 0
            } else if (booking.lastAftersalesOperation === 'AFTERSALES_CANCEL') {
                currentTags.trans_apv_action = 'cancellation'
            }

            currentTags.trans_total_VAT = 0
            currentTags.trans_total_with_VAT = storage.get('lastPaymentAmount', 0)
            currentTags.trans_total_without_VAT = 0
        } else {
            currentTags.trans_total_VAT = booking ? booking.totalVat : 0
            currentTags.trans_total_with_VAT = booking ? booking.totalPriceThatWasPayed : 0
            currentTags.trans_total_without_VAT = booking ? booking.totalPrice - booking.totalVat : 0
        }

        currentTags.trans_total_VAT_EUR = booking ? booking.totalVatUnconverted : 0
        currentTags.trans_total_with_VAT_EUR = booking ? booking.totalPriceUnconverted : 0
        currentTags.trans_total_without_VAT_EUR = booking
            ? booking.totalPriceUnconverted - booking.totalVatUnconverted : 0

        currentTags.trans_ticket_type = inboundArrivalDateTime ? 'Return' : 'Single'
        currentTags.trans_promotion_code = ''
        currentTags.trans_voucher_id = booking && !_.isEmpty(booking.vouchers)
            ? _.map(booking.vouchers, 'code').join(',') : ''
        currentTags.trans_voucher_type = booking && !_.isEmpty(booking.vouchers)
            ? _.map(booking.vouchers, 'type').join(',') : ''

        if (booking && booking.isUpdated) {
            currentTags.trans_id_apv =
                `APV_${booking.bookingNumber}_${moment().format('YYYYMMDDHHmmss')}`
        }

        currentTags.trans_id = currentTags.trans_id_apv
            ? currentTags.trans_id_apv
            : booking ? booking.bookingNumber : ''
        currentTags.trans_currency = booking ? booking.currency : currentTags.trans_currency
        currentTags.trans_departure_station = booking ? _.get(booking.outboundOrigin, 'short_code', '') : ''
        currentTags.trans_departure_station_full = booking ? _.get(booking.outboundOrigin, 'name', '') : ''
        currentTags.trans_departure_city = booking ? _.get(booking.departureStationInfo, 'city', '') : ''
        currentTags.trans_departure_country_code = booking ? alpha3ToAlpha2(_.get(booking.departureStationInfo, 'country_code', '')) : ''

        currentTags.trans_return_station = booking ? _.get(booking.outboundDestination, 'short_code', '') : ''
        currentTags.trans_return_station_full = booking ? _.get(booking.outboundDestination, 'name', '') : ''
        currentTags.trans_return_city = booking ? _.get(booking.arrivalStationInfo, 'city', '') : ''
        currentTags.trans_return_country_code = booking ? alpha3ToAlpha2(_.get(booking.arrivalStationInfo, 'country_code', '')) : ''

        currentTags.trans_nb_seats = booking ? booking.additionalProducts.getProductsForCode('SEATSELECTION').quantity : 0
        currentTags.trans_seats_total_vat = booking ? booking.additionalProducts.getProductsForCode('SEATSELECTION').totalPrice : 0

        const optInEnabled = optInMethod => booking && booking.additionalDetails.some(
            additionalDetail => additionalDetail.key === optInMethod && additionalDetail.value === '1'
        )

        currentTags.trans_optin_email = optInEnabled(NEWSLETTER_OPTIN) ? '1' : '0'
        currentTags.trans_optin_sms = '0'
        currentTags.trans_optin_geo = (
            booking && booking.customer && booking.customer.address &&
            booking.customer.address.postal_code && booking.customer.address.postal_code.length
        ) ? '1' : '0'

        currentTags.basket_nb_seats = booking ? booking.additionalProducts.getProductsForCode('SEATSELECTION').quantity : 0
        currentTags.basket_seat_option = currentTags.basket_seat_option || 'no'
        currentTags.basket_total_with_VAT = currentTags.trans_total_with_VAT
        currentTags.basket_total_without_VAT = currentTags.trans_total_without_VAT
        currentTags.basket_total_with_VAT_EUR = currentTags.trans_total_with_VAT_EUR
        currentTags.basket_total_without_VAT_EUR = currentTags.trans_total_without_VAT_EUR
        currentTags.basket_journey_length = currentTags.trans_journey_length
        currentTags.basket_passenger = currentTags.trans_passenger
        currentTags.basket_adult_passengers = currentTags.trans_adult_passengers
        currentTags.basket_kid_passengers = currentTags.trans_child_passengers
        currentTags.basket_baby_passengers = currentTags.trans_baby_passengers
        currentTags.basket_young_passengers = currentTags.trans_young_passengers
        currentTags.basket_young_with_card_passengers = currentTags.trans_young_with_card_passengers
        currentTags.basket_booking_date = currentTags.trans_booking_date
        currentTags.basket_change_seat = currentTags.trans_change_seat
        currentTags.basket_days_to_departure = currentTags.trans_days_to_departure
        currentTags.basket_return_date = currentTags.trans_return_date
        currentTags.basket_ticket_type = currentTags.trans_ticket_type
        currentTags.basket_departure_date = currentTags.trans_departure_date
        currentTags.basket_outbound_departure_time = currentTags.trans_outbound_departure_time
        currentTags.basket_outbound_arrival_time = currentTags.trans_outbound_arrival_time
        currentTags.basket_inbound_departure_time = currentTags.trans_inbound_departure_time
        currentTags.basket_inbound_arrival_time = currentTags.trans_inbound_arrival_time
        currentTags.basket_departure_station = currentTags.trans_departure_station
        currentTags.basket_departure_station_full = currentTags.trans_departure_station_full
        currentTags.basket_return_station = currentTags.trans_return_station
        currentTags.basket_return_station_full = currentTags.trans_return_station_full
        currentTags.basket_route_type = currentTags.search_route_type
        currentTags.basket_outgoing_adult_ticket_price_with_VAT = currentTags.trans_outgoing_adult_ticket_price_with_VAT
        currentTags.basket_outgoing_child_ticket_price_with_VAT = currentTags.trans_outgoing_child_ticket_price_with_VAT
        currentTags.basket_outgoing_baby_ticket_price_with_VAT = currentTags.trans_outgoing_baby_ticket_price_with_VAT
        currentTags.basket_outgoing_young_ticket_price_with_VAT = currentTags.trans_outgoing_young_ticket_price_with_VAT
        currentTags.basket_outgoing_young_with_card_ticket_price_with_VAT = currentTags.trans_outgoing_young_with_card_ticket_price_with_VAT
        currentTags.basket_return_adult_ticket_price_with_VAT = currentTags.trans_return_adult_ticket_price_with_VAT
        currentTags.basket_return_child_ticket_price_with_VAT = currentTags.trans_return_child_ticket_price_with_VAT
        currentTags.basket_return_baby_ticket_price_with_VAT = currentTags.trans_return_baby_ticket_price_with_VAT
        currentTags.basket_return_young_ticket_price_with_VAT = currentTags.trans_return_young_ticket_price_with_VAT
        currentTags.basket_return_young_with_card_ticket_price_with_VAT = currentTags.trans_return_young_with_card_ticket_price_with_VAT
        currentTags.basket_optin_email = currentTags.trans_optin_email
        currentTags.basket_optin_sms = currentTags.trans_optin_sms
        currentTags.basket_optin_geo = currentTags.trans_optin_geo

        currentTags.trans_service_type = this._getServiceTypes(tariffSegments).join('/')
        currentTags.trans_apv_service_type = currentTags.trans_service_type
        currentTags.basket_service_type = currentTags.trans_service_type

        currentTags.trans_outbound_service_type = this._getServiceTypes(outboundTariffSegments).join('+')
        currentTags.trans_inbound_service_type = this._getServiceTypes(inboundTariffSegments).join('+')
        currentTags.trans_outbound_service_name = uniq(this._getServiceNames(outboundTariffSegments)).join('+')
        currentTags.trans_inbound_service_name = uniq(this._getServiceNames(inboundTariffSegments)).join('+')
        currentTags.basket_outbound_service_type = currentTags.trans_outbound_service_type
        currentTags.basket_inbound_service_type = currentTags.trans_inbound_service_type
        currentTags.basket_outbound_service_name = currentTags.trans_outbound_service_name
        currentTags.basket_inbound_service_name = currentTags.trans_inbound_service_name

        currentTags.departure_country = outboundTariffSegments && outboundTariffSegments.length && outboundTariffSegments.departureStation
            ? outboundTariffSegments.departureStation.country_code
            : ''
        currentTags.arrival_country = outboundTariffSegments && outboundTariffSegments.length && outboundTariffSegments.arrivalStation
            ? outboundTariffSegments.arrivalStation.country_code : ''

        const genders = {M: 'Male', F: 'Female', '': ''}
        currentTags.order_passenger = booking ? booking.passengers.value().map(passenger => ({
            age_range: this._getAgeRange(_.get(passenger, 'birth_date', '')),
            country: '',
            gender: genders[_.get(passenger, 'gender', '')]
        })) : null

        delete currentTags.basket_price_class_outbound
        delete currentTags.trans_price_class_outbound
        delete currentTags.basket_price_class_inbound
        delete currentTags.trans_price_class_inbound

        if (outboundTariffSegments) {
            currentTags.basket_price_class_outbound = outboundTariffSegments.hasPromoTariff()
                ? 'promo' : 'standard'
            currentTags.trans_price_class_outbound = currentTags.basket_price_class_outbound
        }

        if (booking && booking.isReturnTrip() && inboundTariffSegments) {
            currentTags.basket_price_class_inbound = inboundTariffSegments.hasPromoTariff()
                ? 'promo' : 'standard'
            currentTags.trans_price_class_inbound = currentTags.basket_price_class_inbound
        }

        actions.updateGTMTags(currentTags)
    },

    _findLowestPrice (adultPassengersIds, routes) {
        return routes.reduce((carrier, route) => {
            if (route.isCheapestRoute) {
                const cheapestBundle = route.bundles.find(bundle => bundle.isCheapestTravelBundle)

                if (cheapestBundle) {
                    carrier.lowestPrice = cheapestBundle.price
                    carrier.lowestPricePP = cheapestBundle.items
                        .find(item => item.isMainProduct)
                        .passengerFares
                        .reduce((total, fare) =>
                            adultPassengersIds.includes(fare.passengerId) ? total + fare.price : total,
                        0.0
                        ) / (adultPassengersIds.length || 1)
                }
            }

            return carrier
        }, {
            lowestPrice: undefined,
            lowestPricePP: undefined
        })
    },

    _productTypeCount (routes) {
        return routes.reduce((productTypeCounts, route) => {
            if (route.bundles.some(bundle => bundle.isPromo)) {
                productTypeCounts.promo++
            } else if (route.bundles.length) {
                productTypeCounts.standard++
            }
            return productTypeCounts
        }, {promo: 0, standard: 0})
    },

    onUpdateOffer (data) {
        if (data.loading) {
            return false
        }

        const state = getState()
        const journeySearch = journeySearchFromJourneyResultSelector(state)
        const outboundTravel = outboundTravelSelector(state)
        const inboundTravel = inboundTravelSelector(state)

        currentTags.search_availability = !journeySearch ? 'no' : 'yes'
        if (!journeySearch || !outboundTravel) {
            return false
        }

        if (this._getPageName(window.location) === 'offer') {
            currentTags.page_name = window.location.pathname.replace('/offer', '/schedule-select/reload_search')
        }

        const outboundDate = journeySearch.outboundDate
        const inboundDate = journeySearch.inboundDate
        const bookingDate = moment()

        const passengers = passengersSelector(state)

        const passengersByType = groupBy(passengers, 'type')

        if (outboundTravel.originStation) {
            currentTags.departure_country = outboundTravel.originStation.countryCode
            currentTags.search_departure = outboundTravel.originStation.shortCode
            currentTags.search_departure_full = outboundTravel.originStation.name
        }

        if (outboundTravel.destinationStation) {
            currentTags.arrival_country = outboundTravel.destinationStation.countryCode
            currentTags.search_destination = outboundTravel.destinationStation.shortCode
            currentTags.search_destination_full = outboundTravel.destinationStation.name
        }

        currentTags.basket_adult_passengers = (passengersByType[PASSENGER_TYPE_ADULT] || []).length
        currentTags.basket_kid_passengers = (passengersByType[PASSENGER_TYPE_CHILD] || []).length
        currentTags.basket_baby_passengers = (passengersByType[PASSENGER_TYPE_BABY] || []).length

        currentTags.search_availability = 'yes'
        currentTags.search_departure_date = outboundDate ? outboundDate.format('DD/MM/YYYY') : ''
        currentTags.search_return_date = inboundDate ? inboundDate.format('DD/MM/YYYY') : ''
        currentTags.search_days_to_departure = outboundDate ? outboundDate.diff(bookingDate, 'days') : ''
        currentTags.search_journey_length = outboundDate && inboundDate ? inboundDate.diff(outboundDate, 'days') : ''

        currentTags.search_passenger = passengers.length
        currentTags.search_adult_passengers = currentTags.basket_adult_passengers
        currentTags.search_child_passengers = currentTags.basket_kid_passengers
        currentTags.search_baby_passengers = currentTags.basket_baby_passengers
        currentTags.search_ticket_type = inboundDate ? 'Return' : 'Single'

        const outboundRoutes = routesSelector(outboundTravel.id)(state)
        const inboundRoutes = (inboundTravel && routesSelector(inboundTravel.id)(state)) || []

        const adultPassengersIds = passengers
            .filter(passenger => passenger.type === PASSENGER_TYPE_ADULT)
            .map(passenger => passenger.id)
        const outboundLowestPrices = this._findLowestPrice(adultPassengersIds, outboundRoutes)
        const inboundLowestPrices = this._findLowestPrice(adultPassengersIds, inboundRoutes)

        currentTags.search_outgoing_lowestprice = outboundLowestPrices.lowestPrice || 0
        currentTags.search_outgoing_lowestprice_pp = outboundLowestPrices.lowestPricePP || 0
        currentTags.search_return_lowestprice = inboundLowestPrices.lowestPrice || 0
        currentTags.search_return_lowestprice_pp = inboundLowestPrices.lowestPricePP || 0

        const withTransfers = routes => routes.filter(route => route.transfers.length > 0)
        currentTags.nb_res_out_connection = withTransfers(outboundRoutes).length
        currentTags.nb_res_out_direct = outboundRoutes.length - currentTags.nb_res_out_connection
        currentTags.nb_res_in_connection = withTransfers(inboundRoutes).length
        currentTags.nb_res_in_direct = inboundRoutes.length - currentTags.nb_res_in_connection

        const productTypeCounts = this._productTypeCount(outboundRoutes)
        currentTags.search_nb_standard_price_out = productTypeCounts.standard
        currentTags.search_nb_promo_price_out = productTypeCounts.promo

        currentTags.search_nb_standard_price_in = 0
        currentTags.search_nb_promo_price_in = 0
        if (isReturnJourney(state)) {
            const productTypeCounts = this._productTypeCount(inboundRoutes)
            currentTags.search_nb_standard_price_in = productTypeCounts.standard
            currentTags.search_nb_promo_price_in = productTypeCounts.promo
        }
    },

    onUpdateCustomerInfo (data) {
        if (!data.isValid) {
            return false
        }

        const customerEmail = _.get(data, 'fields.emailAddress.value')
        currentTags.customer_email = customerEmail && global.tc_md5_hex ? global.tc_md5_hex(customerEmail.toLowerCase()).toUpperCase() : ''
    },

    _getAgeRange (birthdate) {
        const birthdateMoment = moment(birthdate, ['YYYY-M-D'], true)
        if (!birthdateMoment.isValid()) {
            return ''
        }
        const age = moment().diff(birthdateMoment, 'years')

        return (age <= 12) ? '0-12'
            : (age <= 18) ? '13-18'
                : (age <= 25) ? '19-25'
                    : (age <= 34) ? '26-34'
                        : (age <= 48) ? '35-48'
                            : (age <= 64) ? '49-64' : '65+'
    },

    _paymentCodes: {
        ideal: 'ID',
        cc3ds: 'CC',
        cc2ds: 'CC',
        bancontactmistercash: 'BC',
        voucher: 'VO',
        other: 'UK'
    },

    getCodeForPaymentMethod (method) {
        method = method.toLowerCase()
        if (!(method in this._paymentCodes)) {
            method = 'other'
        }

        return this._paymentCodes[method]
    },

    _getAverageProductPrice (products) {
        if (products.length === 0) {
            return 0
        }

        return products.reduce((price, product) => {
            const discount = (
                product.discounts &&
                product.discounts.length &&
                product.discounts.filter(d => d.type === 'V').reduce((sum, d) => sum + d.amount, 0)
            ) || 0

            return price + product.price - discount
        }, 0) / products.length
    },

    _getAverageProductRebookPrice (booking, direction, passengerType) {
        return Math.max(
            booking.getAmountAddedTicketsByDirectionAndPassengerType(direction, passengerType) -
            booking.getAmountReplacedTicketsByDirectionAndPassengerType(direction, passengerType),
            0
        )
    }
}))
