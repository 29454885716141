import PropTypes from 'prop-types'
import React, { Component } from 'react'
import device from '../../../device'
import RebookingLayoutContainer from '../../re-booking-layout-container'
import ProgressNavigation from '../../../components/options/progress-navigation'
import HelpButton from '../../../base/help-button'
import Information from '../../../components/options/information'
import SeatOptions from '../../../components/options/seat-options'
import LuggageOptions from '../../../components/options/luggage-options'
import BikeOptions from '../../../components/options/bike-options'
import Loader from '../../../element/loader'

export default class Options extends Component {
    static propTypes = {
        hasBooking: PropTypes.bool,
        loading: PropTypes.bool,
        optionSelected: PropTypes.bool,
        onClickPrevious: PropTypes.any,
        onClickNext: PropTypes.any,
        availableSections: PropTypes.array
    }

    render () {
        const {hasBooking, onClickPrevious, onClickNext} = this.props
        const progressNavigationProps = {
            onClickPrevious,
            onClickNext,
            nextLoading: this.props.loading,
            optionSelected: this.props.optionSelected
        }

        if (this.props.loading) {
            return <Loader blocking />
        }

        return hasBooking ? (
            <RebookingLayoutContainer name='booking-options'>
                <ProgressNavigation {...progressNavigationProps} previousButtonEnabled={!device.isMobile()} />
                <Information />
                <SeatOptions
                    seatSelectorRoute='/mys3/re-booking/seat-selector'
                    availableSections={this.props.availableSections}
                    rebookFlow
                />
                <LuggageOptions luggageSelectorRoute='/mys3/re-booking/luggage' />
                <BikeOptions bikeSelectorRoute='/mys3/re-booking/bike' />
                <ProgressNavigation {...progressNavigationProps} />
                {device.isMobile() ? <HelpButton /> : null}
            </RebookingLayoutContainer>
        ) : null
    }
}
