import PropTypes from 'prop-types'
import React from 'react'
import Reflux from 'reflux'
import _t from '../translate'
import _ from 'lodash'
import moment from 'moment-timezone'
import jquery from 'jquery'
import {withRouter} from 'react-router'
import Button from '../element/button'
import TextLabel from '../element/text-label'
import Icon from '../element/icon'
import classNames from 'classnames'
import OfferStore from '../reflux/stores/offer-store'
import SelectBundleStore from '../reflux/stores/select-bundle-store'
import {SERVICE_TYPE_MODALITY_TER} from '../constants'

import {connectState, getState} from '../reflux/bridge/connect-state'
import {sortedTravelsSelector} from '../models/selectors/api/v2/orientation/travel'
import {isReturnJourney} from '../models/selectors/api/v2/orientation/journey-search'
import {
    selectedBundlesSelector,
    routesSelector,
    totalPriceSelectedBundlesSelector
} from '../models/selectors/components/orientation/journey-search'
import createReactClass from 'create-react-class'

const mapPropsToProps = () => {
    const state = getState()
    const selectedBundles = selectedBundlesSelector(state)
    return {
        selectedBundles,
        travels: sortedTravelsSelector(state),
        isReturnTrip: isReturnJourney(state),
        totalPrice: totalPriceSelectedBundlesSelector(state)
    }
}

export default withRouter(connectState(mapPropsToProps)(createReactClass({

    propTypes: {
        selectedBundles: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string,
            direction: PropTypes.string,
            routeId: PropTypes.string
        })),
        travels: PropTypes.arrayOf(PropTypes.object),
        loading: PropTypes.bool.isRequired,
        totalPrice: PropTypes.number.isRequired,
        onNext: PropTypes.func.isRequired,
        isReturnTrip: PropTypes.bool
    },

    mixins: [
        Reflux.connect(OfferStore, 'offer'),
        Reflux.connect(SelectBundleStore, 'selectBundle')
    ],

    getInitialState () {
        return {
            isExpanded: true
        }
    },

    toggleExpandCollapse () {
        this.setState({isExpanded: !this.state.isExpanded})
    },

    render () {
        if (this.props.selectedBundles.length === 0) {
            return null
        }

        const travelBoxes = this.props.travels
            .map(travel => {
                const selectedBundle = this.props.selectedBundles.find(bundle => bundle.direction === travel.direction)
                const selectedRoute = routesSelector(travel.id)(getState())
                    .find(route => selectedBundle && route.id === selectedBundle.routeId)
                return this.renderTravelBox(
                    selectedBundle,
                    travel,
                    selectedRoute,
                    travel.direction,
                    this.props.travels.length === 1
                )
            })

        const canContinue = (this.props.isReturnTrip ? 2 : 1) === this.props.selectedBundles.length

        return <div className='journey-summary-bar'>
            <div className='grid-row no-gutter'>
                {travelBoxes}
                <div className='grid-column--1-1 grid-column--medium-3-11'>
                    <div className='journey-summary-bar-item price'>
                        <Button
                            id='journey-summary-bar-collapse'
                            type='button'
                            className='button secondary collapse'
                            onClick={this.toggleExpandCollapse}
                        >
                            <Icon type={`chevron-${this.state.isExpanded ? 'down' : 'up'}`} className='xsmall' />
                        </Button>
                        <span className='text-label title'>
                            <TextLabel text={_t.getIntlMessage('journey-summary-bar.total-price')} />
                        </span>
                        <span className='text-label total-price'>
                            <TextLabel text={_t.formatCurrency(this.props.totalPrice)} />
                        </span>
                        {this.state.isExpanded ? (
                            <Button
                                id='journey-summary-bar-next'
                                type='button'
                                className='button primary continue'
                                onClick={this.props.onNext}
                                loading={this.props.loading}
                                disabled={!canContinue}
                            >
                                <TextLabel text={_t.formatIntlMessage('progress-navigation.button.next')} />
                                <Icon type='chevron-right' className='xsmall align-right' />
                            </Button>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    },

    _getDepartureTime (leg) {
        return leg.departureStation.departureTime
    },

    _getArrivalTime (leg) {
        return leg.arrivalStation.arrivalTime
    },

    renderTravelBox (bundle, travel, route, type, singleItem) {
        const firstLeg = route && _(route.legs).first()
        const lastLeg = route && _(route.legs).last()
        const legs = (route && route.legs) || []

        const departureTime = firstLeg && this._getDepartureTime(firstLeg)
        const arrivalTime = lastLeg && this._getArrivalTime(lastLeg)

        const travelTime = route && moment.duration(route.duration || '')
        const travelTimeValues = travelTime && {
            hours: Math.floor(travelTime.asHours()),
            minutes: travelTime.minutes(),
            hoursPadded: _.padStart(Math.floor(travelTime.asHours()), 2, '0'),
            minutesPadded: _.padStart(travelTime.minutes(), 2, '0')
        }

        const box = bundle ? (
            <div className={classNames('journey-summary-bar-item', type)}>
                <div className='journey-summary-bar-summary'>
                    <span className='text-label title'>
                        <TextLabel text={_t.formatIntlMessage(`booking-specification.${type}`)} />
                        {this.renderTravelDirectionIcon(type)}
                    </span>
                    <span className='text-label bus-number'>
                        {legs.map(this._renderLeg)}
                    </span>
                </div>
                {this.state.isExpanded ? (
                    <div className='journey-summary-bar-details'>
                        <div className='grid-row no-gutter'>
                            <div className='grid-column--1-7'>
                                <div className='travel-date'>
                                    <span className='text-label travel-date-day-name'>
                                        <TextLabel text={departureTime.format('ddd')} />
                                    </span>
                                    <span className='text-label travel-date-day'>
                                        <TextLabel text={departureTime.format('DD')} />
                                    </span>
                                </div>
                            </div>
                            <div className='grid-column--2-7'>
                                <span className='text-label departure-time-description'>
                                    <TextLabel text={firstLeg.departureStation.name ||
                                    _t.formatIntlMessage('journey-result-set.departure')} />
                                </span>
                                <span className='text-label departure-time'>
                                    <TextLabel text={departureTime && departureTime.format('LT')} />
                                    <Icon type='arrow-right' className='small align-right' />
                                </span>
                            </div>
                            <div className='grid-column--2-7'>
                                <span className='text-label arrival-time-description'>
                                    <TextLabel text={lastLeg.arrivalStation.name ||
                                    _t.formatIntlMessage('journey-result-set.arrival')} />
                                </span>
                                <span className='text-label arrival-time'>
                                    <TextLabel text={arrivalTime && arrivalTime.format('LT')} />
                                </span>
                            </div>
                            <div className='grid-column--2-7'>
                                <span className='text-label travel-time-description'>
                                    <TextLabel text={_t.formatIntlMessage('journey-result-set.travel-time')} />
                                </span>
                                <span className='text-label travel-time'>
                                    <Icon type='clock' className='small align-left' />
                                    <TextLabel text={_t.formatIntlMessage(
                                        'journey-result-set.travel-time-info.text',
                                        travelTimeValues)} />
                                </span>
                            </div>
                        </div>
                    </div>
                ) : null}
            </div>
        ) : (
            <div className='journey-summary-bar-item inbound'>
                <span className='text-label title'>
                    <TextLabel text={_t.formatIntlMessage(`booking-specification.${type}`)} />
                    {this.renderTravelDirectionIcon(type)}
                </span>
                {this.state.isExpanded ? (
                    <a
                        href='#'
                        className='jump-to-journey-result-set'
                        onClick={event => event.preventDefault() || this._scrollTo(type)}
                    >
                        <span className='text-label no-journey-selected'>
                            <TextLabel text={_t.formatIntlMessage(
                                `journey-result-set.journey-summary-box.select-message.${type}`)
                            } />
                        </span>
                    </a>
                ) : null}
            </div>
        )

        const classes = classNames('grid-column--1-1', 'grid-column--small-1-2', 'grid-column--medium-4-11', {
            'offset-grid-column--medium-4-11 columnClass': singleItem
        })

        return (
            <div key={travel.id || 'no-travel'} className={classes}>
                {box}
            </div>
        )
    },

    _renderLeg (leg, index, legs) {
        const sections = []

        sections.push(
            <Icon
                type={leg.serviceType.modality === SERVICE_TYPE_MODALITY_TER ? 'ter' : 'bus'}
                className='small align-left'
            />,
            <TextLabel text={leg.serviceName} />
        )

        if (index + 1 in legs) {
            sections.push(<Icon type='chevron-right' className='align-left align-right xsmall' />)
        }

        return sections
    },

    _scrollTo (type) {
        const element = type === 'outbound' ? jquery('.journey-result-set > .outbound')
            : jquery('.journey-result-set > .inbound')

        jquery('html, body').animate({
            scrollTop: element.offset().top
        }, 1000)
    },

    renderTravelDirectionIcon (type) {
        return type === 'outbound'
            ? <Icon type='half-arrow-right' className='small align-right' />
            : <Icon type='half-arrow-left' className='small align-right' />
    }

})))
