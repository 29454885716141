import _t from '../translate'
import qs from 'qs'
import storage from '../storage'
import {
    BLABLACAR,
    BLABLACAR_APP
} from '../data/affiliate-constants'
import {SALES_CHANNEL_LABELPARTNER} from '../constants'

export const hasDomain = domain => Boolean(window.location && window.location.hostname && window.location.hostname.indexOf(domain) >= 0)
// Show BBB branding if we're not on ouibus domain (bbb or localhost) or if the language is FR/EN.
// FR and EN show a special logo for ouibus + bbb and are allowed to login with crm (my profile).
export const isBlablabusDomain = () => !hasDomain('ouibus') || isOuiBlabusException()
export const isOuiBlabusException = () => ['fr-FR', 'en-GB'].includes(_t.getLocales())
export const insuranceActiveForLocaleAndSalesChannel = salesChannel => ['fr-FR'].includes(_t.getLocales()) &&
    salesChannel === SALES_CHANNEL_LABELPARTNER

// Show BBC branding if the affiliate code is blablacar
const urlParams = qs.parse(window.location.search.slice(1)) || {}
export const showBlablaCarBranding = [BLABLACAR, BLABLACAR_APP].includes((urlParams.affiliateCode || storage.get('affiliate_code') || '').toLowerCase())

export const showLuggageAddOn = () => ['fr-FR', 'de-DE', 'en-GB', 'hr-HR', 'nl-BE', 'nl-NL', 'es-ES', 'it-IT', 'pt-PT', 'cs-CZ', 'hu-HU', 'sk-SK'].includes(_t.getLocales())
