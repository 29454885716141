// Libraries
import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {withRouter} from 'react-router'
import ProgressBar from './progress-bar'
import _t from '../translate'

class ReBookigProgressBar extends Component {
    static propTypes = {
        router: PropTypes.object
    }

    render () {
        const isPayment = this.props.router.isActive(`/${_t.getLocales()}/mys3/re-booking/payment`)
        const isConfirmation = this.props.router.isActive(`/${_t.getLocales()}/mys3/re-booking/confirmation`)

        return (
            <ProgressBar progressRouteMap={[
                {path: `/${_t.getLocales()}/mys3/re-booking/offer`, enabled: !isPayment && !isConfirmation},
                {path: `/${_t.getLocales()}/mys3/re-booking/details`, enabled: !isPayment && !isConfirmation},
                {path: `/${_t.getLocales()}/mys3/re-booking/options`, enabled: !isPayment && !isConfirmation},
                {path: `/${_t.getLocales()}/mys3/re-booking/payment`, enabled: !isConfirmation},
                {path: `/${_t.getLocales()}/mys3/re-booking/confirmation`, enabled: true}
            ]} />
        )
    }
}

export default withRouter(ReBookigProgressBar)
