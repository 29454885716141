// Libraries
import React from 'react'
import {Route} from 'react-router'

import authCheck from '../../mixins/auth'
import AccessManager from '../../data/access-manager'

// Layouts
import MyS3BookingOverviewLayout from '../../layouts/my-s3-booking-overview-layout'

export default (
    <Route
        path='/:lang/mys3/bookings'
        component={MyS3BookingOverviewLayout}
        onEnter={(nextState, replaceState, callback) => authCheck(nextState, replaceState, callback, {
            role: AccessManager.roles.crm
        })}
    />
)
