import Reflux from 'reflux'
import Moment from 'moment-timezone'
import _t from '../../translate'
import UserAwareMixin from '../../reflux/mixin/user-aware-mixin'
import actions from '../../reflux/actions'

import ResponseCodes from '../../api/response-codes'
import {trimString} from '../../misc/helpers'

export default Reflux.createStore({

    listenables: actions,

    mixins: [UserAwareMixin],

    init () {
        this._resetData()

        this.listenTo(actions.requestCrmVouchers, this.resetErrorText)
        this.listenTo(actions.deleteCrmVoucher, this.resetErrorText)
        this.listenTo(actions.addCrmVoucher, this.resetErrorText)
        this.listenTo(actions.addVoucher, this.resetErrorText)
    },

    _resetData () {
        this.data = {
            vouchers: [],
            errorText: ''
        }
    },

    resetErrorText () {
        this.data.errorText = ''
        this.trigger(this.data)
    },

    resetData () {
        this._resetData()
        this.trigger(this.data)
    },

    getInitialState () {
        return this.data
    },

    getAvailableAmount (voucherCode) {
        const voucher = this.data.vouchers.find(voucher => voucher.code === voucherCode)
        return voucher.amount_used
            ? voucher.amount - voucher.amount_used
            : voucher.amount
    },

    getVoucherValueLabel (voucherCode) {
        const voucher = this.data.vouchers.find(voucher => voucher.code === voucherCode)
        if (voucher.percentage) {
            return `% ${voucher.percentage}`
        } else {
            const amount = this.getAvailableAmount(voucherCode)
            return _t.formatCurrency(amount, voucher.currency)
        }
    },

    getAvailableVouchers (outboundDate, inboundDate) {
        return this.data.vouchers
            .filter(voucher => {
                if (!voucher.hasDetailInformation) {
                    return false
                }
                const positiveValue = voucher.percentage > 0 || this.getAvailableAmount(voucher.code) > 0

                const dateTravelStart = Moment(voucher.travel_period_start, 'YYYY-MM-DD')
                const dateTravelEnd = Moment(voucher.travel_period_end, 'YYYY-MM-DD')
                const dateSalesStart = Moment(voucher.sales_period_start, 'YYYY-MM-DD')
                const dateSalesEnd = Moment(voucher.sales_period_end, 'YYYY-MM-DD')
                const dateCurrent = Moment()

                const outboundTravelDate = Moment(outboundDate, 'YYYY-MM-DD')
                const inboundTravelDate = Moment(inboundDate, 'YYYY-MM-DD')

                const withinTravelDate = outboundTravelDate.isBetween(dateTravelStart, dateTravelEnd, null, '[]') ||
                    (inboundDate && inboundTravelDate.isBetween(dateTravelStart, dateTravelEnd, null, '[]'))

                const withinSalesDate = dateCurrent.isBetween(dateSalesStart, dateSalesEnd, null, '[]')

                return voucher.active &&
                    positiveValue &&
                    withinTravelDate &&
                    withinSalesDate
            })
    },

    onRequestCrmVoucher (voucherCode) {
        const index = this.data.vouchers.findIndex(voucher => voucher.code === voucherCode)
        if (index !== -1) {
            this.data.vouchers[index].didRequestDetailInformation = true
            this.trigger(this.data)
        }
    },

    onRequestCrmVoucherCompleted (response) {
        const index = this.data.vouchers.findIndex(voucher => voucher.code === response.code)
        if (index !== -1) {
            this.data.vouchers[index] = {...this.data.vouchers[index], ...response, hasDetailInformation: true}
            this.trigger(this.data)
        }
    },

    onRequestCrmVouchersCompleted (response) {
        this.data.vouchers = response
        this.trigger(this.data)
    },

    onAddCrmVoucherCompleted (response) {
        this.data.vouchers = response
        this.trigger(this.data)
    },

    onDeleteCrmVoucherCompleted (response) {
        this.data.vouchers = response
        this.trigger(this.data)
    },

    onApiError (error) {
        if (error.code === ResponseCodes.VOUCHER_ALREADY_USED ||
            error.code === ResponseCodes.VOUCHER_NOT_FOUND) {
            error.handled = true
        }

        if (error.code === ResponseCodes.VOUCHER_ALREADY_USED) {
            this.data.errorText = ResponseCodes.translate(error.code, error.response.body.message)
        }

        this.trigger(this.data)
    },

    isVoucherValid (voucherCode) {
        return voucherCode !== null && trimString(voucherCode).length >= 3
    }
})
