import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from '../../translate'
import TextLabel from '../../element/text-label'
import {additionalProductsSelector} from '../../models/selectors/api/v2/booking/products'
import {getState} from '../../reflux/bridge/connect-state'

export default class SeatSummaryTotals extends Component {
    static propTypes = {
        selectedSeats: PropTypes.array.isRequired
    }

    getTotalPrice (selectedSeats) {
        const selectedSeatsNotPaid = selectedSeats.filter(selectedSeat => selectedSeat.provisional === true && selectedSeat.cancelled === false) || []
        const additionalProducts = additionalProductsSelector(getState())
        return (selectedSeatsNotPaid || []).reduce((totalPrice, selectedSeat) => {
            const product = (additionalProducts || []).find(product => product.itemRef === selectedSeat.additionalProductItemRef)
            totalPrice += (product && product.price) || 0

            return totalPrice
        }, 0.0)
    }

    render () {
        const {selectedSeats} = this.props
        return (
            <div className='seat-summary-totals'>
                <div className='reserved-seats-total'>
                    <span className='text-label reserved-seats-total-description'>
                        <TextLabel text={_t.getIntlMessage(`seat-summary.reserved-seats-total`)} />
                    </span>
                    <span className='text-label reserved-seats-total-value'>
                        <TextLabel text={_t.formatCurrency(this.getTotalPrice(selectedSeats))} />
                    </span>
                </div>
            </div>
        )
    }
}
