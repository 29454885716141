import _ from 'lodash'
// eslint-disable-next-line import/no-unresolved, import/no-webpack-loader-syntax
import Worldpay from './worldpay-loader!worldpay-cse/worldpay-js/worldpay-cse'

const _errorMessages = {
    101: 'card-number.is-required',
    102: 'card-number.invalid-value',
    103: 'card-number.luhn',
    201: 'cvc-code.invalid-length',
    301: 'valid-until.is-required',
    302: 'valid-until.invalid-value',
    303: 'valid-until.invalid-value',
    304: 'valid-until-year.is-required',
    305: 'valid-until-year.invalid-value',
    306: 'valid-until.date-in-past',
    401: 'card-holder.is-required',
    402: 'card-holder.invalid-value'
}

export default class S3WorldPay {
    static encrypt (data, publicKey) {
        try {
            Worldpay.setPublicKey(publicKey)
        } catch (e) {
            throw new Error('malformed-public-key')
        }

        return Worldpay.encrypt({
            cvc: data.cvc,
            cardHolderName: data.cardHolderName,
            cardNumber: data.cardNumber,
            expiryMonth: _.padStart(String(data.expiryMonth), 2, '0'),
            expiryYear: String(data.expiryYear)
        }, S3WorldPay.errorHandler)
    }

    static errorHandler (errorCodes) {
        let message = 'unknown-error'
        if (errorCodes.length > 0 && _errorMessages[errorCodes[0]]) {
            message = _errorMessages[errorCodes[0]]
        }

        throw new Error(message)
    }
}
