// Libraries
import PropTypes from 'prop-types'
import React, {Component} from 'react'
import _t from '../../translate'
import _ from 'lodash'

// Components
import Button from '../../element/button'
import TextLabel from '../../element/text-label'
import TextField from '../../element/form/text-field'
import EmailField from '../../element/form/email-field'

// Actions
import actions from '../../reflux/actions'

const PropTypeField = PropTypes.shape({
    value: PropTypes.string.isRequired,
    errorText: PropTypes.string,
    isRequired: PropTypes.bool.isRequired
}).isRequired

export default class Form extends Component {
    static propTypes = {
        onSubmit: PropTypes.func.isRequired,
        formData: PropTypes.shape({
            loading: PropTypes.bool.isRequired,
            fields: PropTypes.shape({
                firstName: PropTypeField,
                lastName: PropTypeField,
                emailAddress: PropTypeField,
                repeatEmailAddress: PropTypeField
            }).isRequired
        }).isRequired
    }

    render () {
        return (
            <form action='#' noValidate onSubmit={this._onSubmit}>
                <div className='grid-row'>
                    <div className='grid-column--1-1'>
                        <TextField className='first-name' {...this._textFieldProps('firstName')} />
                    </div>
                </div>
                <div className='grid-row'>
                    <div className='grid-column--1-1'>
                        <TextField className='last-name' {...this._textFieldProps('lastName')} />
                    </div>
                </div>
                <div className='grid-row'>
                    <div className='grid-column--1-1'>
                        <EmailField className='email-address' {...this._textFieldProps('emailAddress')} />
                    </div>
                </div>
                <div className='grid-row'>
                    <div className='grid-column--1-1'>
                        <EmailField className='email-address' {...this._textFieldProps('repeatEmailAddress')} />
                    </div>
                </div>
                <div className='grid-row'>
                    <div className='grid-column--1-1'>
                        <Button
                            type='submit' className='button primary'
                            loading={this.props.formData.loading}
                            onClick={this._onSubmit}
                        >
                            <TextLabel text={_t.getIntlMessage('my-s3-register-form.button')} />
                        </Button>
                    </div>
                </div>
            </form>
        )
    }

    _textFieldProps (fieldName) {
        let kebabCase = _.kebabCase(fieldName)
        return {
            id: fieldName,
            ref: fieldName,
            labelText: this._prefixFieldLabel(kebabCase, 'label'),
            placeholder: this._prefixFieldLabel(kebabCase, 'placeholder'),
            defaultValue: this.props.formData.fields[fieldName].value,
            required: this.props.formData.fields[fieldName].isRequired,
            errorText: this._prefixFieldLabel(kebabCase, this.props.formData.fields[fieldName].errorText),
            onBlur: this._onBlur
        }
    }

    _prefixFieldLabel (field, label) {
        return label ? `my-s3-register-form.${field}.${label}` : null
    }

    _onSubmit (e) {
        e.preventDefault()

        actions.processCrmRegisterData([
            {id: 'firstName', value: this.refs.firstName.getValue()},
            {id: 'lastName', value: this.refs.lastName.getValue()},
            {id: 'emailAddress', value: this.refs.emailAddress.getValue()},
            {id: 'repeatEmailAddress', value: this.refs.repeatEmailAddress.getValue()}
        ])

        this.props.onSubmit()
    }

    _onBlur (event) {
        actions.processCrmRegisterData([{
            id: event.target.id,
            value: event.target.value
        }])
    }
}
