// Libraries
import PropTypes from 'prop-types'
import React from 'react'
import Reflux from 'reflux'
import ProgressBar from './progress-bar'
import _t from '../translate'
import {newOuibusSearchUrl} from '../misc/new-ouibus-helper'
import {isAffiliateBlablacarSelector} from '../reflux/bridge/affiliate'
import {withRouter} from 'react-router'
import BreadcrumbStore from '../reflux/stores/breadcrumb-store'
import createReactClass from 'create-react-class'

export default withRouter(createReactClass({

    displayName: 'OrientationProgressBar',

    mixins: [
        Reflux.connect(BreadcrumbStore, 'data')
    ],

    propTypes: {
        router: PropTypes.object
    },

    routes () {
        const isPayment = this.props.router.isActive(`/${_t.getLocales()}/booking/payment`)
        const isConfirmation = this.props.router.isActive(`/${_t.getLocales()}/booking/confirmation`)

        const isEnabled = !isPayment && !isConfirmation
        const isAfterSalesConfirmation = (
            this.props.router.isActive(`/${_t.getLocales()}/mys3/re-booking/confirmation`) ||
            this.props.router.isActive(`/${_t.getLocales()}/mys3/cancellation/confirmation`) ||
            this.props.router.isActive(`/${_t.getLocales()}/mys3/seat/confirmation`) ||
            this.props.router.isActive(`/${_t.getLocales()}/mys3/update-passengers/confirmation`)
        )

        const isDisabledRoute = path => {
            return this.state.data.disabledRoutes.includes(path) || isAfterSalesConfirmation
        }

        const orientationOfferRoute = {
            path: `/${_t.getLocales()}/orientation/offer`,
            externalUrl: newOuibusSearchUrl(),
            enabled: isEnabled && !isDisabledRoute(`/${_t.getLocales()}/orientation/offer`)
        }
        const bookingPassengerDetailsRoute = {
            path: `/${_t.getLocales()}/booking/passenger-details`,
            enabled: isEnabled && !isDisabledRoute(`/${_t.getLocales()}/booking/passenger-details`)
        }
        const bookingOptionsRoute = {
            path: `/${_t.getLocales()}/booking/options`,
            subPaths: [`/${_t.getLocales()}/booking/seat-selector`],
            enabled: isEnabled && !isDisabledRoute(`/${_t.getLocales()}/booking/options`)
        }
        const bookingCustomerDetailsRoute = {
            path: `/${_t.getLocales()}/booking/customer-details`,
            enabled: isEnabled && !isDisabledRoute(`/${_t.getLocales()}/booking/customer-details`)
        }
        const bookingPaymentRoute = {
            path: `/${_t.getLocales()}/booking/payment`,
            enabled: !isConfirmation && !isDisabledRoute(`/${_t.getLocales()}/booking/payment`)
        }
        const bookingConfirmationRoute = {
            path: `/${_t.getLocales()}/booking/confirmation`,
            enabled: !isDisabledRoute(`/${_t.getLocales()}/booking/confirmation`)
        }

        return [
            orientationOfferRoute,
            bookingPassengerDetailsRoute,
            bookingOptionsRoute,
            bookingCustomerDetailsRoute,
            bookingPaymentRoute,
            bookingConfirmationRoute
        ]
    },

    render () {
        if (isAffiliateBlablacarSelector()) {
            return null
        }

        return (
            <ProgressBar progressRouteMap={this.routes()} />
        )
    }
}))
