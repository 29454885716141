import PropTypes from 'prop-types'
import React, {Component} from 'react'
import classNames from 'classnames'
import _t from '../../../../translate'
import actions from '../../../../reflux/actions'
import AccessManager from '../../../../data/access-manager'
import TextLabel from '../../../../element/text-label'
import Button from '../../../../element/button'
import Message from '../../../../element/message'
import {trimString} from '../../../../misc/helpers'
import VoucherStore from '../../../../reflux/stores/voucher-store'
import {getMessageVoucherEmailRestriction} from '../../../../misc/vouchers'
import TextField from '../../../../element/form/text-field'
import {withRouter} from 'react-router'

class AddVoucherForm extends Component {
    static propTypes = {
        onAddVoucher: PropTypes.func.isRequired,
        messages: PropTypes.array.isRequired,
        loading: PropTypes.bool,
        voucherAction: PropTypes.string,
        addedVoucherCode: PropTypes.string,
        location: PropTypes.object
    }

    constructor (...args) {
        super(...args)
        this.state = {voucherCode: this.props.addedVoucherCode || null}
        this._handleAddVoucher = this._handleAddVoucher.bind(this)
        this._handleChange = this._handleChange.bind(this)
        this._renderMessage = this._renderMessage.bind(this)
    }

    _handleChange (event) {
        this.setState({voucherCode: event.target.value.toUpperCase()})
    }

    _handleAddVoucher (event) {
        event.preventDefault()
        const voucherCode = trimString(this.state.voucherCode).substring(0, 30)

        if (VoucherStore.isVoucherValid(voucherCode)) {
            this.props.onAddVoucher(voucherCode)
            if (AccessManager.isCrmUser()) {
                actions.addCrmVoucher(voucherCode)
            }
        }
    }

    _renderMessage (message, index) {
        return <Message
            key={index}
            message={getMessageVoucherEmailRestriction(this.props.location.pathname, message)}
        />
    }

    render () {
        return (
            <div className='manage-voucher panel'>
                <div className='text-label enter-voucher-code'>
                    <TextLabel
                        text={_t.getIntlMessage('booking-specification.manage-vouchers.enter-voucher-code')} />
                </div>
                <div className='grid-row'>
                    <form onSubmit={this._handleAddVoucher}>
                        <div className='grid-column--1-1'>
                            <div className='voucher-input-button'>
                                <TextField
                                    ref='voucherCode'
                                    id='enter-voucher-code'
                                    name='enter-voucher-code'
                                    labelText='voucher.voucher-code-input-placeholder'
                                    floatingLabel
                                    className={
                                        classNames('voucher-input', {'state--is-invalid': this.props.messages.length})
                                    }
                                    disabled={this.props.loading}
                                    value={this.state.voucherCode || ''}
                                    onChange={this._handleChange}
                                />
                                <Button
                                    ref='addButton'
                                    type='button'
                                    className='button secondary submit-voucher-code'
                                    onClick={this._handleAddVoucher}
                                    disabled={this.props.loading || !VoucherStore.isVoucherValid(this.state.voucherCode)}
                                    loading={this.props.loading && this.props.voucherAction === 'add'}
                                >
                                    <TextLabel text={_t.getIntlMessage(
                                        'booking-specification.manage-vouchers.submit-voucher-code')}
                                    />
                                </Button>
                            </div>
                            {this.props.messages.map(this._renderMessage)}
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

export default withRouter(AddVoucherForm)
