import React from 'react'
import PropTypes from 'prop-types'
import _t from '../translate'
import qs from 'qs'
import moment from 'moment-timezone'
import actions from '../reflux/actions'
import Storage from '../storage'
import {camelCaseGivenKeys} from '../misc/camelcase'
import {DESIGN_STORAGE_KEY} from '../data/design-constants'
import {
    STORAGE_TRACKTOR,
    STORAGE_WEB_VIEW
} from '../constants'
import {processDeepLink} from './deep-link/deep-link'
import {toUtcDateMoment} from '../misc/date'
import {storeTracktorIdentifiers} from '../misc/tracktor'
import {
    BLABLACAR,
    BLABLACAR_APP
} from '../data/affiliate-constants'
import {hasDomain} from '../misc/blablabus-helpers'
import {tracktorProbeEvent} from '../misc/blablabus-tracktor'
import GoogleTaggingStore from '../reflux/stores/google-tagging-store'

const routeMap = {
    routes: 'orientation/offer',
    product: 'orientation/offer',
    addon: 'booking/passenger-details',
    booking: 'booking/passenger-details'
}

export default (bookingRoutes = ['booking'], cb = query => query) => Component =>
    class DeepLink extends React.Component {
        static propTypes = {
            location: PropTypes.shape({
                query: PropTypes.object,
                search: PropTypes.string,
                pathname: PropTypes.string
            }),
            router: PropTypes.shape({
                replace: PropTypes.func
            })
        }

        componentDidMount () {
            const locale = this.props.location.pathname.substring(1, 6)
            if (['fr-FR', 'en-GB'].includes(locale) && !hasDomain('booking.bus.blablacar')) {
                if (hasDomain('booking.blablabus')) {
                    window.location.href = window.location.href.replace('blablabus', 'bus.blablacar')
                } else if (hasDomain('booking.ouibus')) {
                    window.location.href = window.location.href.replace('ouibus', 'bus.blablacar')
                }
            } else if (['de-DE', 'it-IT', 'es-ES', 'nl-NL', 'nl-BE', 'pl-PL', 'hr-HR', 'pt-PT', 'cs-CZ', 'hu-HU', 'sk-SK'].includes(locale) && !hasDomain('booking.blablabus')) {
                if (hasDomain('booking.bus.blablacar')) {
                    window.location.href = window.location.href.replace('bus.blablacar', 'blablabus')
                } else if (hasDomain('booking.ouibus')) {
                    window.location.href = window.location.href.replace('ouibus', 'blablabus')
                }
            }
            let {
                target,
                origin,
                destination,
                outboundDate,
                outboundRoute,
                outboundProducts,
                outboundTransferStation,
                inboundDate,
                inboundRoute,
                inboundProducts,
                inboundTransferStation,
                passengers,
                salesChannel,
                affiliateCode,
                currency,
                voucherCode,
                design,
                webView,
                errorUrl,
                preferredOriginOutbound,
                preferredDestinationOutbound,
                preferredOriginInbound,
                preferredDestinationInbound,
                visid,
                devid,
                sstamp,
                comutoCmkt,
                userUuid,
                ...other
            } = camelCaseGivenKeys(
                cb(this.props.location.query),
                [
                    'preferred_origin_outbound',
                    'preferred_destination_outbound',
                    'preferred_origin_inbound',
                    'preferred_destination_inbound',
                    'comuto_cmkt'
                ]
            )

            if (undefined !== outboundDate && !moment(outboundDate, 'YYYY-MM-DD', true).isValid()) {
                outboundDate = undefined
            }
            if (undefined !== inboundDate && !moment(inboundDate, 'YYYY-MM-DD', true).isValid()) {
                inboundDate = undefined
            }

            const minimalRouteData =
                undefined !== target &&
                undefined !== origin &&
                undefined !== destination &&
                undefined !== outboundDate

            if (undefined !== currency) {
                actions.changeCurrency(currency)
            }

            if (undefined !== affiliateCode) {
                actions.changeAffiliateCode(affiliateCode)
            }

            Storage.clear(DESIGN_STORAGE_KEY)
            design && Storage.set(DESIGN_STORAGE_KEY, design)
            Storage.clear(STORAGE_WEB_VIEW)
            webView && Storage.set(STORAGE_WEB_VIEW, webView === '1')

            comutoCmkt && tracktorProbeEvent(comutoCmkt)
            comutoCmkt && GoogleTaggingStore.onProcessDeepLink({'comuto_cmkt': comutoCmkt})

            affiliateCode && (affiliateCode.toLowerCase() === BLABLACAR || affiliateCode.toLowerCase() === BLABLACAR_APP) ? storeTracktorIdentifiers(visid, devid, sstamp) : Storage.clear(STORAGE_TRACKTOR)

            const currentDate = toUtcDateMoment()
            if (undefined !== outboundDate && toUtcDateMoment(outboundDate).isBefore(currentDate)) {
                outboundDate = currentDate.format('YYYY-MM-DD')
            }

            if (undefined !== inboundDate && toUtcDateMoment(inboundDate).isBefore(currentDate)) {
                inboundDate = currentDate.clone().add(1, 'days').format('YYYY-MM-DD')
            }

            if (minimalRouteData || undefined !== affiliateCode || undefined !== salesChannel) {
                Storage.clear('voucherCode')
                voucherCode && Storage.set('voucherCode', voucherCode)
                const deepLinkdata = {
                    target,
                    origin,
                    destination,
                    outboundDate,
                    outboundRoute,
                    outboundProducts,
                    outboundTransferStation,
                    inboundDate,
                    inboundRoute,
                    inboundProducts,
                    inboundTransferStation,
                    passengers,
                    salesChannel,
                    affiliateCode,
                    currency,
                    errorUrl,
                    preferredOriginOutbound,
                    preferredDestinationOutbound,
                    preferredOriginInbound,
                    preferredDestinationInbound,
                    userUuid,
                    isForOfferPage: target === 'routes',
                    createProvisionalBooking: bookingRoutes.find(route => route === target) !== undefined,
                    queryString: qs.stringify(other)
                }

                actions.processDeepLink(deepLinkdata) // update deep-link-store for legacy reasons

                // execute deeplink itself
                processDeepLink(deepLinkdata).then(data => {
                    this._redirect(data)
                }).catch(() => {
                    actions.clearOffer()
                    this._redirect({queryString: qs.stringify(other)})
                })
            } else {
                this.props.router.replace(`/${_t.getLocales()}/orientation/offer`)
            }
        }

        _redirect (data) {
            const queryString = 'queryString' in data && data.queryString !== '' ? '?' + data.queryString : ''
            if ('target' in data && `${data.target}` in routeMap) {
                this.props.router.replace(
                    `/${_t.getLocales()}/${routeMap[`${data.target}`]}${queryString}`
                )
            } else {
                this.props.router.replace(`/${_t.getLocales()}/orientation/offer${queryString}`)
            }
        }

        render () {
            return <Component {...this.props} />
        }
    }
