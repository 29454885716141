import {createSelector} from 'reselect'
import {selectedBundlesSelector} from '../orientation/journey-search'
import {
    INSURANCE_AVAILABLE_PRODUCT,
    INSURANCE_MAX_BOOKING_VALUE,
    INSURANCE_PRODUCT_REGEX,
    INSURANCE_PRODUCT_SUFFIX_NATIONAL
} from '../../../../constants'
import {bookingSelector} from '../../../../reflux/bridge/booking'
import {
    productsSelector,
    withoutCancelledSelector as productsWithoutCancelledSelector
} from '../../api/v2/booking/products'
import {totalPriceSelector} from '../../api/v2/booking/booking'

export const insuranceItemsSelector = createSelector(
    [selectedBundlesSelector],
    bundles => bundles && bundles.length && bundles[0].items.reduce(
        (products, item) => {
            if (item.productCode.match(INSURANCE_PRODUCT_REGEX)) {
                products.push(item)
            }
            return products
        },
        []
    )
)

export const insuranceAvailableSelector = createSelector(
    [insuranceItemsSelector],
    items => Boolean(items && items.length) && items.some(item => item.productCode === INSURANCE_AVAILABLE_PRODUCT)
)

export const insuranceProductsBookingSelector = createSelector(
    [productsWithoutCancelledSelector(productsSelector(bookingSelector))],
    products => (products || []).filter(product => product.productCode && product.productCode.match(INSURANCE_PRODUCT_REGEX))
)

export const totalPriceInsuranceProductsSelector = createSelector(
    [insuranceProductsBookingSelector],
    products => (products || []).reduce((total, product) => total + product.price, 0)
)

export const productCodeInsuranceSelector = createSelector(
    [
        totalPriceSelector,
        totalPriceInsuranceProductsSelector
    ],
    (bookingTotalPrice, insuranceProductsTotalPrice) => {
        const totalPrice = bookingTotalPrice - insuranceProductsTotalPrice

        let productCode = null
        for (const key of Object.keys(INSURANCE_MAX_BOOKING_VALUE)) {
            if (totalPrice < INSURANCE_MAX_BOOKING_VALUE[key]) {
                productCode = `${key}${INSURANCE_PRODUCT_SUFFIX_NATIONAL}`
                break
            }
        }

        return productCode
    }
)

export const fareInsuranceSelector = createSelector(
    [
        productCodeInsuranceSelector,
        insuranceItemsSelector
    ],
    (productCode, items) => {
        const item = (items || []).find(_item => _item.productCode === productCode)

        return (item && item.passengerFares && item.passengerFares.length && item.passengerFares[0])
    }
)
