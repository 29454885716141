// Libraries
import React, {Component} from 'react'
import PropTypes from 'prop-types'
import _t from '../../translate'
import BookingModel from '../../models/booking-model'
import TextLabel from '../../element/text-label'

export default class MyS3BookingRelatedVoucherDetails extends Component {
    static propTypes = {
        booking: PropTypes.instanceOf(BookingModel)
    }

    render () {
        return this.props.booking.relatedVouchers.length > 0 ? (
            <div className='my-s3-booking-related-voucher-details'>
                <div className='panel'>
                    <header>
                        <span className='text-label heading'>
                            <TextLabel text={_t.getIntlMessage('booking-specification.related-voucher.header')} />
                        </span>
                    </header>
                    <div className='content'>
                        {this._renderRelatedVoucherDetails()}
                    </div>
                </div>
            </div>
        ) : null
    }

    _renderRelatedVoucherDetails () {
        return (
            <div className='booking-related-voucher-details'>
                <table className='responsive-table'>
                    <colgroup>
                        <col className='col-code' />
                        <col className='col-type' />
                        <col className='col-amount' />
                    </colgroup>
                    <thead>
                        <tr>
                            <th scope='col'>{_t.getIntlMessage('booking-specification.related-voucher.header-code')}</th>
                            <th scope='col'>{_t.getIntlMessage('booking-specification.related-voucher.header-type')}</th>
                            <th scope='col'>{_t.getIntlMessage('booking-specification.related-voucher.header-amount')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.booking.relatedVouchers.map(this._renderRelatedVoucher)}
                    </tbody>
                </table>
            </div>
        )
    }

    _renderRelatedVoucher (voucher) {
        return (
            <tr key={voucher.id}>
                <td data-label={_t.getIntlMessage('booking-specification.related-voucher.header-code')}>
                    {voucher.code}
                </td>
                <td data-label={_t.getIntlMessage('booking-specification.related-voucher.header-type')}>
                    {_t.formatIntlMessage('booking-specification.related-voucher.type', {type: voucher.type})}
                </td>
                <td data-label={_t.getIntlMessage('booking-specification.related-voucher.header-amount')}>
                    {_t.formatCurrency(voucher.amount)}
                </td>
            </tr>
        )
    }
}
