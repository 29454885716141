import createReactClass from 'create-react-class'
import React, {Component} from 'react'
import Reflux from 'reflux'
import MyS3LuggageOptions from '../components/mys3/luggage/luggage-options'
import MyS3BikeOptions from '../components/mys3/bike/bike-options'
import SeatOptions from '../components/mys3/seat/seat-options'
import MyS3OptionsLayoutContainer from './my-s3-options-layout-container'
import Loader from '../element/loader'
import BookingModel from '../models/booking-model'
import BookingStore from '../reflux/stores/booking-store'

const MyS3Options = createReactClass({
    displayName: 'MyS3Options',

    mixins: [
        Reflux.connectFilter(BookingStore, 'booking', data => BookingModel.create(data.booking)),
        Reflux.connectFilter(BookingStore, 'loading', data => data.loading)
    ],

    render () {
        const booking = this.state.booking
        return !this.state.loading && booking
            ? <div>
                <SeatOptions
                    selectedSeats={booking.selectedSeats}
                    seatSelectionOptions={booking.seatSelectionOptions().value()}
                    loading={false}
                    booking={booking}
                />
                <MyS3LuggageOptions />
                <MyS3BikeOptions />
            </div>
            : <Loader />
    }
})

export default class MyS3OptionsLayout extends Component {
    render () {
        return (
            <MyS3OptionsLayoutContainer name='my-options-container'>
                <MyS3Options />
            </MyS3OptionsLayoutContainer>
        )
    }
}
