import _ from 'lodash'
import _t from '../translate'
import countriesList from 'countries-list'
import countries from 'i18n-iso-countries'

export default class CountryCollection {
    static create () {
        countries.registerLocale(require(`i18n-iso-countries/langs/${_t.getLocales().substring(0, 2)}.json`))
        return new CountryCollection(countriesList.countries)
    }

    constructor (countries) {
        if (!_.isObject(countries)) {
            throw Error('Countries should be an object mapping country codes to country objects')
        }
        this._countryObjects = countries
    }

    get asMap () {
        return this._countryObjects
    }

    translatedName (code) {
        const language = _t.getLocales().substring(0, 2)
        return countries.getName(code, language)
    }

    get asOptionList () {
        return _(this._countryObjects)
            .map((country, code) => ({text: country.name, value: code}))
            .sortBy('text')
            .value()
    }

    get asCountryCodesOptionList () {
        return _(this._countryObjects)
            .map((country, code) => ({text: '+' + country.phone + ' ' + this.translatedName(code), value: code, name: this.translatedName(code)}))
            .sortBy('name')
            .value()
    }

    get asOptionListWithPriorities () {
        return this.optionsList(this.asOptionList)
    }

    get asCountryCodesOptionListWithPriorities () {
        return this.optionsList(this.asCountryCodesOptionList)
    }

    optionsList (optionList) {
        const priorityCountries = _t.getSetting('priorityCountries', [])
        const priorityOptions = _.filter(optionList, o => priorityCountries.indexOf(o.value) > -1)

        priorityOptions.sort((a, b) => priorityCountries.indexOf(a.value) - priorityCountries.indexOf(b.value))
        return priorityOptions.length > 0 ? [].concat(
            {text: '---', value: ''},
            priorityOptions,
            {text: '---', value: ''},
            optionList.filter(option => !priorityOptions.some(prioOption => prioOption.value === option.value))
        ) : optionList
    }
}
