import Reflux from 'reflux'
import actions from '../actions'
import UserAwareMixin from '../mixin/user-aware-mixin'

export default Reflux.createStore({

    listenables: actions,
    mixins: [UserAwareMixin],

    init () {
        this._resetData()

        this.listenTo(actions.getPaymentMethods, this._startLoading)
        this.listenTo(actions.createBooking.completed, this.resetData)
        this.listenTo(actions.myS3RebookCreateBooking, this.resetData)
    },

    _resetData () {
        this.data = {
            loading: false,
            availableMethods: null,
            errorText: null
        }
    },

    resetData () {
        this._resetData()
        this.trigger(this.data)
    },

    getInitialState () {
        return this.data
    },

    _startLoading () {
        this.data.loading = true
        this.trigger(this.data)
    },

    onGetPaymentMethodsCompleted (availableMethods) {
        this.data.loading = false
        // BBB-10605, APIPAYMENT is not allowed in the FE, it's for the BBB App.
        // eslint-disable-next-line camelcase
        this.data.availableMethods = (availableMethods || []).filter(({method_code}) => method_code && method_code.toUpperCase() !== 'APIPAYMENT')
        this.data.errorText = null
        this.trigger(this.data)
    },

    onGetPaymentMethodsFailed (error) {
        this.data.loading = false
        this.data.availableMethods = null
        this.data.errorText = error
        this.trigger(this.data)
    },

    getPaymentMethodByCode (code) {
        return (this.data.availableMethods || []).find(method => method.code === code)
    },

    getPaymentMethodsByFlowType (flowType) {
        return (this.data.availableMethods || []).filter(method => method.flow_type === flowType)
    },

    getFirstPaymentMethodWithFlowType (flowType) {
        return (this.data.availableMethods || []).find(method => method.flow_type === flowType)
    }
})
