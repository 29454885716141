import Reflux from 'reflux'
import actions from '../../reflux/actions'

export default Reflux.createStore({

    listenables: actions,

    init () {
        this._resetData()

        this.listenTo(actions.createBooking.completed, this.resetData)
        this.listenTo(actions.getServices.completed, this.onGetServicesCompleted)
    },

    _resetData () {
        this.data = {}
    },

    resetData () {
        this._resetData()
        this.trigger(this.data)
    },

    getData () {
        return this.data
    },

    getInitialState () {
        return this.data
    },

    hasAlreadyStored (date, departureStation, arrivalStation, serviceType, serviceName) {
        return this.data.services && this.data.services.some(service => {
            const arrivalStationExists = service.via_stations
                .some(station => station._u_i_c_station_code === arrivalStation)
            const departureStationExists = service.via_stations
                .some(station => station._u_i_c_station_code === departureStation)
            return service.name === serviceName &&
                service.schedule_date === date &&
                service.service_type.code === serviceType &&
                (service.arrival_station._u_i_c_station_code === arrivalStation || arrivalStationExists) &&
                (service.departure_station._u_i_c_station_code === departureStation || departureStationExists)
        })
    },

    onGetServicesCompleted (response) {
        this.data = response.data
        this.trigger(this.data)
    }

})
