import Reflux from 'reflux'
import actions from '../actions'
import UserAwareMixin from '../mixin/user-aware-mixin'
import cookieCutter from 'cookie-cutter'
import moment from 'moment-timezone'
import AccessManager from '../../data/access-manager'

export const REACH_5_SSO_COOKIE_NAME = 'crm_user_id'

export default Reflux.createStore({

    listenables: actions,

    mixins: [UserAwareMixin],

    init () {
        this._resetData()

        // Re-evaluate loggedIn status
        this.listenTo(actions.loadUserLoginInformation.completed, this._setLoggedIn)
        this.listenTo(actions.loadCrmUserInformation.completed, this._setLoggedIn)

        this.listenTo(actions.loadUserLoginInformation.failed, this._setLoggedIn)
        this.listenTo(actions.loadCrmUserInformation.failed, this._setLoggedIn)

        this.listenTo(actions.crmLogin.completed, this._setLoggedIn)
        this.listenTo(actions.bookingLogin.completed, this._setLoggedIn)
        this.listenTo(actions.bookingLoginByPassenger.completed, this._setLoggedIn)

        this.listenTo(actions.crmLogin.failed, this._setLoggedIn)
        this.listenTo(actions.bookingLogin.failed, this._setLoggedIn)
        this.listenTo(actions.bookingLoginByPassenger.failed, this._setLoggedIn)

        this.listenTo(actions.logout.completed, this._setLoggedIn)
        this.listenTo(actions.logout.failed, this._setLoggedIn)
    },

    _userLoggedIn () {
        return AccessManager.isAuthenticated() && !AccessManager.isPublicUser()
    },

    _setLoggedIn () {
        const _loggedIn = !AccessManager.isPublicUser()
        if (this.data.userLoggedIn !== _loggedIn) {
            this.data.userLoggedIn = _loggedIn
            this.trigger(this.data)
        }
    },

    _resetData () {
        this.data = {
            loading: false,
            success: true,
            error: '',
            idToken: null,
            idTokenPayload: null,
            accessToken: null,
            userInformation: null,
            userLoggedIn: this._userLoggedIn()
        }
    },

    resetData () {
        this._resetData()
        this.trigger(this.data)
    },

    getInitialState () {
        return this.data
    },

    getTokenInformation () {
        const {idToken, idTokenPayload, accessToken} = this.data
        return {
            idToken,
            idTokenPayload,
            accessToken
        }
    },

    getIdToken () {
        return this.data.idToken || cookieCutter.get(REACH_5_SSO_COOKIE_NAME)
    },

    hasIdToken () {
        return Boolean(this.getIdToken())
    },

    getDomain () {
        return document.domain.split('.').slice(-2).join('.')
    },

    setIdTokenCookie () {
        cookieCutter.set(REACH_5_SSO_COOKIE_NAME, this.data.idToken, {
            domain: this.getDomain(),
            path: '/',
            secure: true,
            expires: moment().add(30, 'days').toDate()
        })
    },

    clearIdTokenCookie () {
        cookieCutter.set(REACH_5_SSO_COOKIE_NAME, '', {
            domain: this.getDomain(),
            path: '/',
            secure: true
        })
    },

    hasPendingRequest () {
        return this.data.loading
    },

    onAuthenticate () {
        this.data.loading = true
        this.data.success = true
        this.data.error = ''
        this.data.idToken = null
        this.data.idTokenPayload = null
        this.data.accessToken = null
        this.data.userLoggedIn = this._userLoggedIn()

        this.trigger(this.data)
    },

    onAuthenticateCompleted (data) {
        this.data.loading = false
        this.data.idToken = data.idToken
        this.data.idTokenPayload = data.idTokenPayload
        this.data.accessToken = data.accessToken
        this.setIdTokenCookie()

        this.trigger(this.data)
    },

    onAuthenticateFailed (error) {
        this.data.loading = false
        this.data.success = false
        this.data.error = error
        this.data.idToken = null
        this.data.idTokenPayload = null
        this.data.accessToken = null
        this.data.userLoggedIn = this._userLoggedIn()

        this.clearIdTokenCookie()
        this.trigger(this.data)
    }
})
