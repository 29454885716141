import PropTypes from 'prop-types'
import React from 'react'
import Reflux from 'reflux'
import moment from 'moment-timezone'
import _t from '../../translate'
import classNames from 'classnames'
import TextLabel from '../../element/text-label'
import Icon from '../../element/icon'
import CalendarStore from '../../reflux/stores/calendar-store'
import BookingStore from '../../reflux/stores/booking-store'
import BookingModel from '../../models/booking-model'
import {dateStringToLocalizedDate} from '../../misc/date'
import {getNewJourneySearchSelector} from '../../models/selectors/components/orientation/journey-search'
import {TRAVEL_DIRECTION_INBOUND, TRAVEL_DIRECTION_OUTBOUND} from '../../models/selectors/constants'
import OfferStore from '../../reflux/stores/offer-store'
import createReactClass from 'create-react-class'

export default createReactClass({

    displayName: 'JourneyResultSetDateSwitcher',

    mixins: [
        Reflux.connectFilter(CalendarStore, 'calendars', data => data.calendars),
        Reflux.connectFilter(BookingStore, 'booking', data => BookingModel.create(data.booking))
    ],

    propTypes: {
        travel: PropTypes.shape({
            direction: PropTypes.string,
            departureDate: PropTypes.any
        }).isRequired,
        routes: PropTypes.arrayOf(PropTypes.object),
        onSearch: PropTypes.func.isRequired,
        direction: PropTypes.string
    },

    _onDateChange (subtract) {
        const nextTravelDate = this.props.travel.departureDate.add(subtract ? -1 : 1, 'day')
        this.props.onSearch(getNewJourneySearchSelector(nextTravelDate, this.props.direction)(nextTravelDate))
    },

    _onPrevDay (event) {
        event.preventDefault()
        this._onDateChange(true)
    },

    _onNextDay (event) {
        event.preventDefault()
        this._onDateChange(false)
    },

    _disablePrevious () {
        // Don't allow to go back in the past
        if (moment().isAfter(this._getDepartureDateWithoutTime())) {
            return true
        }

        // If there is a booking (Aftersales) and the user only searches for a new inbound.
        // Restrict the user for going before the orginal outbound departure date
        if (this.state.booking !== null &&
            !OfferStore.hasDepartureDate() &&
            this.props.travel.direction === TRAVEL_DIRECTION_INBOUND
        ) {
            const prevDay = moment(this.props.travel.departureDate).subtract(1, 'days')

            return prevDay.isBefore(this.state.booking.outboundTravelDate)
        }

        return false
    },

    _disableNext () {
        // If there is a booking (Aftersales) and the user only searches for a new outbound.
        // Restrict the user for going after the orginal inbound departure date if it's a return trip
        if (this.state.booking !== null &&
            this.state.booking.inboundTravelDate &&
            !OfferStore.hasReturnDate() &&
            this.props.travel.direction === TRAVEL_DIRECTION_OUTBOUND
        ) {
            const nextDay = moment(this.props.travel.departureDate).add(1, 'days')

            return nextDay.isAfter(this.state.booking.inboundTravelDate)
        }

        return false
    },

    render () {
        const departureDate = this._getDepartureDateWithoutTime()

        return (
            <div className='journey-result-date-switcher'>
                <div className='grid-row no-gutter'>
                    <div className='grid-column--1-3'>
                        {this.renderPrevious(this._disablePrevious())}
                    </div>
                    <div className='grid-column--1-3'>
                        <div className='date-switcher-item current text-align-center'>
                            {this.renderDateLabel(departureDate)}
                            {this.renderCheapestPrice(moment(departureDate))}
                        </div>
                    </div>
                    <div className='grid-column--1-3'>
                        {this.renderNext(this._disableNext())}
                    </div>
                </div>
            </div>
        )
    },

    _getDepartureDateWithoutTime () {
        const date = this.props.travel.departureDate
        return moment(date).format('YYYY-MM-DD')
    },

    renderNext (disabled) {
        const dayAfterDepartureDate = moment(this._getDepartureDateWithoutTime()).add(1, 'days')

        const renderIcon = () => [
            this.renderDateLabel(dayAfterDepartureDate.format('YYYY-MM-DD')),
            this.renderCheapestPrice(dayAfterDepartureDate),
            <Icon className='chevron-right next small' type='chevron-right' key='icon' />
        ]

        const topDivClassNames = classNames(
            'date-switcher-item', 'next', 'text-align-center', {disabled: disabled}
        )

        return (
            <div className={topDivClassNames}>
                {disabled ? renderIcon() : <a href='#' onClick={this._onNextDay} className='link'>{renderIcon()}</a>}
            </div>
        )
    },

    renderPrevious (disabled) {
        const dayBeforeDepartureDate = moment(this._getDepartureDateWithoutTime()).subtract(1, 'days')

        const renderIcon = () => [
            <Icon className='chevron-left previous small' type='chevron-left' key='icon' />,
            this.renderDateLabel(dayBeforeDepartureDate.format('YYYY-MM-DD')),
            this.renderCheapestPrice(dayBeforeDepartureDate)
        ]

        const topDivClassNames = classNames(
            'date-switcher-item', 'previous', 'text-align-center', {disabled: disabled}
        )

        return (
            <div className={topDivClassNames}>
                {disabled ? renderIcon() : <a href='#' onClick={this._onPrevDay} className='link'>{renderIcon()}</a>}
            </div>
        )
    },

    renderDateLabel (date) {
        date = dateStringToLocalizedDate(date)
        const weekdayLabel = this.renderLabel(
            _t.formatDate(date, 'full'),
            `${_t.formatDate(date, 'shortWeekday')} ${_t.formatDate(date, 'dateOnly')}`,
            'weekday-date'
        )
        const monthLabel = this.renderLabel(_t.formatDate(date, 'full'), _t.formatDate(date, 'shortMonth'), 'month')

        return <span key='date-label'>{weekdayLabel}{monthLabel}</span>
    },

    renderLabel (title, text, extraClass) {
        return (
            <span key={extraClass} className={classNames('text-label date ', extraClass)} title={title}>
                <TextLabel text={text} />
            </span>
        )
    },

    renderCheapestPrice (date) {
        let price
        if (date.isSame(this._getDepartureDateWithoutTime(), 'day')) {
            price = this.props.routes.reduce((minPrice, route) => {
                const minBundlePrice = route.bundles.reduce(
                    (min, bundle) => bundle.price < min ? bundle.price : min,
                    Number.MAX_VALUE
                )
                return minBundlePrice < minPrice ? minBundlePrice : minPrice
            }, Number.MAX_VALUE)
        } else {
            const dateKey = date.format('YYYY-MM-DD')
            price = this.state.calendars[this.props.direction] &&
                this.state.calendars[this.props.direction][dateKey] &&
                this.state.calendars[this.props.direction][dateKey].amount
        }

        return price > 0 && price < Number.MAX_VALUE ? (
            <div key='cheapest-price'>
                <span className='text-label base-price'>
                    <TextLabel text={_t.getIntlMessage('date-switcher.from')} />
                </span>
                <span className='text-label base-price-value'>
                    <TextLabel text={_t.formatCurrency(price)} />
                </span>
            </div>
        ) : <div className='price-placeholder' key='cheapest-price' />
    }

})
