// Libraries
import React from 'react'
import {Route} from 'react-router'

import authCheck from '../../../mixins/auth'

// Layouts
import RebookingOrientationOfferLayout from '../../../layouts/mys3/re-booking/orientation-offer'

export default (
    <Route path='/:lang/mys3/re-booking/offer'
           component={RebookingOrientationOfferLayout}
           onEnter={(nextState, replaceState, callback) => authCheck(nextState, replaceState, callback)}
        />
)
