import PropTypes from 'prop-types'
import React, { Component } from 'react'
import progressNavigation from './progress-navigation'
import {withRouter} from 'react-router'

class progressNavigationContainer extends Component {
    static propTypes = {
        previousButtonRow: PropTypes.object,
        nextButtonRow: PropTypes.object,
        previousButtonEnabled: PropTypes.bool.isRequired
    }

    render () {
        const {previousButtonRow, nextButtonRow, previousButtonEnabled} = this.props
        return (
            <div className='progress-navigation'>
                {previousButtonEnabled
                    ? (
                        <div className='grid-row'>
                            <div className='grid-column--1-2 grid-column--medium-1-4'>
                                {previousButtonRow}
                            </div>
                            <div className='grid-column--1-2 grid-column--medium-2-4 offset-grid-column--medium-1-4'>
                                {nextButtonRow}
                            </div>
                        </div>
                    )
                    : (
                        <div className='grid-row'>
                            <div className='grid-column--1-1 grid-column--medium-2-4 offset-grid-column--medium-2-4'>
                                {nextButtonRow}
                            </div>
                        </div>
                    )
                }
            </div>
        )
    }
}

export default withRouter(progressNavigation(progressNavigationContainer))
