import PropTypes from 'prop-types'
import React, {Component} from 'react'
import _ from 'lodash'
import BookingStore from '../../../../reflux/stores/booking-store'
import PaymentTerms from '../payment-terms'
import TabContent from '../../../../element/tab-content'
import NumberField from '../../../../element/form/number-field'
import actions from '../../../../reflux/actions'

export default class CashInvoice extends Component {
    static propTypes = {
        formData: PropTypes.object.isRequired,
        paymentAmount: PropTypes.number
    }

    constructor (...args) {
        super(...args)
        this._numberFieldProps = this._numberFieldProps.bind(this)
    }

    componentDidMount () {
        actions.processPaymentData([
            {id: 'cashAmount', value: BookingStore.getBookingModel().pendingTotalPriceToBePaid.toString()}
        ])
    }

    render () {
        return (
            <TabContent visible>
                <form action='#' noValidate onSubmit={this.onSubmit}>
                    <fieldset>
                        <div className='grid-row'>
                            <div className='grid-column--1-1 grid-column--medium-1-2'>
                                <NumberField
                                    {...this._numberFieldProps('cashAmount')}
                                    key={this.props.paymentAmount}
                                />
                            </div>
                        </div>
                    </fieldset>
                </form>
                <PaymentTerms />
            </TabContent>
        )
    }

    onSubmit (event) {
        event.preventDefault()
    }

    _prefixFieldLabel (field, label) {
        return label ? `payment.form.${field}.${label}` : null
    }

    _numberFieldProps (fieldName) {
        let kebabCaseFieldName = _.kebabCase(fieldName)
        return {
            id: fieldName,
            ref: fieldName,
            max: this.props.paymentAmount,
            min: 0,
            decimals: 2,
            className: kebabCaseFieldName,
            labelText: this._prefixFieldLabel(kebabCaseFieldName, 'label'),
            placeholder: this._prefixFieldLabel(kebabCaseFieldName, 'placeholder'),
            defaultValue: this.props.paymentAmount,
            required: this.props.formData.fields[fieldName].isRequired,
            errorText: this._prefixFieldLabel(kebabCaseFieldName, this.props.formData.fields[fieldName].errorText),
            onBlur: this.onBlur,
            onChange: this.onChange
        }
    }

    onBlur (event) {
        const {id, value} = event.target
        actions.processPaymentData([{id, value}])
    }

    onChange (event) {
        const {id, value} = event.target
        actions.processPaymentData([{id, value}])
    }
}
