import PropTypes from 'prop-types'
import React, { Component } from 'react'
import classNames from 'classnames'
import _t from '../../translate'
import TextLabel from '../../element/text-label'
import {
    hasManualSeatPlusProperty,
    isSeatSelectionPropertyCode
} from '../../misc/helpers'
import Icon from '../../element/icon'

export default class Seat extends Component {
    constructor (...args) {
        super(...args)

        this.handleClick = this.handleClick.bind(this)
    }

    static propTypes = {
        seat: PropTypes.shape({
            available: PropTypes.bool.isRequired,
            reversed: PropTypes.bool.isRequired,
            seat_number: PropTypes.string.isRequired,
            x_pos: PropTypes.number.isRequired,
            y_pos: PropTypes.number.isRequired,
            property_codes: PropTypes.array.isRequired
        }).isRequired,
        isSelected: PropTypes.bool.isRequired,
        isSelectedBySelectedPassenger: PropTypes.bool,
        onSelectSeat: PropTypes.func.isRequired,
        onRemoveSeat: PropTypes.func.isRequired,
        readOnly: PropTypes.bool.isRequired
    }

    handleClick () {
        if (!this.props.readOnly && this.props.seat.available) {
            !this.props.isSelected ? this.props.onSelectSeat(this.props.seat) : this.props.onRemoveSeat(this.props.seat)
        }
    }

    render () {
        const {
            seat,
            isSelected,
            readOnly
        } = this.props

        const selectionAvailable = seat.property_codes.some(isSeatSelectionPropertyCode)
        const isAvailable = !readOnly && !isSelected && seat.available && selectionAvailable
        const isUnAvailable = !readOnly && !isSelected && (!seat.available || !selectionAvailable)

        const status = isSelected
            ? 'selected'
            : isAvailable
                ? 'available'
                : 'unavailable'

        const props = {
            key: seat.seat_number,
            title: _t.formatIntlMessage(`seat-layout.seat.${status}`, {seatNumber: seat.seat_number}),
            style: {
                left: `${seat.x_pos}px`,
                top: `${seat.y_pos}px`
            },
            className: classNames(
                'seat',
                {'seat-plus': hasManualSeatPlusProperty(seat)},
                {reversed: seat.reversed},
                {'state--is-seat-selected': isSelected},
                {'state--is-seat-selected-passenger': this.props.isSelectedBySelectedPassenger},
                {'state--is-seat-unavailable': isUnAvailable},
                {'state--is-seat-available': isAvailable},
                {readonly: readOnly}
            ),
            tabIndex: readOnly
                ? null
                : seat.available && !isSelected
                    ? '0'
                    : '-1',
            onClick: !readOnly && selectionAvailable
                ? this.handleClick
                : null
        }

        return (
            <div {...props}>
                <span className='text-label text-label-seat-number'>
                    <TextLabel text={seat.seat_number} />
                </span>
                <Icon className='seat-stroke' type='seat-stroke' />
                <Icon className='seat-inner' type='seat-inner' />
            </div>
        )
    }
}
