import Reflux from 'reflux'
import UserAwareMixin from '../../reflux/mixin/user-aware-mixin'
import _ from 'lodash'
import _t from '../../translate'
import ResponseCodes from '../../api/response-codes'

import actions from '../../reflux/actions'

export default Reflux.createStore({

    listenables: actions,

    mixins: [UserAwareMixin],

    severity: {
        success: 'success',
        info: 'information',
        highlight: 'highlight',
        warning: 'warning',
        error: 'error'
    },

    excludedErrorCodes: [
        ResponseCodes.BOOKING_VOUCHER_DOES_NOT_EXISTS,
        ResponseCodes.BOOKING_VOUCHER_REFUND_NO_ELIGIBLE_TARIFF,
        ResponseCodes.BOOKING_VOUCHER_COMPENSATION_NO_ELIGIBLE_TARIFF,
        ResponseCodes.BOOKING_VOUCHER_PREPAID_NO_ELIGIBLE_TARIFF
    ],

    init () {
        this._resetData()

        this.listenTo(actions.hideAllFlashMessages, this.resetData)
    },

    _resetData () {
        this.data = []
    },

    resetData () {
        this._resetData()
        this.trigger(this.data)
    },

    getInitialState () {
        return this.getFlashMessages()
    },

    getFlashMessages () {
        return this.data
    },

    onShowFlashMessage (message) {
        if (!_.isObject(message)) {
            message = {text: String(message)}
        }
        this.refreshData()

        const id = _.uniqueId()
        this.data.push({
            id: id,
            text: _.get(message, 'text', ''),
            severity: _.get(message, 'severity', this.severity.success)
        })

        if (_.has(message, 'lifetime')) {
            setTimeout(() => this.onHideFlashMessage(id), message.lifetime * 1000)
        }

        this.trigger(this.data)
    },

    onHideFlashMessage (id) {
        this.refreshData()
        _.remove(this.data, notification => notification.id === id)
        this.trigger(this.data)
    },

    refreshData () {
        // make a shallow copy, so that changes can be detected between the previous and new state
        this.data = _.clone(this.data)
    },

    onRequestRefundVoucherCompleted (response) {
        this.onShowFlashMessage(
            _t.formatIntlMessage(
                'flash-message.request-refund-voucher-completed',
                {voucherCode: response.data.voucher.code}
            )
        )
    },

    onRequestRefundPspCompleted () {
        this.onShowFlashMessage(
            _t.getIntlMessage('flash-message.request-refund-psp-completed')
        )
    },

    onMyS3RebookSuccessful () {
        this.onShowFlashMessage(_t.getIntlMessage('flash-message.mys3-re-book-successful'))
    },

    onMyS3CancellationSuccessful () {
        this.onShowFlashMessage(_t.getIntlMessage('flash-message.mys3-cancellation-successful'))
    },

    onMyS3UpdatePassengersSuccessful () {
        this.onShowFlashMessage(_t.getIntlMessage('flash-message.mys3-update-passengers-successful'))
    },

    onMyS3SeatChangeSuccessful () {
        this.onShowFlashMessage(_t.getIntlMessage('flash-message.mys3-update-seat-change-successful'))
    },

    onResendTicketsCompleted () {
        this.onShowFlashMessage(_t.getIntlMessage('resend-tickets.success'))
    },

    onApiError (error) {
        if (
            ResponseCodes.VOUCHER_ALREADY_USED === error.code ||
            ResponseCodes.VOUCHER_NOT_FOUND === error.code
        ) {
            return
        }

        const isPaymentRequest = error.request &&
            error.request.method === 'POST' &&
            error.request.url.slice(-7) === 'payment'

        if (!error.code && isPaymentRequest) {
            error.flashMessage = {
                text: _t.getIntlMessage('flash-message.payment-timeout'),
                severity: this.severity.error
            }
        }

        if (ResponseCodes.hasMessage(error.code) && !this.excludedErrorCodes.includes(error.code)) {
            error.flashMessage = {
                text: ResponseCodes.translate(error.code),
                severity: this.severity.error
            }
        }
    }
})
