import PropTypes from 'prop-types'
import React, {Component} from 'react'
import Reflux from 'reflux'
import _t from '../../translate'
import TextLabel from '../../element/text-label'
import Select from '../../element/select'
import Loader from '../../element/loader'
import UserStore from '../../reflux/stores/user-store'
import BookingStore from '../../reflux/stores/booking-store'
import BookingModel from '../../models/booking-model'
import {
    SALES_CHANNEL_PROPERTY_CANCELLATION_REASONS,
    CMS_BLOCK_CANCEL_REASON_PREFIX,
    DEFAULT_OPTION_CMS_BLOCK_NAME
} from '../../constants'
import cmsComponent from '../cms/cms-component'
import createReactClass from 'create-react-class'

class CancelReasonSelect extends Component {
    static propTypes = {
        cancelReason: PropTypes.string,
        onSelectCancelReason: PropTypes.func.isRequired,
        cancelReasons: PropTypes.arrayOf(PropTypes.shape({
            text: PropTypes.string.isRequired,
            value: PropTypes.string
        })).isRequired
    }

    render () {
        return (
            <div className='callout cancel-reason'>
                <span className='text-label heading'>
                    <TextLabel text={_t.message('my-s3-cancellation.cancel-reason.header')} />
                </span>
                <Select
                    value={this.props.cancelReason}
                    data={this.props.cancelReasons}
                    name='cancel-reason'
                    id='cancel-reason'
                    className='cancel-reason'
                    onChange={this.props.onSelectCancelReason}
                />
            </div>
        )
    }
}

class CancelReasonCmsBlocks extends Component {
    static propTypes = {
        cmsContentLoading: PropTypes.bool.isRequired,
        cmsBlocks: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string,
            content: PropTypes.string
        })),
        cancellationReasons: PropTypes.array
    }

    constructor (...args) {
        super(...args)
        this._getCancelReasons = this._getCancelReasons.bind(this)
        this._getBlockNames = this._getBlockNames.bind(this)
    }

    render () {
        if (this.props.cmsContentLoading) {
            return <Loader />
        }

        const cancelReasons = this._getCancelReasons()
        if (cancelReasons.length <= 1) {
            return null
        }

        return <CancelReasonSelect {...this.props} cancelReasons={this._getCancelReasons()} />
    }

    _getCancelReasons () {
        const defaultCmsBlock = this.props.cmsBlocks.find(cmsBlock =>
            cmsBlock.name === DEFAULT_OPTION_CMS_BLOCK_NAME)
        const defaultOption = {
            text: (defaultCmsBlock ? defaultCmsBlock.content : _t.message('my-s3-cancellation.cancel-reason.select')),
            value: ''
        }

        return [defaultOption].concat(this._getBlockNames().reduce((options, blockName) => {
            const cmsBlock = this.props.cmsBlocks.find(cmsBlock => cmsBlock.name === blockName)
            if (cmsBlock) {
                options.push({
                    text: cmsBlock.content,
                    value: blockName.replace(CMS_BLOCK_CANCEL_REASON_PREFIX, '')
                })
            }

            return options
        }, []))
    }

    _getBlockNames () {
        return this.props.cancellationReasons.map(reason => `${CMS_BLOCK_CANCEL_REASON_PREFIX}${reason}`)
    }
}

export default createReactClass({

    displayName: 'CancelReason',

    mixins: [
        Reflux.connectFilter(UserStore, 'cancellationReasons', data => {
            if (data.user && data.user.sales_channel_properties[SALES_CHANNEL_PROPERTY_CANCELLATION_REASONS]) {
                return data.user && data.user.sales_channel_properties[SALES_CHANNEL_PROPERTY_CANCELLATION_REASONS]
            }
            return []
        }),
        Reflux.connectFilter(BookingStore, 'booking', data => BookingModel.create(data.booking))
    ],

    componentWillMount () {
        this.Component = cmsComponent(...this._getBlockNames())(CancelReasonCmsBlocks)
    },

    _getBlockNames () {
        return [DEFAULT_OPTION_CMS_BLOCK_NAME].concat(
            this.state.cancellationReasons.map(reason => `${CMS_BLOCK_CANCEL_REASON_PREFIX}${reason}`)
        )
    },

    render () {
        if (this.state.cancellationReasons.length === 0 || !this.Component) {
            return null
        }
        const Component = this.Component

        return <Component {...this.props} cancellationReasons={this.state.cancellationReasons} />
    }
})
