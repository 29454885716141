import PropTypes from 'prop-types'
import React from 'react'
import Reflux from 'reflux'
import {withRouter} from 'react-router'
import _t from '../translate'
import defer from 'lodash/defer'
import jquery from 'jquery'
import device from '../device'
import GenericLayout from '../layouts/generic-layout'
import orientationOfferLayout from '../layouts/orientation-offer-layout'
import Row from '../element/row'
import Button from '../element/button'
import Loader from '../element/loader'
import ModalDialog from '../element/modal-dialog'
import Price from '../components/journey-result-set/price'
import cmsComponent from '../components/cms/cms-component'
import JourneySearchContainer from '../components/journey-search-container'
import JourneyResultSetContainer from '../components/journey-result-set-container'
import ProgressNavigation from '../components/orientation-offer/progress-navigation'
import CalendarStore from '../reflux/stores/calendar-store'
import BookingStore from '../reflux/stores/booking-store'
import actions from '../reflux/actions'
import HelpButton from '../base/help-button'
import restoreJourneySearch from '../components/restore/restore-journey-search'
import {
    getInboundOriginDestinationSelector,
    getOutboundOriginDestinationSelector
} from '../models/selectors/components/orientation/journey-search'
import {getState} from '../reflux/bridge/connect-state'
import createReactClass from 'create-react-class'

const component = orientationOfferLayout(createReactClass({

    displayName: 'OrientationOfferLayoutContainer',

    mixins: [
        Reflux.connectFilter(BookingStore, 'bookingLoading', data => data.loading)
    ],

    propTypes: {
        travels: PropTypes.arrayOf(PropTypes.object).isRequired,
        selectedBundles: PropTypes.arrayOf(PropTypes.object).isRequired,
        isInboundBeforeOutbound: PropTypes.func.isRequired,
        isOutboundOriginDifferentThanInboundDestination: PropTypes.func.isRequired,
        onOptionClick: PropTypes.func.isRequired,
        onCreateBooking: PropTypes.func.isRequired,
        affiliateCode: PropTypes.string,
        hasToken: PropTypes.bool.isRequired,
        router: PropTypes.object,
        hasOffer: PropTypes.bool
    },

    getInitialState () {
        return {
            showErrorDialog: false,
            showStationConfirmDialog: false
        }
    },

    render () {
        return this.props.hasToken ? (
            <GenericLayout
                className='orientation-offer'
                showSalesHeader
                hasOffer={this.props.hasOffer}
                onNext={this.onNext}
                bookingLoading={this.state.bookingLoading}
                showSummaryBar
            >
                <Row className='row'>
                    <div className='grid-row'>
                        <div className='grid-column--1-1 grid-column--medium-1-3'>
                            <JourneySearchContainer
                                showCollapsed={!device.isDesktopOrTablet() && this.props.hasOffer}
                            />
                            {!device.isMobile() ? <HelpButton /> : null}
                        </div>
                        <div className='grid-column--1-1 grid-column--medium-2-3'>
                            <JourneyResultSetContainer
                                travels={this.props.travels}
                                hasOffer={this.props.hasOffer}
                                affiliateCode={this.props.affiliateCode}
                                selectedBundles={this.props.selectedBundles}
                                onOptionClick={this.onOptionClick}
                                onSearch={this.onSearch}
                            />
                            <Price />
                            <ProgressNavigation nextButtonProps={{
                                id: 'orientation-offer-next',
                                loading: this.state.bookingLoading,
                                onClick: this.onNext,
                                className: 'button next primary',
                                icon: {
                                    type: 'chevron-right',
                                    className: 'xsmall align-right'
                                }
                            }} />
                            {device.isMobile() ? <HelpButton /> : null}
                        </div>
                    </div>
                </Row>
                {this._renderErrorDialog()}
                {this._renderStationConfirmDialog()}
                {this.props.children}
            </GenericLayout>
        ) : <Loader blocking />
    },

    onNext () {
        if (this.props.isInboundBeforeOutbound()) {
            this.setState({showErrorDialog: true})
        } else if (this.props.isOutboundOriginDifferentThanInboundDestination()) {
            this.setState({showStationConfirmDialog: true})
        } else {
            this.createBooking()
        }
    },

    createBooking () {
        this.props.onCreateBooking().then(() => {
            this.props.router.push(`/${_t.getLocales()}/booking/passenger-details`)
        })
    },

    _renderErrorDialog () {
        if (!this.state.showErrorDialog) {
            return null
        }

        return (
            <ModalDialog
                headerTitle={_t.getIntlMessage('journey-result-set.inbound-before-outbound-dialog.title')}
                onClose={() => this.setState({showErrorDialog: false})}>
                <div className='content'>
                    <p>{_t.getIntlMessage('journey-result-set.inbound-before-outbound-dialog.content')}</p>
                </div>
            </ModalDialog>
        )
    },

    _renderStationConfirmDialog () {
        if (!this.state.showStationConfirmDialog) {
            return null
        }

        let props = {
            text: _t.getIntlMessage('journey-result-set.station-confirm-dialog.button.confirm'),
            className: 'button primary next',
            onClick: () => this.setState({showStationConfirmDialog: false}, () => this.createBooking())
        }

        const outboundOriginDestination = getOutboundOriginDestinationSelector(getState())
        const inboundOriginDestination = getInboundOriginDestinationSelector(getState())

        return (
            <ModalDialog
                headerTitle={_t.getIntlMessage('journey-result-set.station-confirm-dialog.title')}
                onClose={() => this.setState({showStationConfirmDialog: false})}
            >
                <div className='content station-confirm-dialog'>
                    <p>{_t.getIntlMessage('journey-result-set.station-confirm-dialog.content')}</p>
                    <ul>
                        <li>
                            {_t.formatIntlMessage('journey-result-set.station-confirm-dialog.outbound', {
                                departureStationName: outboundOriginDestination.departureStation.name,
                                arrivalStationName: outboundOriginDestination.arrivalStation.name
                            })}
                        </li>
                        <li>
                            {_t.formatIntlMessage('journey-result-set.station-confirm-dialog.inbound', {
                                departureStationName: inboundOriginDestination.departureStation.name,
                                arrivalStationName: inboundOriginDestination.arrivalStation.name
                            })}
                        </li>
                    </ul>
                    <div className='button-container'>
                        <Button {...props} />
                        <div>
                            <a href='#' onClick={() => this.setState({showStationConfirmDialog: false})}>
                                {_t.getIntlMessage('journey-result-set.station-confirm-dialog.button.cancel')}
                            </a>
                        </div>
                    </div>
                </div>
            </ModalDialog>
        )
    },

    onSearch (journeySelection) {
        actions.clearBundles()
        actions.getOffer(journeySelection)
        actions.getCalendar(CalendarStore.createDateSwitcherRequestData(journeySelection))
    },

    onOptionClick (bundleId) {
        this.props.onOptionClick(bundleId)
        const hasInbound = this.props.travels.some(travel => travel.direction === 'inbound')
        if (!device.isDesktopOrTablet()) {
            defer(() => {
                const element = jquery(`.journey-result-set .${hasInbound ? 'inbound' : 'outbound'}`)
                if (element && element.offset) {
                    jquery('html, body').animate({scrollTop: element.offset().top}, 300)
                }
            })
        }
    }
}))

export default withRouter(cmsComponent(
    'adult-information',
    'baby-information',
    'child-information',
    'young-information',
    'banner-default',
    'banner-sncb',
    'banner-vsc'
)(restoreJourneySearch(component)))
