import React, {Component} from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import device from '../../device'
import _t from '../../translate'
import TextLabel from '../../element/text-label'
import Header from './list/header'
import DateSwitcher from './date-switcher'
import CurrencySelector from './currency-selector'
import RouteSelector from './route-selector'
import Sorter from './sorter'
import JourneySearchManager from '../../data/journey-search-manager'

export default class JourneyResultSetListContainer extends Component {
    static propTypes = {
        travel: PropTypes.object,
        journeySearch: PropTypes.object,
        sortBy: PropTypes.string.isRequired,
        onChangeSortBy: PropTypes.func.isRequired,
        onChangeTransfersFilter: PropTypes.func.isRequired,
        routeTransfersFilterValue: PropTypes.string,
        onSearch: PropTypes.func.isRequired,
        routes: PropTypes.array
    }

    static defaultProps = {
        routes: []
    }

    hasMultiSegmentAndDirectRoutes (routes) {
        return routes.some(route => route.transfers.length > 0) && routes.some(route => route.transfers.length === 0)
    }

    checkRoutesAndUpdateFilter (props) {
        if (this.hasMultiSegmentAndDirectRoutes(props.routes) && props.routeTransfersFilterValue === null) {
            props.onChangeTransfersFilter('all')
        }
    }

    componentWillReceiveProps (nextProps) {
        this.checkRoutesAndUpdateFilter(nextProps)
    }

    componentDidMount () {
        this.checkRoutesAndUpdateFilter(this.props)
    }

    render () {
        if (!this.props.travel) {
            return null
        }

        const isOutbound = this.props.travel.direction === 'outbound'
        const classes = classNames('journey-result-list', {
            inbound: !isOutbound,
            outbound: isOutbound
        })

        return (
            <div className={classes}>
                <div className='panel plain'>
                    <Header travel={this.props.travel} />
                    <div className='content'>
                        {this.renderFilter()}
                        <DateSwitcher
                            travel={this.props.travel}
                            direction={this.props.travel.direction}
                            routes={this.props.routes}
                            onSearch={this.props.onSearch}
                        />
                        {this._renderJourneyResultHeader()}
                        {this.props.children}
                    </div>
                </div>
            </div>
        )
    }

    _renderJourneyResultHeader () {
        if (!device.isDesktopOrTablet()) {
            return null
        }

        return (
            <div className='journey-result-header'>
                <div className='grid-row no-gutter'>
                    <div className='grid-column--8-12'>
                        <div className='grid-row no-gutter'>
                            <div className='grid-column--2-9'>
                                {this._renderColumnHeader('departure', 'departure-description', 'departureTime')}
                            </div>
                            <div className='grid-column--2-9'>
                                {this._renderColumnHeader('arrival', 'arrival-description', 'arrivalTime')}
                            </div>
                            <div className='grid-column--2-9'>
                                {this._renderColumnHeader(
                                    'duration',
                                    'travel-time-description duration-info',
                                    'duration'
                                )}
                            </div>
                            <div className='grid-column--3-9'>
                                {this._renderColumnHeader('transfers', 'transfers-description', 'transfers')}
                            </div>
                        </div>
                    </div>
                    <div className='grid-column--4-12'>
                        {this._renderColumnHeader('price', 'bundle-summary-description', 'price')}
                    </div>
                </div>
            </div>
        )
    }

    _renderColumnHeader (label, className, sortBy) {
        const classes = classNames('text-label', className, {'state--is-selected': sortBy === this.props.sortBy})

        return (
            <span className={classes}>
                <TextLabel text={_t.getIntlMessage(`journey-result-set.${label}`)} />
            </span>
        )
    }

    onSort (sortBy) {
        this.props.onChangeSortBy(sortBy)
    }

    renderFilter () {
        return (
            <div className='grid-row'>
                <div className='grid-column--1-1'>
                    <div className='journey-result-set-filter-container'>
                        <div className='grid-row'>
                            {this._renderRouteSelector()}
                            {device.isDesktopOrTablet() ? this._renderSorter() : null}
                            {this._renderCurrencySelector()}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    _renderSorter () {
        if (this.props.routes.length <= 1) {
            return null
        }

        const sorterProps = {
            onChange: this.props.onChangeSortBy,
            sortBy: this.props.sortBy,
            direction: this.props.travel.direction
        }
        return (
            <div className='grid-column--1-1 grid-column--medium-1-3'>
                <Sorter {...sorterProps} />
            </div>
        )
    }

    _renderRouteSelector () {
        return this.hasMultiSegmentAndDirectRoutes(this.props.routes) ? (
            <div className='grid-column--1-2 grid-column--medium-1-3'>
                <RouteSelector onChange={this.props.onChangeTransfersFilter} direction={this.props.travel.direction} />
            </div>
        ) : null
    }

    _renderCurrencySelector () {
        const currencies = JourneySearchManager.getAvailableCurrencies(this.props.travel)

        if (currencies.length < 2) {
            return null
        }

        return (
            <div className='grid-column--1-2 grid-column--medium-1-3'>
                <CurrencySelector enabled currencies={currencies} />
            </div>
        )
    }
}
