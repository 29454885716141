import PropTypes from 'prop-types'
import React, { Component } from 'react'
import get from 'lodash/get'
import _t from '../../../translate'
import FormattedHTMLMessage from '../../../translate/formatted-html-message'
import actions from '../../../reflux/actions'
import kebabCase from 'lodash/kebabCase'
import PaymentStore from '../../../reflux/stores/payment-store'
import Reflux from 'reflux'
import BookingModel from '../../../models/booking-model'
import BookingStore from '../../../reflux/stores/booking-store'
import ServicesStore from '../../../reflux/stores/services-store'
import moment from 'moment-timezone'
import {
    tariffSegmentsSelector,
    withoutCancelledSelector
} from '../../../models/selectors/api/v2/booking/tariff-segments'
import {bookingSelector} from '../../../reflux/bridge/booking'
import {getState} from '../../../reflux/bridge/connect-state'
import {isBlablabusDomain} from '../../../misc/blablabus-helpers'
import createReactClass from 'create-react-class'
import {requiredProductsSelector} from '../../../models/selectors/api/v2/booking/products'
import {cmsBlockContainer} from '../../cms/cms-block-container'

const countryCodeIT = 'ITA'
const isBlaBlabus = () => isBlablabusDomain() && _t.getLocales() !== 'fr-FR'

const RegularTO = createReactClass({
    getDefaultProps () {
        return {
            showRegularPartners: true
        }
    },

    propTypes: {
        additionalPartner: PropTypes.shape({
            name: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired
        }),
        showRegularPartners: PropTypes.bool
    },

    mixins: [
        Reflux.listenTo(ServicesStore, 'onGetServices')
    ],

    getInitialState () {
        return {
            booking: BookingModel.create(BookingStore.getBooking()),
            serviceProps: []
        }
    },

    onGetServices (data) {
        if (!data.services) {
            return
        }

        const serviceProps = data.services.reduce((_serviceProps, service) => {
            return service.service_properties.reduce((_props, property) => {
                const isAllowed = (
                    property.code.includes('CGT')
                )
                let name = service.service_type.name

                // CGT properties with the same name or code will be displayed once
                if (isAllowed && !_props.some(serviceProp => serviceProp.name === name || serviceProp.code === property.code)) {
                    _props.push({
                        code: property.code,
                        name: name,
                        url: property.description || 'none'
                    })
                }
                return _props
            }, _serviceProps)
        }, this.state.serviceProps)

        this.setState({serviceProps})
    },

    componentWillMount () {
        const tariffSegments = withoutCancelledSelector(tariffSegmentsSelector(bookingSelector))(getState())
        tariffSegments.forEach(
            tariffSegment => tariffSegment.bookingJourneySegments.forEach(
                segment => {
                    const date = moment(tariffSegment.travelDate).format('YYYY-MM-DD')
                    if (!ServicesStore.hasAlreadyStored(
                        date,
                        get(segment, 'departure_station._u_i_c_station_code'),
                        get(segment, 'arrival_station._u_i_c_station_code'),
                        get(segment, 'service_type.code'),
                        segment.service_name
                    )) {
                        actions.getServices(
                            date,
                            get(segment, 'departure_station._u_i_c_station_code'),
                            get(segment, 'arrival_station._u_i_c_station_code'),
                            get(segment, 'service_type.code'),
                            segment.service_name
                        )
                    }
                }
            )
        )

        this.onGetServices(ServicesStore.getData())
    },

    render () {
        const partners = [this.props.additionalPartner].concat(this.props.showRegularPartners ? this.state.serviceProps : []).filter(Boolean)

        const translationValues = partners.reduce((result, {url, name}, index) => {
            const letter = (index + 10).toString(36).toUpperCase()

            result[`partner${letter}Url`] = url
            result[`partner${letter}Name`] = name

            return result
        }, {})

        return <AgreeToTermsWithPrivacyAndConditions partners={translationValues} partnerArray={partners} />
    }
})

class AgreeToTerms extends Component {
    static propTypes = {
        partners: PropTypes.shape({
            partnerAUrl: PropTypes.string,
            partnerAName: PropTypes.string,
            partnerBUrl: PropTypes.string,
            partnerBName: PropTypes.string,
            partnerCUrl: PropTypes.string,
            partnerCName: PropTypes.string,
            partnerDUrl: PropTypes.string,
            partnerDName: PropTypes.string
        }),
        partnerArray: PropTypes.array,
        agreeToTermsUrl: PropTypes.string.isRequired,
        agreeToTermsText: PropTypes.string.isRequired,
        privacyUrl: PropTypes.string.isRequired,
        privacyText: PropTypes.string.isRequired
    }

    render () {
        const serviceMessage = _t.formatIntlMessage(`personal-information-form.agree-to-terms.label.partners-${(this.props.partnerArray).length}`, {
            ...this.props.partners,
            agreeToTermsUrl: this.props.agreeToTermsUrl,
            agreeToTermsText: this.props.agreeToTermsText,
            privacyUrl: this.props.privacyUrl,
            privacyText: this.props.privacyText
        })
        return <FormattedHTMLMessage message={serviceMessage} />
    }
}

const AgreeToTermsWithPrivacy = cmsBlockContainer('payment-privacy-policy', ({isCmsLoading, content, title, ...props}) => {
    return !isCmsLoading ? <AgreeToTerms privacyUrl={title} privacyText={content} {...props} /> : null
})
const AgreeToTermsWithPrivacyAndConditions = cmsBlockContainer('payment-general-conditions', ({isCmsLoading, content, title, ...props}) => {
    return !isCmsLoading ? <AgreeToTermsWithPrivacy agreeToTermsUrl={title} agreeToTermsText={content} {...props} /> : null
})

class BaseITDomesticTO extends Component {
    static propTypes = {
        title: PropTypes.string,
        content: PropTypes.string,
        isCmsLoading: PropTypes.bool.isRequired,
        onlyITDomestic: PropTypes.bool.isRequired
    }

    render () {
        const partner = this.props.title ? {
            url: this.props.content,
            name: this.props.title
        } : undefined
        return this.props.isCmsLoading ? null : <RegularTO additionalPartner={partner} showRegularPartners={!this.props.onlyITDomestic} />
    }
}

const ITDomesticTO = cmsBlockContainer('TERM_ITA', BaseITDomesticTO)

const PaymentTerms = createReactClass({
    mixins: [
        Reflux.connectFilter(PaymentStore, 'agreeField', data => data.fields.agree)
    ],

    propTypes: {
        agreeField: PropTypes.shape({
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
            errorText: PropTypes.string,
            isRequired: PropTypes.bool.isRequired
        })
    },

    componentWillMount () {
        if (isBlaBlabus()) {
            actions.processPaymentData([
                {id: 'agree', value: true}
            ])
        }
    },

    checkboxFieldProps (fieldName) {
        let kebabCaseFieldName = kebabCase(fieldName)
        return {
            id: fieldName,
            ref: fieldName,
            value: 1,
            checked: Boolean(this.state.agreeField.value),
            required: this.state.agreeField.isRequired,
            errorText: this._prefixFieldLabel(kebabCaseFieldName, this.state.agreeField.errorText),
            onChange: this.onCheckboxClicked
        }
    },

    _prefixFieldLabel (field, label) {
        return label ? `payment.form.${field}.${label}` : null
    },

    onCheckboxClicked (event) {
        actions.processPaymentData([{
            id: 'agree',
            value: event.target.checked
        }])
    },

    render () {
        return this.state.agreeField
            ? (
                <div className='grid-row payment-terms'>
                    <div className='grid-column--1-1'>
                        {this._renderAgreeToTermsLabel()}
                    </div>
                </div>
            ) : null
    },

    _renderAgreeToTermsLabel () {
        const requiredProducts = requiredProductsSelector(bookingSelector)(getState())
        const mapCountryCodes = requiredProducts.reduce((result, {departureStation, arrivalStation}) => {
            result.push([departureStation.countryCode, arrivalStation.countryCode])

            return result
        }, [])

        const numberSegmentsITDomestic = mapCountryCodes.filter(([countryA, countryB]) => countryA === countryCodeIT && countryB === countryCodeIT).length
        const onlyITDomestic = numberSegmentsITDomestic === mapCountryCodes.length

        return numberSegmentsITDomestic > 0 ? <ITDomesticTO onlyITDomestic={onlyITDomestic} /> : <RegularTO />
    }
})

export default PaymentTerms
