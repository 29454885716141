import React from 'react'
import {IndexRoute} from 'react-router'
import authCheck from '../mixins/auth'
import OrientationOfferLayoutContainer from '../layouts/orientation-offer-layout-container'

export default (
    <IndexRoute
        component={OrientationOfferLayoutContainer}
        onEnter={(nextState, replaceState, callback) => authCheck(nextState, replaceState, callback, {ensureToken: true})}
    />
)
