/* eslint-disable no-template-curly-in-string */

import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {cmsBlockContainer} from './cms/cms-block-container'
import Icon from '../element/icon'
import {CMS_BLOCK_AFTERSALES_VIEW_SEAT_NUMBER} from '../constants'
import _t from '../translate'

class AfterSalesViewSeatNumberComponent extends Component {
    static propTypes = {
        content: PropTypes.string,
        showSeatsThresholdMoment: PropTypes.object,
        isTer: PropTypes.bool
    }

    render () {
        const {
            content,
            showSeatsThresholdMoment,
            isTer
        } = this.props
        if (!content || !showSeatsThresholdMoment || isTer) {
            return null
        }
        const showSeatsThreshold = showSeatsThresholdMoment.toDate()
        const placeholders = {
            '${date}': _t.formatDate(showSeatsThreshold, 'fullWithoutWeekday'),
            '${time}': _t.formatTime(showSeatsThreshold, 'short')
        }

        const contentCMS = this.props.content.replace(/\$\{\w+\}/g, key => placeholders[key] || key)
        return (
            <span className='text-label seat-notice'>
                <Icon type='information' className='xsmall align-left' />
                <span className='text'>
                    <span dangerouslySetInnerHTML={{__html: contentCMS}} />
                </span>
            </span>
        )
    }
}

export default cmsBlockContainer(CMS_BLOCK_AFTERSALES_VIEW_SEAT_NUMBER, AfterSalesViewSeatNumberComponent)
