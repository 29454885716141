import Reflux from 'reflux'
import _ from 'lodash'
import CurrencyStore from './currency-store'
import actions from '../actions'
import UserAwareMixin from '../mixin/user-aware-mixin'
import ResponseCodes from '../../api/response-codes'
import {snakeCaseKeys} from '../../misc/camelcase'
import Storage from '../../storage'
import {journeySearchFromJourneyResultSelector} from '../../models/selectors/api/v2/orientation/journey-result'
import {outboundTravelSelector, inboundTravelSelector} from '../../models/selectors/api/v2/orientation/travel'
import {mapPassengers} from '../../misc/helpers'
import {getState} from '../bridge/connect-state'

export default Reflux.createStore({

    listenables: actions,

    mixins: [UserAwareMixin],

    init () {
        this._resetData()

        this.listenTo(actions.processDeepLink, this.resetData)

        this.listenTo(actions.initiatePayment.completed, this.resetData)
        this.listenTo(actions.processPayment.completed, this.resetData)
        this.listenTo(actions.confirmBooking.completed, this.resetData)
        this.listenTo(actions.revertBooking.completed, this.resetData)
    },

    _resetData () {
        this.data = {
            loading: false,
            error: '',
            journeySearch: {
                id: null
            },
            offer: {}
        }
    },

    resetData () {
        Storage.clear('journeySearch')
        this._resetData()
        this.trigger(this.data)
    },

    getInitialState () {
        return this.data
    },

    isLoading () {
        return this.data.loading
    },

    getJourneySearchData () {
        return this.data.journeySearch
    },

    getPreviousJourneySearchData () {
        return Storage.get('journeySearch')
    },

    hasPreviousJourneySearchData () {
        return this.isValidJourneySearch(Storage.get('journeySearch'))
    },

    isValidJourneySearch (journeySearch) {
        return Boolean(journeySearch &&
            journeySearch.origin &&
            journeySearch.destination &&
            journeySearch.outboundDate &&
            journeySearch.passengers
        )
    },

    getOfferData () {
        return this.data.offer
    },

    onClearOffer () {
        Storage.clear('journeySearch')
        const journeySearch = _.get(this, 'data.journeySearch')
        this._resetData()
        this.data.journeySearch = journeySearch
        this.trigger(this.data)
    },

    onGetOffer () {
        this.data.loading = true
        this.data.error = ''
        this.data.offer = {}
        this.trigger(this.data)
    },

    onGetOfferCompleted (response) {
        // NOTE: Change of components etc are always done with Reflux
        // Selectors are just an alternative way of reading data instead of models

        // Set this data first to be available for selectors
        this.data.loading = false
        this.data.error = ''
        this.data.offer = response.data.offer // especially this one, because the selector calls getOfferData()
        this.data.offer.passengers = this.data.offer.passengers && mapPassengers(this.data.offer.passengers)

        const journeySearch = journeySearchFromJourneyResultSelector(getState())
        const outboundTravel = outboundTravelSelector(getState())
        const inboundTravel = inboundTravelSelector(getState())

        this.data.journeySearch = {
            currency: CurrencyStore.getCurrency(),
            passengers: mapPassengers(journeySearch.passengers.map(snakeCaseKeys)),
            departure_date: journeySearch.outboundDate && journeySearch.outboundDate.toDate(),
            return_date: journeySearch.inboundDate && journeySearch.inboundDate.toDate(),
            origin_station: (outboundTravel && outboundTravel.originStation) ||
                (inboundTravel && inboundTravel.destinationStation) || '',
            destination_station: (outboundTravel && outboundTravel.destinationStation) ||
                (inboundTravel && inboundTravel.originStation) || ''
        }

        Storage.set('journeySearch', journeySearch)

        this.trigger(this.data)
    },

    onGetOfferFailed (error) {
        this.data.loading = false
        this.data.error = error
        this.trigger(this.data)
    },

    onChangeCurrency () {
        this.data.offer = {}
        this.trigger(this.data)
    },

    hasReturnDate () {
        return Boolean(this.data.journeySearch.return_date)
    },

    hasDepartureDate () {
        return Boolean(this.data.journeySearch.departure_date)
    },

    onUpdateRebookJourneySearchSelection (data) {
        if ('departure_date' in data) {
            this.data.journeySearch.departure_date = data.departure_date
        }

        if ('return_date' in data) {
            this.data.journeySearch.return_date = data.return_date
        }

        if (this.data.journeySearch.departure_date instanceof Date &&
            this.data.journeySearch.return_date instanceof Date &&
            this.data.journeySearch.return_date.getTime() < this.data.journeySearch.departure_date.getTime()
        ) {
            this.data.journeySearch.return_date = this.data.journeySearch.departure_date
        }

        this.trigger(this.data)
    },

    onApiError (error) {
        if (error.code === ResponseCodes.BOOKING_FULFILLMENT_METHODS_MISSING ||
            error.code === ResponseCodes.BOOKING_CREATE_NO_AVAILABLE_SEAT_COMBINATION ||
            error.code === ResponseCodes.BOOKING_CREATE_NO_LOGICAL_AVAILABILITY ||
            error.code === ResponseCodes.BOOKING_CREATE_INVALID_REQUEST_FOR_SERVICE ||
            error.code === ResponseCodes.BOOKING_CREATE_COULD_NOT_ALLOCATE_SEATS ||
            error.code === ResponseCodes.BOOKING_CREATE_NO_AVAILABLE_CARRIAGES ||
            error.code === ResponseCodes.BOOKING_CREATE_NO_LOGICAL_OR_PHYSICAL_AVAILABILITY
        ) {
            this.onClearOffer()
        }
    }
})
