import React from 'react'
import {Route} from 'react-router'
import authCheck from '../../mixins/auth'
import DeepLinkLayout from '../../layouts/orientation-deep-link-layout'

export default (
    <Route path='/:lang/orientation/deep-link'
           component={DeepLinkLayout}
           onEnter={(nextState, replaceState, callback) => authCheck(nextState, replaceState, callback)}
    />
)
