import Reflux from 'reflux'
import actions from '../../reflux/actions'

export default Reflux.createStore({

    listenables: actions,

    init () {
        this._resetData()
    },

    _resetData () {
        this.data = {
            products: {},
            loading: false,
            success: false
        }
    },

    resetData () {
        this._resetData()
        this.trigger(this.data)
    },

    getData () {
        return this.data
    },

    getInitialState () {
        return this.data
    },

    getProductSearchData () {
        return this.data.products
    },

    onGetProducts () {
        this.data.loading = true
        this.data.success = false
        this.trigger(this.data)
    },

    onGetProductsCompleted (response) {
        this.data.loading = false
        this.data.success = true
        this.data.products = response.data.products
        this.trigger(this.data)
    },

    onGetProductsFailed (error) {
        this.data.loading = false
        this.data.error = error
        this.trigger(this.data)
    }
})
