import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from '../../../../translate'
import ModalDialog from '../../../../element/modal-dialog'
import Button from '../../../../element/button'

export default class InventoryDialog extends Component {
    static propTypes = {
        closeDialog: PropTypes.func.isRequired
    }

    render () {
        const labelPrefix = 'additional-product-selector.wheelchair-inventory-dialog'

        return (
            <ModalDialog headerTitle={_t.getIntlMessage(`${labelPrefix}.title`)} onClose={this.props.closeDialog}>
                <div className='content'>
                    <p>{_t.getIntlMessage(`${labelPrefix}.body`)}</p>
                    <div className='actions'>
                        <ul className='horizontal-list'>
                            <li>
                                <Button
                                    type='button'
                                    className='button default'
                                    text={_t.getIntlMessage(`${labelPrefix}.confirm`)}
                                    icon={{type: 'cross', className: 'small align-left'}}
                                    onClick={this.props.closeDialog}
                                />
                            </li>
                        </ul>
                    </div>
                </div>
            </ModalDialog>
        )
    }
}
