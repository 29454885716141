import React from 'react'
import {Route} from 'react-router'

import authCheck from '../../../mixins/auth'
import BookingLuggageLayout from '../../../layouts/mys3/re-booking/luggage'

export default (
    <Route
        path='/:lang/mys3/re-booking/luggage'
        component={BookingLuggageLayout}
        onEnter={(nextState, replaceState, callback) => authCheck(nextState, replaceState, callback)}
    />
)
