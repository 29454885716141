import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import _t from '../../translate'
import Button from '../../element/button'
import {withRouter} from 'react-router'
import createReactClass from 'create-react-class'

export default Component => withRouter(createReactClass({
    propTypes: {
        previousButtonEnabled: PropTypes.bool,
        nextButtonEnabled: PropTypes.bool,
        previousButtonProps: PropTypes.object,
        nextButtonProps: PropTypes.object,
        router: PropTypes.object
    },

    getDefaultProps () {
        return {
            previousButtonEnabled: true,
            nextButtonEnabled: true
        }
    },

    render () {
        if (!this.props.previousButtonEnabled && !this.props.nextButtonEnabled) {
            return null
        }

        return (
            <Component
                previousButtonEnabled={this.props.previousButtonEnabled}
                previousButtonRow={this.getPreviousButtonRow()}
                nextButtonRow={this.getNextButtonRow()}
            />
        )
    },

    getNextButtonRow () {
        if (!this.props.nextButtonEnabled) {
            return null
        }

        return this.getNextButton()
    },

    getPreviousButtonRow () {
        if (!this.props.previousButtonEnabled) {
            return null
        }

        return this.getPreviousButton()
    },

    getNextButton () {
        let props = _.merge({
            text: _t.getIntlMessage('progress-navigation.button.next'),
            className: 'button primary next',
            icon: {
                type: 'arrow-right',
                position: 'right',
                className: 'small align-right'
            }
        }, this.props.nextButtonProps)
        return <Button {...props} />
    },

    getPreviousButton () {
        let props = _.merge({
            text: _t.getIntlMessage('progress-navigation.button.back'),
            className: 'button secondary previous',
            onClick: e => {
                e.preventDefault()
                this.props.router.goBack()
            },
            icon: {
                type: 'arrow-left',
                className: 'small align-left'
            }
        }, this.props.previousButtonProps)
        return <Button {...props} />
    }
}))
