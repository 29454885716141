import React, {Component} from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

export default class TextLabel extends Component {
    static propTypes = {
        text: PropTypes.string,
        className: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.array
        ])
    }

    render () {
        let {className, text, children, ...props} = this.props
        className = classNames('text', className)
        return (
            <span className={className} {...props}>{text || children}</span>
        )
    }
}
