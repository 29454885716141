/* globals S3P_SETTINGS: true */

import Reflux from 'reflux'
import moment from 'moment-timezone'
import omit from 'lodash/omit'
import UserAwareMixin from '../mixin/user-aware-mixin'
import actions from '../actions'
import {toUtcDateMoment} from '../../misc/date'
import {snakeCaseKeys} from '../../misc/camelcase'

export default Reflux.createStore({

    listenables: actions,

    mixins: [UserAwareMixin],

    init () {
        this._resetData()
    },

    _resetData () {
        this.data = {
            loading: true,
            calendars: {},
            calendarRequest: {}
        }
    },

    resetData () {
        this._resetData()
        this.trigger(this.data)
    },

    getInitialState () {
        return this.data
    },

    onGetOffer () {
        this.resetData()
    },

    onCalendarDisabled () {
        this.data.loading = false
        this.data.calendars = {}
        this.trigger(this.data)
    },

    onGetCalendar (request) {
        this.data.loading = true
        this.data.calendars = {}
        this.data.calendarRequest = request
        this.trigger(this.data)
    },

    onGetCalendarCompleted (response) {
        this.data.loading = false
        this.data.calendars = response.data.calendars
        this.trigger(this.data)
    },

    getNextOutboundTravelDate () {
        return this.data.calendars && this.data.calendars.calendar_outbound
            ? Object.keys(this.data.calendars.calendar_outbound).sort((a, b) => moment(a).diff(b))[0] : null
    },

    getNextInboundTravelDate () {
        return this.data.calendars && this.data.calendars.calendar_inbound
            ? Object.keys(this.data.calendars.calendar_inbound).sort((a, b) => moment(a).diff(b))[0] : null
    },

    _createCalendarRequestItem (id, origin, destination, direction, startDate, endDate) {
        return {
            id,
            origin,
            destination,
            direction,
            max_transfers: S3P_SETTINGS.s3Passenger.features.stations.maxTransfers,
            entries: {
                begin: toUtcDateMoment(startDate).format('YYYY-MM-DD'),
                end: toUtcDateMoment(endDate).format('YYYY-MM-DD')
            }
        }
    },

    createDateSwitcherRequestData (journeySelection, isAftersales = false) {
        const outboundDate = journeySelection.outboundDate
        const inboundDate = journeySelection.inboundDate
        const passengers = journeySelection.passengers
            .map(passenger => omit(passenger, 'id'))
            .map(snakeCaseKeys)
        const origin = journeySelection.origin
        const destination = journeySelection.destination
        let travels = []
        if (outboundDate && toUtcDateMoment(outboundDate).isValid()) {
            travels = travels.concat(
                this._createCalendarRequestItem(
                    'outbound',
                    origin,
                    destination,
                    'outbound',
                    toUtcDateMoment(outboundDate).subtract(1, 'days'),
                    toUtcDateMoment(outboundDate).add(2, 'days')
                )
            )
        }
        if (inboundDate && toUtcDateMoment(inboundDate).isValid()) {
            travels = travels.concat(
                this._createCalendarRequestItem(
                    'inbound',
                    destination,
                    origin,
                    'inbound',
                    toUtcDateMoment(inboundDate).subtract(1, 'days'),
                    toUtcDateMoment(inboundDate).add(2, 'days')
                )
            )
        }

        let requestData = {
            calendars: travels,
            passengers,
            is_aftersales: isAftersales
        }

        if (journeySelection.currency) {
            requestData.currency = journeySelection.currency
        }

        if (journeySelection.contractCode) {
            requestData.contract_code = journeySelection.contractCode
        }

        return requestData
    },

    createRequestData (journeySearch, directions, isAfterSales) {
        const outboundDate = journeySearch.departure_date ? moment(journeySearch.departure_date).toDate() : null
        const inboundDate = journeySearch.return_date ? moment(journeySearch.return_date).toDate() : null

        let calendars = []
        if (directions.includes('outbound') && outboundDate && toUtcDateMoment(outboundDate).isValid()) {
            calendars = calendars.concat([{
                id: `calendar_outbound`,
                origin: journeySearch.origin_station.UICStationCode,
                destination: journeySearch.destination_station.UICStationCode,
                direction: 'outbound',
                max_transfers: S3P_SETTINGS.s3Passenger.features.stations.maxTransfers,
                entries: {
                    begin: toUtcDateMoment(outboundDate).add(1, 'days').format('YYYY-MM-DD'),
                    end: toUtcDateMoment(outboundDate).add(31, 'days').format('YYYY-MM-DD')
                }
            }])
        }

        if (directions.includes('inbound') && inboundDate && toUtcDateMoment(inboundDate).isValid()) {
            calendars = calendars.concat([{
                id: `calendar_inbound`,
                origin: journeySearch.destination_station.UICStationCode,
                destination: journeySearch.origin_station.UICStationCode,
                direction: 'inbound',
                max_transfers: S3P_SETTINGS.s3Passenger.features.stations.maxTransfers,
                entries: {
                    begin: toUtcDateMoment(inboundDate).add(1, 'days').format('YYYY-MM-DD'),
                    end: toUtcDateMoment(inboundDate).add(31, 'days').format('YYYY-MM-DD')
                }
            }])
        }

        let requestData = {
            calendars: calendars,
            passengers: journeySearch.passengers.map(passenger => omit(passenger, 'id')),
            is_aftersales: isAfterSales
        }

        if (journeySearch.currency) {
            requestData.currency = journeySearch.currency
        }

        return requestData
    }

})
