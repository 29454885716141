import Reflux from 'reflux'
import actions from '../actions'
import oauth from '../../oauth'
import get from 'lodash/get'
import ResponseCodes from '../../api/response-codes'

export default Reflux.createStore({

    listenables: actions,

    init () {
        this.data = {hasToken: oauth.hasValidToken()}
    },

    getInitialState () {
        return this.data
    },

    onNewToken () {
        this.data.hasToken = true
        this.trigger(this.data)
    },

    onFailedToken (response) {
        let code = get(response, 'response.headers.x-oauth-errorcode')
        code = code && parseInt(code)

        if (code && code === ResponseCodes.INVALID_EXTERNAL_TOKEN_PROVIDED) {
            return false
        }

        this.data.hasToken = false
        this.trigger(this.data)
    },

    onLogout () {
        this.data.hasToken = false
        this.trigger(this.data)
    }
})
