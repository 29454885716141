/* globals S3P_SETTINGS: true */
import PropTypes from 'prop-types'
import React from 'react'
import _t from '../../translate'
import actions from '../../reflux/actions'
import {connectState} from '../../reflux/bridge/connect-state'
import BookingStore from '../../reflux/stores/booking-store'
import GetBookingStore from '../../reflux/stores/get-booking-store'
import Loader from '../../element/loader'
import {withRouter} from 'react-router'
import createReactClass from 'create-react-class'

const restoreBooking = (Component, options = {}) => {
    const RestoreBooking = withRouter(createReactClass({

        displayName: 'RestoreBooking',

        propTypes: {
            bookingNumber: PropTypes.string,
            loading: PropTypes.bool,
            hasBooking: PropTypes.bool,
            router: PropTypes.object
        },

        getInitialState () {
            return {
                doPageReload: false
            }
        },

        componentWillMount () {
            if (!this.props.bookingNumber && !this.props.loading) {
                this.props.router.push(
                    `/${_t.getLocales()}${S3P_SETTINGS.s3Passenger.features.unauthorizedPages.default}`
                )
            } else if (!this.props.hasBooking && !this.props.loading) {
                actions.getBooking(this.props.bookingNumber)
                this.setState({doPageReload: true})
            }
        },

        render () {
            return (this.props.loading || !this.props.hasBooking) && options.withBlockingLoader && this.state.doPageReload
                ? <Loader blocking />
                : <Component {...this.props} />
        }

    }))

    const mapPropsToProps = () => ({
        loading: GetBookingStore.isLoading() || BookingStore.isLoading(),
        hasBooking: Boolean(BookingStore.getBooking() && BookingStore.getBooking().booking_number),
        bookingNumber: BookingStore.getStoredBookingNumber()
    })

    return connectState(mapPropsToProps, [BookingStore, GetBookingStore])(RestoreBooking)
}

export default restoreBooking
export const restoreBookingCompose = options => Component => restoreBooking(Component, options)
