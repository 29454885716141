import React from 'react'
import {Route} from 'react-router'
import authCheck from '../../../mixins/auth'
import containAfterSalesConfirmationLayout from '../../../layouts/aftersales-confirmation-layout'
import {AFTER_SALES_SEAT_SELECTION} from '../../../constants'

const AfterSalesConfirmationLayout = containAfterSalesConfirmationLayout(AFTER_SALES_SEAT_SELECTION, {
    restoreBooking: true
})

export default (
    <Route
        path='/:lang/mys3/seat/confirmation'
        component={AfterSalesConfirmationLayout}
        onEnter={(nextState, replaceState, callback) => authCheck(nextState, replaceState, callback)}
    />
)
