import PropTypes from 'prop-types'
import React, {Component} from 'react'
import _ from 'lodash'
import classNames from 'classnames'
import _t from '../../../translate'
import actions from '../../../reflux/actions'
import {isFlexBundle, isBigGroup} from '../../../misc/flex-helper'
import {hasModalityInRoute} from '../../../misc/modality-helper'
import device from '../../../device'
import TextLabel from '../../../element/text-label'
import Icon from '../../../element/icon'
import JourneyResultSetListOptionFromInfo from './option/from-info'
import JourneyResultSetListOptionToInfo from './option/to-info'
import JourneyResultSetListOptionFromToInfo from './option/from-to-info'
import JourneyResultSetListOptionJourneyInfo from './option/journey-info'
import JourneyResultSetListOptionBundleSummary from './option/bundle-summary'
import JourneyResultSetListOptionPassengerInfo from './option/passenger-info'
import JourneyResultSetListOptionTransfers from './option/transfers'
import JourneyResultSetListOptionAgglomeration from './option/agglomeration'
import JourneyResultSetListOptionDetails from './option/details'
import FlexConditions from './option/flex-conditions'
import {
    SERVICE_TYPE_MODALITY_BUS,
    SERVICE_TYPE_MODALITY_TER,
    SERVICE_TYPE_NAME_ALSA
} from '../../../constants'

export default class Option extends Component {
    static propTypes = {
        onClick: PropTypes.func,
        onExpand: PropTypes.func,
        route: PropTypes.shape({
            id: PropTypes.string,
            duration: PropTypes.any,
            isBestTime: PropTypes.bool,
            transfers: PropTypes.array,
            legs: PropTypes.arrayOf(PropTypes.shape({
                departureStation: PropTypes.shape({
                    departureTime: PropTypes.any,
                    UICStationCode: PropTypes.string
                }),
                arrivalStation: PropTypes.shape({
                    arrivalTime: PropTypes.any,
                    UICStationCode: PropTypes.string
                }),
                serviceType: PropTypes.shape({
                    name: PropTypes.string,
                    modality: PropTypes.string
                }),
                availability: PropTypes.arrayOf(PropTypes.shape({
                    inventoryClass: PropTypes.string,
                    physical: PropTypes.number
                }))
            }))
        }),
        selectedBundle: PropTypes.object,
        travel: PropTypes.shape({
            hasPromoBundle: PropTypes.bool
        }),
        bundles: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string,
            isCheapestRouteBundle: PropTypes.bool,
            isCheapestTravelBundle: PropTypes.bool,
            isPromo: PropTypes.bool,
            isAlsaProduct: PropTypes.bool,
            isNelProduct: PropTypes.bool
        })),
        passengers: PropTypes.arrayOf(PropTypes.object),
        comfortZones: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
        isBestTime: PropTypes.bool
    }

    constructor (...args) {
        super(...args)
        this.state = {
            bundleSelectorToggled: false,
            clicked: false,
            showDetails: false
        }
        this._toggleDetails = this._toggleDetails.bind(this)
    }

    static defaultProps = {
        onClick () {
        },
        onExpand () {
        },
        selectedBundle: null,
        route: {},
        bundles: [],
        factor: 1,
        isBestTime: false
    }

    render () {
        const classes = classNames('journey-result-option', {
            'not-available': !this.props.bundles.length,
            active: this.state.showDetails,
            expanded: this.state.bundleSelectorToggled,
            selected: this.isSelected(),
            unselected: this.isUnSelected()
        })

        return device.isDesktopOrTablet() ? this.renderDesktop(classes) : this.renderMobile(classes)
    }

    renderDesktop (classes) {
        const route = this.props.route
        const firstLeg = _(route.legs).first()
        const lastLeg = _(route.legs).last()

        return (
            <div className='journey-result-option-bundle'>
                <div className={classes}>
                    <div className='grid-row no-gutter'>
                        <div className='grid-column--8-12'>
                            <div className='journey-result-option-item-container'>
                                <div className='grid-row no-gutter'>
                                    {this.renderAgglomeration('departure', firstLeg.departureStation)}
                                    {this.renderAgglomeration('arrival', lastLeg.arrivalStation)}
                                    <div className='grid-column--2-9'>
                                        <JourneyResultSetListOptionFromInfo
                                            selected={this.isSelected()}
                                            leg={firstLeg}
                                            days={this._getDays(firstLeg, lastLeg)}
                                        />
                                    </div>
                                    <div className='grid-column--2-9'>
                                        <JourneyResultSetListOptionToInfo leg={lastLeg} />
                                    </div>
                                    <div className='grid-column--2-9'>
                                        <JourneyResultSetListOptionJourneyInfo
                                            travelTime={route.duration}
                                            isBestTime={this.props.isBestTime}
                                        />
                                    </div>
                                    <div className='grid-column--3-9'>
                                        <JourneyResultSetListOptionTransfers transfers={route.transfers} />
                                    </div>
                                </div>
                                {this._renderDetailsButton()}
                            </div>
                        </div>
                        <div className='grid-column--4-12'>
                            {this._renderSummary()}
                        </div>
                    </div>
                    <div className='grid-row no-gutter'>
                        <div className='grid-column--1-1'>
                            {this._renderDetails()}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderMobile (classes) {
        const route = this.props.route
        const firstLeg = _(route.legs).first()
        const lastLeg = _(route.legs).last()

        return (
            <div className='journey-result-option-bundle'>
                <div className={classes}>
                    <div className='grid-row no-gutter'>
                        <div className='grid-column--1-1'>
                            {this.renderAgglomeration('departure', firstLeg.departureStation)}
                            {this.renderAgglomeration('arrival', lastLeg.arrivalStation)}
                        </div>
                        <div className='grid-column--5-12'>
                            <JourneyResultSetListOptionFromToInfo
                                selected={this.isSelected()}
                                fromLeg={firstLeg}
                                toLeg={lastLeg}
                                days={this._getDays(firstLeg, lastLeg)}
                            />
                            <JourneyResultSetListOptionJourneyInfo
                                travelTime={route.duration}
                                days={this._getDays(firstLeg, lastLeg)}
                            />
                            <JourneyResultSetListOptionTransfers transfers={route.transfers} />
                            {this._renderDetailsButton()}
                        </div>
                        <div className='grid-column--7-12'>
                            {this._renderSummary()}
                        </div>
                    </div>
                    <div className='grid-row no-gutter'>
                        <div className='grid-column--1-1'>
                            {this._renderDetails()}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    _toggleDetails () {
        if (!this.state.showDetails) {
            let type = 'Open direct'// Open direct | Open changeover_1 | Open changeover_2
            if (this.props.route.transfers.length > 1) {
                type = 'Open changeover_2'
            } else if (this.props.route.transfers.length > 0) {
                type = 'Open changeover_1'
            }
            actions.logEvent('Route details', type)
        }

        this.setState({
            showDetails: !this.state.showDetails
        })
    }

    _renderDetailsButton () {
        const text = this.state.showDetails ? 'journey-result-set.less-details' : 'journey-result-set.more-details'
        const iconType = this.state.showDetails ? 'minus' : 'plus'

        return (
            <button className='toggle-journey-details' onClick={this._toggleDetails}>
                <span className='text-label collapse-details'>
                    <TextLabel text={_t.message(text)} />
                    <Icon className='small align-right' type={iconType} />
                </span>
            </button>
        )
    }

    _renderDetails () {
        if (!this.state.showDetails) {
            return null
        }

        return (
            <div className='journey-details'>
                <div className='grid-row no-gutter'>
                    <div className='grid-column--1-1 grid-column--medium-1-2 push-grid-column--medium-1-2'>
                        <JourneyResultSetListOptionPassengerInfo
                            bundles={this.props.bundles}
                            passengers={this.props.passengers}
                        />
                    </div>
                    <div className='grid-column--1-1 grid-column--medium-1-2 pull-grid-column--medium-1-2'>
                        <JourneyResultSetListOptionDetails route={this.props.route} />
                    </div>
                </div>
                {this.props.route.transfers.map(this._renderTransferDescription)}
                {this._isFlex() || this._isNel() || this._isAlsa()
                    ? <FlexConditions
                        isNel={this._isNel()}
                        isAlsa={this._isAlsa()}
                        isBigGroup={isBigGroup(this.props.passengers.length)}
                    /> : null}
                {this._renderTerDescription()}
                {this._renderAlsaDescription()}
            </div>
        )
    }

    _renderTerDescription () {
        return hasModalityInRoute(this.props.route, SERVICE_TYPE_MODALITY_TER)
            ? <div>
                <div className='modality-description'>
                    <TextLabel text={_t.message('journey-result-set.journey-details.ter-description')} />
                </div>
            </div>
            : null
    }

    _renderAlsaDescription () {
        return hasModalityInRoute(this.props.route, SERVICE_TYPE_MODALITY_BUS, [SERVICE_TYPE_NAME_ALSA])
            ? <div>
                <div className='modality-description'>
                    <TextLabel text={_t.message(
                        hasModalityInRoute(this.props.route, SERVICE_TYPE_MODALITY_BUS, [], [SERVICE_TYPE_NAME_ALSA])
                            ? 'journey-result-set.journey-details.ouibus-alsa-description'
                            : 'journey-result-set.journey-details.alsa-description'
                    )} />
                </div>
            </div>
            : null
    }

    _renderTransferDescription (transfer, index) {
        return 'transferDescription' in transfer && transfer.transferDescription !== '' ? (
            <div key={index} className='grid-column--1-1'>
                <div className='feedback'>
                    <span className='text-label'>
                        <Icon type='information' className='information small align-left' />
                        <span className='text-label information'>
                            <TextLabel text={transfer.transferDescription} />
                        </span>
                    </span>
                </div>
            </div>
        ) : null
    }

    _renderSummary () {
        if (!this.props.bundles.length) {
            const messageType = this._getPhysicalAvailability() < this.props.passengers.length
                ? 'bus-full' : 'not-available'

            return (
                <div className='grid-row no-gutter'>
                    <div className='grid-column--1-1'>
                        <div className='journey-result-option-item bundle-summary'>
                            <div className='bundle-price-placeholder'>
                                <span className='text-label bundle-not-available-description'>
                                    <TextLabel text={_t.message(`journey-result-set.${messageType}`)} />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        const className = `grid-column--1-${this.props.bundles.length === 1 ? '1' : '2'}`

        return (
            <div className='grid-row no-gutter'>
                {this.props.bundles.map(bundle => {
                    return (
                        <div key={bundle.id} className={className} onClick={() => this.handleSelectedBundle(bundle)}>
                            <JourneyResultSetListOptionBundleSummary
                                selected={Boolean(this.props.selectedBundle && this.props.selectedBundle.id === bundle.id)}
                                bundle={bundle}
                                isBestRoutePrice={bundle.isCheapestRouteBundle}
                                isBestTravelPrice={bundle.isCheapestTravelBundle}
                                isNelproduct={bundle.isNelProduct}
                                isAlsaproduct={bundle.isAlsaProduct}
                                showBestPrice={!this.props.travel.hasPromoBundle}
                                isSingleTariff={this.props.bundles.length === 1}
                                hasAgglomerationStation={
                                    this._containsRouteAgglomerationStation(this.props.route, this.props.travel)
                                }
                                isFlex={this._isFlexBundle(bundle)}
                            />
                        </div>
                    )
                })}
            </div>
        )
    }

    _getPhysicalAvailability () {
        const availabilities = this.props.route.legs.reduce((carry, leg) => {
            const availability = leg.availability.find(availability => availability.inventoryClass === 'ST')
            return availability ? carry.concat(availability.physical) : carry
        }, [])

        return Math.min(...availabilities)
    }

    _getDays (firstLeg, lastLeg) {
        const departureDate = firstLeg.departureStation.departureTime
        const arrivalDate = lastLeg.arrivalStation.arrivalTime
        return arrivalDate.diff(departureDate, 'days')
    }

    _containsRouteAgglomerationStation (route, travel) {
        const firstLeg = _(route.legs).first()
        const lastLeg = _(route.legs).last()

        return this._isAgglomeration('departure', firstLeg.departureStation, travel) ||
            this._isAgglomeration('arrival', lastLeg.arrivalStation, travel)
    }

    _isAgglomeration (type, station, travel) {
        return (type === 'departure' ? travel.originStation.UICStationCode : travel.destinationStation.UICStationCode) !== station.UICStationCode
    }

    renderAgglomeration (type, station) {
        if (!this._isAgglomeration(type, station, this.props.travel)) {
            return null
        }

        return (
            <div className='grid-column--4-4'>
                <JourneyResultSetListOptionAgglomeration type={type} station={station} />
            </div>
        )
    }

    handleSelectedBundle (bundle) {
        if (!this._isBundleSelected(bundle)) {
            this.setState({clicked: true}, () => this.props.onClick(bundle))
        }
    }

    _isBundleSelected (bundle) {
        return this.props.selectedBundle && this.props.selectedBundle.id === bundle.id
    }

    shouldComponentUpdate (_, nextState) {
        // row clicked and not selected
        if (nextState.clicked === true && this.props.selectedBundle === false) {
            // row is clicked and not expanded
            if (this.state.bundleSelectorToggled === false &&
                nextState.bundleSelectorToggled === this.state.bundleSelectorToggled &&
                this.props.bundles.length > 0
            ) {
                return false // Do not update. The 'list' component will trigger an new update
            }
        }
        return true
    }

    componentWillReceiveProps () {
        // reset 'clicked 'state
        this.setState({clicked: false})
    }

    getSelectedIcon () {
        return this.props.selectedBundle ? <Icon type='tick' className='small' /> : null
    }

    isSelected () {
        return this.props.selectedBundle && this.props.selectedBundle.routeId === this.props.route.id
    }

    isUnSelected () {
        return !this.props.selectedBundle && this.props.bundles.length > 0 && this.state.bundleSelectorToggled
    }

    _isFlexBundle (bundle) {
        const departureStation = this.props.route.legs[0].departureStation
        return isFlexBundle(bundle, departureStation.departureTime, this.props.passengers.length)
    }

    _isFlex () {
        return this.props.bundles.some(bundle => this._isFlexBundle(bundle))
    }

    _isAlsa () {
        return this.props.bundles.some(bundle => bundle.isAlsaProduct)
    }

    _isNel () {
        return this.props.bundles.some(bundle => bundle.isNelProduct)
    }
}
