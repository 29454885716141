/* global document */
/* global Math */

import PropTypes from 'prop-types'

import React, { Component } from 'react'
import _ from 'lodash'
import _t from '../../../translate'
import Icon from '../../../element/icon'
import TextLabel from '../../../element/text-label'
import Tether from '../../../element/utils/tether'
import {hasModalityInTravel} from '../../../misc/modality-helper'
import {SERVICE_TYPE_MODALITY_BUS, SERVICE_TYPE_MODALITY_TER} from '../../../constants'
import device from '../../../device'

export default class JourneyResultSetListHeader extends Component {
    static propTypes = {
        travel: PropTypes.object.isRequired
    }

    constructor (...args) {
        super(...args)
        this._setTether = this._setTether.bind(this)
        this._selector = this._selector.bind(this)
    }

    componentDidMount () {
        if (this._tether && this._target) {
            this._tether.init(this._target)
        }
    }

    _setTether (tether) {
        this._tether = tether
    }

    getOriginDestination () {
        let originDestination = [
            this.props.travel.originStation,
            this.props.travel.destinationStation
        ]

        return {
            origin: _.first(originDestination),
            destination: _.last(originDestination)
        }
    }

    _selector () {
        return document.querySelector(
            `div.journey-result-list.${this.props.travel.direction}`
        )
    }

    render () {
        const title = this.props.travel.direction === 'inbound'
            ? _t.getIntlMessage('journey-result-set.inbound.list-header-title')
            : _t.getIntlMessage('journey-result-set.outbound.list-header-title')

        return (
            <header>
                <Tether
                    ref={this._setTether}
                    renderPlaceholder
                    target={this._selector}
                    targetAttachment='top left'
                    attachment='top left'
                    classes={{
                        element: `tether-trip-bar-${this.props.travel.direction}`
                    }}
                    constraints={() => [
                        {
                            to: 'window',
                            pin: 'top'
                        },
                        {
                            to: this._selector(),
                            pin: 'bottom'
                        }
                    ]}
                    DOMWatcher={() => Math.max(
                        document.body.scrollHeight,
                        document.body.offsetHeight,
                        document.documentElement.clientHeight,
                        document.documentElement.scrollHeight,
                        document.documentElement.offsetHeight
                    )}
                >
                    <div className='row'>
                        <div className='wrap'>
                            <div className='grid-row no-gutter'>
                                <div className='grid-column--1-1 grid-column--medium-2-3'>
                                    <div className='trip-bar'>
                                        <div className='trip-bar--orientation'>
                                            <span className='text-label select-journey'>
                                                <TextLabel text={title} />
                                            </span>
                                        </div>
                                        {this._renderTripBarJourney()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Tether>
            </header>
        )
    }

    _renderTripBarJourney () {
        const originDestination = this.getOriginDestination()
        const hasBus = hasModalityInTravel(this.props.travel, SERVICE_TYPE_MODALITY_BUS)
        const hasTer = hasModalityInTravel(this.props.travel, SERVICE_TYPE_MODALITY_TER)

        return (
            <div className='trip-bar--journey'>
                <TextLabel text={originDestination.origin.name} />
                <Icon type='chevron-right' className='align-right xsmall' />
                {hasBus || (!hasBus && !hasTer)
                    ? device.isMobile()
                        ? <Icon type='bus' className='bus' />
                        : <Icon type='bus-side' className='bus-side' />
                    : null
                }
                {hasBus && hasTer ? <Icon type='chevron-right' className='xsmall mobile' /> : null}
                {hasTer ? <span className='ter-illustration'>Ter</span> : null}
                <Icon type='chevron-right' className='align-left xsmall' />
                <TextLabel text={originDestination.destination.name} />
            </div>
        )
    }
}
