import React, {Component} from 'react'
import PropTypes from 'prop-types'
import _t from '../../translate'
import classNames from 'classnames'
import moment from 'moment-timezone'
import TextLabel from '../../element/text-label'
import Icon from '../../element/icon'
import BookingStatus from './booking-table/booking-status'
import orderBy from 'lodash/orderBy'

const UI_TABLE_SORT_ASCENDING = 'asc'
const UI_TABLE_SORT_DESCENDING = 'desc'

const SortIcon = ({sortOrder}) => <Icon
    type={sortOrder === UI_TABLE_SORT_ASCENDING ? 'chevron-up' : 'chevron-down'}
    className='xsmall align-right'
/>

SortIcon.propTypes = {
    sortOrder: PropTypes.string
}

const SortTableHeaderCell = ({name, label, className, sortColumn, sortOrder, onClick}) => {
    const isSortColumn = sortColumn === name
    const sortOrderLong = isSortColumn ? (sortOrder === UI_TABLE_SORT_ASCENDING ? 'ascending' : 'descending') : ''
    const sortClasses = isSortColumn ? `state--is-sorted-${sortOrderLong}` : ''

    return (
        <th scope='col'>
            <div className={classNames('state--is-sortable', sortClasses, className)}
                role='button'
                tabIndex='0'
                data-sort={sortOrderLong}
                onClick={() => onClick(name)}
            >
                <TextLabel text={label} name='thead' />
                {isSortColumn
                    ? <SortIcon sortOrder={sortOrder} />
                    : <Icon type='sort' className='xsmall align-right' />}
            </div>
        </th>
    )
}

SortTableHeaderCell.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    className: PropTypes.string,
    sortColumn: PropTypes.string,
    sortOrder: PropTypes.string,
    onClick: PropTypes.func
}

const mappers = {
    customer: booking => `${booking.first_name}_${booking.last_name}`.toLowerCase(),
    'booking-reference': booking => booking.booking_reference.toLowerCase(),
    route: booking => {
        const route = booking.outbound_route || booking.inbound_route || {}
        return `${route.origin || ' '}_${route.destination || ' '}`
    },
    'travel-date': booking => {
        const route = booking.outbound_route || booking.inbound_route
        return moment(route.date_time).unix()
    },
    'passenger-count': booking => booking.passenger_count,
    'booking-date': booking => moment(booking.booking_date_time).unix(),
    payment: booking => booking.price,
    status: booking => booking.cancelled ? 'cancelled' : (booking.provisional ? 'provisional' : '')
}

const sortData = (data, sortColumn, sortOrder) => {
    const mapper = sortColumn in mappers ? mappers[sortColumn] : sortColumn
    return orderBy(data, mapper, sortOrder)
}

export default class MyS3BookingOverviewBookingTable extends Component {
    static propTypes = {
        onSelectBooking: PropTypes.func,
        bookings: PropTypes.arrayOf(PropTypes.shape({
            booking_reference: PropTypes.string.isRequired,
            first_name: PropTypes.string,
            last_name: PropTypes.string,
            passenger_count: PropTypes.number.isRequired,
            booking_date_time: PropTypes.string.isRequired,
            price: PropTypes.number.isRequired,
            outbound_route: PropTypes.shape({
                origin: PropTypes.string,
                destination: PropTypes.string,
                date_time: PropTypes.string
            }),
            inbound_route: PropTypes.shape({
                date_time: PropTypes.string
            }),
            cancelled: PropTypes.bool.isRequired
        })).isRequired
    }

    static defaultProps = {
        onSelectBooking: () => {}
    }

    constructor (...args) {
        super(...args)
        this.state = {
            sortColumn: 'booking-date',
            sortOrder: UI_TABLE_SORT_DESCENDING
        }
        this.renderBooking = this.renderBooking.bind(this)
        this._sortBy = this._sortBy.bind(this)
    }

    render () {
        const {sortColumn, sortOrder} = this.state
        const sortingProps = {sortColumn, sortOrder, onClick: this._sortBy}
        const headerTypes = ['booking-reference', 'route', 'travel-date', 'passenger-count', 'booking-date', 'payment', 'status']
        const headerCells = headerTypes.map(name => (
            <SortTableHeaderCell
                key={name}
                name={name}
                label={_t.getIntlMessage(`my-s3-booking-overview.table-head.${name}`)}
                {...sortingProps}
            />)
        )

        return (
            <table className='highlight-rows'>
                <colgroup>
                    <col className='col-booking-reference' />
                    <col className='col-route' />
                    <col className='col-travel-date' />
                    <col className='col-pax' />
                    <col className='col-booked' />
                    <col className='col-payment' />
                    <col className='col-status' />
                </colgroup>
                <thead>
                    <tr>{headerCells}</tr>
                </thead>
                <tbody>
                    {sortData(this.props.bookings, sortColumn, sortOrder).map(this.renderBooking)}
                </tbody>
            </table>
        )
    }

    renderBooking (booking) {
        // if outbound is not set, the inbound becomes outbound and the inbound is set to null
        const outbound = booking.outbound_route || booking.inbound_route
        const inbound = booking.outbound_route ? booking.inbound_route : null

        const textLabelClassName = classNames('text-label', {'not-available': booking.cancelled})

        return (
            <tr tabIndex='1'
                className={classNames({'not-available': booking.cancelled})}
                onClick={() => this.props.onSelectBooking(booking)} key={booking.booking_reference}
            >
                <td>
                    <span className={classNames(textLabelClassName, 'booking-reference')}>
                        <TextLabel text={booking.booking_reference} />
                    </span>
                </td>
                <td>
                    {outbound ? (
                        <span className={classNames(textLabelClassName, 'route-od')}>
                            <TextLabel text={_t.formatIntlMessage('my-s3-booking-overview.route', {
                                origin: outbound.origin,
                                destination: outbound.destination
                            })}
                            />
                        </span>
                    ) : null}
                    {inbound ? (
                        <span className={classNames(textLabelClassName, 'route-return-trip')}>
                            <TextLabel text={_t.getIntlMessage('my-s3-booking-overview.return-trip')} />
                        </span>
                    ) : null}
                </td>
                <td>
                    {outbound ? (
                        <span className={classNames(textLabelClassName, 'travel-date-outbound')}>
                            <TextLabel
                                text={_t.formatDate(moment(outbound.date_time, 'YYYY-MM-DD').toDate(), 'short')}
                            />
                        </span>
                    ) : null}
                    {inbound ? (
                        <span className={classNames(textLabelClassName, 'travel-date-inbound')}>
                            <TextLabel
                                text={_t.formatDate(moment(inbound.date_time, 'YYYY-MM-DD').toDate(), 'short')}
                            />
                        </span>
                    ) : null}
                </td>
                <td>
                    <span className={classNames(textLabelClassName, 'pax')}>
                        <TextLabel text={`${booking.passenger_count}`} />
                    </span>
                </td>
                <td>
                    <span className={classNames(textLabelClassName, 'booked-date')}>
                        <TextLabel text={_t.formatDate(moment(booking.booking_date_time).toDate(), 'short')} />
                    </span>
                    <span className={classNames(textLabelClassName, 'booked-time')}>
                        <TextLabel text={_t.formatTime(moment(booking.booking_date_time).toDate(), 'short')} />
                    </span>
                </td>
                <td>
                    <span className={classNames(textLabelClassName, 'payment-amount')}>
                        <TextLabel text={_t.formatCurrency(booking.price, 'EUR')} />
                    </span>
                </td>
                <td>
                    <BookingStatus booking={booking} />
                </td>
            </tr>
        )
    }

    _toggleSortOrder (sortOrder) {
        return sortOrder === UI_TABLE_SORT_ASCENDING ? UI_TABLE_SORT_DESCENDING : UI_TABLE_SORT_ASCENDING
    }

    _sortBy (sortColumn) {
        const sortOrder = this.state.sortColumn === sortColumn
            ? this._toggleSortOrder(this.state.sortOrder) : UI_TABLE_SORT_ASCENDING
        this.setState({sortColumn, sortOrder})
    }
}
