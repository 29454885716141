import React, {Component} from 'react'
import PropTypes from 'prop-types'

export default class RadioField extends Component {
    static propTypes = {
        id: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired
    }

    render () {
        const {children, ...other} = this.props
        delete other.type
        delete other.text

        return (
            <div className='check-radio-wrapper'>
                <input type='radio' ref='input' {...other} />
                {children}
            </div>
        )
    }

    getInputElement () {
        return this.refs.input
    }

    getValue () {
        return this.refs.input.value
    }

    setValue (value) {
        this.refs.input.value = value
    }

    focus () {
        return this.refs.input.focus()
    }
}
