import React from 'react'
import {Route} from 'react-router'
import authCheck from '../../../mixins/auth'
import containAfterSalesConfirmationLayout from '../../../layouts/aftersales-confirmation-layout'
import {AFTER_SALES_REBOOK} from '../../../constants'

const AfterSalesConfirmationLayout = containAfterSalesConfirmationLayout(
    AFTER_SALES_REBOOK, {restoreBooking: true}
)

export default (
    <Route
        path='/:lang/mys3/re-booking/confirmation'
        component={AfterSalesConfirmationLayout}
        onEnter={(nextState, replaceState, callback) => authCheck(nextState, replaceState, callback)}
    />
)
