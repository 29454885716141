import React from 'react'
import Reflux from 'reflux'
import device from '../../device'
import BookingLayoutContainer from '../booking-layout-container'
import PassengerDetails from '../../components/booking/passenger-details/details'
import ProgressNavigation from '../../components/booking/passenger-details/progress-navigation'
import BookingStore from '../../reflux/stores/booking-store'
import actions from '../../reflux/actions'
import cmsComponent from '../../components/cms/cms-component'
import CmsBlockContent from '../../components/cms/cms-block-content'
import HelpButton from '../../base/help-button'
import AccessManager from '../../data/access-manager'
import BookingModel from '../../models/booking-model'
import {restoreJourneySearchCompose} from '../../components/restore/restore-journey-search'
import {restoreBookingCompose} from '../../components/restore/restore-booking'
import compose from '../../misc/compose'
import createReactClass from 'create-react-class'

const component = createReactClass({

    displayName: 'BookingPassengerDetailsLayout',

    mixins: [
        Reflux.connectFilter(BookingStore, 'booking', data => BookingModel.create(data.booking)),
        Reflux.listenTo(actions.crmLogin.completed, actions.myS3GetFavoritePassengers)
    ],

    componentDidMount () {
        if (AccessManager.isCrmUser()) {
            actions.myS3GetFavoritePassengers()
        }
    },

    render () {
        const {booking} = this.state
        if (!booking) {
            return null
        }

        const travelDate = booking.isReturnTrip() ? booking.inboundTravelDate : booking.outboundTravelDate
        return (
            <BookingLayoutContainer title='booking-passenger-details' name='booking-details' hideBookingNumber>
                {booking.bookingNumber ? <PassengerDetails booking={booking} travelDate={travelDate} /> : null}
                <ProgressNavigation travelDate={travelDate} />
                {device.isMobile() ? <HelpButton /> : null}
                <div className='data-collection-statement'>
                    <CmsBlockContent name='data-collection-statement' />
                </div>
            </BookingLayoutContainer>
        )
    }
})

const container = compose(
    restoreJourneySearchCompose({withBlockingLoader: true}),
    restoreBookingCompose({withBlockingLoader: true}),
    cmsComponent('passenger-under-sixteen', 'BOOKING-CONTACT')
)

export default container(component)
