import {getState} from '../../../reflux/bridge/connect-state'
import {
    seatSelectionAvailableSelector,
    seatSelectionLegsSelector,
    seatSelectionServicesSelector
} from '../../../models/selectors/components/booking/seat-selection'
import actions from '../../../reflux/actions'
import {createLegId} from '../../../misc/segment'
import {seatSelectionAvailableSeats} from '../../../misc/helpers'

export const loadAvailableSeatSelectorLegs = async numberOfPassengers => {
    const state = getState()
    const seatSelectionAllowed = seatSelectionAvailableSelector(state)
    if (seatSelectionAllowed) {
        const legIds = seatSelectionLegsSelector(state)
        const services = seatSelectionServicesSelector(state).filter(service => legIds.includes(service.legId))

        return getAvailableLegIds(services, numberOfPassengers)
    } else {
        return []
    }
}

export const getAvailableLegIds = async (services, numberOfPassengers) => {
    const availableLegIds = []
    const loadingServices = services.map(async service => {
        const carriageLayout = await actions.getCarriageLayouts(service)
        const availableSeats = carriageLayout && seatSelectionAvailableSeats(carriageLayout)
        if (numberOfPassengers <= availableSeats) {
            const legId = createLegId(
                service.serviceName,
                service.travelDate,
                service.fromStationUIC,
                service.toStationUIC
            )
            availableLegIds.push(legId)
        }
    })

    await Promise.all(loadingServices)

    return availableLegIds
}
