import PropTypes from 'prop-types'
import React from 'react'
import {withRouter} from 'react-router'
import Reflux from 'reflux'
import _t from '../translate'
import BookingModel from '../models/booking-model'
import MyS3CancellationConfirmContainer from './my-s3-cancellation-confirm-layout-container'
import BookingStore from '../reflux/stores/booking-store'
import actions from '../reflux/actions'
import restoreBooking from '../components/restore/restore-booking'
import createReactClass from 'create-react-class'

export default withRouter(restoreBooking(createReactClass({
    displayName: 'MyS3CancellationConfirmLayout',

    propTypes: {
        loading: PropTypes.bool.isRequired,
        router: PropTypes.object
    },

    mixins: [
        Reflux.listenTo(BookingStore, 'onBookingUpdated')
    ],

    getInitialState () {
        return {
            booking: BookingStore.getBookingModel(),
            confirmed: false
        }
    },

    routerWillLeave () {
        if (!this.state.confirmed) {
            actions.revertBooking(this.state.booking.bookingNumber)
        }
    },

    onBookingUpdated (data) {
        if (data.loading || !data.success) {
            return
        }

        this.setState({booking: BookingModel.create(data.booking)})
    },

    onClickConfirm () {
        actions.confirmBooking(this.state.booking.bookingNumber)
            .then(() => {
                this.setState(
                    {confirmed: true},
                    () => {
                        actions.myS3CancellationSuccessful()
                        actions.getBooking(this.state.booking.bookingNumber).then(
                            () => this.props.router.push(
                                `/${_t.getLocales()}/mys3/cancellation/confirmation?s3_status=success`
                            )
                        )
                    }
                )
            })
    },

    render () {
        if (!this.state.booking) {
            return null
        }

        return (
            <MyS3CancellationConfirmContainer
                booking={this.state.booking}
                loading={this.props.loading}
                onClickConfirm={this.onClickConfirm}
            />
        )
    }
}), {withBlockingLoader: true}))
