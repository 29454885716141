import {createSelector} from 'reselect'
import {bookingSelector} from '../../../../../reflux/bridge/booking'
import {voucherSelector} from './vouchers'

export const totalPriceBookingSelector = createSelector(
    [bookingSelector],
    booking => booking ? booking.total_price : 0
)

export const totalPriceSelector = createSelector(
    [
        totalPriceBookingSelector,
        voucherSelector
    ],
    (totalPrice, vouchers) => (vouchers || []).reduce(
        (total, voucher) => voucher.payment.amount === 0 ? total + voucher.amount : total,
        totalPrice
    )
)

export const currencyBookingSelector = createSelector(
    [bookingSelector],
    booking => booking ? booking.currency : 0
)
