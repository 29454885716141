import {PhoneNumberUtil, PhoneNumberFormat} from 'google-libphonenumber'
import _ from 'lodash'
import Storage from './storage'
import ReactIntl from 'react-intl/src/main'

const phoneUtil = PhoneNumberUtil.getInstance()
const IntlMixin = ReactIntl.IntlMixin

const Extend = {

    defaultLocale: 'en-GB',
    defaultCurrency: 'EUR',

    props: {},

    initialize () {
        this.setLocale(Storage.get('locale', this.defaultLocale))
        this.setCurrency(Storage.get('currency', this.defaultCurrency))
    },

    setLocale (locale) {
        let loader = require('translate-loader')

        let formats = loader.loadLocaleDataFile(this.defaultLocale, 'formats')
        let messages = loader.loadLocaleDataFile(this.defaultLocale, 'messages')
        let settings = loader.loadLocaleDataFile(this.defaultLocale, 'settings')

        if (locale !== this.defaultLocale) {
            formats = _.merge(formats, loader.loadLocaleDataFile(locale, 'formats'))
            messages = _.merge(messages, loader.loadLocaleDataFile(locale, 'messages'))
            settings = _.merge(settings, loader.loadLocaleDataFile(locale, 'settings'))
        }

        this.props.locales = locale
        this.props.messages = messages
        this.props.formats = formats
        this.props.settings = settings

        Storage.set('locale', this.props.locales)
    },

    setCurrency (currency) {
        this.props.currency = currency
        this.props.formats.number.currency.currency = currency
        Storage.set('currency', this.props.currency)
    },

    getLanguage () {
        const map = {
            'cs-CZ': 'cs',
            'de-DE': 'de',
            'en-GB': 'en-GB',
            'es-ES': 'es',
            'fr-FR': 'fr',
            'hr-HR': 'hr',
            'hu-HU': 'hu',
            'it-IT': 'it',
            'nl-BE': 'nl-BE',
            'nl-NL': 'nl',
            'pt-PT': 'pt',
            'sk-SK': 'sk'
        }

        return map[this.props.locales]
    },

    getRegion (locale) {
        return (locale || this.props.locales).split('-')[1]
    },

    getDefaultRegion () {
        return this.getRegion(this.defaultLocale)
    },

    getLocales () {
        return this.props.locales
    },

    getFormats () {
        return this.props.formats
    },

    getCurrency () {
        return this.props.currency
    },

    getSettings () {
        return this.props.settings
    },

    getSetting (setting, defaultValue) {
        return _.get(this.props.settings, setting, defaultValue)
    },

    formatCurrency (value, currency) {
        this._addCurrencyToFormats()

        let options = this.props.formats.number.currency
        if (typeof currency !== 'undefined') {
            options = _.clone(this.props.formats.number.currency)
            options.currency = currency
        }

        return this._patchString(this.formatNumber(value, options))
    },

    formatIntlMessage (path, values) {
        this._addCurrencyToFormats()

        return this._patchString(this.formatMessage(this.getIntlMessage(path), values))
    },

    safeFormatIntlMessage (path, values) {
        return this.hasMessage(path, values) && this.formatIntlMessage(path, values)
    },

    formatPhoneNumber (value, region) {
        try {
            const phoneNumber = phoneUtil.parse(value, region || this.getDefaultRegion())
            return phoneUtil.format(phoneNumber, PhoneNumberFormat.INTERNATIONAL)
        } catch (error) {
            return value
        }
    },

    message (message, context = null) {
        return context ? this.formatIntlMessage(message, context) : this.getIntlMessage(message)
    },

    hasMessage (...args) {
        let result
        try {
            result = Boolean(this.message(...args))
        } catch (e) {
            return false
        }
        return result
    },

    _patchString (value) {
        if (this.props.locales === 'fr-FR') {
            value = value.replace('£GB', '£')
        }

        return value
    },

    _addCurrencyToFormats () {
        if (!_.has(this.props.formats.number.currency, 'currency')) {
            // add currency value to the currency number format
            this.props.formats.number.currency.currency = this.props.currency
        }
    }
}

const Translate = _.merge(IntlMixin, Extend)

Translate.initialize()

export default Translate
