/* globals S3P_SETTINGS: true */

import _t from '../translate'

export const getUnauthorizedPage = () => {
    const unauthorizedPages = S3P_SETTINGS.s3Passenger.features.unauthorizedPages
    const language = _t.getLocales()
    const languageShort = language === 'en-GB' ? 'co.uk' : language.substring(3, 5).toLowerCase()
    return process.env.NODE_ENV === 'development' ? `/${language}${unauthorizedPages.default}` : `https://www.blablacar.${languageShort}/bus`
}
