/* global document: false */
/* globals DEBUG_MODE: true */
/* globals S3P_SETTINGS: true */

import './misc/detectizr'
import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import Reflux from 'reflux'
import RefluxPromise from 'reflux-promise'
import storage from './storage'
import {
    Router,
    withRouter
} from 'react-router'
import device from './device'
import {init, captureException, showReportDialog} from '@sentry/browser'
import {isTrue} from './misc/helpers'
import {showBlablaCarBranding} from './misc/blablabus-helpers'
import initializeRouter from './router'
import createReactClass from 'create-react-class'

// eslint-disable-next-line no-unused-vars
import GoogleTagManagerClient from './misc/google-tag-manager-client'
import {getUnauthorizedPage} from './misc/unauthorized-pages'

require('babel-polyfill')

Reflux.use(RefluxPromise(window.Promise))

const loadCSS = async () => {
    if (showBlablaCarBranding) {
        return import(/* webpackChunkName: "blablacar" */ '../assets/scss/blablacar.scss')
    } else {
        // default blablabus styling
        return import(/* webpackChunkName: "blablabus" */ '../assets/scss/blablabus.scss')
    }
}

const runApp = async () => {
    await loadCSS()
    const SystemErrorLayout = require('layouts/system-error-layout').default
    if (!storage.sessionStorageExists()) {
        ReactDOM.render(
            <SystemErrorLayout />,
            document.getElementById('app')
        )
        return
    }

    const Translate = require('translate').default
    const moment = require('moment-timezone')
    /* eslint-disable no-unused-expressions */
    require('moment/locale/cs').default
    require('moment/locale/de').default
    require('moment/locale/en-gb').default
    require('moment/locale/es').default
    require('moment/locale/fr').default
    require('moment/locale/hr').default
    require('moment/locale/hu').default
    require('moment/locale/it').default
    require('moment/locale/nl').default
    require('moment/locale/nl-be').default
    require('moment/locale/pl').default
    require('moment/locale/pt').default
    require('moment/locale/sk').default
    /* eslint-enable no-unused-expressions */

    const actions = require('reflux/actions').default
    const svg4everybody = require('svg4everybody')
    const oauth = require('oauth').default
    const StationStore = require('reflux/stores/stations-store').default
    const BookingStore = require('reflux/stores/booking-store').default
    const TokenStore = require('reflux/stores/token-store').default
    const AccessManager = require('data/access-manager').default
    const Loader = require('element/loader').default
    const routeCallback = require('routes').default
    const AppContainer = require('app-container').default
    const CmsProvider = require('./components/cms/cms-provider').default
    const BodyClass = require('misc/body-class')
    const reactHistory = initializeRouter()

    svg4everybody()

    let App = withRouter(createReactClass({

        displayName: 'App',

        propTypes: {
            router: PropTypes.object,
            params: PropTypes.object
        },

        mixins: [
            Reflux.listenTo(StationStore, 'onLoadStations'),
            Reflux.listenTo(BookingStore, 'onLoadBooking'),
            Reflux.listenTo(TokenStore, 'onGetToken')
        ],

        getInitialState () {
            return {
                showBlockingLoader: oauth.hasToken(),
                loadingBooking: false
            }
        },

        onLoadStations (data) {
            if (data.loading === false) {
                this.setState({showBlockingLoader: false})
            }
        },

        onLoadBooking (data) {
            if (this.state.loadingBooking && data.loading === false) {
                this.setState({
                    loadingBooking: false,
                    showBlockingLoader: false
                })
            }
        },

        onGetToken (data) {
            if (this.state.showBlockingLoader && !data.hasToken) {
                this.setState({
                    showBlockingLoader: false
                })
                this.props.router.replace(
                    `/${Translate.getLocales()}${S3P_SETTINGS.s3Passenger.features.unauthorizedPages.default}`
                )
            }
        },

        componentWillUpdate (nextProps) {
            nextProps.params.lang && this.updateLocale(nextProps.params.lang)

            if (!nextProps.params.lang) {
                this.props.router.replace(
                    `/${Translate.getLocales()}${S3P_SETTINGS.s3Passenger.features.unauthorizedPages.default}`
                )
            }

            this.updateBodyClass()
        },

        componentWillMount () {
            if (this.props.params.lang) {
                this.updateLocale(this.props.params.lang)
            } else if (this.props.params.splat) {
                this._isSupportedLocale(this.props.params.splat.substring(0, 5))
                    ? this.updateLocale(this.props.params.splat.substring(0, 5))
                    : this.updateLocale(S3P_SETTINGS.i18n.fallbackLocale)
                const url = getUnauthorizedPage()
                this._isAbsoluteUrl(url)
                    ? window.location.href = url
                    : window.reacthistory.pushState(null, url)
            } else {
                this.updateLocale(this._getLocaleFromDevice())
                this.props.router.replace(
                    `/${this._getLocaleFromDevice()}${S3P_SETTINGS.s3Passenger.features.unauthorizedPages.default}`
                )
            }
            this.updateBodyClass()
            if (AccessManager.isCrmUser()) {
                actions.loadCrmUserInformation()
            }
        },

        componentDidMount () {
            GoogleTagManagerClient.pushVars({
                'gtm.start': new Date().getTime(),
                event: 'gtm.js'
            })
        },

        updateBodyClass () {
            BodyClass.addBodyClass('new-ouibus')
        },

        updateLocale (locale) {
            locale = this._getMappedLocale(locale)
            locale = this._isSupportedLocale(locale) ? locale : S3P_SETTINGS.i18n.fallbackLocale

            if (locale) {
                loadCSS()

                const prevLocale = Translate.getLocales()
                Translate.setLocale(locale)
                moment.locale(locale)
                this._updateTitleAndMetaDescription()
                this._setFavicon('blablacar')
                if (locale !== prevLocale) {
                    actions.changeLocale(locale)
                    if (oauth.hasToken()) {
                        actions.loadStations(S3P_SETTINGS.s3Passenger.features.stations)
                    }
                }
            }
        },

        _isAbsoluteUrl (url) {
            return /^https?:\/\//i.test(url)
        },

        _setFavicon (path) {
            // fix for IE11
            let nodes = Array.from(document.querySelectorAll("link[rel*='icon']"))

            nodes.forEach(node => {
                const href = node.href.replace(/img\/favicon\/.+\//g, `img/favicon/${path}/`)
                return node.setAttribute('href', href)
            })
        },

        _getMappedLocale (locale) {
            return locale in S3P_SETTINGS.i18n.mappedLocales ? S3P_SETTINGS.i18n.mappedLocales[locale] : locale
        },

        _isSupportedLocale (locale) {
            return S3P_SETTINGS.i18n.supportedLocales.some(supportedLocale => supportedLocale === locale)
        },

        _getLocaleFromDevice () {
            const language = device.getLanguage()

            return S3P_SETTINGS.i18n.supportedLocales.find(supportedLocale =>
                supportedLocale.substring(0, 2).toLowerCase() === language.substring(0, 2).toLowerCase()
            )
        },

        _updateTitleAndMetaDescription () {
            document.title = showBlablaCarBranding ? Translate.getIntlMessage('title-blablacar') : Translate.getIntlMessage('title')

            let meta = document.getElementsByTagName('meta')
            for (let i = 0; i < meta.length; i++) {
                if (meta[i].name.toLowerCase() === 'description') {
                    meta[i].content = showBlablaCarBranding ? Translate.getIntlMessage('meta.description-blablacar') : Translate.getIntlMessage('meta.description')
                }
                if (meta[i].name.toLowerCase() === 'title') {
                    meta[i].content = document.title
                }
                if (meta[i].name.toLowerCase() === 'og-description') {
                    meta[i].content = showBlablaCarBranding ? Translate.getIntlMessage('meta.description-blablacar') : Translate.getIntlMessage('meta.description')
                }
                if (meta[i].name.toLowerCase() === 'og-title') {
                    meta[i].content = document.title
                }
            }
        },

        render () {
            return this.state.showBlockingLoader ? <Loader blocking />
                : <AppContainer>
                    {React.cloneElement(this.props.children, {lang: Translate.getLocales()})}
                </AppContainer>
        }
    }))

    let routes = routeCallback(App)

    ReactDOM.render(
        <CmsProvider debounceTime={100}>
            <Router
                history={reactHistory}
                routes={routes}
            />
        </CmsProvider>,
        document.getElementById('app')
    )
    if (oauth.hasToken()) {
        actions.loadStations(S3P_SETTINGS.s3Passenger.features.stations)
    }
    if (DEBUG_MODE) {
        window.actions = actions
    }
}

const _ravenWrappedRunApp = () => {
    // eslint-disable-next-line no-extra-boolean-cast
    if (Boolean(S3P_SETTINGS.s3Passenger.sentry.dsn) &&
        S3P_SETTINGS.s3Passenger.sentry.dsn.match(/sentry.io/)
    ) {
        init({
            dsn: S3P_SETTINGS.s3Passenger.sentry.dsn,
            environment: S3P_SETTINGS.s3Passenger.sentry.environment,
            release: S3P_SETTINGS.s3Passenger.release,
            beforeSend (event, hint) {
                if (hint.originalException instanceof ReferenceError) {
                    return null
                }

                if (isTrue(S3P_SETTINGS.s3Passenger.sentry.enableReportDialog) &&
                    (event.extra && event.extra.unhandledApiError) !== false
                ) {
                    showReportDialog()
                }
                return event
            }
        })

        try {
            runApp()
        } catch (e) {
            captureException(e)
            throw e
        }
    } else {
        runApp()
    }
}

// Intl fix for older browsers
if (!window.Intl) {
    require.ensure([], require => {
        require('intl')
        require('intl/locale-data/jsonp/cs-CZ')
        require('intl/locale-data/jsonp/de-DE')
        require('intl/locale-data/jsonp/en-GB')
        require('intl/locale-data/jsonp/es-ES')
        require('intl/locale-data/jsonp/fr-FR')
        require('intl/locale-data/jsonp/hr-HR')
        require('intl/locale-data/jsonp/hu-HU')
        require('intl/locale-data/jsonp/it-IT')
        require('intl/locale-data/jsonp/nl-BE')
        require('intl/locale-data/jsonp/nl-NL')
        require('intl/locale-data/jsonp/pt-PT')
        require('intl/locale-data/jsonp/sk-SK')
        _ravenWrappedRunApp()
    })
} else {
    _ravenWrappedRunApp()
}
