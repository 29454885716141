import React, {Component} from 'react'
import Translate from '../translate'
import ReactIntl from 'react-intl/src/main'

const FormattedMessageComponent = ReactIntl.FormattedMessage

export default class FormattedMessage extends Component {
    render () {
        return <FormattedMessageComponent
            {...this.props}
            locales={Translate.getLocales()}
        />
    }
}
