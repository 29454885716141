import PropTypes from 'prop-types'
import React from 'react'
import Reflux from 'reflux'
import _ from 'lodash'
import _t from '../../translate'
import TextLabel from '../../element/text-label'
import PersonalInformationContent from './customer/personal-information-content'
import CustomerInfoStore from '../../reflux/stores/customer-info-store'
import AffiliateStore from '../../reflux/stores/affiliate-store'
import BookingStore from '../../reflux/stores/booking-store'
import BookingModel from '../../models/booking-model'
import actions from '../../reflux/actions'
import {SERVICE_TYPE_NAME_TRANSALTITUDE, SERVICE_TYPE_MODALITY_BUS, NEWSLETTER_OPTIN} from '../../constants'
import createReactClass from 'create-react-class'

export default createReactClass({

    mixins: [
        Reflux.listenTo(CustomerInfoStore, 'updateCustomerFormData'),
        Reflux.listenTo(BookingStore, 'onUpdateBooking')
    ],

    propTypes: {
        onComplete: PropTypes.func,
        booking: PropTypes.object.isRequired,
        clickButton: PropTypes.func.isRequired
    },

    getInitialState () {
        return {
            loading: false,
            continue: false,
            customerFormData: CustomerInfoStore.getInitialState(),
            affiliateCode: AffiliateStore.getAffiliateCode()
        }
    },

    updateCustomerFormData (data) {
        let newState = {continue: false, customerFormData: data}
        if (data.isValid && this.state.continue) {
            let phoneNumber = data.fields.phone.value
            if (phoneNumber && CustomerInfoStore._validPhoneNumber(phoneNumber, data.fields.countryCode.value) !== null) {
                // Do this because when phoneNumber is invalid. It contains a countryCode calling code which must be cleared
                actions.processCustomerInfoData([{id: 'phone', value: ''}])
            } else {
                const isOptIn = this.props.booking.additional_details && this.props.booking.additional_details.find(additionalDetail =>
                    additionalDetail.key === NEWSLETTER_OPTIN)
                phoneNumber = _t.formatPhoneNumber(phoneNumber, data.fields.countryCode.value)
                const customer = _.omitBy({
                    gender: (data.fields.gender && data.fields.gender.value) || null,
                    first_name: data.fields.firstName.value,
                    last_name: data.fields.lastName.value,
                    email: data.fields.emailAddress.value,
                    phone_number: phoneNumber,
                    address: {
                        postal_code: isOptIn && isOptIn.value && data.fields.postalCode && data.fields.postalCode.value,
                        country_code: isOptIn && isOptIn.value && data.fields.country.value
                    }
                }, value => _.isUndefined(value) || _.isNull(value))

                if (BookingStore.getBooking().customer) {
                    actions.updateCustomer(this.props.booking.booking_number, customer)
                } else {
                    actions.addCustomer(this.props.booking.booking_number, customer)
                }

                this.props.onComplete && this.props.onComplete(true)
            }
        }

        this.setState(newState)
    },

    onUpdateBooking (data) {
        let newState = {loading: data.loading}
        let continueCallback = () => null

        if (data.messages.length > 0 ||
            data.lastCall === 'removeVoucher'
        ) {
            this.setState(newState)
            return
        }

        this.setState(newState, continueCallback)
    },

    _hasBusModality (serviceTypeName) {
        const booking = BookingModel.create(this.props.booking)

        return booking.tariffSegmentCollection.hasSegmentsWithModality(
            SERVICE_TYPE_MODALITY_BUS,
            [serviceTypeName]
        )
    },

    render () {
        const booking = BookingModel.create(this.props.booking)
        return (
            <div>
                <div className='row'>
                    <div className='personal-information'>
                        <div className='panel'>
                            <header>
                                <span className='text-label heading'>
                                    <TextLabel text={_t.getIntlMessage('personal-information-form.header')} />
                                </span>
                            </header>
                            <div className='content'>
                                <PersonalInformationContent
                                    clickButton={this.props.clickButton}
                                    onContinue={this.continue}
                                    passengers={booking.passengers.value()}
                                    customer={booking.customer}
                                    shouldCreateAccount={false}
                                    formData={this.state.customerFormData}
                                    affiliateCode={this.state.affiliateCode}
                                    hasTransAltitude={this._hasBusModality(SERVICE_TYPE_NAME_TRANSALTITUDE)}
                                    additionalDetails={this.props.booking.additional_details}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    },

    continue (cb = () => {}) {
        this.setState({continue: true}, cb)
    }
})
