import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from '../../../translate'
import ModalDialog from '../../../element/modal-dialog'
import Button from '../../../element/button'
import Icon from '../../../element/icon'
import TextLabel from '../../../element/text-label'
import Loader from '../../../element/loader'
import BookingModel from '../../../models/booking-model'
import {cmsBlockContainer} from '../../cms/cms-block-container'

const PREFIX = 'booking-specification.refund.method-voucher.confirm-dialog'

class RequestVoucherModalDialog extends Component {
    static propTypes = {
        title: PropTypes.string,
        content: PropTypes.string,
        isCmsLoading: PropTypes.bool.isRequired,
        booking: PropTypes.instanceOf(BookingModel).isRequired,
        isLoadingRequestVoucher: PropTypes.bool.isRequired,
        handleClose: PropTypes.func.isRequired,
        handleRequestRefundVoucher: PropTypes.func.isRequired,
        handlePrintRefundVoucher: PropTypes.func.isRequired,
        shouldPrint: PropTypes.bool.isRequired,
        refundVoucher: PropTypes.shape({
            code: PropTypes.string.isRequired,
            amount: PropTypes.number.isRequired,
            currency: PropTypes.string.isRequired,
            sales_period_end: PropTypes.string.isRequired
        })
    }

    render () {
        return (
            <ModalDialog
                headerTitle={this.props.title || _t.message(`${PREFIX}.title`)}
                onClose={this.props.handleClose}
            >
                <div className='content'>
                    {this._renderContent()}
                    <div className='actions'>
                        <ul className='horizontal-list'>
                            <li>
                                <Button
                                    className='button default print-voucher'
                                    onClick={this.props.handlePrintRefundVoucher}
                                    type='button'
                                    loading={this.props.isLoadingRequestVoucher && this.props.shouldPrint}
                                >
                                    <Icon type='print' className='small align-left' />
                                    <TextLabel text={_t.message(`${PREFIX}.print-voucher-button`)} />
                                </Button>
                            </li>
                            <li>
                                <Button
                                    className='button primary'
                                    onClick={this.props.handleRequestRefundVoucher}
                                    type='button'
                                    loading={this.props.isLoadingRequestVoucher && !this.props.shouldPrint}
                                >
                                    <TextLabel text={_t.message(`${PREFIX}.confirm-button`)} />
                                    <Icon type='chevron-right' className='small align-right' />
                                </Button>
                            </li>
                        </ul>
                    </div>
                </div>

            </ModalDialog>
        )
    }

    _renderContent () {
        if (!this.props.content && this.props.isCmsLoading) {
            return <Loader />
        } else if (!this.props.content) {
            return null
        }

        /* eslint-disable no-template-curly-in-string */
        const content = this.props.content
            .replace('${customerEmail}', this.props.booking.customer.email)
            .replace('${voucherAmount}', _t.formatCurrency(this.props.booking.openRefundAmount))
        /* eslint-enable no-template-curly-in-string */

        return (
            <div className='cms-block'>
                <span dangerouslySetInnerHTML={{__html: content}} />
            </div>
        )
    }
}

export default cmsBlockContainer('AFTERSALES-FLEX-VOUCHER-POPUP', RequestVoucherModalDialog)
