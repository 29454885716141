import Reflux from 'reflux'
import FormValidationMixin from '../mixin/form-validation-mixin'
import UserAwareMixin from '../mixin/user-aware-mixin'
import ResponseCodes from '../../api/response-codes'
import actions from '../actions'

export default Reflux.createStore({

    listenables: actions,

    mixins: [FormValidationMixin, UserAwareMixin],

    init () {
        this._resetData()

        this.validators = {
            firstName: [
                value => this._validateMinLength(value, 3)
            ],
            lastName: [
                value => this._validateMinLength(value, 3)
            ],
            emailAddress: [
                this._validateEmail
            ],
            repeatEmailAddress: [
                this._validateEmail,
                value => this._compareValues(value, this.data.fields.emailAddress.value)
            ]
        }
    },

    _resetData () {
        this.data = {
            loading: false,
            completed: false,
            isValid: false,
            fields: {
                firstName: {
                    value: '',
                    errorText: null,
                    isRequired: true
                },
                lastName: {
                    value: '',
                    errorText: null,
                    isRequired: true
                },
                emailAddress: {
                    value: '',
                    errorText: null,
                    isRequired: true
                },
                repeatEmailAddress: {
                    value: '',
                    errorText: null,
                    isRequired: true
                }
            }
        }
    },

    resetData () {
        this._resetData()
        this.trigger(this.data)
    },

    getInitialState () {
        return this.data
    },

    onRegisterCrmUser () {
        this.data.loading = true
        this.data.completed = false
        this.trigger(this.data)
    },

    onApiError (error) {
        this.data.loading = false

        let handled = true
        switch (error.code) {
            case ResponseCodes.CRM_REGISTER_EMAIL_EXISTS:
                this.data.fields.emailAddress.errorText = 'exists'
                break
            case ResponseCodes.CRM_REGISTER_EMAIL_INVALID:
                this.data.fields.emailAddress.errorText = 'invalid-email-address'
                break
            default:
                handled = false
                break
        }

        if (handled) {
            error.handled = handled
            this.trigger(this.data)
        }
    },

    onRegisterCrmUserCompleted () {
        this.data.loading = false
        this.data.completed = true
        this.trigger(this.data)
    },

    onProcessCrmRegisterData (newData) {
        if (typeof newData !== 'undefined') {
            // also re-validate repeatEmailAddress on emailAddress change
            if (newData.filter(record => record.id === 'emailAddress').length === 1 &&
                newData.filter(record => record.id === 'repeatEmailAddress').length === 0 &&
                this.data.fields.repeatEmailAddress.value
            ) {
                newData.push({
                    id: 'repeatEmailAddress',
                    value: this.data.fields.repeatEmailAddress.value
                })
            }

            newData.forEach(record => {
                this.data.fields[record.id].value = record.value
            })

            this._validateData(newData)
        }
    }
})
