import PropTypes from 'prop-types'
import React, {Component} from 'react'
import {Link} from 'react-router'
import {
    canAddLuggageAfterSalesSelector,
    existingLuggageProductsPerPassengerBookingForDirectionSelector
} from '../../../models/selectors/components/booking/luggage-selection'
import {getState} from '../../../reflux/bridge/connect-state'
import _t from '../../../translate'
import TextLabel from '../../../element/text-label'
import Icon from '../../../element/icon'
import get from 'lodash/get'
import {TRAVEL_DIRECTION_INBOUND, TRAVEL_DIRECTION_OUTBOUND} from '../../../models/selectors/constants'
import {cmsBlockContainer} from '../../cms/cms-block-container'
import {CmsContentTextLabel} from '../../cms/cms-text-label'
import isEmpty from 'lodash/isEmpty'

const LuggageCmsContentTextLabel = cmsBlockContainer('additional-luggage-summary', CmsContentTextLabel)

class MyS3LuggageSummary extends Component {
    static propTypes = {
        luggageSelectorRoute: PropTypes.string
    }

    render () {
        const canAddLuggageAfterSales = canAddLuggageAfterSalesSelector(getState())
        return (
            <div className='luggage-selection'>
                <div className='grid-row'>
                    <div className='grid-column--1-1'>
                        <div className='text-label select-luggage-summary'>
                            <LuggageCmsContentTextLabel />
                        </div>
                    </div>
                </div>
                {this.renderTravel(TRAVEL_DIRECTION_OUTBOUND)}
                {this.renderTravel(TRAVEL_DIRECTION_INBOUND)}
                {canAddLuggageAfterSales && (
                    <div className='grid-row'>
                        <div className='grid-column--1-1'>
                            <Link
                                className='luggage-selection'
                                to={`/${_t.getLocales()}${this.props.luggageSelectorRoute}`}
                            >
                                <span className='text-label'>
                                    <Icon type='luggage-selector' className='medium align-left' />
                                    <span className='text'>{_t.message('luggage-selection.add')}</span>
                                </span>
                            </Link>
                        </div>
                    </div>
                )}
            </div>
        )
    }

    renderTravel = direction => {
        const passengersLuggage = existingLuggageProductsPerPassengerBookingForDirectionSelector(direction)(getState())

        return !isEmpty(passengersLuggage) ? (
            <div className='luggage-summary'>
                <div className={`passenger-selector`}>
                    <div className='summary'>
                        <span className='text-label summary-title'>
                            <TextLabel text={_t.getIntlMessage(`seat-selector.directions.${direction}`)} />
                            {direction === 'inbound'
                                ? <Icon className='fleche-left medium align-right' type='fleche-left' />
                                : <Icon className='fleche-right medium align-right' type='fleche-right' />
                            }
                        </span>
                    </div>
                    <div className='luggage-summary-passenger-list'>
                        <ul>
                            {Object.values(passengersLuggage).map(this.renderPassenger)}
                        </ul>
                    </div>
                </div>
            </div>
        ) : null
    }

    renderPassenger = ({passenger, amount, totalPrice}) => {
        const firstName = get(passenger, 'firstName', '')
        const lastName = get(passenger, 'lastName', '')
        const text = `${firstName || ''} ${lastName || ''}`
        return (
            <li key={passenger.id} onClick={this.handleClickSelect}>
                <div className='luggage-summary-passenger-list--item'>
                    <span className='text-label passenger-name'>
                        <TextLabel text={text} />
                    </span>
                    <span className='text-label luggage-price'>
                        <TextLabel text={_t.formatIntlMessage('luggage-selection.summary', {amount, price: _t.formatCurrency(totalPrice)})} />
                    </span>
                </div>
            </li>
        )
    }
}

export default MyS3LuggageSummary
