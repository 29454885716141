// Libraries
import PropTypes from 'prop-types'

import React, { Component } from 'react'
import _t from '../../translate'
import Icon from '../../element/icon'
import TextLabel from '../../element/text-label'
import BookingModel from '../../models/booking-model'

export default class MyS3UpdatePassengersInformation extends Component {
    static propTypes = {
        booking: PropTypes.instanceOf(BookingModel).isRequired
    }

    render () {
        const nameChangeAfterSalesRule = this.props.booking.cheapestNameChangeAftersalesRule

        return (
            <div className='modify-passenger-details-information'>
                <div className='panel'>
                    <header>
                        <h2>{_t.getIntlMessage('update-passengers-information.header')}</h2>
                    </header>
                    <div className='content'>
                        <span className='text-label information'>
                            <Icon type='information' className='small align-left' />
                            <TextLabel text={_t.formatIntlMessage('update-passengers-information.content', {
                                price: (nameChangeAfterSalesRule && nameChangeAfterSalesRule.price) || 0,
                                feeCalculationType:
                                    (nameChangeAfterSalesRule && nameChangeAfterSalesRule.fee_calculation_type) || ''
                            })} />
                        </span>
                    </div>
                </div>
            </div>
        )
    }
}
