import React from 'react'
import {Route} from 'react-router'
import authCheck from '../../../mixins/auth'
import MyS3SeatEditSeatLayout from '../../../layouts/mys3/seat/edit-seat-layout'

export default (
    <Route
        path='/:lang/mys3/edit-seats/:id'
        component={MyS3SeatEditSeatLayout}
        onEnter={(nextState, replaceState, callback) => authCheck(nextState, replaceState, callback)}
    />
)
