import omitBy from 'lodash/omitBy'
import get from 'lodash/get'
import moment from 'moment-timezone'
import S3WorldPay from '../../../s3-world-pay'
import { snakeCaseKeys } from '../../../misc/camelcase'
import {
    PAYMENT_FORM_WPAY_CREDITCARD_ENCRYPTED,
    PAYMENT_FORM_WPAY_CREDITCARD,
    PAYMENT_FORM_ADYEN_CREDITCARD_ENCRYPTED,
    PAYMENT_FORM_ADYEN_IDEAL,
    PAYMENT_FORM_WPAY_BANCONTACT,
    PAYMENT_FORM_CASH,
    PAYMENT_FORM_ADYEN_PAYPAL,
    PAYMENT_FORM_ADYEN_SOFORT
} from '../../../constants'

export const getPaymentPreferences = (formName, method, fields) => {
    let preferences = {}

    switch (formName) {
        case PAYMENT_FORM_WPAY_CREDITCARD_ENCRYPTED:
            const date = moment(fields.validUntil.value, 'MM/YY')

            preferences.encrypted_data = S3WorldPay.encrypt(
                {
                    cvc: fields.cvcCode.value,
                    cardHolderName: fields.cardHolder.value,
                    cardNumber: fields.cardNumber.value,
                    expiryMonth: date.isValid() && date.month() + 1,
                    expiryYear: date.isValid() && date.year()
                },
                get(method, 'public_key') || ''
            )
            break
        case PAYMENT_FORM_WPAY_CREDITCARD:
            preferences.credit_card = omitBy({
                type: get(method, 'card_type'),
                cvc: fields.cvcCode.value,
                number: fields.cardNumber.value,
                holder: fields.cardHolder.value,
                expiration_month: parseInt(fields.validUntilMonth.value),
                expiration_year: parseInt(fields.validUntilYear.value)
            }, value => value === '')
            break
        case PAYMENT_FORM_ADYEN_CREDITCARD_ENCRYPTED:
            preferences = snakeCaseKeys(fields.data.value)
            break
        case PAYMENT_FORM_ADYEN_IDEAL:
            preferences.bank_code = fields.bankCode.value
            break
        case PAYMENT_FORM_ADYEN_PAYPAL:
        case PAYMENT_FORM_ADYEN_SOFORT:
            preferences.payment_provider = 'Adyen'
            break
        case PAYMENT_FORM_WPAY_BANCONTACT:
        case PAYMENT_FORM_CASH:
            break
    }

    return preferences
}
