// libraries
import React, {Component} from 'react'
import PropTypes from 'prop-types'
import device from '../device'

// Components
import journeyResultSet from './journey-result-set'
import InteractiveFlashMessage from './interactive-flash-message'
import JourneyResultSetPlaceholder from './journey-search/result-set-placeholder'
import {connectState, getState} from '../reflux/bridge/connect-state'
import {selectedBundlesSelector} from '../models/selectors/components/orientation/journey-search'

class JourneyResultContainer extends Component {
    static propTypes = {
        loading: PropTypes.bool,
        hasOffer: PropTypes.bool,
        affiliateCode: PropTypes.string
    }

    render () {
        return this.props.loading ? null : this.renderContent()
    }

    renderContent () {
        return (
            <div className='journey-result-set'>
                <InteractiveFlashMessage />
                <JourneyResultSetPlaceholder
                    show={device.isDesktopOrTablet() && !this.props.hasOffer}
                    affiliateCode={this.props.affiliateCode}
                />
                {this.props.children}
            </div>
        )
    }
}

const mapPropsToProps = () => ({
    selectedBundles: selectedBundlesSelector(getState())
})

const container = connectState(mapPropsToProps)

export default container(journeyResultSet(JourneyResultContainer))
