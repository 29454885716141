import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from '../../../../translate'
import CmsBlockContent from '../../../cms/cms-block-content'
import TextLabel from '../../../../element/text-label'
import FlexConditionsModal from './flex-conditions-modal'

export default class FlexConditions extends Component {
    static propTypes = {
        isBigGroup: PropTypes.bool.isRequired,
        isNel: PropTypes.bool,
        isAlsa: PropTypes.bool
    }

    constructor (...args) {
        super(...args)
        this.state = {modalVisible: false}
        this.openModal = this.openModal.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.getBlockName = this.getBlockName.bind(this)
    }

    openModal (event) {
        event && event.preventDefault()
        this.setState({modalVisible: true})
    }

    closeModal (event) {
        event && event.preventDefault()
        this.setState({modalVisible: false})
    }

    getBlockName () {
        if (this.props.isAlsa) {
            return 'PRODUCT-STATIC-DESCRIPTION'
        } else if (this.props.isNel) {
            return 'PRODUCT-STATIC_2-DESCRIPTION'
        } else return this.props.isBigGroup ? 'BOOKING-SS-FLEX-DESCRIPTION10' : 'BOOKING-SS-FLEX-DESCRIPTION'
    }

    render () {
        const blockName = this.getBlockName()
        const modalName = this.props.isNel ? 'BOOKING-STATIC_2-POP' : this.props.isAlsa ? 'BOOKING-STATIC-POP' : 'BOOKING-SS-FLEX-POP'
        return (
            <div>
                <div className='flex-bundle-description'>
                    <CmsBlockContent name={blockName}>
                        <a href='#' className='flex-bundle-conditions' onClick={this.openModal}>
                            {this.props.isNel || this.props.isAlsa ? (
                                <div>
                                    <TextLabel
                                        text={_t.message('journey-result-set.journey-details.conditions.alsa-nel-conditions')}
                                    />
                                </div>
                            ) : (
                                <TextLabel
                                    text={_t.message('journey-result-set.journey-details.conditions.open-modal-link')}
                                />
                            )}
                        </a>
                    </CmsBlockContent>
                </div>
                {this.state.modalVisible ? (
                    <FlexConditionsModal name={modalName} onClose={this.closeModal} />
                ) : null}
            </div>
        )
    }
}
