import {createSelector} from 'reselect'
import uniq from 'lodash/uniq'
import {
    PRODUCT_CODE_SEAT_SELECTION,
    PRODUCT_CODE_SEAT_SELECTION_PLUS
} from '../../../../constants'
import {selectedBundlesSelector} from '../orientation/journey-search'
import {routesSelector} from '../../api/v2/orientation/journey-search'
import {isSeatProductCode} from '../../../../misc/helpers'
import {productSearchSelector} from '../../../../reflux/bridge/product-search'

export const seatSelectionLegsSelector = createSelector(
    [selectedBundlesSelector],
    selectedBundles => selectedBundles
        .reduce((legs, bundle) => {
            const seatProducts = (bundle.items || []).filter(item => isSeatProductCode(item.productCode))
            if (seatProducts.length) {
                seatProducts.forEach(product => {
                    legs.push(...product.legIds)
                })
            }
            return uniq(legs)
        }, [])
)

export const seatSelectionProductSelector = createSelector([selectedBundlesSelector],
    bundles => bundles.reduce((product, bundle) => {
        const seatProduct = bundle.items.find(item => item.productCode === PRODUCT_CODE_SEAT_SELECTION)
        return product || seatProduct || null
    }, null)
)

export const seatSelectionPlusProductSelector = createSelector(
    [selectedBundlesSelector],
    bundles => bundles.reduce((product, bundle) => {
        const seatProduct = bundle.items.find(item => item.productCode === PRODUCT_CODE_SEAT_SELECTION_PLUS)
        return product || seatProduct || null
    }, null)
)

export const seatSelectionAvailableSelector = createSelector(
    [seatSelectionLegsSelector],
    legs => legs.length > 0
)

export const seatSelectionServicesSelector = createSelector(
    [routesSelector, selectedBundlesSelector],
    (routes, selectedBundles) => selectedBundles.reduce((services, bundle) => {
        const route = routes.get(bundle.routeId)
        const legs = ((route && route.legs) || []).map(leg => ({
            legId: leg.id,
            direction: route.direction,
            serviceName: leg.serviceName,
            fromStationUIC: leg.departureStation.UICStationCode,
            toStationUIC: leg.arrivalStation.UICStationCode,
            travelDate: leg.serviceScheduleDate.format('YYYY-MM-DD')
        }))
        services.push(...legs)
        return services
    }, [])
)

const passengerFaresSelector = productCode => createSelector(
    [productSearchSelector],
    data => {
        if (!data) {
            return null
        }
        const {items, fares, tariffs} = data
        const item = items.find(item => item.product_code === productCode)
        const fare = item && fares.find(_fare => _fare.id === item.fare_id)
        const mapPassengerFare = fare => {
            const tariff = tariffs.find(tariff => tariff.id === fare.tariff_ref)
            return {
                ...fare,
                passengerId: fare.passenger_ref,
                tariffCode: tariff.code
            }
        }

        return fare ? (fare.passenger_fares || []).map(mapPassengerFare) : null
    }
)

export const seatSelectionPassengerFaresProductSearchSelector = passengerFaresSelector(PRODUCT_CODE_SEAT_SELECTION)
export const seatSelectionPlusPassengerFaresProductSearchSelector = passengerFaresSelector(PRODUCT_CODE_SEAT_SELECTION_PLUS)

const getPassengerFares = (product, passengerFares) => (product && product.passengerFares) || passengerFares || []

export const passengerFareSeatSelector = createSelector(
    [seatSelectionProductSelector, seatSelectionPassengerFaresProductSearchSelector],
    (seatSelectionProduct, seatSelectionPassengerFares) => getPassengerFares(seatSelectionProduct, seatSelectionPassengerFares)
)

export const passengerFareSeatPlusSelector = createSelector(
    [seatSelectionPlusProductSelector, seatSelectionPlusPassengerFaresProductSearchSelector],
    (seatSelectionPlusProduct, seatSelectionPlusPassengerFares) => getPassengerFares(seatSelectionPlusProduct, seatSelectionPlusPassengerFares)
)

export const seatsWithPricesSelector = seats => createSelector(
    [passengerFareSeatPlusSelector, passengerFareSeatSelector],
    (passengerFaresSeatPlus, passengerFaresSeat) => seats.map(seat => ({
        ...seat,
        price: getSeatPrice(seat, passengerFaresSeatPlus, passengerFaresSeat)
    }))
)

export const totalSeatPriceSelector = selectedSeats => createSelector(
    [seatsWithPricesSelector(selectedSeats)],
    seats => seats.reduce((total, seat) => {
        if (seat.provisional !== false && seat.cancelled !== true) {
            total += (seat && seat.price) || 0
        }
        return total
    }, 0)
)

export const getSeatPrice = (seat, passengerFaresSeatPlus, passengerFaresSeat) => {
    const fares = seat.seat_product_code === PRODUCT_CODE_SEAT_SELECTION_PLUS
        ? passengerFaresSeatPlus
        : passengerFaresSeat
    const fare = (fares || []).find(fare => (fare.passenger_ref || fare.passengerId) === seat.passenger_id)

    return (fare && fare.price) || 0
}
