import PropTypes from 'prop-types'
import React, { Component } from 'react'
import TextLabel from '../../element/text-label'
import _t from '../../translate'
import ProgressNavigation from './progress-navigation/progress-navigation-container'
import merge from 'lodash/merge'

export default class SeatSelectorNavigation extends Component {
    static propTypes = {
        totalPrice: PropTypes.number.isRequired,
        previousButtonProps: PropTypes.object,
        nextButtonEnabled: PropTypes.any.isRequired,
        nextButtonProps: PropTypes.object.isRequired
    }

    render () {
        const previousButtonProps = merge({
            className: 'button cancel default',
            text: _t.getIntlMessage('seat-selector.button-back.label'),
            icon: {
                type: 'chevron-left',
                className: 'xsmall align-left'
            }
        }, this.props.previousButtonProps)

        const nextButtonProps = merge({
            className: 'button validate primary',
            text: _t.getIntlMessage('seat-selector.button-continue.label'),
            icon: {
                type: 'chevron-right',
                className: 'xsmall align-right'
            }
        }, this.props.nextButtonProps)

        return (
            <div className='seat-selector-navigation'>
                <div className='total-price'>
                    <span className='text-label total-seat-price-description'>
                        <TextLabel text={_t.message('seat-selector.total-price-label')} />
                    </span>
                    <span className='text-label total-seat-price'>
                        <TextLabel text={_t.formatCurrency(this.props.totalPrice)} />
                    </span>
                </div>
                <ProgressNavigation
                    previousButtonProps={previousButtonProps}
                    nextButtonEnabled={this.props.nextButtonEnabled}
                    nextButtonProps={nextButtonProps}
                />
            </div>
        )
    }
}
