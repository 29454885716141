// Libraries
import React from 'react'
import {Route} from 'react-router'

import authCheck from '../../mixins/auth'

// Layouts
import MyS3UpdatePassengersLayout from '../../layouts/my-s3-update-passengers-layout'

export default (
    <Route path='/:lang/mys3/update-passengers'
           component={MyS3UpdatePassengersLayout}
           onEnter={(nextState, replaceState, callback) => authCheck(nextState, replaceState, callback)}
        />
)
