import PropTypes from 'prop-types'
import React, {Component} from 'react'
import actions from '../reflux/actions'
import _t from '../translate'
import device from '../device'
import {withRouter} from 'react-router'

import GenericLayout from '../layouts/generic-layout'
import Row from '../element/row'
import Tabs from '../element/tabs'
import TabContent from '../element/tab-content'

import RadioGroup from '../element/form/radio-group'

import EmailLoginFormContainer from '../components/booking-login/email-form-container'
import PassengerLoginFormContainer from '../components/booking-login/passenger-form-container'
import ResendTicketsForm from '../components/booking-login/resend-tickets-form'

import bookingLoginLayout from '../layouts/booking-login-layout'

class BookingLoginLayoutContainer extends Component {
    static propTypes = {
        serviceError: PropTypes.string,
        errors: PropTypes.object,
        loading: PropTypes.bool.isRequired,
        onLogin: PropTypes.func.isRequired
    }

    static defaultProps = {
        serviceError: null,
        errorTextBookingReference: null,
        errorTextEmail: null
    }

    constructor (...args) {
        super(...args)
        this.onChangeForm = this.onChangeForm.bind(this)
        this._renderFormSelector = this._renderFormSelector.bind(this)
        this.state = {
            form: 'email'
        }
    }

    onChangeForm (form) {
        this.setState({
            form: form
        }, () => actions.processBookingLoginData([{id: 'form', value: form}]))
    }

    render () {
        return (
            <GenericLayout className='booking-login'>
                <Row className='row'>
                    <div className='grid-row'>
                        <div className='grid-column--1-1 grid-column--medium-2-3 center-grid-column--small'>
                            <div className='generic-login booking-login'>
                                <div className='panel'>
                                    <header>
                                        <h2>{_t.getIntlMessage('booking-login.header')}</h2>
                                    </header>
                                    <div className='content'>
                                        <div className='tab-container'>
                                            {this._renderFormSelector()}
                                            <TabContent key='default-tab' visible={this.state.form === 'email'}>
                                                <EmailLoginFormContainer
                                                    serviceError={this.props.serviceError}
                                                    errors={this.props.errors}
                                                    loading={this.props.loading}
                                                    onLogin={this.props.onLogin}
                                                />
                                            </TabContent>
                                            <TabContent
                                                key='login-by-passenger'
                                                visible={this.state.form === 'passenger'}
                                            >
                                                <PassengerLoginFormContainer
                                                    serviceError={this.props.serviceError}
                                                    errors={this.props.errors}
                                                    loading={this.props.loading}
                                                    onLogin={this.props.onLogin}
                                                />
                                            </TabContent>
                                            <TabContent
                                                key='lost-credentials'
                                                visible={this.state.form === 'lost-credentials'}
                                            >
                                                <ResendTicketsForm />
                                            </TabContent>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Row>
            </GenericLayout>
        )
    }

    _renderFormSelector () {
        if (device.isDesktopOrTablet()) {
            return (
                <Tabs
                    goto={form => this.onChangeForm(form)}
                    current={this.state.form}
                    tabs={this._getLoginTabs()}
                />
            )
        } else {
            return (
                <div className='tabs'>
                    <ul>
                        <li className='active'>
                            <RadioGroup
                                className='login-form-radio'
                                name='login-form-radio'
                                selectedValue={this.state.form}
                                data={this._getSelectData()}
                                onChange={(_, value) => this.onChangeForm(value)}
                            />
                        </li>
                    </ul>
                </div>
            )
        }
    }

    _getLoginTabs () {
        return this._getLoginOptions().map(tab => {
            return {
                key: tab,
                label: _t.getIntlMessage(`booking-login.tabs.${tab}.label`),
                title: _t.getIntlMessage(`booking-login.tabs.${tab}.title`)
            }
        })
    }

    _getLoginOptions () {
        return ['email', 'passenger', 'lost-credentials']
    }

    _getSelectData () {
        return this._getLoginOptions().map(option => {
            return {
                id: option,
                value: option,
                text: `booking-login.tabs.${option}.label`
            }
        })
    }
}

export default withRouter(bookingLoginLayout(BookingLoginLayoutContainer))
