import React, {Component} from 'react'
import PropTypes from 'prop-types'
import _t from '../../../translate'
import Icon from '../../../element/icon'
import Button from '../../../element/button'
import TextLabel from '../../../element/text-label'
import BookingModel from '../../../models/booking-model'
import {containVouchers} from '../../../base/vouchers'

class VoucherList extends Component {
    static propTypes = {
        voucherAction: PropTypes.string,
        onRemoveVoucher: PropTypes.func.isRequired,
        removedVoucherCode: PropTypes.string,
        booking: PropTypes.instanceOf(BookingModel).isRequired,
        readOnly: PropTypes.bool
    }

    constructor (...args) {
        super(...args)

        this._renderVoucher = this._renderVoucher.bind(this)
    }

    render () {
        const pendingVouchers = this.props.booking.pendingVouchers
        if (!pendingVouchers.length) {
            return null
        }

        return (
            <div className='voucher-list'>
                <table className='responsive-table'>
                    <colgroup>
                        <col className='col-voucher-code' />
                        <col className='col-voucher-value' />
                        {!this.props.readOnly ? <col className='col-voucher-action' /> : null}
                    </colgroup>
                    <thead>
                        <tr>
                            <th scope='col'>{_t.getIntlMessage('voucher.column-header-code')}</th>
                            <th scope='col'>{_t.getIntlMessage('voucher.column-header-value')}</th>
                            {!this.props.readOnly ? <th scope='col' /> : null}
                        </tr>
                    </thead>
                    <tbody>
                        {pendingVouchers.map(this._renderVoucher)}
                    </tbody>
                </table>
            </div>
        )
    }

    _renderVoucher (voucher) {
        const loading = this.props.voucherAction === 'remove' && voucher.code === this.props.removedVoucherCode

        return (
            <tr key={voucher.id}>
                <td data-label={voucher.code}>
                    <span className='text-label voucher-code'>
                        <TextLabel text={voucher.code} />
                    </span>
                </td>
                <td data-label={voucher.amount}>
                    <span className='text-label voucher-value'>
                        <TextLabel text={_t.formatCurrency(voucher.amount)} />
                    </span>
                </td>
                {!this.props.readOnly
                    ? <td data-label=''>
                        <Button
                            type='button'
                            className='button danger small remove-voucher'
                            onClick={() => this.props.onRemoveVoucher(voucher.code)}
                            disabled={this.props.voucherAction !== null}
                            loading={loading}
                        >
                            <Icon type='cross' className='small align-left' />
                            <TextLabel text={_t.getIntlMessage('voucher.button-remove')} />
                        </Button>
                    </td>
                    : null}
            </tr>
        )
    }
}

export default containVouchers(VoucherList)
