import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from '../../translate'
import TextLabel from '../../element/text-label'
import RadioField from '../../element/form/radio-field'
import CmsBlockContent from '../cms/cms-block-content'
import InsuranceTotalPrice from './insurance-total-price'
import ModalDialog from '../../element/modal-dialog'
import Benefit from './benefit'
import CmsLoader from '../cms/cms-loader'

export default class InsuranceMobile extends Component {
    static propTypes = {
        price: PropTypes.number.isRequired,
        benefits: PropTypes.array.isRequired,
        onSelectWith: PropTypes.func.isRequired,
        onSelectWithout: PropTypes.func.isRequired,
        selected: PropTypes.bool
    }

    constructor (...props) {
        super(...props)
        this.state = {modalVisible: false}
        this.toggleVisibility = this.toggleVisibility.bind(this)
    }

    componentWillMount () {
        this.setState({modalVisible: false})
    }

    componentWillUnmount () {
        this.setState({modalVisible: false})
    }

    toggleVisibility () {
        this.setState({modalVisible: !this.state.modalVisible})
    }

    renderBenefitsModal () {
        return (
            <ModalDialog
                headerTitle={_t.formatIntlMessage('insurance.modal-header')}
                onClose={this.toggleVisibility}
            >
                <div className='benefits'>
                    <CmsLoader />
                    <ul>
                        {this.props.benefits.map(benefit => <Benefit key={benefit} name={benefit} />)}
                    </ul>
                </div>
                <span className='text-label information-privacy'>
                    <CmsBlockContent name='insurance-conditions' showLoader={false} />
                </span>
            </ModalDialog>
        )
    }

    render () {
        return (
            <div className='panel'>
                <header>
                    <span className='text-label insurance-logo'>
                        <TextLabel text='Allianz logo' />
                    </span>
                    <span className='text-label heading'>
                        <TextLabel text={_t.message('insurance.header')} />
                    </span>
                </header>
                <div className='insurance-content'>
                    <div className='with-insurance'>
                        <div className='information'>
                            <span className='text-label insurance-product-title'>
                                <TextLabel text={_t.message('insurance.insurance-title')} />
                            </span>
                            <a href='javascript:void(0)' className='more-info' onClick={this.toggleVisibility}>
                                <TextLabel text={_t.message('insurance.more-info')} />
                            </a>
                        </div>
                        <InsuranceTotalPrice price={this.props.price} />
                        <RadioField
                            id='with-insurance'
                            name='insurance'
                            onChange={this.props.onSelectWith}
                            checked={this.props.selected === true}
                        >
                            <label htmlFor='with-insurance' className='insurance-radio-label with-insurance'>
                                <TextLabel text={_t.message('insurance.with-insurance.mobile')} />
                            </label>
                        </RadioField>
                    </div>
                    <div className='without-insurance'>
                        <RadioField
                            id='without-insurance'
                            name='insurance'
                            onChange={this.props.onSelectWithout}
                            checked={this.props.selected === false}
                        >
                            <label htmlFor='without-insurance' className='insurance-radio-label'>
                                <TextLabel text={_t.message('insurance.without-insurance.mobile')} />
                            </label>
                        </RadioField>
                    </div>
                </div>
                {this.state.modalVisible ? this.renderBenefitsModal() : null}
            </div>
        )
    }
}
