// Libraries
import PropTypes from 'prop-types'
import React, {Component} from 'react'
import _ from 'lodash'

// Components
import ProgressNavigation from '../../base/main/progress-navigation-container'

export default class ProgressNaviagtionContainer extends Component {
    static propTypes = {
        previousButtonProps: PropTypes.object,
        previousButtonEnabled: PropTypes.bool,
        nextButtonProps: PropTypes.object,
        nextButtonEnabled: PropTypes.bool
    }

    render () {
        let props = {
            previousButtonProps: _.merge(this.props.previousButtonProps, {
                className: 'button previous default',
                icon: {
                    type: 'chevron-left',
                    className: 'xsmall align-left'
                }
            }),
            previousButtonEnabled: this.props.previousButtonEnabled,
            nextButtonEnabled: true,
            nextButtonProps: _.merge(this.props.nextButtonProps, {
                className: 'button next primary',
                icon: {
                    type: 'chevron-right',
                    className: 'xsmall align-right'
                },
                disabled: !this.props.nextButtonEnabled
            })
        }

        return (
            <ProgressNavigation {...props} />
        )
    }
}
