export const PRODUCT_MOVIES_PREFIX = 'FNAC_MOVIES'
export const PRODUCT_SERIES_PREFIX = 'FNAC_SERIES'

export const PRODUCT_QUERY_ENTERTAINMENT = 'entertainment'
export const PRODUCT_QUERY_LUGGAGE = 'luggage'

export const isEntertainmentType = type => product => {
    const code = product.product_code || product.code
    const description = product.description
    return (description && description.toLowerCase().indexOf(type) >= 0) ||
        code.indexOf(type === 'movies' ? PRODUCT_MOVIES_PREFIX : PRODUCT_SERIES_PREFIX) === 0
}

export const isMovie = isEntertainmentType('movies')
export const isSerie = isEntertainmentType('series')

// because we don't have product code in additional-product-list is the first character in description the amount
export const getQuantityByProductCode = product => {
    const code = product.product_code || product.code
    return isMovie(product) || isSerie(product) ? parseInt(code.substr(-1), 10) : 0
}
