import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {cmsBlockContainer} from '../cms/cms-block-container'
import CmsContent from '../cms/cms-content'
import {isAffiliateBlablacarSelector} from '../../reflux/bridge/affiliate'

class Blablacar extends Component {
    static propTypes = {
        title: PropTypes.string,
        content: PropTypes.string,
        loading: PropTypes.bool
    }

    render () {
        const {title, content, loading} = this.props
        return isAffiliateBlablacarSelector() && title && content && !loading
            ? (
                <div className='panel'>
                    <header>
                        <h2>{title}</h2>
                    </header>
                    <CmsContent className='content' {...this.props} />
                </div>
            )
            : null
    }
}

export default cmsBlockContainer('confirm-bbc', Blablacar)
