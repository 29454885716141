import PropTypes from 'prop-types'
import React, { Component } from 'react'
import TextLabel from '../../element/text-label'
import RadioField from '../../element/form/radio-field'
import InsuranceTotalPrice from './insurance-total-price'
import _t from '../../translate'
import CmsBlockContent from '../cms/cms-block-content'
import Benefit from './benefit'
import CmsLoader from '../cms/cms-loader'
import { isBlablabusDomain } from '../../misc/blablabus-helpers'

export default class InsuranceDesktop extends Component {
    static propTypes = {
        price: PropTypes.number.isRequired,
        benefits: PropTypes.array.isRequired,
        onSelectWith: PropTypes.func.isRequired,
        onSelectWithout: PropTypes.func.isRequired,
        selected: PropTypes.bool
    }

    render () {
        return (
            <div className='panel'>
                <header>
                    <span className='text-label insurance-logo'>
                        <TextLabel text='Allianz logo' />
                    </span>
                    <span className='text-label heading'>
                        <TextLabel text={_t.message('insurance.header')} />
                    </span>
                </header>
                <div className='insurance-content'>
                    <div className='with-insurance'>
                        <RadioField
                            id='with-insurance'
                            name='insurance'
                            onChange={this.props.onSelectWith}
                            checked={this.props.selected === true}
                        >
                            <label htmlFor='with-insurance' className='insurance-radio-label with-insurance'>
                                <TextLabel text={_t.message('insurance.with-insurance.desktop')} />
                            </label>
                        </RadioField>
                        <div className={`benefits ${isBlablabusDomain() ? 'blablacar' : ''}`}>
                            <CmsLoader />
                            <ul>
                                {this.props.benefits.map(benefit => <Benefit key={benefit} name={benefit} />)}
                            </ul>
                        </div>
                        <InsuranceTotalPrice price={this.props.price} />
                        <span className='text-label information-privacy'>
                            <CmsBlockContent name='insurance-conditions' showLoader={false} />
                        </span>
                    </div>
                    <div className='without-insurance'>
                        <RadioField
                            id='without-insurance'
                            name='insurance'
                            onChange={this.props.onSelectWithout}
                            checked={this.props.selected === false}
                        >
                            <label htmlFor='without-insurance' className='insurance-radio-label'>
                                <TextLabel text={_t.message('insurance.without-insurance.desktop')} />
                            </label>
                        </RadioField>
                    </div>
                </div>
            </div>
        )
    }
}
