import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from '../../translate'
import Icon from '../../element/icon'
import Button from '../../element/button'
import TextLabel from '../../element/text-label'
import actions from '../../reflux/actions'
import {getState} from '../../reflux/bridge/connect-state'
import {additionalProductsSelector} from '../../models/selectors/api/v2/booking/products'

export default class SeatSummaryPassenger extends Component {
    static propTypes = {
        leg: PropTypes.object.isRequired,
        passenger: PropTypes.object.isRequired,
        selectedSeat: PropTypes.object,
        onClickEdit: PropTypes.func.isRequired,
        onClickRemove: PropTypes.func.isRequired,
        showPrice: PropTypes.bool,
        readOnly: PropTypes.bool,
        isRemoveLoading: PropTypes.bool.isRequired,
        index: PropTypes.number.isRequired
    }

    constructor (...args) {
        super(...args)
        this.handleClickRemove = this.handleClickRemove.bind(this)
        this.handleClickEdit = this.handleClickEdit.bind(this)
        this.handleClickSelect = this.handleClickSelect.bind(this)
    }

    render () {
        const {passenger, selectedSeat} = this.props
        return (
            <li>
                <div className='passenger-item'>
                    <span className='text-label passenger-name'>
                        <TextLabel text={`${passenger.first_name} ${passenger.last_name}`} />
                    </span>
                    {selectedSeat
                        ? this.renderSeatLabel()
                        : this.renderBookSeatLabel()}
                </div>
            </li>
        )
    }

    handleClickRemove () {
        actions.trackEvent('SeatOption', {basket_seat_option_action: 'deleted', basket_seat_price: this.getSeatPrice()})

        const {selectedSeat, passenger, leg, onClickRemove} = this.props
        const itemRef = selectedSeat ? selectedSeat.additionalProductItemRef : null
        onClickRemove(itemRef, passenger.id, leg.id)
    }

    handleClickEdit () {
        actions.trackEvent('SeatOption', {basket_seat_option_action: 'edited', basket_seat_price: this.getSeatPrice()})
        this.handleClickSelect()
    }

    handleClickSelect () {
        const {passenger, onClickEdit} = this.props
        onClickEdit(passenger.id)
    }

    renderSeatLabel () {
        const {readOnly, isRemoveLoading} = this.props
        return !readOnly
            ? (
                <div className='passenger-seat'>
                    <div className='current-selected-seat'>
                        <span className='text-label selected-seat'>
                            <TextLabel text={this.renderSelectedSeatLabel()} />
                        </span>
                    </div>
                    <div className='edit-selected-seat'>
                        <a href='javascript:void(0)' className='button edit clear' onClick={this.handleClickEdit}>
                            <Icon className='edit icon small' type='edit' />
                        </a>
                    </div>
                    <div className='remove-selected-seat'>
                        <Button
                            className='button remove clear'
                            disabled={isRemoveLoading}
                            loading={isRemoveLoading}
                            onClick={this.handleClickRemove}
                        >
                            <Icon className='trash icon small' type='trash' />
                        </Button>
                    </div>
                </div>
            )
            : (
                <div className='passenger-seat'>
                    <span key='seatDescription' className='text-label seat-description'>
                        <TextLabel text={_t.formatIntlMessage('passenger-selector.seat-description')} /> |
                    </span>
                    <span className='text-label selected-seat'>
                        <TextLabel text={this.renderSelectedSeatLabel()} />
                    </span>
                </div>
            )
    }

    getSeatPrice () {
        const {selectedSeat} = this.props
        const additionalProducts = additionalProductsSelector(getState())
        const product = (additionalProducts || []).find(product => product.itemRef === selectedSeat.additionalProductItemRef)

        return (product && product.price) || 0
    }

    renderSelectedSeatLabel () {
        const {selectedSeat} = this.props
        const price = this.getSeatPrice()
        return price && selectedSeat.provisional
            ? `${selectedSeat.seat_number} - ${_t.formatCurrency(price)}`
            : selectedSeat.seat_number
    }

    renderBookSeatLabel () {
        return !this.props.readOnly
            ? (
                <div className='passenger-select'>
                    <a href='javascript:void(0)' className='link' onClick={this.handleClickSelect}>
                        {_t.getIntlMessage('seat-selector.book-seat')}
                    </a>
                </div>
            )
            : <TextLabel text='-' />
    }
}
