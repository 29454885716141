import Reflux from 'reflux'
import _ from 'lodash'
import actions from '../actions'
import UserAwareMixin from '../mixin/user-aware-mixin'

export default Reflux.createStore({

    listenables: actions,

    mixins: [UserAwareMixin],

    init () {
        this._resetData()
    },

    _resetData () {
        this.data = {
            loading: false,
            error: '',
            stations: []
        }
    },

    resetData () {
        this._resetData()
        this.trigger(this.data)
    },

    getInitialState () {
        return this.data
    },

    onLoadStations () {
        this.data.loading = true
        this.trigger(this.data)
    },

    onLoadStationsCompleted (stations) {
        this.data.loading = false
        this.data.error = ''
        this.data.stations = this._sortStations(stations)
        this.trigger(this.data)
    },

    onLoadStationsFailed (error) {
        this.data.loading = false
        this.data.error = error
        this.data.stations = []
        this.trigger(this.data)
    },

    isLoading () {
        return this.data.loading
    },

    getStations () {
        return this.data.stations
    },

    hasStations () {
        return this.data.stations.length > 0
    },

    getStationCountryCode (stationCode) {
        const station = this.data.stations.find(station => station._u_i_c_station_code === stationCode)

        return station ? station.country_code : null
    },

    _sortStations (stations) {
        var parents = _.sortBy(_.filter(stations, function (s) {
            return typeof s.parent === 'undefined'
        }), ['name'])

        var stationsSorted = []
        _.forEach(parents, function (n) {
            stationsSorted.push(n)
            stationsSorted.push(_.sortBy(
                _.filter(stations, function (s) {
                    return (typeof s.parent !== 'undefined') && s.parent === n._u_i_c_station_code
                }), ['name']
            ))
        })
        stationsSorted = _.flatten(stationsSorted)

        return stationsSorted
    }
})
