import React, {Component} from 'react'
import PropTypes from 'prop-types'

export default class Option extends Component {
    static propTypes = {
        disabled: PropTypes.bool,
        text: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ])
    }

    render () {
        let {text, ...other} = this.props
        const disabled = this.props.disabled || (this.props.value !== null && typeof this.props.value !== 'undefined')
            ? null
            : 'disabled'

        return <option disabled={disabled} {...other}>{text}</option>
    }
}
