import React from 'react'
import PropTypes from 'prop-types'
import Reflux from 'reflux'
import moment from 'moment-timezone'
import _t from '../translate'
import AccessManager from '../data/access-manager'
import BookingStore from '../reflux/stores/booking-store'
import RefundVoucherStore from '../reflux/stores/refund-voucher-store'
import actions from '../reflux/actions'
import PrintWindow from '../misc/print-window'
import BookingModel from '../models/booking-model'
import createReactClass from 'create-react-class'

export default Component => createReactClass({

    displayName: 'MyS3BookingLayout',

    mixins: [
        Reflux.listenTo(BookingStore, 'onBookingUpdated'),
        Reflux.connect(RefundVoucherStore, 'voucherData')
    ],

    propTypes: {
        router: PropTypes.object,
        params: PropTypes.object
    },

    getInitialState () {
        return this._getStateFromStoreData(BookingStore.getInitialState())
    },

    componentWillMount () {
        actions.clearRefundVoucher()
        this.printWindow = new PrintWindow(
            _t.message('booking-specification.refund.method-voucher.confirm-dialog.print-voucher-button')
        )

        if (!this._hasBooking() || this.state.booking.bookingNumber.toUpperCase() !== this._getBookingReferenceParam()) {
            this.setState({loading: true}, () => actions.getBooking(this._getBookingReferenceParam()))
        }
    },

    componentWillUpdate (_, nextState) {
        if (!nextState.voucherData.isLoadingRequestVoucher &&
            nextState.voucherData.shouldPrint &&
            nextState.voucherData.refundVoucher
        ) {
            this._printVoucher(nextState)
        }
    },

    onBookingUpdated (data) {
        const newState = this._getStateFromStoreData(data)
        this.setState(newState)
        if (!data.loading &&
            newState.booking && newState.booking.bookingNumber.toUpperCase() !== this._getBookingReferenceParam()
        ) {
            actions.getBooking(this._getBookingReferenceParam())
        }
    },

    _getBookingReferenceParam () {
        return (this.props.params.bookingReference || '').toUpperCase()
    },

    _getStateFromStoreData (data) {
        return {
            booking: data.booking ? BookingModel.create(data.booking) : null,
            loading: data.loading
        }
    },

    _requestRefundVoucher (booking) {
        actions.requestRefundVoucher(booking.bookingNumber)
    },

    _hasBooking () {
        return this.state.booking !== null && this.state.booking.bookingNumber !== null
    },

    render () {
        return (
            <Component
                booking={this.state.booking}
                loading={this.state.loading}
                onConfirmAfterSales={this.onConfirmAfterSales}
                onRevertAfterSales={this.onRevertAfterSales}
                onRebook={this.onRebook}
                onCancel={this.onCancel}
                onOptions={this.onOptions}
                onNameChange={this.onNameChange}
                onRequestRefundVoucher={this.onRequestRefundVoucher}
                onRequestRefundPsp={this.onRequestRefundPsp}
                onContinue={this.onContinue}
                handleRequestRefundVoucher={this._handleRequestRefundVoucher}
                handlePrintRefundVoucher={this._handlePrintRefundVoucher}
                isLoadingRequestVoucher={this.state.voucherData.isLoadingRequestVoucher}
                shouldPrint={this.state.voucherData.shouldPrint}
                refundVoucher={this.state.voucherData.refundVoucher}
            />
        )
    },

    onConfirmAfterSales () {
        if (this._hasBooking()) {
            this.setState(
                {loading: true},
                () => {
                    if (this.state.booking.totalPriceToBePaid > 0) {
                        switch (this.state.booking.lastAfterSalesOperation) {
                            case 'AFTERSALES_CANCEL':
                                this.props.router.push(`/${_t.getLocales()}/mys3/cancellation/payment`)
                                break
                            case 'AFTERSALES_NAMECHANGE':
                                this.props.router.push(
                                    null,
                                    `/${_t.getLocales()}/mys3/update-passengers/payment`
                                )
                                break
                            case 'AFTERSALES_MODIFY':
                                this.props.router.push(
                                    null,
                                    `/${_t.getLocales()}/mys3/additional-products/payment`
                                )
                                break
                            case 'AFTERSALES_REBOOK':
                                this.props.router.push(`/${_t.getLocales()}/mys3/re-booking/payment`)
                                break
                        }
                    } else {
                        actions.confirmBooking(this.state.booking.bookingNumber)
                    }
                }
            )
        }
    },

    onRevertAfterSales () {
        if (this._hasBooking()) {
            this.setState(
                {loading: true},
                () => {
                    actions.revertBooking(this.state.booking.bookingNumber)
                        .then(() => {
                            actions.getBooking(this._getBookingReferenceParam())
                        })
                }
            )
        }
    },

    onRebook () {
        if (this._hasBooking()) {
            this.props.router.push(`/${_t.getLocales()}/mys3/re-booking/offer`)
        }
    },

    onCancel () {
        if (this._hasBooking()) {
            this.props.router.push(`/${_t.getLocales()}/mys3/cancellation`)
        }
    },

    onOptions () {
        if (this._hasBooking()) {
            this.props.router.push(`/${_t.getLocales()}/mys3/my-options`)
        }
    },

    onNameChange () {
        if (this._hasBooking()) {
            this.props.router.push(`/${_t.getLocales()}/mys3/update-passengers`)
        }
    },

    onRequestRefundVoucher () {
        this._requestRefundVoucher(this.state.booking)
    },

    onRequestRefundPsp () {
        actions.requestRefundPsp(this.state.booking.bookingNumber)
    },

    onContinue () {
        if (this._hasBooking()) {
            let url = `/${_t.getLocales()}/booking/passenger-details`
            if (AccessManager.isCrmUser()) {
                url = `/${_t.getLocales()}/booking/details/account`
            }

            this.setState({loading: true}, () => this.props.router.push(url))
        }
    },

    _handleRequestRefundVoucher () {
        actions.requestRefundVoucher(this.state.booking.bookingNumber)
    },

    _handlePrintRefundVoucher () {
        this.printWindow.open()
        actions.requestRefundVoucher(this.state.booking.bookingNumber, true)
    },

    _printVoucher (state) {
        const voucherSalesEndDate = state.voucherData.refundVoucher.sales_period_end
            ? moment(state.voucherData.refundVoucher.sales_period_end).format('DD-MM-YYYY') : ''
        const customerName = `${state.booking.customer.first_name} ${state.booking.customer.last_name}`.trim()
        const customerTitle = `${state.booking.customer.title || ''} ${customerName}`.trim()

        this.printWindow.setContent(
            _t.message('booking-specification.refund.method-voucher.confirm-dialog.print-template', {
                customerTitle,
                voucherCode: state.voucherData.refundVoucher.code,
                voucherAmount: state.voucherData.refundVoucher.amount,
                voucherCurrencyCode: state.voucherData.refundVoucher.currency,
                voucherSalesEndDate,
                bookingNumber: state.booking.bookingNumber
            })
        )
    }
})
