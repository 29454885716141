import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Loader from '../../../element/loader'
import {cmsBlockContainer} from '../../cms/cms-block-container'

class Conditions extends Component {
    static propTypes = {
        title: PropTypes.string,
        content: PropTypes.string,
        isCmsLoading: PropTypes.bool.isRequired
    }

    render () {
        if (!this.props.content && this.props.isCmsLoading) {
            return <Loader />
        }

        return (
            <div className='rebooking-conditions'>
                <h3>{this.props.title}</h3>
                <div className='cms-block'>
                    <span dangerouslySetInnerHTML={{__html: this.props.content}} />
                </div>
            </div>
        )
    }
}

export default cmsBlockContainer('AFTERSALES-REBOOK-FLEX', Conditions)
