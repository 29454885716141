/* global S3P_SETTINGS: true */

import PropTypes from 'prop-types'
import React from 'react'
import Reflux from 'reflux'
import _ from 'lodash'
import moment from 'moment-timezone'
import update from 'react-addons-update'
import StationsStore from '../reflux/stores/stations-store'
import OfferStore from '../reflux/stores/offer-store'
import CurrencyStore from '../reflux/stores/currency-store'
import actions from '../reflux/actions'
import {dateStringToLocalizedDate} from '../misc/date'
import {
    PASSENGER_TYPE_ADULT,
    PASSENGER_TYPE_BABY,
    PASSENGER_TYPE_CHILD
} from '../constants'
import createReactClass from 'create-react-class'

export default Component => createReactClass({
    displayName: 'JourneySearch',

    propTypes: {
        defaultInboundDate: PropTypes.objectOf(Date),
        router: PropTypes.object,
        location: PropTypes.object
    },

    mixins: [
        Reflux.connectFilter(CurrencyStore, 'currency', data => data.currency),
        Reflux.listenTo(StationsStore, 'onLoadStations'),
        Reflux.listenTo(OfferStore, 'onLoadOffer')
    ],

    getDefaultProps () {
        return {
            defaultInboundDate: null
        }
    },

    getInitialState () {
        return {
            stations: StationsStore.getStations(),
            stationsLoading: StationsStore.isLoading(),
            journeySelection: this.getJourneySelection(),
            buttonLoading: false,
            hasOffer: this._hasOffer(OfferStore.getInitialState())
        }
    },

    onLoadOffer (data) {
        const newState = {buttonLoading: data.loading}
        if (data.loading === false) {
            this._updateJourneySelection({$set: this.processJourneySearchData(data.journeySearch)})
            newState.hasOffer = this._hasOffer(data)
        }

        this.setState(newState)
    },

    _hasOffer (offerData) {
        return 'travels' in offerData.offer
    },

    _updateJourneySelection (updateObject, callback) {
        this.setState(update(this.state, {journeySelection: updateObject}), callback)
    },

    _currentDate () {
        return new Date()
    },

    processJourneySearchData (journeySearch) {
        return {
            origin: journeySearch.origin_station ? journeySearch.origin_station.UICStationCode : '',
            destination: journeySearch.destination_station
                ? journeySearch.destination_station.UICStationCode : '',
            outboundDate: journeySearch.departure_date || this._currentDate(),
            inboundDate: journeySearch.return_date || null,
            passengers: journeySearch.passengers || [{type: PASSENGER_TYPE_ADULT}]
        }
    },

    getJourneySelection () {
        const query = this.props.location.query
        const minimalJourneySearchQuery = undefined !== query.origin && undefined !== query.destination

        if (!minimalJourneySearchQuery) {
            let journeySearchData = OfferStore.getJourneySearchData()
            journeySearchData.return_date = journeySearchData.return_date || this.props.defaultInboundDate

            return this.processJourneySearchData(journeySearchData)
        }

        let inboundDate = null
        if (!query.inboundDate && !query.outboundDate && this.props.defaultInboundDate) {
            inboundDate = this.props.defaultInboundDate
        } else if (query.inboundDate) {
            inboundDate = dateStringToLocalizedDate(query.inboundDate)
        }

        return {
            origin: query.origin || '',
            destination: query.destination || '',
            outboundDate: query.outboundDate ? dateStringToLocalizedDate(query.outboundDate) : this._currentDate(),
            inboundDate,
            passengers: query.passengers || [{type: PASSENGER_TYPE_ADULT}]
        }
    },

    componentWillUpdate (_, nextState) {
        this._loadStationData(nextState)

        if (this.state.journeySelection !== nextState.journeySelection) {
            let query = this.props.location.query

            const data = nextState.journeySelection
            query.origin = data.origin
            query.destination = data.destination
            query.outboundDate = moment(data.outboundDate).format('YYYY-MM-DD')
            query.inboundDate = data.inboundDate ? moment(data.inboundDate).format('YYYY-MM-DD') : null
            query.passengers = data.passengers.map(passenger => ({
                type: passenger.type,
                discount_cards: passenger.discount_cards
            }))

            const scrollOffset = window.pageYOffset || document.documentElement.scrollTop
            this.props.router.replace({scrollPosition: [0, scrollOffset]}, this.props.location.pathname, query)
        }
    },

    componentWillMount () {
        this._loadStationData(this.state)
    },

    render () {
        return (
            <Component
                stations={this.state.stations}
                journeySelection={this.state.journeySelection}
                currency={this.state.currency}
                onOriginSelected={this.onOriginSelected}
                onDestinationSelected={this.onDestinationSelected}
                onSwapOriginAndDestination={this.onSwapOriginAndDestination}
                onDatesSelected={this.onDatesSelected}
                onPassengersSelected={this.onPassengersSelected}
                isJourneySelectionValid={this.isJourneySelectionValid}
                isChildUnaccompanied={this.isChildUnaccompanied}
                onJourneySubmit={this.onJourneySubmitButtonClick}
                onClear={this.onClear}
                buttonLoading={this.state.buttonLoading}
                hasOffer={this.state.hasOffer}
                {...this.props}
            />
        )
    },

    onOriginSelected (origin, callback) {
        this._updateJourneySelection({origin: {$set: origin}}, callback)
    },

    onDestinationSelected (destination, callback) {
        this._updateJourneySelection({destination: {$set: destination}}, callback)
    },

    onSwapOriginAndDestination () {
        this._updateJourneySelection({
            origin: {$set: this.state.journeySelection.destination},
            destination: {$set: this.state.journeySelection.origin}
        })
    },

    onPassengersSelected (passengers) {
        this._updateJourneySelection({passengers: {$set: passengers}})
    },

    onDatesSelected (dates) {
        let journeySelection = {}

        if (typeof dates.inboundDate !== 'undefined') {
            let inboundDate = dates.inboundDate
            const outboundDate = 'outboundDate' in dates
                ? dates.outboundDate : this.state.journeySelection.outboundDate

            if (inboundDate && inboundDate.getTime() < outboundDate.getTime()) {
                inboundDate = outboundDate
            }

            journeySelection.inboundDate = {$set: inboundDate}
        }

        if (typeof dates.outboundDate !== 'undefined') {
            journeySelection.outboundDate = {$set: dates.outboundDate}

            const inboundDate = 'inboundDate' in dates ? dates.inboundDate : this.state.journeySelection.inboundDate
            if (inboundDate && inboundDate.getTime && inboundDate.getTime() < dates.outboundDate.getTime()) {
                journeySelection.inboundDate = {$set: dates.outboundDate}
            }
        }

        this._updateJourneySelection(journeySelection)
    },

    onJourneySubmitButtonClick (event) {
        event.preventDefault()
        if (this.isJourneySelectionValid()) {
            actions.getOffer(_.merge(this.state.journeySelection, {currency: this.state.currency}))
        }
    },

    onClear (event) {
        event.preventDefault()
        actions.clearOffer()
    },

    isPassengerTypeSelectionValid () {
        return this.state.journeySelection.passengers.some(({type}) => type !== PASSENGER_TYPE_BABY && type !== PASSENGER_TYPE_CHILD)
    },

    isChildUnaccompanied () {
        return !this.isPassengerTypeSelectionValid() &&
            this.state.journeySelection.passengers.some(({type}) =>
                type === PASSENGER_TYPE_BABY || type === PASSENGER_TYPE_CHILD)
    },

    isJourneySelectionValid () {
        return this.state.journeySelection.origin &&
            this.state.journeySelection.origin.length > 0 &&
            this.state.journeySelection.destination.length > 0 &&
            this.state.journeySelection.outboundDate instanceof Date &&
            this.isPassengerTypeSelectionValid()
    },

    onLoadStations (data) {
        if (!data.loading) {
            this.setState({
                stations: data.stations,
                stationsLoading: false
            })
        }
    },

    _loadStationData (state) {
        if (state.stations.length === 0 && !state.stationsLoading) {
            this.setState(
                {stationsLoading: true},
                () => actions.loadStations(S3P_SETTINGS.s3Passenger.features.stations)
            )
        }
    }

})
