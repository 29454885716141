import _ from 'lodash'
import _t from '../translate'

export const severity = {
    info: 'info',
    warning: 'warning',
    error: 'error'
}

export const severityMap = {
    1002: severity.info,
    1005: severity.info,
    1013: severity.info,
    1015: severity.info,
    1016: severity.warning,
    1017: severity.info,
    1018: severity.info,
    1021: severity.info,
    1038: severity.info,
    1066: severity.info,
    1069: severity.info,
    3003: severity.info,
    3004: severity.info,
    3005: severity.info,
    3006: severity.info,
    3007: severity.info,
    3008: severity.info,
    3010: severity.info,
    3011: severity.info,
    3012: severity.info,
    3014: severity.info,
    3016: severity.info,
    3017: severity.info,
    3021: severity.warning,
    3025: severity.info,
    3026: severity.error,
    3027: severity.info,
    3028: severity.info,
    3029: severity.info,
    3031: severity.info,
    3032: severity.info,
    3033: severity.info,
    3034: severity.info,
    3035: severity.info,
    3036: severity.error,
    3037: severity.error,
    3038: severity.error,
    3040: severity.info,
    3041: severity.info,
    3044: severity.info,
    3046: severity.info,
    3053: severity.info,
    3088: severity.info,
    3089: severity.info,
    3090: severity.info,
    3063: severity.info,
    3163: severity.info,
    3164: severity.info,
    4003: severity.error,
    4004: severity.error,
    4009: severity.error,
    '4009.7': severity.error,
    4016: severity.info,
    4017: severity.info,
    10001: severity.info,
    10003: severity.info
}

export default {

    CRM_REGISTER_EMAIL_EXISTS: 1000,
    CRM_REGISTER_EMAIL_INVALID: 1001,
    INVALID_EXTERNAL_TOKEN_PROVIDED: 1066,

    ORIENTATION_DEEP_LINK_NO_BUNDLE_FOUND: 2007,
    ORIENTATION_DEEP_LINK_NO_OUTBOUND_TRAVEL_FOUND: 2008,
    ORIENTATION_DEEP_LINK_NO_INBOUND_TRAVEL_FOUND: 2009,
    ORIENTATION_DEEP_LINK_NO_ROUTE_FOUND: 2010,
    ORIENTATION_SALES_CHANNEL_REQUIRES_AGENT_AUTHORIZATION: 2012,
    ORIENTATION_SALES_CHANNEL_UNKNOWN: 2013,

    BOOKING_VOUCHER_NOT_FOUND: 3003,
    BOOKING_VOUCHER_NOT_FOR_SALE_TODAY: 3004,
    BOOKING_VOUCHER_NOT_AVAILABLE_FOR_TRAVEL_ON_DATE: 3005,
    BOOKING_VOUCHER_NOT_AVAILABLE_FOR_ANY_SERVICE: 3006,
    BOOKING_VOUCHER_NOT_AVAILABLE_FOR_TOTAL: 3007,
    BOOKING_VOUCHER_USED_BY_CUSTOMER: 3008,
    BOOKING_VOUCHER_NOT_ALLOWED_BY_CUSTOMER: 3009,
    BOOKING_VOUCHER_ALREADY_USED: 3010,
    BOOKING_VOUCHER_RESTRICTED: 3011,
    BOOKING_VOUCHER_AMOUNT_NOT_SUFFICIENT: 3012,
    BOOKING_VOUCHER_NOT_AVAILABLE_FOR_TRAVEL_DAY: 3014,
    BOOKING_NOT_FOUND: 3015,
    BOOKING_NOT_FOUND_FOR_BOOKING_NUMBER: 3016,
    BOOKING_VOUCHER_DOES_NOT_EXISTS: 3017,
    BOOKING_FULFILLMENT_METHODS_MISSING: 3021,
    BOOKING_VOUCHER_FULL_AMOUNT_USED: 3025,
    BOOKING_CREATE_UNKNOWN_ERROR: 3026,
    BOOKING_CREATE_NO_AVAILABLE_SEAT_COMBINATION: 3027,
    BOOKING_CREATE_NO_LOGICAL_AVAILABILITY: 3028,
    BOOKING_RESEND_TICKETS_BOOKING_NOT_FOUND: 3029,
    BOOKING_CREATE_INVALID_REQUEST_FOR_SERVICE: 3031,
    BOOKING_CREATE_COULD_NOT_ALLOCATE_SEATS: 3032,
    BOOKING_CREATE_NO_AVAILABLE_CARRIAGES: 3033,
    BOOKING_CREATE_NO_LOGICAL_OR_PHYSICAL_AVAILABILITY: 3034,
    BOOKING_IS_PENDING_IN_ANOTHER_QUEUE: 3035,
    BOOKING_IS_CANCELLED: 3036,
    BOOKING_IS_EXPIRED: 3037,
    BOOKING_VOUCHER_NOT_AVAILABLE_FOR_BOOKING: 3040,
    BOOKING_VOUCHER_NOT_AVAILABLE_IN_BOOKING_CURRENCY: 3041,
    BOOKING_VOUCHER_EMAIL_VALIDATION_FAILED: 3044,
    BOOKING_VOUCHER_NOT_AVAILABLE_FOR_SALESCHANNEL: 3046,
    BOOKING_SEAT_NOT_AVAILABLE: 3053,
    BOOKING_SEAT_CAN_NOT_BE_ALLOCATED: 3165,
    BOOKING_VOUCHER_CUSTOMER_DETAILS_REQUIRED: 3063,
    BOOKING_CONFIRM_PAYMENT_ITEM_INCONSISTENT: 3068,
    BOOKING_VOUCHER_REFUND_NO_ELIGIBLE_TARIFF: 3088,
    BOOKING_VOUCHER_COMPENSATION_NO_ELIGIBLE_TARIFF: 3089,
    BOOKING_VOUCHER_PREPAID_NO_ELIGIBLE_TARIFF: 3090,
    BOOKING_VOUCHER_NOT_AVAILABLE_FOR_PRODUCT: 3121,
    BOOKING_VOUCHER_PREPAID_NOT_VALID_FOR_TRAVEL: 3142,
    BOOKING_VOUCHER_PREPAID_NOT_VALID_FOR_SALE: 3143,
    BOOKING_CUSTOMER_SEARCH_NOT_SPECIFIC_ENOUGH: 3163,
    BOOKING_PASSENGER_SEARCH_NOT_SPECIFIC_ENOUGH: 3164,
    PAYMENT_NO_REFUND_PAYMENT_FOR_REFUND_VOUCHER: 4003,
    PAYMENT_NO_CUSTOMER_INFORMATION_FOR_REFUND_VOUCHER: 4004,
    PAYMENT_UNKNOWN_INITIATE_PAYMENT_ERROR: 4009,
    PAYMENT_CARD_TYPE_NOT_SUPPORTED: '4009.7',
    PAYMENT_NOT_FOUND_FOR_REFERENCE: 4014,
    PAYMENT_IS_REFUSED: 4016,
    PAYMENT_IS_CANCELLED: 4017,
    CARRIAGE_LAYOUT_SCHEDULE_UNAVAILABLE: 8002,

    VOUCHER_ALREADY_USED: 10001,
    VOUCHER_NOT_FOUND: 10003,

    messages: {
        1002: 'messages.agent_bad_credentials',
        1005: 'messages.agent-has-no-contracts',
        1013: 'messages.agent_with_given_username_already_exists',
        1015: 'messages.agent_reset_password_not_found',
        1016: 'messages.agent_change_password_weak',
        1017: 'messages.agent_blocked_password',
        1018: 'messages.agent_inactive',
        1021: 'messages.agent-has-no-offices',
        1069: 'messages.agent_reset_password_blocked',
        3003: 'messages.voucher_not_found',
        3004: 'messages.voucher_not_for_sale_today',
        3005: 'messages.voucher_not_available_for_travelling_on_give_date',
        3006: 'messages.voucher_not_available_for_any_service',
        3007: 'messages.voucher_not_available_for_total',
        3008: 'messages.voucher_used_by_customer',
        3009: 'messages.voucher-not-allowed-by-customer',
        3010: 'messages.voucher_already_used',
        3011: 'messages.voucher-restricted',
        3012: 'messages.voucher_amount_not_sufficient',
        3014: 'messages.voucher_not_available_for_travel_day',
        3016: 'messages.booking_is_not_found',
        3017: 'messages.voucher_to_remove_does_not_exists',
        3021: 'messages.booking_create_not_available',
        3025: 'messages.voucher_full_amount_used',
        3026: 'messages.booking_create_unknown_error',
        3027: 'messages.booking_create_not_available',
        3028: 'messages.booking_create_not_available',
        3029: 'messages.booking_resend_tickets_booking_not_found',
        3031: 'messages.booking_create_not_available',
        3032: 'messages.booking_create_not_available',
        3033: 'messages.booking_create_not_available',
        3034: 'messages.booking_create_not_available',
        3035: 'messages.booking_is_pending_an_another_queue',
        3036: 'messages.booking_is_cancelled',
        3037: 'messages.booking_is_expired',
        3038: 'messages.booking_is_not_found',
        3040: 'messages.voucher_not_available_for_booking',
        3041: 'messages.voucher_not_available_in_booking_currency',
        3044: 'messages.voucher_email_validation_failed',
        3046: 'messages.voucher_not_available_for_saleschannel',
        3053: 'messages.seat-not-available',
        3068: 'messages.payment-not-processed',
        3088: 'messages.voucher_refund_not_eligible',
        3089: 'messages.voucher_compensation_not_eligible',
        3090: 'messages.voucher_prepaid_not_eligible',
        3063: 'messages.booking_voucher_customer_details_required',
        3121: 'messages.voucher_not_available_for_product',
        3142: 'messages.booking_voucher_not_valid_for_travelling',
        3143: 'messages.booking_voucher_not_valid_for_sales',
        3163: 'messages.booking_search_term_not_specific_enough',
        3164: 'messages.booking_search_term_not_specific_enough',
        3165: 'messages.seat-not-available',
        4003: 'messages.voucher_no_refund_payment_for_refund_voucher',
        4004: 'messages.voucher_no_customer_information_for_refund_voucher',
        4009: 'messages.payment_unknown_initiate_payment_error',
        '4009.7': 'messages.payment_card_type_not_supported',
        4016: 'messages.payment-is-refused',
        4017: 'messages.payment-is-cancelled',
        10001: 'messages.voucher_already_added_to_crm_account',
        10003: 'messages.voucher_not_found'
    },

    hasMessage (code) {
        return _.has(this.messages, code)
    },

    translate (code, defaultValue, context) {
        try {
            if (context) {
                return _t.formatIntlMessage(_.get(this.messages, code), {...context})
            } else {
                return _t.getIntlMessage(_.get(this.messages, code))
            }
        } catch (err) {
            return defaultValue || _.get(this.messages, code, `error: ${code}`)
        }
    },

    getSeverity (code) {
        return code && code in severityMap ? severityMap[code] : severity.error
    }
}
