import PropTypes from 'prop-types'
import React, {Component} from 'react'

import TextLabel from './text-label'

export default class Heading extends Component {
    static propTypes = {
        title: PropTypes.string.isRequired
    }

    render () {
        return (
            <header>
                <span className='text-label heading'>
                    <TextLabel text={this.props.title} />
                </span>
            </header>
        )
    }
}
