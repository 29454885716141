import Reflux from 'reflux'
import _ from 'lodash'
import actions from '../actions'
import FormValidationMixin from '../mixin/form-validation-mixin'
import UserAwareMixin from '../mixin/user-aware-mixin'

export default Reflux.createStore({

    listenables: actions,

    mixins: [FormValidationMixin, UserAwareMixin],

    init () {
        this._resetData()

        this.validators = {
            bookingReference: [],
            email: [this._validateEmail]
        }
    },

    _resetData () {
        this.data = {
            isValid: false,
            errorText: null,
            form: 'email',
            defaultFields: {
                bookingReference: {
                    value: null,
                    errorText: null,
                    isRequired: true
                },
                code: {
                    value: null,
                    errorText: null,
                    isRequired: false
                },
                form: {
                    value: null,
                    errorText: null,
                    isRequired: false // this is checked by a validator
                }
            },
            forms: {
                email: {
                    fields: {
                        email: {
                            value: null,
                            errorText: null,
                            isRequired: true
                        }
                    }
                },
                passenger: {
                    fields: {
                        firstName: {
                            value: null,
                            errorText: null,
                            isRequired: true
                        },
                        lastName: {
                            value: null,
                            errorText: null,
                            isRequired: true
                        }
                    }
                },
                'lost-credentials': {}
            }
        }

        this._changeForm('email')
    },

    resetData () {
        this._resetData()
        this.trigger(this.data)
    },

    getInitialState () {
        return this.data
    },

    _changeForm (form) {
        this.data.form = form
        if (_.has(this.data.forms, form)) {
            let fields = _.clone(this.data.defaultFields)
            this.data.fields = _.extend(fields, this.data.forms[form].fields)
        } else {
            throw new Error('invalid form argument')
        }
    },

    onProcessBookingLoginData (newData) {
        const form = _(newData).find({id: 'form'})
        if (form) {
            this._changeForm(form.value)
        }

        newData.forEach(record => {
            const value = record.id === 'bookingReference' ? (record.value || '').trim().toUpperCase() : record.value
            this.data.fields[record.id].value = value
        })

        this._validateData(newData)
    }
})
