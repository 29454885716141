const _MutationObserver = window.WebKitMutationObserver || window.MutationObserver || null

class MutationObserverWatcher {
    constructor (tick) {
        this.tick = tick
        this.observer = null
        this.running = false
    }

    start () {
        if (this.running) {
            return
        }

        this.running = true

        if (_MutationObserver) {
            this.observer = new _MutationObserver(this.tick)
            this.observer.observe(document.body, {
                childList: true, subtree: true, attributes: true, attributeFilter: ['style']
            })
        } else {
            this.observer = event => {
                if (Array.isArray(event.path) && event.path[0] !== document.body) {
                    // event is triggered by a new element
                    this.tick()
                }
            }
            document.body.addEventListener('DOMSubtreeModified', this.observer)
        }
    }

    stop () {
        this.running = false
        if (this.observer) {
            if (_MutationObserver) {
                this.observer.disconnect()
            } else {
                document.body.removeEventListener('DOMSubtreeModified', this.observer)
            }
        }
    }
}

class WatchDOM {
    constructor (watcher) {
        this.watcher = watcher
        this.observers = []
        this.observer = null
        this.oldValue = null
    }

    tick () {
        const newValue = this.watcher()
        if (this.oldValue !== newValue) {
            this.observers.forEach(fn => fn())
        }
        this.oldValue = newValue
    }

    observe (fn) {
        this.observers.push(fn)

        if (!this.observer) {
            this.start()
        }

        return () => {
            this.observers = this.observers.filter(observer => observer !== fn)
        }
    }

    start () {
        if (!this.observer) {
            this.observer = new MutationObserverWatcher(this.tick.bind(this))
            this.observer.start()
        }
    }

    stop () {
        if (this.observer) {
            this.observer.stop()
        }
    }
}

export default WatchDOM
