import get from 'lodash/get'
import sumBy from 'lodash/sumBy'
import {createSelector} from 'reselect'
import {PRODUCT_CODE_LUGGAGE} from '../../../../constants'
import {bookingSelector} from '../../../../reflux/bridge/booking'
import {passengersSelector, withoutCancelledSelector} from '../../api/v2/booking/passengers'
import {
    additionalProductsSelector,
    requiredProductsSelector,
    withNonProvisionalNonCancelledSelector,
    withProvisionalNonCancelledSelector
} from '../../api/v2/booking/products'
import {TRAVEL_DIRECTION_INBOUND, TRAVEL_DIRECTION_OUTBOUND} from '../../constants'
import {selectedBundlesSelector} from '../orientation/journey-search'

// Get luggage products from the selected bundle from the journey search
export const luggageProductsSelector = createSelector(
    [selectedBundlesSelector],
    bundles => bundles.flatMap(
        bundle => bundle.items
            .filter(item => item.productCode === PRODUCT_CODE_LUGGAGE)
            .map(item => ({...item, direction: bundle.direction}))
    )
)

// Get an overview of the total price of the luggage product group per direction and per passenger
// Because it is a per passenger per journey product the total price is split across all segments in the selected journey
export const luggageTotalPricePerPassengerPerDirectionSelector = createSelector(
    [luggageProductsSelector],
    products => products.reduce((directions, {direction, passengerFares}) => {
        if (!directions[direction]) directions[direction] = {}

        directions[direction] = passengerFares.reduce((directionPricePerPassenger, {passengerId, price}) => {
            directionPricePerPassenger[passengerId] = directionPricePerPassenger[passengerId]
                ? directionPricePerPassenger[passengerId] + price
                : price

            return directionPricePerPassenger
        }, directions[direction])

        return directions
    }, [])
)

// Max quantity should be the same for every product for a direction
// return the max quantity of the first product found for the given direction
const luggageMaxQuantityForDirectionSelector = _direction => createSelector(
    [luggageProductsSelector],
    luggageProducts => get(luggageProducts.find(({direction}) => direction === _direction), 'maxQuantity', 0)
)

// Retrieve the maximum quantity allowed to add for a passenger in a direction based on the maxQuantity allowed
// for the luggage and the already confirmed items in an existing booking
export const luggageMaxQuantityForDirectionAndPassengerSelector = (_direction, passengerId) => createSelector(
    [
        luggageMaxQuantityForDirectionSelector(_direction),
        existingLuggageProductsPerPassengerBookingForDirectionSelector(_direction)
    ],
    (maxQuantity, existingLuggage) => maxQuantity > 0 ? maxQuantity - get(existingLuggage[passengerId], 'amount', 0) : 0
)

// Tariff code should be the same for every product for a direction and a passenger
// return the tariff code of the first product passenger fare found for the given direction and passenger
export const luggageTariffCodeForDirectionAndPassengerSelector = (_direction, _passengerId) => createSelector(
    [luggageProductsSelector],
    luggageProducts => luggageProducts.find(
        ({direction}) => direction === _direction
    ).passengerFares.find(
        ({passengerId}) => passengerId === _passengerId
    ).tariffCode
)

const mapLuggagePerPassenger = (_direction, products) =>
    products.reduce((passengerProducts, {productCode, direction, passenger, price, segmentId}) => {
        if (productCode !== PRODUCT_CODE_LUGGAGE || direction !== _direction) {
            return passengerProducts
        }

        let _amount = 1
        let _totalPrice = price
        let _singlePrice = price
        let _segmentId = segmentId

        if (passengerProducts[passenger.id]) {
            // To determine the correct amount to show to the customer only take one segment in the journey into account
            _segmentId = passengerProducts[passenger.id].segmentId
            _amount = passengerProducts[passenger.id].amount + (passengerProducts[passenger.id].segmentId === segmentId ? 1 : 0)
            // Total price of all selected luggage for this passenger
            _totalPrice += passengerProducts[passenger.id].totalPrice
            // Price of a single luggage for this passenger
            _singlePrice = passengerProducts[passenger.id].singlePrice + (passengerProducts[passenger.id].segmentId === segmentId ? price : 0)
        }

        return {
            ...passengerProducts,
            [passenger.id]: {
                amount: _amount,
                singlePrice: _singlePrice,
                totalPrice: _totalPrice,
                passenger,
                segmentId: _segmentId
            }
        }
    }, {})

// Retrieve the provisional luggage products from the booking grouped per passenger for a given direction
export const provisionalLuggageProductsPerPassengerBookingForDirectionSelector = _direction => createSelector(
    [withProvisionalNonCancelledSelector(additionalProductsSelector)],
    products => mapLuggagePerPassenger(_direction, products)
)

// Retrieve the existing luggage products from the booking grouped per passenger for a given direction
export const existingLuggageProductsPerPassengerBookingForDirectionSelector = _direction => createSelector(
    [withNonProvisionalNonCancelledSelector(additionalProductsSelector)],
    products => mapLuggagePerPassenger(_direction, products)
)

const canAddLuggageAfterSalesForDirectionSelector = _direction => createSelector(
    [
        existingLuggageProductsPerPassengerBookingForDirectionSelector(_direction),
        luggageMaxQuantityForDirectionSelector(_direction),
        withoutCancelledSelector(passengersSelector(bookingSelector))
    ],
    (existingLuggage, maxQuantity, passengers) => {
        const sumExistingLuggage = sumBy(Object.values(existingLuggage), 'amount')
        const potentialLuggage = maxQuantity * passengers.length

        return sumExistingLuggage < potentialLuggage
    }
)

export const canAddLuggageAfterSalesSelector = createSelector(
    [
        canAddLuggageAfterSalesForDirectionSelector(TRAVEL_DIRECTION_OUTBOUND),
        canAddLuggageAfterSalesForDirectionSelector(TRAVEL_DIRECTION_INBOUND)
    ],
    (canAddOutbound, canAddInbound) => canAddOutbound || canAddInbound
)

export const canOfferLuggageForDirectionSelector = _direction => createSelector(
    [requiredProductsSelector(bookingSelector)],
    products => {
        // Booking or Rebook flow
        const newlyAddedProducts = products.filter(({cancelled, provisional}) => !cancelled && provisional)
        if (newlyAddedProducts.length) {
            return newlyAddedProducts.some(({direction}) => direction === _direction)
        }

        // Add luggage in after sales flow
        return products.some(({cancelled, direction}) => !cancelled && direction === _direction)
    }
)
