import PropTypes from 'prop-types'
import React from 'react'
import Reflux from 'reflux'
import CmsStore from '../../reflux/stores/cms-store'
import createReactClass from 'create-react-class'

export default (...args) => Component => {
    const displayName = `CmsComponent(${Component.displayName || Component.name || 'Component'})`

    return createReactClass({
        displayName,

        mixins: [
            Reflux.listenTo(CmsStore, 'updateStore')
        ],

        contextTypes: {
            requireCmsBlocks: PropTypes.func
        },

        componentWillMount () {
            this.context.requireCmsBlocks(args)
        },

        getInitialState () {
            return CmsStore.getInitialState()
        },

        updateStore (data) {
            this.setState(data)
        },

        render () {
            return (
                <Component
                    {...this.props}
                    cmsContentLoading={this.state.loading && !this._areBlocksLoaded()}
                    cmsBlocks={this.state.cmsBlocks}
                />
            )
        },

        _areBlocksLoaded () {
            return args.every(blockName => this.state.cmsBlocks.some(block => block.name === blockName))
        }
    })
}
