// Libraries
import React, {Component} from 'react'
import PropTypes from 'prop-types'
import _t from '../../../translate'
import moment from 'moment-timezone'

// Components
import Button from '../../../element/button'
import Icon from '../../../element/icon'
import TextLabel from '../../../element/text-label'
import RebookingJourneySearchOutboundDate from './journey-search/outbound-date'
import RebookingJourneySearchInboundDate from './journey-search/inbound-date'

import BookingModel from '../../../models/booking-model'

export default class JourneySearch extends Component {
    static propTypes = {
        booking: PropTypes.instanceOf(BookingModel).isRequired,
        outBoundDate: PropTypes.instanceOf(Date),
        inBoundDate: PropTypes.instanceOf(Date),
        searchButtonEnabled: PropTypes.bool.isRequired,
        searchButtonLoading: PropTypes.bool.isRequired,
        onSearchButtonClick: PropTypes.func.isRequired,
        onChangeOutboundDate: PropTypes.func.isRequired,
        onChangeInboundDate: PropTypes.func.isRequired
    }

    constructor (...args) {
        super(...args)
        this.onChangeInboundDate = this.onChangeInboundDate.bind(this)
        this.onChangeOutboundDate = this.onChangeOutboundDate.bind(this)
    }

    onChangeInboundDate (value) {
        if (value === null && moment(this.props.outBoundDate).isAfter(this.props.booking.inboundTravelDate)) {
            this.props.onChangeOutboundDate(null)
        }
        this.props.onChangeInboundDate(value)
    }

    onChangeOutboundDate (value) {
        const inboundDate = this.props.inBoundDate || this.props.booking.inboundTravelDate
        if (moment(value).isAfter(inboundDate)) {
            this.props.onChangeOutboundDate(inboundDate)
        } else {
            this.props.onChangeOutboundDate(value)
        }
    }

    render () {
        return (
            <div className='rebooking-journey-search'>
                <form noValidate>
                    <div className='grid-row'>
                        <div className='grid-column--1-1 grid-column--medium-1-2'>
                            <RebookingJourneySearchOutboundDate
                                date={this.props.outBoundDate}
                                maxDate={this.props.inBoundDate || this.props.booking.inboundTravelDate}
                                enabled={this.props.booking.canRebookOutbound}
                                onChange={this.onChangeOutboundDate}
                                dateFormat='d M yy'
                            />
                        </div>
                        <div className='grid-column--1-1 grid-column--medium-1-2'>
                            {this.props.booking.canRebookInbound ? (
                                <RebookingJourneySearchInboundDate
                                    date={this.props.inBoundDate}
                                    minDate={this.props.outBoundDate || this.props.booking.outboundTravelDate}
                                    enabled
                                    onChange={this.onChangeInboundDate}
                                    dateFormat='d M yy'
                                />
                            ) : null}
                        </div>
                    </div>
                    <div className='grid-row'>
                        <div className='grid-column--1-1 grid-column--medium-1-2 push-grid-column--medium-1-4'>
                            <Button
                                type='button'
                                className='button search primary'
                                title={_t.getIntlMessage('my-s3-re-booking.journey-search.button-search-text')}
                                onClick={e => {
                                    e.preventDefault()
                                    this.props.onSearchButtonClick()
                                }}
                                disabled={!this.props.searchButtonEnabled}
                                loading={this.props.searchButtonLoading}
                            >
                                <TextLabel
                                    text={_t.getIntlMessage('my-s3-re-booking.journey-search.button-search-text')} />
                                <Icon type='chevron-right' className='xsmall align-right' />
                            </Button>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}
