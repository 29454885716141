import PropTypes from 'prop-types'
import React, {Component} from 'react'
import _t from '../../translate'

import TextLabel from '../../element/text-label'

export default class Sorter extends Component {
    static propTypes = {
        sortBy: PropTypes.oneOf(['departureTime', 'price', 'duration']).isRequired,
        onChange: PropTypes.func.isRequired,
        direction: PropTypes.string
    }

    constructor (...args) {
        super(...args)
        this.onChange = this.onChange.bind(this)
    }

    onChange (event) {
        this.props.onChange(event.target.value)
    }

    render () {
        const id = `journey-result-sort-${this.props.direction}`
        return (
            <div className='journey-result-set-filter journey-result-sort'>
                <div className='grid-row'>
                    <div className='grid-column--1-1'>
                        <label
                            htmlFor={id}
                            className='text-label sort-description'
                        >
                            <TextLabel text={_t.getIntlMessage('journey-result-set.sorter.sort-by')} />
                        </label>
                    </div>
                </div>
                <div className='grid-row'>
                    <div className='grid-column--1-1'>
                        <select id={id} onChange={this.onChange}>
                            <option value='departureTime'>
                                {_t.getIntlMessage('journey-result-set.sorter.departure')}
                            </option>
                            <option value='price'>
                                {_t.getIntlMessage('journey-result-set.sorter.price')}
                            </option>
                            <option value='duration'>
                                {_t.getIntlMessage('journey-result-set.sorter.duration')}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        )
    }
}
