import PropTypes from 'prop-types'
import React, {Component} from 'react'
import intersectionBy from 'lodash/intersectionBy'
import _t from '../../translate'
import ModalDialog from '../../element/modal-dialog'
import Button from '../../element/button'
import BookingModel from '../../models/booking-model'

export default class MyS3CancellationConfirmDialog extends Component {
    static propTypes = {
        booking: PropTypes.instanceOf(BookingModel).isRequired,
        cancelItemIds: PropTypes.array.isRequired,
        onCancel: PropTypes.func.isRequired,
        onConfirm: PropTypes.func.isRequired,
        loading: PropTypes.bool.isRequired
    }

    constructor (...args) {
        super(...args)
        this._areSomeSelected = this._areSomeSelected.bind(this)
        this._isPassengerDirectionSelected = this._isPassengerDirectionSelected.bind(this)
        this._isPassengerSelected = this._isPassengerSelected.bind(this)
        this._isPassengerDirectionSelected = this._isPassengerDirectionSelected.bind(this)
        this._getMappingForSingleTrip = this._getMappingForSingleTrip.bind(this)
        this._getMappingForReturnTrip = this._getMappingForReturnTrip.bind(this)
    }

    _areSomeSelected (products) {
        return products.some(product => this.props.cancelItemIds.indexOf(product.item_ref) >= 0)
    }

    _isPassengerDirectionSelected (passenger, direction) {
        const directionProducts = direction === 'outbound'
            ? this.props.booking.outboundProducts.value() : this.props.booking.inboundProducts.value()
        const passengerProducts = this.props.booking.products.filter({passenger_id: passenger.id}).value()

        return this._areSomeSelected(intersectionBy(directionProducts, passengerProducts, product => product.item_ref))
    }

    _isPassengerSelected (passenger) {
        return this._areSomeSelected(this.props.booking.products.filter({passenger_id: passenger.id}))
    }

    _getMappingForSingleTrip () {
        return {
            outbound: [],
            inbound: [],
            booking: this.props.booking.passengers.filter(passenger => this._isPassengerSelected(passenger)).value()
        }
    }

    _getMappingForReturnTrip () {
        return this.props.booking.passengers.reduce(
            (mapping, passenger) => {
                const outboundSelected = this._isPassengerDirectionSelected(passenger, 'outbound')
                const inboundSelected = this._isPassengerDirectionSelected(passenger, 'inbound')

                if (outboundSelected && inboundSelected) {
                    mapping['booking'].push(passenger)
                } else if (outboundSelected) {
                    mapping['outbound'].push(passenger)
                } else if (inboundSelected) {
                    mapping['inbound'].push(passenger)
                }

                return mapping
            },
            {outbound: [], inbound: [], booking: []}
        )
    }

    render () {
        const mapping = this.props.booking.isReturnTrip()
            ? this._getMappingForReturnTrip() : this._getMappingForSingleTrip()

        return (
            <ModalDialog
                headerTitle={_t.message('my-s3-cancellation.confirm-dialog.header')}
                onClose={this.props.onCancel}
            >
                <div className='content'>
                    {this._renderPassengerList(mapping, 'outbound')}
                    {this._renderPassengerList(mapping, 'inbound')}
                    {this._renderPassengerList(mapping, 'booking')}
                    <div className='button-group'>
                        <ul className='horizontal-list'>
                            <li>
                                <Button
                                    type='button'
                                    className='button default'
                                    text={_t.getIntlMessage('my-s3-cancellation.confirm-dialog.cancel-button')}
                                    onClick={this.props.onCancel}
                                />
                            </li>
                            <li>
                                <Button
                                    type='button'
                                    className='button primary'
                                    text={_t.getIntlMessage('my-s3-cancellation.confirm-dialog.confirm-button')}
                                    onClick={this.props.onConfirm}
                                    loading={this.props.loading}
                                />
                            </li>
                        </ul>
                    </div>
                </div>
            </ModalDialog>
        )
    }

    _renderPassengerList (mapping, direction) {
        return mapping[direction].length > 0 ? [
            <p key={`label-${direction}`}>{_t.message(`my-s3-cancellation.confirm-dialog.confirm-${direction}`)}</p>,
            <ul key={`list-${direction}`}>
                {mapping[direction].map(passenger => (
                    <li key={passenger.id}>
                        <strong>{`${passenger.first_name} ${passenger.last_name}`}</strong>
                    </li>
                ))}
            </ul>
        ] : null
    }
}
