import React, {Component} from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import TextLabel from './text-label'

const PropTypeTab = PropTypes.shape({
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    icon: PropTypes.element
})

export default class Tabs extends Component {
    static propTypes = {
        tabs: PropTypes.arrayOf(PropTypeTab).isRequired,
        goto: PropTypes.func.isRequired,
        current: PropTypes.string,
        className: PropTypes.string
    }

    constructor (...args) {
        super(...args)
        this.onClick = this.onClick.bind(this)
        this.renderTab = this.renderTab.bind(this)
    }

    onClick (event, tab) {
        event.preventDefault()
        this.props.goto(tab)
    }

    render () {
        return (
            <div className={classNames('tabs', this.props.className)}>
                <ul>
                    {(this.props.tabs || []).map(this.renderTab)}
                </ul>
            </div>
        )
    }

    renderTab (tab) {
        return (
            <li
                key={tab.key}
                className={classNames(this.props.className, {active: this.props.current === tab.key})}
            >
                <a href='#' onClick={event => this.onClick(event, tab.key)}>
                    <span className='text-label tab-description' title={tab.title}>
                        {tab.icon ? React.cloneElement(tab.icon) : null}
                        <TextLabel text={tab.label} />
                    </span>
                </a>
            </li>
        )
    }
}
