/* globals S3P_SETTINGS: true */

import snakeCase from 'lodash/snakeCase'
import oauth from '../oauth'
import request from 'superagent'
import _t from '../translate'
import get from 'lodash/get'
import _ from 'lodash'
import actions from '../reflux/actions'
import omitBy from 'lodash/omitBy'
import isUndefined from 'lodash/isUndefined'
import qs from 'qs'
import recursiveQs from '../misc/recursive-qs'
import {getUnauthorizedPage} from '../misc/unauthorized-pages'
import {toUtcDateMoment} from '../misc/date'
import {
    captureException,
    withScope
} from '@sentry/browser'
import ResponseCodes, {severity} from './response-codes'
import {
    HTTP_CODE_ORIGIN_UNREACHABLE,
    HTTP_CODE_UNAUTHORIZED,
    HTTP_CODE_SERVICE_UNAVAILABLE
} from '../constants'
import {snakeCaseKeys} from '../misc/camelcase'

export const sentryIgnoredHttpCodes = [
    HTTP_CODE_ORIGIN_UNREACHABLE,
    HTTP_CODE_UNAUTHORIZED,
    HTTP_CODE_SERVICE_UNAVAILABLE
]

const checkUrl = url => typeof url === 'string' && url.indexOf('http') === 0 ? url : null

const logRavenException = (error, unhandledApiError) => {
    if (checkUrl(S3P_SETTINGS.s3Passenger.sentry.dsn)) {
        const {response} = error
        const errorCode = get(response, 'body.error.code') || get(response, 'body.code')
        const statusCode = get(response, 'statusCode', 0)
        const level = ResponseCodes.getSeverity(errorCode)

        if (level === severity.error && statusCode < 500) {
            let fingerprint = []
            if (errorCode) {
                fingerprint.push(`errorCode_${errorCode}`)
            } else {
                fingerprint.push('{{ default }}')
            }
            if (statusCode) {
                fingerprint.push(`statusCode_${statusCode}`)
            }

            withScope(scope => {
                scope.setLevel(level)
                scope.setFingerprint(fingerprint)
                scope.setExtra('errorCode', errorCode)
                scope.setExtra('error', get(response, 'body.error.message') || get(response, 'body.message') || get(response, 'body.body.travels'))
                scope.setExtra('statusCode', statusCode)
                scope.setExtra('appName', get(response, 'headers.x-s3-appname'))
                scope.setExtra('sentryEventId', get(response, 'headers.x-sentry-event-id'))
                scope.setExtra('correlationId', get(response, 'headers.x-s3-correlation-id'))
                scope.setExtra('unhandledApiError', unhandledApiError)

                captureException(get(response, 'error', error))
            })
        }
    }
}

export default {

    state: {errorRedirect: false},

    _wrapRequest (request, token, resolve, optional = {}) {
        if (token instanceof Error) {
            oauth.logout()
            this._handleError(request, token, token.response)
        } else if (!this.state.errorRedirect) {
            return request
                .set('Accept-Language', _t.getLocales())
                .set('Authorization', 'Bearer ' + token.access_token)
                .end((error, response) => {
                    if (error) {
                        this._handleError(request, error, response)
                    } else if (!_.isEmpty(optional)) {
                        resolve({data: response.body, optional})
                    } else {
                        resolve(response.body)
                    }
                })
        }
    },

    _handleError (request, error, response) {
        let unhandledApiError = false
        if (!this._triggerApiError(request, error, response)) {
            this._handleUnhandledApiError(response)
            unhandledApiError = true
        }

        if (error &&
            error instanceof Error &&
            !sentryIgnoredHttpCodes.includes(error.statusCode) &&
            !(request && request.method === 'HEAD')
        ) {
            logRavenException(error, unhandledApiError)
        }
    },

    _handleOAuthError (error, response) {
        if (error && error.status >= 500) {
            this._redirectToErrorPage()
        }
        if (!this._triggerApiError(undefined, error, response)) {
            this._redirectToUnauthorizedPage()
        }
    },

    _triggerApiError (request, error, response) {
        let code = get(response, 'body.error.code') ||
            get(response, 'body.code') ||
            get(response, 'headers.x-oauth-errorcode')
        code = code && parseInt(code)

        const subCode = get(response, 'body.error.sub_code')
        if (subCode) {
            code = `${code}.${subCode}`
        }

        const errorObject = {
            code,
            request,
            error,
            response,
            handled: false,
            redirectUrl: null,
            flashMessage: null
        }

        actions.apiError(errorObject)

        if (errorObject.redirectUrl && errorObject.redirectUrl.startsWith('http')) {
            window.location = errorObject.redirectUrl
            return true
        }

        if (errorObject.redirectUrl) {
            window.reacthistory.push(errorObject.redirectUrl)
        }

        if (errorObject.flashMessage) {
            actions.showFlashMessage(errorObject.flashMessage)
        }

        return errorObject.handled
    },

    _handleUnhandledApiError (response) {
        switch (get(response, 'statusCode')) {
            case 401:
                oauth.logout()
                this._redirectToUnauthorizedPage()
                break
            default:
                this._redirectToErrorPage()
                break
        }
    },

    _redirectToUnauthorizedPage () {
        const hasToken = oauth.hasToken() || oauth.hasPendingRequest()

        if (!hasToken && !this.state.errorRedirect) {
            actions.logout().then(() => {
                window.reacthistory.push(getUnauthorizedPage())
            })
        }
    },

    _redirectToErrorPage () {
        this.state.errorRedirect = true
        const errorPage = S3P_SETTINGS.s3Passenger.features.errorPage

        window.reacthistory.push(`/${_t.getLocales()}${errorPage}`)
    },

    _get (endPoint) {
        return new Promise(resolve => {
            oauth.process()
                .then(token => {
                    this._wrapRequest(request.get(S3P_SETTINGS.s3Passenger.baseUri + endPoint).send(), token, resolve)
                })
                .catch(data => {
                    this._handleOAuthError(get(data, 'response.error', 'unknown'), get(data, 'response'))
                })
        })
    },

    _head (endPoint) {
        return new Promise(resolve => {
            oauth.process()
                .then(token => {
                    this._wrapRequest(request.head(S3P_SETTINGS.s3Passenger.baseUri + endPoint).send(), token, resolve)
                })
                .catch(data => {
                    this._handleOAuthError(get(data, 'response.error', 'unknown'), get(data, 'response'))
                })
        })
    },

    _put (data, endPoint) {
        return new Promise(resolve => {
            oauth.process()
                .then(token => {
                    this._wrapRequest(
                        request.put(S3P_SETTINGS.s3Passenger.baseUri + endPoint).send(data),
                        token,
                        resolve
                    )
                })
                .catch(data => {
                    this._handleOAuthError(get(data, 'response.error', 'unknown'), get(data, 'response'))
                })
        })
    },

    _patch (data, endPoint) {
        return new Promise(resolve => {
            oauth.process()
                .then(token => {
                    this._wrapRequest(
                        request.patch(S3P_SETTINGS.s3Passenger.baseUri + endPoint).send(data),
                        token,
                        resolve
                    )
                })
                .catch(data => {
                    this._handleOAuthError(get(data, 'response.error', 'unknown'), get(data, 'response'))
                })
        })
    },

    _post (data, endPoint, optional = {}) {
        return new Promise(resolve => {
            oauth.process()
                .then(token => {
                    this._wrapRequest(
                        request.post(S3P_SETTINGS.s3Passenger.baseUri + endPoint).send(data),
                        token,
                        resolve,
                        optional
                    )
                })
                .catch(data => {
                    this._handleOAuthError(get(data, 'response.error', 'unknown'), get(data, 'response'))
                })
        })
    },

    _delete (data, endPoint) {
        return new Promise(resolve => {
            oauth.process()
                .then(token => {
                    this._wrapRequest(
                        request.del(S3P_SETTINGS.s3Passenger.baseUri + endPoint).send(data),
                        token,
                        resolve
                    )
                })
                .catch(data => {
                    this._handleOAuthError(get(data, 'response.error', 'unknown'), get(data, 'response'))
                })
        })
    },

    _prepareGetOfferPostData (data) {
        const travels = []
        if (data.outboundDate instanceof Date) {
            travels.push({
                origin: data.origin,
                destination: data.destination,
                direction: 'outbound',
                max_transfers: S3P_SETTINGS.s3Passenger.features.stations.maxTransfers,
                id: 'travel_1',
                departure: toUtcDateMoment(data.outboundDate).format('YYYY-MM-DD')
            })
        }

        if (data.inboundDate instanceof Date) {
            travels.push({
                origin: data.inboundOrigin || data.destination,
                destination: data.inboundDestination || data.origin,
                direction: 'inbound',
                max_transfers: S3P_SETTINGS.s3Passenger.features.stations.maxTransfers,
                id: 'travel_2',
                departure: toUtcDateMoment(data.inboundDate).format('YYYY-MM-DD')
            })
        }
        return {
            contract_code: data.contractCode,
            currency: data.currency,
            passengers: data.passengers.map(snakeCaseKeys),
            is_aftersales: false,
            travels
        }
    },

    loadStations (options = {}) {
        const query = Object.entries(options).reduce((result, [key, value]) => result.concat(`${snakeCase(key)}=${value}`), [])

        const urlQuery = query.length ? '?' + query.join('&') : ''

        return this._get('/api/v2/meta/stations' + urlQuery)
    },

    loadUserLoginInformation () {
        return this._get('/api/v2/user')
    },

    loadCrmUserInformation () {
        return this._get('/api/v2/user/crm')
    },

    updateCrmUserInformation (data) {
        return this._put(data, '/api/v2/user/crm')
    },

    getOffer (data) {
        return this._post(this._prepareGetOfferPostData(data), '/api/v2/orientation/journey-search')
    },

    getCalendar (data) {
        if (data.calendars.length > 0) {
            return this._post(data, '/api/v2/orientation/journey-search/calendar')
        }
    },

    getProducts (data) {
        return this._post(data, '/api/v2/orientation/product-search')
    },

    createBooking (payload) {
        return this._post(payload, '/api/v2/booking')
    },

    rebookBooking (bookingNumber, payload) {
        return this._post(payload, `/api/v2/booking/${bookingNumber}/rebook`)
    },

    patchBooking (bookingNumber, data) {
        const payload = {
            ...data,
            segments: data.segments.map(segment => omitBy({
                ...snakeCaseKeys(segment),
                items: segment.items.map(snakeCaseKeys)
            }, isUndefined))
        }
        return this._patch(
            payload,
            `/api/v2/booking/${bookingNumber}`
        )
    },

    updateCustomer (bookingNumber, customer) {
        return this._put({customer}, `/api/v2/booking/${bookingNumber}/customer`)
    },

    addCustomer (bookingNumber, customer) {
        return this._post({customer}, `/api/v2/booking/${bookingNumber}/customer`)
    },

    updatePassengers (bookingNumber, data) {
        return this._patch(snakeCaseKeys(data), `/api/v2/booking/${bookingNumber}/passengers`)
    },

    getBooking (bookingNumber) {
        return this._get(`/api/v2/booking/${bookingNumber}`)
    },

    loadPaymentDetails (bookingNumber, paymentReference) {
        return this._get(`/api/v2/payment/details/${bookingNumber}/${paymentReference}`)
    },

    deleteItems (bookingNumber, data) {
        return this._delete(data, `/api/v2/booking/${bookingNumber}/items`)
    },

    revertBooking (bookingNumber) {
        return this._post(null, `/api/v2/booking/${bookingNumber}/revert`)
    },

    confirmBooking (bookingNumber) {
        return this._post({}, `/api/v2/booking/${bookingNumber}/confirm`)
    },

    cancelBooking (bookingNumber) {
        return this._post({}, `/api/v2/booking/${bookingNumber}/cancel`)
    },

    myS3GetBookings (fieldName, fieldValue) {
        let suffix = ''
        if (fieldName && fieldValue) {
            suffix = `/${fieldName}/${encodeURIComponent(fieldValue)}`
        }

        return this._get('/api/v2/bookings' + suffix)
    },

    myS3GetFavoritePassengers () {
        return this._get('/api/v2/user/crm/passengers')
    },

    myS3CreateFavoritePassengers (data) {
        return this._post(data, '/api/v2/user/crm/passengers')
    },

    myS3UpdateFavoritePassengers (data) {
        return this._put(data, '/api/v2/user/crm/passengers')
    },

    myS3DeleteFavoritePassengers (data) {
        return this._delete(data, '/api/v2/user/crm/passengers')
    },

    getCarriageLayouts (data) {
        const path = [data.serviceName, data.fromStationUIC, data.toStationUIC, data.travelDate].join('/')

        return this._get(`/api/v2/meta/carriage-layouts/${path}`)
    },

    cancelItems (bookingNumber, data) {
        return this._post(snakeCaseKeys(data), `/api/v2/booking/${bookingNumber}/items/cancel`)
    },

    myS3GetInvoiceUrl (bookingNumber) {
        return this._get(`/api/v2/booking/${bookingNumber}/invoice-url`)
    },

    myS3SaveBookingNote (bookingNumber, note) {
        return this._post(note, `/api/v2/booking/${bookingNumber}/note`)
    },

    registerCrmUser (postData) {
        return this._post(postData, '/api/register/crm')
    },

    getPaymentMethods (bookingNumber) {
        return this._get(`/api/v2/payment/methods/${bookingNumber}`)
    },

    initiatePayment (postData) {
        return this._post(postData, '/api/v2/payment')
    },

    processPayment (postData) {
        return this._post(postData, '/api/v2/payment/process')
    },

    addVoucher (postData) {
        return this._post(postData, '/api/v2/payment/voucher')
    },

    removeVoucher (postData) {
        return this._delete(postData, '/api/v2/payment/voucher')
    },

    requestRefund (type, bookingNumber) {
        return this._post({booking_number: bookingNumber, type}, '/api/v2/request-refund')
    },

    resendTickets (data) {
        return this._post(data, '/api/v2/resend-tickets')
    },

    loadCmsBlocks (blockNames) {
        return this._get('/api/v2/cms?' + qs.stringify({block: blockNames}))
    },

    searchVouchers (data) {
        return this._get(`/api/v2/vouchers?${recursiveQs.stringify(data)}`)
    },

    requestJsonApiKey (postData) {
        return this._post(postData, '/api/api-keys')
    },

    addPayments (payments, bookingNumber) {
        const data = {payments: payments}
        return this._post(
            data,
            `/api/v2/booking/${bookingNumber}/payments`
        )
    },

    updatePayments (payments, bookingNumber) {
        const data = {payments: payments}
        return this._patch(
            data,
            `/api/v2/booking/${bookingNumber}/payments`
        )
    },

    requestCrmVoucher (voucherCode) {
        return this._get(`/api/v2/user/crm/voucher?voucherCode=${voucherCode}`)
    },

    requestCrmVouchers () {
        return this._get('/api/v2/user/crm/vouchers')
    },

    addCrmVoucher (voucherCode) {
        const data = {voucher_codes: Array.isArray(voucherCode) ? voucherCode : [voucherCode]}
        return this._post(
            data,
            '/api/v2/user/crm/vouchers'
        )
    },

    deleteCrmVoucher (voucherCode) {
        const data = {voucher_codes: Array.isArray(voucherCode) ? voucherCode : [voucherCode]}
        return this._delete(
            data,
            '/api/v2/user/crm/vouchers'
        )
    },

    getServices (date, origin, destination, serviceType, serviceName) {
        const query = qs.stringify({
            date: date,
            origin: origin,
            destination: destination,
            service_type: serviceType,
            service_name: serviceName
        })
        return this._get(`/api/v2/orientation/search-services?${query}`)
    },

    resetErrorRedirect () {
        this.state.errorRedirect = false
    },

    updateSeats (bookingNumber, seats) {
        const data = {seats: Array.isArray(seats) ? seats : [seats]}

        return this._patch(
            data,
            `/api/v2/booking/${bookingNumber}/seats`
        )
    },

    addAdditionalDetails (bookingNumber, additionalDetails) {
        const data = {additional_details: Array.isArray(additionalDetails) ? additionalDetails : [additionalDetails]}
        return this._post(
            data,
            `/api/v2/booking/${bookingNumber}/additional-details`
        )
    },

    updateAdditionalDetails (bookingNumber, additionalDetails) {
        const data = {additional_details: additionalDetails}
        return this._patch(
            data,
            `/api/v2/booking/${bookingNumber}/additional-details`
        )
    }
}
