import PropTypes from 'prop-types'
import React from 'react'
import actions from '../../reflux/actions'
import Loader from '../../element/loader'
import OfferStore from '../../reflux/stores/offer-store'
import CalendarStore from '../../reflux/stores/calendar-store'
import {connectState, getState} from '../../reflux/bridge/connect-state'
import {isValidBundleSelectionSelector} from '../../models/selectors/components/orientation/journey-search'
import {journeySearchOfferSelector} from '../../reflux/bridge/journey-search'
import {dateStringToLocalizedDate} from '../../misc/date'
import createReactClass from 'create-react-class'

const restoreJourneySearch = (Component, options = {}) => {
    const RestoreJourneySearch = createReactClass({

        propTypes: {
            isValidBundleSelection: PropTypes.bool,
            hasOffer: PropTypes.bool,
            offerLoading: PropTypes.bool
        },

        componentDidMount () {
            if (!OfferStore.hasPreviousJourneySearchData()) {
                return
            }

            if (!this.props.offerLoading && !this.props.hasOffer) {
                const journeySearchStorage = OfferStore.getPreviousJourneySearchData()
                const processedJourneySearch = this.processJourneySearchData(journeySearchStorage)

                actions.getOffer(processedJourneySearch)
                actions.getCalendar(CalendarStore.createDateSwitcherRequestData(processedJourneySearch))
            }
        },

        componentWillReceiveProps (nextProps) {
            if (!nextProps.offerLoading && nextProps.hasOffer) {
                this.checkBundleSelection(nextProps)
            }
        },

        processJourneySearchData (journeySearch) {
            return journeySearch ? {
                ...journeySearch,
                outboundDate: dateStringToLocalizedDate(journeySearch.outboundDate),
                inboundDate: journeySearch.inboundDate ? dateStringToLocalizedDate(journeySearch.inboundDate) : null,
                contractCode: journeySearch.contractCode
            } : {}
        },

        checkBundleSelection (nextProps) {
            if (!nextProps.offerLoading && nextProps.hasOffer && !nextProps.isValidBundleSelection) {
                actions.clearOffer() // reset all bundles if bundle selection is invalid
            }
        },

        render () {
            return (this.props.offerLoading || !this.props.hasOffer) && options.withBlockingLoader && OfferStore.hasPreviousJourneySearchData()
                ? <Loader blocking />
                : <Component {...this.props} />
        }
    })

    const mapPropsToProps = () => {
        const state = getState()
        return {
            offerLoading: OfferStore.isLoading(),
            hasOffer: Boolean(journeySearchOfferSelector(state)),
            isValidBundleSelection: isValidBundleSelectionSelector(state)
        }
    }

    return connectState(mapPropsToProps, [OfferStore])(RestoreJourneySearch)
}

export default restoreJourneySearch
export const restoreJourneySearchCompose = options => Component => restoreJourneySearch(Component, options)
