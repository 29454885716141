// Libraries
import React, {Component} from 'react'
import PropTypes from 'prop-types'
import _t from '../translate'
import classNames from 'classnames'

// Components
import Icon from '../element/icon'
import TextLabel from '../element/text-label'

export default class Loader extends Component {
    static propTypes = {
        blocking: PropTypes.bool,
        overlay: PropTypes.bool,
        relativeToParent: PropTypes.bool,
        text: PropTypes.string
    }

    static defaultProps = {

        blocking: false,
        overlay: false,
        relativeToParent: false,
        text: _t.getIntlMessage('loading')
    }

    render () {
        const className = classNames([
            'loader',
            {'inline-loader': !this.props.blocking},
            {'blocking-loader': this.props.blocking},
            {'parent-is-relative': this.props.relativeToParent}
        ])

        return (
            <div className={className}>
                {this.props.overlay ? <div className='overlay' /> : null}
                <div className='loader-content'>
                    <span className='text-label loader-description'>
                        <Icon type='loader' className='small align-left loader-animation' />
                        <TextLabel text={this.props.text} />
                    </span>
                </div>
            </div>
        )
    }
}
