import moment from 'moment-timezone'
import FormValidationMixin from '../../reflux/mixin/form-validation-mixin'
import BookingModel from '../../models/booking-model'
import AccessManager from '../../data/access-manager'
import omitBy from 'lodash/omitBy'
import storage from '../../storage'
import forIn from 'lodash/forIn'
import JollicodeDiscountCardStore from '../stores/jollicode-discount-card-store'
import {
    PASSENGER_TYPE_ADULT,
    PASSENGER_TYPE_CHILD,
    PASSENGER_TYPE_BABY,
    PASSENGER_GENDER_FEMALE,
    PASSENGER_GENDER_MALE,
    PASSENGER_TYPE_YOUTH,
    ADDITIONAL_DETAIL_DISCOUNT_CARD,
    STORAGE_PASSENGER_DETAILS
} from '../../constants'
import actions from '../actions'
import {isBlank} from '../../misc/helpers'
import DOMPurify from 'dompurify'

const INVALID_DISCOUNT_NUMBER_ERROR = 'invalid-value'

export default {

    mixins: [FormValidationMixin],

    init () {
        this._resetData()
        this._initListenTo()

        this.validators = {
            gender: [
                value => this._validateInGenderOptionsList(value, this.getGenderOptions())
            ],
            firstName: [
                value => this._validateMinLength(value, 1),
                value => this._validateMaxLength(value, 50)
            ],
            lastName: [
                value => this._validateMinLength(value, 1),
                value => this._validateMaxLength(value, 50)
            ],
            dateOfBirth: [
                (value, field) => this._validateDate(value, field)
            ],
            discountCardNumber: [
                // Starts with J followed by 3 letters and 8 numbers. Total of 12 characters. Allowed to be empty.
                value => value && !value.match(/^(J|j)[a-zA-Z]{3}\d{8}$/) ? INVALID_DISCOUNT_NUMBER_ERROR : null
            ]
        }
        this.normalizers = {
            firstName: DOMPurify.sanitize,
            lastName: DOMPurify.sanitize,
            customerProfessionalId: DOMPurify.sanitize
        }

        this.listenTo(actions.checkDiscountCard.failed, this._addDiscountCardErrors)
        this.listenTo(actions.checkDiscountCard.completed, this._addDiscountCardErrors)
        this.listenTo(actions.cancelBooking.completed, this._addDiscountCardErrors)
        this.listenTo(actions.clearDiscountCardOfPassenger, this._addDiscountCardErrors)
    },

    _addDiscountCardErrors () {
        this._validateDiscountCard()
        this.trigger(this.data)
    },

    _validateDiscountCard () {
        if (!this.data.passengers) {
            return
        }

        // aggregate error from checkDiscountCard into passenger form
        this.data.passengers.forEach(this._validateDiscountCardOfPassenger)

        this.data.isValid = this._isDataValid()

        return this.data.isValid
    },

    _resetData () {
        this.data = {
            isValid: false,
            errorText: null
        }
    },

    resetData () {
        this._resetData()
        storage.clear('passengers_details')
        this.trigger(this.data)
    },

    resetPassenger (id) {
        const index = this.data.passengers.findIndex(passenger => passenger.id === id)
        const fields = this._getFields(this.data.passengers[index])
        if (fields.gender) {
            fields.gender.value = ''
        }
        fields.firstName.value = ''
        fields.lastName.value = ''
        fields.dateOfBirth.value = ''
        if (fields.crmSelect) {
            fields.crmSelect.value = ''
        }
        this.data.isValid = false
        this.data.passengers[index].fields = fields
        this.trigger(this.data)
    },

    getGenderOptions () {
        return [
            {
                id: 'female',
                text: 'personal-information-form.gender.values.f',
                value: PASSENGER_GENDER_FEMALE
            },
            {
                id: 'male',
                text: 'personal-information-form.gender.values.m',
                value: PASSENGER_GENDER_MALE
            }
        ]
    },

    getInitialState () {
        return this.data
    },

    _loadPassengers (booking) {
        this.data.bookingNumber = booking.booking_number
        this.data.booking = BookingModel.create(booking)

        this.data.passengers = this.data.booking.passengers.values().value().map(passenger => {
            return this._buildPassengerSubForm(passenger, this._getDefaultValuesForPassenger(passenger))
        })

        this.trigger(this.data)
    },

    _getDefaultValuesForPassenger (passenger) {
        const storagePassengers = storage.has(STORAGE_PASSENGER_DETAILS) ? storage.get(STORAGE_PASSENGER_DETAILS) : []
        const dataPassenger = storagePassengers.find(({id, type}) => id === passenger.id && type === passenger.type)

        const defaultValues = {}
        if (dataPassenger && dataPassenger.fields) {
            defaultValues.firstName = dataPassenger.fields.firstName && dataPassenger.fields.firstName.value
            defaultValues.lastName = dataPassenger.fields.lastName && dataPassenger.fields.lastName.value
            defaultValues.dateOfBirth = dataPassenger.fields.dateOfBirth && dataPassenger.fields.dateOfBirth.value
            defaultValues.gender = dataPassenger.fields.gender && dataPassenger.fields.gender.value
            defaultValues.discountCardNumber = dataPassenger.fields.discountCardNumber && dataPassenger.fields.discountCardNumber.value
        }

        return defaultValues
    },

    _buildPassengerSubForm (passenger, defaultValues) {
        return {
            id: passenger.id,
            type: passenger.type,
            passengerType: passenger.passengerType,
            isYouthWithDiscount: passenger.isYouthWithDiscount,
            timestamp: moment().format('x'),
            fields: this._getFields(passenger, defaultValues),
            refId: passenger.ref_id,
            isValid: false,
            isCancelled: Boolean(passenger.cancel_timestamp)
        }
    },

    _getFields (passenger, defaultValues = {}) {
        const dateOfBirth = passenger.birth_date
            ? moment(passenger.birth_date).format('DD/MM/YYYY')
            : defaultValues.dateOfBirth

        const fields = {
            firstName: {
                id: `${passenger.id}.firstName`,
                value: passenger.first_name || defaultValues.firstName || '',
                errorText: null,
                isRequired: true
            },
            lastName: {
                id: `${passenger.id}.lastName`,
                value: passenger.last_name || defaultValues.lastName || '',
                errorText: null,
                isRequired: true
            },
            dateOfBirth: {
                id: `${passenger.id}.dateOfBirth`,
                value: dateOfBirth || '',
                errorText: null,
                notice: null,
                isRequired: true
            }
        }

        if (passenger.isYouthWithDiscount) {
            fields.discountCardNumber = {
                id: `${passenger.id}.discountCardNumber`,
                value: defaultValues.discountCardNumber || '',
                errorText: null,
                isRequired: false
            }
        }

        if (passenger.type === PASSENGER_TYPE_ADULT) {
            fields.gender = {
                id: `${passenger.id}.gender`,
                value: passenger.gender || defaultValues.gender || '',
                errorText: null,
                isRequired: false,
                options: this.getGenderOptions()
            }
        }

        if (AccessManager.isCrmUser()) {
            fields.crmSelect = {
                id: `${passenger.id}.crmSelect`,
                value: '',
                errorText: null,
                isRequired: false
            }
        }
        return fields
    },

    /**
     * Check if any youth passenger with discount card has a discount card number filled in
     * @returns {Array} There are passengers which have a invalid formatted discount card
     */
    youthsWithDiscountAndInvalidCardNumber () {
        return this.data.passengers.filter(passenger => {
            const discountNumberField = passenger.fields.discountCardNumber
            return passenger.isYouthWithDiscount &&
                (!discountNumberField.value || (!discountNumberField.isValid && discountNumberField.errorText === INVALID_DISCOUNT_NUMBER_ERROR))
        })
    },

    getPassengerIdFromFieldId (fieldId) {
        return fieldId.split('.')[0]
    },

    getFieldNameFromFieldId (fieldId) {
        return fieldId.split('.')[1]
    },

    getPassenger (passengerId) {
        return this.data.passengers.find(({id}) => id === passengerId)
    },

    _processData (newData) {
        newData.forEach(record => {
            const passengerId = this.getPassengerIdFromFieldId(record.id)
            const fieldName = this.getFieldNameFromFieldId(record.id)

            const passenger = this.getPassenger(passengerId)
            if (fieldName && passenger.fields[fieldName]) {
                passenger.fields[fieldName].value = this.normalizers[fieldName]
                    ? this.normalizers[fieldName](record.value)
                    : record.value
            }

            if (fieldName === 'requireDateOfBirth') {
                passenger.fields.dateOfBirth.isRequired = Boolean(record.value)
                passenger.fields.dateOfBirth.isDisabled = !record.value
                if (!record.value) {
                    const originalPassenger = this.data.booking.passengers.find(({id}) => id === passenger.id)
                    passenger.fields.dateOfBirth.value = originalPassenger && originalPassenger.birth_date
                        ? moment(originalPassenger.birth_date).format('DD/MM/YYYY')
                        : ''
                    delete passenger.fields.dateOfBirth.isValid
                    delete passenger.fields.dateOfBirth.errorText
                }
            }
        })
        this._validateData(newData)
    },

    _validateData (newData) {
        let fieldIds = (undefined === newData) ? null : newData.map(record => record.id)
        this.data.isValid = false

        this.data.passengers.forEach(passenger => {
            passenger.isValid = this._validateFields(passenger, fieldIds)
            this._validateDiscountCardOfPassenger(passenger)
            this._addPassengerDateOfBirthNotice(passenger)
        })

        this._validateAgeOfPassengers()

        this.data.isValid = this._isDataValid()
        this.storePassengerDetails(this.data.passengers)

        this.trigger(this.data)
    },

    storePassengerDetails (data) {
        storage.set(STORAGE_PASSENGER_DETAILS, data)
    },

    prefillPassengers (passengers) {
        const _passengerSubForms = passengers.map(passenger => this._buildPassengerSubForm(passenger, passenger))
        this.storePassengerDetails(_passengerSubForms)
        this.data.passengers = _passengerSubForms
        this.trigger(this.data)
    },

    _isDataValid () {
        return this.data.passengers && this.data.passengers.every(({isValid}) => isValid)
    },

    _validateAgeOfPassengers () {
        let personsBelowTwelveFields = []
        let personsBetweenTwelveAndSixteen = []
        let personsEighteenOrOlder = 0
        let fields = []

        this.data.passengers.forEach(passenger => {
            const passengerAge = this.getPassengerAge(passenger.id)
            if (passengerAge >= 0) {
                fields.push(passenger)
                if (passengerAge < 12) {
                    personsBelowTwelveFields.push(passenger)
                }
                if (passengerAge >= 12 && passengerAge < 16) {
                    personsBetweenTwelveAndSixteen.push(passenger)
                }
                if (passengerAge >= 18) {
                    personsEighteenOrOlder += 1
                }
            }
        })

        if (fields.length === this.data.passengers.length) {
            if (personsEighteenOrOlder === 0 && personsBelowTwelveFields.length > 0) {
                personsBelowTwelveFields.forEach(passenger => {
                    passenger.isValid = false
                    passenger.fields.dateOfBirth.isValid = false
                    passenger.fields.dateOfBirth.errorText = 'no-adult-passengers-twelve'
                })
            } else if (this.data.booking.isInternationalTravel &&
                personsEighteenOrOlder === 0 &&
                personsBetweenTwelveAndSixteen.length > 0
            ) {
                personsBetweenTwelveAndSixteen.forEach(passenger => {
                    passenger.isValid = false
                    passenger.fields.dateOfBirth.isValid = false
                    passenger.fields.dateOfBirth.errorText = 'no-adult-passengers-international'
                })
            } else {
                fields.forEach(passenger => {
                    if (['no-adult-passengers-twelve', 'no-adult-passengers-international'].includes(passenger.fields.dateOfBirth.errorText)) {
                        passenger.fields.dateOfBirth.isValid = true
                        passenger.fields.dateOfBirth.errorText = null
                    }
                })
            }
        }
    },

    _validateDiscountCardOfPassenger (passenger) {
        let isValid = passenger.isValid
        const errors = JollicodeDiscountCardStore.getPassengerErrors(passenger.id)

        forIn(passenger.fields, (_, fieldName) => {
            // Skip validation if the field is invalid for other reasons
            // If the error we had came from the third party, the third party can make it valid again.
            // However if the error on the field was from something else, even if the third party doesn't trigger an error, the field does not become valid.
            const checkDiscountCardError = errors[fieldName]
            if (
                checkDiscountCardError &&
                (passenger.fields[fieldName].isValid || JollicodeDiscountCardStore.isCheckDiscountCardError(checkDiscountCardError))
            ) {
                isValid = false
                passenger.fields[fieldName].errorText = checkDiscountCardError
                passenger.fields[fieldName].isValid = isValid
            } else if (
                !checkDiscountCardError &&
                JollicodeDiscountCardStore.isCheckDiscountCardError(passenger.fields[fieldName].errorText)
            ) {
                // Field is valid, if the previous error was a checkDiscount api error, but the current response doesn't have it anymore.
                isValid = true
                passenger.fields[fieldName].errorText = null
                passenger.fields[fieldName].isValid = isValid
            }
        })

        passenger.isValid = isValid
    },

    _addPassengerDateOfBirthNotice (passenger) {
        let field = passenger.fields.dateOfBirth
        const dateOfBirth = field.value

        // Reset dateOfBirth notice if passenger is valid.
        if (passenger.isValid) {
            field.notice = null
        }

        // dateOfBirth is invalid, add a notice instead.
        if (dateOfBirth !== '') {
            const passengerAge = this.getPassengerAge(passenger.id)
            if (field.errorText === 'invalid-age-baby') {
                field.notice = 'not-eligible-for-half-price'
            } else if ((passenger.type === PASSENGER_TYPE_ADULT || passenger.type === PASSENGER_TYPE_CHILD) && passengerAge < 2) {
                field.notice = 'eligible-for-half-price'
            } else if (field.errorText === 'invalid-age-kid') {
                field.notice = 'invalid-age-kid'
            } else if (field.errorText === 'invalid-age-youth') {
                field.notice = 'invalid-age-youth'
            }
        }
    },

    getPassengerAge (id) {
        const passenger = this.data.passengers.find(passenger => passenger.id === id)
        if (!passenger) {
            throw Error('Invalid argument, passenger not found')
        }
        const dateOfBirth = this._getMoment(passenger.fields.dateOfBirth.value)

        return this._getPassengerAge(dateOfBirth)
    },

    _getPassengerAge (dateOfBirth) {
        const outboundTravelDate = moment(this.data.booking.outboundTravelDate).startOf('day')
        return outboundTravelDate.diff(dateOfBirth, 'years')
    },

    _getMoment (value) {
        return moment(value, ['D/M/YYYY'], true)
    },

    _validateDate (value, field) {
        const moment = this._getMoment(value)
        const passengerAge = this._getPassengerAge(moment)
        const passenger = this.getPassenger(this.getPassengerIdFromFieldId(field.id))

        if (!passenger) {
            return null
        }

        if (value !== '' && !moment.isValid()) {
            return 'invalid-date'
        } else if (moment.isBefore('1900-01-01') || moment.isAfter()) {
            return 'invalid-date'
        } else if (passenger.type === PASSENGER_TYPE_BABY && passengerAge > 1) {
            return 'invalid-age-baby'
        } else if (passenger.type === PASSENGER_TYPE_CHILD && passengerAge >= 12) {
            return 'invalid-age-kid'
        } else if ((passenger.type === PASSENGER_TYPE_YOUTH && passengerAge < 12) || (passenger.type === PASSENGER_TYPE_YOUTH && passengerAge >= 26)) {
            return 'invalid-age-youth'
        } else if (passenger.fields.requireDateOfBirth !== undefined &&
            passenger.fields.requireDateOfBirth.value &&
            passengerAge > 17
        ) {
            return 'invalid-age-underage'
        }

        return null
    },

    getPassengersForApiCall () {
        return this.data.passengers.reduce((passengers, passenger) => {
            if (!passenger.isCancelled) {
                passengers.push(omitBy({
                    id: passenger.id,
                    first_name: passenger.fields.firstName.value,
                    last_name: passenger.fields.lastName.value,
                    gender: passenger.fields.gender && passenger.fields.gender.value,
                    birth_date: passenger.fields.dateOfBirth.value
                        ? this._getMoment(passenger.fields.dateOfBirth.value).format('YYYY-MM-DD') : null
                }, isBlank)
                )
            }
            return passengers
        }, [])
    },

    _hasAdditionalDetail (key, passengerId) {
        const booking = BookingModel.create(this.data.booking)
        return booking.additionalDetails.some(detail => detail.key === key && detail.passenger_ref === passengerId)
    },

    getAdditionalDetails () {
        const add = []
        const update = []

        const youthWithDiscountPassengers = this.data.passengers.filter(({isYouthWithDiscount}) => isYouthWithDiscount)
        if (youthWithDiscountPassengers) {
            youthWithDiscountPassengers.forEach(passenger => {
                const discountCardNumber = this._hasAdditionalDetail(ADDITIONAL_DETAIL_DISCOUNT_CARD, passenger.id)
                const fieldValue = passenger.fields.discountCardNumber.value
                if (!passenger.isCancelled && (fieldValue || discountCardNumber)) {
                    const detail = {
                        key: ADDITIONAL_DETAIL_DISCOUNT_CARD,
                        value: fieldValue,
                        passenger_ref_id: passenger.refId
                    }
                    discountCardNumber ? update.push(detail) : add.push(detail)
                }
            })
        }

        return {add, update}
    },

    validatePassengers () {
        this._validateData()
    },

    // Is the passenger valid according to our validators (excluding 3rd party checkDiscountCard).
    // Only then we're allowed to do a third party check
    isPassengerInternallyValid (passenger) {
        return passenger && Object.entries(passenger.fields).every(([fieldName, field]) => !this._validateField(fieldName, field))
    },

    isPassengerByIdInternallyValid (passengerId) {
        const _passenger = this.getPassenger(passengerId)
        return _passenger && this.isPassengerInternallyValid(_passenger)
    }
}
