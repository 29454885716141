import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Button from './button'
import TextLabel from './text-label'

export default class ModalDialog extends Component {
    static propTypes = {
        headerTitle: PropTypes.string.isRequired,
        headerIcon: PropTypes.element,
        name: PropTypes.string,
        onClose: PropTypes.func
    }

    render () {
        const className = 'modal' + (this.props.name ? ` modal--${this.props.name}` : '')

        return (
            <div className={className}>
                <div className='modal-panel'>
                    <header>
                        <span className='text-label heading'>
                            {this.props.headerIcon}
                            <TextLabel text={this.props.headerTitle} />
                        </span>
                        {this.props.onClose ? (
                            <Button
                                ref='closeModalButton'
                                type='button'
                                className='button modal-close clear'
                                text='Close'
                                icon={{type: 'cross', className: 'large'}}
                                onClick={this.props.onClose}
                            />
                        ) : null}
                    </header>
                    {this.props.children}
                </div>
                <div className='modal-bg' />
            </div>
        )
    }
}
