import React from 'react'
import PropTypes from 'prop-types'
import Reflux from 'reflux'
import device from '../../device'
import {withRouter} from 'react-router'
import _t from '../../translate'
import ConfirmDialog from '../../element/confirm-dialog'
import BookingLayoutContainer from '../booking-layout-container'
import PaymentContainer from '../../components/booking-payment/payment-container'
import ProgressNavigation from '../../components/booking-payment/progress-navigation'
import BookingStore from '../../reflux/stores/booking-store'
import BookingModel from '../../models/booking-model'
import actions from '../../reflux/actions'
import HelpButton from '../../base/help-button'
import {STORAGE_TARGET} from '../../constants'
import {addFavoriteStations} from '../../misc/favorite-stations-helper'
import CmsBlockContent from '../../components/cms/cms-block-content'
import Storage from '../../storage'
import createReactClass from 'create-react-class'
import {restoreBooking} from '../../misc/booking'

const component = createReactClass({

    displayName: 'BookingPaymentLayout',

    propTypes: {
        router: PropTypes.object
    },

    mixins: [
        Reflux.connectFilter(
            BookingStore,
            'booking',
            data => BookingModel.create(data.booking)
        )
    ],

    getInitialState () {
        return {
            showConfirmDialog: false,
            removePayments: false,
            removedVoucherCode: null,
            isDeeplink: false
        }
    },

    componentWillMount () {
        if (!this._hasBooking()) {
            restoreBooking()
        }
    },

    _hasBooking () {
        return this.state.booking !== null && this.state.booking.bookingNumber !== null
    },

    _removePendingPayments () {
        const pendingVouchers = this.state.booking.pendingVouchers
        if (pendingVouchers.length) {
            const code = pendingVouchers[0].code
            if (code !== this.state.removedVoucherCode) {
                this.setState({
                    removedVoucherCode: code
                }, () => actions.removeVoucher({
                    booking_number: this.state.booking.bookingNumber,
                    voucher_code: code
                }))
            }
        }
    },

    componentDidMount () {
        actions.setPaymentReturnUrl(`/${_t.getLocales()}/booking/confirmation`)
        const target = Storage.get(STORAGE_TARGET)
        target && target === 'payment' && this.setState({isDeeplink: true})
    },

    componentWillUnmount () {
        Storage.clear(STORAGE_TARGET)
    },

    componentDidUpdate () {
        if (this.state.removePayments) {
            const pendingPayments = this.state.booking.pendingPayments
            if (pendingPayments.length === 0) {
                this.setState({removePayments: false}, this.props.router.goBack)
            } else {
                this._removePendingPayments()
            }
        }
    },

    onConfirmed () {
        addFavoriteStations()
        this.props.router.push(
            `/${_t.getLocales()}/booking/confirmation${this.state.booking.requiresPayment ? '' : '?s3_status=success'}`
        )
    },

    onPrev () {
        this.props.router.goBack()
    },

    routerWillLeave (nextLocation) {
        if (nextLocation.pathname === `/${_t.getLocales()}/booking/customer-details`) {
            const pendingPayments = this.state.booking.pendingPayments || []
            if (pendingPayments.length > 0) {
                this.setState({
                    showConfirmDialog: true
                })

                return false
            }
        }
    },

    render () {
        const nextButtonProps = {
            id: 'booking-payment-next',
            text: _t.getIntlMessage('progress-navigation.button.pay')
        }

        return (
            <BookingLayoutContainer
                name='booking-payment'
                hideBookingNumber
                title='booking-payment'
            >
                <PaymentContainer />
                <ProgressNavigation
                    onPrev={this.onPrev}
                    onConfirmed={this.onConfirmed}
                    nextButtonProps={nextButtonProps}
                    previousButtonEnabled={!this.state.isDeeplink}
                />
                {device.isMobile() ? <HelpButton /> : null}
                {this._renderConfirmDialog()}
                <div className='price-payment'>
                    <CmsBlockContent name='price-payment-message' />
                </div>
            </BookingLayoutContainer>
        )
    },

    _renderConfirmDialog () {
        const labelPrefix = 'payment.confirm-remove-vouchers'

        return this.state.showConfirmDialog ? (
            <ConfirmDialog
                onClose={this.closeDialog}
                onCancelButtonClick={this.closeDialog}
                onConfirmButtonClick={this.confirmDialog}
                labelPrefix={labelPrefix}
            />
        ) : null
    },

    closeDialog () {
        this.setState({showConfirmDialog: false})
    },

    confirmDialog () {
        this.setState({
            showConfirmDialog: false,
            removePayments: true
        })
    }

})

export default withRouter(component, {withBlockingLoader: true})
