import PropTypes from 'prop-types'
import React, {Component} from 'react'
import _t from '../translate'
import camelCase from 'lodash/camelCase'
import GenericLayout from './generic-layout'
import Row from '../element/row'

export default class MyS3BookingOverviewLayoutContainer extends Component {
    static propTypes = {
        loading: PropTypes.bool.isRequired
    }

    render () {
        return (
            <GenericLayout className='booking-overview'>
                <Row className='row contains-my-s3-booking-overview'>
                    <div className='my-s3-booking-details'>
                        <div className='panel header-outside'>
                            <header>
                                <h2>
                                    {_t.message('my-s3-booking-overview.header')}
                                    <small>{_t.message('my-s3-booking-overview.sub-header')}</small>
                                </h2>
                            </header>
                            <div className='content'>
                                <div className='my-s3-booking-overview'>
                                    {this.props.children}
                                </div>
                            </div>
                        </div>
                    </div>
                </Row>
            </GenericLayout>
        )
    }

    _getSearchCategories () {
        return [this._getOption('booking-number')]
    }

    _getOption (name) {
        return {value: camelCase(name), text: _t.message('my-s3-booking-overview.filters.category.' + name)}
    }
}
