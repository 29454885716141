import React, {Component} from 'react'
import PropTypes from 'prop-types'
import times from 'lodash/times'
import _t from '../../translate'

import TextLabel from '../../element/text-label'
import Select from '../../element/select'
import Icon from '../../element/icon'
import Tooltip from '../../element/tooltip'
import CmsBlockContent from '../cms/cms-block-content'

import SalesChannelManager from '../../data/sales-channel-manager'
import {
    DISCOUNT_CARD_YOUTH,
    PASSENGER_TYPE_ADULT,
    PASSENGER_TYPE_BABY,
    PASSENGER_TYPE_CHILD,
    PASSENGER_TYPE_YOUTH,
    PASSENGER_TYPE_YOUTH_WITH_DISCOUNT
} from '../../constants'
import IconPassengerType from '../../element/icon-passenger-type'

/**
 * Builds an array with n options. Starts at 0, so we add 1 to n.
 * @returns {Array}
 */
const getOptions = n => times(n + 1, i => ({
    text: i.toString(),
    value: i,
    disabled: false
}))

/**
 * Adds a '+' to the text of the last option.
 * @returns {Array}
 */

export default class PassengerSpecifications extends Component {
    static propTypes = {
        passengers: PropTypes.array.isRequired,
        onChange: PropTypes.func
    }

    constructor (...args) {
        super(...args)
        this.state = {
            showTooltipBaby: false,
            showTooltipChildren: false,
            showTooltipAdult: false,
            showTooltipYouth: false,
            showTooltipWithDiscount: false
        }
        this.getNumberOfAdults = this.getNumberOfAdults.bind(this)
        this.getNumberOfChildren = this.getNumberOfChildren.bind(this)

        this.getNumberOfYouth = this.getNumberOfYouth.bind(this)

        this.getNumberOfYouthWithDiscount = this.getNumberOfYouthWithDiscount.bind(this)
        this.onChangeNumberOfAdults = this.onChangeNumberOfAdults.bind(this)
        this.onChangeNumberOfChildren = this.onChangeNumberOfChildren.bind(this)
        this.onChangeNumberOfYouthWithDiscount = this.onChangeNumberOfYouthWithDiscount.bind(this)
        this.onChangeNumberOfBabies = this.onChangeNumberOfBabies.bind(this)
        this.getPassengers = this.getPassengers.bind(this)
    }

    render () {
        const maxAdults = SalesChannelManager.bookingMaxAdults || 50

        const bookingMaxAdults = maxAdults - this.getNumberOfYouth() - this.getNumberOfYouthWithDiscount()
        const adultSelectOptions = getOptions(bookingMaxAdults)

        const bookingMaxYouthWithDiscount = maxAdults - this.getNumberOfAdults() - this.getNumberOfYouth()
        const youthWithDiscountOptions = getOptions(bookingMaxYouthWithDiscount)

        const babyOptions = getOptions((SalesChannelManager.bookingMaxChildren || 13) - this.getNumberOfChildren())
        const childOptions = getOptions((SalesChannelManager.bookingMaxChildren || 13) - this.getNumberOfBabies())

        return (
            <div className='passenger-specifications'>
                <div className='grid-row'>
                    <div className='grid-column--3-5 grid-column--medium-4-7 grid-column--large-3-5'>
                        <label className='text-label passenger-type inline-label'>
                            <TextLabel text={_t.getIntlMessage('journey-search.passenger-type.adult.label')} />
                            <span
                                id='icon-adult-information'
                                onClick={() => this.setState({showTooltipAdult: !this.state.showTooltipAdult})}
                                className='tether-target'
                            >
                                <Icon className='small align-right' type='information' />
                            </span>
                            <Tooltip
                                target='icon-adult-information'
                                visible={this.state.showTooltipAdult}
                                position='right'
                                onClose={() => this.setState({showTooltipAdult: false})}
                                title={_t.getIntlMessage('journey-search.passenger-type.adult.label')}
                            >
                                <CmsBlockContent name='adult-information' />
                            </Tooltip>
                        </label>
                    </div>
                    <div className='grid-column--1-5 grid-column--medium-1-7 grid-column--large-1-5'>
                        <IconPassengerType
                            className='adult icon passenger-type medium'
                            passengerType={PASSENGER_TYPE_ADULT}
                        />
                    </div>
                    <div className='grid-column--1-5 grid-column--medium-2-7 grid-column--large-1-5'>
                        <Select
                            data={adultSelectOptions}
                            value={this.getNumberOfAdults()}
                            id='temp-passenger-type-adult'
                            name='temp-passenger-type-adult'
                            className='temp-passenger-type-adult'
                            required
                            onChange={this.onChangeNumberOfAdults}
                        />
                    </div>
                </div>
                <div className='grid-row'>
                    <div className='grid-column--3-5 grid-column--medium-4-7 grid-column--large-3-5'>
                        <label className='text-label passenger-type inline-label'>
                            <TextLabel text={_t.getIntlMessage('journey-search.passenger-type.child.label')} />
                            <span
                                id='icon-baby-information'
                                onClick={() => this.setState({showTooltipBaby: !this.state.showTooltipBaby})}
                                className='tether-target'
                            >
                                <Icon className='small align-right' type='information' />
                            </span>
                            <Tooltip
                                target='icon-baby-information'
                                visible={this.state.showTooltipBaby}
                                position='right'
                                onClose={() => this.setState({showTooltipBaby: false})}
                                title={_t.getIntlMessage('journey-search.passenger-type.child.tooltip.title')}
                            >
                                <CmsBlockContent name='baby-information' />
                            </Tooltip>
                        </label>
                    </div>
                    <div className='grid-column--1-5 grid-column--medium-1-7 grid-column--large-1-5'>
                        <IconPassengerType
                            className='baby icon passenger-type medium'
                            passengerType={PASSENGER_TYPE_BABY}
                        />
                    </div>
                    <div className='grid-column--1-5 grid-column--medium-2-7 grid-column--large-1-5'>
                        <Select
                            data={babyOptions}
                            value={this.getNumberOfBabies()}
                            id='temp-passenger-type-children'
                            name='temp-passenger-type-children'
                            className='temp-passenger-type-children'
                            onChange={this.onChangeNumberOfBabies}
                        />
                    </div>
                </div>
                <div className='grid-row'>
                    <div className='grid-column--3-5 grid-column--medium-4-7 grid-column--large-3-5'>
                        <label className='text-label passenger-type inline-label'>
                            <TextLabel text={_t.getIntlMessage('journey-search.passenger-type.kid.label')} />
                            <span
                                id='icon-kid-information'
                                onClick={() => this.setState({showTooltipChildren: !this.state.showTooltipChildren})}
                                className='tether-target'
                            >
                                <Icon className='small align-right' type='information' />
                            </span>
                            <Tooltip
                                target='icon-kid-information'
                                visible={this.state.showTooltipChildren}
                                position='right'
                                onClose={() => this.setState({showTooltipChildren: false})}
                                title={_t.getIntlMessage('journey-search.passenger-type.kid.tooltip.title')}
                            >
                                <CmsBlockContent name='child-information' />
                            </Tooltip>
                        </label>
                    </div>
                    <div className='grid-column--1-5 grid-column--medium-1-7 grid-column--large-1-5'>
                        <IconPassengerType
                            className='child icon passenger-type medium'
                            passengerType={PASSENGER_TYPE_CHILD}
                        />
                    </div>
                    <div className='grid-column--1-5 grid-column--medium-2-7 grid-column--large-1-5'>
                        <Select
                            data={childOptions}
                            value={this.getNumberOfChildren()}
                            id='temp-passenger-type-kids'
                            name='temp-passenger-type-kids'
                            className='temp-passenger-type-kids'
                            onChange={this.onChangeNumberOfChildren}
                        />
                    </div>
                </div>
                {_t.getLocales() === 'fr-FR'
                    ? <div key='youth-discount' className='grid-row'>
                        <div className='grid-column--3-5 grid-column--medium-4-7 grid-column--large-3-5'>
                            <label className='text-label passenger-type inline-label'>
                                <TextLabel
                                    text={_t.getIntlMessage('journey-search.passenger-type.youth-discount.label')} />
                                <span
                                    id='icon-young-discount-information'
                                    onClick={() => this.setState({showTooltipYouthWithDiscount: !this.state.showTooltipYouthWithDiscount})}
                                    className='tether-target'
                                >
                                    <Icon className='small align-right' type='information' />
                                </span>
                                <Tooltip
                                    target='icon-young-discount-information'
                                    visible={this.state.showTooltipYouthWithDiscount}
                                    position='right'
                                    onClose={() => this.setState({showTooltipYouthWithDiscount: false})}
                                    title={_t.getIntlMessage('journey-search.passenger-type.youth.tooltip.title')}
                                >
                                    <CmsBlockContent name='young-information' />
                                </Tooltip>
                            </label>
                        </div>
                        <div className='grid-column--1-5 grid-column--medium-1-7 grid-column--large-1-5'>
                            <IconPassengerType
                                className='youngman icon passenger-type medium'
                                passengerType={PASSENGER_TYPE_YOUTH}
                            />
                        </div>
                        <div className='grid-column--1-5 grid-column--medium-2-7 grid-column--large-1-5'>
                            <Select
                                data={youthWithDiscountOptions}
                                value={this.getNumberOfYouthWithDiscount()}
                                id='temp-passenger-type-youth-discount'
                                name='temp-passenger-type-youth-discount'
                                className='temp-passenger-type-youth-discount'
                                onChange={this.onChangeNumberOfYouthWithDiscount}
                            />
                        </div>
                    </div>
                    : null}
            </div>
        )
    }

    getNumberOfAdults () {
        return this.props.passengers.filter(passenger => passenger.passengerType === PASSENGER_TYPE_ADULT).length
    }

    getNumberOfChildren () {
        return this.props.passengers.filter(passenger => passenger.passengerType === PASSENGER_TYPE_CHILD).length
    }

    getNumberOfYouth () {
        return this.props.passengers.filter(passenger => passenger.passengerType === PASSENGER_TYPE_YOUTH).length
    }

    getNumberOfYouthWithDiscount () {
        return this.props.passengers.filter(passenger => passenger.passengerType === PASSENGER_TYPE_YOUTH_WITH_DISCOUNT).length
    }

    getNumberOfBabies () {
        return this.props.passengers.filter(passenger => passenger.passengerType === PASSENGER_TYPE_BABY).length
    }

    onChangeNumberOfAdults (amount) {
        this.triggerChange(this.getPassengers({[PASSENGER_TYPE_ADULT]: {amount}}))
    }

    onChangeNumberOfChildren (amount) {
        this.triggerChange(this.getPassengers({[PASSENGER_TYPE_CHILD]: {amount}}))
    }

    onChangeNumberOfYouthWithDiscount (amount) {
        this.triggerChange(this.getPassengers({
            [PASSENGER_TYPE_YOUTH_WITH_DISCOUNT]: {
                amount,
                type: PASSENGER_TYPE_YOUTH,
                discount_cards: [{code: DISCOUNT_CARD_YOUTH}]
            }
        }))
    }

    onChangeNumberOfBabies (amount) {
        this.triggerChange(this.getPassengers({[PASSENGER_TYPE_BABY]: {amount}}))
    }

    getPassengers (newValue) {
        const formValues = {
            [PASSENGER_TYPE_ADULT]: {amount: this.getNumberOfAdults()},
            [PASSENGER_TYPE_CHILD]: {amount: this.getNumberOfChildren()},
            [PASSENGER_TYPE_BABY]: {amount: this.getNumberOfBabies()},
            [PASSENGER_TYPE_YOUTH]: {amount: this.getNumberOfYouth()},
            [PASSENGER_TYPE_YOUTH_WITH_DISCOUNT]: {
                amount: this.getNumberOfYouthWithDiscount(),
                type: PASSENGER_TYPE_YOUTH,
                discount_cards: [{code: DISCOUNT_CARD_YOUTH}]
            },
            ...newValue
        }

        return Object.entries(formValues).reduce((_passengers, [type, {amount, ...extra}]) => {
            times(amount, () => {
                _passengers.push({type, ...extra})
            })
            return _passengers
        }, [])
    }

    triggerChange (passengers) {
        if (typeof this.props.onChange === 'function') {
            this.props.onChange(passengers)
        }
    }
}
