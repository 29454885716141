import PropTypes from 'prop-types'
import createReactClass from 'create-react-class'
import Reflux from 'reflux'
import debounce from 'lodash/debounce'
import CmsStore from '../../reflux/stores/cms-store'
import UserStore from '../../reflux/stores/user-store'
import actions from '../../reflux/actions'

const CmsProvider = createReactClass({
    mixins: [
        Reflux.listenTo(CmsStore, 'onCmsStoreUpdate'),
        Reflux.listenTo(UserStore, 'onUserStoreUpdate')
    ],

    propTypes: {
        debounceTime: PropTypes.number.isRequired
    },

    childContextTypes: {
        requireCmsBlocks: PropTypes.func
    },

    onUserStoreUpdate (data) {
        if (data && data.user === null) {
            this.setState(this.getInitialState())
        }
    },

    onCmsStoreUpdate (data) {
        this.setState({
            locale: data.locale,
            cmsBlockNames: data.cmsBlocks.map(block => block.name),
            unfoundBlocks: data.unfoundBlocks,
            loadingBlocks: data.loadingBlocks
        })
    },

    getChildContext () {
        return {
            requireCmsBlocks: this.requireCmsBlocks
        }
    },

    getInitialState () {
        return {
            cmsBlockNames: [],
            cmsBlocks: new Set(),
            unfoundBlocks: new Set(),
            loadingBlocks: []
        }
    },

    componentWillUpdate () {
        this.loadCmsBlocksDebounced()
    },

    componentWillMount () {
        this.loadCmsBlocksDebounced = debounce(this._loadCmsBlocks, parseInt(this.props.debounceTime))
    },

    componentDidMount () {
        this._loadCmsBlocks()
    },

    _loadCmsBlocks () {
        const newCmsBlockNames = [...this.state.cmsBlocks].filter(
            blockName => !this.state.cmsBlockNames.includes(blockName) &&
                !this.state.unfoundBlocks.has(blockName) &&
                !this.state.loadingBlocks.includes(blockName)
        )

        if (newCmsBlockNames.length) {
            actions.loadCmsBlocks(newCmsBlockNames)
        }
    },

    requireCmsBlocks (blockNames) {
        const newBlocks = new Set(blockNames.filter(blockName => !this.state.cmsBlocks.has(blockName)))

        if (newBlocks.size) {
            this.setState(state => ({
                ...state,
                cmsBlocks: new Set([...state.cmsBlocks, ...newBlocks])
            })
            )
        }
    },

    render () {
        return this.props.children
    }
})

export default CmsProvider
