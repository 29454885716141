// Libraries
import React from 'react'
import PropTypes from 'prop-types'
import Reflux from 'reflux'
import {withRouter} from 'react-router'
import _t from '../translate'

import createReactClass from 'create-react-class'

// Components
import BookingTable from '../components/my-s3-booking-overview/booking-table'
import NoResults from '../components/my-s3-booking-overview/no-results'

// Layouts
import MyS3BookingOverviewLayoutContainer from '../layouts/my-s3-booking-overview-layout-container'
import Message from '../element/message'

// Stores
import MyS3BookingOverviewStore from '../reflux/stores/my-s3-booking-overview-store'

// Actions
import actions from '../reflux/actions'

const MyS3BookingOverviewLayout = createReactClass({

    propTypes: {
        router: PropTypes.object
    },

    mixins: [
        Reflux.connect(MyS3BookingOverviewStore, 'data')
    ],

    hasBookings () {
        return this.state.data.bookings.length > 0
    },

    componentDidMount () {
        if (!this.hasBookings()) {
            actions.myS3GetBookings()
        }
    },

    onSelectBooking (booking) {
        this.props.router.push(`/${_t.getLocales()}/mys3/booking/${booking.booking_reference}`)
    },

    render () {
        return (
            <MyS3BookingOverviewLayoutContainer loading={this.state.data.loading}>
                {this.renderBookings()}
            </MyS3BookingOverviewLayoutContainer>
        )
    },

    renderBookings () {
        if (this.state.data.loading) {
            return null
        }

        if (this.state.data.error) {
            return this.state.data.messages.map((message, idx) => {
                return <Message key={idx} message={message} />
            })
        }

        return this.hasBookings() ? (
            <BookingTable
                bookings={this.state.data.bookings}
                onSelectBooking={this.onSelectBooking}
            />
        ) : (
            <NoResults />
        )
    }

})

export default withRouter(MyS3BookingOverviewLayout)
