/* global window */

export default {

    getLanguage () {
        return window && window.Detectizr && window.Detectizr.browser.language
    },

    isDesktop () {
        return window && window.Detectizr && window.Detectizr.device.type === 'desktop'
    },

    isTablet () {
        return window && window.Detectizr && window.Detectizr.device.type === 'tablet'
    },

    isMobile () {
        return window && window.Detectizr && window.Detectizr.device.type === 'mobile'
    },

    isDesktopOrTablet () {
        return this.isDesktop() || this.isTablet()
    },

    getOSType () {
        return window && window.Detectizr && window.Detectizr.os.name
    },

    isIOS () {
        return window && window.Detectizr && window.Detectizr.os.name === 'ios'
    },

    isIE (version) {
        return window && window.Detectizr &&
            window.Detectizr.browser.name === 'ie' &&
            window.Detectizr.browser.major === version
    },

    isAndroid () {
        return window && window.Detectizr && window.Detectizr.os.name === 'android'
    },

    isWindowsMobile () {
        return window && window.Detectizr && window.Detectizr.os.name === 'windows' &&
            window.Detectizr.device.type === 'mobile'
    },

    isLandscape () {
        return window &&
            window.Detectizr &&
            window.Detectizr.device.orientation === 'landscape'
    },

    isWidthGreaterThan (minWidth) {
        const width = (this.isLandscape() && window.screen.availWidth < window.screen.availHeight)
            ? window.screen.availHeight : window.screen.availWidth
        return window && width > minWidth
    },

    isWideLandscapeOrDesktop (minWidth) {
        return (this.isLandscape() && this.isWidthGreaterThan(minWidth)) || this.isDesktop()
    },

    supportsInputMask () {
        return Boolean(
            window &&
            window.Detectizr &&
            !(window.Detectizr.os.name === 'windows' && window.Detectizr.device.type === 'mobile')
        )
    },

    supportsNativeSmartBanner () {
        return window && window.Detectizr &&
            window.Detectizr.os.name === 'ios' && parseInt(window.Detectizr.os.major) >= 6 &&
            window.Detectizr.browser.name === 'safari'
    }
}
