import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Checkbox from '../../../../element/form/checkbox-field'
import _ from 'lodash'
import {prefixFieldLabel} from '../../../../forms/field-props'
import actions from '../../../../reflux/actions'
import {PASSENGER_DETAILS_FORM_PREFIX} from '../../../../constants'

export default class PassengerRequireDateOfBirth extends Component {
    static propTypes = {
        passenger: PropTypes.object.isRequired
    }

    render () {
        const {passenger} = this.props
        if (!passenger.fields.requireDateOfBirth) {
            return null
        }
        return (
            <fieldset>
                <div className='grid-row'>
                    <div className='grid-column--1-1'>
                        <div className='check-radio-wrapper'>
                            <Checkbox {...this.checkboxFieldProps(passenger, 'requireDateOfBirth')} />
                        </div>
                    </div>
                </div>
            </fieldset>
        )
    }

    checkboxFieldProps (passenger, fieldName) {
        let kebabCase = _.kebabCase(fieldName)
        return {
            id: passenger.fields[fieldName].id,
            ref: passenger.fields[fieldName].id,
            labelText: prefixFieldLabel(PASSENGER_DETAILS_FORM_PREFIX, kebabCase, 'label'),
            labelPosition: 'after',
            checked: passenger.fields[fieldName].value === true,
            required: passenger.fields[fieldName].isRequired,
            errorText: prefixFieldLabel(kebabCase, passenger.fields[fieldName].errorText),
            onChange: this.onCheckboxClicked
        }
    }

    onCheckboxClicked (event) {
        actions.processPassengerDetailsData([{
            id: event.target.id,
            value: event.target.checked
        }])
    }
}

