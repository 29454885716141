import BookingStore from '../../../../reflux/stores/booking-store'
import {getJourneySearchFromBooking} from '../../../../misc/journey-search-url-query'
import {PRODUCT_CODE_SEAT_SELECTION} from '../../../../constants'
import {processDeepLink} from '../../../deep-link/deep-link'
import _t from '../../../../translate'
import actions from '../../../../reflux/actions'
import {goToOuibusSearchPage} from '../../../../misc/new-ouibus-helper'

const filterProductsWithoutSeatSelection = products => products.reduce((list, product) => {
    // Don't add seat selection
    if (product.code !== PRODUCT_CODE_SEAT_SELECTION) {
        list.push(product.code)
    }
    return list
}, [])

export const cancelProvisionalBookingAndCreateNew = async (invalidYouthCardsPassengers = []) => {
    const booking = BookingStore.getBookingModel()

    const passengersForJourneySearch = booking.passengers.value().map(passenger => ({
        type: invalidYouthCardsPassengers.includes(passenger.id) ? 'A' : passenger.type,
        discount_cards: invalidYouthCardsPassengers.includes(passenger.id) ? [] : passenger.discount_cards.map(({code}) => ({code}))
    }))

    const {origin, destination, outboundDate, inboundDate} = getJourneySearchFromBooking(booking)

    const inbound = booking.inboundSegmentCollection
    const outbound = booking.outboundSegmentCollection

    const data = {
        origin,
        destination,
        outboundDate,
        outboundRoute: outbound.journeySegments.map(segment => segment.service_name),
        outboundProducts: filterProductsWithoutSeatSelection(outbound.products),
        inboundDate,
        inboundRoute: inbound && inbound.journeySegments.map(segment => segment.service_name),
        inboundProducts: inbound && filterProductsWithoutSeatSelection(inbound.products),
        passengers: passengersForJourneySearch,
        salesChannel: booking.salesChannelCode,
        currency: booking.currency,
        createProvisionalBooking: true,
        isForOfferPage: false
    }

    // Cancel 'old' booking to make products available again.
    await actions.cancelBooking(booking.bookingNumber)

    processDeepLink(data).then(() => {
        // reload passenger details page with new booking info.
        window.reacthistory.push(`/${_t.getLocales()}/booking/passenger-details`)
    }).catch(() => {
        // Can't create provisional booking, redirect to search page.
        goToOuibusSearchPage()
    })

    return data
}
