import React, {Component} from 'react'
import PropTypes from 'prop-types'
import Option from '../element/option'
import _ from 'lodash'

export default class Select extends Component {
    static propTypes = {
        data: PropTypes.array,
        onChange: PropTypes.func,
        onBlur: PropTypes.func,
        groupBy: PropTypes.string,
        multiple: PropTypes.bool,
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.array
        ])
    }

    static defaultProps = {
        data: [],
        groupBy: null
    }

    constructor (...args) {
        super(...args)
        this.onBlur = this.onBlur.bind(this)
        this.onChange = this.onChange.bind(this)
    }

    render () {
        let {value, data, ...other} = this.props
        delete other.onChange
        delete other.onBlur
        delete other.groupBy

        if (!this.props.multiple && !this._valueExists(value, data) && data.length > 0) {
            value = data[0].value
        }

        const createOption = (item, i) => <Option key={i} {...item} />

        let children
        if (this.props.groupBy === null) {
            children = data.map(createOption)
        } else {
            children = _.map(_.groupBy(data, item => _.get(item, this.props.groupBy, '')), (items, groupBy) => {
                const options = items.map(createOption)

                return groupBy !== '' ? <optgroup key={groupBy} label={groupBy}>{options}</optgroup> : options
            })
        }

        return <select onChange={this.onChange} onBlur={this.onBlur} value={value} {...other}>{children}</select>
    }

    _valueExists (value, data) {
        return data.filter(item => value === item.value).length > 0
    }

    onBlur (event) {
        this.onChange(event)
        if (typeof this.props.onBlur === 'function') {
            this.props.onBlur()
        }
    }

    onChange (event) {
        if (typeof this.props.onChange === 'function') {
            this.props.onChange(event.target.value, event)
        }
    }
}
