import OfferStore from '../stores/offer-store'
import isEmpty from 'lodash/isEmpty'

export const journeySearchOfferSelector = () => {
    const offerData = OfferStore.getOfferData()
    return isEmpty(offerData) ? null : offerData
}

export const journeySearchSelector = () => {
    const journeySearchData = OfferStore.getJourneySearchData()
    return isEmpty(journeySearchData) ? null : journeySearchData
}
