import Reflux from 'reflux'
import actions from '../../reflux/actions'
import UserAwareMixin from '../../reflux/mixin/user-aware-mixin'
import CrmCustomerModel, {EXTERNAL_REFERENCE_DISCOUNT_CARD_CODE_DISCOUNT_CARD_NUMBER} from '../../models/crm-customer-model'
import {isBlank, getPassengerDiscountCardCode} from '../../misc/helpers'
import omitBy from 'lodash/omitBy'
import pick from 'lodash/pick'
import get from 'lodash/get'
import moment from 'moment-timezone'
import isEqual from 'lodash/isEqual'

export default Reflux.createStore({

    listenables: actions,

    mixins: [UserAwareMixin],

    init () {
        this._resetData()

        this.listenTo(actions.updateCrmUserInformation, this._onRequest)
        this.listenTo(actions.updateCrmUserInformation.completed, this._onCompleted)
        this.listenTo(actions.updateCrmUserInformation.failed, this._onFailed)

        this.listenTo(actions.loadCrmUserInformation, this._onRequest)
        this.listenTo(actions.loadCrmUserInformation.completed, this._onCompleted)
        this.listenTo(actions.loadCrmUserInformation.failed, this._onFailed)
    },

    _resetData () {
        this.data = {
            loading: false,
            success: true,
            error: '',
            user: null
        }
    },

    resetData () {
        this._resetData()
        this.trigger(this.data)
    },

    getInitialState () {
        return this.data
    },

    getUser () {
        return this.data.user
    },

    getCrmCustomer () {
        return this.getUser() && this.getUser().customer
    },

    getCrmCustomerModel () {
        return this.getCrmCustomer() && CrmCustomerModel.create(this.getCrmCustomer())
    },

    hasUserInformation () {
        return this.data.user !== null
    },

    hasCustomerInformation () {
        const customer = this.getCrmCustomer()
        return Boolean(customer && customer.first_name && customer.last_name && customer.birth_date)
    },

    _onCompleted (response) {
        this.data.loading = false
        this.data.user = response.data
        this.trigger(this.data)
    },

    _onRequest () {
        this.data.loading = true
        this.data.success = true
        this.data.error = ''
        this.data.user = null
        this.trigger(this.data)
    },

    _onFailed (error) {
        this.data.loading = false
        this.data.success = false
        this.data.error = error
        this.trigger(this.data)
    },

    formatUpdateCrmUserForApiCall (passenger) {
        const customer = this.getCrmCustomerModel()
        const passengerFields = passenger && passenger.fields

        if (!passengerFields || !customer) {
            return
        }

        // If data exists on crmUser, don't update it.
        const formData = {
            first_name: customer.firstName || get(passengerFields, 'firstName.value'),
            last_name: customer.lastName || get(passengerFields, 'lastName.value'),
            birth_date: customer.dateOfBirth || get(passengerFields, 'dateOfBirth.value'),
            gender: customer.gender || get(passengerFields, 'gender.value')
        }

        if (formData.birth_date) {
            formData.birth_date =
                moment(formData.birth_date, ['YYYY-MM-DD', 'DD/MM/YYYY'])
                    .format('YYYY-MM-DD')
        }

        // Always update discount card number
        const passengerDiscountCardNumber = get(passengerFields, 'discountCardNumber.value')

        if (passengerDiscountCardNumber) {
            formData.meta_data = customer.buildMetaData(
              EXTERNAL_REFERENCE_DISCOUNT_CARD_CODE_DISCOUNT_CARD_NUMBER,
              `${getPassengerDiscountCardCode(passenger)}|${passengerDiscountCardNumber}`
            )
        }

        const data = omitBy({
            ...customer.rawData,
            ...formData
        }, isBlank)

        const hasChanges = !isEqual(formData, pick(customer.rawData, Object.keys(formData)))
        return hasChanges ? data : null
    }
})
