// Libraries
import React from 'react'
import createReactClass from 'create-react-class'

// Components
import FooterCommon from './footer-common'

import cmsComponent from '../../components/cms/cms-component'
import CmsBlockContent from '../../components/cms/cms-block-content'

export default cmsComponent('footer-sncb')(createReactClass({

    displayName: 'LayoutFooterSNCB',

    render () {
        return (
            <FooterCommon {...this.props}>
                <CmsBlockContent name='footer-sncb' />
            </FooterCommon>
        )
    }

}))
