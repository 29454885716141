import PropTypes from 'prop-types'
import React, { Component } from 'react'
import BookingLayoutContainer from '../booking-layout-container'
import SeatSelectorContainer from '../../components/seat-selector/seat-selector-container'
import {restoreBookingCompose} from '../../components/restore/restore-booking'
import {restoreJourneySearchCompose} from '../../components/restore/restore-journey-search'
import compose from '../../misc/compose'
import InteractiveFlashMessage from '../../components/interactive-flash-message'

class SeatSelector extends Component {
    static propTypes = {
        location: PropTypes.object
    }

    render () {
        return (
            <BookingLayoutContainer name='seat-selection-container'>
                <InteractiveFlashMessage />
                <SeatSelectorContainer
                    returnUrl='/booking/options'
                    selectedLegPassenger={this.props.location.query ? this.props.location.query : null}
                />
            </BookingLayoutContainer>
        )
    }
}

const container = compose(
    restoreJourneySearchCompose({withBlockingLoader: true}),
    restoreBookingCompose({withBlockingLoader: true})
)

export default container(SeatSelector)
