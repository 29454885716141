import {useRouterHistory} from 'react-router'
import createHashHistory from 'history/lib/createHashHistory'
import createBrowserHistory from 'history/lib/createBrowserHistory'
import browser from 'detect-browser'
import qs from 'qs'
import useScroll from 'scroll-behavior/lib/useStandardScroll'

export default () => {
    const createAppHistory = useScroll(useRouterHistory(
        browser && browser.name === 'ie' && browser.version === '9.0.0' ? createHashHistory : createBrowserHistory
    ))

    const history = createAppHistory({
        parseQueryString: query => qs.parse(query, {arrayLimit: 50}),
        stringifyQuery: query => qs.stringify(query, {encode: false, skipNulls: true})
    })

    window.reacthistory = history

    return history
}
