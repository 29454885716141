import React, {Component} from 'react'
import PropTypes from 'prop-types'
import _t from '../../translate'

import Icon from '../../element/icon'
import TextLabel from '../../element/text-label'
import Accordion from '../../element/accordion'
import AccordionPanel from '../../element/accordion/panel'
import AccordionPanelHeader from '../../element/accordion/panel/header'
import AccordionPanelContent from '../../element/accordion/panel/content'
import BookingSpecificationPanel from './panel'
import ManageVouchers from './panel/manage-vouchers'
import BookingModel from '../../models/booking-model'
import TotalPrice from './panel/total-price'

export default class PanelMobile extends Component {
    static propTypes = {
        afterSales: PropTypes.bool,
        renderManageVouchers: PropTypes.bool,
        insuranceSelected: PropTypes.bool,
        booking: PropTypes.instanceOf(BookingModel).isRequired
    }

    static defaultProps = {

        afterSales: false
    }

    render () {
        const booking = this.props.booking

        let outboundSegments = booking.outboundSegmentCollection.withRequiredProducts()
        let inboundSegments = booking.inboundSegmentCollection.withRequiredProducts()

        return (
            <BookingSpecificationPanel afterSales={this.props.afterSales} collapsable>
                <div className='panel'>
                    <Accordion>
                        <AccordionPanel name='booking-specification-panel'>
                            <AccordionPanelHeader showCollapsed>
                                <header>
                                    <h1 className='text-label expand-details'>
                                        <TextLabel text={_t.getIntlMessage('booking-specification.journey-title')} />
                                        <Icon className='medium align-right' type='chevron-down' />
                                    </h1>
                                </header>
                            </AccordionPanelHeader>
                            <AccordionPanelHeader showExpanded>
                                <header>
                                    <h1 className='text-label expand-details'>
                                        <TextLabel text={_t.getIntlMessage('booking-specification.journey-title')} />
                                        <Icon className='medium align-right' type='chevron-up' />
                                    </h1>
                                </header>
                            </AccordionPanelHeader>
                            <AccordionPanelContent>
                                {this.props.children}
                            </AccordionPanelContent>
                        </AccordionPanel>
                    </Accordion>
                </div>
                <div className='panel'>
                    <TotalPrice
                        totalPrice={booking.totalPrice}
                        passengers={booking.passengers.values().value()}
                        inboundSegments={inboundSegments}
                        outboundSegments={outboundSegments}
                        insuranceProduct={booking.insuranceProduct}
                        fees={this.props.afterSales ? booking.applicableFeePrice : booking.allFeesPrice}
                        insuranceSelected={this.props.insuranceSelected}
                    />
                    {this.props.renderManageVouchers ? <ManageVouchers /> : null}
                </div>
            </BookingSpecificationPanel>
        )
    }
}
