import ResponseCodes from '../api/response-codes'
import AccessManager from '../data/access-manager'
import includes from 'lodash/includes'
import _t from '../translate'

export const voucherResponseCodes = [
    ResponseCodes.BOOKING_VOUCHER_NOT_FOUND,
    ResponseCodes.BOOKING_VOUCHER_USED_BY_CUSTOMER,
    ResponseCodes.BOOKING_VOUCHER_NOT_ALLOWED_BY_CUSTOMER,
    ResponseCodes.BOOKING_VOUCHER_RESTRICTED,
    ResponseCodes.BOOKING_VOUCHER_AMOUNT_NOT_SUFFICIENT,
    ResponseCodes.BOOKING_VOUCHER_NOT_AVAILABLE_FOR_TRAVEL_DAY,
    ResponseCodes.BOOKING_VOUCHER_DOES_NOT_EXISTS,
    ResponseCodes.BOOKING_VOUCHER_FULL_AMOUNT_USED,
    ResponseCodes.BOOKING_VOUCHER_NOT_AVAILABLE_FOR_BOOKING,
    ResponseCodes.BOOKING_VOUCHER_NOT_AVAILABLE_IN_BOOKING_CURRENCY,
    ResponseCodes.BOOKING_VOUCHER_CUSTOMER_DETAILS_REQUIRED,
    ResponseCodes.BOOKING_VOUCHER_REFUND_NO_ELIGIBLE_TARIFF,
    ResponseCodes.BOOKING_VOUCHER_COMPENSATION_NO_ELIGIBLE_TARIFF,
    ResponseCodes.BOOKING_VOUCHER_PREPAID_NO_ELIGIBLE_TARIFF,
    ResponseCodes.BOOKING_VOUCHER_EMAIL_VALIDATION_FAILED,
    ResponseCodes.BOOKING_VOUCHER_NOT_FOR_SALE_TODAY,
    ResponseCodes.BOOKING_VOUCHER_NOT_AVAILABLE_FOR_TRAVEL_ON_DATE,
    ResponseCodes.BOOKING_VOUCHER_NOT_AVAILABLE_FOR_ANY_SERVICE,
    ResponseCodes.BOOKING_VOUCHER_NOT_AVAILABLE_FOR_TOTAL,
    ResponseCodes.BOOKING_VOUCHER_NOT_AVAILABLE_FOR_SALESCHANNEL,
    ResponseCodes.BOOKING_VOUCHER_NOT_AVAILABLE_FOR_PRODUCT,
    ResponseCodes.BOOKING_VOUCHER_ALREADY_USED,
    ResponseCodes.BOOKING_VOUCHER_PREPAID_NOT_VALID_FOR_TRAVEL,
    ResponseCodes.BOOKING_VOUCHER_PREPAID_NOT_VALID_FOR_SALE,
    ResponseCodes.VOUCHER_ALREADY_USED,
    ResponseCodes.VOUCHER_NOT_FOUND
]

export const getMessageVoucherEmailRestriction = (pathname, message) => {
    if (message.code !== ResponseCodes.BOOKING_VOUCHER_EMAIL_VALIDATION_FAILED) {
        return message
    }

    const onPrepaymentPage = (
        includes(pathname, '/booking/passenger-details') ||
        includes(pathname, '/booking/options') ||
        includes(pathname, '/booking/customer-details')
    )

    let label
    if (onPrepaymentPage) {
        label = 'prepayment-page'
    } else if (AccessManager.isCrmUser() && includes(pathname, '/booking/payment')) {
        label = 'crm-booking-payment-page'
    } else if (includes(pathname, '/booking/payment')) {
        label = 'booking-payment-page'
    }

    if (label) {
        return {
            ...message,
            content: _t.getIntlMessage(`voucher.error-email-validation.${label}`)
        }
    }

    return message
}
