import Reflux from 'reflux'
import Storage from '../../storage'
import actions from '../actions'

const STORAGE_KEY = 'affiliate_code'

export default Reflux.createStore({

    listenables: actions,

    getInitialState () {
        return this._getData()
    },

    onChangeAffiliateCode (code) {
        Storage.set(STORAGE_KEY, code)

        this.trigger(this._getData())
    },

    getAffiliateCode () {
        return Storage.get(STORAGE_KEY, null)
    },

    _getData () {
        return {affiliate_code: Storage.get(STORAGE_KEY)}
    }

})
