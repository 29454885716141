import React, {Component} from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import _t from '../../../../translate'
import device from '../../../../device'

// elements
import TextLabel from '../../../../element/text-label'
import Icon from '../../../../element/icon'

export default class JourneyInfo extends Component {
    static propTypes = {
        travelTime: PropTypes.any.isRequired,
        isBestTime: PropTypes.bool
    }

    static defaultProps = {
        isBestTime: false
    }

    render () {
        return device.isDesktopOrTablet() ? this.renderDesktop() : this.renderMobile()
    }

    renderDesktop () {
        return (
            <div className='journey-result-option-item duration-info'>
                {this.getTravelTimeInfo()}
                {this.getBestTimeInfo()}
            </div>
        )
    }

    renderMobile () {
        return (
            <div className='journey-result-option-item duration-info'>
                {this.getTravelTimeInfo()}
            </div>
        )
    }

    getTravelTimeInfo () {
        const travelTime = this.props.travelTime
        const values = {
            hours: Math.floor(travelTime.asHours()),
            minutes: travelTime.minutes(),
            hoursPadded: _.padStart(Math.floor(travelTime.asHours()), 2, '0'),
            minutesPadded: _.padStart(travelTime.minutes(), 2, '0')
        }
        const props = {
            className: 'text-label travel-time',
            title: _t.message('journey-result-set.travel-time-info.title', values)
        }

        return (
            <div>
                <span className='text-label duration'>
                    <TextLabel text={_t.message('journey-result-set.duration')} />
                </span>
                <span {...props}>
                    <Icon className='small align-left' type='clock' />
                    <TextLabel text={_t.message('journey-result-set.travel-time-info.text', values)} />
                </span>
            </div>
        )
    }

    getBestTimeInfo () {
        return !this.props.isBestTime
            ? null
            : (
                <span className='text-label travel-time-best-time'>
                    <TextLabel text={_t.message('journey-result-set.travel-time-info.best-time')} />
                </span>
            )
    }
}
