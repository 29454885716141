// Libraries
import React, {Component} from 'react'
import PropTypes from 'prop-types'
import _t from '../../translate'

import classNames from 'classnames'

// Components
import Header from '../../base/header'
import Row from '../../element/row'
import TextLabel from '../../element/text-label'

import UserNavigation from '../../base/header/user-navigation'
import {getAffiliateHomepageUrl, getBlablaCarHomepageUrl, isPremiumPartner} from '../../reflux/bridge/affiliate'

export default class LayoutHeader extends Component {
    static propTypes = {
        showLanguageSelector: PropTypes.bool,
        className: PropTypes.string
    }

    static defaultProps = {
        showLanguageSelector: true
    }

    handleClick () {
        if (isPremiumPartner()) {
            window.location.href = getAffiliateHomepageUrl()
        } else {
            window.location.href = getBlablaCarHomepageUrl()
        }
    }

    render () {
        const className = classNames('link', 'header-logo', this.props.className)
        const navRowClassName = classNames('row', 'contains-header')

        return (
            <Header>
                <Row className={navRowClassName}>
                    <div className='grid-row'>
                        <div className='grid-column--1-1'>
                            <div className='language-selector-meta-placeholder'>
                                <UserNavigation showLanguageSelector={this.props.showLanguageSelector} />
                            </div>
                        </div>
                    </div>
                </Row>
                <Row className='row contains-marketing-header'>
                    <div className='grid-row'>
                        <div className='grid-column--1-1'>
                            <a onClick={this.handleClick} className={className}>
                                <span className='text-label'>
                                    <TextLabel text={_t.getIntlMessage('header.logo.label')} />
                                </span>
                            </a>
                            <span className='text-label header-title'>
                                <TextLabel text={_t.getIntlMessage('header.title')} />
                            </span>
                        </div>
                    </div>
                </Row>
            </Header>
        )
    }
}
