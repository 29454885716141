import UserStore from '../reflux/stores/user-store'

class SalesChannelManager {
    constructor () {
        this.properties = {
            allowAftersalesCancel: 'allow_aftersales_cancel',
            allowAftersalesRebook: 'allow_aftersales_rebook',
            allowAftersalesRefund: 'allow_aftersales_refund',
            allowAftersalesOverrideFees: 'allow_aftersales_override_fees',
            bookingGroupStart: 'booking_group_start',
            bookingMaxPersons: 'booking_max_persons',
            bookingMaxAdults: 'booking_max_adults',
            bookingMaxChildren: 'booking_max_children',
            requestPassenger: 'request_passengers.requestPassenger'
        }
    }

    get channelName () {
        return UserStore.getSalesChannel()
    }

    get allowAftersalesCancel () {
        return UserStore.getSalesChannelProperty(this.properties.allowAftersalesCancel)
    }

    get allowAftersalesRebook () {
        return UserStore.getSalesChannelProperty(this.properties.allowAftersalesRebook)
    }

    get allowAftersalesRefund () {
        return UserStore.getSalesChannelProperty(this.properties.allowAftersalesRefund)
    }

    get allowAftersalesOverrideFees () {
        return UserStore.getSalesChannelProperty(this.properties.allowAftersalesOverrideFees)
    }

    get bookingGroupStart () {
        return UserStore.getSalesChannelProperty(this.properties.bookingGroupStart)
    }

    get bookingMaxPersons () {
        return UserStore.getSalesChannelProperty(this.properties.bookingMaxPersons)
    }

    get bookingMaxAdults () {
        return UserStore.getSalesChannelProperty(this.properties.bookingMaxAdults)
    }

    get bookingMaxChildren () {
        return UserStore.getSalesChannelProperty(this.properties.bookingMaxChildren)
    }

    get requestPassenger () {
        return UserStore.getSalesChannelProperty(this.properties.requestPassenger)
    }

    get isCRC () {
        return this.channelName === 'CRC'
    }
}

export default new SalesChannelManager()
