import React from 'react'
import PropTypes from 'prop-types'
import {withRouter} from 'react-router'
import Reflux from 'reflux'
import get from 'lodash/get'
import _t from '../../../translate'
import GenericLayout from '../../generic-layout'
import Row from '../../../element/row'
import AfterSalesConfirmationContainer from '../../../components/after-sales-confirmation-container'
import BookingStore from '../../../reflux/stores/booking-store'
import FlashMessageStore from '../../../reflux/stores/flash-message-store'
import actions from '../../../reflux/actions'
import BookingModel from '../../../models/booking-model'
import restoreBooking from '../../../components/restore/restore-booking'
import createReactClass from 'create-react-class'

export default withRouter(restoreBooking(createReactClass({
    displayName: 'MyS3BikeConfirmLayout',

    mixins: [
        Reflux.connectFilter(BookingStore, 'booking', data => BookingModel.create(data.booking))
    ],

    propTypes: {
        router: PropTypes.object
    },

    onPaymentSuccess () {
        this.props.router.push(`/${_t.getLocales()}/mys3/my-options`)
    },

    onPaymentFailed () {
        actions.showFlashMessage({
            text: _t.getIntlMessage('flash-message.payment-cancelled'),
            severity: FlashMessageStore.severity.highlight
        })
        this.props.router.push(`/${_t.getLocales()}/mys3/bike/payment`)
    },

    onPaymentCancelled () {
        actions.showFlashMessage({
            text: _t.getIntlMessage('flash-message.payment-failed'),
            severity: FlashMessageStore.severity.warning
        })
        this.props.router.push(`/${_t.getLocales()}/mys3/bike/payment`)
    },

    render () {
        const booking = this.state.booking
        return (
            <GenericLayout className='bike-confirmation'>
                <Row className='row contains-after-sales-confirmation'>
                    {booking ? (
                        <AfterSalesConfirmationContainer
                            status={get(this.props, 'location.query.s3_status')}
                            booking={booking}
                            onSuccess={this.onPaymentSuccess}
                            onFailed={this.onPaymentFailed}
                            onCancelled={this.onPaymentCancelled} />
                    ) : null}
                </Row>
            </GenericLayout>
        )
    }
})))
