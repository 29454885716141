import React from 'react'
import Reflux from 'reflux'
import _t from '../../../translate'
import PropTypes from 'prop-types'
import qs from 'qs'
import moment from 'moment-timezone'
import Loader from '../../../element/loader'
import Icon from '../../../element/icon'
import TextLabel from '../../../element/text-label'
import Button from '../../../element/button'
import EmailField from '../../../element/form/email-field'
import CalendarStore from '../../../reflux/stores/calendar-store'
import FormValidationMixin from '../../../reflux/mixin/form-validation-mixin'
import {connectState, getState} from '../../../reflux/bridge/connect-state'
import {getNewJourneySearchSelector} from '../../../models/selectors/components/orientation/journey-search'
import createReactClass from 'create-react-class'

const component = createReactClass({

    displayName: 'JourneyResultSetListNoResults',

    mixins: [
        Reflux.connectFilter(CalendarStore, 'loading', _ => false /* data.loading */),
        FormValidationMixin
    ],

    propTypes: {
        direction: PropTypes.string.isRequired,
        onSearch: PropTypes.func.isRequired,
        getNewJourneySelection: PropTypes.func
    },

    getInitialState () {
        return {
            emailAddressError: null,
            emailAddress: null
        }
    },

    onClick (nextTravelDate) {
        if (nextTravelDate) {
            this.props.onSearch(this.props.getNewJourneySelection(nextTravelDate, this.props.direction))
        } else {
            const queryString = qs.stringify({email: this.state.emailAddress})
            window.location = `${_t.message('journey-result-set.no-routes.no-results.url')}?${queryString}`
        }
    },

    render () {
        if (this.state.loading) {
            return <div className='journey-no-result'><Loader /></div>
        }

        const nextTravelDate = this.props.direction === 'outbound'
            ? CalendarStore.getNextOutboundTravelDate() : CalendarStore.getNextInboundTravelDate()

        return nextTravelDate ? this._renderNextTravel(nextTravelDate) : this._renderNoTravel()
    },

    _renderNextTravel (nextTravelDate) {
        return <div className='journey-no-result'>
            <span className='text-label'>
                <h3>{_t.formatIntlMessage('journey-result-set.no-routes.next-date.header',
                    {date: moment(nextTravelDate).toDate()})}</h3>
                <Button className='button primary' onClick={() => this.onClick(nextTravelDate)}>
                    <TextLabel text={_t.getIntlMessage('journey-result-set.no-routes.next-date.button')} />
                    <Icon className='chevron-right xsmall align-right' type='chevron-right' />
                </Button>
            </span>
        </div>
    },

    _renderNoTravel () {
        return <div className='journey-no-result'>
            <span className='text-label'>
                <h3>{_t.getIntlMessage('journey-result-set.no-routes.no-results.header')}</h3>
                <TextLabel text={_t.getIntlMessage('journey-result-set.no-routes.no-results.message')} />
            </span>
            <div className='grid-row'>
                <div className='grid-column--1-1 grid-column--medium-2-3'>
                    <EmailField
                        ref='email'
                        id='email'
                        errorText={this.state.emailAddressError
                            ? `journey-result-set.no-routes.no-results.${this.state.emailAddressError}` : null}
                        placeholder={'journey-result-set.no-routes.no-results.email-placeholder'}
                        className='email-address'
                        required={false}
                        onBlur={this.onValidateEmail}
                        autoComplete='off'
                    />
                </div>
                <div className='grid-column--1-1 grid-column--medium-1-3'>
                    <Button
                        className='button primary'
                        disabled={this.state.emailAddress === null}
                        onClick={() => this.onClick(false)}
                    >
                        <TextLabel text={_t.getIntlMessage('journey-result-set.no-routes.no-results.button')} />
                        <Icon className='chevron-right xsmall align-right' type='chevron-right' />
                    </Button>
                </div>
            </div>
        </div>
    },

    onValidateEmail (event) {
        const error = this._validateEmail(event.target.value)
        this.setState({
            emailAddressError: error,
            emailAddress: error ? null : event.target.value
        })
    }
})

const mapPropsToProps = () => ({
    getNewJourneySelection: (nextDate, direction) => getNewJourneySearchSelector(nextDate, direction)(getState())
})

export default connectState(mapPropsToProps)(component)
