import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _ from 'lodash'
import _t from '../../translate'
import Tabs from '../../element/tabs'
import TabContent from '../../element/tab-content'
import Seat from './seat'

export default class SeatLayoutContainer extends Component {
    static propTypes = {
        onSelectSeat: PropTypes.func.isRequired,
        onRemoveSeat: PropTypes.func.isRequired,
        seats: PropTypes.array.isRequired,
        selectedSeats: PropTypes.arrayOf(PropTypes.shape({
            passenger_id: PropTypes.string.isRequired,
            seat_number: PropTypes.string.isRequired
        })).isRequired,
        style: PropTypes.object.isRequired,
        readOnly: PropTypes.bool.isRequired,
        selectedPassenger: PropTypes.object
    }

    constructor (props) {
        super(props)
        this.state = {
            selectedTab: this._getSelectedTab(this.props)
        }
        this.onSelectTab = this.onSelectTab.bind(this)
    }

    componentWillReceiveProps (nextProps) {
        if (nextProps.selectedPassenger.id !== this.props.selectedPassenger.id &&
            this.props.selectedSeats.find(seat => seat.passenger_id === nextProps.selectedPassenger.id)) {
            this.onSelectTab(this._getSelectedTab(nextProps))
        }
    }

    _getSelectedTab (props) {
        const selectedSeat = props.selectedSeats.find(seat => seat.passenger_id === props.selectedPassenger.id)

        if (selectedSeat) {
            const seat = props.seats.find(seat => seat.seat_number === selectedSeat.seat_number)
            if (seat && seat.property_codes.includes('UPPERDECK')) {
                return 'upperDeck'
            }
        }

        return 'lowerDeck'
    }

    onSelectTab (code) {
        this.setState({selectedTab: code})
    }

    _hasUpperDeck () {
        return this.props.seats.some(seat => seat.property_codes.includes('UPPERDECK'))
    }

    _getTabs () {
        let tabs = []

        if (this._hasUpperDeck()) {
            tabs.push({
                key: 'lowerDeck',
                label: _t.message('seat-selector.decks.lowerdeck'),
                title: _t.message('seat-selector.decks.lowerdeck')
            })
            tabs.push({
                key: 'upperDeck',
                label: _t.message('seat-selector.decks.upperdeck'),
                title: _t.message('seat-selector.decks.upperdeck')
            })
        } else {
            tabs.push({
                key: 'lowerDeck',
                label: '',
                title: ''
            })
        }

        return tabs
    }

    _getSelectedSeat (seatNumber) {
        return this.props.selectedSeats.find(seat => seat.seat_number === seatNumber)
    }

    _isSeatSelectedBySelectedPassenger (seat) {
        const selectedSeat = this._getSelectedSeat(seat.seat_number)
        return selectedSeat
            ? selectedSeat.passenger_id === this.props.selectedPassenger.id
            : false
    }

    _isSeatSelected (seat) {
        return this._getSelectedSeat(seat.seat_number) !== undefined
    }

    render () {
        let lowerDeckStyle = _.merge({backgroundPositionX: '0'}, this.props.style)
        let upperDeckStyle = null

        if (this._hasUpperDeck()) {
            const width = _.trimEnd(this.props.style.width, 'px')
            lowerDeckStyle.width = `${width}px`
            upperDeckStyle = _.clone(lowerDeckStyle)
            upperDeckStyle.left = `-${width / 2}px`
        }

        const tabs = [{key: 'lowerDeck', style: lowerDeckStyle}, {key: 'upperDeck', style: upperDeckStyle}]

        return (
            <div className='tab-container'>
                {this._getTabs().length > 1
                    ? <Tabs goto={this.onSelectTab} current={this.state.selectedTab} tabs={this._getTabs()} />
                    : null}
                {tabs.map(tab => this.renderTab(tab))}
            </div>
        )
    }

    renderTab (tab) {
        if (_.isEmpty(tab.style)) {
            return null
        }

        return (
            <TabContent key={tab.key} visible={this.state.selectedTab === tab.key}>
                <div className='grid-row'>
                    <div className='grid-column--1-1'>
                        <div className='seat-layout'>
                            <div className='seat-layout-floorplan' style={tab.style}>
                                {this.props.seats.map(seat => this.renderSeat(seat))}
                            </div>
                        </div>
                    </div>
                </div>
            </TabContent>
        )
    }

    renderSeat (seat) {
        if (this.state.selectedTab === ('lowerDeck' && seat.property_codes.includes('UPPERDECK')) ||
            this.state.selectedTab === ('upperDeck' && !seat.property_codes.includes('UPPERDECK'))) {
            return null
        }

        return <Seat
            key={seat.seat_number}
            seat={seat}
            isSelected={this._isSeatSelected(seat)}
            isSelectedBySelectedPassenger={this._isSeatSelectedBySelectedPassenger(seat)}
            onSelectSeat={this.props.onSelectSeat}
            onRemoveSeat={this.props.onRemoveSeat}
            readOnly={this.props.readOnly}
        />
    }
}
