/* globals S3P_SETTINGS: true */
import request from 'superagent'
import moment from 'moment-timezone'
import {ADDITIONAL_DETAIL_DISCOUNT_CARD} from '../constants'
import actions from '../reflux/actions'
import BookingModel from '../models/booking-model'
import has from 'lodash/has'

let pendingRequest = {}

/**
 * Checks if the given discount card is valid.
 * @param {Object} data
 * @param {String} data.outboundDate YYYY-MM-DD
 * @param {String} data.cardNumber
 * @param {String} data.cardHolderFirstName
 * @param {String} data.cardHolderLastName
 * @param {String} data.cardHolderBirthDate YYYY-MM-DD
 * @param {String} passengerId
 * @returns {Promise}
 */
export const checkDiscountCard = (data, passengerId) => {
    if (!pendingRequest[data.cardNumber]) {
        pendingRequest[data.cardNumber] = new Promise((resolve, reject) => {
            request.post(S3P_SETTINGS.jollicode.url + S3P_SETTINGS.jollicode.endPoints.checkDiscountCard)
                .accept('application/json')
                .type('application/json')
                .send(data, passengerId)
                .set('content-type', 'application/json')
                .end((error, response) => {
                    pendingRequest[data.cardNumber] = null
                    const _data = {
                        response: response && response.body,
                        request: data,
                        passengerId: passengerId
                    }
                    if (error) {
                        error.data = _data
                        return reject(error)
                    }

                    return resolve(_data)
                })
        })
    }

    return pendingRequest[data.cardNumber]
}

/**
 * Validate all passenger discount cards found in the booking additional details.
 * Does not raise an exception when one fails, but collects all errors in the JollicodeDiscountCardStore.
 * @param booking
 * @param {String} outboundDate
 * @returns {Promise<any[]>}
 */
export const validateBookingDiscountCards = (booking, outboundDate) => {
    const _booking = BookingModel.create(booking)
    const calls = _booking.passengers.reduce((_calls, passenger) => {
        if (passenger.isYouthWithDiscount) {
            const discountCardNumber = _booking.additionalDetails.find(detail => detail.key === ADDITIONAL_DETAIL_DISCOUNT_CARD && detail.passenger_ref === passenger.id)
            if (discountCardNumber && discountCardNumber.value) {
                _calls.push(
                    actions.checkDiscountCard({
                        outboundDate: moment(outboundDate).format('YYYY-MM-DD'),
                        cardNumber: discountCardNumber.value,
                        cardHolderFirstName: passenger.first_name,
                        cardHolderLastName: passenger.last_name,
                        cardHolderBirthDate: passenger.birth_date
                    }, passenger.id).catch(error => error)
                )
            }
        }
        return _calls
    }, [])

    return Promise.all(calls)
}

const isValid = passengerForm => (
    has(passengerForm, 'fields.discountCardNumber.value') &&
    has(passengerForm, 'fields.firstName.value') &&
    has(passengerForm, 'fields.lastName.value') &&
    has(passengerForm, 'fields.dateOfBirth.value')
)

export const validatePassengerFormDiscountCards = (passengerForms, outboundDate) => {
    const calls = passengerForms.reduce((_calls, passengerForm) => {
        if (isValid(passengerForm) && outboundDate) {
            _calls.push(
                actions.checkDiscountCard({
                    outboundDate: moment(outboundDate).format('YYYY-MM-DD'),
                    cardNumber: passengerForm.fields.discountCardNumber.value,
                    cardHolderFirstName: passengerForm.fields.firstName.value,
                    cardHolderLastName: passengerForm.fields.lastName.value,
                    cardHolderBirthDate: moment(passengerForm.fields.dateOfBirth.value, ['D/M/YYYY'], true).format('YYYY-MM-DD')
                }, passengerForm.id).catch(error => error)
            )
        }
        return _calls
    }, [])

    return Promise.all(calls)
}
