import PropTypes from 'prop-types'
import React, {Component} from 'react'
import Icon from '../../../element/icon'
import Panel from '../../../element/panel'
import {showLuggageAddOn} from '../../../misc/blablabus-helpers'
import {restoreSelectedBundlesFromBooking} from '../../../misc/journey-search'
import {hasLuggagePayingOptionsSelector} from '../../../models/selectors/components/orientation/journey-search'
import {getState} from '../../../reflux/bridge/connect-state'
import BookingStore from '../../../reflux/stores/booking-store'
import _t from '../../../translate'
import LuggageOptions from '../../options/luggage-options'

class MyS3LuggageOptions extends Component {
    static propTypes = {
        open: PropTypes.bool
    }

    static defaultProps = {
        open: true
    }

    constructor (...args) {
        super(...args)
        this.state = {
            panelOpen: this.props.open
        }
        this.togglePanel = this.togglePanel.bind(this)
    }

    togglePanel () {
        this.setState({panelOpen: !this.state.panelOpen})
    }

    componentWillReceiveProps (nextProps) {
        if (nextProps.open !== this.state.panelOpen) {
            this.setState({panelOpen: nextProps.open})
        }
    }

    render () {
        return (
            <div className='my-s3-luggage-selection'>
                <Panel
                    icon={<Icon type='luggage-selector' className='large align-left' />}
                    title={_t.message('my-s3-options.luggage.header')}
                    open={this.state.panelOpen}
                    onToggle={this.togglePanel}
                    name='toggle-booking-preference'
                >
                    <LuggageOptions luggageSelectorRoute='/mys3/luggage-selector' myS3Options />
                </Panel>
            </div>
        )
    }
}

class MyS3LuggageOptionsContainer extends Component {
    constructor (...args) {
        super(...args)
        this.state = {
            hasLuggagePayingOptions: false
        }
    }

    async componentDidMount () {
        const booking = BookingStore.getBookingModel()
        if (showLuggageAddOn()) {
            await restoreSelectedBundlesFromBooking(booking)
            this.setState({
                hasLuggagePayingOptions: hasLuggagePayingOptionsSelector(getState())
            })
        }
    }

    render () {
        return this.state.hasLuggagePayingOptions ? <MyS3LuggageOptions {...this.props} /> : null
    }
}

export default MyS3LuggageOptionsContainer
