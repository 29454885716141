import React, {Component} from 'react'
import PropTypes from 'prop-types'
import Wrap from './wrap'

export default class Row extends Component {
    static propTypes = {
        wrap: PropTypes.bool,
        className: PropTypes.string,
        style: PropTypes.object
    }

    static defaultProps = {
        wrap: true, className: 'row', style: {}
    }

    render () {
        let children = this.props.children
        if (this.props.wrap) {
            children = <Wrap>{this.props.children}</Wrap>
        }

        return (
            <div className={this.props.className} style={this.props.style}>
                {children}
            </div>
        )
    }
}

