// Libraries
import React, {Component} from 'react'
import PropTypes from 'prop-types'
import kebabCase from 'lodash/kebabCase'
import get from 'lodash/get'
import _t from '../../../translate'

export default class PersonalInformationSummary extends Component {
    static propTypes = {
        gender: PropTypes.string,
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        emailAddress: PropTypes.string.isRequired,
        phone: PropTypes.string,
        postalCode: PropTypes.string,
        country: PropTypes.string
    }

    getFullName () {
        return `${this.props.firstName} ${this.props.lastName}`
    }

    getGender () {
        return this.props.gender
            ? _t.getIntlMessage('personal-information-form.gender.values.' + this.props.gender.toLowerCase()) : '-'
    }

    render () {
        const fields = ['fullName', 'gender', 'country', 'phone', 'emailAddress']

        return (
            <ul className='summary-list'>
                {fields.map(field =>
                    <li key={field}>
                        <span className='label'>{this.getLabel(field)}: </span>
                        <span className='value'>{this.getValue(field)}</span>
                    </li>
                )}
            </ul>
        )
    }

    getLabel (fieldName) {
        return _t.getIntlMessage(`personal-information-form.${kebabCase(fieldName)}.label`)
    }

    getValue (fieldName) {
        if (fieldName === 'fullName') {
            return this.getFullName()
        }

        if (fieldName === 'gender') {
            return this.getGender()
        }

        return get(this.props, fieldName)
    }
}
