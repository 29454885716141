import Reflux from 'reflux'
import ResponseCodes from '../../api/response-codes'
import actions from '../actions'
import FormValidationMixin from '../mixin/form-validation-mixin'
import UserAwareMixin from '../mixin/user-aware-mixin'

export default Reflux.createStore({

    listenables: actions,

    mixins: [FormValidationMixin, UserAwareMixin],

    init () {
        this._resetData()

        this.validators = {
            email: [this._validateEmail]
        }
    },

    _resetData () {
        this.data = {
            loading: false,
            ticketUrl: null,
            success: false,
            isValid: false,
            fields: {
                email: {
                    value: null,
                    errorText: null,
                    isRequired: true
                },
                origin: {
                    value: null,
                    errorText: null,
                    isRequired: true
                },
                destination: {
                    value: null,
                    errorText: null,
                    isRequired: true
                },
                travelDate: {
                    value: null,
                    errorText: null,
                    isRequired: true
                }
            }
        }
    },

    resetData () {
        this._resetData()
        this.trigger(this.data)
    },

    getInitialState () {
        return this.data
    },

    onProcessResendTicketsData (newData) {
        newData.forEach(record => {
            this.data.fields[record.id].value = record.value
        })

        this._validateData(newData)
    },

    onResendTickets () {
        this.data.loading = true
        this.data.success = false
        this.trigger(this.data)
    },

    onResendTicketsCompleted () {
        this.data.loading = false
        this.data.success = true
        this.trigger(this.data)
    },

    onApiError (error) {
        if (error.code === ResponseCodes.BOOKING_RESEND_TICKETS_BOOKING_NOT_FOUND) {
            error.handled = true
            this.data.loading = false
            this.data.success = false
            this.trigger(this.data)
        }
    },

    validateFields () {
        this._validateFields(this.data, null)
        this.trigger(this.data)
    }
})
