import React from 'react'
import TextLabel from '../../element/text-label'
import PropTypes from 'prop-types'

export const CmsTitleTextLabel = ({title}) => <TextLabel text={title || ''} />
CmsTitleTextLabel.propTypes = {
    title: PropTypes.string
}

export const CmsContentTextLabel = ({content}) => <TextLabel text={content || ''} />
CmsContentTextLabel.propTypes = {
    content: PropTypes.string
}
