import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {withRouter} from 'react-router'
import _t from '../../translate'
import _ from 'lodash'
import ProgressNavigationContainer from '../../base/main/progress-navigation-container'

class ProgressNavigation extends Component {
    static propTypes = {
        completed: PropTypes.bool,
        previousButtonProps: PropTypes.object,
        nextButtonProps: PropTypes.object,
        handleClick: PropTypes.func,
        router: PropTypes.object
    }

    static defaultProps = {
        previousButtonProps: {},
        nextButtonProps: {}
    }

    constructor (...args) {
        super(...args)
        this.onClick = this.onClick.bind(this)
        this.onNext = this.onNext.bind(this)
    }

    componentWillReceiveProps (nextProps) {
        if (nextProps.completed) {
            this.onNext()
        }
    }

    render () {
        let props = {
            previousButtonProps: this.props.previousButtonProps,
            nextButtonProps: _.merge(this.props.nextButtonProps, {
                onClick: this.onClick
            })
        }

        return <ProgressNavigationContainer {...props} />
    }

    onClick () {
        // Trigger click in PersonalInformationContent
        this.props.handleClick()
    }

    onNext () {
        this.props.router.push(`/${_t.getLocales()}/booking/payment`)
    }
}

export default withRouter(ProgressNavigation)
