import PropTypes from 'prop-types'
import React from 'react'
import _ from 'lodash'
import PureRenderMixin from 'react-addons-pure-render-mixin'

import CmsBlockContent from '../cms/cms-block-content'
import createReactClass from 'create-react-class'

export default createReactClass({

    displayName: 'JourneyResultSetPlaceholder',

    mixins: [PureRenderMixin],

    propTypes: {
        show: PropTypes.bool,
        affiliateCode: PropTypes.string
    },

    render () {
        const label = ['SNCB', 'VSC'].includes(this.props.affiliateCode)
            ? `banner-${_.kebabCase(this.props.affiliateCode)}`
            : 'banner-default'

        return this.props.show
            ? <div className='journey-result-set-placeholder'>
                <CmsBlockContent name={label} />
            </div> : null
    }
})
