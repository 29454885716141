import PropTypes from 'prop-types'
import React, {Component} from 'react'
import Button from '../../element/button'
import ModalDialog from '../../element/modal-dialog'
import TextLabel from '../../element/text-label'
import _t from '../../translate'
import {cmsBlockContainer} from '../cms/cms-block-container'
import CmsContent from '../cms/cms-content'

class CmsModal extends Component {
    static propTypes = {
        title: PropTypes.string,
        content: PropTypes.string,
        closeModal: PropTypes.func
    }

    render () {
        return (
            <ModalDialog
                headerTitle={this.props.title || ''}
                onClose={this.props.closeModal}
            >
                <div className='content'>
                    <CmsContent content={this.props.content} />
                    <Button type='button' className='button primary continue' onClick={this.props.closeModal}>
                        <TextLabel
                            text={_t.message('bike-selection.close-modal-button')}
                        />
                    </Button>
                </div>
            </ModalDialog>
        )
    }
}

const BikeModal = cmsBlockContainer('additional-bike-pop-up', CmsModal)

export default BikeModal
