import get from 'lodash/get'
import {
    COUNTRY_CODE_CZECH_REPUBLIC,
    COUNTRY_CODE_GREAT_BRITAIN, COUNTRY_CODE_HUNGARY
} from './country-codes'
import {
    CURRENCY_CZK,
    CURRENCY_EUR,
    CURRENCY_GBP, CURRENCY_HUF
} from '../constants'

/**
 * @type {{[string]: string}}
 */
const currencyPerCountry = {
    [COUNTRY_CODE_GREAT_BRITAIN]: CURRENCY_GBP,
    [COUNTRY_CODE_HUNGARY]: CURRENCY_HUF,
    [COUNTRY_CODE_CZECH_REPUBLIC]: CURRENCY_CZK
}

const getCountryCodes = travel => ([
    get(travel, 'originStation.countryCode'),
    get(travel, 'destinationStation.countryCode')
])

class JourneySearchManager {
    /**
     * @param travel {{originStation: {countryCode: string}, destinationStation: {countryCode: string}}}
     * @returns {string[]}
     */
    getAvailableCurrencies (travel) {
        const currencies = new Set([
            CURRENCY_EUR,
            ...getCountryCodes(travel).map(this.getCountryCurrency)
        ])
        return [...currencies]
    }

    /**
     * @param countryCode {string}
     * @returns {string}
     */
    getCountryCurrency (countryCode) {
        return currencyPerCountry[countryCode] ?? CURRENCY_EUR
    }
}

export default new JourneySearchManager()
