import PropTypes from 'prop-types'
import React, { Component } from 'react'
import TextLabel from '../../element/text-label'
import {cmsBlockContainer} from '../cms/cms-block-container'

class CancellationResults extends Component {
    static propTypes = {
        title: PropTypes.string,
        content: PropTypes.string
    }

    render () {
        return this.props.title || this.props.content ? (
            <div className='my-s3-cancellation-results'>
                <div className='panel'>
                    {this.props.title ? (
                        <header>
                            <span className='text-label heading'>
                                <TextLabel text={this.props.title} />
                            </span>
                        </header>
                    ) : null}
                    {this.props.content ? (
                        <div className='content'>
                            <div className='cms-block'>
                                <span dangerouslySetInnerHTML={{__html: this.props.content}} />
                                {this.props.children}
                            </div>
                        </div>
                    ) : null }
                </div>
            </div>
        ) : null
    }
}

export default cmsBlockContainer('cancellation-confirm-description', CancellationResults)
