import Reflux from 'reflux'
import _t from '../../translate'
import actions from '../actions'
import UserAwareMixin from '../mixin/user-aware-mixin'

export default Reflux.createStore({

    listenables: actions,

    mixins: [UserAwareMixin],

    init () {
        this._resetData()
    },

    _resetData () {
        this.data = {
            locale: _t.getLocales(),
            loading: false,
            cmsBlocks: [],
            loadingBlocks: [],
            unfoundBlocks: new Set()
        }
    },

    resetData () {
        this._resetData()
        this.trigger(this.data)
    },

    getInitialState () {
        return this.data
    },

    onChangeLocale (locale) {
        this._resetData()
        this.data.locale = locale
        this.trigger(this.data)
    },

    onLoadCmsBlocks (blockNames) {
        this.data.loading = true
        this.data.loadingBlocks = blockNames
        this.trigger(this.data)
    },

    onLoadCmsBlocksCompleted (response) {
        let newBlocks = [].concat(response.data)
        if (this.data.cmsBlocks) {
            newBlocks = newBlocks.concat(
                this.data.cmsBlocks.filter(cmsBlock => !newBlocks.some(newBlock => newBlock.name === cmsBlock.name))
            )
        }
        this.data.unfoundBlocks = new Set([
            ...this.data.unfoundBlocks,
            ...this.data.loadingBlocks.filter(blockName => !newBlocks.some(block => block.name === blockName))
        ])
        this.data.cmsBlocks = newBlocks
        this.data.loading = false
        this.trigger(this.data)
    },

    onLoadCmsBlocksFailed () {
        this.data.unfoundBlocks = new Set([...this.data.unfoundBlocks, ...this.data.loadingBlocks])
        this.data.loading = false
        this.trigger(this.data)
    }
})
