import React from 'react'
import {Route} from 'react-router'
import authCheck from '../../mixins/auth'
import MyOptionsLayout from '../../layouts/my-s3-options-layout'

export default (
    <Route
        path='/:lang/mys3/my-options'
        component={MyOptionsLayout}
        onEnter={(nextState, replaceState, callback) => authCheck(nextState, replaceState, callback)}
    />
)
