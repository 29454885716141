import camelCase from 'lodash/camelCase'
import snakeCase from 'lodash/snakeCase'

export const camelCaseKeys = obj => Object.keys(obj).reduce((data, value) => {
    data[camelCase(value)] = obj[value]
    return data
}, {})

export const camelCaseGivenKeys = (obj, keysToCamelCase = []) => Object.keys(obj).reduce((data, value) => {
    const key = keysToCamelCase.includes(value) ? camelCase(value) : value
    data[key] = obj[value]

    return data
}, {})

export const snakeCaseKeys = obj => Object.keys(obj).reduce((data, value) => {
    data[snakeCase(value)] = obj[value]
    return data
}, {})
