import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from '../translate'
import Icon from './icon'
import Loader from './loader'
import {isBlablabusDomain} from '../misc/blablabus-helpers'

export default class Panel extends Component {
    static propTypes = {
        title: PropTypes.string,
        icon: PropTypes.object,
        secondaryIcon: PropTypes.object,
        name: PropTypes.string,
        open: PropTypes.bool,
        onToggle: PropTypes.func,
        loading: PropTypes.bool,
        closedText: PropTypes.string,
        closedElement: PropTypes.object
    }

    static defaultProps = {
        icon: null,
        secondaryIcon: null,
        closedText: 'panel.open',
        open: true,
        closedElement: null,
        loading: false
    }

    render () {
        const {title, icon, secondaryIcon, name, open, onToggle, closedText} = this.props

        return (
            <div className='panel'>
                <header>
                    <h2>{icon} {title} {secondaryIcon}</h2>
                </header>
                <div className={`${name}-panel`}>
                    <span onClick={onToggle} className='text-label is-clickable toggle-booking-preference-panel-label'>
                        {!isBlablabusDomain() && <Icon type={open ? 'minus' : 'plus'} className='small align-left' />}
                        <span className='text'>{_t.message(open ? 'panel.close' : closedText)}</span>
                    </span>
                </div>
                {this.renderContent()}
            </div>
        )
    }

    renderContent () {
        if (this.props.open && this.props.loading) {
            return <Loader />
        }

        return this.props.open ? <div className='content'>{this.props.children}</div> : this.props.closedElement
    }
}
