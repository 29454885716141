import PropTypes from 'prop-types'
import React, {Component} from 'react'

const Option = item => {
    const {text, ...rest} = item
    return <option {...rest}>{text}</option>
}

class Select extends Component {
    static propTypes = {
        data: PropTypes.array.isRequired,
        onChange: PropTypes.func,
        placeholder: PropTypes.string,
        className: PropTypes.any,
        disabled: PropTypes.any,
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ]),
        text: PropTypes.string
    }

    constructor (...args) {
        super(...args)

        this.onChange = this.onChange.bind(this)
    }

    onChange (event) {
        this.props.onChange(event.target.value)
    }

    shouldComponentUpdate (nextProps) {
        return nextProps.data !== this.props.data ||
            nextProps.placeholder !== this.props.placeholder ||
            nextProps.className !== this.props.className ||
            nextProps.value !== this.props.value ||
            nextProps.disabled !== this.props.disabled
    }

    render () {
        let {value, data, placeholder, ...rest} = this.props
        delete rest.onChange
        delete rest.labelText
        delete rest.errorText

        if (placeholder) {
            data = [{
                value: '',
                text: placeholder
            }, ...data]
        }

        return (
            <select onChange={this.onChange} value={value || ''} {...rest}>
                {data.map((item, index) => <Option key={index} {...item} />)}
            </select>
        )
    }
}

export default Select
