import Reflux from 'reflux'
import actions from '../actions'
import _ from 'lodash'
import _t from '../../translate'
import BookingStore from './booking-store'

export default Reflux.createStore({

    listenables: actions,

    init () {
        this.listenTo(BookingStore, data => {
            if (!data.loading && _.has(data, 'booking.currency')) {
                const currencyBooking = data.booking.currency
                if (currencyBooking !== _t.getCurrency()) {
                    this.onChangeCurrency(currencyBooking)
                }
            }
        })

        this.listenTo(actions.getOffer, 'onRequestOffer')
    },

    onRequestOffer (request) {
        if (request.currency && this.getCurrency() !== request.currency) {
            this.onChangeCurrency(request.currency)
        }
    },

    getInitialState () {
        return this._getData()
    },

    onChangeCurrency (currency) {
        _t.setCurrency(currency)

        this.trigger(this._getData())
    },

    getCurrency () {
        return _t.getCurrency()
    },

    _getData () {
        return {currency: _t.getCurrency()}
    }
})
