import PropTypes from 'prop-types'
import React, {Component} from 'react'
import _ from 'lodash'
import moment from 'moment-timezone'
import _t from '../../../../translate'
import JourneySection from '../../../journey-section-details/journey-section'
import {isOuibusOnlyRoute} from '../../../../misc/modality-helper'

export default class JourneyResultSetListOptionDetails extends Component {
    static propTypes = {
        availability: PropTypes.number,
        route: PropTypes.shape({
            id: PropTypes.string.isRequired,
            legs: PropTypes.arrayOf(PropTypes.shape({
                id: PropTypes.string.isRequired,
                serviceType: PropTypes.shape({
                    modality: PropTypes.string.isRequired
                }).isRequired,
                availability: PropTypes.arrayOf(PropTypes.shape({
                    inventory_class: PropTypes.string,
                    physical: PropTypes.number
                })),
                arrivalStation: PropTypes.shape({
                    name: PropTypes.string,
                    arrivalTime: PropTypes.any
                }),
                departureStation: PropTypes.shape({
                    name: PropTypes.string,
                    departureTime: PropTypes.any
                })
            })).isRequired
        }).isRequired
    }

    render () {
        return (
            <div className='journey-section-details'>
                {this.props.route.legs.map((leg, index, legs) => this._renderLeg(leg, index, legs))}
            </div>
        )
    }

    _renderLeg (leg, index, legs) {
        let type = index === 0 ? 'origin' : 'other'
        let sections = []
        sections.push(
            <JourneySection
                key={`${type}-${index}`}
                type={type}
                station={leg.departureStation}
                serviceType={leg.serviceType}
                serviceName={leg.serviceName}
                time={this._getDepartureTime(leg)}
                travelTime={this._formatTravelDuration(this._getTravelDuration(leg))}
                showModalityLabel={!isOuibusOnlyRoute(this.props.route)}
            />
        )

        const nextIndex = index + 1
        const nextLeg = nextIndex in legs ? legs[nextIndex] : null

        if (nextLeg) {
            sections.push(
                <JourneySection
                    key={`transfer-${index}`}
                    type='transfer'
                    station={leg.arrivalStation}
                    time={this._getArrivalTime(leg)}
                    travelTime={this._formatTransferDuration(this._getTransferDuration(leg, nextLeg))}
                />
            )
        } else {
            sections.push(
                <JourneySection
                    key='destination'
                    type='destination'
                    time={this._getArrivalTime(leg)}
                    station={leg.arrivalStation}
                />
            )
        }

        return sections
    }

    _getDepartureTime (leg) {
        const date = leg.departureStation.departureTime
        return date.format('LT')
    }

    _getArrivalTime (leg) {
        const date = leg.arrivalStation.arrivalTime
        return date.format('LT')
    }

    _getTravelDuration (leg) {
        const arrivalDate = leg.arrivalStation.arrivalTime
        const departureDate = leg.departureStation.departureTime
        return moment.duration(arrivalDate.diff(departureDate))
    }

    _getTransferDuration (leg1, leg2) {
        const startDate = leg1.arrivalStation.arrivalTime
        const endDate = leg2.departureStation.departureTime
        return moment.duration(endDate.diff(startDate))
    }

    _formatTravelDuration (duration) {
        return _t.formatIntlMessage('journey-section.travel-time', this._getDurationValues(duration))
    }

    _formatTransferDuration (duration) {
        return _t.formatIntlMessage('journey-section.transfer-time', this._getDurationValues(duration))
    }

    _getDurationValues (duration) {
        return {
            hours: Math.floor(duration.asHours()),
            minutes: duration.minutes(),
            hoursPadded: _.padStart(Math.floor(duration.asHours()), 2, '0'),
            minutesPadded: _.padStart(duration.minutes(), 2, '0')
        }
    }
}
