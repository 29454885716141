import React, {Component} from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

export default class TabContent extends Component {
    static propTypes = {
        visible: PropTypes.bool.isRequired,
        className: PropTypes.string
    }

    render () {
        const className = classNames('tab-content', this.props.className)

        return (
            <div className={className} hidden={!this.props.visible}>
                {this.props.children}
            </div>
        )
    }
}
