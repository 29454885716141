import PropTypes from 'prop-types'
import React, { Component } from 'react'

export default class Container extends Component {
    static propTypes = {
        className: PropTypes.string
    }

    render () {
        return (
            <div className={this.props.className || 'container'}>
                {this.props.children}
            </div>
        )
    }
}
