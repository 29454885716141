// Libraries
import React from 'react'
import {Route} from 'react-router'

import authCheck from '../../../mixins/auth'

// Layouts
import RebookingPaymentLayout from '../../../layouts/mys3/re-booking/payment'

export default (
    <Route path='/:lang/mys3/re-booking/payment'
           component={RebookingPaymentLayout}
           onEnter={(nextState, replaceState, callback) => authCheck(nextState, replaceState, callback)}
        />
)
