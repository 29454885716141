import React, {Component} from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

export default class AccordionPanelContent extends Component {
    static propTypes = {
        className: PropTypes.string,
        visible: PropTypes.bool,
        onShow: PropTypes.func
    }

    static defaultProps = {
        visible: false
    }

    componentDidMount () {
        const {visible, onShow} = this.props
        if (visible && onShow) {
            onShow()
        }
    }

    render () {
        if (!this.props.visible) {
            return null
        }

        var {className, ...props} = this.props
        delete props.visible

        className = classNames('accordion-content', className)
        return (
            <div className={className} {...props}>
                {this.props.children}
            </div>
        )
    }
}

