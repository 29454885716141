/* globals S3P_SETTINGS: true */

import PropTypes from 'prop-types'
import React from 'react'
import Reflux from 'reflux'
import storage from '../../storage'
import Loader from '../../element/loader'
import PaymentStore from '../../reflux/stores/payment-store'
import actions from '../../reflux/actions'
import BookingModel from '../../models/booking-model'
import createReactClass from 'create-react-class'

export default createReactClass({
    displayName: ' ConfirmationPoller',

    mixins: [
        Reflux.listenTo(PaymentStore, 'onUpdatePayment'),
        Reflux.listenTo(actions.getBooking.completed, 'onBookingCompleted')
    ],

    propTypes: {
        bookingNumber: PropTypes.string,
        status: PropTypes.string,
        onSuccess: PropTypes.func.isRequired,
        onCancelled: PropTypes.func.isRequired,
        onFailed: PropTypes.func.isRequired,
        onTimeOut: PropTypes.func.isRequired,
        urlStatus: PropTypes.string
    },

    getInitialState () {
        return {
            pollCounter: 0,
            pollTimer: null,
            timeOutTimer: window.setTimeout(() => this._handleTimeOut(), this._getTimeOutInterval())
        }
    },

    componentDidMount () {
        this._processPayment()
    },

    componentWillUnmount () {
        this._clearPoll()
        this._clearTimeOut()
    },

    onBookingCompleted (data) {
        const booking = BookingModel.create(data.data.booking)
        const pendingPayments = booking.pendingPayments
        const successfulPayments = booking.successfulPayments
        if (pendingPayments.length > 0) {
            this.setState({
                pollTimer: window.setTimeout(() => this._getBooking(), this._getPollInterval()),
                pollCounter: this.state.pollCounter + 1
            })
        } else if (successfulPayments.length === 0) {
            this.props.onFailed()
        }
    },

    onUpdatePayment (data) {
        if (data.loading || !data.paymentResult) {
            return
        }
        this._clearPoll()

        if (data.paymentResult.isFailed()) {
            if (this.props.status === 'cancelled') {
                this._clearTimeOut()
                this.props.onCancelled()
            } else {
                this._getBooking()
            }
        } else if (!data.paymentResult.handled) {
            this.setState({
                pollTimer: window.setTimeout(() => this._processPayment(), this._getPollInterval()),
                pollCounter: this.state.pollCounter + 1
            })
        } else if (data.paymentResult.isSuccess()) {
            this._clearTimeOut()
            this.props.onSuccess()
        }
    },

    _getBooking () {
        actions.getBooking(this.props.bookingNumber)
    },

    // Handle status in booking when there is no payment reference
    _handleBookingStatus () {
        if (this.props.status === 'success') {
            this.props.onSuccess()
        } else if (this.props.status === 'failed') {
            this.props.onFailed()
        }
    },

    _processPayment () {
        const paymentData = {
            booking_number: this.props.bookingNumber,
            status_indicator: this.props.urlStatus,
            token: window.postData && window.postData.PaRes,
            payment_session: window.postData && window.postData.MD
        }
        if (storage.has('payment_reference')) {
            paymentData.payment_reference = storage.get('payment_reference')
        }

        if (paymentData.payment_reference) {
            actions.processPayment(paymentData)
        } else {
            this._handleBookingStatus()
        }
        window.postData = undefined
    },

    _clearPoll () {
        if (this.state.pollTimer) {
            window.clearTimeout(this.state.pollTimer)
        }
    },

    _handleTimeOut () {
        this._clearPoll()
        this.props.onTimeOut()
    },

    _clearTimeOut () {
        if (this.state.timeOutTimer) {
            window.clearTimeout(this.state.timeOutTimer)
        }
    },

    _getPollInterval () {
        return S3P_SETTINGS.s3Passenger.features.payment.pollInterval * 1000
    },

    _getTimeOutInterval () {
        return S3P_SETTINGS.s3Passenger.features.payment.timeOut * 1000
    },

    render () {
        return <Loader />
    }
})
