// Libraries
import PropTypes from 'prop-types'
import React from 'react'
import Reflux from 'reflux'
import BookingModel from '../models/booking-model'
import createReactClass from 'create-react-class'

// Stores
import BookingStore from '../reflux/stores/booking-store'

export default Component => createReactClass({

    displayName: 'BookingLayout',

    mixins: [
        Reflux.listenTo(BookingStore, 'bookingUpdated')
    ],

    propTypes: {
        name: PropTypes.string.isRequired
    },

    getDefaultProps () {
        return {name: ''}
    },

    getInitialState () {
        return {
            booking: BookingStore.getBookingModel()
        }
    },

    bookingUpdated (data) {
        if (!data.loading && data.success) {
            this.setState({
                booking: BookingModel.create(data.booking)
            })
        }
    },

    render () {
        return (
            <Component {...this.props} booking={this.state.booking}>
                {this.props.children}
            </Component>
        )
    }
})
