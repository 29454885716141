import { createSelector } from 'reselect'
import { TRAVEL_DIRECTION_OUTBOUND, TRAVEL_DIRECTION_INBOUND } from '../../../constants'
import { camelCaseKeys } from '../../../../../misc/camelcase'
import { stationMapper } from '../meta/stations'
import { productFamiliesSelector } from './product-families'
import { basePassengersSelector } from './passengers'
import moment from 'moment-timezone'
import sumBy from 'lodash/sumBy'
import {bookingSelector} from '../../../../../reflux/bridge/booking'

const productMapper = ({
    name: productName,
    code: productCode,
    passenger_id: passengerId,
    product_family_id: productFamilyId,
    ...product
},
segment,
productFamilies,
passengers,
direction,
required) => (
    {
        ...camelCaseKeys(product),
        discountedPrice: product.price - sumBy(product.discounts || [], 'amount'),
        productName,
        productCode,
        passenger: passengers && passengers.find(passenger => passenger.id === passengerId),
        productFamily: productFamilies && productFamilies.find(productFamily => productFamily.id === productFamilyId),
        direction,
        segmentId: segment.id,
        departureStation: segment.departure_station && stationMapper(segment.departure_station),
        arrivalStation: segment.arrival_station && stationMapper(segment.arrival_station),
        required
    })

const productsSelectorCreator = (segmentsProperty, productsProperty, direction, required) => bookingSelector =>
    createSelector(
        [
            bookingSelector,
            productFamiliesSelector(bookingSelector),
            basePassengersSelector(bookingSelector)
        ],
        (booking, productFamilies, passengers) => ((booking && booking[segmentsProperty]) || []).reduce(
            (products, segment) => products.concat(segment[productsProperty].map(
                product => productMapper(product, segment, productFamilies, passengers, direction, required)
            )),
            []
        )
    )

export const requiredOutboundProductsSelector = productsSelectorCreator(
    'outbound_booking_tariff_segments',
    'required_products',
    TRAVEL_DIRECTION_OUTBOUND,
    true
)

export const additionalOutboundProductsSelector = productsSelectorCreator(
    'outbound_booking_tariff_segments',
    'additional_products',
    TRAVEL_DIRECTION_OUTBOUND,
    false
)

export const requiredInboundProductsSelector = productsSelectorCreator(
    'inbound_booking_tariff_segments',
    'required_products',
    TRAVEL_DIRECTION_INBOUND,
    true
)

export const additionalInboundProductsSelector = productsSelectorCreator(
    'inbound_booking_tariff_segments',
    'additional_products',
    TRAVEL_DIRECTION_INBOUND,
    false
)

export const outboundProductsSelector = bookingSelector => createSelector(
    [requiredOutboundProductsSelector(bookingSelector), additionalOutboundProductsSelector(bookingSelector)],
    (requiredProducts, additionalProducts) => requiredProducts.concat(additionalProducts)
)

export const inboundProductsSelector = bookingSelector => createSelector(
    [requiredInboundProductsSelector(bookingSelector), additionalInboundProductsSelector(bookingSelector)],
    (requiredProducts, additionalProducts) => requiredProducts.concat(additionalProducts)
)

export const productsSelector = bookingSelector => createSelector(
    [outboundProductsSelector(bookingSelector), inboundProductsSelector(bookingSelector)],
    (outboundProducts, inboundProducts) => outboundProducts.concat(inboundProducts)
)

export const withoutCancelledSelector = productsSelector => createSelector(
    [productsSelector],
    products => products.filter(product => !product.cancelled)
)

export const withProvisionalCancelledSelector = productsSelector => createSelector(
    [productsSelector],
    products => products.filter(({cancelled, provisional}) => cancelled && provisional)
)

export const withProvisionalNonCancelledSelector = productsSelector => createSelector(
    [productsSelector],
    products => products.filter(({cancelled, provisional}) => !cancelled && provisional)
)

export const withNonProvisionalNonCancelledSelector = productsSelector => createSelector(
    [productsSelector],
    products => products.filter(({cancelled, provisional}) => !cancelled && !provisional)
)

export const requiredProductsSelector = bookingSelector => createSelector(
    [requiredInboundProductsSelector(bookingSelector), requiredOutboundProductsSelector(bookingSelector)],
    (inboundProducts, outboundProducts) => inboundProducts.concat(outboundProducts)
)

export const additionalProductsSelector = createSelector(
    [additionalInboundProductsSelector(bookingSelector), additionalOutboundProductsSelector(bookingSelector)],
    (inboundProducts, outboundProducts) => inboundProducts.concat(outboundProducts)
)

export const canRebookProductsSelector = productsSelector => createSelector(
    [withoutCancelledSelector(productsSelector)],
    products => (products.length > 0 && products.every(product => product.canBeRebooked))
)

export const aftersalesRulesSelector = productsSelector => createSelector(
    [withoutCancelledSelector(productsSelector)],
    products => (products || []).reduce((rules, product) => {
        rules.push(...product.afterSalesRules)
        return rules
    }, [])
)

export const aftersalesRuleSelector = type => createSelector(
    [aftersalesRulesSelector(productsSelector(bookingSelector))],
    rules => rules.find(rule => rule.type === type)
)

export const aftersalesRulesPaymentRequiredSelector = type => productsSelector => createSelector(
    [aftersalesRulesSelector(productsSelector)],
    aftersalesRules => aftersalesRules.some(
        rule =>
            (!type || rule.type === type) &&
    rule.active &&
    rule.price &&
    rule.price > 0 &&
    moment().isSameOrBefore(rule.valid_until)
    )
)

export const totalPriceAdditionalProductsSelector = createSelector(
    [withoutCancelledSelector(additionalProductsSelector)],
    additionalProducts => additionalProducts.reduce((sum, product) =>
        sum + product.price, 0)
)
