import React from 'react'
import {Route} from 'react-router'

import authCheck from '../../../mixins/auth'
import MyS3SeatSelectorLayout from '../../../layouts/mys3/seat/seat-selector-layout'

export default (
    <Route
        path='/:lang/mys3/seat-selector/:id'
        component={MyS3SeatSelectorLayout}
        onEnter={(nextState, replaceState, callback) => authCheck(nextState, replaceState, callback)}
    />
)
