import _ from 'lodash'
import sumBy from 'lodash/sumBy'
import omitBy from 'lodash/omitBy'
import moment from 'moment-timezone'
import SegmentCollection from './segment-collection'
import SegmentModel from './segment-model'
import ProductCollection from './product-collection'
import {
    PAYMENT_STATUS_P,
    PAYMENT_STATUS_S,
    VOUCHER_TYPE_DISCOUNT,
    PAYMENT_METHOD_VOUCHER,
    INSURANCE_PRODUCT_REGEX,
    PRODUCT_CODE_NEL,
    PRODUCT_CODE_ALSA
} from '../constants'
import {createLegId} from '../misc/segment'
import {isPendingCashPayment} from '../misc/payment'
import {
    isBlank,
    mapPassengers
} from '../misc/helpers'

export default class BookingModel {
    static create (booking) {
        if (booking instanceof BookingModel) {
            return booking
        }

        return _.get(booking, 'booking_number') ? new BookingModel(booking) : null
    }

    constructor (data) {
        if (!_.isObject(data)) {
            throw Error('Booking should be an object')
        }

        const booking = data instanceof BookingModel ? data.rawBooking : data
        if (booking && booking.passengers) {
            booking.passengers = mapPassengers(booking.passengers)
        }

        this._data = _(booking)
    }

    /** @deprecated use model to get booking data */
    get rawBooking () {
        return this._data.value()
    }

    get requiresPayment () {
        return this.totalPriceToBePaid > 0 && this.pendingTotalPriceToBePaid > 0
    }

    isFullyCancelled () {
        return this.isOutboundCancelled() && this.isInboundCancelled()
    }

    isInboundCancelled () {
        return _.isUndefined(this.inboundProducts.find({cancelled: false}))
    }

    isOutboundCancelled () {
        return _.isUndefined(this.outboundProducts.find({cancelled: false}))
    }

    isFullyInThePast () {
        return this.isOutboundInThePast() && (this.inboundTravelDate === null || this.isInboundInThePast())
    }

    isOutboundInThePast () {
        return moment().isAfter(this.outboundTravelDate)
    }

    isInboundInThePast () {
        return moment().isAfter(this.inboundTravelDate)
    }

    get bookingNumber () {
        return this._data.get('booking_number')
    }

    get lastAftersalesOperation () {
        return this._data.get('last_after_sales_operation')
    }

    get salesChannelCode () {
        return this._data.get('sales_channel_code')
    }

    get expiryTimestamp () {
        const timestamp = this._data.get('expiry_timestamp')

        return timestamp ? moment(timestamp).toDate() : null
    }

    get ageInSeconds () {
        const timestamp = this._data.get('confirmed_timestamp')

        return timestamp ? moment().diff(moment(timestamp), 'seconds') : null
    }

    get ticketUrl () {
        return this._data.get('ticket_url')
    }

    get outboundTariffSegments () {
        return _(this._data.get('outbound_booking_tariff_segments', []))
    }

    get inboundTariffSegments () {
        return _(this._data.get('inbound_booking_tariff_segments', []))
    }

    get tariffSegments () {
        const outboundTariffSegments = this.outboundTariffSegments.map(data => {
            data.direction = 'outbound'
            return data
        })

        const inboundTariffSegments = this.inboundTariffSegments.map(data => {
            data.direction = 'inbound'
            return data
        })

        return outboundTariffSegments.concat(inboundTariffSegments.value())
    }

    get tariffSegmentCollection () {
        return new SegmentCollection(this.tariffSegments.value())
    }

    get tariffSegmentsWithSeats () {
        return this.tariffSegments.filter(
            tariffSegment => !BookingModel._getProductsForTariffSegments(_([tariffSegment])).map('seat').isEmpty()
        )
    }

    get outboundSegmentCollection () {
        return new SegmentCollection(this.outboundTariffSegments.value())
    }

    get inboundSegmentCollection () {
        return new SegmentCollection(this.inboundTariffSegments.value())
    }

    hasInboundSegments (filter = () => true) {
        return !this._getInboundBookingJourneySegments(filter).isEmpty()
    }

    get outboundOrigin () {
        const segmentCollection = this.outboundSegmentCollection.withRequiredProducts().withoutCancelled()

        return _.get(segmentCollection.firstJourneySegment, 'departure_station')
    }

    get outboundDestination () {
        const segmentCollection = this.outboundSegmentCollection.withRequiredProducts().withoutCancelled()

        return _.get(segmentCollection.lastJourneySegment, 'arrival_station')
    }

    get inboundOrigin () {
        const segmentCollection = this.inboundSegmentCollection.withRequiredProducts().withoutCancelled()

        return _.get(segmentCollection.firstJourneySegment, 'departure_station')
    }

    get inboundDestination () {
        const segmentCollection = this.inboundSegmentCollection.withRequiredProducts().withoutCancelled()

        return _.get(segmentCollection.lastJourneySegment, 'arrival_station')
    }

    get outboundTravelDate () {
        const segmentCollection = this.outboundSegmentCollection.withRequiredProducts().withoutCancelled()

        return segmentCollection.departureDateTime
    }

    get outboundArrivalDate () {
        const segmentCollection = this.outboundSegmentCollection.withRequiredProducts().withoutCancelled()

        return segmentCollection.arrivalDateTime
    }

    get departureStationInfo () {
        const segmentCollectionWithoutCancelled = this.outboundSegmentCollection.withRequiredProducts().withoutCancelled()
        const segmentCollection = segmentCollectionWithoutCancelled.length ? segmentCollectionWithoutCancelled : this.outboundSegmentCollection.withRequiredProducts()
        return segmentCollection.first.departure_station
    }

    get arrivalStationInfo () {
        const segmentCollectionWithoutCancelled = this.outboundSegmentCollection.withRequiredProducts().withoutCancelled()
        const segmentCollection = segmentCollectionWithoutCancelled.length ? segmentCollectionWithoutCancelled : this.outboundSegmentCollection.withRequiredProducts()

        return segmentCollection.last.arrival_station
    }

    get inboundTravelDate () {
        const segmentCollection = this.inboundSegmentCollection.withRequiredProducts().withoutCancelled()

        return segmentCollection.departureDateTime
    }

    get inboundArrivalDate () {
        const segmentCollection = this.inboundSegmentCollection.withRequiredProducts().withoutCancelled()

        return segmentCollection.arrivalDateTime
    }

    get additionalProducts () {
        const products = this.tariffSegments.map('additional_products').flatten().value()
        return ProductCollection.create(products)
    }

    getOutboundRequiredProductsByPassengerType (type) {
        return this._getRequiredProductsByPassengerType(this.outboundSegmentCollection, type)
    }

    getInboundRequiredProductsByPassengerType (type) {
        return this._getRequiredProductsByPassengerType(this.inboundSegmentCollection, type)
    }

    _getRequiredProductsByPassengerType (segmentCollection, type) {
        return segmentCollection.withoutCancelled().requiredProducts.reduce((filter, product) => {
            if (this.passengers.some(passenger => passenger.passengerType === type && passenger.id === product.passenger_id)) {
                return filter.concat(product)
            }

            return filter
        }, [])
    }

    get isConfirmed () {
        return this._data.has('confirmed_timestamp')
    }

    get isUpdated () {
        return this._data.has('updated_timestamp')
    }

    get hasChangeExpiryTime () {
        return this._data.has('change_expiry_timestamp')
    }

    get additionalDetails () {
        return this._data.get('additional_details', [])
    }

    get outboundProducts () {
        return BookingModel._getProductsForTariffSegments(this.outboundTariffSegments)
    }

    get inboundProducts () {
        return BookingModel._getProductsForTariffSegments(this.inboundTariffSegments)
    }

    get products () {
        return this.outboundProducts.concat(this.inboundProducts.value())
    }

    get provisionalProducts () {
        return this.products.filter({provisional: true})
    }

    isReturnTrip () {
        return !this.outboundTariffSegments.isEmpty() && !this.inboundTariffSegments.isEmpty()
    }

    get passengers () {
        return _(this._data.get('passengers', [])).filter(passenger => !passenger.cancel_timestamp)
    }

    get passengersWithProvisionalCancelled () {
        const products = this.products.reduce((_passengers, product) => {
            if (!product.cancelled || (product.provisional && product.cancelled)) {
                _passengers.set(product.passenger_id, this.passengers.find({id: product.passenger_id}))
            }
            return _passengers
        }, new Map())

        return _(Array.from(products.values()))
    }

    get passengersWithCancelled () {
        return _(this._data.get('passengers', []))
    }

    get payments () {
        return this._data.get('payments', [])
    }

    get customer () {
        return this._data.get('customer')
    }

    get customerName () {
        return `${this.customer.first_name} ${this.customer.last_name}`
    }

    get currency () {
        return this._data.get('currency')
    }

    get affiliateCode () {
        return this._data.get('affiliate_code')
    }

    get isPaidWithVouchers () {
        const successfulPaymentsAmount = sumBy(this.successfulPayments, 'amount')
        const pendingVouchersAmount = sumBy(this.pendingVouchers, 'amount')
        const remainingAmountToBePaid = this.totalPrice - successfulPaymentsAmount

        // round is needed to prevent floating point rounding errors
        return _.round(remainingAmountToBePaid, 4) <= _.round(pendingVouchersAmount, 4)
    }

    get totalPrice () {
        return this._data.get('total_price', 0.0) + this.totalDiscountVoucherAmount
    }

    get totalPriceUnconverted () {
        return this._data.get('total_price_unconverted', 0.0)
    }

    get totalDiscountVoucherAmount () {
        return _(this.vouchers).filter({type: 'DISCOUNT'}).sumBy('amount')
    }

    get totalPriceToBePaid () {
        return this._data.get('total_price_to_be_paid', 0.0)
    }

    get pendingTotalPriceToBePaid () {
        const totalPrice = this._data.get('total_price', 0.0)

        const pendingPrice = this.payments
            .reduce(
                (_totalPrice, payment) => {
                    if (payment.payment_status === PAYMENT_STATUS_S ||
                        (payment.payment_status === PAYMENT_STATUS_P && payment.method === PAYMENT_METHOD_VOUCHER) ||
                        isPendingCashPayment(payment)
                    ) {
                        _totalPrice -= payment.amount
                    }

                    return _totalPrice
                },
                totalPrice
            )
        return Math.round(pendingPrice * 100) / 100
    }

    get totalPriceThatWasPayed () {
        return this.totalPrice - _(this.vouchers).filter(v => v.type !== 'PREPAID').sumBy('amount')
    }

    get totalVat () {
        return this._data.get('total_vat', 0.0)
    }

    get totalVatUnconverted () {
        return this._data.get('total_vat_unconverted', 0.0)
    }

    get openRefundAmount () {
        return -1 * _(this.payments).filter('refund_available').sumBy('amount')
    }

    get hasCustomer () {
        return this._data.has('customer')
    }

    get hasPassengers () {
        return this.passengers.size() > 0
    }

    _getInboundBookingJourneySegments () {
        return this.inboundTariffSegments
            .map('booking_journey_segments')
            .flatten()
    }

    static _getProductsForTariffSegments (tariffSegments) {
        return tariffSegments.map('required_products')
            .concat(tariffSegments.map('additional_products').value())
            .flatten()
    }

    get applicableFeePrice () {
        return this.getFeesToBePaid().sumBy('price')
    }

    get successfulPayments () {
        return this.payments.filter(payment => PAYMENT_STATUS_S === payment.payment_status)
    }

    get allFeesPrice () {
        return this._getNonCancelledFees().sumBy('price')
    }

    _getAllRequiredProductsByPassengerType (segmentCollection, type) {
        return segmentCollection.requiredProducts.reduce((filter, product) => {
            if (this.passengers.some(passenger => passenger.type === type && passenger.id === product.passenger_id)) {
                return filter.concat(product)
            }

            return filter
        }, [])
    }

    getAmountAddedTicketsByDirectionAndPassengerType (direction, type) {
        const collection = direction === 'outbound' ? this.outboundSegmentCollection : this.inboundSegmentCollection
        return this._getAllRequiredProductsByPassengerType(collection, type)
            .filter(p => p.provisional && !p.cancelled)
            .reduce((sum, p) => sum + p.price, 0)
    }

    getAmountReplacedTicketsByDirectionAndPassengerType (direction, type) {
        const collection = direction === 'outbound' ? this.outboundSegmentCollection : this.inboundSegmentCollection
        return this._getAllRequiredProductsByPassengerType(collection, type)
            .filter(p => p.provisional && p.cancelled)
            .reduce((sum, p) => sum + p.price, 0)
    }

    get amountAddedTickets () {
        const products = this.products.filter({provisional: true, cancelled: false})
        return products.sumBy('price') + this.getAmountVoucherDiscountForProducts(products)
    }

    getAmountVoucherDiscountForProducts (products) {
        return products
            .reduce((sum, product) => {
                if (product.cancelled) {
                    return sum
                }
                const productSum = product.discounts
                    .reduce((sum, discount) => discount.type === 'V' ? sum + discount.amount : sum, 0)
                return sum + productSum
            }, 0)
    }

    get amountReplacedTickets () {
        const products = this.products.filter({provisional: true, cancelled: true})

        return products.sumBy('price') +
            this.getAmountVoucherDiscountForProducts(products)
    }

    getFeesToBePaid () {
        return this._getNonCancelledFees().filter({provisional: true})
    }

    getFeesPaid () {
        return this._getNonCancelledFees().filter({provisional: false})
    }

    get provisionalCancelledFees () {
        return this._getAllFees().filter({provisional: true, cancelled: true}).value()
    }

    get amountProvisionalCancelledFees () {
        return _.sumBy(this.provisionalCancelledFees, 'price')
    }

    _getNonCancelledFees () {
        return this._getAllFees().filter({cancelled: false})
    }

    _getAllFees () {
        return this.tariffSegments.map('fees').flatten()
    }

    get vouchers () {
        return this._data.get('vouchers', [])
    }

    isVoucherSuccessful (code) {
        const voucher = this.vouchers.find(voucher => voucher.code === code)
        return voucher
            ? this.payments.find(payment => payment.id === voucher.payment_id && payment.payment_status === PAYMENT_STATUS_S)
            : false
    }

    get pendingVouchers () {
        return this.vouchers.filter(voucher => this.pendingPayments.some(payment => payment.voucher_id === voucher.id))
    }

    get pendingPayments () {
        return this.payments.filter(payment => PAYMENT_STATUS_P === payment.payment_status)
    }

    get hasPendingPayments () {
        return this.pendingPayments.length > 0
    }

    get hasDiscountAfterPendingPayment () {
        const hasVoucherDiscount = this.pendingVouchers.find(voucher => voucher.type === VOUCHER_TYPE_DISCOUNT)
        if (!hasVoucherDiscount) {
            return false
        }
        const voucherDiscount = this.pendingPayments.find(payment => payment.id === hasVoucherDiscount.payment_id)
        const transactionTimeVoucherDiscount = moment(voucherDiscount.transaction_timestamp, 'YYYY-MM-DDTHH:mm:ssZ')
        const pendingPayments = this.pendingPayments.filter(payment => {
            if (
                payment.reference &&
                payment.reference === voucherDiscount.reference
            ) {
                return false
            }
            const transactionTimePayment = moment(payment.transaction_timestamp, 'YYYY-MM-DDTHH:mm:ssZ')

            return transactionTimePayment.isBefore(transactionTimeVoucherDiscount)
        })

        return pendingPayments.length > 0
    }

    get relatedVouchers () {
        return this._data.get('related_vouchers', [])
    }

    getJourneySegmentById (id) {
        const data = this._getGroupedJourneySegmentData().find(data => !_(data).filter({id: id}).isEmpty())[0]
        data.journeySegment.travel_date = data.tariffSegment.travel_date

        return data.journeySegment
    }

    getTariffSegmentDataById (id) {
        return _(this._getGroupedJourneySegmentData().find(data => !_(data).filter({id: id}).isEmpty()))
    }

    get lastAfterSalesOperation () {
        return this._data.get('last_after_sales_operation')
    }

    get canBeRebooked () {
        return this.canRebookOutbound || this.canRebookInbound
    }

    get canRebookOutbound () {
        return this.outboundSegmentCollection.canBeRebooked
    }

    get canRebookInbound () {
        return this.inboundSegmentCollection.hasSegments && this.inboundSegmentCollection.canBeRebooked
    }

    get canBeCancelled () {
        return !_.isUndefined(this.products.find('can_be_cancelled'))
    }

    get canChangeName () {
        const products = this.products.filter({cancelled: false})

        return products.size() > 0 && _.isUndefined(products.find({can_change_name: false}))
    }

    canSelectSeatByLeg (legId) {
        return this._getGroupedJourneySegmentData()
            .some(travel =>
                travel[0].id === legId &&
                travel[0].tariffSegment.required_products
                    .some(requiredProduct => requiredProduct.can_change_seat)
            )
    }

    get selectedSeats () {
        return this._getGroupedJourneySegmentData()
            .reduce((seats, journeySegments) => ([
                ...seats,
                journeySegments[0].tariffSegment.required_products
                    .reduce((seats, requiredProduct) => {
                        return requiredProduct.seat
                            ? [
                                ...seats,
                                {
                                    leg_id: journeySegments[0].id,
                                    passenger_id: requiredProduct.passenger_id,
                                    carriage_number: requiredProduct.seat.carriage,
                                    seat_number: requiredProduct.seat.number
                                }
                            ]
                            : seats
                    }, [])
            ]), [])
            .flatten()
    }

    seatSelectionOptions (provisional) {
        const productsFilter = {cancelled: false}
        if (typeof provisional === 'boolean') {
            productsFilter.provisional = provisional
        }
        return this._getGroupedJourneySegmentData()
            .filter(journeySegments =>
                _(journeySegments)
                    .map('tariffSegment.required_products')
                    .flatten()
                    .find(productsFilter)
            )
            .map(data => {
                let tariffSegment = new SegmentModel(data[0].tariffSegment)
                let journeySegment = data[0].journeySegment

                return {
                    direction: data[0].tariffSegment.direction,
                    segment_id: tariffSegment.id,
                    leg: {
                        id: data[0].id,
                        departure_station: {
                            name: journeySegment.departure_station.name,
                            departure_timestamp: journeySegment.departure_date_time,
                            _u_i_c_station_code: journeySegment.departure_station._u_i_c_station_code
                        },
                        arrival_station: {
                            name: journeySegment.arrival_station.name,
                            _u_i_c_station_code: journeySegment.arrival_station._u_i_c_station_code
                        },
                        service_schedule_date: data[0].tariffSegment.travel_date,
                        service_name: journeySegment.service_name,
                        service_type: {
                            code: journeySegment.service_type.code,
                            modality: journeySegment.service_type.modality
                        },
                        show_seats_threshold: tariffSegment.canViewSeats(),
                        show_seats_threshold_moment: tariffSegment.showSeatsThresholdMoment
                    }
                }
            })
    }

    get hasManuallySelectedSeats () {
        return this.tariffSegmentCollection.selectedManualSelectedSeats.length >= 1
    }

    get hasProvisionalManuallySelectedSeats () {
        return (this.tariffSegmentCollection.selectedManualSelectedSeats || []).some(seat => seat.provisional)
    }

    hasCancelledTickets () {
        return !this.products.filter({cancelled: true}).isEmpty()
    }

    get provisional () {
        return this.expiryTimestamp && moment(this.expiryTimestamp).isAfter() && !this.hasCancelledTickets()
    }

    _getGroupedJourneySegmentData () {
        return this._getDeNormalizedFlattenJourneySegments().groupBy(
            data => [
                data.journeySegment.departure_date_time,
                data.journeySegment.arrival_date_time,
                data.journeySegment.departure_station._u_i_c_station_code,
                data.journeySegment.arrival_station._u_i_c_station_code,
                data.journeySegment.service_name
            ].join('|')
        )
    }

    _getDeNormalizedFlattenJourneySegments () {
        return this.tariffSegmentsWithSeats.map(
            tariffSegment => tariffSegment.booking_journey_segments.map(
                journeySegment => ({
                    id: createLegId(
                        journeySegment.service_name,
                        tariffSegment.travel_date,
                        journeySegment.departure_station.short_code,
                        journeySegment.arrival_station.short_code
                    ),
                    tariffSegment: tariffSegment,
                    journeySegment: journeySegment
                })
            )).flatten()
    }

    setInvoiceUrl (invoiceUrl) {
        return BookingModel.create(Object.assign({invoice_url: invoiceUrl}, this._data.value()))
    }

    get invoiceUrl () {
        return this._data.get('invoice_url')
    }

    get notes () {
        return this._data.get('notes', [])
    }

    get isInternationalTravel () {
        const outboundSegmentCollection = this.outboundSegmentCollection.withRequiredProducts().withoutCancelled()
        const inboundSegmentCollection = this.inboundSegmentCollection.withRequiredProducts().withoutCancelled()

        let departureStation, arrivalStation
        if (outboundSegmentCollection.hasSegments) {
            departureStation = outboundSegmentCollection.departureStation
            arrivalStation = outboundSegmentCollection.arrivalStation
        } else if (inboundSegmentCollection.hasSegments) {
            departureStation = inboundSegmentCollection.departureStation
            arrivalStation = inboundSegmentCollection.arrivalStation
        }

        return Boolean(
            departureStation &&
            arrivalStation &&
            departureStation.country_code !== arrivalStation.country_code
        )
    }

    hasPromoTariff () {
        return this.tariffSegmentCollection.hasPromoTariff()
    }

    isConfirmedAndNotFullInThePast () {
        return this.isConfirmed && !this.isFullyInThePast()
    }

    canViewSeats () {
        return this.tariffSegmentCollection.requiredProducts.some(product =>
            product.seat && product.segment.canViewSeats()
        )
    }

    get segmentsAndPassengersForProducts () {
        const segments = this.tariffSegmentCollection.withoutCancelled().getData().map(
            segment => omitBy({
                id: segment.id,
                origin_station: segment.departure_station._u_i_c_station_code,
                destination_station: segment.arrival_station._u_i_c_station_code,
                start_validity_date: segment.validity_start_date,
                start_validity_time: segment.booking_journey_segments && segment.booking_journey_segments.length && moment(segment.booking_journey_segments[0].departure_date_time).format('HH:mm:ss'),
                service_name: segment.booking_journey_segments && segment.booking_journey_segments.length && segment.booking_journey_segments[0].service_name
            }, isBlank)
        ).value()

        const passengers = this.passengers.map(
            passenger => ({
                id: passenger.id,
                type: passenger.type
            })
        ).value()

        return {segments, passengers}
    }

    get hasConfirmedStaticProducts () {
        return this.hasALSAProduct || this.hasNELProduct
    }

    get hasNELProduct () {
        return this.products.some(product => product.code === PRODUCT_CODE_NEL && !product.cancelled && !product.provisional)
    }

    get hasALSAProduct () {
        return this.products.some(product => product.code === PRODUCT_CODE_ALSA && !product.cancelled && !product.provisional)
    }

    get cheapestNameChangeAftersalesRule () {
        return this.products.map('after_sales_rules')
            .flatten()
            .filter({active: true, type: 'NAMECHANGE'})
            .sortBy('price')
            .first()
    }

    get outboundProductFamilyId () {
        return this._data.get('outbound_product_family_id')
    }

    get insuranceProduct () {
        return this.additionalProducts.value().find(
            product => (
                !product.cancelled &&
                (product.product_code || product.code || '').match(INSURANCE_PRODUCT_REGEX)
            )
        )
    }
}
