import React, {Component} from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import AccordionPanelHeader from './panel/header'
import AccordionPanelContent from './panel/content'

export default class AccordionPanel extends Component {
    static propTypes = {
        name: PropTypes.string.isRequired,
        className: PropTypes.string,
        onClick: PropTypes.func,
        collapsed: PropTypes.bool
    }

    render () {
        let {className, onClick, name, collapsed, ...props} = this.props
        className = classNames('accordion-panel', className, {'state--is-selected': !collapsed})
        let children = React.Children.map(this.props.children, child => {
            if (child.type === AccordionPanelHeader) {
                return React.cloneElement(child, {panelName: name, collapsed: collapsed, onClick})
            } else if (child.type === AccordionPanelContent && !collapsed) {
                return React.cloneElement(child, {...child.props, visible: !collapsed})
            }
        })

        return <div className={className} {...props}>{children}</div>
    }
}
