/* globals S3P_SETTINGS: true */

import Reflux from 'reflux'
import actions from '../actions'
import ResponseCodes from '../../api/response-codes'
import _t from '../../translate'

export default Reflux.createStore({

    listenables: actions,

    init () {
        this._resetData()

        this.listenTo(actions.getOffer, this.resetData)
        this.listenTo(actions.clearBooking, this.resetData)
    },

    _resetData () {
        this.data = {}
    },

    resetData () {
        this._resetData()
        this.trigger(this.data)
    },

    getData () {
        return this.data
    },

    getInitialState () {
        return this.data
    },

    onProcessDeepLink (data) {
        this.data = data
        this.trigger(this.data)
    },

    onApiError (error) {
        if (error.code === ResponseCodes.BOOKING_SEAT_CAN_NOT_BE_ALLOCATED) {
            const errorPage = S3P_SETTINGS.s3Passenger.features.errorPage
            error.redirectUrl = `/${_t.getLocales()}${errorPage}`
        }
    }

})
