import React, {Component} from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import _t from '../../translate'
import Button from '../../element/button'
import TextLabel from '../../element/text-label'
import Icon from '../../element/icon'
import BookingModel from '../../models/booking-model'
import InteractiveFlashMessage from '../interactive-flash-message'
import BookingInformationContainer from '../booking-information-container'

export default class BookingDetails extends Component {
    static propTypes = {
        booking: PropTypes.instanceOf(BookingModel),
        onContinue: PropTypes.func.isRequired,
        onConfirmAfterSales: PropTypes.func.isRequired,
        onRevertAfterSales: PropTypes.func.isRequired
    }

    _renderBookingStatus (bookingData) {
        if (bookingData.bookingStatus === 'confirmed') {
            return null
        }

        return (
            <span className={classNames('text-label booking-status', bookingData.bookingStatus)}>
                {_t.message(
                    'my-s3-booking.booking-details.sub-header',
                    bookingData
                )}
            </span>
        )
    }

    render () {
        if (!this.props.booking || !this.props.booking.bookingNumber) {
            return null
        }

        const bookingData = {
            bookingNumber: this.props.booking.bookingNumber,
            bookingStatus: this.props.booking.isFullyCancelled() ? 'cancelled'
                : this.props.booking.provisional ? 'provisional'
                    : 'confirmed'
        }

        return (
            <div className='my-s3-booking-details'>
                <div className='panel'>
                    <header>
                        <h2>
                            {_t.message('my-s3-booking.booking-details.header')}
                            {this._renderBookingStatus(bookingData)}
                        </h2>
                        {this.renderContinueButton()}
                        {this.renderAfterSalesButtons()}
                    </header>
                    <div className='content'>
                        <InteractiveFlashMessage />
                        <BookingInformationContainer booking={this.props.booking} />
                    </div>
                </div>
            </div>
        )
    }

    renderContinueButton () {
        return this.props.booking.provisional
            ? <Button
                type='button'
                className='button search primary complete-booking'
                title={_t.getIntlMessage('my-s3-booking.booking-options.continue')}
                disabled={false}
                onClick={this.props.onContinue}
            >
                <Icon className='icon xsmall align-left' type='tick' />
                <TextLabel text={_t.getIntlMessage('my-s3-booking.booking-options.continue')} />
            </Button>
            : null
    }

    renderAfterSalesButtons () {
        return this.props.booking.lastAfterSalesOperation ? (
            <div>
                <Button
                    type='button'
                    className='button search primary continue-after-sales'
                    title={_t.getIntlMessage('my-s3-booking.booking-options.continue-after-sales')}
                    disabled={false}
                    onClick={this.props.onConfirmAfterSales}
                >
                    <Icon className='icon xsmall align-left' type='tick' />
                    <TextLabel text={_t.getIntlMessage('my-s3-booking.booking-options.continue-after-sales')} />
                </Button>
                <Button
                    type='button'
                    className='button search primary revert-after-sales'
                    title={_t.getIntlMessage('my-s3-booking.booking-options.revert-after-sales')}
                    disabled={false}
                    onClick={this.props.onRevertAfterSales}
                >
                    <Icon className='icon xsmall align-left' type='tick' />
                    <TextLabel text={_t.getIntlMessage('my-s3-booking.booking-options.revert-after-sales')} />
                </Button>
            </div>
        ) : null
    }
}
