import kebabCase from 'lodash/kebabCase'
// eslint-disable-next-line import/extensions
import affiliateMapping from '../../data/affiliate-mapping.json'
import AffiliateStore from '../stores/affiliate-store'
import {BLABLACAR, SNCB, VSC} from '../../data/affiliate-constants'
import _t from '../../translate'
import device from '../../device'
import {newOuibusSearchUrl} from '../../misc/new-ouibus-helper'

export const affiliateCodeSelector = () => AffiliateStore.getAffiliateCode()
export const affiliateKeySelector = () => {
    const affiliateCode = affiliateCodeSelector()
    return Object.keys(affiliateMapping).find(key => affiliateMapping[key].includes(affiliateCode)) || kebabCase(affiliateCode)
}

export const isPremiumPartner = () => [SNCB, VSC, 'new-vsc', BLABLACAR].includes(affiliateKeySelector())

export const isAffiliateBlablacarSelector = () => affiliateKeySelector() === BLABLACAR
export const isAffiliateSNCBSelector = () => affiliateKeySelector() === SNCB
export const isAffiliateVSCSelector = () => affiliateKeySelector() === VSC

export const getAffiliateHomepageUrl = () => {
    const messagePath = `affiliate.${affiliateKeySelector()}.${device.isMobile() ? 'homepage-mobile' : 'homepage'}`
    return _t.hasMessage(messagePath)
        ? _t.message(messagePath)
        : newOuibusSearchUrl()
}

export const getBlablaCarHomepageUrl = () => {
    const messagePath = `affiliate.blablacar.${device.isMobile() ? 'homepage-mobile' : 'homepage'}`
    return _t.hasMessage(messagePath)
        ? _t.message(messagePath)
        : newOuibusSearchUrl()
}
