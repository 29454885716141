import React, {Component} from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {isBlablabusDomain} from '../misc/blablabus-helpers'

const blablabusIconsMap = new Map([
    ['arrow-right', 'arrow-right'],
    ['assigned-seat', 'my-seat'],
    ['bus', 'bus-front'],
    ['bus-side', 'bus-side'],
    ['cancel-ticket', 'cancellation'],
    ['clock', 'clock'],
    ['disability', 'disabled-passenger'],
    ['document', 'booking-number'],
    ['document-pdf', 'ticket'],
    ['document-search', 'summary'],
    ['edit', 'modify'],
    ['eye', 'see-seats'],
    ['fleche-left', 'inbound-arrow'],
    ['fleche-right', 'outbound-arrow'],
    ['half-arrow-left', 'inbound-arrow'],
    ['half-arrow-right', 'outbound-arrow'],
    ['help', 'help'],
    ['info', 'info'],
    ['invoice-confirmation', 'invoice'],
    ['loader', 'loader'],
    ['button-loader', 'button-loader'],
    ['map-marker', 'itinerary'],
    ['passenger', 'my-seat'],
    ['passengers', 'passenger'],
    ['rebook', 'rebooking'],
    ['seat-selector', 'my-seat'],
    ['ticket', 'my-booking'],
    ['trash', 'delete']
])

export default class Icon extends Component {
    static propTypes = {
        type: PropTypes.string.isRequired,
        className: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.array
        ])
    }

    static defaultProps = {
        svgMap: 'icon'
    }

    constructor (...args) {
        super(...args)
        this.getBlablabusIcon = this.getBlablabusIcon.bind(this)
    }

    getBlablabusIcon (defaultType) {
        const type = blablabusIconsMap.get(defaultType)
        if (type) {
            return require(`../../assets/img/icons-blablabus/${type}.svg`).default
        } else {
            return require(`../../assets/img/icon/${this.props.type}.svg`).default
        }
    }

    render () {
        // eslint-disable-next-line no-unused-expressions
        isBlablabusDomain() ? this.getBlablabusIcon(this.props.type) : require(`../../assets/img/icon/${this.props.type}.svg`).default
        let {className, ...other} = this.props
        delete other.svgMap
        const type = (isBlablabusDomain() && blablabusIconsMap.get(this.props.type)) || this.props.type
        className = classNames('icon', type, className)
        return <svg
            {...other}
            className={classNames('icon', `icon--${type}`, className)}
            aria-labelledby={`icon-${type}-title`}
            role='img'
            key={type}
        >
            <title id={`icon-${type}-title`}>{type}</title>
            <use pointerEvents='none' xlinkHref={`#${type}`} />
        </svg>
    }
}
