import Reflux from 'reflux'

export default Reflux.createStore({

    init () {
        this._resetData()
    },

    getInitialState () {
        return this.data
    },

    _resetData () {
        this.data = {
            disabledRoutes: []
        }
    },
    resetData () {
        this._resetData()
        this.trigger(this.data)
    },

    removeOptionsRoute (route) {
        this.data = {
            disabledRoutes: [route]
        }
        this.trigger(this.data)
    }
})
