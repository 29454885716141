import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from '../../../translate'
import groupBy from 'lodash/groupBy'
import TextLabel from '../../../element/text-label'
import SegmentCollection from '../../../models/segment-collection'
import Icon from '../../../element/icon'
import Button from '../../../element/button'
import ModalDialog from '../../../element/modal-dialog'
import {
    isSerie,
    isMovie,
    getQuantityByProductCode
} from '../../../data/entertainment-constants'
import {
    isSpecialLuggage,
    isNormalLuggage
} from '../../../data/luggage-constants'
import {
    isFlexSegmentCollection,
    getFlexUntilDate
} from '../../../misc/flex-helper'
import CmsBlockContent from '../../cms/cms-block-content'
import {SERVICE_TYPE_MODALITY_TER} from '../../../constants'
import sumBy from 'lodash/sumBy'
import {
    connectState,
    getState
} from '../../../reflux/bridge/connect-state'
import {fareInsuranceSelector} from '../../../models/selectors/components/booking/insurance'
import ProductsStore from '../../../reflux/stores/products-store'
import {aftersalesRuleSelector} from '../../../models/selectors/api/v2/booking/products'
import moment from 'moment-timezone'
import {isBlablabusDomain} from '../../../misc/blablabus-helpers'

class TotalPrice extends Component {
    static propTypes = {
        totalPrice: PropTypes.number.isRequired,
        passengers: PropTypes.array.isRequired,
        outboundSegments: PropTypes.instanceOf(SegmentCollection).isRequired,
        inboundSegments: PropTypes.instanceOf(SegmentCollection),
        fees: PropTypes.number,
        insuranceSelected: PropTypes.bool,
        insuranceProduct: PropTypes.shape({
            price: PropTypes.number.isRequired
        }),
        insuranceFare: PropTypes.shape({
            price: PropTypes.number.isRequired
        }),
        isNelProduct: PropTypes.bool,
        isAlsaProduct: PropTypes.bool,
        cancelAfterSalesRule: PropTypes.shape({
            valid_until: PropTypes.string
        })
    }

    constructor (props) {
        super(props)
        this.state = {modalVisible: false}

        this.toggleVisibility = this.toggleVisibility.bind(this)
        this._renderDetailsModal = this._renderDetailsModal.bind(this)
        this._renderFlexConditions = this._renderFlexConditions.bind(this)
        this._getTotalPrice = this._getTotalPrice.bind(this)
        this._renderPassengers = this._renderPassengers.bind(this)
        this._renderInsuranceProductsTotalPrice = this._renderInsuranceProductsTotalPrice.bind(this)
        this._totalPrice = this._totalPrice.bind(this)
    }

    componentWillUnmount () {
        this.setState({modalVisible: false})
    }

    toggleVisibility () {
        this.setState({modalVisible: !this.state.modalVisible})
    }

    _totalPrice () {
        const {insuranceFare, insuranceSelected, totalPrice} = this.props
        return totalPrice + ((insuranceSelected && insuranceFare && insuranceFare.price) || 0.0)
    }

    render () {
        const {passengers} = this.props

        return (
            <div className='journey-total-price'>
                <span className='text-label journey-total-price-description'>
                    <TextLabel
                        text={_t.formatIntlMessage('booking-specification.total-description',
                            {number: passengers.length})
                        }
                    />
                </span>
                <span className='text-label journey-total-price-value'>
                    <TextLabel text={_t.formatCurrency(this._totalPrice())} />
                    <Button
                        type='button' className='button booking-specification-details clear'
                        onClick={() => this.toggleVisibility()}>
                        <Icon className='small align-right' type='info' />
                    </Button>
                </span>
                {this.state.modalVisible ? this._renderDetailsModal() : null}
            </div>
        )
    }

    _renderDetailsModal () {
        const gridClass = this.props.inboundSegments.hasSegments ? 'grid-column--medium-1-2' : ''

        return (
            <ModalDialog
                headerTitle={_t.formatIntlMessage('booking-specification.modal-header')}
                onClose={this.toggleVisibility}
            >
                <div className='content'>
                    <div className='details'>
                        <div className='grid-row'>
                            <div className={`grid-column--1-1 ${gridClass}`}>
                                <div className='outbound'>
                                    <div className='journey-details-direction-travel-date'>
                                        <div className='text-label journey-details-direction'>
                                            <TextLabel
                                                text={_t.getIntlMessage('booking-specification.outbound')} />
                                        </div>
                                        <div className='text-label journey-details-travel-date'>
                                            <TextLabel text={this.props.outboundSegments.travelDate} />
                                        </div>
                                    </div>
                                    {this._renderPassengers(this.props.outboundSegments)}
                                    {this._renderSeatSelectionProducts(this.props.outboundSegments)}
                                    {this._renderAdditionalProducts(this.props.outboundSegments, 'outbound')}
                                    {this._getTotalPrice(this.props.outboundSegments)}
                                    {this._renderFlexConditions(this.props.outboundSegments)}
                                </div>
                            </div>
                            {this.props.inboundSegments.hasSegments
                                ? (
                                    <div className='grid-column--1-1 grid-column--medium-1-2 grid-inbound'>
                                        <div className='inbound'>
                                            <div className='journey-details-direction-travel-date'>
                                                <div className='text-label journey-details-direction'>
                                                    <TextLabel
                                                        text={_t.getIntlMessage('booking-specification.inbound')} />
                                                </div>
                                                <div className='text-label journey-details-travel-date'>
                                                    <TextLabel text={this.props.inboundSegments.travelDate} />
                                                </div>
                                            </div>
                                            {this._renderPassengers(this.props.inboundSegments)}
                                            {this._renderSeatSelectionProducts(this.props.inboundSegments)}
                                            {this._renderAdditionalProducts(this.props.inboundSegments, 'inbound')}
                                            {this._getTotalPrice(this.props.inboundSegments)}
                                            {this._renderFlexConditions(this.props.inboundSegments)}
                                        </div>
                                    </div>
                                ) : null}
                        </div>
                    </div>
                    {isBlablabusDomain() ? this._renderBlablabusTotalPrice() : this._renderInsuranceProductsTotalPrice(this.props.insuranceProduct)}
                </div>
            </ModalDialog>
        )
    }

    _renderFlexConditions (segments) {
        const isFlex = isFlexSegmentCollection(segments, this.props.passengers.length)
        const hasTer = segments.hasSegmentsWithModality(SERVICE_TYPE_MODALITY_TER)
        let name = 'BOOKING-SS-UNFLEX-MARKET'
        if (this.props.isNelProduct || this.props.isAlsaProduct) {
            name = 'BOOKING-STATIC-STATIC_2-MARKET'
        } else if (isFlex) {
            name = 'BOOKING-SS-FLEX-CONDITIONS'
        }
        const flexDateTime = getFlexUntilDate(segments.departureDateTime, this.props.passengers.length)
        const cancelDateTime = this.props.cancelAfterSalesRule &&
            this.props.cancelAfterSalesRule.valid_until &&
            moment(this.props.cancelAfterSalesRule.valid_until)
        const momentDateTime = isFlex ? flexDateTime : cancelDateTime
        const dateTime = momentDateTime ? momentDateTime.format('LLL') : ''
        const date = momentDateTime ? momentDateTime.format('LL') : ''
        const time = momentDateTime ? momentDateTime.format('LT') : ''

        return (
            <div className='detail-row flex-conditions'>
                <div className='grid-row no-gutter'>
                    <div className='grid-column--3-3'>
                        <CmsBlockContent name={name} values={{dateTime, date, time}} />
                        {hasTer ? <TextLabel text={_t.message('booking-specification.ter-description')} /> : null}
                    </div>
                </div>
            </div>
        )
    }

    _getTotalPrice (segments) {
        const segmentsTotalPrice = segments.insuranceProduct
            ? segments.totalPrice - segments.insuranceProduct.price
            : segments.totalPrice
        return (
            <div className='journey-total-price'>
                <span className='text-label journey-total-price-description'>
                    <TextLabel
                        text={_t.formatIntlMessage('booking-specification.total-description',
                            {number: this.props.passengers.length})
                        }
                    />
                </span>
                <span className='text-label journey-total-price-value'>
                    <TextLabel text={_t.formatCurrency(segmentsTotalPrice)} />
                </span>
            </div>
        )
    }

    _renderPassengers (segments) {
        const productsByPassenger = groupBy(segments.requiredProducts, 'passenger_id')

        return this.props.passengers.map((passenger, index) => {
            const typeTranslated = _t.message(`passenger-types.${passenger.passengerType.toLowerCase()}`)
            const totalPrice = (productsByPassenger[passenger.id] || []).reduce(
                (sum, product) => {
                    const discountAmount = product.discounts.reduce(
                        (_sum, discount) => {
                            if (discount.type === 'V') {
                                _sum = _sum + discount.amount
                            }

                            return _sum
                        },
                        0.0
                    )

                    return sum + product.price + discountAmount
                }, 0.0)

            const title = _t.formatIntlMessage('booking-specification.details.passenger.title', {
                index: index + 1,
                type: typeTranslated
            })

            return (
                <div className='detail-row' key={passenger.id}>
                    <div className='grid-row no-gutter'>
                        <div className='grid-column--2-3'>
                            <span className='text-label passenger-type overflow-ellipsis' title={title}>
                                <TextLabel text={typeTranslated} />
                            </span>
                        </div>
                        <div className='grid-column--1-3'>
                            <span className='text-label passenger-type-price'>
                                <TextLabel text={_t.formatCurrency(totalPrice)} />
                            </span>
                        </div>
                    </div>
                </div>
            )
        })
    }

    _renderSeatSelectionProducts (segments) {
        const seatSelectionPrice = segments.seatProducts.reduce((price, product) => {
            price += product.price
            return price
        }, 0.0)

        return seatSelectionPrice > 0 ? (
            <div className='detail-row'>
                <div className='grid-row no-gutter'>
                    <div className='grid-column--2-3'>
                        <span className='text-label seat-selection overflow-ellipsis'>
                            <TextLabel text={_t.getIntlMessage('booking-specification.seat-option')} />
                        </span>
                    </div>
                    <div className='grid-column--1-3'>
                        <span className='text-label seat-selection-price'>
                            <TextLabel text={_t.formatCurrency(seatSelectionPrice)} />
                        </span>
                    </div>
                </div>
            </div>
        ) : null
    }

    _renderInsuranceProductsTotalPrice (insuranceProduct) {
        const {insuranceFare, insuranceSelected} = this.props
        const price = (
            (insuranceProduct && insuranceProduct.price) ||
            (insuranceSelected && insuranceFare && insuranceFare.price) ||
            0.0
        )
        return (
            (insuranceProduct || (insuranceSelected && insuranceFare)) ? (
                <div className='details'>
                    <div className='grid-row'>
                        <div className='grid-column--1-1'>
                            <div className='total-price-insurance'>
                                {insuranceProduct || (insuranceSelected && insuranceFare) ? this._renderInsurance(price) : null}
                                {this._renderTotalPrice()}
                            </div>
                        </div>
                    </div>
                </div>) : null
        )
    }

    _renderTotalPrice () {
        return (
            <div className='journey-total-price'>
                <div className='grid-row no-gutter'>
                    <div className='grid-column--2-3 grid-column--medium-5-6'>
                        <span className='text-label journey-total-price-label overflow-ellipsis'>
                            <TextLabel text={_t.message('booking-specification.total-price')} />
                        </span>
                    </div>
                    <div className='grid-column--1-3 grid-column--medium-1-6'>
                        <span className='text-label journey-total-price-value'>
                            <TextLabel text={_t.formatCurrency(this._totalPrice())} />
                        </span>
                    </div>
                </div>
            </div>
        )
    }

    _renderBlablabusTotalPrice () {
        return (
            <div className='details'>
                <div className='grid-row'>
                    <div className='grid-column--1-1'>
                        <div className='journey-total-price journey-total-price-all'>
                            <div className='grid-row no-gutter'>
                                <div className='grid-column--2-3 grid-column--medium-5-6'>
                                    <span className='text-label journey-total-price-label overflow-ellipsis'>
                                        <TextLabel text={_t.message('booking-specification.total-price')} />
                                    </span>
                                </div>
                                <div className='grid-column--1-3 grid-column--medium-1-6'>
                                    <span className='text-label journey-total-price-value'>
                                        <TextLabel text={_t.formatCurrency(this._totalPrice())} />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    _renderInsurance (price) {
        return (
            <div className='insurance'>
                <div className='grid-row no-gutter'>
                    <div className='grid-column--2-3 grid-column--medium-5-6'>
                        <span className='text-label insurance-price-label overflow-ellipsis'>
                            <TextLabel text={_t.message('booking-specification.insurance-option')} />
                        </span>
                    </div>
                    <div className='grid-column--1-3 grid-column--medium-1-6'>
                        <span className='text-label insurance-price-value'>
                            <TextLabel text={_t.formatCurrency(price)} />
                        </span>
                    </div>
                </div>
            </div>
        )
    }

    _renderAdditionalProducts (segments, direction) {
        const getSpecByFilter = (products, filter) => {
            const filteredProducts = products.filter(filter)
            return {
                totalPrice: sumBy(filteredProducts, 'price'),
                quantity: filteredProducts.length
            }
        }

        const isNormalBike = product => (product.product_code || product.code) === 'ADD_BIKE'

        const getSerieMovieSpecByFilter = (products, filter) => {
            const filteredProducts = products.filter(filter)
            return {
                totalPrice: sumBy(filteredProducts, 'price'),
                quantity: filteredProducts.reduce((total, product) => total + getQuantityByProductCode(product), 0)
            }
        }

        const addSpec = (oldSpec, addedSpec) => ({
            ...oldSpec,
            totalPrice: oldSpec.totalPrice + addedSpec.totalPrice,
            quantity: Math.max(oldSpec.quantity, addedSpec.quantity)
        })

        const bags = segments.getRawData().reduce(
            (bags, segment) => {
                const products = (segment.additional_products || []).filter(product => !product.cancelled)

                bags[0] = addSpec(bags[0], getSpecByFilter(products, isNormalLuggage))
                bags[1] = addSpec(bags[1], getSpecByFilter(products, isSpecialLuggage))
                bags[2] = addSpec(bags[2], getSpecByFilter(products, isNormalBike))
                bags[3] = addSpec(bags[3], getSerieMovieSpecByFilter(products, isSerie))
                bags[4] = addSpec(bags[4], getSerieMovieSpecByFilter(products, isMovie))

                return bags
            },
            [
                {type: 'luggage', totalPrice: 0.0, quantity: 0},
                {type: 'special-luggage', totalPrice: 0.0, quantity: 0},
                {type: 'bike', totalPrice: 0.0, quantity: 0},
                {type: 'series', totalPrice: 0.0, quantity: 0},
                {type: 'movies', totalPrice: 0.0, quantity: 0}
            ]
        ).filter(({quantity}) => quantity > 0)

        return bags.length > 0 ? (
            <div className='detail-row' key={`${direction}-bags`}>
                {bags.map(bag => {
                    return (
                        <div className='grid-row no-gutter' key={bag.type}>
                            <div className='grid-column--2-3'>
                                <span className='text-label passenger-type overflow-ellipsis'>
                                    <TextLabel text={_t.formatIntlMessage(
                                        `booking-specification.details.${bag.type}`, {quantity: bag.quantity}
                                    )} />
                                </span>
                            </div>
                            <div className='grid-column--1-3'>
                                <span className='text-label passenger-type-price'>
                                    <TextLabel text={_t.formatCurrency(bag.totalPrice)} />
                                </span>
                            </div>
                        </div>
                    )
                })}
            </div>
        ) : null
    }

    _renderApplicableFee (fees) {
        return fees > 0
            ? (
                <div className='applicable-fee'>
                    <div className='grid-row no gutter'>
                        <div className='grid-column--1-2 grid-column--medium-2-3'>
                            <span className='text-label booking-specification-description'>
                                <Icon type='plus' className='medium align-left' />
                                <TextLabel text={_t.getIntlMessage('booking-specification.applicable-fee')} />
                            </span>
                        </div>
                        <div className='grid-column--1-2 grid-column--medium-1-3'>
                            <span className='text-label booking-specification-value'>
                                <TextLabel text={_t.formatCurrency(fees)} />
                            </span>
                        </div>
                    </div>
                </div>
            ) : null
    }
}

const mapPropsToProps = () => {
    const state = getState()
    return {
        insuranceFare: fareInsuranceSelector(state),
        cancelAfterSalesRule: aftersalesRuleSelector('CANCEL')(state)
    }
}

export default connectState(mapPropsToProps, [ProductsStore])(TotalPrice)

