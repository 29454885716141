/* globals S3P_SETTINGS: true */

import React from 'react'
import closeByClickOutside from '../../element/close-by-click-outside'
import ModalDialog from '../../element/modal-dialog'
import device from '../../device'

const Dialog = closeByClickOutside('modal-panel')(ModalDialog)

// eslint-disable-next-line react/display-name
export default Component => class extends React.Component {
    constructor (...args) {
        super(...args)

        this.onShowModal = this.onShowModal.bind(this)
        this.onHideModal = this.onHideModal.bind(this)

        this.state = {showModal: false}
    }

    onShowModal (event) {
        if (event) {
            event.preventDefault()
            event.stopPropagation()
        }

        this._scriptElement = document.createElement('script')
        this._scriptElement.type = 'text/javascript'
        this._scriptElement.src = device.isMobile()
            ? S3P_SETTINGS.inbenta.mobileUrl : S3P_SETTINGS.inbenta.desktopUrl

        document.getElementsByTagName('head')[0].appendChild(this._scriptElement)

        this.setState({showModal: true})
    }

    onHideModal (event) {
        if (event) {
            event.preventDefault()
            event.stopPropagation()
        }
        document.getElementsByTagName('head')[0].removeChild(this._scriptElement)
        this.setState({showModal: false})
    }

    render () {
        return (
            <react-wrapper class='react-wrapper'>
                <Component
                    {...this.props}
                    onShowHelpDialog={this.onShowModal}
                    onHideHelpDialog={this.onHideModal}
                />
                {this.state.showModal ? (
                    <Dialog
                        onClose={this.onHideModal}
                        onHideElement={this.onHideModal}
                        headerTitle=''
                        name='help-dialog'
                    >
                        <div ref={element => {
                            this._element = element
                        }}
                             dangerouslySetInnerHTML={{__html: '<div id="inbentaPage"></div>'}}
                        />
                    </Dialog>
                ) : null}
            </react-wrapper>
        )
    }
}

