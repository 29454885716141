import PropTypes from 'prop-types'
import React from 'react'
import Reflux from 'reflux'
import {showLuggageAddOn} from '../../misc/blablabus-helpers'
import BookingModel from '../../models/booking-model'
import {hasLuggagePayingOptionsSelector} from '../../models/selectors/components/orientation/journey-search'
import BookingStore from '../../reflux/stores/booking-store'
import createReactClass from 'create-react-class'
import LuggageSummary from '../luggage-options/luggage-summary'
import LuggageSelection from '../luggage-options/luggage-selection'
import {getState} from '../../reflux/bridge/connect-state'
import {luggageTotalPricePerPassengerPerDirectionSelector} from '../../models/selectors/components/booking/luggage-selection'
import OfferStore from '../../reflux/stores/offer-store'
import MyS3LuggageSummary from '../mys3/luggage/luggage-summary'

export default createReactClass({
    displayName: 'LuggageOptions',

    mixins: [
        Reflux.listenTo(OfferStore, 'updateOffer'),
        Reflux.connectFilter(BookingStore, 'booking', data => BookingModel.create(data.booking))
    ],

    propTypes: {
        luggageSelectorRoute: PropTypes.any,
        myS3Options: PropTypes.bool
    },

    componentWillMount () {
        this.updateOffer()
    },

    updateOffer () {
        const luggagePricePerDirectionPerPassenger = luggageTotalPricePerPassengerPerDirectionSelector(getState())
        if (Object.values(luggagePricePerDirectionPerPassenger).length > 0) {
            const minPrice = Object.values(luggagePricePerDirectionPerPassenger).reduce(
                (minPrice, direction) => Object.values(direction).reduce((min, price) => price < min ? price : min, minPrice),
                Number.MAX_VALUE
            )

            this.setState({price: minPrice})
        }
    },

    render () {
        const hasLuggagePayingOptions = showLuggageAddOn() && hasLuggagePayingOptionsSelector(getState())
        return hasLuggagePayingOptions ? this.renderLuggageOptions() : null
    },

    renderLuggageOptions () {
        const hasSummaryLuggage = this.state.booking.tariffSegmentCollection.hasSummaryLuggageProducts
        const LuggageSummaryComponent = this.props.myS3Options ? MyS3LuggageSummary : LuggageSummary
        return (
            <div className='row'>
                <div className='luggage-options'>
                    {
                        hasSummaryLuggage
                            ? <LuggageSummaryComponent luggageSelectorRoute={this.props.luggageSelectorRoute} />
                            : <LuggageSelection luggageSelectorRoute={this.props.luggageSelectorRoute} price={this.state.price} />
                    }
                </div>
            </div>
        )
    }
})
