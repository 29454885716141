import PropTypes from 'prop-types'
import React from 'react'
import InputField from './input-field'
import _t from '../../translate'
import PureRenderMixin from 'react-addons-pure-render-mixin'
import createReactClass from 'create-react-class'

export default createReactClass({
    displayName: 'SelectField',

    mixins: [PureRenderMixin],

    propTypes: {
        data: PropTypes.array.isRequired,
        onChange: PropTypes.func
    },

    render () {
        let {data, ...props} = this.props
        /* eslint-disable react/prop-types */
        delete props.type
        delete props.onChange
        /* eslint-enable react/prop-types */

        data = data.map(item => {
            let text
            try {
                text = _t.getIntlMessage(item.text)
            } catch (err) {
                text = item.text
            }

            return {text: text || item.text, value: item.value, disabled: item.disabled || false}
        })

        return <InputField ref='inputField' type='select' data={data} onChange={this.onChange} {...props} />
    },

    onChange: function (_, event) {
        if (typeof this.props.onChange === 'function') {
            this.props.onChange(event)
        }
    },

    getInputElement () {
        return this.refs.inputField.getInputElement()
    },

    getValue () {
        return this.refs.inputField.getValue()
    },

    setValue (value) {
        return this.refs.inputField.setValue(value)
    },

    focus () {
        return this.refs.inputField.focus()
    }

})
