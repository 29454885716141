import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from '../translate'
import Button from '../element/button'
import Icon from '../element/icon'
import TextLabel from '../element/text-label'
import containHelpDialog from '../layouts/generic-layout/help-dialog-container'
import {isAffiliateBlablacarSelector} from '../reflux/bridge/affiliate'
import {isBlablabusDomain} from '../misc/blablabus-helpers'

class HelpButton extends Component {
    static propTypes = {
        onShowHelpDialog: PropTypes.func
    }

    render () {
        if (isBlablabusDomain() || isAffiliateBlablacarSelector()) {
            return null
        }

        return (
            <Button type='button' className='button journey-search-help' onClick={this.props.onShowHelpDialog}>
                <TextLabel text={_t.getIntlMessage('help-button.label')} />
                <Icon className='icon large align-right' type='help' />
            </Button>
        )
    }
}

export default containHelpDialog(HelpButton)
