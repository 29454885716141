import { createSelector } from 'reselect'
import { journeySearchSelector } from './journey-search'
import { camelCaseKeys } from '../../../../../misc/camelcase'

export const passengersSelector = createSelector(
  [journeySearchSelector],
  journeySearch => journeySearch ? journeySearch.passengers.map((passenger, index) => ({
    ...camelCaseKeys(passenger),
    number: index + 1
  })) : []
)
