import React, {Component} from 'react'
import PropTypes from 'prop-types'
import _t from '../../../../translate'
import FormattedMessage from '../../../../translate/formatted-message'
import Heading from '../../../../element/heading'
import moment from 'moment-timezone'

export default class PassengerDetailsSummary extends Component {
    static propTypes = {
        title: PropTypes.string.isRequired,
        passengers: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string.isRequired,
                firstName: PropTypes.string.isRequired,
                lastName: PropTypes.string.isRequired,
                dateOfBirth: PropTypes.string
            })
        ).isRequired
    }

    render () {
        return (
            <div className='content'>
                <Heading title={this.props.title} />
                <div className='summary-list'>
                    <ul>
                        {this.props.passengers.map(passenger => {
                            const dateOfBirth = moment(passenger.dateOfBirth).format('L')
                            return (
                                <li key={passenger.id}>
                                    <FormattedMessage
                                        message={_t.getIntlMessage('passenger-details-summary-form.name')}
                                        firstName={<span className='value'>{passenger.firstName}</span>}
                                        lastName={<span className='value'>{passenger.lastName}</span>}
                                        dateOfBirth={<span className='value'>{dateOfBirth}</span>}
                                    />
                                </li>
                            )
                        }
                        )}
                    </ul>
                </div>
            </div>
        )
    }
}
