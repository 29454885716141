// Libraries
import React from 'react'
import PropTypes from 'prop-types'
import Reflux from 'reflux'
import {withRouter} from 'react-router'
import _t from '../translate'
import createReactClass from 'create-react-class'

// Components
import MyS3UpdatePassengersPaymentLayoutContainer from '../layouts/my-s3-update-passengers-payment-layout-container'

// Actions
import actions from '../reflux/actions'

// Stores
import BookingStore from '../reflux/stores/booking-store'
import restoreBooking from '../components/restore/restore-booking'
import BookingModel from '../models/booking-model'

export default withRouter(restoreBooking(createReactClass({

    displayName: 'MyS3UpdatePassengersPaymentLayout',

    propTypes: {
        router: PropTypes.object
    },

    mixins: [
        Reflux.connectFilter(BookingStore, 'booking', data => BookingModel.create(data.booking))
    ],

    componentDidMount () {
        actions.setPaymentReturnUrl(`/${_t.getLocales()}/mys3/update-passengers/confirm`)
    },

    onPrev () {
        actions.revertBooking(this.state.booking.bookingNumber)
        this.props.router.push(`/${_t.getLocales()}/mys3/update-passengers`)
    },

    onConfirm () {
        actions.confirmBooking(this.state.booking.bookingNumber)
    },

    onConfirmed () {
        this.props.router.push(`/${_t.getLocales()}/mys3/update-passengers/confirm?s3_status=success`)
    },

    render () {
        return (
            <MyS3UpdatePassengersPaymentLayoutContainer
                booking={this.state.booking}
                onPrev={this.onPrev}
                onConfirm={this.onConfirm}
                onConfirmed={this.onConfirmed}
            />
        )
    }

}), {withBlockingLoader: true}))
