import React, {Component} from 'react'
import PropTypes from 'prop-types'
import _t from '../../../translate'
import TextLabel from '../../../element/text-label'

export default class VoucherItem extends Component {
    static propTypes = {
        label: PropTypes.string.isRequired,
        price: PropTypes.number.isRequired
    }

    render () {
        return (
            <div className='voucher-discount'>
                <div className='grid-row'>
                    <div className='grid-column--1-2 grid-column--medium-3-4'>
                        <span className='text-label voucher-description'>
                            <TextLabel text={this.props.label} />
                        </span>
                    </div>
                    <div className='grid-column--1-2 grid-column--medium-1-4'>
                        <span className='text-label voucher-value'>
                            <TextLabel text={`-${_t.formatCurrency(this.props.price)}`} />
                        </span>
                    </div>
                </div>
            </div>
        )
    }
}
