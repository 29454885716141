import React, {Component} from 'react'
import PropTypes from 'prop-types'
import _t from '../translate'
import classNames from 'classnames'

import Icon from './icon'
import TextLabel from './text-label'
import Button from './button'

import FlashMessageStore from '../reflux/stores/flash-message-store'

export default class FlashMessage extends Component {
    static propTypes = {
        text: PropTypes.string.isRequired,
        severity: PropTypes.oneOf([
            FlashMessageStore.severity.success,
            FlashMessageStore.severity.info,
            FlashMessageStore.severity.highlight,
            FlashMessageStore.severity.warning,
            FlashMessageStore.severity.error
        ]),
        onClose: PropTypes.func
    }

    static defaultProps =
        {severity: FlashMessageStore.severity.success}

    render () {
        return (
            <div className={classNames('feedback', this.props.severity)}>
                <span className='text-label'>
                    <Icon type={this.props.severity} className='small align-left' />
                    <TextLabel text={this.props.text} />
                </span>
                {this.props.onClose ? (
                    <Button
                        type='button'
                        className='button secondary close'
                        title={_t.getIntlMessage('flash-message.close')}
                        onClick={this.props.onClose}
                    >
                        <Icon type='cross' className='medium' />
                        <TextLabel text={_t.getIntlMessage('flash-message.close')} />
                    </Button>
                ) : null}
            </div>
        )
    }
}
