// libraries
import React, {Component} from 'react'
import PropTypes from 'prop-types'
import _t from '../../translate'
import SelectBundleStore from '../../reflux/stores/select-bundle-store'
import TextLabel from '../../element/text-label'
import {connectState, getState} from '../../reflux/bridge/connect-state'
import {totalPriceSelectedBundlesSelector} from '../../models/selectors/components/orientation/journey-search'

class Price extends Component {
    static propTypes = {
        totalPrice: PropTypes.number
    }

    render () {
        const totalPrice = this.props.totalPrice
        if (totalPrice === 0) {
            return null
        }

        return (
            <div className='price-summary'>
                <div className='panel plain'>
                    <div className='content'>
                        <div className='price-summary-row'>
                            <div className='grid-row no-gutter'>
                                <div className='grid-column--2-3 grid-column--medium-4-5'>
                                    <span className='text-label price-description'>
                                        <TextLabel
                                            text={_t.getIntlMessage('journey-result-set.price-summary.total-price')}
                                        />
                                    </span>
                                </div>
                                <div className='grid-column--1-3 grid-column--medium-1-5'>
                                    <span className='text-label price-amount'>
                                        <TextLabel text={_t.formatCurrency(totalPrice)} />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapPropsToProps = () => ({
    totalPrice: totalPriceSelectedBundlesSelector(getState())
})

export default connectState(mapPropsToProps, [SelectBundleStore])(Price)
