import React, {Component} from 'react'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'

export default class TriStateCheckBox extends Component {
    static propTypes = {
        state: PropTypes.oneOf([-1, 0, 1]).isRequired
    }

    componentDidMount () {
        this.handleCheckboxState(this.props)
    }

    componentWillReceiveProps (nextProps) {
        this.handleCheckboxState(nextProps)
    }

    handleCheckboxState (props) {
        const domElement = ReactDOM.findDOMNode(this.refs.triStateCheckbox)
        switch (props.state) {
            case 1:
                domElement.indeterminate = false
                domElement.checked = true
                break
            case 0:
                domElement.indeterminate = true
                domElement.checked = false
                break
            default:
                domElement.indeterminate = false
                domElement.checked = false
        }
    }

    render () {
        const props = {...this.props}
        delete props.type
        delete props.state

        return (
            <input type='checkbox' ref='triStateCheckbox' {...props} />
        )
    }
}
