import React from 'react'
import Reflux from 'reflux'
import createReactClass from 'create-react-class'

export const getState = () => Math.random()

export const connectState = (mapPropsToProps, listenToStores = []) => Component => createReactClass({

    displayName: 'ConnectState',

    mixins: listenToStores.map(store => Reflux.listenTo(store, 'onUpdateState')),

    getInitialState () {
        return {}
    },

    reUpdateState (props) {
        this.setState(mapPropsToProps(props))
    },

    onUpdateState () {
        this.reUpdateState(this.props)
    },

    componentWillReceiveProps (nextProps) {
        if (this.props !== nextProps) {
            this.reUpdateState(nextProps)
        }
    },

    componentWillMount () {
        this.reUpdateState(this.props)
    },

    render () {
        return <Component {...this.props} {...this.state} />
    }

})
