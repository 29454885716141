import React, {Component} from 'react'
import TextLabel from '../../element/text-label'
import _t from '../../translate'
import _ from 'lodash'
import Icon from '../../element/icon'
import PropTypes from 'prop-types'

export default class SegmentFees extends Component {
    static propTypes = {
        fees: PropTypes.arrayOf(PropTypes.shape({
            price: PropTypes.number.isRequired
        })).isRequired,
        paid: PropTypes.bool.isRequired
    }

    render () {
        const fees = _(this.props.fees)
        if (fees.isEmpty()) {
            return null
        }

        const label = 'booking-information.segment-fees-' + (this.props.paid ? 'paid' : 'to-be-paid')
        const iconType = this.props.paid ? 'tick' : 'minus-circle'
        const className = 'after-sales-fees' + (this.props.paid ? '-paid' : '')

        return (
            <tr className={className}>
                <td colSpan={2}>
                    <span className='text-label after-sales-fees-description'>
                        <Icon type={iconType} className='xsmall align-left' />
                        <TextLabel text={_t.getIntlMessage(label)} />
                    </span>
                </td>
                <td>
                    <span className='text-label after-sales-fees-price'>
                        <TextLabel text={_t.formatCurrency(fees.sumBy('price'))} />
                    </span>
                </td>
            </tr>
        )
    }
}
