import PropTypes from 'prop-types'
import React, {Component} from 'react'
import device from '../device'
import _t from '../translate'
import GenericLayout from './generic-layout'
import CancellationResults from '../components/my-s3-cancellation-confirm/cancellation-results'
import CancellationSpecification from '../components/my-s3-cancellation-confirm/cancellation-specification'
import Row from '../element/row'
import ProgressNavigation from '../base/main/progress-navigation-container'
import Help from '../components/my-s3-booking/help'
import AfterSalesNavigation from '../base/after-sales-navigation'
import AfterSalesSummary from '../components/after-sales-summary'
import cmsComponent from '../components/cms/cms-component'
import BookingModel from '../models/booking-model'

class MyS3CancellationConfirmLayoutContainer extends Component {
    static propTypes = {
        booking: PropTypes.instanceOf(BookingModel).isRequired,
        loading: PropTypes.bool.isRequired,
        onClickConfirm: PropTypes.func.isRequired
    }

    render () {
        const progressNavigationProps = {
            previousButtonProps: {
                className: 'button previous default',
                icon: {
                    type: 'chevron-left',
                    className: 'xsmall align-left'
                }
            },
            nextButtonProps: {
                className: 'button next primary',
                icon: {
                    type: 'chevron-right',
                    className: 'xsmall align-right'
                },
                text: _t.getIntlMessage('my-s3-cancellation.confirm.button'),
                onClick: this.props.onClickConfirm,
                loading: this.props.loading
            }
        }

        return (
            <GenericLayout>
                <AfterSalesNavigation />
                <Row className='row contains-cancellation'>
                    <div className='grid-row'>
                        <div className='grid-column--1-1 grid-column--medium-1-3'>
                            <AfterSalesSummary booking={this.props.booking} />
                            <CancellationResults />
                            {device.isDesktop() ? <Help /> : null}
                        </div>
                        <div className='grid-column--1-1 grid-column--medium-2-3'>
                            <div className='my-s3-cancellation-specification'>
                                <CancellationSpecification booking={this.props.booking} />
                            </div>
                            <ProgressNavigation {...progressNavigationProps} />
                            {!device.isDesktop() ? <Help /> : null}
                        </div>
                    </div>
                </Row>
            </GenericLayout>
        )
    }
}

export default cmsComponent('cancellation-confirm-description')(MyS3CancellationConfirmLayoutContainer)
