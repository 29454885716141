import React from 'react'
import {Route} from 'react-router'
import authCheck from '../../mixins/auth'
import BookingConfirmationLayout from '../../layouts/booking/confirmation'

export default (
    <Route
        path='/:lang/booking/confirmation'
        component={BookingConfirmationLayout}
        onEnter={(nextState, replaceState, callback) => authCheck(nextState, replaceState, callback)}
    />
)
