import React, {Component} from 'react'
import _t from '../../translate'
import TextLabel from '../../element/text-label'

export default class NoResults extends Component {
    render () {
        return (
            <div className='no-result'>
                <div className='feedback warning'>
                    <span className='text-label feedback-message'>
                        <TextLabel text={_t.getIntlMessage('my-s3-booking-overview.no-results')} />
                    </span>
                </div>
            </div>
        )
    }
}
