export const createLegId = (serviceName, serviceScheduleDate, originCode, destinationCode) =>
    `${serviceName}|${serviceScheduleDate}|${originCode}|${destinationCode}`

export const deconstructLegId = legId => {
    const legIdArray = legId.split('|')
    return {
        serviceName: legIdArray[0],
        serviceScheduleDate: legIdArray[1],
        originCode: legIdArray[2],
        destinationCode: legIdArray[3]
    }
}
