import PropTypes from 'prop-types'
import React, { Component } from 'react'
import device from '../device'
import GenericLayout from '../layouts/generic-layout'
import Row from '../element/row'
import ProgressBar from '../base/re-booking-progress-bar'
import BookingSpecificationPanel from '../components/booking-specification/booking-panel'
import AfterSalesNavigation from '../base/after-sales-navigation'
import Help from '../components/my-s3-booking/help'
import bookingLayout from './booking-layout'
import BookingModel from '../models/booking-model'

class RebookingLayoutContainer extends Component {
    static propTypes = {
        name: PropTypes.string.isRequired,
        booking: PropTypes.instanceOf(BookingModel).isRequired
    }

    render () {
        return (
            <GenericLayout className={this.props.name}>
                <AfterSalesNavigation />
                <Row className='row contains-progress-bar'>
                    <ProgressBar />
                </Row>
                <Row className={`row contains-${this.props.name}`}>
                    <div className='grid-row'>
                        <div className='grid-column--1-1 grid-column--medium-1-3'>
                            {this.props.booking ? (
                                <BookingSpecificationPanel
                                    afterSales
                                    afterSalesOperation='rebook'
                                    booking={this.props.booking}
                                />
                            ) : null}
                            {!device.isMobile() ? <Help /> : null}
                        </div>
                        <div className='grid-column--1-1 grid-column--medium-2-3'>
                            {this.props.children}
                        </div>
                    </div>
                </Row>
            </GenericLayout>
        )
    }
}

export default bookingLayout(RebookingLayoutContainer)
