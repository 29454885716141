import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {restoreBookingCompose} from '../../../components/restore/restore-booking'
import {restoreJourneySearchCompose} from '../../../components/restore/restore-journey-search'
import compose from '../../../misc/compose'
import InteractiveFlashMessage from '../../../components/interactive-flash-message'
import MyS3OptionsLayoutContainer from '../../my-s3-options-layout-container'
import BikeSelector from '../../../components/bike-selector/bike-selector'

class BikeSelectorContainer extends Component {
    static propTypes = {
        location: PropTypes.object
    }

    render () {
        return (
            <MyS3OptionsLayoutContainer name='luggage-selection-container'>
                <InteractiveFlashMessage />
                <BikeSelector returnUrl='/mys3/bike/payment' />
            </MyS3OptionsLayoutContainer>
        )
    }
}

const container = compose(
    restoreJourneySearchCompose({withBlockingLoader: true}),
    restoreBookingCompose({withBlockingLoader: true})
)

export default container(BikeSelectorContainer)
