// Libraries
import React from 'react'
import createReactClass from 'create-react-class'

// Components
import FooterCommon from './footer-common'
import cmsComponent from '../../components/cms/cms-component'
import CmsBlockContent from '../../components/cms/cms-block-content'

export default cmsComponent('footer-vsc-new')(createReactClass({

    displayName: 'LayoutFooterVSCNew',

    render () {
        return (
            <FooterCommon {...this.props}>
                <CmsBlockContent name={'footer-vsc-new'} />
            </FooterCommon>
        )
    }
}))
