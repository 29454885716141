import React from 'react'
import {Route} from 'react-router'
import authCheck from '../../../mixins/auth'
import LuggageSelectorPaymentLayout from '../../../layouts/mys3/luggage/luggage-payment'

export default (
    <Route
        path='/:lang/mys3/luggage/payment'
        component={LuggageSelectorPaymentLayout}
        onEnter={(nextState, replaceState, callback) => authCheck(nextState, replaceState, callback)}
    />
)
