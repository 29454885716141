import React, {Component} from 'react'
import PropTypes from 'prop-types'
import $ from 'jquery'
import Row from '../../element/row'
import JourneySummaryBar from '../../components/journey-summary-bar'
import cmsComponent from '../../components/cms/cms-component'

const blockNames = [
    'BOOKING-ALL-MKTBLOC-1',
    'BOOKING-ALL-MKTBLOC-2',
    'BOOKING-ALL-MKTBLOC-3'
]

class FooterCommon extends Component {
    static propTypes = {
        cmsContentLoading: PropTypes.bool,
        hasOffer: PropTypes.bool,
        bookingLoading: PropTypes.bool,
        showSummaryBar: PropTypes.bool,
        onNext: PropTypes.func
    }

    componentDidMount () {
        $(window).on('DOMContentLoaded load resize scroll', this.placeSummaryBar)
    }

    componentWillUnmount () {
        $(window).off('DOMContentLoaded load resize scroll', this.placeSummaryBar)
    }

    placeSummaryBar () {
        const journeySummaryBar = $('.contains-journey-summary-bar')
        const anchor = $('footer.main .content')
        const anchorRect = anchor[0].getBoundingClientRect()

        let anchorTop = anchorRect.top
        const isAnchorInViewport = anchorTop <= (window.innerHeight || document.documentElement.clientHeight)

        $('footer.main').css('padding-top', isAnchorInViewport ? 0 : journeySummaryBar.outerHeight())
        journeySummaryBar
            .css('position', isAnchorInViewport ? 'relative' : 'fixed')
            .toggleClass('placed', isAnchorInViewport)
    }

    render () {
        return (
            <footer className='main'>
                <Row className='row contains-journey-summary-bar'>
                    {this.renderSummaryBar()}
                </Row>
                <div className='content'>
                    {this.props.children}
                </div>
            </footer>
        )
    }

    renderSummaryBar () {
        return this.props.showSummaryBar && this.props.hasOffer ? (
            <JourneySummaryBar loading={this.props.bookingLoading} onNext={this.props.onNext} />
        ) : null
    }
}

export default cmsComponent(...blockNames)(FooterCommon)
