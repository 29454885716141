import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Button from './button'

export default class AsyncDownloadButton extends Component {
    static propTypes = {
        downloadUrl: PropTypes.string,
        onRetrieveDownLoadUrlClick: PropTypes.func.isRequired,
        buttonProps: PropTypes.object.isRequired
    }

    constructor (props) {
        super(props)

        this.onRetrieveDownLoadUrlClick = this.onRetrieveDownLoadUrlClick.bind(this)
    }

    componentWillUpdate (nextProps) {
        if (!this.props.downloadUrl && nextProps.downloadUrl) {
            this.refs.linkElement.href = nextProps.downloadUrl
            this.refs.linkElement.click()
        }
    }

    render () {
        const props = {...this.props.buttonProps}
        delete props.type
        delete props.onClick

        return (
            <span>
                <a
                    ref='linkElement'
                    href={this.props.downloadUrl}
                    download
                    target='_blank'
                    rel='noopener noreferrer'
                />
                <Button onClick={this.onRetrieveDownLoadUrlClick} type='button' {...props}>
                    {this.props.children}
                </Button>
            </span>
        )
    }

    onRetrieveDownLoadUrlClick () {
        if (this.props.downloadUrl) {
            this.refs.linkElement.click()
        } else {
            this.props.onRetrieveDownLoadUrlClick()
        }
    }
}
