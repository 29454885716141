import PropTypes from 'prop-types'
import React, {Component} from 'react'
import $ from 'jquery'
import Wrap from '../element/wrap'

export default class Footer extends Component {
    static propTypes = {
        className: PropTypes.string
    }

    static defaultProps = {
        className: 'footer'
    }

    componentDidMount () {
        let placeStickyFooter = function () {
            $('body').css('margin-bottom', $('footer.main').outerHeight())
        }
        placeStickyFooter()

        $(window).resize(placeStickyFooter)
    }

    render () {
        return (
            <footer className={this.props.className}>
                <Wrap>
                    {this.props.children}
                </Wrap>
            </footer>
        )
    }
}
