// Libraries
import React, {Component} from 'react'
import PropTypes from 'prop-types'

// Components
import CmsBlockContent from '../../components/cms/cms-block-content'
import cmsComponent from '../../components/cms/cms-component'

class HeaderSncb extends Component {
    static propTypes = {
        showLanguageSelector: PropTypes.bool
    }

    getDefaultProps () {
        return {
            showLanguageSelector: true
        }
    }

    render () {
        return <CmsBlockContent name='header-sncb' />
    }
}

export default cmsComponent('header-sncb')(HeaderSncb)
