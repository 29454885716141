// Libraries
import React from 'react'
import {Route} from 'react-router'

import authCheck from '../mixins/auth'

// Layouts
import BookingLoginLayoutContainer from '../layouts/booking-login-layout-container'

const check = (nextState, replaceState, callback) => {
    if (!nextState.location.query.code) {
        return authCheck(nextState, replaceState, callback, {ensureToken: true})
    }
    callback()
}

export default (
    <Route path='/:lang/booking-login'
           component={BookingLoginLayoutContainer}
           onEnter={check}
    />
)
