import PropTypes from 'prop-types'
import React from 'react'
import {withRouter} from 'react-router'
import _t from '../../translate'
import Icon from '../../element/icon'
import Button from '../../element/button'
import AsyncDownloadButton from '../../element/async-download-button'
import TextLabel from '../../element/text-label'
import ConfirmationPoller from './confirmation-poller'
import BookingModel from '../../models/booking-model'
import CmsBlockContent from '../../components/cms/cms-block-content'
import InteractiveFlashMessage from '../interactive-flash-message'
import {
    AFTER_SALES_REBOOK,
    AFTER_SALES_UPDATE_PASSENGERS,
    AFTER_SALES_ADDITIONAL_PRODUCTS_LUGGAGE,
    AFTER_SALES_ADDITIONAL_PRODUCTS_ENTERTAINMENT,
    AFTER_SALES_CANCELLATION, BRANDING_NAME_BLABLACAR_BUS, BRANDING_NAME_BLABLACAR
} from '../../constants'
import confirmationDetails from './details'
import Device from '../../device'
import classnames from 'classnames'
import createReactClass from 'create-react-class'
import {showBlablaCarBranding} from '../../misc/blablabus-helpers'

const afterSalesCmsBlockMap = {
    [AFTER_SALES_REBOOK]: 'AFTERSALES-REBOOK-CONF',
    [AFTER_SALES_UPDATE_PASSENGERS]: 'AFTERSALES-PASSENGERS-CONF',
    [AFTER_SALES_ADDITIONAL_PRODUCTS_LUGGAGE]: 'AFTERSALES-LUGGAGE-CONF',
    [AFTER_SALES_ADDITIONAL_PRODUCTS_ENTERTAINMENT]: 'AFTERSALES-MOVIES-CONF',
    [AFTER_SALES_CANCELLATION]: 'AFTERSALES-CANCEL-CONF'
}

export default withRouter(confirmationDetails(createReactClass({

    displayName: 'BookingConfirmationDetailsContainer',

    propTypes: {
        status: PropTypes.string.isRequired,
        booking: PropTypes.instanceOf(BookingModel),
        onPaymentSuccess: PropTypes.func.isRequired,
        onPaymentCancelled: PropTypes.func.isRequired,
        onPaymentTimeOut: PropTypes.func.isRequired,
        onPaymentFailed: PropTypes.func.isRequired,
        onViewBooking: PropTypes.func.isRequired,
        onRedirectHome: PropTypes.func.isRequired,
        onRedirectOrientation: PropTypes.func.isRequired,
        onDownloadInvoice: PropTypes.func.isRequired,
        afterSalesAction: PropTypes.string,
        router: PropTypes.object,
        urlStatus: PropTypes.string
    },

    redirectMyS3 (e) {
        e.preventDefault()
        this.props.router.push(`/${_t.getLocales()}/mys3/bookings`)
    },

    render () {
        return (
            <div className='booking-confirmation'>
                <div className='panel'>
                    <header>
                        <span className='text-label heading'>
                            <TextLabel text={_t.message('booking-confirmation.header')} />
                        </span>
                    </header>
                    {this._renderContent()}
                </div>
            </div>
        )
    },

    _renderContent () {
        switch (this.props.status) {
            case 'success':
                return this._renderSuccess()
            case 'timeOut':
                return this._renderTimeOut()
            default:
                return this._renderPending()
        }
    },

    _renderPending () {
        return (
            this.props.booking && this.props.booking.bookingNumber ? <div className='content'>
                <p>{_t.message('booking-confirmation.information.pending')}</p>
                <div className='callout'>
                    <ConfirmationPoller
                        status={this.props.status}
                        urlStatus={this.props.urlStatus}
                        bookingNumber={this.props.booking.bookingNumber}
                        onSuccess={this.props.onPaymentSuccess}
                        onCancelled={this.props.onPaymentCancelled}
                        onTimeOut={this.props.onPaymentTimeOut}
                        onFailed={this.props.onPaymentFailed}
                    />
                </div>
                {this._renderButtons()}
            </div> : null
        )
    },

    _renderTimeOut () {
        const label = 'booking-confirmation.information.' +
            (this.props.status === 'success' ? 'time-out-success' : 'time-out-unknown')

        return (
            <div className='content'>
                <p>{_t.message(label)}</p>
                {this._renderButtons()}
            </div>
        )
    },

    _renderSuccess () {
        let confirmationSuccessHeader = null
        if (this.props.afterSalesAction && this.props.afterSalesAction in afterSalesCmsBlockMap) {
            confirmationSuccessHeader = <CmsBlockContent name={afterSalesCmsBlockMap[this.props.afterSalesAction]} />
        } else if (!this.props.afterSalesAction) {
            confirmationSuccessHeader = (
                <span className='text-label booking-confirmation-success'>
                    <TextLabel text={_t.message('booking-confirmation.information.success', {
                        brandingName: showBlablaCarBranding ? BRANDING_NAME_BLABLACAR : BRANDING_NAME_BLABLACAR_BUS,
                        showBlablaCarBranding
                    })} />
                </span>
            )
        }

        const buttonClassName = classnames('button primary', {small: Device.isDesktop()})

        return this.props.booking !== null ? (
            <div className='content'>
                {this.props.afterSalesAction ? <InteractiveFlashMessage /> : null}
                {confirmationSuccessHeader}
                <div className='callout'>
                    <dl>
                        <dt key='booking-reference-label'>
                            <span className='text-label booking-reference-label'>
                                <Icon type='document' className='small align-left' />
                                <TextLabel text={_t.message('booking-confirmation.reference')} />
                            </span>
                        </dt>
                        <dd key='booking-reference'>
                            <span className='text-label booking-reference'>
                                <TextLabel text={this.props.booking.bookingNumber} />
                            </span>
                        </dd>
                        <dt key='booking-url-label'>
                            <span className='text-label booking-url'>
                                <Icon type='document-search' className='small align-left' />
                                <TextLabel text={_t.message('booking-confirmation.overview')} />
                            </span>
                        </dt>
                        <dd key='booking-url'>
                            <button onClick={this.props.onViewBooking} className={`${buttonClassName} view-booking`}>
                                <TextLabel text={_t.message('booking-confirmation.view-booking')} />
                            </button>
                        </dd>
                        {this.props.booking.ticketUrl
                            ? [
                                <dt key='pdf-ticket-label'>
                                    <span className='text-label pdf-ticket'>
                                        <Icon type='document-pdf' className='medium align-left' />
                                        <TextLabel text={_t.message('booking-confirmation.pdf-ticket')} />
                                    </span>
                                </dt>,
                                <dd key='pdf-ticket-url'>
                                    <a
                                        href={this.props.booking.ticketUrl}
                                        target='_blank'
                                        rel='noopener noreferrer'
                                        className={`${buttonClassName} download-ticket`}
                                        download
                                    >
                                        {_t.message('booking-confirmation.download-ticket')}
                                    </a>
                                </dd>
                            ]
                            : null}
                        {this.props.status === 'success'
                            ? [
                                <dt key='button-download-invoice-title'>
                                    <span className='text-label download-invoice'>
                                        <Icon type='invoice-confirmation' className='medium align-left' />
                                        <TextLabel text={_t.message('booking-confirmation.download-invoice')} />
                                    </span>
                                </dt>,
                                <dd key='button-download-invoice'>
                                    <AsyncDownloadButton
                                        downloadUrl={this.props.booking.invoiceUrl}
                                        onRetrieveDownLoadUrlClick={this.props.onDownloadInvoice}
                                        buttonProps={{className: `${buttonClassName} download-invoice`}}
                                    >
                                        <TextLabel text={_t.message('booking-confirmation.button-download-invoice')} />
                                    </AsyncDownloadButton>
                                </dd>
                            ]
                            : null}
                    </dl>
                    <span className='text-label booking-confirmation-information'>
                        <CmsBlockContent name='BOOKING-CONF-SUB' values={{
                            customerEmail: this.props.booking.customer && this.props.booking.customer.email
                        }} />
                    </span>
                </div>
                {this._renderButtons()}
            </div>
        ) : null
    },

    _renderButtons () {
        return (
            <div className='button-group'>
                <ul>
                    <li key='button-home'>
                        <Button type='button' className='button secondary' onClick={this.props.onRedirectHome}>
                            <Icon type='arrow-left' className='small align-left' />
                            <TextLabel text={_t.message('booking-confirmation.button-home')} />
                        </Button>
                    </li>
                    <li key='button-new-booking'>
                        <Button type='button' className='button primary' onClick={this.props.onRedirectOrientation}>
                            <TextLabel text={_t.message('booking-confirmation.button-new-booking')} />
                        </Button>
                    </li>
                </ul>
            </div>
        )
    }
})))
