import omitBy from 'lodash/omitBy'
import moment from 'moment-timezone'
import qs from 'qs'
import {isBlank} from './helpers'

export const createQueryFromValues = values => qs.stringify(values, {encode: false, skipNulls: true})

export const getJourneySearchFromBooking = booking => {
    let values = {}
    const outboundSegments = booking && booking.outboundSegmentCollection.withRequiredProducts()
    if (outboundSegments && outboundSegments.hasSegments) {
        const outboundOrigin = booking.outboundOrigin
        const outboundDestination = booking.outboundDestination
        const outboundTravelDate = booking.outboundTravelDate
        const outboundArrivalDate = booking.outboundArrivalDate
        const inboundOrigin = booking.inboundOrigin
        const inboundDestination = booking.inboundDestination
        const inboundTravelDate = booking.inboundTravelDate

        values = omitBy({
            currency: booking.currency,
            origin: outboundOrigin && outboundOrigin._u_i_c_station_code,
            destination: outboundDestination && outboundDestination._u_i_c_station_code,
            inboundOrigin: inboundOrigin && inboundOrigin._u_i_c_station_code,
            inboundDestination: inboundDestination && inboundDestination._u_i_c_station_code,
            outboundDate: outboundTravelDate && moment(outboundTravelDate).format('YYYY-MM-DD'),
            inboundDate: inboundTravelDate && moment(inboundTravelDate).format('YYYY-MM-DD'),
            passengers: booking.passengers.value().map(passenger => ({
                id: passenger.id,
                type: passenger.type,
                discount_cards: passenger.discount_cards.map(({code}) => ({code}))
            })),
            selectedOutbound: {
                arrivalDatetime: outboundArrivalDate && moment(outboundArrivalDate).format(),
                bundle: booking.outboundProductFamilyId
            }
        }, isBlank)
    }
    return values
}

export const createOriginDestinationQueryFromBooking = booking => {
    let values = {}
    const outboundSegments = booking.outbound_booking_tariff_segments
    if (outboundSegments.length > 0) {
        values.origin = outboundSegments[0].departure_station._u_i_c_station_code
        values.destination = outboundSegments[outboundSegments.length - 1].arrival_station._u_i_c_station_code
    }

    return createQueryFromValues(values)
}
