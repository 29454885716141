// Libraries
import React from 'react'
import PropTypes from 'prop-types'
import {withRouter} from 'react-router'
import Reflux from 'reflux'
import _t from '../translate'
import createReactClass from 'create-react-class'

// Components
import GenericLayout from '../layouts/generic-layout'
import Row from '../element/row'
import Button from '../element/button'
import TextLabel from '../element/text-label'
import Icon from '../element/icon'
import RegisterForm from '../components/crm-register/form'

// Stores
import CrmRegisterStore from '../reflux/stores/crm-register-store'

// Actions
import actions from '../reflux/actions'

const component = createReactClass({

    mixins: [
        Reflux.listenTo(CrmRegisterStore, 'updateCrmRegisterFormData')
    ],

    propTypes: {
        router: PropTypes.object
    },

    getInitialState () {
        return {
            submit: false,
            crmRegisterFormData: CrmRegisterStore.getInitialState()
        }
    },

    updateCrmRegisterFormData (data) {
        if (!data.completed && data.isValid && this.state.submit) {
            actions.registerCrmUser({
                first_name: data.fields.firstName.value,
                last_name: data.fields.lastName.value,
                email: data.fields.emailAddress.value
            })
        }

        this.setState({
            submit: false,
            crmRegisterFormData: data
        })
    },

    _onSubmit () {
        this.setState({submit: true})
    },

    _redirectOrientation (e) {
        e.preventDefault()
        this.props.router.push(`/${_t.getLocales()}/orientation/offer`)
    },

    render () {
        return (
            <GenericLayout>
                <Row className='row'>
                    <div className='grid-row'>
                        <div className='grid-column--1-1 grid-column--small-1-2
                        grid-column--medium-1-3 center-grid-column--small'>
                            {this.state.crmRegisterFormData.completed
                                ? this._renderPanelCompleted() : this._renderPanelForm()}
                        </div>
                    </div>
                </Row>
            </GenericLayout>
        )
    },

    _renderPanelForm () {
        return (
            <div className='my-s3-register-crm-user'>
                <div className='panel'>
                    <header>
                        <span className='text-label heading'>
                            <TextLabel text={_t.getIntlMessage('my-s3-register-form.header')} />
                        </span>
                    </header>
                    <div className='content'>
                        <RegisterForm formData={this.state.crmRegisterFormData} onSubmit={this._onSubmit} />
                    </div>
                </div>
            </div>
        )
    },

    _renderPanelCompleted () {
        return (
            <div className='panel'>
                <header>
                    <span className='text-label heading'>
                        <TextLabel text={_t.getIntlMessage('my-s3-register-form.completed.header')} />
                    </span>
                </header>
                <div className='content'>
                    <div className='feedback success'>
                        <span className='label feedback-message'>
                            {_t.getIntlMessage('my-s3-register-form.completed.message')}
                        </span>
                    </div>
                    <Button type='button' className='button secondary' onClick={this._redirectOrientation}>
                        <Icon type='arrow-left' className='small align-left' />
                        <TextLabel text={_t.getIntlMessage('my-s3-register-form.completed.button-home')} />
                    </Button>
                </div>
            </div>
        )
    }
})
export default withRouter(component)
