module.exports = {
    loadLocaleDataFile (locale, file) {
        try {
            delete require.cache[require.resolve(`../locale-data/${locale}/${file}.json`)]
            return require(`../locale-data/${locale}/${file}.json`)
        } catch (e) {
            return {}
        }
    }
}
