import React, {Component} from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import device from '../../../device'
import _t from '../../../translate'
import BookingModel from '../../../models/booking-model'
import GenericLayout from '../../generic-layout'
import Row from '../../../element/row'
import RebookingJourneySearch from '../../../components/mys3/re-booking/journey-search'
import RebookingDiscountCardModal from '../../../components/mys3/re-booking/discount-card-modal'
import JolliCodeDiscountCardStore from '../../../reflux/stores/jollicode-discount-card-store'
import AfterSalesSummary from '../../../components/after-sales-summary'
import JourneyResultSetContainer from '../../../components/journey-result-set-container'
import Price from '../../../components/journey-result-set/price'
import ProgressNavigation from '../../../base/main/progress-navigation-container'
import Help from '../../../components/my-s3-booking/help'
import AfterSalesNavigation from '../../../base/after-sales-navigation'
import ModalDialog from '../../../element/modal-dialog'
import actions from '../../../reflux/actions'
import CalendarStore from '../../../reflux/stores/calendar-store'
import Conditions from '../../../components/mys3/re-booking/conditions'
import PromoTariffBanner from '../../../base/promo-tariff-banner'
import cmsComponent from '../../../components/cms/cms-component'

class OrientatrionOfferContainer extends Component {
    static propTypes = {
        booking: PropTypes.instanceOf(BookingModel).isRequired,
        journeySearch: PropTypes.object.isRequired,
        hasOffer: PropTypes.bool.isRequired,
        selectedBundles: PropTypes.array.isRequired,
        loading: PropTypes.bool.isRequired,
        bookingLoading: PropTypes.bool,
        onClickNext: PropTypes.func.isRequired,
        onOptionClick: PropTypes.func.isRequired,
        isBundleSelectionComplete: PropTypes.bool.isRequired,
        outboundBeforeInbound: PropTypes.bool,
        enableSearchButton: PropTypes.bool,
        offer: PropTypes.object,
        travels: PropTypes.array
    }

    constructor (...args) {
        super(...args)
        this.state = {
            showErrorDialog: false
        }
        this.onClickNext = this.onClickNext.bind(this)
        this._validateDiscountCards = this._validateDiscountCards.bind(this)
        this._renderJourneyResultSet = this._renderJourneyResultSet.bind(this)
        this.onSearchButtonClick = this.onSearchButtonClick.bind(this)
        this._renderPromoWarning = this._renderPromoWarning.bind(this)
        this.onCloseDialog = this.onCloseDialog.bind(this)
        this._renderErrorDialog = this._renderErrorDialog.bind(this)
    }

    componentDidMount () {
        if (!isEmpty(this.props.offer)) {
            this._validateDiscountCards()
        }
    }

    onClickNext () {
        if (this.props.outboundBeforeInbound) {
            this.setState({showErrorDialog: true})
        } else {
            this.props.onClickNext()
        }
    }

    render () {
        const booking = this.props.booking
        return (
            <GenericLayout className='rebook-offer'>
                <AfterSalesNavigation />
                <Row>
                    <div className='grid-row'>
                        <div className='grid-column--1-1 grid-column--medium-1-3'>
                            <AfterSalesSummary booking={booking} />
                            {device.isDesktop() ? <Help /> : null}
                        </div>
                        <div className='grid-column--1-1 grid-column--medium-2-3'>
                            <Conditions />
                            <RebookingJourneySearch
                                booking={booking}
                                outBoundDate={this.props.journeySearch.departure_date}
                                inBoundDate={this.props.journeySearch.return_date}
                                searchButtonEnabled={this.props.enableSearchButton}
                                searchButtonLoading={this.props.loading}
                                onSearchButtonClick={this.onSearchButtonClick}
                                onChangeOutboundDate={
                                    value => actions.updateRebookJourneySearchSelection({departure_date: value})
                                }
                                onChangeInboundDate={
                                    value => actions.updateRebookJourneySearchSelection({return_date: value})
                                }
                            />
                            {this._renderJourneyResultSet()}
                            <Price />
                            <ProgressNavigation
                                previousButtonEnabled={false}
                                nextButtonProps={{
                                    onClick: this.onClickNext,
                                    loading: this.props.bookingLoading,
                                    className: 'button next primary',
                                    icon: {
                                        type: 'chevron-right',
                                        className: 'xsmall align-right'
                                    },
                                    disabled: JolliCodeDiscountCardStore.hasViolationCodes()
                                }}
                                nextButtonEnabled={this.props.isBundleSelectionComplete}
                            />
                            {!device.isDesktop() ? <Help /> : null}
                        </div>
                    </div>
                </Row>
                {this._renderErrorDialog()}
                <RebookingDiscountCardModal />
            </GenericLayout>
        )
    }

    _renderJourneyResultSet () {
        const travels = this.props.travels
        if (!this.props.loading && travels.length === 0) {
            return this._renderPromoWarning()
        }

        return <JourneyResultSetContainer
            travels={travels}
            hasOffer={this.props.hasOffer}
            selectedBundles={this.props.selectedBundles}
            journeySearch={this.props.journeySearch}
            onOptionClick={this.props.onOptionClick}
            onSearch={this.onSearch}
        />
    }

    _validateDiscountCards () {
        const booking = this.props.booking
        const outboundDate = booking.isReturnTrip() ? this.props.journeySearch.return_date : this.props.journeySearch.departure_date

        // When all discount card numbers are validated. Check for errors and display a modal if needed.
        return actions.validateBookingDiscountCards(booking, outboundDate)
    }

    onSearchButtonClick () {
        this._validateDiscountCards()

        const booking = this.props.booking

        const journeySelection = {
            origin: booking.outboundOrigin && booking.outboundOrigin._u_i_c_station_code,
            destination: booking.outboundDestination && booking.outboundDestination._u_i_c_station_code,
            inboundOrigin: booking.inboundOrigin && booking.inboundOrigin._u_i_c_station_code,
            inboundDestination: booking.inboundDestination && booking.inboundDestination._u_i_c_station_code,
            outboundDate: this.props.journeySearch.departure_date,
            inboundDate: this.props.journeySearch.return_date,
            passengers: booking.passengers.values().value().map(passenger => ({
                id: passenger.id,
                type: passenger.type,
                discount_cards: passenger.discount_cards
            })),
            currency: this.props.journeySearch.currency || booking.currency
        }
        this.onSearch(journeySelection)
    }

    onSearch (journeySelection) {
        actions.clearBundles()
        actions.getOffer(journeySelection)
        actions.getCalendar(CalendarStore.createDateSwitcherRequestData(journeySelection, true))
    }

    _renderPromoWarning () {
        const booking = this.props.booking
        if (!booking.hasPromoTariff()) {
            return null
        }
        const direction = booking.outboundSegmentCollection.hasPromoTariff() ? 'outbound' : 'inbound'

        return <PromoTariffBanner text={_t.message(`my-s3-re-booking.journey-search.promo-warning.${direction}`)} />
    }

    onCloseDialog () {
        this.setState({
            showErrorDialog: false
        })
    }

    _renderErrorDialog () {
        if (!this.state.showErrorDialog) {
            return null
        }

        return (
            <ModalDialog
                headerTitle={_t.message('journey-result-set.inbound-before-outbound-dialog.title')}
                onClose={this.onCloseDialog}
            >
                <div className='content'>
                    <p>{_t.message('journey-result-set.inbound-before-outbound-dialog.content')}</p>
                </div>
            </ModalDialog>
        )
    }
}

export default cmsComponent('AFTERSALES-REBOOK-FLEX')(OrientatrionOfferContainer)
