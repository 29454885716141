// Libraries
import React from 'react'
import {Route} from 'react-router'

import authCheck from '../mixins/auth'
import AccessManager from '../data/access-manager'

// Layouts
import CrmRegisterLayout from '../layouts/crm-register-layout'

export default (
    <Route path='/:lang/crm-register'
           component={CrmRegisterLayout}
           onEnter={(nextState, replaceState, callback) => authCheck(nextState, replaceState, callback, {
                   role: AccessManager.roles.public
               })}
        />
)
