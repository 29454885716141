// Libraries
import PropTypes from 'prop-types'
import React, {Component} from 'react'
import _t from '../translate'

// Components
import ConfirmationPoller from './booking-confirmation/confirmation-poller'
import BookingSpecificationBookingPanel from './booking-specification/booking-panel'

// Models
import BookingModel from '../models/booking-model'

export default class AfterSalesConfirmationContainer extends Component {
    static propTypes = {
        status: PropTypes.string,
        booking: PropTypes.instanceOf(BookingModel).isRequired,
        onSuccess: PropTypes.func.isRequired,
        onFailed: PropTypes.func.isRequired,
        onCancelled: PropTypes.func.isRequired
    }

    constructor (...args) {
        super(...args)
        this.state = {timeOut: false}
    }

    render () {
        const label = 'booking-confirmation.information.' + (this.state.timeOut ? 'time-out-unknown' : 'pending')

        return (
            <div className='grid-row'>
                <div className='grid-column--1-1 grid-column--medium-1-3'>
                    <BookingSpecificationBookingPanel
                        afterSales
                        booking={this.props.booking}
                        onlyShowNewTariffSegments
                    />
                </div>
                <div className='grid-column--1-1 grid-column--medium-2-3'>
                    <div className='panel'>
                        <header>
                            <h2>{_t.getIntlMessage(label)}</h2>
                        </header>
                        <div className='content'>
                            {!this.state.timeOut ? (
                                <ConfirmationPoller
                                    status={this.props.status}
                                    bookingNumber={this.props.booking.bookingNumber}
                                    onSuccess={this.props.onSuccess}
                                    onCancelled={this.props.onCancelled}
                                    onTimeOut={() => this.setState({timeOut: true})}
                                    onFailed={this.props.onFailed}
                                />
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
