import React from 'react'
import TabContent from '../../../../element/tab-content'
import VoucherForm from '../voucher-form'

const Voucher = () => (
    <TabContent visible>
        <VoucherForm />
    </TabContent>
)

export default Voucher
