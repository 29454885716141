import PropTypes from 'prop-types'
import React, {Component} from 'react'
import ReactDOM from 'react-dom'
import first from 'lodash/first'
import deburr from 'lodash/deburr'
import device from '../../device'
import Mousetrap from 'mousetrap'
import classNames from 'classnames'

const $ = require('jquery')
require('jquery/widgets/auto-complete')

export default class AutoComplete extends Component {
    static propTypes = {
        data: PropTypes.array,
        matcher: PropTypes.func,
        placeholder: PropTypes.string,
        value: PropTypes.any,
        defaultValue: PropTypes.any,
        autoCompleteOptions: PropTypes.any,
        onChange: PropTypes.any,
        onFocus: PropTypes.any,
        onClick: PropTypes.any,
        onOpen: PropTypes.any,
        onClose: PropTypes.any,
        readOnly: PropTypes.any,
        className: PropTypes.any,
        title: PropTypes.any,
        type: PropTypes.any,
        onBlur: PropTypes.func,
        isValid: PropTypes.bool,
        accentMap: PropTypes.any
    }

    static defaultProps = {
        autoCompleteOptions: {
            maxLength: 5
        },
        data: [],
        accentMap: require('misc/accent-map')
    }

    constructor (...args) {
        super(...args)
        this._getJQueryNode = this._getJQueryNode.bind(this)
        this.onChange = this.onChange.bind(this)
        this.onBlur = this.onBlur.bind(this)
        this.onFocus = this.onFocus.bind(this)
        this.source = this.source.bind(this)
        this.onAutoCompleteOpen = this.onAutoCompleteOpen.bind(this)
        this.onTypeAheadComplete = this.onTypeAheadComplete.bind(this)
        this.onAutoCompleteClose = this.onAutoCompleteClose.bind(this)
        this.onAutoCompleteChange = this.onAutoCompleteChange.bind(this)
        this.onAutoCompleteSelect = this.onAutoCompleteSelect.bind(this)
        this.onAutoCompleteFocus = this.onAutoCompleteFocus.bind(this)
        this.triggerChange = this.triggerChange.bind(this)

        this.state = {
            value: null,
            typeAhead: null,
            isOpen: false
        }
    }

    componentDidMount () {
        const options = $.extend({}, this.props.autoCompleteOptions, {
            source: this.source,
            open: this.onAutoCompleteOpen,
            focus: this.onAutoCompleteFocus,
            close: this.onAutoCompleteClose,
            change: this.onAutoCompleteChange,
            select: this.onAutoCompleteSelect,
            delay: 0,
            minLength: 0,
            maxLength: this.props.autoCompleteOptions.maxLength
        })

        this._createAutoCompleteWidget()

        this._getJQueryNode().autocomplete(options)

        const mousetrap = new Mousetrap(this._getDOMNode())
        mousetrap.bind('tab', this.onTypeAheadComplete)
    }

    _getDOMNode () {
        return ReactDOM.findDOMNode(this.refs.inputField)
    }

    _getJQueryNode () {
        return $(this._getDOMNode())
    }

    _createAutoCompleteWidget () {
        $.widget('s3p.autocomplete', $.ui.autocomplete, {
            _create: function () {
                this._super()
                this.widget().menu('option', 'items', `> :not(.group)`)
            },
            _renderMenu: function (ul, items) {
                const that = this
                $.each(items, function (_, item) {
                    if (item.isGroup) {
                        ul.append(`<li class='group'><span class='${item.className}'>${item.label}</span></li>`)
                    } else {
                        that._renderItemData(ul, item)
                    }
                })
            }
        })
    }

    componentWillUnmount () {
        this._getJQueryNode().autocomplete('destroy')
    }

    componentWillReceiveProps (nextProps) {
        if (nextProps.value !== this.props.value) {
            this._setValue(this.valueExists(nextProps.value, nextProps.data) ? nextProps.value : null)
        }
        this.setState({typeAhead: this._getTypeAheadItem(this.state.value)})
    }

    _getTypeAheadItem (value) {
        value = this.getInputText(value)
        if (value && value.length > 12) {
            return null
        }
        return this._getItem(value, true)
    }

    _getItem (value, excludeExactMatch) {
        if (!value) {
            return null
        }
        const deburredValue = deburr(value.toLowerCase())
        return this.props.data.find(item => {
            if (item.disabled || !item.text || item.isGroup) {
                return false
            }
            const deburredItemText = deburr(item.text.toLowerCase())

            if (excludeExactMatch && deburredItemText === deburredValue) {
                return false
            }

            return deburredItemText.indexOf(deburredValue) === 0
        })
    }

    onTypeAheadComplete (event) {
        if (event && this.state.typeAhead) {
            event.preventDefault()
        }

        const item = this._getItem(this.getInputText(this.state.value, false))
        if (item && item.value !== this.props.value) {
            this.triggerChange(item.value)
        }
        this._setValue(null)
        this._getJQueryNode().autocomplete('close')
    }

    _setValue (value, restState = {}) {
        this.setState({...restState, value, typeAhead: this._getTypeAheadItem(value)})
    }

    render () {
        const props = {...this.props}
        /* eslint-disable react/prop-types */
        delete props.value
        delete props.defaultValue
        delete props.autoCompleteOptions
        delete props.onChange
        delete props.data
        delete props.onFocus
        delete props.onClick
        delete props.onOpen
        delete props.onClose
        delete props.readOnly
        delete props.className
        delete props.title
        delete props.type
        delete props.matcher
        delete props.isValid
        delete props.accentMap
        /* eslint-enable react/prop-types */

        const typeAhead = this.state.typeAhead && this.state.typeAhead.text

        return (
            <div className='autocomplete-type-ahead-wrapper'>
                {device.isIE(10) || device.isIE(11) ? this._renderIEWrapper(props) : this._renderInput(props)}
                <input type='text' className={this.getClassName()} value={typeAhead || ''} readOnly />
            </div>
        )
    }

    getClassName () {
        return classNames('autocomplete-type-ahead', {
            'state--is-invalid': !this.props.isValid
        })
    }

    _renderIEWrapper (props) {
        // eslint-disable-next-line react/prop-types
        let {placeholder, ...other} = props
        placeholder = !this.props.value ? placeholder : ''

        return (
            <div className='ui-autocomplete-wrapper' data-placeholder={placeholder}>
                {this._renderInput(other)}
            </div>
        )
    }

    _renderInput (props) {
        return (
            <input
                ref='inputField'
                title=''
                type='text'
                value={this.getSelectedValue() || ''}
                autoComplete='off'
                className={this.getClasses().join(' ')}
                onFocus={this.onFocus}
                onClick={this.onFocus}
                onChange={this.onChange}
                onBlur={this.onBlur}
                {...props}
            />
        )
    }

    /**
     * @returns {string[]}
     */
    getClasses () {
        let classes = ['combobox-input', 'ui-widget', 'ui-widget-content', 'ui-state-default', 'ui-corner-left']
        if (this.props.className && this.props.className.length > 0) {
            classes.push(this.props.className)
        }
        return classes
    }

    /**
     * @returns {string}
     */
    getSelectedValue () {
        let value

        if (this.state.value !== null) {
            value = this.state.value
        } else if (this.props.data.length) {
            value = this.props.value
        }

        return this.getInputText(value)
    }

    getInputText (value) {
        const selectedItem = value && first(this.props.data.filter(item => item.value === value))

        if (selectedItem) {
            value = selectedItem.text
        }

        return value
    }

    onChange (event) {
        this._setValue(event.target.value)
    }

    onFocus (...args) {
        this._getJQueryNode().keydown()
        this.setState({value: ''})
        this.props.onFocus && this.props.onFocus(...args)
    }

    onBlur (...args) {
        if (this.state.isOpen) {
            this.onTypeAheadComplete()
            this.setState({isOpen: false})
        }
        this.props.onBlur && this.props.onBlur(...args)
    }

    normalize (term) {
        let ret = ''
        for (let i = 0; i < term.length; i++) {
            ret += this.props.accentMap[term.charAt(i)] || term.charAt(i)
        }
        return ret
    }

    isMatchedValue (item, term) {
        if (typeof this.props.matcher === 'function') {
            return this.props.matcher(item, term, value => this.normalize(value))
        } else {
            const matcher = new RegExp(this.normalize($.ui.autocomplete.escapeRegex(term)), 'i')
            return matcher.test(this.normalize(item.text)) || matcher.test(this.normalize(item.value))
        }
    }

    filter (array, term) {
        return array.filter(function (item) {
            return (!item.disabled && this.isMatchedValue(item, term))
        }.bind(this)).map(function (item) {
            return {
                label: item.text,
                value: item.text,
                isMain: item.isMain || false,
                isGroup: item.isGroup || false,
                className: item.className || null
            }
        })
    }

    source (request, response) {
        response(this.filter(this.props.data, request.term))
    }

    onAutoCompleteOpen (event, ui) {
        if (device.isIOS()) {
            $('.ui-autocomplete').off('menufocus hover mouseover')
        }

        if (!this.state.isOpen) {
            this._setValue(this.state.value || '', {isOpen: true})
        }

        if (typeof this.props.onOpen === 'function') {
            this.props.onOpen(event, ui)
        }
    }

    onAutoCompleteFocus (event) {
        if (event.keyCode) {
            this.setState({typeAhead: ''})
        }
    }

    onAutoCompleteClose (event, ui) {
        this.setState({isOpen: false})
        if (typeof this.props.onClose === 'function') {
            this.props.onClose(event, ui)
        }
    }

    onAutoCompleteChange (_, ui) {
        // Selected an item, nothing to do
        if (ui.item) {
            return
        }

        let valid = false

        // Search for a match (case-insensitive)
        let value = this._getJQueryNode().val()
        const valueLowerCase = value.toLowerCase()
        $.each(this.props.data, function () {
            if (this.text.toLowerCase() === valueLowerCase) {
                valid = true
                return false
            }
        })

        if (valid) {
            return
        }

        // Remove invalid value
        this._getJQueryNode().attr('title', value + ' didn\'t match any item')
        this._getJQueryNode().autocomplete('instance').term = ''
        this._setValue(null)
    }

    onAutoCompleteSelect (_, ui) {
        if (typeof this.props.onChange === 'function') {
            const data = this.props.data.filter(function (item) {
                return item.text === ui.item.value && !item.isGroup
            }).map(function (item) {
                return item.value
            })
            this.triggerChange(first(data))
        }
    }

    triggerChange (value) {
        if (typeof this.props.onChange === 'function') {
            this.props.onChange(value)
        }
    }

    valueExists (value, data) {
        return data.filter(function (item) {
            return value === item.value
        }).length > 0
    }
}
