import React, {Component} from 'react'
import PropTypes from 'prop-types'
import $ from 'jquery'
import _t from '../../translate'
import CmsBlockContent from '../../components/cms/cms-block-content'
import cmsComponent from '../../components/cms/cms-component'
import {VSC_D_ENWW} from '../../data/affiliate-constants'

// Function to show a menu
const showMenu = (tabElement, cssClass) => {
    const wrapperWidth = $('#header-wrapper').outerWidth()
    const tabPosition = tabElement.position() // Get tab position
    const tabWidth = tabElement.outerWidth() // Get tab width
    const arrow = tabElement.find('.sub-menu-arrow') // Get arrow element
    const tabMiddle = tabPosition.left + (tabWidth / 2) // Calculate tab's middle
    const arrowPosition = tabMiddle - (arrow.outerWidth() / 2) // Calculate arrow position
    const menu = tabElement.find('.sub-menu') // Get menu element
    let menuPosition = tabMiddle - (menu.outerWidth() / 2) // Calculate menu position;

    // Check left position
    if (menuPosition < 0) { // Left border of the menu is out the wrapper
        menuPosition = 0 // Set left position
    }

    // Check right position
    if ((tabMiddle + (menu.outerWidth() / 2)) > wrapperWidth) { // Right border of menu is out of the wrapper
        menuPosition = 'auto' // Reset left property
        menu.css('right', 0) // Set right position
    }

    arrow.css('left', arrowPosition) // Set arrow position
    menu.css('left', menuPosition) // Set menu position

    $('#menu').find('li.tab').removeClass(cssClass) // To be sure there will be only one menu displayed
    tabElement.addClass(cssClass) // To show the defined menu
}

// Function to hide a menu
const hideMenu = (tabElement, cssClass) => {
    tabElement.removeClass(cssClass)
    if (cssClass === 'show-sub-menu-click') {
        // On click, remove focus click too, which is useless
        tabElement.removeClass('show-sub-menu-focus')
    }
}

// Function to hide all menus
const hideAllMenus = () => {
    $('#menu').find('li.tab').removeClass('show-sub-menu-click show-sub-menu-focus')
}

class HeaderVsc extends Component {
    static propTypes= {
        affiliateCode: PropTypes.string.isRequired,
        showLanguageSelector: PropTypes.bool
    }

    getDefaultProps () {
        return {
            showLanguageSelector: true
        }
    }

    componentDidMount () {
        // Show menu on click
        $(document).on('click.vsc-show-menu', '#menu a.clickable-tab', function (e) {
            e.preventDefault()
            const $parent = $(this).parent()
            if ($parent.hasClass('show-sub-menu-click')) {
                hideMenu($parent, 'show-sub-menu-click')
            } else {
                showMenu($parent, 'show-sub-menu-click')
            }
            return false
        })

        // Hide menus on click somewhere else
        $(document).on('click.vsc-hide-menus', function () {
            hideAllMenus()
        })

        // Hide menus on focus on inputs (some browsers do not interprate this as a click)
        $('input').on('focus.vsc-hide-menus', function () {
            hideAllMenus()
        })

        // Language switcher
        $(document).on('click.vsc-language-selector', '#language-switcher .current', function () {
            const $countryList = $('#country-list')
            const self = $(this)

            // Hide all menus
            hideAllMenus()

            // Show list on click, hide it if already displayed
            if (self.hasClass('selected')) {
                $countryList.fadeOut('fast')
                self.removeClass('selected')
            } else {
                $countryList.fadeIn('fast')
                self.addClass('selected')
            }
            // Hide open panel on click outside
            $(document).bind('click.vsc-hide-language-selector', function () {
                $countryList.fadeOut('fast')
                self.removeClass('selected')
            })

            return false
        })
    }

    componentWillUnmount () {
        $(document)
            .off('click.vsc-show-menu click.vsc-hide-menus')
            .off('click.vsc-language-selector click.vsc-hide-language-selector')
        $('input').off('focus.vsc-hide-menus')
    }

    render () {
        const name = _t.getLocales() === 'en-GB' && this.props.affiliateCode === VSC_D_ENWW
            ? 'header-vsc-ww' : 'header-vsc'

        return <CmsBlockContent name={name} />
    }
}
export default cmsComponent('header-vsc', 'header-vsc-ww')(HeaderVsc)
