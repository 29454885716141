import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from '../../translate'
import device from '../../device'
import BaseProgressNavigation from '../../base/main/progress-navigation-container'

export default class ProgressNavigation extends Component {
    static
    propTypes = {
        onClickPrevious: PropTypes.func.isRequired,
        onClickNext: PropTypes.func.isRequired,
        previousButtonEnabled: PropTypes.bool,
        nextLoading: PropTypes.bool,
        optionSelected: PropTypes.bool
    }

    render () {
        const nextButtonText = device.isMobile() && this.props.previousButtonEnabled
            ? _t.message('options.continue-with')
            : _t.message(`options.continue-with${this.props.optionSelected ? '' : 'out'}`)
        return <BaseProgressNavigation
            previousButtonEnabled={this.props.previousButtonEnabled}
            previousButtonProps={{
                className: 'button previous default',
                icon: {
                    type: 'chevron-left',
                    className: 'xsmall align-left'
                },
                onClick: this.props.onClickPrevious
            }}
            nextButtonProps={{
                id: 'booking-options-next',
                className: 'button next primary',
                text: nextButtonText,
                icon: {
                    type: 'chevron-right',
                    className: 'xsmall align-right'
                },
                onClick: this.props.onClickNext,
                loading: this.props.nextLoading
            }}
        />
    }
}
