import React, {Component} from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

export default class AccordionPanelHeader extends Component {
    static propTypes = {
        className: PropTypes.string,
        panelName: PropTypes.string,
        showCollapsed: PropTypes.bool,
        showExpanded: PropTypes.bool,
        collapsed: PropTypes.bool,
        onClick: PropTypes.func
    }

    static defaultProps = {
        showCollapsed: false,
        showExpanded: false
    }

    render () {
        if ((this.props.collapsed && this.props.showExpanded) || (!this.props.collapsed && this.props.showCollapsed)) {
            return null
        }

        var {className, panelName, onClick, ...props} = this.props
        delete props.showCollapsed
        delete props.showExpanded
        delete props.collapsed

        className = classNames('accordion-header', className)

        return (
            <div className={className} onClick={event => onClick(event, panelName)} {...props}>
                {this.props.children}
            </div>
        )
    }
}
