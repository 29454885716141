import React, {Component} from 'react'
import PropTypes from 'prop-types'
import Checkbox from '../../../element/form/checkbox-field'
import Loader from '../../../element/loader'
import {cmsBlockContainer} from '../../cms/cms-block-container'

class OptOutOption extends Component {
    static propTypes = {
        onClick: PropTypes.func.isRequired,
        checked: PropTypes.bool,
        disabled: PropTypes.bool,
        isCmsLoading: PropTypes.bool,
        content: PropTypes.string
    }

    render () {
        return (
            <div className='opt-out-option'>
                <Checkbox
                    id={`opt-out-newsletter`}
                    className='opt-out'
                    labelPosition='after'
                    checked={this.props.checked}
                    disabled={this.props.disabled}
                    onClick={this.props.onClick}
                >
                    <span>{this.props.isCmsLoading ? <Loader /> : this.props.content || ''}&nbsp;</span>
                </Checkbox>
            </div>
        )
    }
}

export default cmsBlockContainer('checkbox-newsletter-opt-out', OptOutOption)
