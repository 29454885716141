import PropTypes from 'prop-types'
import React from 'react'
import {withRouter} from 'react-router'
import Reflux from 'reflux'
import _t from '../../../translate'
import times from 'lodash/times'
import ProgressNavigation from '../../../base/main/progress-navigation-container'
import AccessManager from '../../../data/access-manager'
import PassengersDetailsStore from '../../../reflux/stores/passenger-details-store'
import FavoritePassengersStore from '../../../reflux/stores/favorite-passenger-store'
import CrmUserStore from '../../../reflux/stores/crm-user-store'
import actions from '../../../reflux/actions'
import {NEW_FAVORITE_PASSENGER, UPDATE_CRM} from '../../../constants'
import DiscountCardModal from './customer/discount-card-modal'
import JollicodeDiscountCardStore from '../../../reflux/stores/jollicode-discount-card-store'
import {goToOuibusSearchPage} from '../../../misc/new-ouibus-helper'
import {isAffiliateBlablacarSelector} from '../../../reflux/bridge/affiliate'
import BookingModel from '../../../models/booking-model'
import BookingStore from '../../../reflux/stores/booking-store'
import GoogleTaggingStore from '../../../reflux/stores/google-tagging-store'
import createReactClass from 'create-react-class'

const CHECK_DISCOUNT_CARD_TIMEOUT = 1000
let submitTimer

const component = createReactClass({

    mixins: [
        Reflux.connectFilter(BookingStore, 'booking', data => BookingModel.create(data.booking)),
        Reflux.connectFilter(BookingStore, 'bookingLoading', data => data.loading),
        Reflux.connectFilter(PassengersDetailsStore, 'passengerFormData', data => data),
        Reflux.connectFilter(PassengersDetailsStore, 'passengerWithMeSelected', data => (
            (data.passengers || []).find(passenger => (
                passenger.fields.crmSelect && passenger.fields.crmSelect.value === UPDATE_CRM
            ))
        )),
        Reflux.connectFilter(PassengersDetailsStore, 'passengersFromFavoritePassenger', data => (
            (data.passengers || []).filter(passenger => (
                passenger.fields.crmSelect && passenger.fields.crmSelect.value && ![UPDATE_CRM, NEW_FAVORITE_PASSENGER].includes(passenger.fields.crmSelect.value)
            ))
        )),
        Reflux.listenTo(actions.createBooking.completed, 'handleCloseDiscountCardModal')
    ],

    propTypes: {
        travelDate: PropTypes.any.isRequired,
        router: PropTypes.object
    },

    contextTypes: {
        history: PropTypes.object
    },

    getInitialState () {
        return {
            buttonLoading: false,
            showDiscountCardModal: false,
            youthsWithInvalidCards: []
        }
    },

    componentWillUnmount () {
        this._clearSubmitTimer()
    },

    _clearSubmitTimer () {
        if (submitTimer) {
            clearTimeout(submitTimer)
            submitTimer = null
        }
    },

    handleCloseDiscountCardModal () {
        this.setState({showDiscountCardModal: false})
    },

    async createFavoritePassengers () {
        const createFavoritePassengersApiCall = FavoritePassengersStore.getCreateFavoritePassengersForApiCall()

        // Track # new registered passengers
        times(createFavoritePassengersApiCall.passengers.length, () => {
            actions.trackEvent('addPassenger', {add_passenger: 'register new'})
        })

        // Track # existing favorite passengers that have been selected excluding me.
        times(this.state.passengersFromFavoritePassenger.length, () => {
            actions.trackEvent('addPassenger', {add_passenger: 'add from list'})
        })

        if (createFavoritePassengersApiCall.passengers.length > 0) {
            return actions.myS3CreateFavoritePassengers(createFavoritePassengersApiCall)
        }
    },

    async updateCrmCustomer () {
        const updateCrmUSerInformationData = CrmUserStore.formatUpdateCrmUserForApiCall(this.state.passengerWithMeSelected)
        return updateCrmUSerInformationData && actions.updateCrmUserInformation(updateCrmUSerInformationData)
    },

    async _handleOnNext () {
        this._clearSubmitTimer()

        // Form can be become invalid during the timeout
        PassengersDetailsStore.validatePassengers()
        if (!this.state.passengerFormData.isValid) {
            return false
        }

        this.setState({buttonLoading: true})

        if (AccessManager.isCrmUser()) {
            await this.createFavoritePassengers()
            await this.updateCrmCustomer()
        }

        const bookingNumber = this.state.booking.bookingNumber
        const {add, update} = PassengersDetailsStore.getAdditionalDetails()
        const passengersApiCall = PassengersDetailsStore.getPassengersForApiCall()
        await actions.updatePassengers(bookingNumber, {passengers: passengersApiCall})

        add.length && await actions.addAdditionalDetails(bookingNumber, add)
        update.length && await actions.updateAdditionalDetails(bookingNumber, update)

        this.props.router.push(`/${_t.getLocales()}/booking/options`)
    },

    onNext () {
        GoogleTaggingStore.onCompletePassengerDetails()
        // Show Form validations
        PassengersDetailsStore.validatePassengers()

        // Display upsale popup if youth card is left empty
        const youthsWithInvalidCards = PassengersDetailsStore.youthsWithDiscountAndInvalidCardNumber()
        if (youthsWithInvalidCards.length > 0) {
            return this.setState({
                showDiscountCardModal: true,
                youthsWithInvalidCards: youthsWithInvalidCards.map(passenger => passenger.id)
            })
        }

        // If we have youth discount cards, check them again to make sure. Skip if it takes longer than 1s.
        const passengersWithYouthCard = this.state.passengerFormData.passengers.filter(({isYouthWithDiscount}) => isYouthWithDiscount)
        if (passengersWithYouthCard.length) {
            if (submitTimer) {
                return
            }
            actions.validatePassengerFormDiscountCards(passengersWithYouthCard, this.props.travelDate).then(() => {
                this._clearSubmitTimer()
                if (!JollicodeDiscountCardStore.hasViolationCodes()) {
                    // All codes checked within 1s. Go to the next step.
                    this._handleOnNext()
                }
            })
            submitTimer = setTimeout(this._handleOnNext, CHECK_DISCOUNT_CARD_TIMEOUT)
        } else {
            // No cards to check
            this._handleOnNext()
        }
    },

    onPrevious () {
        if (this.state.booking.provisional) {
            actions.cancelBooking(this.state.booking.bookingNumber)
        }
        goToOuibusSearchPage()
    },

    render () {
        const {bookingLoading, buttonLoading, showDiscountCardModal} = this.state
        const props = {
            previousButtonEnabled: !isAffiliateBlablacarSelector(),
            previousButtonProps: {
                className: 'button previous default',
                icon: {
                    type: 'chevron-left',
                    className: 'xsmall align-left'
                },
                loading: bookingLoading,
                onClick: this.onPrevious
            },
            nextButtonEnabled: true,
            nextButtonProps: {
                text: _t.message('passenger-details.validate'),
                id: 'booking-details-next',
                className: 'button next primary',
                icon: {
                    type: 'chevron-right',
                    className: 'xsmall align-right'
                },
                onClick: this.onNext,
                loading: buttonLoading,
                disabled: JollicodeDiscountCardStore.isCardExpired() || JollicodeDiscountCardStore.isCardDisabled()
            }
        }

        return (
            <div>
                {showDiscountCardModal
                    ? <DiscountCardModal
                        onClose={this.handleCloseDiscountCardModal}
                        youthsWithInvalidCards={this.state.youthsWithInvalidCards}
                    />
                    : null}
                <ProgressNavigation {...props} />
            </div>
        )
    }
})
export default withRouter(component)
