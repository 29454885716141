import {createSelector} from 'reselect'

import {stationsSelector as sourceStationsSelector} from '../../../../../reflux/bridge/stations'
import {camelCaseKeys} from '../../../../../misc/camelcase'
import {toTimezoneMoment} from '../../../../../misc/date'
import sortBy from 'lodash/sortBy'

export const stationNameToSearchString = text => text ? text.toLowerCase().replace(/[^a-z0-9 ]/g, '') : ''

export const stationMapper = ({name, _u_i_c_station_code: UICStationCode, ...station}) => ({
    ...camelCaseKeys(station),
    UICStationCode,
    name: name || UICStationCode,
    arrivalTime: station.arrival_timestamp
        ? toTimezoneMoment(station.arrival_timestamp, station.timezone)
        : undefined,
    departureTime: station.departure_timestamp
        ? toTimezoneMoment(station.departure_timestamp, station.timezone)
        : undefined,
    searchString: stationNameToSearchString(name)
})

export const resolveParentStations = stations => {
    if (!stations) {
        return null
    }

    stations = stations.map(stationMapper)
    const parentStations = stations.reduce(
        (parents, station) => {
            if (station.parent) {
                parents[station.UICStationCode] = station
            }
            return parents
        },
        {}
    )

    return sortBy(
        stations,
        station => {
            const parent = station.parent && parentStations[station.parent]
                ? parentStations[station.parent].name
                : ''
            return parent + station.name
        }
    )
}

export const baseStationsSelector = createSelector(
    [sourceStationsSelector],
    stations => resolveParentStations(stations)
)

const parentAndChildStationCodesSelector = createSelector(
    [baseStationsSelector],
    stations => (stations || []).reduce(
        (children, station) => station.parent ? children.add(station.UICStationCode).add(station.parent) : children,
        new Set()
    )
)

const getStationShortName = (station, parentAndChildStationCodes) =>
    station && (parentAndChildStationCodes.has(station.UICStationCode) ? station.name.split(' ')[0] : station.name)

export const stationShortNameSelector = stationSelector => createSelector(
    [stationSelector, parentAndChildStationCodesSelector],
    (station, parentAndChildStationCodes) => getStationShortName(station, parentAndChildStationCodes)
)

export const stationsSelector = createSelector(
    [baseStationsSelector, parentAndChildStationCodesSelector],
    (stations, parentAndChildStationCodes) => stations && stations.map(station => {
        const shortName = getStationShortName(station, parentAndChildStationCodes)
        return {
            ...station,
            shortName,
            shortNameSuffix: station.name.replace(shortName, '')
        }
    })
)

export const enrichStation = (station, stations) => {
    if (!station) {
        return null
    }
    const mappedStation = station.hasOwnProperty('UICStationCode') ? station : stationMapper(station)
    const apiStation = mappedStation.UICStationCode && stations &&
        stations.find(_station => _station.UICStationCode === mappedStation.UICStationCode)

    return apiStation ? { ...apiStation, ...mappedStation } : mappedStation
}
