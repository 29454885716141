/* globals ENABLE_TRACKING: true */

import React, {Component} from 'react'
import {withRouter} from 'react-router'
import classNames from 'classnames'
import device from '../device'
import Storage from '../storage'
import {STORAGE_WEB_VIEW} from '../constants'

import Container from '../base/container'
import GenericLayoutHeader from './generic-layout/header'
import GenericLayoutFooter from './generic-layout/footer'

import GenericLayoutHeaderVSC from './generic-layout/header-vsc'
import GenericLayoutHeaderVSCNew from './generic-layout/header-vsc-new'
import GenericLayoutFooterVSC from './generic-layout/footer-vsc'
import GenericLayoutFooterVSCNew from './generic-layout/footer-vsc-new'

import GenericLayoutHeaderSNCB from './generic-layout/header-sncb'
import GenericLayoutFooterSNCB from './generic-layout/footer-sncb'

import GenericLayoutHeaderSales from './generic-layout/header-sales-flow'

import Main from '../base/main'

import AffiliateStore from '../reflux/stores/affiliate-store'
import {isNewVSC} from '../misc/new-vsc-helper'
import {isAffiliateSNCBSelector, isAffiliateVSCSelector} from '../reflux/bridge/affiliate'
import {isBlablabusDomain, showBlablaCarBranding} from '../misc/blablabus-helpers'
import {tracktorPageEvent} from '../misc/blablabus-tracktor'
import PropTypes from 'prop-types'
import TagPusherCollector from '../components/tag-pusher-collector'

window.device = device

class GenericLayout extends Component {
    static propTypes = {
        showLanguageSelector: PropTypes.bool,
        showSalesHeader: PropTypes.bool,
        router: PropTypes.object,
        className: PropTypes.string
    }

    static defaultProps = {
        showSalesHeader: false
    }

    componentWillMount () {
        tracktorPageEvent()
    }

    render () {
        const className = classNames('container', this.props.className, {
            aftersales: !this.props.showSalesHeader
        })

        return (
            <Container className={className}>
                {this._renderHeader()}
                <Main>
                    {this.props.children}
                </Main>
                {this._renderFooter()}
                {this._renderTagPusher()}
            </Container>
        )
    }

    _renderTagPusher () {
        if (!ENABLE_TRACKING) {
            return null
        }

        return <div id='tag-commander-client' style={{display: 'none'}}>
            <TagPusherCollector />
        </div>
    }

    _renderHeader () {
        const affiliateCode = AffiliateStore.getAffiliateCode()
        const webView = Storage.get(STORAGE_WEB_VIEW)
        const className = showBlablaCarBranding
            ? 'blablacar'
            : isBlablabusDomain()
                ? 'blablabus'
                : this.props.className
        const {showSalesHeader, ...props} = this.props

        if (webView && affiliateCode === 'vsc-d-frfr') {
            return <GenericLayoutHeaderSales {...props} />
        }

        if (!device.isMobile()) {
            if (isNewVSC()) {
                return <GenericLayoutHeaderVSCNew {...this.props} />
            }

            if (isAffiliateVSCSelector()) {
                return <GenericLayoutHeaderVSC affiliateCode={affiliateCode} {...this.props} />
            }

            if (isAffiliateSNCBSelector()) {
                return <GenericLayoutHeaderSNCB {...props} />
            }
        } else {
            if (isAffiliateVSCSelector()) {
                return <GenericLayoutHeader {...props} className='vsc' />
            }

            if (isAffiliateSNCBSelector()) {
                return <GenericLayoutHeader {...props} className='sncb' />
            }
        }

        if (showSalesHeader) {
            return <GenericLayoutHeaderSales {...props} className={className} />
        }

        return <GenericLayoutHeader {...props} className={className} />
    }

    _renderFooter () {
        const affiliateCode = AffiliateStore.getAffiliateCode()
        const webView = Storage.get(STORAGE_WEB_VIEW)

        if (webView && affiliateCode === 'vsc-d-frfr') {
            return <GenericLayoutFooter {...this.props} />
        }

        if (!device.isMobile()) {
            if (isNewVSC()) {
                return <GenericLayoutFooterVSCNew {...this.props} />
            }

            if (isAffiliateVSCSelector()) {
                return <GenericLayoutFooterVSC affiliateCode={affiliateCode} {...this.props} />
            }

            if (isAffiliateSNCBSelector()) {
                return <GenericLayoutFooterSNCB {...this.props} />
            }
        }

        return <GenericLayoutFooter {...this.props} />
    }
}

export default withRouter(GenericLayout)
