import Reflux from 'reflux'
import actions from '../../reflux/actions'
import moment from 'moment-timezone'
import PassengerDetailsMixin from '../../reflux/mixin/passenger-details-mixin'
import UserAwareMixin from '../../reflux/mixin/user-aware-mixin'
import BookingStore from '../../reflux/stores/booking-store'

export default Reflux.createStore({

    listenables: actions,

    mixins: [PassengerDetailsMixin, UserAwareMixin],

    _initListenTo () {
        this.listenTo(BookingStore, data => {
            if (!data.loading && data.booking.booking_number) {
                this._loadPassengers(data.booking)
                this.data.originalPassengers = data.booking.passengers
            }
        })
    },

    onProcessUpdatePassengerDetailsData (newData) {
        this._processData(newData)
    },

    onResetUpdatePassenger (passengerId) {
        const originalPassenger = this.data.originalPassengers.find(passenger => passenger.id === passengerId)
        const passenger = this.data.passengers.find(passenger => passenger.id === passengerId)

        if (passenger.fields.gender) {
            passenger.fields.gender.value = originalPassenger.gender
        }
        passenger.fields.firstName.value = originalPassenger.first_name
        passenger.fields.lastName.value = originalPassenger.last_name
        if (originalPassenger.birth_date) {
            passenger.fields.dateOfBirth.value = moment(originalPassenger.birth_date).format('DD/MM/YYYY')
        }
        passenger.timestamp = moment().format('x')

        this._validateData()
    }

})
