import React from 'react'
import {Route} from 'react-router'
import authCheck from '../../../mixins/auth'
import RebookingOptionsLayout from '../../../layouts/mys3/re-booking/options-container'

export default (
    <Route path='/:lang/mys3/re-booking/options'
           component={RebookingOptionsLayout}
           onEnter={(nextState, replaceState, callback) => authCheck(nextState, replaceState, callback)}
        />
)
