import React, {Component} from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import map from 'lodash/map'
import kebabCase from 'lodash/kebabCase'
import TabContent from '../../../../element/tab-content'
import SelectField from '../../../../element/form/select-field'
import PaymentTerms from '../payment-terms'
import actions from '../../../../reflux/actions'
import PaymentMethodsStore from '../../../../reflux/stores/payment-methods-store'

export default class Ideal extends Component {
    static propTypes = {
        formData: PropTypes.object.isRequired,
        code: PropTypes.string
    }

    render () {
        return (
            <TabContent visible>
                <form action='#' noValidate>
                    <fieldset>
                        <div className='grid-row'>
                            <div className='grid-column--1-1 grid-column--medium-1-2'>
                                <SelectField {...this._selectFieldProps('bankCode', this._bankOptions())} />
                            </div>
                        </div>
                    </fieldset>
                </form>
                {this.props.children}
                <PaymentTerms />
            </TabContent>
        )
    }

    _selectFieldProps (fieldName, options) {
        let kebabCaseName = kebabCase(fieldName)
        return {
            id: fieldName,
            ref: fieldName,
            className: kebabCaseName,
            data: options,
            value: this.props.formData.fields[fieldName].value,
            labelText: this._prefixFieldLabel(kebabCaseName, 'label'),
            required: this.props.formData.fields[fieldName].isRequired,
            errorText: this._prefixFieldLabel(kebabCaseName, this.props.formData.fields[fieldName].errorText),
            onChange: this.onBlur
        }
    }

    _prefixFieldLabel (field, label) {
        return label ? `payment.form.${field}.${label}` : null
    }

    onBlur (event) {
        actions.processPaymentData([{
            id: event.target.id,
            value: event.target.value
        }])
    }

    _bankOptions () {
        const banks = get(PaymentMethodsStore.getPaymentMethodByCode(this.props.code), 'available_banks')

        return [{text: this._prefixFieldLabel('bank-code', 'placeholder'), value: ''}]
            .concat(map(banks, (text, value) => ({text, value})))
    }
}
