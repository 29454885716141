import PropTypes from 'prop-types'
import React, { Component } from 'react'
import SelectField from '../../../../element/form/select-field'
import {
    NEW_FAVORITE_PASSENGER,
    PASSENGER_TYPE_ADULT,
    PASSENGER_TYPE_YOUTH,
    UPDATE_CRM
} from '../../../../constants'

export default class SelectFavoritePassenger extends Component {
    static propTypes = {
        passenger: PropTypes.object.isRequired,
        isFirstPassenger: PropTypes.bool.isRequired,
        mePassengerId: PropTypes.string,
        favoritePassengers: PropTypes.array,
        selectedPassenger: PropTypes.any,
        onSelectedPassenger: PropTypes.func,
        onBlur: PropTypes.func
    }

    render () {
        return (
            <div className='grid-row'>
                <div className='grid-column--1-1 grid-column--medium-1-3'>
                    <SelectField
                        id={`select-favorite-passenger-${this.props.passenger.id}`}
                        ref='favorite-passenger'
                        className='favorite-passenger'
                        value={this.props.selectedPassenger}
                        data={this.getFavoritePassengerSelectData()}
                        onChange={this.props.onSelectedPassenger}
                    />
                </div>
            </div>
        )
    }

    getFavoritePassengerSelectData () {
        const selectData = []
        const isPassengerYouthOrAdult = this.props.passenger.type === PASSENGER_TYPE_YOUTH || this.props.passenger.type === PASSENGER_TYPE_ADULT
        const mePassengerId = this.props.mePassengerId

        selectData.push({
            text: 'passenger-details-form.favorite-passengers-select.default',
            value: '_',
            disabled: true
        })

        const meOption = {
            text: 'passenger-details-form.favorite-passengers-select.me',
            value: UPDATE_CRM
        }

        if (!mePassengerId && isPassengerYouthOrAdult) {
            // If no 'me' is selected, and the passenger is an adult or youth, offer the option 'me'
            selectData.push(meOption)
        } else if (mePassengerId === this.props.passenger.id) {
            // if this passenger is 'me', offer the option 'me'
            selectData.push(meOption)
        }

        Array.isArray(this.props.favoritePassengers) &&
        selectData.push(
            ...this.props.favoritePassengers
                .map(favoritePassenger => ({
                    text: `${favoritePassenger.first_name} ${favoritePassenger.last_name}`,
                    value: favoritePassenger.ref_id
                }))
                .sort((a, b) => {
                    const aName = a.text.toUpperCase()
                    const bName = b.text.toUpperCase()
                    if (aName < bName) {
                        return -1
                    }
                    if (aName > bName) {
                        return 1
                    }
                    return 0
                })
        )
        selectData.push({
            text: 'passenger-details-form.favorite-passengers-select.new-passenger',
            value: NEW_FAVORITE_PASSENGER
        })

        return selectData
    }
}
