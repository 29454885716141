// Libraries
import React from 'react'
import PropTypes from 'prop-types'
import {withRouter} from 'react-router'
import Reflux from 'reflux'
import _t from '../../translate'
import actions from '../../reflux/actions'

import {
    newOuibusSearchUrl,
    isAbsoluteUrl
} from '../../misc/new-ouibus-helper'
import createReactClass from 'create-react-class'

// Components
import CmsBlockContent from '../../components/cms/cms-block-content'
import GenericLayout from '../../layouts/generic-layout'
import Row from '../../element/row'
import TextLabel from '../../element/text-label'
import cmsComponent from '../../components/cms/cms-component'

export default withRouter(cmsComponent('booking-not-found')(createReactClass({

    displayName: 'BookingNotFoundLayout',

    mixins: [
        Reflux.listenTo(actions.apiError, 'onPreventAllRedirects')
    ],

    propTypes: {
        router: PropTypes.object
    },

    onPreventAllRedirects (error) {
        if (error.error === 'unknown') {
            error.handled = true
        }
    },

    handleClick (e) {
        e.preventDefault()
        const ouibusUrl = newOuibusSearchUrl()
        isAbsoluteUrl(ouibusUrl)
            ? window.location.href = ouibusUrl
            : this.props.router.push(ouibusUrl)
    },

    render () {
        return (
            <GenericLayout className='error-page-layout'>
                <Row className='row contains-error-page'>
                    <div className='grid-row'>
                        <div className='grid-column--1-1 grid-column--medium-1-2 center-grid-column--small'>
                            <div className='error-page'>
                                <div className='panel'>
                                    <header>
                                        <span className='text-label heading'>
                                            <TextLabel
                                                text={_t.getIntlMessage('error-page.booking-not-found.header')} />
                                        </span>
                                    </header>
                                    <div className='content'>
                                        <span className='text-label error-page-content'>
                                            <CmsBlockContent name='booking-not-found' />
                                        </span>
                                        <a
                                            href='#'
                                            onClick={this.handleClick}
                                            className='button primary'
                                        >
                                            {_t.getIntlMessage('error-page.goto-homepage')}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Row>
            </GenericLayout>
        )
    }

})))
