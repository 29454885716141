import React from 'react'
import Reflux from 'reflux'
import { Link } from 'react-router'
import _t from '../../../translate'

import TextLabel from '../../../element/text-label'
import ModalDialog from '../../../element/modal-dialog'
import AccessManager from '../../../data/access-manager'
import CrmUserStore from '../../../reflux/stores/crm-user-store'
import Reach5 from '../../../mixins/reach-5'
import createReactClass from 'create-react-class'

export default createReactClass({

    displayName: 'UserNavigationMyProfileItem',

    mixins: [
        Reflux.connectFilter(CrmUserStore, 'crmUser', data => data.user && data.user.customer),
        Reach5('reach-5-my-profile')
    ],

    getInitialState () {
        return {
            showDialog: false
        }
    },

    componentDidUpdate (_, prevState) {
        if (this.state.showDialog && prevState.showDialog !== this.state.showDialog) {
            this.onShowEmail()
        }
    },

    render () {
        let myProfileLabel = _t.getIntlMessage('user-navigation.my-profile.label')
        const props = {
            to: '#'
        }
        if (AccessManager.isCrmUser()) {
            if (this.state.crmUser && this.state.crmUser.first_name) {
                myProfileLabel = this.state.crmUser.first_name
            }
            props.to = _t.getIntlMessage('user-navigation.my-profile.url')
            props.target = '_blank'
        } else if (AccessManager.isPublicUser() || AccessManager.isBookingUser()) {
            props.onClick = this.showDialog
        }

        return (
            <li>
                <Link {...props} className='link'>
                    <span className='text-label'>
                        <TextLabel text={myProfileLabel} />
                    </span>
                </Link>
                {this.renderReach5DialogModal()}
            </li>
        )
    },

    renderReach5DialogModal () {
        return this.state.showDialog ? (
            <ModalDialog
                headerTitle=' '
                onClose={this.hideDialog}
            >
                <div id='reach-5-my-profile' />
            </ModalDialog>
        ) : null
    },

    showDialog (e) {
        e.preventDefault()
        this.setState({showDialog: true})
    },

    hideDialog () {
        this.setState({showDialog: false})
    }
})
