import React from 'react'
import PropTypes from 'prop-types'
import Reflux from 'reflux'
import _t from '../translate'
import _ from 'lodash'
import actions from '../reflux/actions'
import BookingParameterEncoder from '../data/booking-parameter-encoder'
import BookingLoginStore from '../reflux/stores/booking-login-store'
import AffiliateStore from '../reflux/stores/affiliate-store'
import Loader from '../element/loader'
import BookingModel from '../models/booking-model'
import Storage from '../storage'
import {STORAGE_TARGET, STORAGE_TRACKTOR} from '../constants'
import {tracktorProbeEvent} from '../misc/blablabus-tracktor'
import {storeTracktorIdentifiers} from '../misc/tracktor'
import createReactClass from 'create-react-class'
import {BLABLACAR, BLABLACAR_APP} from '../data/affiliate-constants'
import GoogleTaggingStore from '../reflux/stores/google-tagging-store'

const routeMap = {
    payment: 'booking/payment',
    options: 'booking/options'
}

export default Component => createReactClass({

    displayName: 'BookingLoginLayout',

    propTypes: {
        router: PropTypes.object
    },

    mixins: [
        Reflux.listenTo(BookingLoginStore, 'onBookingLoginUpdate'),
        Reflux.listenTo(actions.getBooking.completed, 'onBookingCompleted')
    ],

    getInitialState () {
        return {
            formData: BookingLoginStore.getInitialState(),
            buttonLoading: false,
            authenticate: false,
            serviceError: null
        }
    },

    componentDidMount () {
        const credentials = BookingParameterEncoder.decode(this._getQueryCode())
        const mktCode = this._getMktCode()
        let {
            affiliateCode,
            visid,
            devid,
            sstamp
        } = this._getQuery()
        if (affiliateCode) {
            (affiliateCode.toLowerCase() === BLABLACAR || affiliateCode.toLowerCase() === BLABLACAR_APP) ? storeTracktorIdentifiers(visid, devid, sstamp) : Storage.clear(STORAGE_TRACKTOR)
            AffiliateStore.onChangeAffiliateCode(affiliateCode)
        }

        mktCode && tracktorProbeEvent(mktCode)
        mktCode && GoogleTaggingStore.onProcessDeepLink({'comuto_cmkt': mktCode})
        if (credentials) {
            this.onLogin([{
                id: 'bookingReference',
                value: credentials.bookingNumber
            }, {
                id: 'email',
                value: credentials.email
            }, {
                id: 'code',
                value: this._getQuerySalesChannel()
            }])
        }
    },

    _getQuery () {
        return _.get(this.props, 'location.query')
    },

    _getQueryCode () {
        return _.get(this.props, 'location.query.code')
    },

    _getQuerySalesChannel () {
        return _.get(this.props, 'location.query.salesChannel')
    },

    _getTarget () {
        return _.get(this.props, 'location.query.target')
    },

    _getMktCode () {
        return _.get(this.props, 'location.query.comuto_cmkt')
    },

    onBookingLoginUpdate (data) {
        const authenticate = this.state.authenticate
        this.setState(
            {formData: data, authenticate: false},
            () => {
                if (data.isValid && authenticate) {
                    this._doLogin(data)
                } else {
                    this.setState({buttonLoading: false})
                }
            }
        )
    },

    onBookingCompleted (data) {
        const booking = BookingModel.create(data.data.booking)
        if (booking.provisional) {
            booking.affiliateCode && actions.changeAffiliateCode(booking.affiliateCode)
            Storage.set(STORAGE_TARGET, this._getTarget())
            this.props.router.push(`/${_t.getLocales()}/${routeMap[`${this._getTarget()}`]}`)
        } else {
            this._redirectToReferrer()
        }
    },

    _redirectToReferrer () {
        window.alert(_t.getIntlMessage('booking-login.error.booking-not-found-redirect'))
        window.location.replace(document.referrer)
    },

    _doLogin (data) {
        if (data.form === 'passenger') {
            actions.bookingLoginByPassenger(
                data.fields.bookingReference.value,
                data.fields.firstName.value,
                data.fields.lastName.value
            ).then(this.onAuthenticationSuccess, this.onAuthenticationFailed)
        } else {
            actions.bookingLogin(data.fields.bookingReference.value, data.fields.email.value, data.fields.code.value)
                .then(this.onAuthenticationSuccess, this.onAuthenticationFailed)
        }
    },

    onAuthenticationSuccess (resolve) {
        actions.loadUserLoginInformation(resolve).then(() => {
            const bookingReference = this.state.formData.fields.bookingReference.value
            const aftersalesOperation = _.get(this.props, 'location.query.aftersales')

            let query = ''
            if (aftersalesOperation) {
                query = `?aftersales=${aftersalesOperation}`
            }
            if (Object.keys(routeMap).includes(this._getTarget())) {
                try {
                    actions.getBooking(bookingReference)
                } catch (err) {
                    this._redirectToReferrer()
                }
            } else {
                this.props.router.push(`/${_t.getLocales()}/mys3/booking/${bookingReference}${query}`)
            }
        })
    },

    onAuthenticationFailed () {
        if (Object.keys(routeMap).includes(this._getTarget())) {
            this._redirectToReferrer()
        } else {
            const serviceError = _t.getIntlMessage('booking-login.error.booking-not-found')
            this.setState({buttonLoading: false, serviceError}, () =>
                this._getQueryCode() && this.history.push(`/${_t.getLocales()}/booking-login`)
            )
        }
    },

    onLogin (credentials) {
        this.setState(
            {buttonLoading: true, authenticate: true},
            () => actions.processBookingLoginData(credentials)
        )
    },

    render () {
        if (this._getQueryCode()) {
            return <Loader blocking />
        }

        return (
            <Component
                serviceError={this.state.serviceError}
                errors={this._getErrors()}
                loading={this.state.buttonLoading}
                onLogin={this.onLogin} />
        )
    },

    _getErrors () {
        let errors = {}
        _.forEach(this.state.formData.fields, (field, key) => {
            if (field.errorText) {
                errors[key] = `booking-login.login-${_.kebabCase(key)}.${field.errorText}`
            }
        })

        return errors
    }

})
