import PropTypes from 'prop-types'
import React, { Component } from 'react'
import InputField from './input-field'

export default class ToggleSlider extends Component {
    static propTypes = {
        labelPosition: PropTypes.string
    }

    render () {
        const {labelPosition, ...props} = this.props
        return (
            <div className='toggle-slider check-radio-wrapper'>
                <InputField
                    ref='inputField'
                    type='checkbox'
                    labelPosition={labelPosition || 'after'}
                    toggleSlider={<div className='slider' />}
                    {...props} />
            </div>
        )
    }

    getInputElement () {
        return this.refs.inputField && this.refs.inputField.getInputElement()
    }

    getValue () {
        return this.refs.inputField && this.refs.inputField.getValue()
    }

    setValue (value) {
        return this.refs.inputField && this.refs.inputField.setValue(value)
    }

    focus () {
        return this.refs.inputField && this.refs.inputField.focus()
    }
}
