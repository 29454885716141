import React from 'react'
import {Link} from 'react-router'
import Reflux from 'reflux'
import JolliCodeDiscountCardStore, {
    DISCOUNT_CARD_ERROR_CODE_BIRTH_DATE_DOES_NOT_MATCH,
    DISCOUNT_CARD_ERROR_CODE_CARD_EXPIRED,
    DISCOUNT_CARD_ERROR_CODE_CARD_NOT_ENABLED,
    DISCOUNT_CARD_ERROR_CODE_CARD_NOT_EXIST,
    DISCOUNT_CARD_ERROR_CODE_NAME_DOES_NOT_NATCH
} from '../../../reflux/stores/jollicode-discount-card-store'
import _t from '../../../translate'
import actions from '../../../reflux/actions'
import ModalDialog from '../../../element/modal-dialog'
import TextLabel from '../../../element/text-label'
import createReactClass from 'create-react-class'

export default createReactClass({
    displayName: 'ReBookingDiscountCardModal',

    getInitialState () {
        return {
            showDiscountCardDialog: false
        }
    },

    mixins: [
        Reflux.listenTo(actions.validateBookingDiscountCards.completed, '_checkDiscountCardErrors')
    ],

    _checkDiscountCardErrors () {
        if (JolliCodeDiscountCardStore.hasViolationCodes()) {
            this.setState({showDiscountCardDialog: true})
        }
    },

    onCloseDiscountCardDialog () {
        this.setState({
            showDiscountCardDialog: false
        })
    },

    _getDiscountCardErrorCode () {
        const errorCodes = JolliCodeDiscountCardStore.getViolationCodes()

        const priorities = [
            DISCOUNT_CARD_ERROR_CODE_CARD_EXPIRED,
            DISCOUNT_CARD_ERROR_CODE_CARD_NOT_ENABLED,
            DISCOUNT_CARD_ERROR_CODE_CARD_NOT_EXIST,
            DISCOUNT_CARD_ERROR_CODE_NAME_DOES_NOT_NATCH,
            DISCOUNT_CARD_ERROR_CODE_BIRTH_DATE_DOES_NOT_MATCH
        ]

        return priorities.find(code => errorCodes.includes(code))
    },

    render () {
        if (!this.state.showDiscountCardDialog) {
            return null
        }

        const errorCode = this._getDiscountCardErrorCode()

        if (!errorCode) {
            return null
        }

        return (
            <ModalDialog headerTitle={_t.message(`my-s3-re-booking.journey-search.discount-card-dialog.${errorCode}.title`)} onClose={this.onCloseDiscountCardDialog}>
                <div className='content'>
                    <h3>{_t.message(`my-s3-re-booking.journey-search.discount-card-dialog.${errorCode}.description`)}</h3>
                    <p>{_t.message('my-s3-re-booking.journey-search.discount-card-dialog.content')}</p>
                    <Link to={`${_t.getLocales()}/mys3/cancellation`} className='button primary next'>
                        <span className='text-label'>
                            <TextLabel text={_t.formatIntlMessage('my-s3-re-booking.journey-search.discount-card-dialog.button')} />
                        </span>
                    </Link>
                </div>
            </ModalDialog>
        )
    }
})
