import React from 'react'
import PropTypes from 'prop-types'
import {withRouter} from 'react-router'
import Reflux from 'reflux'
import get from 'lodash/get'
import defer from 'lodash/defer'
import _t from '../translate'
import GenericLayout from './generic-layout'
import Row from '../element/row'
import AfterSalesConfirmationContainer from '../components/after-sales-confirmation-container'
import BookingStore from '../reflux/stores/booking-store'
import FlashMessageStore from '../reflux/stores/flash-message-store'
import actions from '../reflux/actions'
import BookingModel from '../models/booking-model'
import restoreBooking from '../components/restore/restore-booking'
import createReactClass from 'create-react-class'

export default withRouter(restoreBooking(createReactClass({
    displayName: 'MyS3UpdatePassengerConfirmLayout',

    propTypes: {
        router: PropTypes.object
    },

    mixins: [
        Reflux.connectFilter(BookingStore, 'booking', data => BookingModel.create(data.booking))
    ],

    onPaymentSuccess () {
        actions.myS3UpdatePassengersSuccessful()
        defer(() => this.props.router.push(`/${_t.getLocales()}/mys3/update-passengers/confirmation?s3_status=success`))
    },

    onPaymentFailed () {
        actions.showFlashMessage({
            text: _t.message('flash-message.payment-cancelled'),
            severity: FlashMessageStore.severity.highlight
        })
        this.props.router.push(`/${_t.getLocales()}/mys3/update-passengers/payment`)
    },

    onPaymentCancelled () {
        actions.showFlashMessage({
            text: _t.message('flash-message.payment-failed'),
            severity: FlashMessageStore.severity.warning
        })
        this.props.router.push(`/${_t.getLocales()}/mys3/update-passengers/payment`)
    },

    render () {
        return (
            <GenericLayout className='update-passengers-confirmation'>
                <Row className='row contains-after-sales-confirmation'>
                    {this.state.booking ? (
                        <AfterSalesConfirmationContainer
                            status={get(this.props, 'location.query.s3_status')}
                            booking={this.state.booking}
                            onSuccess={this.onPaymentSuccess}
                            onFailed={this.onPaymentFailed}
                            onCancelled={this.onPaymentCancelled}
                        />
                    ) : null}
                </Row>
            </GenericLayout>
        )
    }
})))
