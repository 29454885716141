import PropTypes from 'prop-types'
import React, {Component} from 'react'
import ReactDOM from 'react-dom'
import Tether from 'tether'

export default class TetherPopover extends Component {
    static propTypes = {
        attachment: PropTypes.string,
        targetAttachment: PropTypes.string,
        targetOffset: PropTypes.string,
        target: PropTypes.string.isRequired,
        constraints: PropTypes.arrayOf(
            PropTypes.shape({
                to: PropTypes.string.isRequired,
                attachment: PropTypes.string.isRequired
            })
        ),
        className: PropTypes.string
    }

    static defaultProps = {
        attachment: 'top center',
        constraints: [
            {
                to: 'window',
                attachment: 'together',
                pin: true
            }
        ],
        targetAttachment: 'bottom center',
        targetOffset: '10px 0'
    }

    componentWillMount () {
        let popoverContainer = document.createElement('div')
        popoverContainer.className = 'popover__container'

        this._popoverElement = popoverContainer

        document.querySelector('body').appendChild(this._popoverElement)
    }

    componentDidMount () {
        this._renderPopover()
    }

    componentDidUpdate () {
        this._renderPopover()
    }

    _popoverComponent () {
        return (
            <div className={this.props.className}>
                {this.props.children}
            </div>
        )
    }

    _tetherOptions () {
        return {
            element: this._popoverElement,
            target: document.getElementById(this.props.target),
            attachment: this.props.attachment,
            targetAttachment: this.props.targetAttachment,
            targetOffset: this.props.targetOffset,
            optimizations: {
                moveElement: false
            },
            constraints: this.props.constraints
        }
    }

    _renderPopover () {
        if (this._tether !== undefined) {
            this._tether.setOptions(this._tetherOptions())
        } else if (window && document) {
            this._tether = new Tether(this._tetherOptions())
        }

        ReactDOM.render(this._popoverComponent(), this._popoverElement, () => this._tether.position())
    }

    componentWillUnmount () {
        this._tether.destroy()
        ReactDOM.unmountComponentAtNode(this._popoverElement)
        if (this._popoverElement.parentNode) {
            this._popoverElement.parentNode.removeChild(this._popoverElement)
        }
    }

    render () {
        return <span />
    }
}
