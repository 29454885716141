import React from 'react'
import {Route} from 'react-router'
import authCheck from '../../mixins/auth'
import BookingLuggageSelectorLayout from '../../layouts/booking/luggage-selector'

export default (
    <Route
        path='/:lang/booking/luggage'
        component={BookingLuggageSelectorLayout}
        onEnter={(nextState, replaceState, callback) => authCheck(nextState, replaceState, callback)}
    />
)
