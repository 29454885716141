import React from 'react'
import {Route} from 'react-router'
import authCheck from '../../mixins/auth'
import BookingBikeSelectorLayout from '../../layouts/booking/bike-selector'

export default (
    <Route
        path='/:lang/booking/bike'
        component={BookingBikeSelectorLayout}
        onEnter={(nextState, replaceState, callback) => authCheck(nextState, replaceState, callback)}
    />
)
