import PropTypes from 'prop-types'
import React, {Component} from 'react'
import Button from '../../element/button'
import TextLabel from '../../element/text-label'
import Icon from '../../element/icon'
import StationSelector from '../../element/station-selector'
import _t from '../../translate'
import device from '../../device'

export default class OriginDestinationContainer extends Component {
    static propTypes = {
        getOriginStationData: PropTypes.func.isRequired,
        origin: PropTypes.string,
        destination: PropTypes.string,
        getDestinationStationData: PropTypes.func.isRequired,
        onOriginSelected: PropTypes.func,
        onDestinationSelected: PropTypes.func,
        onSwapOriginAndDestination: PropTypes.func,
        isOriginAndDestinationSelected: PropTypes.func
    }

    constructor (...args) {
        super(...args)
        this.onStationSelectorBlur = this.onStationSelectorBlur.bind(this)
        this.onStationSelectorFocus = this.onStationSelectorFocus.bind(this)
        this.onClearDestination = this.onClearDestination.bind(this)
        this.onClearOrigin = this.onClearOrigin.bind(this)
    }

    render () {
        return (
            <div className='origin-destination'>
                <div className='origin'>
                    <div className='grid-row'>
                        <div className='grid-column--1-3 grid-column--medium-1-4'>
                            <label
                                htmlFor='journey-search-origin'
                                className='text-label journey-search-origin inline-label'
                            >
                                {_t.getIntlMessage('journey-search.origin.label')}
                            </label>
                        </div>
                        <div className='grid-column--2-3 grid-column--medium-3-4'>
                            {this.props.origin ? this.renderClearButton(this.onClearOrigin) : null}
                            <StationSelector
                                data={this.props.getOriginStationData()}
                                id='journey-search-origin'
                                name='journey-search-origin'
                                placeholder={_t.getIntlMessage('journey-search.origin.placeholder')}
                                value={this.props.origin}
                                required
                                groupByCountry
                                className='journey-search-origin no-margin'
                                onChange={this.props.onOriginSelected}
                                onFocus={this.onStationSelectorFocus}
                                onBlur={this.onStationSelectorBlur}
                                matcherFields={['name', 'synonyms']}
                                autoCompleteOptions={{maxLength: 12}}
                            />
                        </div>
                    </div>
                </div>
                <div className='destination'>
                    <div className='grid-row'>
                        <div className='grid-column--1-3 grid-column--medium-1-4'>
                            <label
                                htmlFor='journey-search-destination'
                                className='text-label journey-search-destination inline-label'
                            >
                                {_t.getIntlMessage('journey-search.destination.label')}
                            </label>
                        </div>
                        <div className='grid-column--2-3 grid-column--medium-3-4'>
                            {this.props.destination ? this.renderClearButton(this.onClearDestination) : null}
                            <StationSelector
                                data={this.props.getDestinationStationData()}
                                id='journey-search-destination'
                                name='journey-search-destination'
                                placeholder={_t.getIntlMessage('journey-search.destination.placeholder')}
                                value={this.props.destination}
                                required
                                groupByCountry
                                className='journey-search-destination no-margin'
                                onChange={this.props.onDestinationSelected}
                                onFocus={this.onStationSelectorFocus}
                                onBlur={this.onStationSelectorBlur}
                                matcherFields={['name', 'synonyms']}
                                autoCompleteOptions={{maxLength: 12}}
                            />
                        </div>
                    </div>
                </div>
                {this.props.isOriginAndDestinationSelected()
                    ? <Button
                        ref='swapButton'
                        type='button'
                        title={_t.getIntlMessage('journey-search.button.swap-stations-title')}
                        className='button default icon-circle swap'
                        onClick={this.props.onSwapOriginAndDestination}
                    >
                        <TextLabel text={_t.getIntlMessage('journey-search.button.swap-stations-text')} />
                        <Icon className='small swap' type='swap' />
                    </Button>
                    : null
                }
            </div>
        )
    }

    onClearOrigin () {
        this.props.onOriginSelected('', () => this.props.onDestinationSelected(''))
    }

    onClearDestination () {
        this.props.onDestinationSelected('')
    }

    onStationSelectorFocus () {
        if (device.isDesktopOrTablet() && this.props.isOriginAndDestinationSelected()) {
            this.refs.swapButton.setState({visible: false})
        }
    }

    onStationSelectorBlur () {
        if (device.isDesktopOrTablet() && this.props.isOriginAndDestinationSelected()) {
            this.refs.swapButton.setState({visible: true})
        }
    }

    renderClearButton (onClick) {
        return <Button
            type='button'
            className='button secondary icon-circle clear-input'
            onClick={onClick}>
            <TextLabel text={'clear'} />
            <Icon className='small cross' type='cross' />
        </Button>
    }
}
