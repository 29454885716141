// Libraries
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from '../../translate'

import countries from 'countries-list'

import TextLabel from '../../element/text-label'

const addressPropType = PropTypes.shape({
    street: PropTypes.string,
    house_number: PropTypes.string,
    postal_code: PropTypes.string,
    city: PropTypes.string,
    country_code: PropTypes.string
})

const customerPropType = PropTypes.shape({
    address: addressPropType,
    title: PropTypes.string,
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    phone_number: PropTypes.string
})

export default class CustomerDetails extends Component {
    static propTypes = {
        customer: customerPropType
    }

    getFullName () {
        return `${this.getTitle()} ${this.props.customer.first_name} ${this.props.customer.last_name}`
    }

    getCountry () {
        const countryCode = this.props.customer.address && this.props.customer.address.country_code
        return countryCode && countryCode in countries.countries ? countries.countries[countryCode].name : ''
    }

    getTitle () {
        return this.props.customer.title
            ? _t.getIntlMessage('personal-information-form.title.values.' + this.props.customer.title.toLowerCase()) : ''
    }

    getCustomerDetailFields () {
        return [
            {
                label: _t.getIntlMessage('my-s3-booking.customer-details.name'),
                value: this.getFullName()
            },
            {
                label: _t.getIntlMessage('my-s3-booking.customer-details.postal-code'),
                value: (this.props.customer.address && this.props.customer.address.postal_code) || '-'
            },
            {
                label: _t.getIntlMessage('my-s3-booking.customer-details.country'),
                value: this.getCountry()
            },
            {
                label: _t.getIntlMessage('my-s3-booking.customer-details.email'),
                value: this.props.customer.email
            },
            {
                label: _t.getIntlMessage('my-s3-booking.customer-details.phone-number'),
                value: this.props.customer.phone_number || ''
            }
        ]
    }

    render () {
        return (
            <div className='my-s3-booking-customer-details'>
                <div className='panel'>
                    <header>
                        <h3>{_t.getIntlMessage('my-s3-booking.customer-details.heading')}</h3>
                    </header>
                    <div className='content'>
                        {this.getCustomerDetailFields().map((field, key) => (
                            <div key={key} className='grid-row'>
                                <div className='grid-column--1-2'>
                                    <span className='text-label customer-information-label'>
                                        <TextLabel text={field.label} />
                                    </span>
                                </div>
                                <div className='grid-column--1-2'>
                                    <span className='text-label customer-value overflow-ellipsis' title={field.value}>
                                        <TextLabel text={field.value} />
                                    </span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        )
    }
}
