import Reflux from 'reflux'
import UserAwareMixin from '../../reflux/mixin/user-aware-mixin'
import actions from '../../reflux/actions'

export default Reflux.createStore({

    listenables: actions,

    mixins: [UserAwareMixin],

    init () {
        this._resetData()
    },

    _resetData () {
        this.data = {
            services: []
        }
    },

    resetData () {
        this._resetData()
        this.trigger(this.data)
    },

    getInitialState () {
        return this.data
    },

    onRequestJourneyDetailsCompleted (response) {
        this.data.services = this.data.services.concat(response.data.services)
        this.trigger(this.data)
    }
})
