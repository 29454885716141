import {createSelector} from 'reselect'
import {PAYMENT_STATUS_P} from '../../../../../constants'
import {bookingSelector} from '../../../../../reflux/bridge/booking'

export const voucherSelector = createSelector(
    [bookingSelector],
    booking => ((booking && booking.vouchers) || []).map(
        voucher => ({
            ...voucher,
            isPending: booking.payments.some(
                payment => payment.voucher_id === voucher.id && PAYMENT_STATUS_P === payment.payment_status
            ),
            payment: booking.payments.find(payment => payment.voucher_id === voucher.id)
        })
    )
)

export const pendingVoucherSelector = createSelector(
    [voucherSelector],
    vouchers => vouchers.filter(voucher => voucher.isPending)
)
