import PropTypes from 'prop-types'
import React, {Component} from 'react'
import kebabCase from 'lodash/kebabCase'
import _t from '../../../../translate'
import TabContent from '../../../../element/tab-content'
import Icon from '../../../../element/icon'
import TextLabel from '../../../../element/text-label'
import TelephoneField from '../../../../element/form/telephone-field'
import Tooltip from '../../../../element/tooltip'
import PaymentTerms from '../payment-terms'
import actions from '../../../../reflux/actions'

export default class CreditCard extends Component {
    static propTypes = {
        formData: PropTypes.object.isRequired,
        customerName: PropTypes.string.isRequired
    }

    constructor (...args) {
        super(...args)
        this.state = {
            tooltipVisible: false
        }
        this.openTooltip = this.openTooltip.bind(this)
        this.closeTooltip = this.closeTooltip.bind(this)
        this._renderCvcField = this._renderCvcField.bind(this)
        this._renderFormError = this._renderFormError.bind(this)
        this._selectFieldProps = this._selectFieldProps.bind(this)
        this._textFieldProps = this._textFieldProps.bind(this)
        this._numberFieldProps = this._numberFieldProps.bind(this)
        this.onChangeCvc = this.onChangeCvc.bind(this)
        this.onBlur = this.onBlur.bind(this)
    }

    componentDidUpdate () {
        if (this.props.formData.fields.cardHolder &&
            this.props.formData.fields.cardHolder.value === null &&
            this.props.customerName
        ) {
            actions.processPaymentData([{
                id: 'cardHolder',
                value: this.props.customerName
            }])
        }
    }

    openTooltip (event) {
        event.preventDefault()
        this.setState({tooltipVisible: true})
    }

    closeTooltip () {
        this.setState({tooltipVisible: false})
    }

    render () {
        return (
            <TabContent visible>
                {this._renderFormError()}
                <div className='credit-card-container'>
                    <form action='#' noValidate>
                        <fieldset>
                            {this._renderCreditCardInformation()}
                            <div className='grid-row'>
                                <div className='grid-column--1-1 grid-column--medium-1-1'>
                                    <TelephoneField
                                        className='card-number'
                                        mask={[/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/]}
                                        autoComplete='off'
                                        valueFilter={this._filterCreditCardNumber}
                                        {...this._textFieldProps('cardNumber')}
                                    />
                                </div>
                            </div>
                        </fieldset>
                        <fieldset>
                            <div className='grid-row'>
                                <div className='grid-column--1-1 grid-column--medium-1-2'>
                                    <TelephoneField
                                        mask={[/\d/, /\d/, '/', /\d/, /\d/]} {...this._textFieldProps('validUntil')} />
                                </div>
                                {this._renderCvcField()}
                            </div>
                        </fieldset>
                        <PaymentTerms />
                    </form>
                </div>
            </TabContent>
        )
    }

    _renderCreditCardInformation () {
        return (
            <div className='custom-content'>
                <div className='secure-payment'>
                    <span className='text-label secure-payment-heading'>
                        <Icon type='lock' className='xsmall align-left' />
                        <TextLabel text={_t.getIntlMessage('payment.creditcard-information.header')} />
                    </span>
                    <div className='secure-payment-logos'>
                        <ul className='horizontal-list'>
                            <li>
                                <span className='text-label secure-payment-logo verified-by-visa'>
                                    <TextLabel text={_t.getIntlMessage(
                                        'payment.creditcard-information.visa-verified'
                                    )} />
                                </span>
                            </li>
                            <li>
                                <span className='text-label secure-payment-logo mastercard-securecode'>
                                    <TextLabel text={_t.getIntlMessage(
                                        'payment.creditcard-information.mastercard-securecode'
                                    )} />
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }

    _renderCvcField () {
        return (
            <div className='grid-column--3-5 grid-column--medium-1-2 cvc-field'>
                <TelephoneField onChange={this.onChangeCvc} {...this._numberFieldProps('cvcCode')} />
                <span
                    className='text-label sub-label has-tooltip cvc-code'
                    onClick={this.openTooltip}
                >
                    <Icon id='cvc-tooltip-icon' className='small align-left' type='info' />
                </span>
                <Tooltip
                    className='security-code'
                    target='cvc-tooltip-icon'
                    visible={this.state.tooltipVisible}
                    position='top'
                    onClose={this.closeTooltip}
                    title={_t.getIntlMessage(this._prefixFieldLabel('tooltip', 'title'))}
                >
                    <span className='text-label tooltip-content'>
                        <Icon type='payment-security' />
                        <TextLabel text={_t.getIntlMessage(this._prefixFieldLabel('tooltip', 'body'))} />
                    </span>
                </Tooltip>
            </div>
        )
    }

    _renderFormError () {
        const error = this.props.formData.errorText
        if (!error || error === 'timeout' || error === 'refused' || error === 'cancelled') {
            return null
        }

        return (
            <div className='feedback warning'>
                <span className='label feedback-message'>
                    {_t.getIntlMessage(`payment.form.${this.props.formData.errorText}`)}
                </span>
            </div>
        )
    }

    _selectFieldProps (fieldName, options) {
        let kebabCaseFieldName = kebabCase(fieldName)
        const field = this.props.formData.fields[fieldName] || {}
        return {
            id: fieldName,
            ref: fieldName,
            className: kebabCaseFieldName,
            data: options,
            value: field.value,
            labelText: '',
            required: field.isRequired,
            errorText: this._prefixFieldLabel(kebabCaseFieldName, field.errorText),
            onChange: this.onSelect
        }
    }

    _textFieldProps (fieldName) {
        let kebabCaseFieldName = kebabCase(fieldName)
        const field = this.props.formData.fields[fieldName] || {}
        return {
            id: fieldName,
            ref: fieldName,
            className: kebabCaseFieldName,
            labelText: this._prefixFieldLabel(kebabCaseFieldName, 'placeholder'),
            floatingLabel: true,
            value: field.value || '',
            required: field.isRequired,
            errorText: this._prefixFieldLabel(kebabCaseFieldName, field.errorText),
            onBlur: this.onBlur,
            onChange: this.onBlur
        }
    }

    _numberFieldProps (fieldName) {
        const props = this._textFieldProps(fieldName)
        props.defaultValue = props.defaultValue ? Number(props.defaultValue) : null
        props.onChange = this.onChangeCvc
        return props
    }

    checkboxFieldProps (fieldName) {
        let kebabCaseFieldName = kebabCase(fieldName)
        const field = this.props.formData.fields[fieldName] || {}
        return {
            id: fieldName,
            ref: fieldName,
            value: 1,
            checked: Boolean(field.value),
            required: field.isRequired,
            errorText: this._prefixFieldLabel(kebabCaseFieldName, field.errorText),
            onChange: this.onCheckboxClicked
        }
    }

    _prefixFieldLabel (field, label) {
        return label ? `payment.form.${field}.${label}` : null
    }

    _filterCreditCardNumber (rawValue) {
        return rawValue.replace(/\D+/g, '')
    }

    onChangeCvc (event) {
        const stripped = event.target.value.replace(/[^0-9]/g, '')
        event.target.value = stripped
        if (event.target.value.length > 4) {
            event.target.value = event.target.value.slice(0, 4)
        }

        this.onBlur(event)
    }

    onCheckboxClicked (event) {
        actions.processPaymentData([{
            id: event.target.id,
            value: event.target.checked
        }])
    }

    onBlur (event) {
        const id = event.target.id
        let value = event.target.value

        if (id === 'cardNumber') {
            value = this._filterCreditCardNumber(value)
        }
        if (id === 'cvcCode') {
            value = value.toString()
        }
        actions.processPaymentData([{id: id, value: value}])
    }
}
