import React from 'react'
import {Route} from 'react-router'
import authCheck from '../../../mixins/auth'
import MyS3LuggageConfirmLayout from '../../../layouts/mys3/luggage/luggage-confirmation'

export default (
    <Route
        path='/:lang/mys3/luggage/confirm'
        component={MyS3LuggageConfirmLayout}
        onEnter={(nextState, replaceState, callback) => authCheck(nextState, replaceState, callback)}
    />
)
