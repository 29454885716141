import PropTypes from 'prop-types'
import React, { Component } from 'react'
import WheelchairPanel from './additional-product-selector/wheelchair-panel'
import SalesChannelManager from '../../data/sales-channel-manager'
import {connectState, getState} from '../../reflux/bridge/connect-state'
import actions from '../../reflux/actions'
import {passengersSelector} from '../../models/selectors/api/v2/orientation/passenger'
import {
    selectedDisabilityProductsSelector,
    hasAvailabilitySelector,
    wheelchairProductsSelector
} from '../../models/selectors/components/orientation/wheelchair'
import BookingStore from '../../reflux/stores/booking-store'
import {
    tariffSegmentsSelector,
    withoutCancelledSelector as withoutCancelledSegmentsSelector
} from '../../models/selectors/api/v2/booking/tariff-segments'
import {withoutCancelledSelector} from '../../models/selectors/api/v2/booking/products'
import {bookingSelector} from '../../reflux/bridge/booking'
import {currencyBookingSelector} from '../../models/selectors/api/v2/booking/booking'

const SALESCHANNEL_CRC = 'CRC'

export const addWheelchairItems = async bookingNumber => {
    const state = getState()
    const currency = currencyBookingSelector(state)
    const passengers = passengersSelector(state)
    const tariffSegments = withoutCancelledSegmentsSelector(tariffSegmentsSelector(bookingSelector))(state)
    const wheelchairProducts = wheelchairProductsSelector(state)

    // add 1 product to first tariffSegment
    if (tariffSegments[0]) {
        const segment = tariffSegments[0]
        const product = wheelchairProducts
            .find(whProduct => whProduct.direction === segment.direction)
        const passenger = passengers[0]

        const payload = {
            currency,
            segments: [{
                origin: segment.departureStation.UICStationCode,
                destination: segment.arrivalStation.UICStationCode,
                serviceName: segment.serviceName,
                serviceIdentifier: segment.serviceIdentifier,
                startValidityDate: segment.travelDate.format('YYYY-MM-DD'),
                items: [{
                    passengerId: passenger.id,
                    tariffCode: product.passengerFares
                        .find(fare => fare.passengerId === passenger.id)
                        .tariffCode
                }]
            }]
        }

        await actions.patchBooking(bookingNumber, payload)
    }
}

const removeWheelchairItems = async bookingNumber => {
    const state = getState()
    const itemsToBeRemoved = selectedDisabilityProductsSelector(state)
        .map(item => item.itemRef)

    if (itemsToBeRemoved.length) {
        await actions.deleteItems(bookingNumber, {
            item_refs: itemsToBeRemoved
        })
    }
}

const mapPropsToProps = () => {
    const state = getState()
    const bookingNumber = BookingStore.getBookingNumber()
    const products = withoutCancelledSelector(selectedDisabilityProductsSelector)(state)
    return {
        checked: products.length > 0,
        disabled: BookingStore.isLoading(),
        hasAvailability: hasAvailabilitySelector(state),
        passengerCount: passengersSelector(state).length,
        isCRCSalesChannel: SalesChannelManager.channelName === SALESCHANNEL_CRC,
        onRemoveWheelchairItems: () => removeWheelchairItems(bookingNumber),
        onAddWheelchairItems: () => addWheelchairItems(bookingNumber)
    }
}

const MODAL_SUPPORT = 'support'
const MODAL_INVENTORY = 'inventory'
const MODAL_CONFIRM = 'confirm'

class WheelchairContainer extends Component {
    static propTypes = {
        disabled: PropTypes.bool,
        checked: PropTypes.bool,
        hasAvailability: PropTypes.bool,
        passengerCount: PropTypes.number,
        isCRCSalesChannel: PropTypes.bool,
        onAddWheelchairItems: PropTypes.func.isRequired,
        onRemoveWheelchairItems: PropTypes.func.isRequired
    }

    constructor (...args) {
        super(...args)

        this.onChange = this.onChange.bind(this)
        this.onConfirm = this.onConfirm.bind(this)
        this.onCancel = this.onCancel.bind(this)

        this.state = {
            showDialog: null
        }
    }

    onCancel () {
        this.setState({showDialog: null})
    }

    onConfirm () {
        if (!this.props.isCRCSalesChannel) {
            this.setState({showDialog: MODAL_SUPPORT})
        } else if (!this.props.hasAvailability) {
            this.setState({showDialog: MODAL_INVENTORY})
        } else {
            this.props.onAddWheelchairItems().then(this.onCancel)
        }
    }

    onChange () {
        if (this.props.checked) {
            this.props.onRemoveWheelchairItems()
        } else {
            this.setState({showDialog: MODAL_CONFIRM})
        }
    }

    render () {
        return (
            <WheelchairPanel
                onChange={this.onChange}
                checked={this.props.checked}
                disabled={this.props.disabled}
                showSupportDialog={this.state.showDialog === MODAL_SUPPORT}
                showInventoryDialog={this.state.showDialog === MODAL_INVENTORY}
                showConfirmDialog={this.state.showDialog === MODAL_CONFIRM}
                onConfirm={this.onConfirm}
                onCancel={this.onCancel}
                passengerCount={this.props.passengerCount}
            />
        )
    }
}

export default connectState(mapPropsToProps, [BookingStore])(WheelchairContainer)
