// Libraries
import React from 'react'
import {Route} from 'react-router'

// Layouts
import SystemUnavailableLayout from '../layouts/system-unavailable-layout'

export default (
    <Route path='*' component={SystemUnavailableLayout} />
)
