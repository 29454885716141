/* global AdyenCheckout S3P_SETTINGS */
import React, {Component} from 'react'
import _t from '../../../../translate'
import TabContent from '../../../../element/tab-content'
import actions from '../../../../reflux/actions'
import PaymentTerms from '../payment-terms'
import {hasDomain} from '../../../../misc/blablabus-helpers'

const SCRIPT_NODE_ID = 'adyen-script'

export default class CreditCardAyden extends Component {
    componentDidMount () {
        const node = document.getElementById(SCRIPT_NODE_ID)
        if (node) {
            this._initiateAdyen()
        } else {
            this.scriptNode = document.createElement('script')
            this.scriptNode.id = SCRIPT_NODE_ID
            this.scriptNode.onload = this._initiateAdyen
            document.body.appendChild(this.scriptNode)
            this.scriptNode.async = 1
            this.scriptNode.src = `${S3P_SETTINGS.adyen.loadingContext}sdk/2.1.0/adyen.js`
        }
    }

    _initiateAdyen () {
        const originKeyOptions = () => {
            if (hasDomain('blablabus')) {
                return S3P_SETTINGS.adyen.originKeyBlablabus
            } else if (hasDomain('blablacar')) {
                return S3P_SETTINGS.adyen.originKeyBlablacar
            } else {
                return S3P_SETTINGS.adyen.originKeyOuibus
            }
        }
        const AdyenSettings = {
            ...S3P_SETTINGS.adyen,
            originKey: originKeyOptions(),
            locale: _t.getLocales()
        }
        delete AdyenSettings.originKeyBlablacar
        delete AdyenSettings.originKeyBlablabus
        delete AdyenSettings.originKeyOuibus

        const checkout = new AdyenCheckout(AdyenSettings)
        const card = checkout.create(
            'card',
            {
                // Optional Configuration
                // hasHolderName: true,

                // Optional. Customize the look and feel of the payment form
                // https://docs.adyen.com/developers/checkout/api-integration/configure-secured-fields/styling-secured-fields
                styles: {},

                // Optional. Define custom placeholders for the Card fields
                // https://docs.adyen.com/developers/checkout/api-integration/configure-secured-fields/styling-secured-fields
                placeholders: {
                    // encryptedCardNumber: '9999 9999 9999 9999',
                    // encryptedExpiryDate: '01/22',
                    // encryptedSecurityCode : '123'
                },

                onChange: state => { // args: state, component
                    state.isValid && actions.processPaymentData([{id: 'data', value: state.data}])
                }
            }
        )
        const node = document.getElementById('card-container')
        if (node) {
            return card.mount(node)
        }
    }

    render () {
        return (
            <TabContent visible>
                <div className='credit-card-container adyen'>
                    <div id='card-container' />
                    <PaymentTerms />
                </div>
            </TabContent>
        )
    }
}
