import React, {Component} from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import _t from '../../../translate'
import TextLabel from '../../../element/text-label'

export default class GridRow extends Component {
    static propTypes = {
        messageLabel: PropTypes.string.isRequired,
        messageContext: PropTypes.object,
        value: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string
        ]).isRequired,
        className: PropTypes.string,
        translate: PropTypes.bool,
        isPrice: PropTypes.bool
    }

    static defaultProps = {
        className: 'text-label',
        translate: true,
        isPrice: true
    }

    render () {
        return (
            <div className='grid-row'>
                <div className='grid-column--1-2 grid-column--medium-2-3'>
                    <span className='text-label booking-specification-description'>
                        {this.props.children}
                        <TextLabel text={this._getText()} />
                    </span>
                </div>
                <div className='grid-column--1-2 grid-column--medium-1-3'>
                    <span className={this.props.className}>
                        <TextLabel text={this._getValue()} />
                    </span>
                </div>
            </div>
        )
    }

    _getText () {
        if (this.props.translate) {
            return _.isEmpty(this.props.messageContext)
                ? _t.getIntlMessage(this.props.messageLabel)
                : _t.formatIntlMessage(this.props.messageLabel, this.props.messageContext)
        }

        return this.props.messageLabel
    }

    _getValue () {
        if (this.props.isPrice) {
            return _t.formatCurrency(this.props.value)
        }

        return this.props.value
    }
}
