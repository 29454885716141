import PropTypes from 'prop-types'
import React from 'react'
import Reflux from 'reflux'
import _t from '../../translate'
import Icon from '../../element/icon'
import Button from '../../element/button'
import TextLabel from '../../element/text-label'
import EmailField from '../../element/form/email-field'
import PasswordField from '../../element/form/password-field'
import actions from '../../reflux/actions'
import CrmLoginStore from '../../reflux/stores/crm-login-store'
import UserStore from '../../reflux/stores/user-store'
import AccessManager from '../../data/access-manager'
import createReactClass from 'create-react-class'

export default createReactClass({

    displayName: 'CrmLoginForm',

    mixins: [
        Reflux.listenTo(CrmLoginStore, 'onCrmLoginUpdate'),
        Reflux.listenTo(UserStore, 'onUserUpdate')
    ],

    propTypes: {
        showForgotPasswordLink: PropTypes.bool,
        onSuccess: PropTypes.func
    },

    getDefaultProps () {
        return {
            showForgotPasswordLink: false,
            onSuccess: () => {
            }
        }
    },

    getInitialState () {
        return {
            formData: CrmLoginStore.getInitialState(),
            buttonLoading: false,
            authenticate: false,
            serviceError: null
        }
    },

    onCrmLoginUpdate (data) {
        const authenticate = this.state.authenticate
        this.setState(
            {formData: data, authenticate: false},
            () => {
                if (data.isValid && authenticate) {
                    actions.crmLogin({email: data.fields.email.value, password: data.fields.password.value})
                        .then(actions.loadUserLoginInformation)
                        .then(() => {
                            if (AccessManager.isCrmUser()) {
                                actions.loadCrmUserInformation()
                            }
                        })
                        .catch(this.authenticationFailed)
                } else {
                    this.setState({buttonLoading: false})
                }
            }
        )
    },

    authenticationFailed () {
        this.setState({
            buttonLoading: false,
            serviceError: _t.getIntlMessage('crm-login.authentication-error')
        })
    },

    onUserUpdate () {
        if (AccessManager.isCrmUser()) {
            this.props.onSuccess()
        }
    },

    render () {
        const errorTextEmail = this.state.formData.fields.email.errorText
            ? `crm-login.login-email.${this.state.formData.fields.email.errorText}` : null
        const errorTextPassword = this.state.formData.fields.password.errorText
            ? `crm-login.login-password.${this.state.formData.fields.password.errorText}` : null

        /* c onst forgotPasswordLink = this.props.showForgotPasswordLink ? (
            <a onClick={this.onClickForgotPassword} className='forgot-password'
               title={_t.getIntlMessage('crm-login.forgot-password.title')}>
               {_t.getIntlMessage('crm-login.forgot-password.text')}
            </a>
        ) : null TODO: Implement forgot password */

        return (
            <form action='#' noValidate>
                {this.renderServiceError()}
                <div className='grid-row'>
                    <div className='grid-column--1-1'>
                        <EmailField
                            ref='email'
                            id='email'
                            labelText={'crm-login.login-email.label'}
                            errorText={errorTextEmail}
                            placeholder={'crm-login.login-email.placeholder'}
                            className='login-email-address'
                            required
                            onBlur={this.onBlur}
                            autoComplete='off'
                        />
                    </div>
                </div>
                <div className='grid-row'>
                    <div className='grid-column--1-1'>
                        <PasswordField
                            ref='password'
                            id='password'
                            labelText={'crm-login.login-password.label'}
                            errorText={errorTextPassword}
                            placeholder={'crm-login.login-password.placeholder'}
                            className='login-password'
                            required
                            onBlur={this.onBlur}
                        />
                    </div>
                </div>
                <div className='grid-row'>
                    <div className='grid-column--1-1'>
                        {/* forgotPasswordLink - TODO: Implement forgot password */}
                        <Button
                            loading={this.state.buttonLoading}
                            type='submit'
                            className='button primary login'
                            onClick={this.onLogin}
                        >
                            <TextLabel text={_t.getIntlMessage('crm-login.button')} />
                        </Button>
                    </div>
                </div>
            </form>
        )
    },

    renderServiceError () {
        return this.state.serviceError ? (
            <div className='feedback error'>
                <span className='text-label feedback-message'>
                    <Icon type='warning' className='small align-left' />
                    <TextLabel text={this.state.serviceError} />
                </span>
            </div>
        ) : null
    },

    onLogin (event) {
        event.preventDefault()
        const email = this.refs.email.getValue() || ''
        const password = this.refs.password.getValue() || ''

        this.setState({
            buttonLoading: true,
            authenticate: true
        }, () => actions.processCrmLoginData(
            [
                {id: 'email', value: email},
                {id: 'password', value: password}
            ]
        ))
    },

    onBlur (event) {
        actions.processCrmLoginData([{id: event.target.id, value: event.target.value}])
    },

    onClickForgotPassword () {
        // TODO: implement forgot password functionality
    }
})
