import PropTypes from 'prop-types'
import React, {Component} from 'react'

export default class Wrap extends Component {
    static propTypes = {
        className: PropTypes.string
    }

    static defaultProps = {
        className: 'wrap'
    }

    render () {
        return <div className={this.props.className}>{this.props.children}</div>
    }
}
