import PropTypes from 'prop-types'
import React, { Component } from 'react'
import classNames from 'classnames'
import TextLabel from '../element/text-label'

export const ASTERISK_NONE = 'none'
export const ASTERISK_BEFORE = 'before'
export const ASTERISK_AFTER = 'after'

export default class PromoTariffBanner extends Component {
    static propTypes = {
        text: PropTypes.string.isRequired,
        asterisk: PropTypes.oneOf([ASTERISK_NONE, ASTERISK_BEFORE, ASTERISK_AFTER])
    }

    static defaultProps = {
        asterisk: ASTERISK_NONE
    }

    render () {
        const className = classNames('text-label', {
            'asterisk-after': ASTERISK_AFTER === this.props.asterisk,
            'asterisk-before': ASTERISK_BEFORE === this.props.asterisk
        })

        return (
            <div className='promo-tariff-banner'>
                <span className={className}>
                    <TextLabel text={this.props.text} />
                </span>
            </div>
        )
    }
}
