import PropTypes from 'prop-types'
import React from 'react'
import {Link, withRouter} from 'react-router'
import Reflux from 'reflux'
import _t from '../../translate'
import Icon from '../../element/icon'
import TextLabel from '../../element/text-label'
import MyProfileItem from './user-navigation/my-profile-item'
import BookingItem from './user-navigation/booking-item'
import UserStore from '../../reflux/stores/user-store'
import {isAffiliateBlablacarSelector} from '../../reflux/bridge/affiliate'
import device from '../../device'
import {isBlablabusDomain, isOuiBlabusException} from '../../misc/blablabus-helpers'
import createReactClass from 'create-react-class'

export default withRouter(createReactClass({

    displayName: 'HeaderUserNavigation',

    mixins: [
        Reflux.connectFilter(UserStore, 'user', data => data.user)
    ],

    propTypes: {
        showLanguageSelector: PropTypes.bool,
        router: PropTypes.object,
        location: PropTypes.object
    },

    getInitialState () {
        return {
            mobileMenu: {
                isVisible: false
            }
        }
    },

    showMobileMenu () {
        this.setState({
            mobileMenu: {
                isVisible: true
            }
        })
    },

    hideMobileMenu () {
        this.setState({
            mobileMenu: {
                isVisible: false
            }
        })
    },

    render () {
        if (device.isMobile()) {
            return this.renderMobileNavigation()
        } else {
            return this.renderDesktopNavigation()
        }
    },

    renderDesktopNavigation () {
        return (
            <div className='meta'>
                <ul className='horizontal-list'>
                    <BookingItem />
                    <li>
                        <Link
                            to={_t.getIntlMessage('user-navigation.contact.url')}
                            className='link'
                            target='_blank'
                        >
                            <span className='text-label'>
                                <TextLabel text={_t.getIntlMessage('user-navigation.contact.label')} />
                            </span>
                        </Link>
                    </li>
                </ul>
            </div>
        )
    },

    _renderProfileItem () {
        if (isAffiliateBlablacarSelector() || (isBlablabusDomain() && isOuiBlabusException())) {
            return null
        }
        return <MyProfileItem />
    },

    renderMobileNavigation () {
        return (
            <div className='meta mobile-navigation'>
                <ul className='horizontal-list'>
                    <li>
                        <button className='button clear show-menu' onClick={() => this.showMobileMenu()}>
                            <span className='text-label show-menu'>
                                <Icon className='xlarge' type='menu' />
                                <TextLabel text='Navigation' />
                            </span>
                        </button>
                    </li>
                </ul>
                {
                    this.state.mobileMenu.isVisible
                        ? this.renderMobileMenu()
                        : null
                }
            </div>
        )
    },

    renderMobileMenu () {
        return (
            <div className='mobile-menu'>
                <div className='menu-panel'>
                    <div className='menu-header'>
                        <span className='text-label menu-title'>
                            <TextLabel text='Hello!' />
                        </span>
                        <button
                            className='button clear close-menu'
                            onClick={() => this.hideMobileMenu()}>
                            <Icon className='xlarge' type='cross' alt='Masquer le menu' />
                        </button>
                        <ul className='vertical-list'>
                            <BookingItem />
                        </ul>
                    </div>
                    <div className='menu-content'>
                        <nav className='mobile-nav'>
                            <ul className='vertical-list'>
                                <li>
                                    <Link
                                        to={_t.getIntlMessage('user-navigation.contact.url')}
                                        className='link'
                                        target='_blank'
                                    >
                                        <span className='text-label'>
                                            <TextLabel text={_t.getIntlMessage('user-navigation.contact.label')} />
                                        </span>
                                    </Link>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
                <div className='menu-overlay' onClick={() => this.hideMobileMenu()} />
            </div>
        )
    }
}))
