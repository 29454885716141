import PropTypes from 'prop-types'
import React from 'react'
import Reflux from 'reflux'
import forEach from 'lodash/forEach'
import moment from 'moment-timezone'
import _t from '../../../translate'
import device from '../../../device'
import PassengerDetailsContent from './customer/passenger-details-content'
import InvalidDiscountCardModal from './customer/invalid-discount-card-modal'
import CmsBlockContent from '../../cms/cms-block-content'
import Icon from '../../../element/icon'
import TextLabel from '../../../element/text-label'
import Heading from '../../../element/heading'
import Accordion from '../../../element/accordion'
import AccordionPanel from '../../../element/accordion/panel'
import AccordionPanelHeader from '../../../element/accordion/panel/header'
import AccordionPanelContent from '../../../element/accordion/panel/content'
import PassengersDetailsStore from '../../../reflux/stores/passenger-details-store'
import Reach5Store from '../../../reflux/stores/reach-5-store'
import BookingStore from '../../../reflux/stores/booking-store'
import SalesChannelManager from '../../../data/sales-channel-manager'
import BookingModel from '../../../models/booking-model'
import actions from '../../../reflux/actions'
import {PASSENGER_TYPE_ADULT} from '../../../constants'
import Reach5Widget from '../../reach-5-widget/reach-5-widget'
import WheelchairContainer from '../../orientation-booking-preferences/wheelchair-container'
import {sortPassengersDescending} from '../../../misc/helpers'
import {isPremiumPartner} from '../../../reflux/bridge/affiliate'
import {isBlablabusDomain} from '../../../misc/blablabus-helpers'
import createReactClass from 'create-react-class'

export default createReactClass({

    displayName: 'PassengerDetailsDetails',

    mixins: [
        Reflux.listenTo(PassengersDetailsStore, 'updatePassengerFormData'),
        Reflux.connectFilter(Reach5Store, 'userLoggedIn', data => data.userLoggedIn),
        Reflux.listenTo(BookingStore, 'onUpdateBooking')
    ],

    propTypes: {
        booking: PropTypes.instanceOf(BookingModel).isRequired,
        travelDate: PropTypes.any.isRequired
    },

    getInitialState () {
        const passengerFormData = PassengersDetailsStore.getInitialState()
        return {
            passengerFormData,
            loading: false
        }
    },

    componentWillMount () {
        const passengersForm = this.state.passengerFormData.passengers

        if (passengersForm.length >= SalesChannelManager.bookingGroupStart) {
            const passengerDetails = []
            forEach(passengersForm, passenger => {
                if (passenger.fields.gender && passenger.fields.gender.value === '') {
                    passengerDetails.push({
                        id: passenger.fields.gender.id,
                        value: 'M'
                    })
                }
                if (passenger.fields.firstName.value === '') {
                    passengerDetails.push({
                        id: passenger.fields.firstName.id,
                        value: 'group passenger'
                    })
                }
                if (passenger.fields.lastName.value === '') {
                    passengerDetails.push({
                        id: passenger.fields.lastName.id,
                        value: 'group passenger'
                    })
                }
                if (passenger.fields.dateOfBirth.value === '') {
                    passengerDetails.push({
                        id: passenger.fields.dateOfBirth.id,
                        value: passenger.type === PASSENGER_TYPE_ADULT
                            ? moment().year(1990).startOf('year').format('DD/MM/YYYY')
                            : moment().startOf('year').format('DD/MM/YYYY')
                    })
                }
            })
            if (passengerDetails.length > 0) {
                actions.processPassengerDetailsData(passengerDetails)
            }
        }
    },

    componentDidMount () {
        // Do a check on existing discount card numbers
        if (this.state.passengerFormData.passengers) {
            const passengers = this.state.passengerFormData.passengers.filter(passenger => passenger.isYouthWithDiscount && PassengersDetailsStore.isPassengerInternallyValid(passenger))
            actions.validatePassengerFormDiscountCards(passengers, this.props.travelDate)
        }
    },

    componentDidUpdate () {
        const invalidElement = document.querySelector('.state--is-invalid')
        if (invalidElement) {
            const coordinates = invalidElement.getBoundingClientRect()
            if (coordinates.top < 0) {
                const bodyRect = document.body.getBoundingClientRect()
                const scrollOffset = coordinates.top - bodyRect.top
                window.scrollTo(0, scrollOffset - 50)
            }
        }
    },

    updatePassengerFormData (data) {
        this.setState({passengerFormData: data})
    },

    onUpdateBooking (data) {
        this.setState({loading: data.loading})
    },

    render () {
        return (
            <div>
                <div className='row'>
                    <div className='passenger-details'>
                        {this.renderReach5Widget()}
                        {this.renderPassengers()}
                        {this.renderUnderSixteen()}
                        {this.renderBetweenSixteenEighteenInternational()}
                    </div>
                </div>
                <InvalidDiscountCardModal />
            </div>
        )
    },

    renderReach5Widget () {
        if (this.state.userLoggedIn || isPremiumPartner() || isBlablabusDomain()) {
            return null
        }

        if (device.isMobile()) {
            return (
                <div className='panel'>
                    <Accordion>
                        <AccordionPanel name='reach-5-mobile-block'>
                            <AccordionPanelHeader showCollapsed>
                                <header>
                                    <h1 className='text-label expand-details'>
                                        <TextLabel text={_t.getIntlMessage('passenger-details.reach-5.header')} />
                                        <Icon className='large align-right' type='plus' />
                                    </h1>
                                </header>
                            </AccordionPanelHeader>
                            <AccordionPanelHeader showExpanded>
                                <header>
                                    <h1 className='text-label expand-details'>
                                        <TextLabel text={_t.getIntlMessage('passenger-details.reach-5.header')} />
                                        <Icon className='large align-right' type='minus' />
                                    </h1>
                                </header>
                            </AccordionPanelHeader>
                            <AccordionPanelContent>
                                <Reach5Widget />
                            </AccordionPanelContent>
                        </AccordionPanel>
                    </Accordion>
                </div>
            )
        } else {
            return (
                <div className='panel'>
                    <Heading title={_t.getIntlMessage('passenger-details.reach-5.header')} />
                    <Reach5Widget />
                </div>
            )
        }
    },

    renderPassengers () {
        return (
            <div className='panel'>
                <Heading title={_t.formatIntlMessage('passenger-details.header')} />
                <span className='text-label information'>
                    <TextLabel text={_t.getIntlMessage('passenger-details.information')} />
                </span>
                <WheelchairContainer />
                {this._getPassengers().map((passenger, index) => (
                    <PassengerDetailsContent
                        key={passenger.id}
                        loading={this.state.loading}
                        title={_t.formatIntlMessage('passenger-details.passenger', {
                            number: index + 1,
                            type: _t.message(`passenger-types.${passenger.type.toLowerCase()}`),
                            passengerType: passenger.passengerType
                        })}
                        passenger={passenger}
                        isFirstPassenger={index === 0}
                    />
                ))}
            </div>
        )
    },

    renderUnderSixteen () {
        const booking = BookingModel.create(this.props.booking)
        if (booking.isInternationalTravel) {
            return null
        }

        let personsBetweenTwelveAndSixteen = 0
        let personsEighteenOrOlder = 0
        this.state.passengerFormData.passengers.forEach(passenger => {
            const passengerAge = PassengersDetailsStore.getPassengerAge(passenger.id)
            if (passengerAge >= 12 && passengerAge < 16) {
                personsBetweenTwelveAndSixteen += 1
            }
            if (passengerAge >= 18) {
                personsEighteenOrOlder += 1
            }
        })

        return personsBetweenTwelveAndSixteen > 0 && personsEighteenOrOlder === 0 ? (
            <div className='passenger-feedback'>
                <div className='feedback passenger'>
                    <span className='text-label feedback-title'>
                        <Icon className='information medium align-left' type='information' />
                        <TextLabel text={_t.message('passenger-information.no-adult-passengers-domestic')} />
                    </span>
                    <CmsBlockContent name='passenger-under-sixteen' />
                </div>
            </div>
        ) : null
    },

    renderBetweenSixteenEighteenInternational () {
        const booking = BookingModel.create(this.props.booking)
        const personBetweenSixteenEighteen = this.state.passengerFormData.passengers.some(({id}) => {
            const passengerAge = PassengersDetailsStore.getPassengerAge(id)
            if (passengerAge >= 16 && passengerAge < 18) {
                return true
            }
        })

        return personBetweenSixteenEighteen && booking.isInternationalTravel
            ? (
                <div className='passenger-feedback'>
                    <div className='feedback passenger'>
                        <span className='text-label feedback-title'>
                            <Icon className='information medium align-left' type='information' />
                            <TextLabel
                                text={_t.message('passenger-information.sixteen-up-international-authorization')} />
                        </span>
                    </div>
                </div>
            )
            : null
    },

    _getPassengers () {
        return sortPassengersDescending(this.state.passengerFormData.passengers)
    },

    onUploadComplete (passengerValues) {
        actions.processPassengerDetailsData(passengerValues)
    }
})
