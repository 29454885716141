import PropTypes from 'prop-types'
import React from 'react'
import Reflux from 'reflux'
import device from '../device'
import _t from '../translate'
import {PRODUCT_CODE_SEAT_SELECTION} from '../constants'
import BookingModel from '../models/booking-model'
import ProductsStore from '../reflux/stores/products-store'
import BookingStore from '../reflux/stores/booking-store'
import GenericLayout from './generic-layout'
import AfterSalesNavigation from '../base/after-sales-navigation'
import Help from '../components/my-s3-booking/help'
import BookingSpecificationBookingPanel from '../components/booking-specification/booking-panel'
import SeatSummary from '../components/seat-summary/seat-summary'
import Heading from '../element/heading'
import Loader from '../element/loader'
import restoreBooking from '../components/restore/restore-booking'
import createReactClass from 'create-react-class'

export default restoreBooking(createReactClass({
    displayName: 'MyS3SeatLayout',

    mixins: [
        Reflux.connectFilter(ProductsStore, 'products', data => data.products),
        Reflux.connectFilter(BookingStore, 'loading', data => data.loading),
        Reflux.connectFilter(BookingStore, 'booking', data => BookingModel.create(data.booking))
    ],

    propTypes: {
        showJourneySummary: PropTypes.bool,
        showSeatSummary: PropTypes.bool
    },

    getDefaultProps () {
        return {
            showJourneySummary: true,
            showSeatSummary: false
        }
    },

    _renderSidePanel () {
        const booking = this.state.booking

        const panels = []

        if (this.state.loading || !booking) {
            return <Loader />
        }

        if (this.props.showJourneySummary) {
            panels.push(<BookingSpecificationBookingPanel
                key='journeySummary'
                afterSales
                booking={booking}
                onlyShowNewTariffSegments={false}
            />)
        }
        if (this.props.showSeatSummary) {
            let price = 0
            const item = (this.state.products.items || []).find(item => item.product_code === PRODUCT_CODE_SEAT_SELECTION)
            const fare = item && this.state.products.fares.find(_fare => _fare.id === item.fare_id)
            const passengerFare = fare && fare.passenger_fares && fare.passenger_fares[0]

            if (passengerFare) {
                price = passengerFare.price
            }

            panels.push(
                <div
                    key='seatSummary'
                    className='panel'
                >
                    <Heading title={_t.formatIntlMessage('seat-summary.header')} />
                    <SeatSummary
                        booking={booking}
                        passengers={booking.passengers.value()}
                        price={price}
                        showPrice
                        readOnly
                        viewVertical
                    />
                </div>
            )
        }

        return panels
    },

    render () {
        return (
            <GenericLayout name='seat-selection-container'>
                <AfterSalesNavigation />
                <div className='wrap'>
                    <div className='grid-row contains-seat-selector'>
                        <div className='grid-column--1-1 grid-column--medium-1-3'>
                            {this._renderSidePanel()}
                            {!device.isMobile() ? <Help /> : null}
                        </div>
                        <div className='grid-column--1-1 grid-column--medium-2-3'>
                            {this.props.children}
                            {device.isMobile() ? <Help /> : null}
                        </div>
                    </div>
                </div>
            </GenericLayout>
        )
    }
}), {withBlockingLoader: true})
