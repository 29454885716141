import React from 'react'
import PropTypes from 'prop-types'
import {withRouter} from 'react-router'
import Reflux from 'reflux'
import _t from '../translate'
import device from '../device'
import Icon from '../element/icon'
import TextLabel from '../element/text-label'
import Button from '../element/button'
import actions from '../reflux/actions'
import { isNewVSC, newVSCUrl } from '../misc/new-vsc-helper'
import {
    getAffiliateHomepageUrl,
    getBlablaCarHomepageUrl,
    isAffiliateBlablacarSelector,
    isPremiumPartner
} from '../reflux/bridge/affiliate'
import BookingModel from '../models/booking-model'
import BookingStore from '../reflux/stores/booking-store'
import createReactClass from 'create-react-class'

const component = createReactClass({

    propTypes: {
        router: PropTypes.object
    },

    mixins: [
        Reflux.connectFilter(BookingStore, 'booking', data => BookingModel.create(data.booking))
    ],

    render () {
        if (!device.isDesktopOrTablet() || isAffiliateBlablacarSelector()) {
            return null
        }

        return (
            <div className='new-journey-search'>
                <div className='panel plain'>
                    <div className='content'>
                        <Button className='button new-search default' onClick={this.onClick}>
                            <Icon className='xsmall align-left' type='chevron-left' />
                            <TextLabel text={_t.getIntlMessage('new-search')} />
                        </Button>
                    </div>
                </div>
            </div>
        )
    },

    onClick (event) {
        event.preventDefault()
        if (this.state.booking.provisional) {
            actions.cancelBooking(this.state.booking.bookingNumber)
        }
        actions.clearOffer()
        if (isNewVSC()) {
            window.location.href = newVSCUrl()
        } else if (isPremiumPartner()) {
            window.location.href = getAffiliateHomepageUrl()
        } else {
            window.location.href = getBlablaCarHomepageUrl()
        }
    }

})
export default withRouter(component)
