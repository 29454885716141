import _ from 'lodash'
import UserStore from '../reflux/stores/user-store'

class AccessManager {
    constructor () {
        this.roles = {
            public: 'ROLE_PUBLIC',
            booking: 'ROLE_BOOKING',
            crm: 'ROLE_CRM'
        }
    }

    isAuthenticated () {
        return _.get(UserStore, 'data.user') !== null
    }

    isPublicUser () {
        return this.hasRole(this.roles.public)
    }

    isCrmUser () {
        return this.hasRole(this.roles.crm)
    }

    isBookingUser () {
        return this.hasRole(this.roles.booking)
    }

    getUsername () {
        return _.get(UserStore, 'data.user.username')
    }

    getSalesChannel () {
        return _.get(UserStore, 'data.user.sales_channel')
    }

    hasRole (role) {
        return this.getRoles().indexOf(role) !== -1
    }

    getRoles () {
        return _.get(UserStore, 'data.user.roles', [])
    }

    hasAccessToVoucherPayment () {
        return this.allowSalesChannelVoucherPayment()
    }

    allowSalesChannelVoucherPayment () {
        return UserStore.getSalesChannelProperty(UserStore.salesChannelProperties.allowVoucherPayment)
    }

    hasAccessToAfterSalesRebooking () {
        return UserStore.getSalesChannelProperty(UserStore.salesChannelProperties.allowAftersalesRebook)
    }

    hasAccessToAfterSalesCancellation () {
        return this.salesChannelAllowsCancellation()
    }

    hasAccessToAfterSalesPartialCancellation () {
        return this.hasAccessToAfterSalesCancellation()
    }

    hasAccessToAfterSalesRefundVoucher () {
        return UserStore.getSalesChannelProperty(UserStore.salesChannelProperties.allowRefundVoucherCreation)
    }

    hasAccessToAfterSalesRefund () {
        return UserStore.getSalesChannelProperty(UserStore.salesChannelProperties.allowAftersalesRefund)
    }

    salesChannelAllowsCancellation () {
        return UserStore.getSalesChannelProperty(UserStore.salesChannelProperties.allowAftersalesCancel)
    }
}

export default new AccessManager()
