import PropTypes from 'prop-types'
import React, {Component} from 'react'
import _t from '../../../../translate'
import Icon from '../../../../element/icon'
import TextLabel from '../../../../element/text-label'

export default class FromToInfo extends Component {
    static propTypes = {
        fromLeg: PropTypes.shape({
            arrivalStation: PropTypes.shape({
                name: PropTypes.string.isRequired,
                arrivalTime: PropTypes.any
            }),
            departureStation: PropTypes.shape({
                name: PropTypes.string.isRequired,
                departureTime: PropTypes.any.isRequired
            }).isRequired
        }).isRequired,
        toLeg: PropTypes.shape({
            arrivalStation: PropTypes.shape({
                name: PropTypes.string.isRequired,
                arrivalTime: PropTypes.any.isRequired
            }).isRequired,
            departureStation: PropTypes.shape({
                name: PropTypes.string.isRequired,
                departureTime: PropTypes.any
            })
        }).isRequired,
        selected: PropTypes.bool,
        days: PropTypes.number.isRequired
    }

    static defaultProps = {
        selected: false
    }

    _getDepartureTime () {
        const date = this.props.fromLeg.departureStation.departureTime
        return date.format('LT')
    }

    _getArrivalTime () {
        const date = this.props.toLeg.arrivalStation.arrivalTime
        return date.format('LT')
    }

    render () {
        return (
            <div className='journey-result-option-item from-to-info'>
                <span className='text-label departure-time'>
                    <TextLabel text={this._getDepartureTime()} />
                    <Icon type='arrow-right' className='small align-right' />
                </span>
                <span className='text-label arrival-time'>
                    <TextLabel text={this._getArrivalTime()} />
                </span>
                {this.getOvernightTravelInfo()}
            </div>
        )
    }

    getOvernightTravelInfo () {
        if (this.props.days === 0) {
            return null
        }

        return (
            <span className='text-label overnight-travel' title={_t.message('journey-result-set.overnight-travel')}>
                <Icon className='night small align-left' type='night' />
                <TextLabel className='dimmed' text={_t.message('journey-result-set.overnight-travel')} />
            </span>
        )
    }
}
