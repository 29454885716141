import React, {Component} from 'react'
import PropTypes from 'prop-types'
import _t from '../../../../translate'
import TextLabel from '../../../../element/text-label'
import {
    PASSENGER_TYPE_ADULT,
    PASSENGER_TYPE_BABY,
    PASSENGER_TYPE_CHILD,
    PASSENGER_TYPE_YOUTH
} from '../../../../constants'

export default class FromInfo extends Component {
   static propTypes = {
       bundles: PropTypes.arrayOf(PropTypes.shape({
           isPromo: PropTypes.bool,
           isMainProduct: PropTypes.bool,
           items: PropTypes.arrayOf(PropTypes.shape({
               passengerFares: PropTypes.arrayOf(PropTypes.shape({
                   passengerId: PropTypes.string,
                   price: PropTypes.number
               }))
           }))
       })).isRequired,
       passengers: PropTypes.arrayOf(
           PropTypes.shape({
               id: PropTypes.string.isRequired,
               type: PropTypes.oneOf([PASSENGER_TYPE_ADULT, PASSENGER_TYPE_CHILD, PASSENGER_TYPE_BABY, PASSENGER_TYPE_YOUTH])
           }).isRequired
       ).isRequired
   }

   render () {
       if (!this.props.bundles.length) {
           return null
       }

       return (
           <div className='journey-passenger-details'>
               <table>
                   <thead>
                       <tr>
                           <th>&nbsp;</th>
                           {this.props.bundles.map(bundle => {
                               const isPromo = bundle.isPromo
                               return (
                                   <th key={`${bundle.id}_${isPromo ? 'promo' : 'standard'}`}>
                                       <span className='text-label thead'>
                                           <TextLabel text={_t.message(
                                               `journey-result-set.product-type.${isPromo ? 'promo' : 'standard'}`
                                           )} />
                                       </span>
                                   </th>
                               )
                           })}
                       </tr>
                   </thead>
                   <tbody>
                       {this.props.passengers.map((passenger, index) => {
                           return (
                               <tr key={`passenger-type_${passenger.id}`}>
                                   <td>
                                       <span className='text-label passenger-type'>
                                           <TextLabel text={_t.message('journey-result-set.details.passenger', {
                                               type: _t.message(`passenger-types.${passenger.passengerType.toLowerCase()}`),
                                               number: index + 1
                                           })} />
                                       </span>
                                   </td>
                                   {this.props.bundles.map(bundle => (
                                       <td key={`passenger_tariff_price_${bundle.id}_${passenger.id}`}>
                                           <span className='text-label passenger-tariff-price'>
                                               <TextLabel text={_t.formatCurrency(bundle.passengerPrices.get(passenger.id))} />
                                           </span>
                                       </td>
                                   ))
                                   }
                               </tr>
                           )
                       })}
                   </tbody>
               </table>
           </div>
       )
   }
}
