import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from '../../../../translate'

export default class SegmentInformation extends Component {
    static propTypes = {
        origin: PropTypes.string.isRequired,
        destination: PropTypes.string.isRequired
    }

    render () {
        return (
            <span className='text-label outbound-inbound-journey'>
                {_t.message('seat-selector.origin-destination', this.props)}
            </span>
        )
    }
}
