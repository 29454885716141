/* global Image */

import PropTypes from 'prop-types'

import React, { Component } from 'react'
import SeatLayoutContainer from './seat-layout-container'
import _t from '../../translate'
import TextLabel from '../../element/text-label'
import get from 'lodash/get'
import {
    hasManualSeatProperty,
    hasManualSeatPlusProperty
} from '../../misc/helpers'

export default class SeatLayout extends Component {
    static propTypes = {
        selectedPassenger: PropTypes.shape({
            id: PropTypes.string
        }).isRequired,
        carriage: PropTypes.shape({
            template: PropTypes.string.isRequired,
            seats: PropTypes.arrayOf(PropTypes.shape({
                seat_number: PropTypes.string.isRequired,
                x_pos: PropTypes.number.isRequired,
                y_pos: PropTypes.number.isRequired,
                reversed: PropTypes.bool.isRequired,
                available: PropTypes.bool.isRequired,
                blocked_passengers: PropTypes.arrayOf(
                    PropTypes.string.isRequired
                ).isRequired,
                property_codes: PropTypes.arrayOf(
                    PropTypes.string.isRequired
                ).isRequired
            })).isRequired,
            carriage_number: PropTypes.string.isRequired
        }).isRequired,
        selected: PropTypes.shape({
            legId: PropTypes.string
        }),
        readOnly: PropTypes.bool,
        selectedSeats: PropTypes.any,
        onSelectSeat: PropTypes.any,
        onRemoveSeat: PropTypes.any,
        availableSections: PropTypes.array.isRequired,
        availableSeat: PropTypes.bool,
        availableSeatPlus: PropTypes.bool
    }

    constructor (...args) {
        super(...args)
        this.state = {
            loading: true,
            templates: {}
        }
        this.mounted = false
    }

    componentDidMount () {
        this.mounted = true
        if (this.props.carriage.template) {
            this._loadTemplate(this.props.carriage.template)
        }
    }

    componentWillUnmount () {
        this.mounted = false
    }

    componentWillReceiveProps (nextProps) {
        if (nextProps.carriage.template && nextProps.carriage.template !== this.props.carriage.template) {
            this._loadTemplate(nextProps.carriage.template)
        }
    }

    _loadTemplate (template) {
        if (template) {
            this.setState({loading: true})
            const img = new Image()
            img.onload = () => this.mounted && this.setState({
                loading: false,
                templates: {
                    [template]: {
                        width: img.width,
                        height: img.height
                    }
                }
            })
            img.onerror = () => this.mounted && this.setState({loading: false})
            img.src = template
        }
    }

    render () {
        return this.props.carriage && this.props.selected && (this._legHasSeatSelection(this.props.selected.legId) || this.props.readOnly)
            ? (
                <SeatLayoutContainer
                    className='seat-layout-floorplan'
                    style={{
                        backgroundImage: 'url(' + this.props.carriage.template + ')',
                        width: get(this.state.templates, [this.props.carriage.template, 'width'], 0) + 'px',
                        height: get(this.state.templates, [this.props.carriage.template, 'height'], 150) + 'px'
                    }}
                    seats={this._getSeats()}
                    selectedPassenger={this.props.selectedPassenger}
                    selectedSeats={this.props.selectedSeats}
                    onSelectSeat={this.props.onSelectSeat}
                    onRemoveSeat={this.props.onRemoveSeat}
                    readOnly={this.props.readOnly}
                />
            )
            : (
                <span className='text-label seat-selection-unavailable'>
                    <TextLabel text={_t.getIntlMessage(`seat-selector.seat-unavailable`)} />
                </span>
            )
    }

    _legHasSeatSelection (legId) {
        return this.props.availableSections.includes(legId) || this.props.selectedSeats.some(seat => legId === seat.leg_id)
    }

    _getSeats () {
        return this.props.carriage.seats.map(
            seat => ({
                seat_number: seat.seat_number,
                x_pos: seat.x_pos,
                y_pos: seat.y_pos,
                reversed: seat.reversed,
                available: this._isSeatAvailable(seat),
                property_codes: seat.property_codes
            })
        )
    }

    _isSeatAvailable (seat) {
        if (seat.blocked_passengers.indexOf(this.props.selectedPassenger.id) !== -1) {
            return false
        }
        return seat.available &&
            (
                (this.props.availableSeat && hasManualSeatProperty(seat)) ||
                (this.props.availableSeatPlus && hasManualSeatPlusProperty(seat))
            )
    }
}
