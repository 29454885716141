import React from 'react'
import {Route} from 'react-router'
import authCheck from '../../../mixins/auth'
import CancellationConfirmLayout from '../../../layouts/my-s3-cancellation-confirm-layout'

export default (
    <Route
        path='/:lang/mys3/cancellation/confirm'
        component={CancellationConfirmLayout}
        onEnter={(nextState, replaceState, callback) => authCheck(nextState, replaceState, callback)}
    />
)
