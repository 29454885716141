import Reflux from 'reflux'
import UserAwareMixin from '../mixin/user-aware-mixin'
import actions from '../actions'
import ResponseCodes from '../../api/response-codes'

export default Reflux.createStore({

    listenables: actions,

    mixins: [UserAwareMixin],

    init () {
        this._resetData()
    },

    _resetData () {
        this.data = {
            loading: false,
            carriageLayout: null
        }
    },

    resetData () {
        this._resetData()
        this.trigger(this.data)
    },

    getInitialState () {
        return this.data
    },

    onGetCarriageLayouts () {
        this.data.loading = true
        this.data.carriageLayout = null
        this.trigger(this.data)
    },

    onGetCarriageLayoutsCompleted (carriageLayout) {
        this.data.loading = false
        this.data.carriageLayout = carriageLayout
        this.trigger(this.data)
    },

    onGetCarriageLayoutsFailed () {
        this.data.loading = false
        this.data.carriageLayout = null
        this.trigger(this.data)
    },

    onApiError (error) {
        if (error.code === ResponseCodes.CARRIAGE_LAYOUT_SCHEDULE_UNAVAILABLE) {
            error.handled = true
        }
        this.trigger(this.data)
    }
})
