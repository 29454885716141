// Libraries
import React from 'react'
import {Route} from 'react-router'

import authCheck from '../../mixins/auth'

// Layouts
import MyS3BookingLayoutContainer from '../../layouts/my-s3-booking-layout-container'

export default (
    <Route path='/:lang/mys3/booking/:bookingReference'
           component={MyS3BookingLayoutContainer}
           onEnter={(nextState, replaceState, callback) => authCheck(nextState, replaceState, callback)}
        />
)
