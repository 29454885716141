/* globals S3P_SETTINGS: true */

import React from 'react'
import Reflux from 'reflux'
import isEmpty from 'lodash/isEmpty'
import actions from '../reflux/actions'
import OfferStore from '../reflux/stores/offer-store'
import TokenStore from '../reflux/stores/token-store'
import SelectBundleStore from '../reflux/stores/select-bundle-store'
import AffiliateStore from '../reflux/stores/affiliate-store'
import CalendarStore from '../reflux/stores/calendar-store'
import {sortedTravelsSelector} from '../models/selectors/api/v2/orientation/travel'
import {isTrue} from '../misc/helpers'
import {createBookingRequestPayload} from '../models/selectors/components/orientation/booking'
import {
    inboundBeforeOutboundSelector,
    outboundDepartureStationDifferentThanInboundArrivalStationSelector
} from '../models/selectors/components/orientation/journey-search'
import {getState} from '../reflux/bridge/connect-state'
import createReactClass from 'create-react-class'

export default Component => createReactClass({

    displayName: 'OrientationOfferLayout',

    mixins: [
        Reflux.connectFilter(TokenStore, 'hasToken', data => data.hasToken),
        Reflux.listenTo(OfferStore, 'onGetOffer'),
        Reflux.listenTo(SelectBundleStore, 'onSelectBundles')
    ],

    componentWillMount () {
        const data = OfferStore.getInitialState()

        if (!data.loading && data.offer.travels) {
            this.getCalendar(data)
        }
    },

    onSelectBundles (data) {
        this.setState({
            selectedBundles: data.bundles
        })
    },

    getInitialState () {
        return {
            hasOffer: !isEmpty(OfferStore.getOfferData()),
            travels: sortedTravelsSelector(getState()),
            selectedBundles: [],
            affiliateCode: AffiliateStore.getAffiliateCode()
        }
    },

    onGetOffer (data) {
        if (data.error === '') {
            const hasOffer = !isEmpty(data.offer)
            if (!data.loading) {
                this.setState({
                    travels: sortedTravelsSelector(getState()),
                    hasOffer
                })
            }

            if (!data.loading && hasOffer) {
                this.getCalendar(data)
            }
        }
    },

    getCalendar (data) {
        const directions = data.offer.travels
            .filter(travel => travel.routes.length === 0)
            .map(travel => travel.direction)

        if (directions.length > 0) {
            if (isTrue(S3P_SETTINGS.s3Passenger.enableCalendar)) {
                actions.getCalendar(CalendarStore.createRequestData(
                    data.journeySearch,
                    directions,
                    false
                ))
            } else {
                actions.calendarDisabled()
            }
        }
    },

    async onCreateBooking () {
        const createBookingPayload = createBookingRequestPayload(getState())
        return actions.createBooking(createBookingPayload)
    },

    onOptionClick (bundle) {
        actions.selectBundle(bundle)
    },

    _isInboundBeforeOutbound () {
        return inboundBeforeOutboundSelector(getState())
    },

    _isOutboundOriginDifferentThanInboundDestination () {
        return outboundDepartureStationDifferentThanInboundArrivalStationSelector(getState())
    },

    render () {
        return (
            <Component
                hasToken={this.state.hasToken}
                travels={this.state.travels}
                selectedBundles={this.state.selectedBundles}
                hasOffer={this.state.hasOffer}
                isInboundBeforeOutbound={this._isInboundBeforeOutbound}
                isOutboundOriginDifferentThanInboundDestination={this._isOutboundOriginDifferentThanInboundDestination}
                onOptionClick={this.onOptionClick}
                onCreateBooking={this.onCreateBooking}
                affiliateCode={this.state.affiliateCode}
                {...this.props}
            />
        )
    }

})
