import React from 'react'
import Reflux from 'reflux'
import _ from 'lodash'
import debounce from 'lodash/debounce'
import get from 'lodash/get'
import storage from '../../storage'
import BookingModel from '../../models/booking-model'
import PropTypes from 'prop-types'
import qs from 'qs'
import ProgressNavigationContainer from './progress-navigation-container'
import BookingStore from '../../reflux/stores/booking-store'
import PaymentStore from '../../reflux/stores/payment-store'
import actions from '../../reflux/actions'
import {
    PAYMENT_STATUS_P,
    PAYMENT_METHOD_FLOW_TYPE_VOUCHER,
    PAYMENT_METHOD_FLOW_TYPE_CREDIT_CARD_ENCRYPTED,
    CURRENCY_EUR,
    PAYMENT_METHOD_CODE_VOUCHER
} from '../../constants'
import PaymentMethodsStore from '../../reflux/stores/payment-methods-store'
import {tracktorButtonEvent} from '../../misc/blablabus-tracktor'
import createReactClass from 'create-react-class'

export default createReactClass({

    mixins: [
        Reflux.listenTo(PaymentStore, 'onPaymentUpdated'),
        Reflux.listenTo(BookingStore, 'onBookingUpdated')
    ],

    propTypes: {
        onPrev: PropTypes.func.isRequired,
        onConfirm: PropTypes.func,
        onConfirmed: PropTypes.func.isRequired,
        previousButtonProps: PropTypes.object,
        nextButtonProps: PropTypes.object,
        agreedToTerms: PropTypes.bool,
        previousButtonEnabled: PropTypes.bool
    },

    getDefaultProps () {
        return {
            onConfirm: () => {
                return actions.confirmBooking(BookingStore.getBooking().booking_number)
            },
            previousButtonProps: {},
            nextButtonProps: {}
        }
    },

    _doInitiatePayment: false,
    _updatingPendingPayments: false,
    _paymentIsValid: null,

    getInitialState () {
        this._paymentIsValid = PaymentStore.getInitialState().isValid

        return {
            loading: false,
            booking: BookingModel.create(BookingStore.getBooking())
        }
    },

    onPaymentUpdated (data) {
        const agreedToTerms = data.fields.agree && data.fields.agree.value
        const paymentMethodCode = data.paymentMethodCode

        if (agreedToTerms !== this.state.agreedToTerms || paymentMethodCode !== this.state.paymentMethodCode) {
            this.setState({
                agreedToTerms,
                paymentMethodCode
            })
        }

        if (data.paymentResult && !data.paymentResult.isFailed()) {
            this._handlePaymentResult(data)
        } else {
            if (this._doInitiatePayment && data.isValid && data.paymentPreferences) {
                actions.initiatePayment(data.paymentPreferences)
            }

            this._paymentIsValid = data.isValid
            this._doInitiatePayment = false
        }
    },

    componentWillMount () {
        this.tryConfirm(this.state.booking)
    },

    onBookingUpdated (data) {
        const booking = BookingModel.create(data.booking)
        this.setState({
            loading: data.loading && BookingStore.getVoucherAction() == null,
            booking
        })
        this.tryConfirm(booking)
    },

    tryConfirm (booking) {
        if (booking &&
            booking.isConfirmed &&
            !booking.hasChangeExpiryTime &&
            !BookingStore.isLoading() &&
            BookingStore.lastCall !== 'revertBooking' // Don't redirect after revertbooking
        ) {
            this.props.onConfirmed()
        }
    },

    _handlePaymentResult (data) {
        storage.set('payment_reference', data.paymentResult.reference)
        if (data.paymentResult.redirectUrl) {
            const method = PaymentMethodsStore.getPaymentMethodByCode(data.paymentPreferences.payment_method_code)

            if (method && method.flow_type === PAYMENT_METHOD_FLOW_TYPE_CREDIT_CARD_ENCRYPTED) {
                this.setState({redirectPost: data.paymentResult})
            } else {
                window.location.href = data.paymentResult.redirectUrl
            }
        } else if (data.paymentResult.isSuccess() || data.paymentResult.isPending()) {
            this.props.onConfirmed()
        }
    },

    pendingTotalPriceToBePaid () {
        return this.state.booking ? this.state.booking.pendingTotalPriceToBePaid : null
    },

    componentDidUpdate () {
        if (this._redirectForm) {
            this._redirectForm.submit()
        }
    },

    render () {
        let props = {
            previousButtonProps: _.merge(this.props.previousButtonProps, {
                onClick: this.props.onPrev
            }),
            previousButtonEnabled: this.props.previousButtonEnabled,
            nextButtonEnabled: (
                this.state.paymentMethodCode !== PAYMENT_METHOD_CODE_VOUCHER ||
                (!this._requiresPayment() && this.state.agreedToTerms) ||
                this.state.booking.isPaidWithVouchers
            ),
            nextButtonProps: _.merge(this.props.nextButtonProps, {
                onClick: debounce(this.onNext, 200),
                loading: this.state.loading
            })
        }

        return (
            <div>
                {this._renderRedirectForm()}
                <ProgressNavigationContainer {...props} />
            </div>
        )
    },

    _renderRedirectForm () {
        if (this.state.redirectPost) {
            const urlData = this.state.redirectPost.redirectUrl.split('?')
            const {TermUrl, PaReq, ...query} = urlData[1] ? qs.parse(urlData[1]) : {}

            let action = urlData[0]
            if (Object.keys(query).length) {
                action += '?' + qs.stringify(query, {encode: false})
            }

            return (
                <form ref={form => (this._redirectForm = form)} action={action} method='post'>
                    {PaReq ? <input type='hidden' name='PaReq' value={PaReq} /> : null}
                    {TermUrl ? <input type='hidden' name='TermUrl' value={TermUrl} /> : null}
                    <input type='hidden' name='MD' value={this.state.redirectPost.MD} />
                </form>
            )
        }
    },

    _addCashPayment (cashValue, method) {
        const bookingNumber = this.state.booking.bookingNumber
        let reference = ''
        for (let i = 0; i < 4; i++) {
            reference += String.fromCharCode(65 + Math.floor(Math.random() * 25))
        }
        const payments = [{
            amount: parseFloat(cashValue),
            method,
            status: PAYMENT_STATUS_P,
            currency: CURRENCY_EUR,
            reference: `${bookingNumber}-${reference}`
        }]
        actions.addPayments(payments, bookingNumber).then(actions.getPaymentMethods(bookingNumber))
    },

    _initiatePayment () {
        if (!this._doInitiatePayment) {
            this._doInitiatePayment = true
            actions.buildPaymentPreferences(this.state.booking.bookingNumber)
        }
    },

    _requiresPayment () {
        return (
            !this.state.booking ||
            (this.state.booking &&
                (this.state.booking.requiresPayment || this.pendingTotalPriceToBePaid() > 0))
        )
    },

    _isPaymentDetailsValid () {
        return (this._paymentIsValid || this.pendingTotalPriceToBePaid() === 0) &&
            (
                // Validate the payment Form, except for vouchers.
                (this.state.paymentMethodCode !== PAYMENT_METHOD_FLOW_TYPE_VOUCHER || // Voucher is only valid if it reduces total price to be paid to 0
                    !this._requiresPayment())
            ) &&
            (this.state.booking && !this.state.booking.hasDiscountAfterPendingPayment) &&
            this.state.agreedToTerms
    },

    onNext () {
        tracktorButtonEvent()
        if (!this._isPaymentDetailsValid()) {
            PaymentStore._validatePaymentData()
            return false
        }
        const data = PaymentStore.getInitialState()
        const cashValue = get(data, 'fields.cashAmount.value')
        if (this.state.booking && !this.state.loading) {
            if (
                this.state.booking.isPaidWithVouchers
            ) {
                this.props.onConfirm()
            } else if (cashValue && cashValue < this.pendingTotalPriceToBePaid()) {
                const value = data.paymentMethodCode
                const index = value.indexOf('_') + 1
                this._addCashPayment(cashValue, value.slice(index))
            } else if (!this._updatingPendingPayments && this.state.booking.pendingPayments.length) {
                this._updatingPendingPayments = false
                this._initiatePayment()
            } else {
                this._initiatePayment()
            }
        }
    }
})
