import React, {Component} from 'react'
import PropTypes from 'prop-types'
import TextLabel from '../../../element/text-label'
import Icon from '../../../element/icon'
import _t from '../../../translate'

export default class SegementFee extends Component {
    static propTypes = {
        fee: PropTypes.shape({
            name: PropTypes.string.isRequired,
            price: PropTypes.number.isRequired
        }).isRequired
    }

    render () {
        return (
            <tr className='after-sales-fees'>
                <td colSpan='2'>
                    <span className='text-label after-sales-fees-description'>
                        <Icon type='minus-circle' className='xsmall align-left' />
                        <TextLabel text={_t.formatIntlMessage('booking-information.segment-fees-to-be-cancelled', {
                            name: this.props.fee.name
                        })} />
                    </span>
                </td>
                <td>
                    <span className='text-label after-sales-fees-price'>
                        <TextLabel text={_t.formatCurrency(this.props.fee.price)} />
                    </span>
                </td>
            </tr>
        )
    }
}
