import PropTypes from 'prop-types'
import React, { Component } from 'react'
import TextLabel from '../../element/text-label'
import _t from '../../translate'

export default class TotalPrice extends Component {
    static propTypes = {
        selectedOptionsValue: PropTypes.number.isRequired,
        totalPrice: PropTypes.number.isRequired,
        numberOfPassengers: PropTypes.number.isRequired
    }

    render () {
        return (
            <div className='panel'>
                <div className='options-total-price'>
                    <div className='selection-price'>
                        <div className='grid-row'>
                            <div className='grid-column--2-3 grid-column--medium-3-5'>
                                <span className='text-label'>
                                    <TextLabel text={_t.message('options.total-price.options-selected')} />
                                </span>
                            </div>
                            <div className='grid-column--1-3 grid-column--medium-2-5'>
                                <span className='text-label price'>
                                    <TextLabel text={_t.formatCurrency(this.props.selectedOptionsValue)} />
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className='booking-price'>
                        <div className='grid-row'>
                            <div className='grid-column--2-3 grid-column--medium-3-5'>
                                <span className='text-label'>
                                    <TextLabel
                                        text={_t.formatIntlMessage('options.total-price.total-description',
                                            {number: this.props.numberOfPassengers})
                                        }
                                    />
                                </span>
                            </div>
                            <div className='grid-column--1-3 grid-column--medium-2-5'>
                                <span className='text-label price'>
                                    <TextLabel text={_t.formatCurrency(this.props.totalPrice)} />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
