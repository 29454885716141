import PropTypes from 'prop-types'
import React, { Component } from 'react'
import classNames from 'classnames'
import TextLabel from '../../element/text-label'
import Icon from '../../element/icon'

export default class SegmentInboundOutbound extends Component {
    static propTypes = {
        header: PropTypes.string.isRequired,
        type: PropTypes.oneOf(['inbound', 'outbound'])
    }

    render () {
        const iconType = this.props.type === 'inbound' ? 'half-arrow-left' : 'half-arrow-right'
        const className = classNames('text-label', this.props.type)

        return (
            <tr className='outbound-inbound'>
                <td colSpan={3}>
                    <span className={className}>
                        <Icon type={iconType} className='xsmall align-left' />
                        <TextLabel text={this.props.header} />
                    </span>
                </td>
            </tr>
        )
    }
}
