import _ from 'lodash'

export default class ProductCollection {
    static create (products) {
        return new ProductCollection(products)
    }

    constructor (data) {
        if (!_.isArray(data)) {
            throw Error('Products should be an array')
        }
        this._data = _(data)
    }

    get totalPrice () {
        return this._data.sumBy('price')
    }

    value () {
        return this._data.value()
    }

    groupedBy (key) {
        return this._data
            .groupBy(key)
            .values()
    }

    getProductsForCode (code) {
        const products = this._data.filter(product => product.code === code).value()
        return ProductCollection.create(products)
    }

    getProductsPrefixedWithCode (code) {
        const products = this._data.filter(product => product.code.indexOf(code) === 0).value()
        return ProductCollection.create(products)
    }

    get quantity () {
        return this._data.size()
    }

    get groupedAndMappedByProductCode () {
        return this
            .groupedBy('product_code')
            .map(product => {
                const prod = _(product)
                return {
                    name: prod.first().product_name,
                    code: prod.first().product_code,
                    quantity: prod.sumBy('quantity'),
                    price: prod.sumBy('price')
                }
            })
            .value()
    }

    get groupedAndMappedByCode () {
        return this
            .groupedBy('code')
            .map(product => {
                const prod = _(product)
                return {
                    name: prod.first().name,
                    code: prod.first().code,
                    quantity: prod.size(),
                    price: prod.sumBy('price')
                }
            })
            .value()
    }

    get getGroupedByBundle () {
        return this._data
            .map(prod => prod.price_specifications)
            .flatten()
            .map(price => ({
                origin: price.origin,
                destination: price.destination,
                travel_id: price.travel_id,
                bundle_id: price.bundle_id,
                identifier: [price.bundle_id, price.travel_id].join(':')
            }))
            .uniqBy('identifier')
            .value()
    }
}
