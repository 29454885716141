import React from 'react'
import Container from '../base/container'
import Main from '../base/main'
import Row from '../element/row'
import Loader from '../element/loader'
import Reach5 from '../mixins/reach-5'
import createReactClass from 'create-react-class'

export default createReactClass({
    displayName: 'Reach5CallbackLayout',
    mixins: [
        Reach5('reach-5-callback')
    ],

    render () {
        return (
            <Container className='container reach5-callback'>
                <Main>
                    <Row className='row contains-blocking-loader'>
                        <Loader blocking />
                    </Row>
                </Main>
            </Container>
        )
    }
})
