import React from 'react'
import Reflux from 'reflux'
import _t from '../translate'
import actions from '../reflux/actions'
import GenericLayout from '../layouts/generic-layout'
import Row from '../element/row'
import TextLabel from '../element/text-label'
import RequestJsonApiKeyStore from '../reflux/stores/request-json-api-key-store'
import RequestJsonApiKeyForm from '../components/request-json-api-key/form'
import createReactClass from 'create-react-class'

export default createReactClass({
    displayName: 'RequestJsonApiKeyLayout',

    mixins: [
        Reflux.listenTo(RequestJsonApiKeyStore, 'onRequestJsonApiKeyUpdated')
    ],

    grantCategoryMap: {
        orientation: ['https://com.sqills.s3.oauth.orientation'],
        public: [
            'https://com.sqills.s3.oauth.public',
            'refresh_token'
        ],
        all: [
            'https://com.sqills.s3.oauth.orientation',
            'https://com.sqills.s3.oauth.public',
            'https://com.sqills.s3.oauth.crm',
            'https://com.sqills.s3.oauth.booking',
            'https://com.sqills.s3.oauth.agent',
            'refresh_token'
        ]
    },

    getInitialState () {
        return {submit: false, formData: RequestJsonApiKeyStore.getInitialState()}
    },

    onRequestJsonApiKeyUpdated (data) {
        if (!data.completed && data.isValid && this.state.submit) {
            actions.requestJsonApiKey({
                partner_organisation: data.fields.partnerOrganisation.value,
                contact_person: data.fields.contactPerson.value,
                contact_email_address: data.fields.contactEmailAddress.value,
                contact_phone_number: data.fields.contactPhoneNumber.value,
                application_description: data.fields.applicationDescription.value,
                grant_types: this.grantCategoryMap[data.fields.grantCategory.value]
            })
        }

        this.setState({formData: data, submit: false})
    },

    onSubmit (callback) {
        this.setState({submit: true}, callback)
    },

    render () {
        return (
            <GenericLayout>
                <Row className='row'>
                    <div className='grid-row'>
                        <div className='grid-column--1-1 grid-column--small-1-2
                            grid-column--medium-1-3 center-grid-column--small'>
                            {this.state.formData.completed ? this._renderPanelCompleted() : this._renderPanelForm()}
                        </div>
                    </div>
                </Row>
            </GenericLayout>
        )
    },

    _renderPanelForm () {
        return (
            <div className='request-json-api-key'>
                <div className='panel'>
                    <header>
                        <span className='text-label heading'>
                            <TextLabel text={_t.getIntlMessage('request-json-api-key.header')} />
                        </span>
                    </header>
                    <div className='content'>
                        <RequestJsonApiKeyForm formData={this.state.formData} onSubmit={this.onSubmit} />
                    </div>
                </div>
            </div>
        )
    },

    _renderPanelCompleted () {
        return (
            <div className='panel'>
                <header>
                    <span className='text-label heading'>
                        <TextLabel text={_t.getIntlMessage('request-json-api-key.completed.header')} />
                    </span>
                </header>
                <div className='content'>
                    <div className='feedback success'>
                        <span className='label feedback-message'>
                            {_t.getIntlMessage('request-json-api-key.completed.message')}
                        </span>
                    </div>
                </div>
            </div>
        )
    }
})
