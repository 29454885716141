import React from 'react'
import Container from '../base/container'
import Header from '../base/header'
import Main from '../base/main'
import Footer from '../base/footer'
import Row from '../element/row'
import TextLabel from '../element/text-label'

const SystemErrorLayout = () => (
    <Container className='container'>
        <Header>
            <Row className='contains-header'>
                <div className='grid-row'>
                    <div className='grid-column--1-1 grid-column--medium-1-2'>
                        <a href='#' className='sprite sprite-s3-passenger-logo'>S3 Passenger</a>
                    </div>
                    <div className='grid-column--1-1 grid-column--medium-1-2' />
                </div>
            </Row>
        </Header>
        <Main>
            <Row className='row contains-error-page'>
                <div className='grid-row'>
                    <div className='grid-column--1-1 grid-column--medium-1-2 center-grid-column--small'>
                        <div className='error-page'>
                            <div className='panel'>
                                <header>
                                <span className='text-label heading'>
                                    <TextLabel text='500 - System error' />
                                </span>
                                </header>
                                <div className='content'>
                                    <span className='text-label error-page-content'>
                                        <TextLabel text='This application can only run when cookies, local storage and
                                        session storage is enabled, please check your browser settings. Also when
                                        browsing using incognito mode this application can not run correctly.' />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Row>
        </Main>
        <Footer />
    </Container>
)

export default SystemErrorLayout
