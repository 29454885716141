import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from '../../translate'
import TextLabel from '../../element/text-label'

export default class InsuranceTotalPrice extends Component {
    static propTypes = {
        price: PropTypes.number.isRequired
    }

    render () {
        return (
            <div className='insurance-total-price'>
                <span className='text-label insurance-price'>
                    <TextLabel text={_t.formatCurrency(this.props.price)} />
                </span>
                <span className='text-label price-comment'>
                    <TextLabel text={_t.getIntlMessage('insurance.price-comment')} />
                </span>
            </div>
        )
    }
}
