import PropTypes from 'prop-types'
import React, {Component} from 'react'
import device from '../../device'
import InsuranceMobile from './insurance-mobile'
import InsuranceDesktop from './insurance-desktop'
import {
    fareInsuranceSelector,
    insuranceAvailableSelector
} from '../../models/selectors/components/booking/insurance'
import {
    connectState,
    getState
} from '../../reflux/bridge/connect-state'
import BookingStore from '../../reflux/stores/booking-store'
import UserStore from '../../reflux/stores/user-store'
import actions from '../../reflux/actions'
import {insuranceActiveForLocaleAndSalesChannel} from '../../misc/blablabus-helpers'

const benefits = [
    'BOOKING-INSURANCE-1',
    'BOOKING-INSURANCE-2',
    'BOOKING-INSURANCE-3',
    'BOOKING-INSURANCE-4',
    'BOOKING-INSURANCE-5'
]

class Insurance extends Component {
    static propTypes = {
        showInsurance: PropTypes.bool,
        onChange: PropTypes.func
    }

    constructor (props) {
        super(props)

        this.handleSelectWith = this.handleChange.bind(this, true)
        this.handleSelectWithout = this.handleChange.bind(this, false)
    }

    handleChange (value) {
        this.props.onChange(value)
        if (value === false) {
            actions.trackEvent('DeclineInsurance', {decline_insurance_clic: '$decline_insurance_clic'})
        }
    }

    render () {
        const {showInsurance, ...props} = this.props
        delete props.onChange
        props.benefits = benefits
        props.onSelectWith = this.handleSelectWith
        props.onSelectWithout = this.handleSelectWithout

        return showInsurance ? (
            <div className='insurance'>
                {
                    device.isMobile()
                        ? <InsuranceMobile {...props} />
                        : <InsuranceDesktop {...props} />
                }
            </div>
        ) : null
    }
}

const mapPropsToProps = () => {
    const state = getState()
    const fare = fareInsuranceSelector(state)
    const showInsurance = insuranceAvailableSelector(state) &&
        insuranceActiveForLocaleAndSalesChannel(UserStore.getSalesChannel())

    return {
        showInsurance,
        price: (showInsurance && fare && fare.price) || 0.0
    }
}

export default connectState(mapPropsToProps, [BookingStore])(Insurance)
