import React from 'react'
import PropTypes from 'prop-types'
import {withRouter} from 'react-router'
import Reflux from 'reflux'
import _t from '../../../translate'
import Options from './options'
import BookingStore from '../../../reflux/stores/booking-store'
import BookingModel from '../../../models/booking-model'
import actions from '../../../reflux/actions'
import {addFavoriteStations} from '../../../misc/favorite-stations-helper'
import {restoreJourneySearchCompose} from '../../../components/restore/restore-journey-search'
import {restoreBookingCompose} from '../../../components/restore/restore-booking'
import compose from '../../../misc/compose'
import createReactClass from 'create-react-class'
import {loadAvailableSeatSelectorLegs} from '../../../components/seat-selector/actions/seat-selector'
import CarriageLayoutsStore from '../../../reflux/stores/carriage-layouts-store'

const component = createReactClass({

    propTypes: {
        router: PropTypes.object
    },

    mixins: [
        Reflux.connectFilter(BookingStore, 'booking', data => BookingModel.create(data.booking)),
        Reflux.connectFilter(BookingStore, 'hasCompletedBooking',
            data => data.booking && data.booking.confirmed_timestamp
        )
    ],

    getInitialState () {
        return {
            loading: true
        }
    },

    async componentDidMount () {
        await this._setAvailability()

        this.state.availableSections.length
            ? this.optionsAvailable()
            : this.noOptions()
    },

    async _setAvailability () {
        const booking = BookingStore.getBookingModel()
        const numberOfPassengers = booking.passengers.size()
        const availableLegIds = await loadAvailableSeatSelectorLegs(numberOfPassengers)
        CarriageLayoutsStore.resetData()
        return this.setState({
            loading: false,
            availableSections: availableLegIds
        })
    },

    optionsAvailable () {
        this.setState({loading: false})
    },

    noOptions () {
        this.handleClickNext()
    },

    handleClickPrevious () {
        this.props.router.push(`/${_t.getLocales()}/mys3/re-booking/details`)
    },

    handleClickNext () {
        if (!this._hasOptionSelected()) {
            actions.trackEvent('DeclineOption', {decline_insurance_clic: '$decline_options_clic'})
        }

        if (this.state.booking.totalPriceToBePaid > 0) {
            this.props.router.push(`/${_t.getLocales()}/mys3/re-booking/payment`)
        } else {
            this.confirmBooking()
        }
    },

    _hasOptionSelected () {
        if (this.state.booking) {
            return Boolean(
                this.state.booking.hasProvisionalManuallySelectedSeats ||
                this.state.booking.tariffSegmentCollection.hasProvisionalLuggageProducts ||
                this.state.booking.tariffSegmentCollection.hasProvisionalBikeProducts)
        }

        return false
    },

    confirmBooking () {
        this.setState({loading: true}, () => {
            actions.myS3RebookSuccessful()
        })
        this._handleConfirmBooking()
    },

    _handleConfirmBooking () {
        return actions.confirmBooking(this.state.booking.bookingNumber).then(() => {
            addFavoriteStations()
            this.props.router.push(`/${_t.getLocales()}/mys3/re-booking/confirmation?s3_status=success`)
        })
    },

    render () {
        const {booking, loading, availableSections} = this.state
        return (
            <Options
                hasBooking={Boolean(booking)}
                loading={loading}
                availableSections={availableSections}
                optionSelected={this._hasOptionSelected()}
                onClickPrevious={this.handleClickPrevious}
                onClickNext={this.handleClickNext}
            />
        )
    }
})

const container = compose(
    restoreJourneySearchCompose({withBlockingLoader: true}),
    restoreBookingCompose({withBlockingLoader: true})
)

export default withRouter(container(component))
