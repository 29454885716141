import PropTypes from 'prop-types'
import React, { Component } from 'react'
import RebookingLayoutContainer from '../../re-booking-layout-container'
import SeatSelectorContainer from '../../../components/seat-selector/seat-selector-container'
import restoreJourneySearch from '../../../components/restore/restore-journey-search'
import restoreBooking from '../../../components/restore/restore-booking'

const component = class SeatSelectorLayout extends Component {
    static propTypes = {
        location: PropTypes.object
    }

    render () {
        return (
            <RebookingLayoutContainer name='seat-selection-container'>
                <SeatSelectorContainer
                    returnUrl='/mys3/re-booking/options'
                    selectedLegPassenger={this.props.location.query ? this.props.location.query : null}
                />
            </RebookingLayoutContainer>
        )
    }
}

export default restoreJourneySearch(
    restoreBooking(component, {withBlockingLoader: true}),
    {withBlockingLoader: true}
)
