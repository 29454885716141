import React from 'react'
import Reflux from 'reflux'
import difference from 'lodash/difference'
import FlashMessage from '../element/flash-message'
import FlashMessageStore from '../reflux/stores/flash-message-store'
import actions from '../reflux/actions'
import createReactClass from 'create-react-class'

export default createReactClass({

    displayName: 'InteractiveFlashMessage',

    mixins: [
        Reflux.connect(FlashMessageStore, 'flashMessages')
    ],

    componentWillUnmount () {
        actions.hideAllFlashMessages()
    },

    shouldComponentUpdate (_, nextState) {
        return this.state.flashMessages.length !== nextState.flashMessages.length ||
            difference(
                this.state.flashMessages.map(flashMessage => flashMessage.id),
                nextState.flashMessages.map(flashMessage => flashMessage.id)
            ).length > 0
    },

    render () {
        return <div>{this.state.flashMessages.map(this._renderFlashMessage)}</div>
    },

    _renderFlashMessage (flashMessage) {
        return (
            <FlashMessage
                key={flashMessage.id}
                text={flashMessage.text}
                severity={flashMessage.severity}
                onClose={() => actions.hideFlashMessage(flashMessage.id)}
            />
        )
    }
})
