/* globals S3P_SETTINGS: true */

import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import oauth from '../oauth'
import _t from '../translate'
import AccessManager from '../data/access-manager'
import actions from '../reflux/actions'
import reach5Login from '../misc/reach-5-login'
import Reach5Store from '../reflux/stores/reach-5-store'

const errorRedirect = () => window.reacthistory.push(`/${_t.getLocales()}/error`)

const loadPublicToken = publicChannel => {
    const loadReach5 = Reach5Store.hasIdToken()
    const loadToken = loadReach5 ? reach5Login() : oauth.publicAccess(publicChannel)

    loadToken.then(() => {
        if (!oauth.hasValidToken()) {
            errorRedirect()
        } else if (!loadReach5) {
            actions.loadUserLoginInformation()
        }
    }).catch(errorRedirect)
}

const loadToken = options => {
    if (get(options, 'ensureToken') && S3P_SETTINGS.s3Passenger.features.auth.defaultPublicChannel) {
        loadPublicToken(S3P_SETTINGS.s3Passenger.features.auth.defaultPublicChannel)
    } else if ('publicChannel' in options) {
        loadPublicToken(options.publicChannel)
    }
}

export default async (_, __, callback, options = {}) => {
    let roleCheck = () => true
    const role = get(options, 'role')
    if (role) {
        roleCheck = () => AccessManager.hasRole(role)
    } else if (get(options, 'oneOfRoles')) {
        roleCheck = () => get(options, 'oneOfRoles').reduce(
            (access, _role) => access || AccessManager.hasRole(_role),
            false
        )
    }

    let hasToken = oauth.hasToken() || oauth.hasPendingRequest()
    !hasToken && loadToken(options)

    // If the user isn't loaded yet, but has a valid token. We get the user first so we can determine it's role.
    // Else we might upgrade a crm token to a different token.
    if (!AccessManager.isAuthenticated() && oauth.hasValidToken()) {
        await actions.loadUserLoginInformation()
    }

    // sso when there is a valid token.
    if (hasToken && oauth.hasValidToken()) {
        reach5Login()
    }

    if (!roleCheck()) {
        // code bellow is duplicated with client.js, must be refactored
        const unauthorizedPages = S3P_SETTINGS.s3Passenger.features.unauthorizedPages
        let redirectPage = unauthorizedPages.default

        if (AccessManager.isCrmUser() && !isEmpty(unauthorizedPages.crm)) {
            redirectPage = unauthorizedPages.crm
        } else if (AccessManager.isBookingUser() && !isEmpty(unauthorizedPages.booking)) {
            redirectPage = unauthorizedPages.booking
        } else if (AccessManager.isPublicUser() && !isEmpty(unauthorizedPages.public)) {
            redirectPage = unauthorizedPages.public
        }

        actions.logout().then(() => {
            window.reacthistory.push(`/${_t.getLocales()}${redirectPage}`)
        })

        return
    }

    // A booking user is only allowed to have 1 booking. So when creating a new booking, create a new token.
    if (hasToken && get(options, 'renewToken') && AccessManager.isBookingUser()) {
        loadToken(options)
    }

    callback()
}
