import React, {Component} from 'react'
import InputField from './input-field'

export default class PasswordField extends Component {
    render () {
        const props = {...this.props}
        delete props.type

        return <InputField ref='inputField' type='password' {...props} />
    }

    getInputElement () {
        return this.refs.inputField && this.refs.inputField.getInputElement()
    }

    getValue () {
        return this.refs.inputField && this.refs.inputField.getValue()
    }

    setValue (value) {
        return this.refs.inputField && this.refs.inputField.setValue(value)
    }

    focus () {
        return this.refs.inputField && this.refs.inputField.focus()
    }
}
