import React, {Component} from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import classNames from 'classnames'
import Icon from '../element/icon'
import FormattedHTMLMessage from '../translate/formatted-html-message'

export default class Message extends Component {
    static propTypes = {
        message: PropTypes.shape({
            content: PropTypes.string.isRequired,
            severity: PropTypes.string.isRequired
        }).isRequired
    }

    render () {
        const map = {
            information: 'information',
            warning: 'warning',
            error: 'error',
            highlight: 'highlight',
            success: 'success',
            info: 'information',
            warn: 'warning'
        }
        const severity = _.get(map, this.props.message.severity) || 'warning'
        const className = classNames('feedback', severity)

        return (
            <div className={className}>
                <span className='text-label'>
                    <Icon type={severity} className='small align-left' />
                    <FormattedHTMLMessage message={this.props.message.content} />
                </span>
            </div>
        )
    }
}
