import {createSelector} from 'reselect'
import {journeySearchSelector} from '../../../../reflux/bridge/journey-search'
import {bookingSelector} from '../../../../reflux/bridge/booking'
import {
    hasOutboundAndInboundSelectedBundleSelector,
    selectedBundlesSelector,
    inboundBeforeOutboundSelector
} from '../orientation/journey-search'
import {tariffSegmentsSelector} from '../../api/v2/booking/tariff-segments'
import {routesSelector as baseRoutesSelector} from '../../api/v2/orientation/journey-search'

export const journeySearchValidSelector = createSelector([journeySearchSelector], journeySearch => Boolean(
    journeySearch &&
    (journeySearch.inboundDate || journeySearch.outboundDate) &&
    journeySearch.passengers.length > 0 &&
    journeySearch.origin &&
    journeySearch.destination
))

export const bundleSelectionCompleteSelector = createSelector(
    [journeySearchSelector, selectedBundlesSelector],
    (journeySearch, selectedBundles) => {
        const isReturnTripBundles = Boolean(journeySearch.departure_date && journeySearch.return_date)
        return selectedBundles.length === (isReturnTripBundles ? 2 : 1)
    }
)

export const isOutboundBundleArrivalAfterInboundBookingDepartureSelector = createSelector(
    [selectedBundlesSelector, tariffSegmentsSelector(bookingSelector), baseRoutesSelector],
    (selectedBundles, tariffSegments, routes) => {
        const outboundBundle = selectedBundles.find(bundle => bundle.direction === 'outbound')
        const inboundSegment = tariffSegments.find(segment => segment.direction === 'inbound')

        if (inboundSegment && outboundBundle) {
            const outboundRoute = routes.get(outboundBundle.routeId)
            return Boolean(
                outboundRoute &&
                outboundRoute.arrivalStation.arrivalTime.isAfter(inboundSegment.departureDate)
            )
        } else {
            return false
        }
    }
)

export const isInboundBundleDepartureBeforeOutboundBookingArrivalSelector = createSelector(
    [selectedBundlesSelector, tariffSegmentsSelector(bookingSelector), baseRoutesSelector],
    (selectedBundles, tariffSegments, routes) => {
        const inboundBundle = selectedBundles.find(bundle => bundle.direction === 'inbound')
        const outboundBundle = selectedBundles.find(bundle => bundle.direction === 'outbound')
        const outboundSegment = tariffSegments.find(segment => segment.direction === 'outbound')

        if (outboundBundle && inboundBundle) {
            return false
        }

        if (outboundSegment && inboundBundle) {
            const inboundRoute = routes.get(inboundBundle.routeId)
            return Boolean(
                inboundRoute &&
                inboundRoute.departureStation.departureTime.isBefore(outboundSegment.arrivalDate)
            )
        } else {
            return false
        }
    }
)

export const inboundBeforeOutboundWithBookingSelector = state =>
    hasOutboundAndInboundSelectedBundleSelector(state)
        ? inboundBeforeOutboundSelector(state)
        : (
            isOutboundBundleArrivalAfterInboundBookingDepartureSelector(state) ||
            isInboundBundleDepartureBeforeOutboundBookingArrivalSelector(state)
        )

