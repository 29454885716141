import Reflux from 'reflux'
import actions from '../../reflux/actions'

export default Reflux.createStore({

    listenables: actions,

    init () {
        this._resetData()
    },

    _resetData () {
        this.data = {}
    },

    resetData () {
        this._resetData()
        this.trigger(this.data)
    },

    onApiError (error) {
        this.data = error

        this.trigger(this.data)
    },

    getError () {
        return this.data
    }
})
