// Libraries
import React from 'react'
import {Route} from 'react-router'

// Layouts
import MyS3UpdatePassengersPaymentLayout from '../../../layouts/my-s3-update-passengers-payment-layout'

export default (
    <Route path='/:lang/mys3/update-passengers/payment' component={MyS3UpdatePassengersPaymentLayout} />
)
