import PropTypes from 'prop-types'
import React, { Component } from 'react'
import TextLabel from '../text-label'
import Loader from '../loader'

export default class PanelCard extends Component {
    static propTypes = {
        title: PropTypes.string,
        content: PropTypes.string,
        isCmsLoading: PropTypes.bool,
        showLoader: PropTypes.bool
    }

    static defaultProps = {
        isCmsLoading: false,
        showLoader: true
    }

    render () {
        if (this.props.showLoader && this.props.isCmsLoading) {
            return <Loader />
        }

        return this.props.title && this.props.content ? (
            <div className='card'>
                <div className='card--header'>
                    <span className='text-label heading'>
                        <TextLabel text={this.props.title} />
                    </span>
                </div>
                <div className='card--content'>
                    <span dangerouslySetInnerHTML={{__html: this.props.content}} />
                </div>
            </div>
        ) : null
    }
}
