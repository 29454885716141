import PropTypes from 'prop-types'
import React from 'react'
import Reflux from 'reflux'
import {withRouter} from 'react-router'
import includes from 'lodash/includes'
import _t from '../../translate'
import BookingStore from '../../reflux/stores/booking-store'
import FlashMessageStore from '../../reflux/stores/flash-message-store'
import BookingModel from '../../models/booking-model'
import actions from '../../reflux/actions'
import containBookingActions from './booking-actions'
import {isNewVSC, newVSCUrl} from '../../misc/new-vsc-helper'
import {
    getAffiliateHomepageUrl,
    getBlablaCarHomepageUrl,
    isPremiumPartner
} from '../../reflux/bridge/affiliate'
import createReactClass from 'create-react-class'

export default Component => withRouter(containBookingActions(createReactClass({

    displayName: 'BookingConfirmationDetails',

    mixins: [
        Reflux.connectFilter(BookingStore, 'booking', data => data.booking)
    ],

    propTypes: {
        status: PropTypes.string,
        urlStatus: PropTypes.string,
        requiresPayment: PropTypes.bool,
        router: PropTypes.object,
        onViewBooking: PropTypes.func,
        afterSalesAction: PropTypes.string
    },

    _initStatus (requiresPayment) {
        const urlStatus = (this.props.afterSalesAction || requiresPayment === false)
            ? ['success', 'cancelled', 'failed']
            : ['cancelled', 'failed']

        return includes(urlStatus, this.props.urlStatus) ? this.props.urlStatus : 'pending'
    },

    getInitialState () {
        return {
            status: this._initStatus(true)
        }
    },

    componentWillMount () {
        const booking = BookingModel.create(this.state.booking)
        if (booking && !booking.hasPendingPayments && booking.isConfirmed) {
            this.setState({status: this._initStatus(false)})
        }
    },

    onPaymentSuccess () {
        this.setState({status: 'success'})
    },

    onPaymentTimeOut () {
        this.setState({status: 'timeOut'})
    },

    onPaymentFailed () {
        actions.showFlashMessage({
            text: _t.message('flash-message.payment-failed'),
            severity: FlashMessageStore.severity.highlight
        })
        this.props.router.push(`/${_t.getLocales()}/booking/payment`)
    },

    onPaymentCancelled () {
        actions.showFlashMessage({
            text: _t.message('flash-message.payment-cancelled'),
            severity: FlashMessageStore.severity.warning
        })
        this.props.router.push(`/${_t.getLocales()}/booking/payment`)
    },

    redirectHome (e) {
        e.preventDefault()
        if (isNewVSC()) {
            window.location.href = newVSCUrl()
        } else if (isPremiumPartner()) {
            window.location.href = getAffiliateHomepageUrl()
        } else {
            window.location.href = getBlablaCarHomepageUrl()
        }
    },

    redirectOrientation (e) {
        e.preventDefault()

        if (isNewVSC()) {
            window.location.href = newVSCUrl()
        } else if (isPremiumPartner()) {
            window.location.href = getAffiliateHomepageUrl()
        } else {
            window.location.href = getBlablaCarHomepageUrl()
        }
    },

    onDownloadInvoice () {
        actions.myS3GetInvoiceUrl(this.state.booking.booking_number)
    },

    render () {
        return (
            <Component
                booking={BookingModel.create(this.state.booking)}
                status={this.state.status}
                urlStatus={this.props.urlStatus}
                onPaymentSuccess={this.onPaymentSuccess}
                onPaymentCancelled={this.onPaymentCancelled}
                onPaymentTimeOut={this.onPaymentTimeOut}
                onPaymentFailed={this.onPaymentFailed}
                onViewBooking={this.props.onViewBooking}
                onRedirectHome={this.redirectHome}
                onRedirectOrientation={this.redirectOrientation}
                onDownloadInvoice={this.onDownloadInvoice}
                afterSalesAction={this.props.afterSalesAction}
            />
        )
    }
})))
