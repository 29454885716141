import React from 'react'
import {Route} from 'react-router'
import authCheck from '../../mixins/auth'
import BookingCustomerDetailsLayout from '../../layouts/booking/customer-details'

export default (
    <Route path='/:lang/booking/customer-details'
           component={BookingCustomerDetailsLayout}
           onEnter={(nextState, replaceState, callback) => authCheck(nextState, replaceState, callback)}
    />
)
