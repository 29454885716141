import PropTypes from 'prop-types'
import React, {Component} from 'react'
import _t from '../../translate'
import AccessManager from '../../data/access-manager'
import BookingModel from '../../models/booking-model'
import TextLabel from '../../element/text-label'
import Icon from '../../element/icon'
import Button from '../../element/button'
import ConfirmDialog from '../../element/confirm-dialog'
import RequestVoucherModalDialog from './refund-method/request-voucher-modal-dialog'

export default class MyS3BookingRefundMethod extends Component {
    static propTypes = {
        booking: PropTypes.instanceOf(BookingModel).isRequired,
        isLoadingRequestVoucher: PropTypes.bool.isRequired,
        handleRequestRefundVoucher: PropTypes.func.isRequired,
        handlePrintRefundVoucher: PropTypes.func.isRequired,
        shouldPrint: PropTypes.bool.isRequired,
        refundVoucher: PropTypes.object,
        onRequestRefundPsp: PropTypes.func.isRequired
    }

    constructor (...args) {
        super(...args)
        this.state = {showConfirmRefundVoucherDialog: false, showConfirmRefundPspDialog: false}
        this._handleShowConfirmRefundVoucherDialog = this._handleShowConfirmRefundVoucherDialog.bind(this)
        this._handleShowConfirmRefundPspDialog = this._handleShowConfirmRefundPspDialog.bind(this)
        this._handleCloseConfirmRefundVoucherDialog = this._handleCloseConfirmRefundVoucherDialog.bind(this)
        this._handleCloseConfirmRefundPspDialog = this._handleCloseConfirmRefundPspDialog.bind(this)
        this._handleRefundPsp = this._handleRefundPsp.bind(this)
    }

    _handleShowConfirmRefundVoucherDialog () {
        this.setState({showConfirmRefundVoucherDialog: true})
    }

    _handleShowConfirmRefundPspDialog () {
        this.setState({showConfirmRefundPspDialog: true})
    }

    _handleCloseConfirmRefundVoucherDialog () {
        this.setState({showConfirmRefundVoucherDialog: false})
    }

    _handleCloseConfirmRefundPspDialog () {
        this.setState({showConfirmRefundPspDialog: false})
    }

    _handleRefundPsp () {
        this.setState({showConfirmRefundPspDialog: false}, this.props.onRequestRefundPsp)
    }

    render () {
        return (
            <div className='refund-method'>
                <h3>{_t.message('booking-specification.refund.header')}</h3>
                <span className='text-label refund-method-description'>
                    <TextLabel text={_t.message('booking-specification.refund.description', {
                        amount: this.props.booking.openRefundAmount,
                        gender: this.props.booking.customer.gender,
                        name: this.props.booking.customerName
                    })} />
                </span>
                <div className='grid-row'>
                    {this._renderRefundVoucherOption()}
                </div>
                {this._renderConfirmRefundVoucherDialog()}
                {this._renderConfirmRefundPspDialog()}
            </div>
        )
    }

    _renderRefundVoucherOption () {
        return AccessManager.hasAccessToAfterSalesRefundVoucher() ? (
            <div className='grid-column--1-1 grid-column--small-1-2'>
                <div className='refund-item'>
                    <div className='callout'>
                        <span className='text-label refund-title'>
                            <Icon type='voucher' className='large align-left' />
                            <TextLabel text={_t.message('booking-specification.refund.method-voucher.header')} />
                        </span>
                        <span className='text-label refund-subtext'>
                            <TextLabel text={_t.message(
                                'booking-specification.refund.method-voucher.description',
                                {gender: this.props.booking.customer.gender, name: this.props.booking.customerName}
                            )} />
                        </span>
                        <Button className='button primary refund'
                            type='button'
                            onClick={this._handleShowConfirmRefundVoucherDialog}>
                            <TextLabel text={_t.message('booking-specification.refund.method-voucher.button')} />
                        </Button>
                    </div>
                </div>
            </div>
        ) : null
    }

    _renderConfirmRefundVoucherDialog () {
        return this.state.showConfirmRefundVoucherDialog ? (
            <RequestVoucherModalDialog
                booking={this.props.booking}
                isLoadingRequestVoucher={this.props.isLoadingRequestVoucher}
                handleRequestRefundVoucher={this.props.handleRequestRefundVoucher}
                handlePrintRefundVoucher={this.props.handlePrintRefundVoucher}
                shouldPrint={this.props.shouldPrint}
                refundVoucher={this.props.refundVoucher}
                handleClose={this._handleCloseConfirmRefundVoucherDialog}
            />
        ) : null
    }

    _renderConfirmRefundPspDialog () {
        return this.state.showConfirmRefundPspDialog ? (
            <ConfirmDialog
                onClose={this._handleCloseConfirmRefundPspDialog}
                onCancelButtonClick={this._handleCloseConfirmRefundPspDialog}
                onConfirmButtonClick={this._handleRefundPsp}
                labelPrefix='booking-specification.refund.method-psp.confirm-dialog'
            />
        ) : null
    }
}
