import React from 'react'
import Reflux from 'reflux'
import ApiErrorStore from './reflux/stores/api-error-store'
import SessionExpiredDialog from './base/session-expired-dialog'
import createReactClass from 'create-react-class'

export default createReactClass({
    displayName: 'appContainer',

    mixins: [
        Reflux.listenTo(ApiErrorStore, 'onApiError')
    ],

    getInitialState () {
        return {showSessionExpiredDialog: false}
    },

    onApiError (data) {
        this.setState({showSessionExpiredDialog: Boolean(data.oauthRefreshError)})
    },

    render () {
        return (
            <react-wrapper class='react-wrapper'>
                {this.props.children}
                {this.state.showSessionExpiredDialog ? <SessionExpiredDialog /> : null}
            </react-wrapper>
        )
    }

})
