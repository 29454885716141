const getBody = () => document.getElementsByTagName('body')[0]

const getBodyClassNames = () => (getBody().className || '').split(' ').filter(className => Boolean(className))

const setBodyClassNames = classNames => {
    getBody().className = classNames.join(' ')
    return getBody().className
}

export const addBodyClass = className => {
    const classNames = getBodyClassNames()
    if (!classNames.includes(className)) {
        classNames.push(className)
    }
    setBodyClassNames(classNames)
}

export const removeBodyClass = className =>
    setBodyClassNames(getBodyClassNames().filter(bodyClassName => bodyClassName !== className))
