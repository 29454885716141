import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {cmsBlockContainer} from '../cms/cms-block-container'
import Icon from '../../element/icon'
import classNames from 'classnames'

export default class BookingConfirmationServiceType extends Component {
    static propTypes = {
        hasOuibus: PropTypes.bool.isRequired,
        sprite: PropTypes.string.isRequired,
        content: PropTypes.any
    }

    render () {
        const className = classNames('sprite', this.props.sprite)
        return this.props.content ? (
            <div className='booking-service-type'>
                <div className='panel'>
                    <div className='content'>
                        <h3>{this.props.content}</h3>
                        <div className='booking-service-type-wrapper'>
                            <Icon type='bus' className='xlarge' />
                            <span className={className} />
                            {this._renderOuibus()}
                        </div>
                    </div>
                </div>
            </div>
        ) : null
    }

    _renderOuibus () {
        return this.props.hasOuibus ? [
            <span key='sprite-plus' className='sprite plus' />,
            <Icon key='icon-bus' type='bus' className='xlarge' />,
            <span key='sprite-ouibus' className='sprite ouibus' />
        ] : null
    }
}

export const BookingConfirmationAlsa = cmsBlockContainer(
    'BOOKING-CONF-ALSA',
    BookingConfirmationServiceType
)
export const BookingConfirmationTransaltitude = cmsBlockContainer(
    'BOOKING-CONF-TRANSALTITUDE',
    BookingConfirmationServiceType
)
export const BookingConfirmationNationalExpress = cmsBlockContainer(
    'BOOKING-CONF-NEL',
    BookingConfirmationServiceType
)
export const BookingConfirmationMarino = cmsBlockContainer(
    'BOOKING-CONF-MARINO',
    BookingConfirmationServiceType
)
export const BookingConfirmationBusVerts = cmsBlockContainer(
    'BOOKING-CONF-BUSVERTS',
    BookingConfirmationServiceType
)
