import PropTypes from 'prop-types'
import React, { Component } from 'react'
import MyS3OptionsLayoutContainer from '../../my-s3-options-layout-container'
import MyS3SeatSelectorLayoutContainer from './seat-selector-layout-container'

export default class MyS3SeatEditSeatLayout extends Component {
    static propTypes = {
        params: PropTypes.shape({
            id: PropTypes.string.isRequired
        }).isRequired
    }

    render () {
        return (
            <MyS3OptionsLayoutContainer name='edit-seat-container'>
                <MyS3SeatSelectorLayoutContainer
                    legId={this.props.params.id}
                    returnUrl='/mys3/my-options'
                    editOnly
                />
            </MyS3OptionsLayoutContainer>
        )
    }
}
