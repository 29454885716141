import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _ from 'lodash'
import _t from '../../../translate'
import TextLabel from '../../../element/text-label'

export default class SegmentProductInformation extends Component {
    static propTypes = {
        passenger: PropTypes.shape({
            passengerType: PropTypes.string.isRequired,
            first_name: PropTypes.string,
            last_name: PropTypes.string,
            id: PropTypes.string
        }).isRequired,
        product: PropTypes.shape({
            name: PropTypes.string.isRequired,
            description: PropTypes.string,
            tariff_code: PropTypes.string.isRequired,
            price: PropTypes.number.isRequired
        }),
        required: PropTypes.bool
    }

    render () {
        const productName = !this.props.required ? this.props.product.name
            : _t.message('booking-information.passenger', {
                number: _(this.props.passenger.id.split('_')).last()
            })

        return (
            <tr className='segment-product-information action-row' onClick={this.onAction}>
                <td>
                    <span className='text-label product-type'>
                        <TextLabel text={productName} />
                    </span>
                </td>
                <td>
                    <span className='text-label passenger-type'>
                        {this._renderPassengerTextLabel()}
                    </span>
                </td>
                <td>
                    <span className='text-label price'>
                        <TextLabel text={_t.formatCurrency(this.props.product.price)} />
                    </span>
                </td>
            </tr>
        )
    }

    _renderPassengerTextLabel () {
        const {first_name: firstName, last_name: lastName, passengerType} = this.props.passenger
        const text = firstName && lastName
            ? `${firstName} ${lastName}`
            : _t.message(`passenger-types.${passengerType.toLowerCase()}`)
        return <TextLabel text={text} />
    }
}
