import TracktorJS from '@blablacar/tracktor'
import sessionStorage from '../storage'
import cookieCutter from 'cookie-cutter'
import {postDataWithRetry} from './tracktor'
import {showBlablaCarBranding} from './blablabus-helpers'

const config = {
    appName: 'BlaBlaBus',
    sendUrl: 'https://t.blablacar.com/c'
}

const frontEndUri = `${window.location.protocol}//${window.location.hostname}`
const userConfig = {
    userAgent: window.navigator.userAgent,
    hostname: frontEndUri.includes('localhost') ? 'localhost' : frontEndUri // this is necessary because the storage part of tracktor (not ours) strictly checks for 'localhost' to set the domain for the cookies. If the parameter is used as is was the domain it not correct and the cookie is not set
}

const storage = {
    get: key => showBlablaCarBranding && key === 'visitorId' ? sessionStorage.get(key) : cookieCutter.get(key),
    set: (key, value, opts) => cookieCutter.set(key, value, {...opts, path: '/'})
}

export const LogTracktorEvent = (eventName, payload) => {
    TracktorJS.initializeEventBuffer(config)

    const Tracktor = new TracktorJS(userConfig, storage)

    Tracktor.push(eventName, payload)
}

const isMyS3Booking = path => path.match(/mys3\/booking/)

const isBookingConfirmation = path => path.includes('/booking/confirm')

export const tracktorPageEvent = () => {
    const pathname = window.location.pathname

    const payload = {
        page_category: isMyS3Booking(pathname) ? 'aftersales' : 'booking',
        url: window.location.href,
        page: window.location.pathname
    }

    let eventName = {
        name: 'page_stream',
        version: 2
    }
    postDataWithRetry(eventName, payload) // oude tracktor
    !showBlablaCarBranding && LogTracktorEvent(eventName, payload) // nieuwe tracktor

    if (isBookingConfirmation(pathname)) {
        eventName = {
            name: 'booking_complete',
            version: 1
        }
        Object.assign(payload, {id: sessionStorage.get('bookingNumber')})
        postDataWithRetry(eventName, payload) // oude tracktor
        !showBlablaCarBranding && LogTracktorEvent(eventName, payload) // nieuwe tracktor
    }
}

export const tracktorButtonEvent = () => {
    const payload = {
        name: 'pay_button'
    }

    const eventName = {
        name: 'button_action',
        version: 1
    }
    postDataWithRetry(eventName, payload)
    !showBlablaCarBranding && LogTracktorEvent(eventName, payload)
}

export const tracktorProbeEvent = mktCode => {
    const payload = {
        mkt_code: mktCode
    }

    const eventName = {
        name: 'mkt_code_assign',
        version: 1
    }

    !showBlablaCarBranding && LogTracktorEvent(eventName, payload)
}
