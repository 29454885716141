/* globals S3P_SETTINGS: true */

import React from 'react'
import Reflux from 'reflux'
import _t from '../../translate'
import _ from 'lodash'
import moment from 'moment-timezone'

import Button from '../../element/button'
import TextField from '../../element/form/text-field'
import TextLabel from '../../element/text-label'
import StationSelector from '../../element/station-selector'
import DatePicker from '../../element/jquery/date-picker'
import InteractiveFlashMessage from '../interactive-flash-message'
import createReactClass from 'create-react-class'

import StationsStore from '../../reflux/stores/stations-store'
import ResendTicketsStore from '../../reflux/stores/resend-tickets-store'

import actions from '../../reflux/actions'

export default createReactClass({
    displayName: 'ResendTicketsForm',

    mixins: [
        Reflux.connectFilter(StationsStore, 'stations', data => data.stations),
        Reflux.connect(ResendTicketsStore, 'formData')
    ],

    _getStationSelectData () {
        return this.state.stations
            .filter(station => !station.agglomeration)
            .map(station => ({
                value: station._u_i_c_station_code,
                text: station.name,
                isMain: true,
                data: station
            }))
    },

    onResendTickets (event) {
        event.preventDefault()
        ResendTicketsStore.validateFields()
        if (this.state.formData.isValid && !this.state.formData.loading) {
            actions.resendTickets({
                email: this.state.formData.fields.email.value,
                departure_date: moment(this.state.formData.fields.travelDate.value).format(),
                origin: this.state.formData.fields.origin.value,
                destination: this.state.formData.fields.destination.value
            })
        }
    },

    componentWillMount () {
        actions.loadStations(S3P_SETTINGS.s3Passenger.features.stations)
    },

    render () {
        return (
            <div>
                <InteractiveFlashMessage />
                {!this.state.formData.success ? this._renderForm() : null}
            </div>
        )
    },

    _renderForm () {
        return (
            <form action='#' noValidate>
                <div className='grid-row'>
                    <div className='grid-column--1-3'>
                        <label className='asterisk-after inline-label' htmlFor=''>
                            {_t.getIntlMessage('resend-tickets.email.label')}
                        </label>
                    </div>
                    <div className='grid-column--2-3'>
                        <TextField autoComplete='off' {...this.getTextFieldProps('email')} />
                        <span className='text-label sub-label'>
                            <TextLabel text={_t.getIntlMessage('resend-tickets.email.sub-label')} />
                        </span>
                    </div>
                </div>
                <div className='grid-row'>
                    <div className='grid-column--1-3'>
                        <label className='asterisk-after inline-label' htmlFor=''>
                            {_t.getIntlMessage('resend-tickets.origin.label')}
                        </label>
                    </div>
                    <div className='grid-column--2-3'>
                        <StationSelector {...this.getStationSelectorFieldProps('origin')} />
                        <span className='text-label sub-label'>
                            <TextLabel text={_t.getIntlMessage('resend-tickets.origin.sub-label')} />
                        </span>
                    </div>
                </div>
                <div className='grid-row'>
                    <div className='grid-column--1-3'>
                        <label className='asterisk-after inline-label' htmlFor=''>
                            {_t.getIntlMessage('resend-tickets.destination.label')}
                        </label>
                    </div>
                    <div className='grid-column--2-3'>
                        <StationSelector {...this.getStationSelectorFieldProps('destination')} />
                        <span className='text-label sub-label'>
                            <TextLabel text={_t.getIntlMessage('resend-tickets.destination.sub-label')} />
                        </span>
                    </div>
                </div>
                <div className='grid-row'>
                    <div className='grid-column--1-3'>
                        <label className='asterisk-after inline-label' htmlFor=''>
                            {_t.getIntlMessage('resend-tickets.travel-date.label')}
                        </label>
                    </div>
                    <div className='grid-column--2-3'>
                        <DatePicker {...this.getDatePickerFieldProps('travelDate')} />
                        <span className='text-label sub-label'>
                            <TextLabel text={_t.getIntlMessage('resend-tickets.travel-date.sub-label')} />
                        </span>
                    </div>
                </div>
                <div className='grid-row'>
                    <div className='grid-column--1-1'>
                        <Button loading={this.state.formData.loading} type='submit' className='button primary'
                            onClick={this.onResendTickets}>
                            <TextLabel text={_t.getIntlMessage('resend-tickets.button')} />
                        </Button>
                    </div>
                </div>
            </form>
        )
    },

    getTextFieldProps (fieldName) {
        return Object.assign({
            defaultValue: this.state.formData.fields[fieldName].value,
            onBlur: this.onBlur
        }, this.getDefaultFieldProps(fieldName, false))
    },

    getStationSelectorFieldProps (fieldName) {
        return Object.assign({
            value: this.state.formData.fields[fieldName].value,
            data: this._getStationSelectData(),
            onChange: value => this.onChangeField(fieldName, value)
        }, this.getDefaultFieldProps(fieldName))
    },

    getDatePickerFieldProps (fieldName) {
        return Object.assign({
            value: this.state.formData.fields[fieldName].value,
            autoComplete: 'off',
            datePickerOptions: {minDate: 0},
            onChange: value => this.onChangeField(fieldName, value)
        }, this.getDefaultFieldProps(fieldName))
    },

    _prefixFieldLabel (field, label) {
        return label ? `resend-tickets.${field}.${label}` : null
    },

    getDefaultFieldProps (fieldName, translatePlaceholder = true) {
        const kebabCase = _.kebabCase(fieldName)
        let placeholder = this._prefixFieldLabel(kebabCase, 'placeholder')
        if (translatePlaceholder) {
            placeholder = _t.getIntlMessage(placeholder)
        }

        return {
            ref: fieldName,
            id: kebabCase,
            className: kebabCase,
            placeholder: placeholder,
            errorText: this._prefixFieldLabel(kebabCase, this.state.formData.fields[fieldName].errorText),
            required: true
        }
    },

    onBlur (event) {
        this.onChangeField(event.target.id, event.target.value)
    },

    onChangeField (fieldName, value) {
        actions.processResendTicketsData([{id: fieldName, value}])
    }

})
