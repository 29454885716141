import PropTypes from 'prop-types'
import React from 'react'
import Reflux from 'reflux'
import Modal from '../element/modal-dialog'
import Button from '../element/button'
import Icon from '../element/icon'
import {withRouter} from 'react-router'
import actions from '../reflux/actions'
import BookingStore from '../reflux/stores/booking-store'
import AccessManager from '../data/access-manager'
import _t from '../translate'
import {
    newOuibusSearchUrl,
    isAbsoluteUrl
} from '../misc/new-ouibus-helper'
import createReactClass from 'create-react-class'

const bookingFlowPaths = [
    '/orientation/offer',
    '/booking/passenger-details',
    '/booking/options',
    '/booking/customer-details',
    '/booking/payment'
]

export default withRouter(createReactClass({
    displayName: 'sessionExpiredDialog',

    mixins: [
        Reflux.connectFilter(BookingStore, 'booking', data => data.booking)
    ],

    propTypes: {
        router: PropTypes.object,
        location: PropTypes.object
    },

    onGoToReinitiateSearch (event) {
        event.preventDefault()
        this._proceedTo(newOuibusSearchUrl(true))
    },

    async _proceedTo (path) {
        await actions.logout()
        isAbsoluteUrl(path) ? window.location.href = path : this.props.router.push(path)
    },

    _isReinitiateSearchAvailable () {
        return AccessManager.isPublicUser() && bookingFlowPaths.some(path => this._isCurrentPath(path))
    },

    _isCurrentPath (path) {
        return path && this.props.location.pathname.indexOf(path) >= 0
    },

    render () {
        const reinitiate = this._isReinitiateSearchAvailable()
        const labelPrefix = `session-expired.${reinitiate ? 'reinitiate-search' : 'default'}`

        return (
            <Modal
                headerTitle={_t.getIntlMessage(`${labelPrefix}.title`)}
                headerIcon={<Icon type='chronometer' className='large align-left' />}
                name='session-expired'
            >
                <div className='content'>
                    <p>{_t.getIntlMessage(`${labelPrefix}.description`)}</p>
                    <div className='button-container'>
                        {reinitiate ? (
                            <Button
                                text={_t.getIntlMessage(`${labelPrefix}.reinitiate-search-link`)}
                                className='button primary next'
                                onClick={this.onGoToReinitiateSearch}
                            />
                        ) : null}
                        <div>
                            <a href='#' onClick={this.onGoToReinitiateSearch}>
                                {_t.getIntlMessage(`${labelPrefix}.homepage-link`)}
                            </a>
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }
}))
