import React, {Component} from 'react'
import PropTypes from 'prop-types'
import _t from '../../translate'
import BookingModel from '../../models/booking-model'
import TextLabel from '../../element/text-label'
import TriStateCheckboxField from '../../element/form/tri-state-checkbox-field'
import {CANCEL_OPTION_ENTIRE_BOOKING} from '../../constants'

export default class CancelEntireBooking extends Component {
    static propTypes = {
        onAction: PropTypes.func.isRequired,
        cancelItemIds: PropTypes.arrayOf(PropTypes.number).isRequired,
        booking: PropTypes.instanceOf(BookingModel),
        includeFees: PropTypes.bool,
        onSelectCancellationOption: PropTypes.func.isRequired,
        selectedCancellationOption: PropTypes.string
    }

    static defaultProps = {
        includeFees: false
    }

    constructor (...args) {
        super(...args)
        this.getCheckboxState = this.getCheckboxState.bind(this)
        this.onChange = this.onChange.bind(this)
    }

    getCheckboxState () {
        let itemIds = this.props.booking.products.filter('can_be_cancelled').map('item_ref').value()
        if (this.props.includeFees) {
            itemIds = itemIds.concat(this.props.booking.getFeesPaid().map('item_ref').value())
        }

        let state = -1
        if (itemIds.every(itemId => this.props.cancelItemIds.includes(itemId))) {
            state = 1
        }

        return state
    }

    onChange () {
        if (this.props.selectedCancellationOption !== CANCEL_OPTION_ENTIRE_BOOKING) {
            this.props.onSelectCancellationOption(CANCEL_OPTION_ENTIRE_BOOKING)
        }
    }

    render () {
        const selected = this.props.selectedCancellationOption === CANCEL_OPTION_ENTIRE_BOOKING

        return (
            <div className='callout cancel-entire-booking'>
                <span className='text-label heading'>
                    <input
                        type='radio'
                        checked={selected}
                        onChange={this.onChange}
                    />
                    <TextLabel text={_t.getIntlMessage('my-s3-cancellation.entire-booking.header')} />
                </span>
                {selected ? (
                    <div className='check-radio-wrapper'>
                        <TriStateCheckboxField
                            id='cancel-all'
                            state={this.getCheckboxState()}
                            onChange={e => this.props.onAction(e.target.checked, this.props.includeFees)}
                        />
                        <label htmlFor='cancel-all' className='text-label'>
                            {_t.getIntlMessage('my-s3-cancellation.entire-booking.label')}
                        </label>
                    </div>
                ) : null}
            </div>
        )
    }
}
