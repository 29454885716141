import PropTypes from 'prop-types'
import {withRouter} from 'react-router'
import React from 'react'
import Reflux from 'reflux'
import _t from '../../../translate'
import BookingModel from '../../../models/booking-model'
import TextLabel from '../../../element/text-label'
import Button from '../../../element/button'
import Checkbox from '../../../element/form/checkbox-field'
import Message from '../../../element/message'
import {containVouchers} from '../../../base/vouchers'
import AccessManager from '../../../data/access-manager'
import actions from '../../../reflux/actions'
import VoucherStore from '../../../reflux/stores/voucher-store'
import PaymentTerms from '../payment-container/payment-terms'
import {severity} from '../../../api/response-codes'
import {trimString} from '../../../misc/helpers'
import {getMessageVoucherEmailRestriction} from '../../../misc/vouchers'
import TextField from '../../../element/form/text-field'
import createReactClass from 'create-react-class'

const VoucherForm = createReactClass({

    listenables: actions,

    mixins: [
        Reflux.connectFilter(VoucherStore, 'crmVouchers', data => data.vouchers || []),
        Reflux.connectFilter(VoucherStore, 'errorText', data => data.errorText),
        Reflux.listenTo(actions.requestCrmVouchers.completed, 'onCompleteCrmVouchers'),
        Reflux.listenTo(actions.addCrmVoucher.completed, 'onCompleteAddCrmVoucher'),
        Reflux.listenTo(actions.addVoucher.completed, 'onCompleteAddVoucher')
    ],

    displayName: 'VoucherForm',

    propTypes: {
        disabled: PropTypes.bool.isRequired,
        loading: PropTypes.bool.isRequired,
        messages: PropTypes.array.isRequired,
        booking: PropTypes.instanceOf(BookingModel).isRequired,
        onAddVoucher: PropTypes.func.isRequired,
        onRemoveVoucher: PropTypes.func.isRequired,
        voucherAction: PropTypes.string,
        location: PropTypes.object
    },

    getInitialState () {
        return {
            voucherCode: null
        }
    },

    requestDetailedVouchers (vouchers) {
        vouchers
            .filter(voucher => !voucher.didRequestDetailInformation)
            .forEach(voucher => actions.requestCrmVoucher(voucher.code))
    },

    _onAddVoucher (event) {
        event.preventDefault()
        this.props.onAddVoucher(this.state.voucherCode)
    },

    _onUseCrmVoucher (voucherCode) {
        this.props.onAddVoucher(voucherCode)
        this.setState({
            voucherCode: null
        })
    },

    componentWillMount () {
        if (AccessManager.isCrmUser()) {
            actions.requestCrmVouchers()
        }
    },

    onCompleteAddCrmVoucher (vouchers) {
        this.requestDetailedVouchers(vouchers)
    },

    onCompleteCrmVouchers (vouchers) {
        this.requestDetailedVouchers(vouchers)
    },

    onCompleteAddVoucher (voucher) {
        if (
            AccessManager.isCrmUser() &&
            this.state.voucherCode !== null &&
            voucher.messages.length === 0
        ) {
            actions.addCrmVoucher(trimString(this.state.voucherCode).substring(0, 30))
            this.setState({
                voucherCode: null
            })
        }
    },

    _renderMessage (message, index) {
        return <Message
            key={index}
            message={getMessageVoucherEmailRestriction(this.props.location.pathname, message)}
        />
    },

    handleChangeVoucher (event) {
        this.setState({voucherCode: event.target.value.toUpperCase()})
    },

    render () {
        if (!this.props.booking.hasCustomer || !this.props.booking.hasPassengers) {
            return null
        }
        const {inboundTravelDate, outboundTravelDate} = this.props.booking
        const availableVouchers = AccessManager.isCrmUser()
            ? VoucherStore.getAvailableVouchers(outboundTravelDate, inboundTravelDate)
            : []
        return (
            <div className='voucher-form'>
                <form noValidate onSubmit={this._onAddVoucher}>
                    {this.props.messages.map(this._renderMessage)}
                    {this.state.errorText
                        ? <Message message={{content: this.state.errorText, severity: severity.warning}} />
                        : null}
                    <div className='voucher-input'>
                        <div className='grid-row'>
                            <div className='grid-column--1-1 grid-column--small-3-5'>
                                <div className='voucher-input-button'>
                                    <TextField
                                        ref='voucherCode'
                                        id='voucher-code'
                                        name='voucher-code'
                                        labelText='voucher.voucher-code-input-placeholder'
                                        floatingLabel
                                        className='voucher-code'
                                        disabled={this.props.disabled || this.props.loading}
                                        value={this.state.voucherCode || ''}
                                        onChange={this.handleChangeVoucher}
                                    />
                                    <Button
                                        ref='addButton'
                                        type='button'
                                        className='button default add-voucher'
                                        onClick={this._onAddVoucher}
                                        disabled={this.props.disabled || this.props.loading || !VoucherStore.isVoucherValid(this.state.voucherCode)}
                                        loading={this.props.loading && this.props.voucherAction === 'add'}
                                    >
                                        <TextLabel text={_t.getIntlMessage('voucher.button-add')} />
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                {AccessManager.isCrmUser() && availableVouchers.length > 0
                    ? <div className='voucher-tile-selector'>
                        <div className='grid-row'>
                            <div className='grid-column--1-1'>
                                <TextLabel className='voucher-title' text={_t.getIntlMessage('voucher.title')} />
                            </div>
                            {availableVouchers.map(voucher => this._renderVoucherTile(voucher.code))}
                        </div>
                        <PaymentTerms />
                    </div>
                    : null
                }
            </div>
        )
    },

    _renderVoucherTile (code) {
        const {booking, onRemoveVoucher} = this.props
        const vouchers = booking.vouchers
        const isVoucherActive = vouchers.length && vouchers.some(bookingVoucher => bookingVoucher.code === code)
        const isVoucherSuccessful = booking.isVoucherSuccessful(code)
        const text = isVoucherActive
            ? _t.getIntlMessage('voucher.not-use-voucher')
            : _t.getIntlMessage('voucher.use-voucher')
        const checkboxProps = {
            id: `voucher-toggle-${code}`,
            value: isVoucherActive,
            onClick: isVoucherActive
                ? () => onRemoveVoucher(code)
                : () => this._onUseCrmVoucher(code),
            disabled: isVoucherSuccessful,
            checked: isVoucherActive
        }

        return (
            <div className='grid-column--1-1 grid-column--medium-2-4' key={code}>
                <div className='voucher-tile'>
                    <div className='voucher-tile__content'>
                        <span className='text-label voucher-description'>
                            <TextLabel text={_t.getIntlMessage('voucher.voucher-description')} />
                        </span>
                        <span className='text-label voucher-amount'>
                            <TextLabel text={VoucherStore.getVoucherValueLabel(code)} />
                        </span>
                    </div>
                    <div className='voucher-tile__toggle'>
                        <Checkbox {...checkboxProps}>
                            <span className='text-label voucher-toggle'>
                                <TextLabel text={text} />
                            </span>
                        </Checkbox>
                    </div>
                </div>
            </div>
        )
    }
})

export default withRouter(containVouchers(VoucherForm))
