import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {withRouter} from 'react-router'
import {STORAGE_TARGET, STORAGE_INSURANCE_SELECTED} from '../../constants'
import device from '../../device'
import {restoreSelectedBundlesFromBooking} from '../../misc/journey-search'
import BookingLayoutContainer from '../booking-layout-container'
import ProgressNavigation from '../../components/options/progress-navigation'
import BookingStore from '../../reflux/stores/booking-store'
import UserStore from '../../reflux/stores/user-store'
import HelpButton from '../../base/help-button'
import {restoreJourneySearchCompose} from '../../components/restore/restore-journey-search'
import {restoreBookingCompose} from '../../components/restore/restore-booking'
import compose from '../../misc/compose'
import Information from '../../components/options/information'
import LuggageOptions from '../../components/options/luggage-options'
import SeatOptions from '../../components/options/seat-options'
import BikeOptions from '../../components/options/bike-options'
import Insurance from '../../components/insurance/insurance'
import TotalPrice from '../../components/options/total-price'
import _t from '../../translate'
import {
    fareInsuranceSelector,
    insuranceAvailableSelector,
    insuranceProductsBookingSelector
} from '../../models/selectors/components/booking/insurance'
import {
    connectState,
    getState
} from '../../reflux/bridge/connect-state'
import actions from '../../reflux/actions'
import storage from '../../storage'

import {totalPriceAdditionalProductsSelector} from '../../models/selectors/api/v2/booking/products'
import {totalPriceSelector} from '../../models/selectors/api/v2/booking/booking'
import ProductsStore from '../../reflux/stores/products-store'
import {loadAvailableSeatSelectorLegs} from '../../components/seat-selector/actions/seat-selector'
import {
    insuranceActiveForLocaleAndSalesChannel,
    showLuggageAddOn
} from '../../misc/blablabus-helpers'
import CarriageLayoutsStore from '../../reflux/stores/carriage-layouts-store'
import Loader from '../../element/loader'
import breadcrumbStore from '../../reflux/stores/breadcrumb-store'
import {
    hasBikePayingOptionsSelector,
    hasLuggagePayingOptionsSelector
} from '../../models/selectors/components/orientation/journey-search'
import {addBreadcrumb} from '@sentry/browser'

const deleteInsurance = async (bookingNumber, insuranceProductsBooking) => {
    if (insuranceProductsBooking && insuranceProductsBooking.length) {
        await actions.deleteItems(bookingNumber, {
            'item_refs': insuranceProductsBooking.map(product => product.itemRef)
        })
    }
}

const updateBooking = async (insuranceSelected, props) => {
    const booking = BookingStore.getBookingModel()
    const currency = booking.currency
    await deleteInsurance(booking.bookingNumber, props.insuranceProductsBooking)

    if (insuranceSelected) {
        let tariffSegment = booking && booking.outboundTariffSegments && booking.outboundTariffSegments.first()
        if (insuranceSelected && props.insuranceFare && tariffSegment) {
            const item = {
                passenger_id: props.insuranceFare.passengerId,
                tariff_code: props.insuranceFare.tariffCode
            }

            const segments = [{
                id: tariffSegment.id,
                origin: tariffSegment.departure_station._u_i_c_station_code,
                destination: tariffSegment.arrival_station._u_i_c_station_code,
                start_validity_date: tariffSegment.validity_start_date,
                direction: tariffSegment.direction,
                service_name: tariffSegment.validity_service,
                service_identifier: tariffSegment.service_identifier || `${tariffSegment.validity_service}|${tariffSegment.validity_start_date}`,
                items: [item]
            }]

            return actions.patchBooking(booking.bookingNumber, {currency, segments})
        }
    }
}

class Options extends Component {
    static propTypes = {
        router: PropTypes.object,
        hasManuallySelectedSeats: PropTypes.bool,
        hasProvisionalLuggage: PropTypes.bool,
        hasProvisionalBike: PropTypes.bool,
        insuranceFare: PropTypes.object,
        totalPrice: PropTypes.number,
        totalPriceAdditionalProducts: PropTypes.number,
        hasBooking: PropTypes.bool,
        numberOfPassengers: PropTypes.number,
        insuranceProductsBooking: PropTypes.array,
        bookingNumber: PropTypes.string
    }

    constructor (...args) {
        super(...args)
        this.state = {
            loading: true,
            insuranceSelected: storage.get(STORAGE_INSURANCE_SELECTED, null),
            availableSections: [],
            isDeeplink: false
        }
        this.onNext = this.onNext.bind(this)
        this.onPrevious = this.onPrevious.bind(this)
        this.handleChangeInsurance = this.handleChangeInsurance.bind(this)
        this._hasOptionSelected = this._hasOptionSelected.bind(this)
        this.selectedOptionsValue = this.selectedOptionsValue.bind(this)
    }

    async componentDidMount () {
        const target = storage.get(STORAGE_TARGET)
        const isDeeplink = target && target === 'options'
        storage.clear(STORAGE_TARGET)
        isDeeplink && await restoreSelectedBundlesFromBooking(BookingStore.getBookingModel())
        const availableSections = await this._setAvailability()
        const hasLuggagePayingOptions = showLuggageAddOn() && hasLuggagePayingOptionsSelector(getState())
        const hasBikePayingOptions = hasBikePayingOptionsSelector(getState())
        await deleteInsurance(this.props.bookingNumber, this.props.insuranceProductsBooking)
        const insuranceAvailable = insuranceAvailableSelector(getState()) &&
            insuranceActiveForLocaleAndSalesChannel(UserStore.getSalesChannel())

        this.setState({
            isDeeplink,
            loading: false,
            availableSections,
            hasLuggagePayingOptions,
            hasBikePayingOptions
        })

        availableSections.length || insuranceAvailable || hasLuggagePayingOptions || hasBikePayingOptions
            ? this.optionsAvailable()
            : this.noOptions()
    }

    optionsAvailable () {
        breadcrumbStore.resetData()
        this.setState({loading: false})
    }

    noOptions () {
        breadcrumbStore.removeOptionsRoute(`/${_t.getLocales()}/booking/options`)
        addBreadcrumb({message: 'No options found, redirecting to customer details page.'})
        this.props.router.replace(`/${_t.getLocales()}/booking/customer-details`)
    }

    onPrevious () {
        this.props.router.push(`/${_t.getLocales()}/booking/passenger-details`)
    }

    onNext () {
        this.setState({loading: true})
        if (!this._hasOptionSelected()) {
            actions.trackEvent('DeclineOption', {decline_insurance_clic: '$decline_options_clic'})
        }

        updateBooking(this.state.insuranceSelected, this.props)
            .then(() => this.props.router.push(`/${_t.getLocales()}/booking/customer-details`))
    }

    async _setAvailability () {
        const booking = BookingStore.getBookingModel()
        const numberOfPassengers = booking.passengers.size()
        const availableLegIds = await loadAvailableSeatSelectorLegs(numberOfPassengers)
        CarriageLayoutsStore.resetData()
        return availableLegIds
    }

    _hasOptionSelected () {
        return Boolean(this.state.insuranceSelected || this.props.hasManuallySelectedSeats || this.props.hasProvisionalLuggage || this.props.hasProvisionalBike)
    }

    handleChangeInsurance (value) {
        if (value) {
            actions.trackEvent(
                'InsuranceOption',
                {insurance_price: (this.props.insuranceFare && this.props.insuranceFare.price) || 0.0}
            )
        }
        this.setState({insuranceSelected: value}, () => storage.set(STORAGE_INSURANCE_SELECTED, value))
    }

    selectedOptionsValue () {
        const insuranceSelected = this.state.insuranceSelected
        const {insuranceFare, totalPriceAdditionalProducts} = this.props
        const insuranceFarePrice = insuranceSelected && insuranceFare ? insuranceFare.price : 0

        return insuranceFarePrice + totalPriceAdditionalProducts
    }

    render () {
        const progressNavigationProps = {
            onClickPrevious: this.onPrevious,
            onClickNext: this.onNext,
            nextLoading: this.state.loading,
            optionSelected: this._hasOptionSelected()
        }

        const insuranceSelected = this.state.insuranceSelected
        const {totalPrice, insuranceFare} = this.props

        if (this.state.loading) {
            return <Loader blocking />
        }

        return this.props.hasBooking ? (
            <BookingLayoutContainer
                title='booking-options'
                name='booking-options'
                hideBookingNumber
                insuranceSelected={this.state.insuranceSelected}
            >
                <ProgressNavigation
                    {...progressNavigationProps}
                    previousButtonEnabled={!device.isMobile() && !this.state.isDeeplink} />
                <Information />
                <SeatOptions
                    seatSelectorRoute='/booking/seat-selector'
                    availableSections={this.state.availableSections}
                />
                <LuggageOptions luggageSelectorRoute='/booking/luggage' />
                <BikeOptions bikeSelectorRoute='/booking/bike' />
                <Insurance
                    selected={insuranceSelected}
                    onChange={this.handleChangeInsurance}
                />
                <TotalPrice
                    selectedOptionsValue={this.selectedOptionsValue()}
                    numberOfPassengers={this.props.numberOfPassengers}
                    totalPrice={insuranceSelected && insuranceFare ? totalPrice + insuranceFare.price : totalPrice}
                />
                <ProgressNavigation
                    {...progressNavigationProps}
                    previousButtonEnabled={!this.state.isDeeplink} />
                {device.isMobile() ? <HelpButton /> : null}
            </BookingLayoutContainer>
        ) : null
    }
}

const mapPropsToProps = () => {
    const state = getState()
    const hasBooking = BookingStore.hasBooking()
    const hasManuallySelectedSeats = hasBooking && BookingStore.getBookingModel().hasManuallySelectedSeats
    const hasProvisionalLuggage = hasBooking && BookingStore.getBookingModel().tariffSegmentCollection.hasProvisionalLuggageProducts
    const hasProvisionalBike = hasBooking && BookingStore.getBookingModel().tariffSegmentCollection.hasProvisionalBikeProducts
    const booking = BookingStore.getBooking()

    return {
        insuranceFare: fareInsuranceSelector(state),
        insuranceProductsBooking: insuranceProductsBookingSelector(state),
        totalPrice: totalPriceSelector(state),
        hasBooking,
        hasManuallySelectedSeats,
        hasProvisionalLuggage,
        hasProvisionalBike,
        numberOfPassengers: booking.passengers.length,
        totalPriceAdditionalProducts: totalPriceAdditionalProductsSelector(state)
    }
}

const container = compose(
    restoreJourneySearchCompose({withBlockingLoader: true}),
    restoreBookingCompose({withBlockingLoader: true}),
    connectState(mapPropsToProps, [BookingStore, ProductsStore])
)

export default withRouter(container(Options))
