import Reflux from 'reflux'
import actions from '../../reflux/actions'
import _ from 'lodash'
import _t from '../../translate'
import ResponseCodes from '../../api/response-codes'
import UserAwareMixin from '../../reflux/mixin/user-aware-mixin'
import BookingModel from '../../models/booking-model'
import sessionStorage from '../../storage'
import GoogleTaggingStore from './google-tagging-store'

export default Reflux.createStore({

    listenables: actions,

    mixins: [UserAwareMixin],

    init () {
        this._resetData()

        this.listenTo(actions.clearBooking, this.resetData)

        this.listenTo(actions.createBooking, this._startLoading)
        this.listenTo(actions.cancelBooking, this._startLoading)
        this.listenTo(actions.cancelItems, this._startLoading)
        this.listenTo(actions.patchBooking, this._startLoading)
        this.listenTo(actions.updateSeats, this._startLoading)
        this.listenTo(actions.updatePassengers, this._startLoading)
        this.listenTo(actions.addCustomer, this._startLoading)
        this.listenTo(actions.updateCustomer, this._startLoading)
        this.listenTo(actions.initiatePayment, this._startLoading)
        this.listenTo(actions.processPayment, this._startLoading)
        this.listenTo(actions.confirmBooking, this._startLoading)
        this.listenTo(actions.getBooking, this._startLoading)
        this.listenTo(actions.myS3RebookCreateBooking, this._startLoading)
        this.listenTo(actions.addPayments, this._startLoading)
        this.listenTo(actions.updatePayments, this._startLoading)
        this.listenTo(actions.deleteItems, this._startLoading)
        this.listenTo(actions.revertBooking, this._startLoading)
        this.listenTo(actions.requestRefundVoucher, this._startLoading)
        this.listenTo(actions.requestRefundPsp, this._startLoading)

        this.listenTo(actions.getBooking.failed, this._setError)
        this.listenTo(actions.createBooking.failed, this._setError)
        this.listenTo(actions.cancelBooking.failed, this._setError)
        this.listenTo(actions.cancelItems.failed, this._setError)
        this.listenTo(actions.patchBooking.failed, this._setError)
        this.listenTo(actions.updateSeats.failed, this._setError)
        this.listenTo(actions.updatePassengers.failed, this._setError)
        this.listenTo(actions.updateCustomer.failed, this._setError)
        this.listenTo(actions.addCustomer.failed, this._setError)
        this.listenTo(actions.initiatePayment.failed, this._setError)
        this.listenTo(actions.processPayment.failed, this._setError)
        this.listenTo(actions.addVoucher.failed, this._setError)
        this.listenTo(actions.removeVoucher.failed, this._setError)
        this.listenTo(actions.confirmBooking.failed, this._setError)
        this.listenTo(actions.myS3RebookCreateBooking.failed, this._setError)
        this.listenTo(actions.deleteItems.failed, this._setError)
        this.listenTo(actions.revertBooking.failed, this._setError)
        this.listenTo(actions.requestRefundVoucher.failed, this._setError)
        this.listenTo(actions.requestRefundPsp.failed, this._setError)
    },

    _resetData () {
        this.data = {
            loading: false,
            success: true,
            messages: [],
            error: '',
            voucherAction: null,
            booking: this._getInitialBooking(),
            lastCall: null
        }
        this._booking = BookingModel.create(this.data.booking)
    },

    resetData () {
        this._resetData()
        this.trigger(this.data)
    },

    _getInitialBooking () {
        return {
            booking_number: null,
            fulfillment_methods: [],
            booking_fulfillment_method: null,
            inbound_booking_tariff_segments: [],
            outbound_booking_tariff_segments: [],
            passengers: [],
            payments: [],
            total_price: 0.00,
            total_vat: 0.00
        }
    },

    getInitialState () {
        return this.data
    },

    onGetBookingCompleted (response) {
        this._setData({booking: response.data.booking})
    },

    onAddPaymentsCompleted (response) {
        this._setData({booking: response.data.booking})
    },

    onDeleteItemsCompleted (response) {
        this._setData({booking: response.data.booking})
    },

    onUpdatePaymentsCompleted (response) {
        this._setData({booking: response.data.booking})
    },

    onCreateBookingCompleted (response) {
        this._setData({booking: response.data.booking, messages: response.messages, lastCall: 'createBooking'})
    },

    onCancelBookingCompleted () {
        this.resetData()
    },

    onCancelItemsCompleted (response) {
        this._setData({booking: response.data.booking, messages: response.messages, lastCall: 'cancelItems'})
    },

    onPatchBookingCompleted (response) {
        this._setData({booking: response.data.booking, messages: response.messages, lastCall: 'patchBooking'})
    },

    onProcessPaymentCompleted (response) {
        this._setData({
            booking: response.data.booking || response.data.completed_booking,
            lastCall: 'processPayment'
        })
    },

    onUpdateCustomerCompleted (response) {
        this._setData({booking: response.data.booking, lastCall: 'updateCustomer'})
    },

    onAddCustomerCompleted (response) {
        this._setData({booking: response.data.booking, lastCall: 'addCustomer'})
    },

    onUpdateSeatsCompleted (response) {
        this._setData({booking: response.data.booking, lastCall: 'updateSeats'})
    },

    onUpdatePassengersCompleted (response) {
        this._setData({booking: response.data.booking, lastCall: 'updatePassengers'})
    },

    onUpdateSelectBundleCompleted (response) {
        if (response.data.booking) {
            this._setData({booking: response.data.booking, lastCall: 'selectBundle'})
        }
    },

    onAddVoucher () {
        this.data.voucherAction = 'add'
        this._startLoading()
    },

    onAddVoucherCompleted (response) {
        this._setData({booking: response.data, messages: response.messages, lastCall: 'addVoucher'})
    },

    onRemoveVoucher () {
        this.data.voucherAction = 'remove'
        this._startLoading()
    },

    onRemoveVoucherCompleted (response) {
        this._setData({booking: response.data, messages: response.messages, lastCall: 'removeVoucher'})
    },

    onConfirmBookingCompleted (response) {
        this._setData({booking: response.data.booking, lastCall: 'confirmBooking'})
    },

    onMyS3RebookCreateBookingCompleted (response) {
        this._setData({booking: response.data.booking, lastCall: 'rebookBooking'})
    },

    onRevertBookingCompleted (response) {
        this._setData({booking: response.data.booking, lastCall: 'revertBooking'})
    },

    onRequestRefundVoucherCompleted (response) {
        this._setData({booking: response.data.booking, lastCall: 'requestRefundVoucher'})
    },

    onRequestRefundPspCompleted (response) {
        this._setData({booking: response.data.booking, lastCall: 'requestRefundPsp'})
    },

    onAddAdditionalDetailsCompleted (response) {
        this._setData({booking: response.data.booking, lastCall: 'addAdditionalDetails'})
    },

    onUpdateAdditionalDetailsCompleted (response) {
        this._setData({booking: response.data.booking, lastCall: 'updateAdditionalDetails'})
    },

    onMyS3GetInvoiceUrlCompleted (data) {
        if (this._booking && _.get(data, 'data.booking_number') === this._booking.bookingNumber) {
            this._booking = this._booking.setInvoiceUrl(_.get(data, 'data.invoice_url'))
            this.data.booking.invoice_url = _.get(data, 'data.invoice_url')
            this.trigger(this.data)
        }
    },

    onMyS3SaveBookingNoteCompleted (response) {
        if (this.data.booking instanceof BookingModel &&
            _.get(response, 'data.booking_number') === this.data.booking.bookingNumber
        ) {
            this._setData({booking: response.data, messages: response.messages})
        }
    },

    isLoading () {
        return this.data.loading
    },

    _startLoading () {
        this.data.loading = true
        this.data.error = ''
        this.data.success = true
        this.data.lastCall = null
        this.trigger(this.data)
    },

    _setData (data) {
        this.data.voucherAction = null
        this.data.loading = false
        this.data.lastCall = data.lastCall
        this.data.booking = data.booking || this._getInitialBooking()
        this.data.messages = data.messages ? data.messages.map(message => ({
            code: message.code,
            content: ResponseCodes.translate(message.code, message.content, message.context),
            severity: message.severity
        })) : []
        this._booking = BookingModel.create(this.data.booking)

        if (this._booking) {
            sessionStorage.set('bookingNumber', this._booking.bookingNumber)
        }

        this.trigger(this.data)
    },

    getStoredBookingNumber () {
        return sessionStorage.get('bookingNumber')
    },

    onApiError (error) {
        let shouldTrigger = false
        if (this.data.loading) {
            this.data.loading = false
            shouldTrigger = true
        }

        if (
            error.code === ResponseCodes.BOOKING_CONFIRM_PAYMENT_ITEM_INCONSISTENT ||
            error.code === ResponseCodes.BOOKING_VOUCHER_DOES_NOT_EXISTS ||
            error.code === ResponseCodes.BOOKING_VOUCHER_USED_BY_CUSTOMER ||
            error.code === ResponseCodes.BOOKING_VOUCHER_REFUND_NO_ELIGIBLE_TARIFF ||
            error.code === ResponseCodes.BOOKING_VOUCHER_COMPENSATION_NO_ELIGIBLE_TARIFF ||
            error.code === ResponseCodes.BOOKING_VOUCHER_PREPAID_NO_ELIGIBLE_TARIFF ||
            error.code === ResponseCodes.BOOKING_SEAT_NOT_AVAILABLE ||
            error.code === ResponseCodes.BOOKING_SEAT_CAN_NOT_BE_ALLOCATED
        ) {
            this.data.voucherAction = null
            this.data.messages = [{
                code: error.code,
                content: ResponseCodes.translate(error.code),
                severity: 'warning'
            }]
            error.handled = true
            shouldTrigger = true
        }

        if (error.code === ResponseCodes.BOOKING_VOUCHER_RESTRICTED) {
            this.data.voucherAction = null
            error.handled = true
        }

        if (error.code === ResponseCodes.BOOKING_NOT_FOUND ||
            error.code === ResponseCodes.BOOKING_NOT_FOUND_FOR_BOOKING_NUMBER
        ) {
            error.redirectUrl = `/${_t.getLocales()}/error/booking-not-found`
            error.handled = true
            shouldTrigger = true
        }

        if (error.code === ResponseCodes.BOOKING_FULFILLMENT_METHODS_MISSING ||
            error.code === ResponseCodes.BOOKING_CREATE_UNKNOWN_ERROR ||
            error.code === ResponseCodes.BOOKING_CREATE_NO_AVAILABLE_SEAT_COMBINATION ||
            error.code === ResponseCodes.BOOKING_CREATE_NO_LOGICAL_AVAILABILITY ||
            error.code === ResponseCodes.BOOKING_CREATE_INVALID_REQUEST_FOR_SERVICE ||
            error.code === ResponseCodes.BOOKING_CREATE_COULD_NOT_ALLOCATE_SEATS ||
            error.code === ResponseCodes.BOOKING_CREATE_NO_AVAILABLE_CARRIAGES ||
            error.code === ResponseCodes.BOOKING_CREATE_NO_LOGICAL_OR_PHYSICAL_AVAILABILITY ||
            error.code === ResponseCodes.BOOKING_IS_CANCELLED ||
            error.code === ResponseCodes.BOOKING_IS_EXPIRED ||
            error.code === ResponseCodes.BOOKING_NOT_FOUND
        ) {
            const bookingNumber = this.getBookingNumber()
            error.redirectUrl = bookingNumber
                ? `/${_t.getLocales()}/mys3/booking/${bookingNumber}`
                : `/${_t.getLocales()}/orientation/offer`
            error.handled = true
            shouldTrigger = true
        }

        GoogleTaggingStore.onBookingFailure(error.code)
        if (shouldTrigger) {
            this.trigger(this.data)
        }
    },

    _setError (error) {
        this.data.voucherAction = null
        this.data.loading = false
        this.data.success = false
        this.data.error = error
        this.trigger(this.data)
    },

    hasBooking () {
        return _.get(this.data, 'booking.booking_number', null) !== null
    },

    hasCustomer () {
        return _.get(this.data, 'booking.customer', null) !== null
    },

    hasCrmCustomerId () {
        return _.get(this.data.booking, 'customer.crm_customer_id', null) !== null
    },

    hasPassengers () {
        return _.get(this.data, 'booking.passengers', []).length > 0
    },

    getBooking () {
        return this.data.booking
    },

    getBookingModel () {
        return this._booking
    },

    getBookingNumber () {
        return _.get(this.data, 'booking.booking_number')
    },

    getVouchers () {
        return _.get(this.data, 'booking.vouchers', [])
    },

    getVoucherAction () {
        return _.get(this.data, 'voucherAction', null)
    },

    getMessages () {
        return _.get(this.data, 'messages', [])
    },

    getPayments () {
        return _.get(this.data, 'booking.payments', [])
    },

    isPaid () {
        return _.get(this.data, 'booking.total_price_to_be_paid') === 0
    },

    getTotalPriceToBePaid () {
        return _.get(this.data, 'booking.total_price_to_be_paid', 0)
    },

    requiresPaymentMethod () {
        return !this.isPaid() || (this.isPaid() && !this.getPayments().some(payment => payment.payment_status === 'P'))
    },

    hasAdditionalProducts () {
        let outbound = _(this.data.booking.outbound_booking_tariff_segments)
            .map(tariffSegment => tariffSegment.additional_products)
            .flatten()
            .size()

        return outbound > 0 ? true
            : _(this.data.booking.inbound_booking_tariff_segments)
                .map(tariffSegment => tariffSegment.additional_products)
                .flatten()
                .size() > 0
    },

    get lastCall () {
        return this.data.lastCall
    }
})
