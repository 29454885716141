/* globals S3P_SETTINGS: true */

// Libraries
import React from 'react'
import {Route} from 'react-router'

export default (
    <Route path='/:lang/agent-login' component={() => {
        window.location = S3P_SETTINGS.portal.uri
        return null
    }} />
)
