import React, {Component} from 'react'
import Container from '../base/container'
import deepLink from '../components/deep-link'
import Loader from '../element/loader'

class OrientationDeepLinkLayout extends Component {
    render () {
        return (
            <Container className='deepLink'>
                <Loader blocking />
            </Container>
        )
    }
}

export default deepLink(['booking', 'addon'])(OrientationDeepLinkLayout)
