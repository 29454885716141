import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from '../../../../translate'
import TextLabel from '../../../../element/text-label'
import Button from '../../../../element/button'
import ModalDialog from '../../../../element/modal-dialog'
import cmsContainer from '../../../cms/cms-container'

class FlexConditionsModal extends Component {
    static propTypes = {
        name: PropTypes.string,
        title: PropTypes.string,
        content: PropTypes.string,
        loading: PropTypes.bool.isRequired,
        onClose: PropTypes.func.isRequired
    }

    render () {
        return (
            <ModalDialog name='flex-bundle-conditions' headerTitle={this.props.title} onClose={this.props.onClose}>
                <div className='content'>
                    {!this.props.loading && this.props.content ? (
                        <div className='cms-block'>
                            <span dangerouslySetInnerHTML={{__html: this.props.content}} />
                        </div>
                    ) : null}
                    <Button type='button' className='button primary continue' onClick={this.props.onClose}>
                        <TextLabel
                            text={_t.message('journey-result-set.journey-details.conditions.close-modal-button')}
                        />
                    </Button>
                </div>
            </ModalDialog>
        )
    }
}

export default cmsContainer(FlexConditionsModal)
