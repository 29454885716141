import React from 'react'
import ReactDOMServer from 'react-dom/server'
import Button from '../element/button'
import TextLabel from '../element/text-label'
import Icon from '../element/icon'
import Loader from '../element/loader'

export default class PrintWindow {
    constructor (printButtonText) {
        this.printButtonText = printButtonText
    }

    open () {
        this.printWindow = window.open('', 'printWindow', 'location=no,width=640,height=480')
        if (this.printWindow) {
            this.printWindow.document.write(this._getContent())
            this.printWindow.document.close()
            this.printWindow.focus()
        }
    }

    _getContent () {
        return ReactDOMServer.renderToString(
            <html>
                <head>
                    <title>{document.title}</title>
                    {[].slice.call(document.styleSheets).map(styleSheet => (
                        <link key={styleSheet.href} rel='stylesheet' href={styleSheet.href} media='print, screen' />
                    ))}
                </head>
                <body>
                    <div id='content' className='print-refund-voucher'>
                        <Loader />
                    </div>
                </body>
            </html>
        )
    }

    _getPrintButton () {
        return ReactDOMServer.renderToString(
            <Button id='printButton' type='button' className='button default print-voucher'>
                <Icon type='print' className='small align-left' />
                <TextLabel text={this.printButtonText} />
            </Button>
        )
    }

    setContent (content) {
        if (!this.printWindow) {
            this.open()
        }

        if (this.printWindow) {
            this.printWindow.document.getElementById('content').innerHTML = content + this._getPrintButton()
            this.printWindow.document.getElementById('printButton').onclick = () => this.printWindow.print()
        }
    }
}
