import React, {Component} from 'react'
import _t from '../translate'

import {
    newOuibusSearchUrl,
    isAbsoluteUrl
} from '../misc/new-ouibus-helper'
import GenericLayout from '../layouts/generic-layout'
import Row from '../element/row'
import TextLabel from '../element/text-label'

export default class SystemUnavailableLayout extends Component {
    handleClick (e) {
        e.preventDefault()
        const ouibusUrl = newOuibusSearchUrl()
        isAbsoluteUrl(ouibusUrl)
            ? window.location.href = ouibusUrl
            : this.history.push(ouibusUrl)
    }

    render () {
        return (
            <GenericLayout className='system-unavailable-layout' showLanguageSelector={false}>
                <Row className='row contains-system-unavailable'>
                    <div className='grid-row'>
                        <div className='grid-column--1-1 grid-column--medium-2-3 center-grid-column--small'>
                            <div className='system-unavailable'>
                                <div className='panel'>
                                    <header>
                                        <span className='text-label heading'>
                                            <TextLabel text={_t.getIntlMessage('error-page.header')} />
                                        </span>
                                    </header>
                                    <div className='content'>
                                        <span className='text-label system-unavailable-content'>
                                            <TextLabel text={_t.getIntlMessage('error-page.content')} />
                                        </span>
                                        <a
                                            href='#'
                                            onClick={this.handleClick}
                                            className='button primary'
                                        >
                                            {_t.getIntlMessage('error-page.goto-homepage')}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Row>
            </GenericLayout>
        )
    }
}
