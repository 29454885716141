import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from '../../../../translate'
import Button from '../../../../element/button'
import TextLabel from '../../../../element/text-label'
import Icon from '../../../../element/icon'

export default class Notice extends Component {
    static propTypes = {
        dateOfBirthNotice: PropTypes.string,
        onNewSearch: PropTypes.func.isRequired
    }

    render () {
        const {dateOfBirthNotice} = this.props

        if (!dateOfBirthNotice) {
            return null
        }

        return (
            <div className='passenger-feedback'>
                <div className='feedback passenger'>
                    <span className='text-label feedback-title'>
                        <Icon className='warning medium align-left' type='warning' />
                        <TextLabel text={_t.getIntlMessage('passenger-feedback.header')} />
                    </span>
                    {this._renderDateOfBirthNotice(dateOfBirthNotice)}
                </div>
            </div>
        )
    }

    _renderDateOfBirthNotice (notice) {
        return notice ? [
            <span key='bod-label' className='text-label feedback-description'>
                <TextLabel text={_t.getIntlMessage(`passenger-feedback.date-of-birth.${notice}`)} />
            </span>,
            <Button key='bod-button' className='button secondary' type='button' onClick={this.props.onNewSearch}>
                <TextLabel text={_t.getIntlMessage('passenger-feedback.date-of-birth.button-text')} />
                <Icon className='chevron-right small align-right' type='chevron-right' />
            </Button>
        ] : null
    }
}
