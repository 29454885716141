import PropTypes from 'prop-types'
import React, {Component} from 'react'

export default class Main extends Component {
    static propTypes = {
        className: PropTypes.string
    }

    render () {
        return (
            <main className={this.props.className}>
                {this.props.children}
            </main>
        )
    }
}
