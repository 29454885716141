import PropTypes from 'prop-types'
import React, { Component } from 'react'
import get from 'lodash/get'
import device from '../device'
import BookingLayoutContainer from './booking-layout-container'
import BookingConfirmationDetailsContainer from '../components/booking-confirmation/details-container'
import BookingConfirmationInformation from '../components/booking-confirmation/information/information'
import BookingConfirmationTer from '../components/booking-confirmation/ter'
import BookingConfirmationReach5 from '../components/booking-confirmation/reach-5'
import HelpButton from '../base/help-button'
import cmsComponent from '../components/cms/cms-component'
import {
    SERVICE_TYPE_MODALITY_BUS,
    SERVICE_TYPE_MODALITY_TER,
    SERVICE_TYPE_NAME_ALSA,
    NON_OUIBUS_SERVICE_TYPE_NAMES,
    SERVICE_TYPE_NAME_TRANSALTITUDE,
    SERVICE_TYPE_NAME_NATIONAL_EXPRESS,
    SERVICE_TYPE_NAME_MARINO,
    SERVICE_TYPE_NAME_BUS_VERTS
} from '../constants'
import {
    BookingConfirmationAlsa,
    BookingConfirmationMarino,
    BookingConfirmationNationalExpress,
    BookingConfirmationTransaltitude,
    BookingConfirmationBusVerts
} from '../components/booking-confirmation/service-type'
import AccessManager from '../data/access-manager'
import actions from '../reflux/actions'
import {isPremiumPartner} from '../reflux/bridge/affiliate'
import Blablacar from '../components/booking-confirmation/blablacar'

class ConfirmationLayoutContainer extends Component {
    constructor (...args) {
        super(...args)

        this.state = {
            updatedCrmUser: false
        }
    }

    static propTypes = {
        cmsContentLoading: PropTypes.bool.isRequired,
        crmUser: PropTypes.object,
        booking: PropTypes.object,
        afterSalesAction: PropTypes.string,
        filter: PropTypes.string,
        isLoadingRequestVoucher: PropTypes.bool,
        handleRequestRefundVoucher: PropTypes.func,
        handlePrintRefundVoucher: PropTypes.func,
        handleCloseConfirmRefundVoucherDialog: PropTypes.func,
        shouldPrint: PropTypes.bool,
        refundVoucher: PropTypes.object
    }

    componentWillReceiveProps (nextProps) {
        const {crmUser, booking} = nextProps
        this._updateCrmUserName(crmUser, booking)
    }

    render () {
        const booking = this.props.booking
        const hasBooking = Boolean(booking && booking.bookingNumber)
        const isAfterSales = Boolean(this.props.afterSalesAction)

        const hasNELProduct = hasBooking && booking.hasNELProduct
        const hasALSAProduct = hasBooking && booking.hasALSAProduct
        const hasTer = hasBooking && booking.tariffSegmentCollection.hasSegmentsWithModality(
            SERVICE_TYPE_MODALITY_TER
        )
        const hasAlsa = this._hasBusModality(SERVICE_TYPE_NAME_ALSA)
        const hasTransaltitude = this._hasBusModality(SERVICE_TYPE_NAME_TRANSALTITUDE)
        const hasNEL = this._hasBusModality(SERVICE_TYPE_NAME_NATIONAL_EXPRESS)
        const hasMarino = this._hasBusModality(SERVICE_TYPE_NAME_MARINO)
        const hasBusVerts = this._hasBusModality(SERVICE_TYPE_NAME_BUS_VERTS)
        const hasOuibus = booking && booking.tariffSegmentCollection.hasSegmentsWithModality(
            SERVICE_TYPE_MODALITY_BUS,
            [],
            NON_OUIBUS_SERVICE_TYPE_NAMES
        )
        const showReach5 = hasBooking &&
            AccessManager.isPublicUser() &&
            !isPremiumPartner()

        return (
            <BookingLayoutContainer isAfterSales={isAfterSales} name='booking-confirmation-layout' title='booking-confirmation'>
                <BookingConfirmationDetailsContainer
                    urlStatus={get(this.props, 'location.query.s3_status')}
                    afterSalesAction={this.props.afterSalesAction}
                />
                <Blablacar />
                {showReach5 ? <BookingConfirmationReach5 /> : null }
                {hasBooking
                    ? (
                        <BookingConfirmationInformation
                            loading={this.props.cmsContentLoading}
                            hasNELProduct={hasNELProduct}
                            hasALSAProduct={hasALSAProduct}
                        />
                    ) : null}
                {hasTer ? <BookingConfirmationTer /> : null}
                {hasAlsa
                    ? <BookingConfirmationAlsa sprite='alsa' hasOuibus={hasOuibus} />
                    : null
                }
                {hasTransaltitude
                    ? <BookingConfirmationTransaltitude sprite='transaltitude' hasOuibus={hasOuibus} />
                    : null
                }
                {hasNEL
                    ? <BookingConfirmationNationalExpress sprite='nel' hasOuibus={hasOuibus} />
                    : null
                }
                {hasMarino
                    ? <BookingConfirmationMarino sprite='marino' hasOuibus={hasOuibus} />
                    : null
                }
                {hasBusVerts
                    ? <BookingConfirmationBusVerts sprite='bus-verts' hasOuibus={hasOuibus} />
                    : null
                }
                {device.isMobile() ? <HelpButton /> : null}
            </BookingLayoutContainer>
        )
    }

    _hasBusModality (serviceTypeName) {
        return this.props.booking && this.props.booking.tariffSegmentCollection.hasSegmentsWithModality(
            SERVICE_TYPE_MODALITY_BUS,
            [serviceTypeName]
        )
    }

    _updateCrmUserName (crmUser, booking) {
        if (
            AccessManager.isCrmUser() && booking && booking.customer && crmUser && !this.state.updatedCrmUser &&
            (
                (!crmUser.first_name && booking.customer.first_name) ||
                (!crmUser.last_name && booking.customer.last_name)
            )
        ) {
            const crmUserUpdate = {
                first_name: booking.customer.first_name,
                last_name: booking.customer.last_name,
                ...crmUser
            }
            actions.updateCrmUserInformation(crmUserUpdate)
            this.setState({updatedCrmUser: true})
        }
    }
}

const blockNames = [
    'boarding-information',
    'BOOKING-CONF-ASR',
    'BOOKING-CONF-SEAT',
    'BOOKING-CONF-SUB',
    'BOOKING-CONF-MORE',
    'BOOKING-CONF-STATIC',
    'BOOKING-CONF-STATIC_2',
    'AFTERSALES-REBOOK-CONF',
    'AFTERSALES-PASSENGERS-CONF',
    'AFTERSALES-LUGGAGE-CONF',
    'AFTERSALES-MOVIES-CONF',
    'AFTERSALES-CANCEL-CONF',
    'AFTERSALES-FLEX-VOUCHER-POPUP'
]

export default cmsComponent(...blockNames)(ConfirmationLayoutContainer)
