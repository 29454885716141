import PropTypes from 'prop-types'
import React, {Component} from 'react'
import {Link} from 'react-router'
import {getState} from '../../reflux/bridge/connect-state'
import _t from '../../translate'
import TextLabel from '../../element/text-label'
import Icon from '../../element/icon'
import get from 'lodash/get'
import {TRAVEL_DIRECTION_INBOUND, TRAVEL_DIRECTION_OUTBOUND} from '../../models/selectors/constants'
import {cmsBlockContainer} from '../cms/cms-block-container'
import {CmsContentTextLabel, CmsTitleTextLabel} from '../cms/cms-text-label'
import isEmpty from 'lodash/isEmpty'
import {provisionalBikeProductsPerPassengerBookingForDirectionSelector} from '../../models/selectors/components/booking/bike-selection'

const BikeCmsTitleTextLabel = cmsBlockContainer('additional-bike-summary', CmsTitleTextLabel)
const BikeCmsContentTextLabel = cmsBlockContainer('additional-bike-summary', CmsContentTextLabel)

class BikeSummary extends Component {
    static propTypes = {
        bikeSelectorRoute: PropTypes.string
    }

    render () {
        return (
            <div className='panel bike-selection'>
                <header>
                    <Icon type='bike' className='medium align-left' />
                    <span className='text-label heading'>
                        <BikeCmsTitleTextLabel />
                    </span>
                </header>
                <div className='grid-row'>
                    <div className='grid-column--1-1'>
                        <div className='text-label select-bike-summary'>
                            <BikeCmsContentTextLabel />
                        </div>
                    </div>
                </div>
                {this.renderTravel(TRAVEL_DIRECTION_OUTBOUND)}
                {this.renderTravel(TRAVEL_DIRECTION_INBOUND)}
                <div className='grid-row'>
                    <div className='grid-column--1-1 grid-column--medium-1-4 offset-grid-column--medium-3-4'>
                        <Link
                            className='bike-selection'
                            to={`/${_t.getLocales()}${this.props.bikeSelectorRoute}`}
                        >
                            <button className='button secondary open-bike-selector'>
                                <TextLabel text={_t.message('bike-selection.modify')} />
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        )
    }

    renderTravel = direction => {
        const passengersBike = provisionalBikeProductsPerPassengerBookingForDirectionSelector(direction)(getState())

        return !isEmpty(passengersBike) ? (
            <div className='bike-summary'>
                <div className={`passenger-selector`}>
                    <div className='summary'>
                        <span className='text-label summary-title'>
                            <TextLabel text={_t.getIntlMessage(`seat-selector.directions.${direction}`)} />
                            {direction === 'inbound'
                                ? <Icon className='fleche-left medium align-right' type='fleche-left' />
                                : <Icon className='fleche-right medium align-right' type='fleche-right' />
                            }
                        </span>
                    </div>
                    <div className='bike-summary-passenger-list'>
                        <ul>
                            {Object.values(passengersBike).map(this.renderPassenger)}
                        </ul>
                    </div>
                </div>
            </div>
        ) : null
    }

    renderPassenger = ({passenger, amount, totalPrice}) => {
        const firstName = get(passenger, 'firstName', '')
        const lastName = get(passenger, 'lastName', '')
        const text = `${firstName || ''} ${lastName || ''}`
        return (
            <li key={passenger.id} onClick={this.handleClickSelect}>
                <div className='bike-summary-passenger-list--item'>
                    <span className='text-label passenger-name'>
                        <TextLabel text={text} />
                    </span>
                    <span className='text-label bike-price'>
                        <TextLabel text={_t.formatIntlMessage('bike-selection.summary', {amount, price: _t.formatCurrency(totalPrice)})} />
                    </span>
                </div>
            </li>
        )
    }
}

export default BikeSummary
