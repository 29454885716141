import React from 'react'
import {Route} from 'react-router'
import authCheck from '../../mixins/auth'
import BookingPassengerDetailsLayout from '../../layouts/booking/passenger-details'

export default (
    <Route path='/:lang/booking/passenger-details'
           component={BookingPassengerDetailsLayout}
           onEnter={(nextState, replaceState, callback) => authCheck(nextState, replaceState, callback)}
    />
)
