import {selectedBundlesSelector} from './journey-search'
import {itemsSelector, routesSelector} from '../../api/v2/orientation/journey-search'
import {passengersSelector} from '../../api/v2/orientation/passenger'
import omitBy from 'lodash/omitBy'
import isUndefined from 'lodash/isUndefined'
import groupBy from 'lodash/groupBy'
import {snakeCaseKeys} from '../../../../misc/camelcase'
import {getState} from '../../../../reflux/bridge/connect-state'
import {selectedBundlesSelector as baseSelectedBundlesSelector} from '../../../../reflux/bridge/select-bundle'
import currencyStore from '../../../../reflux/stores/currency-store'
import {isBlank} from '../../../../misc/helpers'
import {affiliateCodeSelector} from '../../../../reflux/bridge/affiliate'
import {NEWSLETTER_OPTIN} from '../../../../constants'

const mapSelectedProductsForBooking = (products, items, routes) => products.reduce(
    (products, product) => {
        const item = items.get(product.itemId)
        const passengerFare = item.passengerFares.find(passengerFare => passengerFare.passengerId === product.passengerId)
        const route = routes.get(item.routeId)
        const firstLeg = route.legs.find(leg => leg.id === item.legIds[0])
        const lastLeg = route.legs.find(leg => leg.id === item.legIds[item.legIds.length - 1])

        const result = {
            origin: firstLeg.departureStation.UICStationCode,
            destination: lastLeg.arrivalStation.UICStationCode,
            serviceScheduleDate: firstLeg.serviceScheduleDate,
            direction: route.direction,
            passengerId: passengerFare.passengerId,
            tariffCode: passengerFare.tariffCode
        }
        if (item.legIds.length === 1) {
            result.serviceName = firstLeg.serviceName
            result.serviceIdentifier = firstLeg.serviceIdentifier
        }
        for (let i = 0; i < product.quantity; i++) {
            products.push(result)
        }

        return products
    },
    []
)

const prepareBooking = (products, passengers, extraData = {}) => {
    const groupedSegments = groupBy(
        products,
        product => `${product.origin}|${product.destination}|${product.direction}|${product.serviceIdentifier}`
    )

    return omitBy({
        segments: Object.values(groupedSegments).map(products => {
            const product = products[0]
            return omitBy({
                origin: product.origin,
                destination: product.destination,
                direction: product.direction,
                service_name: product.serviceName,
                service_identifier: product.serviceIdentifier,
                start_validity_date: product.serviceScheduleDate.format('YYYY-MM-DD'),
                items: products.map(item => ({
                    passenger_id: item.passengerId,
                    tariff_code: item.tariffCode
                }))
            }, isUndefined)
        }),
        passengers: passengers && passengers.map((passenger, index) => ({
            type: passenger.type,
            disability_type: passenger.disabilityType,
            discount_cards: passenger.discountCards,
            id: passenger.id || `passenger_${index + 1}`
        })),
        ...snakeCaseKeys(extraData)
    }, isBlank)
}

export const addRequiredProductsForBundle = bundle => bundle.items.reduce(
    (products, item) => {
        if (item.required) {
            products.push(...item.passengerFares.map(passengerFare => ({
                itemId: item.id,
                passengerId: passengerFare.passengerId,
                quantity: 1
            })))
        }

        return products
    },
    []
)

export const addAdditionalItemsForBundle = ({id: bundleId}) => state => {
    const selectedBundlesObject = baseSelectedBundlesSelector(state)
        .find(bundle => bundle.id === bundleId)
    const bundle = selectedBundlesSelector(state)
        .find(bundle => bundle.id === bundleId)

    if (selectedBundlesObject && bundle) {
        const additionalItems = selectedBundlesObject.items || []
        // find all additional products that are available for that passenger
        return additionalItems
            .filter(additionalItem => bundle.items
                .some(item => item.id === additionalItem.itemId &&
                    item.passengerFares.some(fare => fare.passengerId === additionalItem.passengerId)
                )
            )
    }

    return []
}

export const createBookingRequestPayload = () => {
    const state = getState()
    const selectedItems = selectedBundlesSelector(state).reduce((items, bundle) => {
        items.push(...addAdditionalItemsForBundle(bundle)(state))
        items.push(...addRequiredProductsForBundle(bundle))
        return items
    }, [])
    const passengers = passengersSelector(state)
    const items = itemsSelector(state)
    const routes = routesSelector(state)
    const products = mapSelectedProductsForBooking(selectedItems, items, routes)
    return prepareBooking(products, passengers, {
        affiliateCode: affiliateCodeSelector(state),
        currency: currencyStore.getCurrency(),
        additional_details: [{
            key: NEWSLETTER_OPTIN,
            value: '1'
        }]
    })
}
