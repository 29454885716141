import PropTypes from 'prop-types'
import React from 'react'
import _t from '../../../../translate'
import Button from '../../../../element/button'
import ModalDialog from '../../../../element/modal-dialog'
import Reflux from 'reflux'
import actions from '../../../../reflux/actions'
import JollicodeDiscountCardStore from '../../../../reflux/stores/jollicode-discount-card-store'
import {cancelProvisionalBookingAndCreateNew} from './cancel-booking'
import createReactClass from 'create-react-class'

export default createReactClass({
    displayName: 'InvalidDiscountCardModal',

    propTypes: {
        name: PropTypes.string,
        title: PropTypes.string
    },

    mixins: [
        Reflux.listenTo(actions.checkDiscountCard.failed, 'checkIfCardEnabledOrExpired'),
        Reflux.listenTo(actions.validatePassengerFormDiscountCards.completed, 'checkIfCardEnabledOrExpired'),
        Reflux.listenTo(actions.createBooking.completed, 'closeModal')
    ],

    getInitialState () {
        return {
            shouldRender: false,
            isLoading: false
        }
    },

    checkIfCardEnabledOrExpired () {
        if (JollicodeDiscountCardStore.isCardExpired() || JollicodeDiscountCardStore.isCardDisabled()) {
            return this.setState({shouldRender: true})
        }
    },

    closeModal () {
        return this.setState({shouldRender: false, isLoading: false})
    },

    handleCloseModal () {
        if (this.state.isLoading) {
            return
        }

        this.closeModal()
    },

    handleClick () {
        this.setState({isLoading: true}, cancelProvisionalBookingAndCreateNew)
    },

    render () {
        const notice = JollicodeDiscountCardStore.isCardExpired() ? 'expired' : 'inactive'
        return this.state.shouldRender ? (
            <ModalDialog name='discount-card-modal' headerTitle={_t.formatIntlMessage(`invalid-discount-card-modal.${notice}.header`)} onClose={this.handleCloseModal}>
                <div className='content'>
                    <h2>{_t.formatIntlMessage(`invalid-discount-card-modal.${notice}.title`)}</h2>
                    <p>{_t.formatIntlMessage(`invalid-discount-card-modal.${notice}.description`)}</p>
                    <Button text={_t.formatIntlMessage(`invalid-discount-card-modal.${notice}.button`)} className='button primary next' disabled={this.state.isLoading} onClick={this.handleClick} />
                </div>
            </ModalDialog>
        ) : null
    }
})
