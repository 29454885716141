import React, {Component} from 'react'
import PropTypes from 'prop-types'
import _t from '../../../../translate'
import TextLabel from '../../../../element/text-label'

export default class Agglomeration extends Component {
    static propTypes = {
        type: PropTypes.string.isRequired,
        station: PropTypes.shape({
            name: PropTypes.string.isRequired
        })
    }

    render () {
        return (
            <div className='journey-result-option-item agglomeration'>
                <span className='text-label agglomeration-description'>
                    <TextLabel text={_t.getIntlMessage(`journey-result-set.agglomeration.${this.props.type}`)} />
                </span>
                <span className='text-label agglomeration-value'>
                    <TextLabel text={this.props.station.name} />
                </span>
            </div>
        )
    }
}
