import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from '../translate'
import moment from 'moment-timezone'
import BookingModel from '../models/booking-model'
import Icon from '../element/icon'
import TextLabel from '../element/text-label'

export default class AfterSalesSummary extends Component {
    static propTypes = {
        booking: PropTypes.instanceOf(BookingModel)
    }

    render () {
        return this.props.booking ? (
            <div className='panel'>
                <header>
                    <h1>{_t.message('after-sales-summary.header')}</h1>
                </header>
                <div className='content'>
                    <div className='after-sales-summary'>
                        <div className='after-sales-information'>
                            {this._getOutboundContentBlock()}
                            {this._getInboundContentBlock()}
                            {this._getPassengersContentBlock()}
                        </div>
                    </div>
                </div>
            </div>
        ) : null
    }

    _getOutboundContentBlock () {
        const {booking} = this.props
        if (!booking || booking.isOutboundCancelled() || !booking.outboundOrigin) {
            return null
        }

        return this._getTravelContentBlock('outbound', {
            origin: booking.outboundOrigin.name,
            destination: booking.outboundDestination.name,
            date: booking.outboundTravelDate,
            originTimezone: booking.outboundOrigin.timezone,
            destinationTimezone: booking.outboundDestination.timezone,
            enabled: true
        })
    }

    _getInboundContentBlock () {
        const {booking} = this.props
        if (!booking || !booking.hasInboundSegments() || booking.isInboundCancelled() || !booking.inboundOrigin) {
            return null
        }

        return this._getTravelContentBlock('inbound', {
            origin: booking.inboundOrigin.name,
            destination: booking.inboundDestination.name,
            date: booking.inboundTravelDate,
            originTimezone: booking.inboundOrigin.timezone,
            destinationTimezone: booking.inboundDestination.timezone,
            enabled: true
        })
    }

    _getTravelContentBlock (type, travel) {
        const iconType = 'half-arrow-' + (type === 'outbound' ? 'right' : 'left')
        const travelDate = moment.tz.zone(travel.originTimezone)
            ? moment(travel.date).tz(travel.originTimezone)
            : moment(travel.date)

        return (
            <div className={type}>
                <span className='text-label title-label'>
                    <Icon type={iconType} className='medium align-left' />
                    <span className='text'>{_t.getIntlMessage('after-sales-summary.' + type)}</span>
                </span>
                <span className={`text-label ${type}-origin`}>
                    <TextLabel text={_t.formatIntlMessage(
                        'after-sales-summary.origin-to-destination-text-label',
                        travel
                    )} />
                </span>
                <span className={`text-label ${type}-date`}>
                    <TextLabel text={travelDate.format('dddd, LL')} />
                </span>
            </div>
        )
    }

    _getPassengersContentBlock () {
        return (
            <div className='passengers'>
                <span className='text-label title-label'>
                    <Icon type='passengers' className='medium align-left' />
                    <TextLabel text={_t.formatIntlMessage('after-sales-summary.passengers')} />
                </span>
                <span className='text-label passengers-value'>
                    <TextLabel
                        text={_t.formatIntlMessage(
                            'after-sales-summary.number-of-passengers', {
                                totalPassengerCount: this.props.booking.passengersWithProvisionalCancelled.size()
                            })}
                    />
                </span>
            </div>
        )
    }
}
