import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Row from '../element/row'
import BookingSpecificationPanel from '../components/booking-specification/booking-panel'
import GenericLayout from './generic-layout'
import bookingLayout from './booking-layout'
import BookingModel from '../models/booking-model'
import TextLabel from '../element/text-label'
import device from '../device'
import _t from '../translate'

class BookingLayoutContainer extends Component {
    static propTypes = {
        name: PropTypes.string.isRequired,
        isAfterSales: PropTypes.bool,
        booking: PropTypes.instanceOf(BookingModel),
        hideBookingNumber: PropTypes.bool,
        insuranceSelected: PropTypes.bool,
        title: PropTypes.string
    }

    static defaultProps = {isAfterSales: false}

    constructor (...args) {
        super(...args)
        this._renderMobileTitle = this._renderMobileTitle.bind(this)
    }

    render () {
        return (
            <GenericLayout className={this.props.name} showSalesHeader>
                <Row className={`row contains-${this.props.name}`}>
                    <div className='grid-row'>
                        {this._renderMobileTitle()}
                        <div className='grid-column--1-1 grid-column--medium-1-3'>
                            {this.props.booking ? (
                                <BookingSpecificationPanel
                                    booking={this.props.booking}
                                    afterSales={this.props.isAfterSales}
                                    hideBookingNumber={this.props.hideBookingNumber}
                                    insuranceSelected={this.props.insuranceSelected}
                                />
                            ) : null}
                        </div>
                        <div className='grid-column--1-1 grid-column--medium-2-3'>
                            {this.props.children}
                        </div>
                    </div>
                </Row>
            </GenericLayout>
        )
    }

    _renderMobileTitle () {
        return device.isMobile() && this.props.title
            ? (
                <div className='grid-column--1-1'>
                    <div className='mobile-title'>
                        <h1 className='text-label mobile-title-text'>
                            <TextLabel text={_t.getIntlMessage(`progress-bar.${this.props.title}`)} />
                        </h1>
                    </div>
                </div>
            ) : null
    }
}

export default bookingLayout(BookingLayoutContainer)
