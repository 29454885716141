// Libraries
import React from 'react'
import PropTypes from 'prop-types'
import Reflux from 'reflux'
import {withRouter} from 'react-router'
import classNames from 'classnames'
import createReactClass from 'create-react-class'

import _t from '../translate'
import client from '../api/client'

// Components
import Container from '../base/container'
import Header from '../base/header'
import Main from '../base/main'
import Row from '../element/row'
import TextLabel from '../element/text-label'
import LegalItem from './generic-layout/footer/legal-item'

import UserNavigation from '../base/header/user-navigation'
import ErrorStore from '../reflux/stores/error-store'
import {
    newOuibusSearchUrl,
    isAbsoluteUrl
} from '../misc/new-ouibus-helper'
import {getUrl} from '../misc/helpers'
import cmsComponent from '../components/cms/cms-component'
import {isBlablabusDomain} from '../misc/blablabus-helpers'

const cmsBlockNames = [
    'terms-conditions',
    'legal-notices',
    'confidentiality-charter'
]

export default withRouter(cmsComponent(...cmsBlockNames)(createReactClass({

    displayName: 'ErrorLayout',

    mixins: [
        Reflux.listenTo(ErrorStore, 'onErrorUpdated')
    ],

    propTypes: {
        router: PropTypes.object,
        logoUrl: PropTypes.string
    },

    onErrorUpdated (data) {
        this.setState({
            error: data
        })
    },

    getInitialState () {
        return {
            error: ErrorStore.getError()
        }
    },

    getDefaultProps () {
        return {
            showLanguageSelector: true,
            logoUrl: getUrl()
        }
    },

    handleClick (e) {
        e.preventDefault()
        client.resetErrorRedirect()
        const ouibusUrl = newOuibusSearchUrl()
        isAbsoluteUrl(ouibusUrl)
            ? window.location.href = ouibusUrl
            : this.props.router.push(ouibusUrl)
    },

    render () {
        const errorCode = 'code' in this.state.error ? this.state.error.code : null
        const className = classNames('link', 'header-logo', isBlablabusDomain() ? 'blablabus' : '')
        const navRowClassName = classNames('row', 'contains-header')

        return (
            <Container className='container error-page'>
                <Header>
                    <Row className={navRowClassName}>
                        <div className='grid-row'>
                            <div className='grid-column--1-1'>
                                <div className='language-selector-meta-placeholder'>
                                    <UserNavigation />
                                </div>
                            </div>
                        </div>
                    </Row>
                    <Row className='row contains-marketing-header'>
                        <div className='grid-row'>
                            <div className='grid-column--1-1'>
                                <a href={this.props.logoUrl} className={className}>
                                    <span className='text-label'>
                                        <TextLabel text={_t.getIntlMessage('header.logo.label')} />
                                    </span>
                                </a>
                                <span className='text-label header-title'>
                                    <TextLabel text={_t.getIntlMessage('header.title')} />
                                </span>
                            </div>
                        </div>
                    </Row>
                </Header>
                <Main>
                    <Row className='row contains-error-page'>
                        <div className='grid-row'>
                            <div className='grid-column--1-1 grid-column--medium-1-2 center-grid-column--small'>
                                <div className='error-page'>
                                    <div className='panel'>
                                        <header>
                                            <span className='text-label heading'>
                                                <TextLabel text={_t.getIntlMessage('internal-error-page.header')} />
                                            </span>
                                        </header>
                                        <div className='content'>
                                            <span className='text-label error-page-content'>
                                                <TextLabel text={_t.getIntlMessage('internal-error-page.content')} />
                                                {errorCode
                                                    ? <TextLabel className='error-page-code' text={`(#${errorCode})`} />
                                                    : null}
                                            </span>
                                            <a
                                                href='#'
                                                onClick={this.handleClick}
                                                className='button primary'
                                            >
                                                {_t.getIntlMessage('internal-error-page.goto-homepage')}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Row>
                </Main>
                <footer className='main'>
                    <div className='content'>
                        <Row className='row contains-footer'>
                            <div className='grid-column--1-1'>
                                <div className='legal'>
                                    <ul className='horizontal-list'>
                                        <LegalItem
                                            key='general-terms'
                                            cmsBlockName='terms-conditions'
                                        />
                                        <LegalItem
                                            key='legal-information'
                                            cmsBlockName='legal-notices'
                                        />
                                        <LegalItem
                                            key='privacy-policy'
                                            cmsBlockName='confidentiality-charter'
                                        />
                                    </ul>
                                </div>
                            </div>
                        </Row>
                    </div>
                </footer>
            </Container>
        )
    }

})))
