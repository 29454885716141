import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from '../../../translate'
import PersonalInformationSummary from './personal-information-summary'
import PassengerDetailsSummary from '../../booking/passenger-details/customer/passenger-details-summary'
import BookingModel from '../../../models/booking-model'
import WheelchairContainer from '../../orientation-booking-preferences/wheelchair-container'

export default class RebookingDetails extends Component {
    static propTypes = {
        booking: PropTypes.instanceOf(BookingModel).isRequired
    }

    render () {
        if (!this.props.booking) {
            return null
        }

        return (
            <div className='row'>
                <div className='personal-information'>
                    <div className='panel'>
                        <header><h2>{_t.getIntlMessage('personal-information-form.header')}</h2></header>
                        <PersonalInformationSummary {...this._getCustomerData()} />
                    </div>
                </div>
                <div className='passenger-details'>
                    <div className='panel'>
                        <WheelchairContainer />
                        {this._renderPassengers()}
                    </div>
                </div>
            </div>
        )
    }

    _renderPassengers () {
        return this._getPassengers().map((passenger, index) => (
            <PassengerDetailsSummary
                key={`passenger-details-${index}`}
                passengers={[passenger]}
                title={_t.formatIntlMessage('passenger-details.passenger', {
                    number: index + 1,
                    type: _t.message(`passenger-types.${passenger.passengerType.toLowerCase()}`),
                    passengerType: passenger.passengerType
                })}
            />
        ))
    }

    _getCustomerData () {
        return {
            gender: this.props.booking.customer.gender,
            firstName: this.props.booking.customer.first_name,
            lastName: this.props.booking.customer.last_name,
            emailAddress: this.props.booking.customer.email,
            phone: this.props.booking.customer.phone_number,
            postalCode: this.props.booking.customer.address && this.props.booking.customer.address.postal_code,
            country: this.props.booking.customer.address && this.props.booking.customer.address.country_code
        }
    }

    _getPassengers () {
        return this.props.booking.passengers.values().value().map(passenger => {
            return {
                id: passenger.id,
                type: passenger.type,
                passengerType: passenger.passengerType,
                firstName: passenger.first_name,
                lastName: passenger.last_name,
                dateOfBirth: passenger.birth_date
            }
        })
    }
}
