import React from 'react'
import Reflux from 'reflux'
import BookingStore from '../../reflux/stores/booking-store'
import CrmUserStore from '../../reflux/stores/crm-user-store'
import ConfirmationLayoutContainer from '../confirmation-layout-container'
import restoreBooking from '../../components/restore/restore-booking'
import storage from '../../storage'
import {STORAGE_INSURANCE_SELECTED} from '../../constants'
import createReactClass from 'create-react-class'

export default restoreBooking(createReactClass({

    displayName: 'BookingConfirmationLayout',

    mixins: [
        Reflux.connectFilter(BookingStore, 'booking', _ => BookingStore.getBookingModel()),
        Reflux.connectFilter(CrmUserStore, 'crmUser', data => data.user && data.user.customer)
    ],

    componentDidMount () {
        storage.clear(STORAGE_INSURANCE_SELECTED)
    },

    render () {
        return <ConfirmationLayoutContainer {...this.props} booking={this.state.booking} crmUser={this.state.crmUser} />
    }
}))
