import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from '../../../../translate'
import TextLabel from '../../../../element/text-label'
import Icon from '../../../../element/icon'
import Loader from '../../../../element/loader'

class ManageVoucherPanel extends Component {
    static propTypes = {
        loading: PropTypes.bool.isRequired,
        voucher: PropTypes.shape({
            code: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired,
            description: PropTypes.string.isRequired,
            amount: PropTypes.number.isRequired
        }).isRequired,
        onRemoveVoucher: PropTypes.func.isRequired
    }

    constructor (...args) {
        super(...args)
        this.onRemoveVoucher = this.onRemoveVoucher.bind(this)
    }

    onRemoveVoucher (event) {
        event.preventDefault()
        this.props.onRemoveVoucher()
        return false
    }

    render () {
        return (
            <div className='manage-voucher panel'>
                <div className='added-voucher'>
                    <div className='grid-row no-gutter'>
                        <div className='grid-column--3-4'>
                            <TextLabel
                                text={_t.getIntlMessage('booking-specification.manage-vouchers.discount')}
                                className='voucher-description'
                            />
                        </div>
                        <div className='grid-column--1-4'>
                            <div className='text-label voucher-value'>
                                <TextLabel text={`- ${_t.formatCurrency(this.props.voucher.amount)}`} />
                            </div>
                        </div>
                    </div>
                </div>
                {this.props.loading ? <Loader /> : (
                    <div className='remove-voucher-container'>
                        <a href='#' className='remove-voucher' onClick={this.onRemoveVoucher}>
                            <Icon type='cross-circle' className='xsmall align-left' />
                            <TextLabel
                                text={_t.getIntlMessage('booking-specification.manage-vouchers.remove-voucher')} />
                        </a>
                    </div>
                )}
            </div>
        )
    }
}

export default ManageVoucherPanel
