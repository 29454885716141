import PropTypes from 'prop-types'
import React, { Component } from 'react'
import cmsContainer from '../cms/cms-container'
import Heading from '../../element/heading'

class InformationTitle extends Component {
    static propTypes = {
        title: PropTypes.string
    }

    render () {
        return this.props.title ? (
            <Heading title={this.props.title} />
        ) : null
    }
}

export default cmsContainer(InformationTitle)
