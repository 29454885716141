/* globals S3P_SETTINGS, XMLHttpRequest */
import moment from 'moment-timezone'
import Storage from '../storage'
import {STORAGE_TRACKTOR} from '../constants'

const _buildRequestBody = (eventName, payload, retry) => {
    const {sessionStamp, visitorId, deviceId} = getTracktorIdentifiers()
    const time = moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ')

    const [language] = window.location.pathname.split('/').splice(1)

    const events = [
        {
            name: eventName.name,
            version: eventName.version,
            created_at_client_local: time,
            payload
        }
    ]

    return JSON.stringify({
        jsonrpc: '2.0',
        method: 'push',
        params: {
            session_stamp: sessionStamp,
            user_agent: `${navigator.userAgent} tklib/ ouibus_wl/1`,
            user_id: null,
            visitor_id: visitorId,
            device_id: deviceId,
            loc: language,
            sent_at_client_local: time,
            retry,
            events: events
        }
    })
}

export const postDataWithRetry = (eventName, payload, retry = 0) => {
    const request = new XMLHttpRequest()
    request.open('POST', S3P_SETTINGS.tracktorUrl)
    request.addEventListener('error', () => {
        if (retry < 5) {
            postDataWithRetry(eventName, payload, retry + 1)
        }
    })
    hasTracktorIdentifiers() && request.send(_buildRequestBody(eventName, payload, retry))
}

/**
 * Store tracktor params, only when all params are sent.
 * @param visitorId
 * @param deviceId
 * @param sessionStamp
 */
export const storeTracktorIdentifiers = (visitorId, deviceId, sessionStamp) => {
    Storage.clear(STORAGE_TRACKTOR)
    Storage.set(
        STORAGE_TRACKTOR,
        {
            visitorId: visitorId || null,
            deviceId: deviceId || 'null',
            sessionStamp: sessionStamp || -1
        }
    )
}

const getTracktorIdentifiers = () => {
    const {sessionStamp, ...values} = Storage.get(STORAGE_TRACKTOR)
    values.sessionStamp = parseInt(sessionStamp)

    return values
}

const hasTracktorIdentifiers = () => Storage.has(STORAGE_TRACKTOR)
