// Libraries
import PropTypes from 'prop-types'

import React from 'react'
import Reflux from 'reflux'
import _t from '../../../translate'
import createReactClass from 'create-react-class'

// Components
import CustomerDetailsForm from './form'
import TextLabel from '../../../element/text-label'
import CmsBlockContent from '../../cms/cms-block-content'
import Radio from '../../../element/form/radio-field'
import CrmUserStore from '../../../reflux/stores/crm-user-store'
import AccessManager from '../../../data/access-manager'
import CountryCollection from '../../../models/country-collection'
import {
    CUSTOMER_TYPE_PAY_ONLY,
    PASSENGER_TYPE_ADULT,
    PASSENGER_TYPE_YOUTH
} from '../../../constants'

// Actions
import actions from '../../../reflux/actions'
import customerInfoStore from '../../../reflux/stores/customer-info-store'

const PropTypeField = PropTypes.shape({
    value: PropTypes.string.isRequired,
    errorText: PropTypes.string,
    isRequired: PropTypes.bool.isRequired
})

export default createReactClass({

    displayName: 'PersonalInformationContent',

    mixins: [
        Reflux.connectFilter(CrmUserStore, 'crmUser', data => data.user && data.user.customer),
        Reflux.listenTo(CrmUserStore, 'onUpdateCrmUser')
    ],

    propTypes: {
        onContinue: PropTypes.func.isRequired,
        formData: PropTypes.shape({
            isValid: PropTypes.bool.isRequired,
            errorText: PropTypes.string,
            fields: PropTypes.shape({
                emailAddress: PropTypeField.isRequired,
                phone: PropTypeField.isRequired,
                gender: PropTypes.object,
                firstName: PropTypes.object,
                lastName: PropTypes.object
            }).isRequired
        }).isRequired,
        passengers: PropTypes.array,
        clickButton: PropTypes.func,
        additionalDetails: PropTypes.array.isRequired,
        customer: PropTypes.object
    },

    onUpdateCrmUser () {
        // Re-validate email. Reach5 might have an unsupported email address (gmail).
        actions.processCustomerInfoData([{id: 'emailAddress', value: this.state.crmUser.email}])
    },

    componentWillMount () {
        if (AccessManager.isCrmUser() && !CrmUserStore.hasUserInformation()) {
            actions.loadCrmUserInformation()
        }

        const {customer, clickButton} = this.props
        if (customer) {
            const {
                email,
                gender,
                address,
                first_name: firstName,
                last_name: lastName,
                phone_number: _phoneNumber
            } = customer

            const selectedCustomer = this._getPossibleCustomers()
                .find(passenger => passenger.first_name === firstName && passenger.last_name === lastName)
            this.setState({selectedPassengerId: selectedCustomer ? selectedCustomer.id : CUSTOMER_TYPE_PAY_ONLY})
            const infoData = [
                {id: 'gender', value: gender || null},
                {id: 'firstName', value: firstName},
                {id: 'lastName', value: lastName},
                {id: 'emailAddress', value: email},
                {id: 'repeatEmailAddress', value: email}
            ]
            if (_phoneNumber) {
                const {phoneNumber, countryCodePhone} = customerInfoStore.parsePhoneNumber(_phoneNumber)
                infoData.push({id: 'phone', value: phoneNumber})
                countryCodePhone && infoData.push({id: 'countryCode', value: countryCodePhone})
            }
            if (!infoData.find(record => record.id === 'countryCode') && address && address.country_code) {
                infoData.push({id: 'countryCode', value: address.country_code})
            }
            actions.processCustomerInfoData(infoData)
        }

        clickButton(this.onButtonClick)
    },

    componentDidMount () {
        const {crmUser} = this.state
        if (crmUser !== null) {
            const {email, addresses, phone_numbers: phoneNumbers} = crmUser
            const data = [{id: 'emailAddress', value: email}]
            if (addresses && addresses.length && addresses[0].country_code) {
                const countries = CountryCollection.create().asOptionListWithPriorities
                const country = countries.find(country => country.value === addresses[0].country_code)
                data.push({id: 'countryCode', value: country.value})
            }
            if (phoneNumbers && phoneNumbers.length) {
                data.push({id: 'phone', value: phoneNumbers[0].value})
            }

            if (data.length) {
                actions.processCustomerInfoData(data)
            }
        }
    },

    autoFillEmail (checked) {
        const emailAddress = checked ? 'station@ouibus.com' : ''
        actions.processCustomerInfoData([{id: 'emailAddress', value: emailAddress}])
    },

    _emailIsAutoFilled () {
        return this.props.formData.fields.emailAddress.value === 'station@ouibus.com'
    },

    render () {
        const {formData, additionalDetails} = this.props
        const {selectedPassengerId, crmUser} = this.state
        return (
            this._crmFullNameNotSet()
                ? (
                    <div className='content'>
                        <span className='text-label information' id={selectedPassengerId ? null : 'sms-alert'}>
                            <CmsBlockContent name='BOOKING-CONTACT' className='cms-block-inline' />
                        </span>
                        {this._renderSelectCustomer()}
                        {selectedPassengerId
                            ? (
                                <div className='contains-customer-form'>
                                    <CustomerDetailsForm
                                        crmFullNameNotSet={this._crmFullNameNotSet()}
                                        crmEmailNotSet={this._crmEmailNotSet()}
                                        formData={formData}
                                        crmUser={crmUser}
                                        selectedPassengerId={selectedPassengerId}
                                        additionalDetails={additionalDetails}
                                    />
                                    <TextLabel
                                        className='text-label required-notification'
                                        text={_t.getIntlMessage('form.required-notification')}
                                    />
                                </div>
                            )
                            : null}
                    </div>
                )
                : (
                    <div className='content'>
                        <span className='text-label information'>
                            <CmsBlockContent name='BOOKING-CONTACT' className='cms-block-inline' />
                        </span>
                        <div className='contains-customer-form'>
                            <CustomerDetailsForm
                                crmFullNameNotSet={this._crmFullNameNotSet()}
                                crmEmailNotSet={this._crmEmailNotSet()}
                                formData={formData}
                                crmUser={crmUser}
                                selectedPassengerId={selectedPassengerId}
                                additionalDetails={additionalDetails}
                            />
                            <TextLabel
                                className='text-label required-notification'
                                text={_t.getIntlMessage('form.required-notification')}
                            />
                        </div>
                    </div>
                )
        )
    },

    _getPossibleCustomers () {
        return this.props.passengers.filter(({type}) => [PASSENGER_TYPE_ADULT, PASSENGER_TYPE_YOUTH].includes(type))
    },

    _renderSelectCustomer () {
        return (
            this._crmFullNameNotSet()
                ? <div className='select-passenger'>
                    {this._getPossibleCustomers().map((passenger, idx) => (
                        <div className='select-passengers' key={idx}>
                            <Radio
                                id={passenger.id} name='selectPassenger'
                                onChange={this.onPassengerSelect}
                                checked={this.state.selectedPassengerId === passenger.id}
                            >
                                <label htmlFor={passenger.id} className='text-label'>
                                    <TextLabel
                                        text={_t.formatIntlMessage(
                                            'personal-information-form.select-passenger',
                                            {firstName: passenger.first_name, lastName: passenger.last_name}
                                        )} />
                                </label>
                            </Radio>
                        </div>
                    ))}
                    <Radio
                        id={CUSTOMER_TYPE_PAY_ONLY}
                        name='selectPassenger'
                        onChange={this.onPassengerSelect}
                        checked={this.state.selectedPassengerId === CUSTOMER_TYPE_PAY_ONLY}
                    >
                        <label htmlFor={CUSTOMER_TYPE_PAY_ONLY} className='text-label'>
                            <TextLabel text={_t.getIntlMessage('personal-information-form.payment-only')} />
                        </label>
                    </Radio>
                </div>
                : null
        )
    },

    onPassengerSelect (value) {
        customerInfoStore._resetForm()
        this.setState({
            selectedPassengerId: value.target.id
        })
    },

    _crmFullNameNotSet () {
        const crmUser = this.state.crmUser
        return !crmUser || !crmUser.first_name || !crmUser.last_name
    },

    _crmEmailNotSet () {
        const crmUser = this.state.crmUser
        return !crmUser || !crmUser.email
    },

    onButtonClick () {
        const {crmUser, selectedPassengerId} = this.state
        const {emailAddress, phone} = this.props.formData.fields
        let customerFields = []
        if (crmUser && !this._crmFullNameNotSet()) {
            const {gender, first_name: firstName, last_name: lastName} = crmUser
            customerFields = [
                {id: 'gender', value: gender},
                {id: 'firstName', value: firstName},
                {id: 'lastName', value: lastName},
                {id: 'phone', value: phone.value},
                {id: 'emailAddress', value: emailAddress.value},
                {id: 'repeatEmailAddress', value: emailAddress.value}
            ]
        } else {
            if (!selectedPassengerId) {
                return false
            }

            const {gender, firstName, lastName} = this.props.formData.fields
            const passenger = this._getPossibleCustomers().find(passenger => passenger.id === selectedPassengerId)
            customerFields = [
                {id: 'gender', value: (passenger && passenger.gender) || (gender && gender.value)},
                {id: 'firstName', value: (passenger && passenger.first_name) || firstName.value},
                {id: 'lastName', value: (passenger && passenger.last_name) || lastName.value},
                {id: 'phone', value: phone.value},
                {id: 'emailAddress', value: emailAddress.value}
            ]
        }

        this.props.onContinue(() => actions.processCustomerInfoData(customerFields))
    }

})
