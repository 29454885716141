import React, {Component} from 'react'
import PropTypes from 'prop-types'
import GridRow from './grid-row'

export default class ApplicableFee extends Component {
    static propTypes = {
        price: PropTypes.number.isRequired,
        afterSales: PropTypes.bool
    }

    static defaultProps = {
        afterSales: false
    }

    render () {
        const messageLabel = this.props.afterSales
            ? 'booking-specification.applicable-after-sales-fee' : 'booking-specification.applicable-fee'

        return (
            <div className='applicable-fee'>
                <GridRow
                    messageLabel={messageLabel}
                    value={this.props.price}
                    className='text-label booking-specification-value'
                />
            </div>
        )
    }
}

