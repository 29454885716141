import _ from 'lodash'

export default {

    stringify (obj) {
        return this._build(obj)
    },

    _build (obj, prefix) {
        if (obj instanceof _) {
            obj = obj.value()
        }

        return Object.keys(obj).reduce((str, propertyName) => {
            const k = prefix ? prefix + '[' + propertyName + ']' : propertyName
            const v = obj[propertyName]
            str.push(typeof v === 'object'
                ? this._build(v, k)
                : encodeURIComponent(k) + '=' + encodeURIComponent(v))
            return str
        }, []).join('&')
    }
}
