import {createSelector} from 'reselect'
import {TRAVEL_DIRECTION_INBOUND, TRAVEL_DIRECTION_OUTBOUND} from '../../constants'
import {passengersSelector, withoutCancelledSelector} from '../../api/v2/booking/passengers'
import {bookingSelector} from '../../../../reflux/bridge/booking'
import sumBy from 'lodash/sumBy'
import {
    additionalProductsSelector, requiredProductsSelector,
    withNonProvisionalNonCancelledSelector,
    withProvisionalNonCancelledSelector
} from '../../api/v2/booking/products'
import get from 'lodash/get'
import {PRODUCT_CODE_BIKE} from '../../../../constants'
import {selectedBundlesSelector} from '../orientation/journey-search'

// Get bike products from the selected bundle from the journey search
export const bikeProductsSelector = createSelector(
    [selectedBundlesSelector],
    bundles => bundles.flatMap(
        bundle => bundle.items
            .filter(item => item.productCode === PRODUCT_CODE_BIKE)
            .map(item => ({...item, direction: bundle.direction}))
    )
)

// Get an overview of the total price of the bike product group per direction and per passenger
// It is a once per passenger per booking product
// the total price is split across all segments in the selected journey
export const bikeTotalPricePerPassengerPerDirectionSelector = createSelector(
    [bikeProductsSelector],
    products => products.reduce((directions, {direction, passengerFares}) => {
        if (!directions[direction]) directions[direction] = {}

        directions[direction] = passengerFares.reduce((directionPricePerPassenger, {passengerId, price}) => {
            directionPricePerPassenger[passengerId] = directionPricePerPassenger[passengerId]
                ? directionPricePerPassenger[passengerId] + price
                : price

            return directionPricePerPassenger
        }, directions[direction])

        return directions
    }, [])
)

// Max quantity should be the same for every product for a direction
// return the max quantity of the first product found for the given direction
const bikeMaxQuantityForDirectionSelector = _direction => createSelector(
    [bikeProductsSelector],
    bikeProducts => get(bikeProducts.find(({direction}) => direction === _direction), 'maxQuantity', 0)
)

// Retrieve the maximum quantity allowed to add for a passenger in a direction based on the maxQuantity allowed
// for the bike and the already confirmed items in an existing booking
export const bikeMaxQuantityForDirectionAndPassengerSelector = (_direction, passengerId) => createSelector(
    [
        bikeMaxQuantityForDirectionSelector(_direction),
        existingBikeProductsPerPassengerBookingForDirectionSelector(_direction)
    ],
    (maxQuantity, existingBikes) => maxQuantity > 0 ? maxQuantity - get(existingBikes[passengerId], 'amount', 0) : 0
)

// Tariff code should be the same for every product for a direction and a passenger
// return the tariff code of the first product passenger fare found for the given direction and passenger
export const bikeTariffCodeForDirectionAndPassengerSelector = (_direction, _passengerId) => createSelector(
    [bikeProductsSelector],
    bikeProducts => bikeProducts.find(
        ({direction}) => direction === _direction
    ).passengerFares.find(
        ({passengerId}) => passengerId === _passengerId
    ).tariffCode
)

const mapBikePerPassenger = (_direction, products) =>
    products.reduce((passengerProducts, {productCode, direction, passenger, price, segmentId}) => {
        if (productCode !== PRODUCT_CODE_BIKE || direction !== _direction) {
            return passengerProducts
        }

        let _amount = 1
        let _totalPrice = price
        let _singlePrice = price
        let _segmentId = segmentId

        if (passengerProducts[passenger.id]) {
            // To determine the correct amount to show to the customer only take one segment in the journey into account
            _segmentId = passengerProducts[passenger.id].segmentId
            _amount = passengerProducts[passenger.id].amount + (passengerProducts[passenger.id].segmentId === segmentId ? 1 : 0)
            // Total price of all selected bike for this passenger
            _totalPrice += passengerProducts[passenger.id].totalPrice
            // Price of a single bike for this passenger
            _singlePrice = passengerProducts[passenger.id].singlePrice + (passengerProducts[passenger.id].segmentId === segmentId ? price : 0)
        }

        return {
            ...passengerProducts,
            [passenger.id]: {
                amount: _amount,
                singlePrice: _singlePrice,
                totalPrice: _totalPrice,
                passenger,
                segmentId: _segmentId
            }
        }
    }, {})

// Retrieve the provisional bike products from the booking grouped per passenger for a given direction
export const provisionalBikeProductsPerPassengerBookingForDirectionSelector = _direction => createSelector(
    [withProvisionalNonCancelledSelector(additionalProductsSelector)],
    products => mapBikePerPassenger(_direction, products)
)

// Retrieve the existing bike products from the booking grouped per passenger for a given direction
export const existingBikeProductsPerPassengerBookingForDirectionSelector = _direction => createSelector(
    [withNonProvisionalNonCancelledSelector(additionalProductsSelector)],
    products => mapBikePerPassenger(_direction, products)
)

const canAddBikeAfterSalesForDirectionSelector = _direction => createSelector(
    [
        existingBikeProductsPerPassengerBookingForDirectionSelector(_direction),
        bikeMaxQuantityForDirectionSelector(_direction),
        withoutCancelledSelector(passengersSelector(bookingSelector))
    ],
    (existingBike, maxQuantity, passengers) => {
        const sumExistingBike = sumBy(Object.values(existingBike), 'amount')
        const potentialBike = maxQuantity * passengers.length

        return sumExistingBike < potentialBike
    }
)

export const canAddBikeAfterSalesSelector = createSelector(
    [
        canAddBikeAfterSalesForDirectionSelector(TRAVEL_DIRECTION_OUTBOUND),
        canAddBikeAfterSalesForDirectionSelector(TRAVEL_DIRECTION_INBOUND)
    ],
    (canAddOutbound, canAddInbound) => canAddOutbound || canAddInbound
)

export const canOfferBikeForDirectionSelector = _direction => createSelector(
    [requiredProductsSelector(bookingSelector)],
    products => {
        // Booking or Rebook flow
        const newlyAddedProducts = products.filter(({cancelled, provisional}) => !cancelled && provisional)
        if (newlyAddedProducts.length) {
            return newlyAddedProducts.some(({direction}) => direction === _direction)
        }

        // Add bike in after sales flow
        return products.some(({cancelled, direction}) => !cancelled && direction === _direction)
    }
)
