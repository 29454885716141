import forIn from 'lodash/forIn'
import indexOf from 'lodash/indexOf'
import isBoolean from 'lodash/isBoolean'
import isDate from 'lodash/isDate'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import moment from 'moment-timezone'
import {PhoneNumberUtil} from 'google-libphonenumber'

const phoneUtil = PhoneNumberUtil.getInstance()

export default {

    _validateData (newData) {
        let fieldIds = (undefined === newData) ? null : newData.map(record => record.id)
        this.data.isValid = this._validateFields(this.data, fieldIds)
        this.trigger(this.data)
    },

    _validateFields (dataObject, fieldIds) {
        let isValid = true

        forIn(dataObject.fields, (field, fieldName) => {
            const result = this._validateField(fieldName, field)
            const isWarning = Boolean(result && result.indexOf('warning') === 0)

            isValid = (result === null || isWarning) && isValid

            if (fieldIds === null || indexOf(fieldIds, field.id || fieldName) !== -1) {
                dataObject.fields[fieldName].isValid = result === null || isWarning
                dataObject.fields[fieldName].errorText = result
            }
        })

        return isValid
    },

    _validateField (fieldName, field) {
        let validators = this.validators[fieldName] || []
        if (field.isRequired) {
            validators = [this._validateRequired, ...validators]
        }

        let result = null
        validators.some(validator => {
            result = validator(field.value, field)

            return result !== null
        })

        return result
    },

    _validateRequired (value) {
        const valid =
            (isBoolean(value) && value === true) ||
            (isDate(value) && moment(value).isValid()) ||
            (!isEmpty(value))

        return valid ? null : 'is-required'
    },

    _validateBoolean (value) {
        return typeof value !== 'boolean' ? 'invalid-boolean-value' : null
    },

    _validateMinLength (value, minLength) {
        return typeof value === 'string' && minLength > value.length ? 'invalid-min-length' : null
    },

    _validateMaxLength (value, maxLength) {
        return typeof value === 'string' && maxLength < value.length ? 'invalid-max-length' : null
    },

    _validateGmail (value) {
        const regex = new RegExp('^(\\W|^)[\\w.+\\-]*@gmail\\.\\w{2,}$')
        return regex.test(value) ? 'warning-gmail-address' : null
    },

    _validateEmail (value) {
        const regex = new RegExp('^(([^<>()[\\].,;:\\s@"]+(\\.[^<>()[\\].,;:\\s@"]+)*)|(".+"))@' +
            '(([^<>()[\\].,;:\\s@"]+\\.)+[^<>()[\\].,;:\\s@"]{2,})$')
        return !regex.test(value) ? 'invalid-email-address' : null
    },

    _validateInOptionsList (value, options) {
        return options.filter(option => value === option.value).length === 0 ? 'invalid-value-not-in-list' : null
    },

    _validateInGenderOptionsList (value, options) {
        return !value
            ? null
            : options.filter(option => value === option.value).length === 0
                ? 'invalid-value-not-in-list'
                : null
    },

    _compareValues (value, compare) {
        return !isEqual(value, compare) ? 'invalid-value-is-not-the-same' : null
    },

    _validDateFormats () {
        return ['D-M-YYYY', 'D/M/YYYY', 'YYYY/M/D', 'YYYY-M-D']
    },

    _validateDate (value) {
        return value !== '' && !moment(value, this._validDateFormats(), true).isValid() ? 'invalid-date' : null
    },

    _validPhoneNumber (value, region) {
        try {
            return phoneUtil.isValidNumber(phoneUtil.parse(value, region))
                ? null : 'invalid-phone-number'
        } catch (e) {
            return 'invalid-phone-number'
        }
    },

    _validateFloat (value) {
        return value !== '' && isNaN(value) ? 'invalid-float' : null
    },

    _validateInteger (value) {
        return value !== '' && (isNaN(value) || !Number.isInteger(parseFloat(value))) ? 'invalid-integer' : null
    },

    _validateMin (value, min) {
        return min > value ? 'invalid-min' : null
    },

    _validateRegex (value, regex) {
        const regexp = new RegExp(regex)
        return !regexp.test(value) ? 'invalid-regex' : null
    }
}
