import React, {Component} from 'react'
import PropTypes from 'prop-types'
import _t from '../../translate'
import TextLabel from '../../element/text-label'
import device from '../../device'

export default class RouteSelector extends Component {
    static propTypes = {
        onChange: PropTypes.func.isRequired,
        defaultValue: PropTypes.string,
        direction: PropTypes.oneOf(['outbound', 'inbound'])
    }

    constructor (...args) {
        super(...args)
        this.state = {
            value: this.props.defaultValue
        }
        this.onChange = this.onChange.bind(this)
    }

    static defaultProps = {
        defaultValue: 'all'
    }

    onChange (event) {
        this.setState({
            value: event.target.value
        })
        this.props.onChange(event.target.value)
    }

    render () {
        const id = `route-selector-${this.props.direction}`
        return (
            <div className='journey-result-set-filter route-selector'>
                <div className='grid-row'>
                    {device.isDesktopOrTablet() ? <div className='grid-column--1-1'>
                        <label
                            htmlFor={id}
                            className='text-label select-route-description'
                        >
                            <TextLabel text={_t.getIntlMessage('journey-result-set.route-selector')} />
                        </label>
                    </div> : null}
                    <div className='grid-column--1-1'>
                        <select
                            id={id}
                            value={this.state.value}
                            onChange={this.onChange}
                        >
                            <option value='all'>{_t.getIntlMessage('route-selector.all')}</option>
                            <option value='direct'>{_t.getIntlMessage('route-selector.direct')}</option>
                        </select>
                    </div>
                </div>
            </div>
        )
    }
}
