import PropTypes from 'prop-types'
import React from 'react'
import Reflux from 'reflux'
import _ from 'lodash'
import moment from 'moment-timezone'
import _t from '../../translate'
import device from '../../device'
import TextField from '../../element/form/text-field'
import Checkbox from '../../element/form/checkbox-field'
import RadioGroup from '../../element/form/radio-group'
import actions from '../../reflux/actions'
import UpdatePassengersDetailsStore from '../../reflux/stores/update-passenger-details-store'
import {sortPassengers} from '../../misc/helpers'
import createReactClass from 'create-react-class'

export default createReactClass({
    displayName: 'Details',

    mixins: [
        Reflux.connect(UpdatePassengersDetailsStore, 'formData')
    ],

    propTypes: {
        editPassengers: PropTypes.array.isRequired,
        onEditPassenger: PropTypes.func.isRequired,
        onResetPassenger: PropTypes.func.isRequired
    },

    render () {
        return (
            <div>
                <div className='passenger-details'>
                    {sortPassengers(this.state.formData.passengers).reduce((output, passenger, index) => {
                        if (!passenger.isCancelled) {
                            output.push(this._renderPassenger(passenger, index))
                        }
                        return output
                    }, [])}
                </div>
            </div>
        )
    },

    _renderPassenger (passenger, index) {
        const edit = this.props.editPassengers.indexOf(passenger.id) !== -1
        const editPassengerOnChange = edit ? this.props.onResetPassenger : this.props.onEditPassenger
        return (
            <div className='panel wizard state--is-active' key={`${passenger.id}_${passenger.timestamp}`}>
                <header>
                    <h2>
                        {_t.formatIntlMessage('update-passenger-details.header', {
                            number: index + 1,
                            type: _t.message(`passenger-types.${passenger.passengerType.toLowerCase()}`),
                            passengerType: passenger.passengerType
                        })}
                    </h2>
                </header>
                <div className='content'>
                    <form action='#' noValidate>
                        <fieldset>
                            <div className='grid-row'>
                                <div className='grid-column--1-1'>
                                    <Checkbox
                                        id={`editPassenger_${passenger.id}`}
                                        ref={`editPassenger_${passenger.id}`}
                                        labelPosition='after'
                                        labelText='update-passenger-details.edit-passenger'
                                        checked={edit}
                                        onChange={() => editPassengerOnChange(passenger.id)}
                                        disabled={passenger.isCancelled}
                                    />
                                </div>
                            </div>
                        </fieldset>
                        {this.renderPassengerRequireDateOfBirth(passenger, edit)}
                        <fieldset>
                            <div className='grid-row'>
                                {passenger.fields.gender ? (
                                    <div className='grid-column--1-1 grid-column--medium-1-4'>
                                        <RadioGroup
                                            className='row gender'
                                            data={passenger.fields.gender.options}
                                            {...this._radioGroupProps(passenger, 'gender', edit)}
                                        />
                                    </div>
                                ) : null}
                                <div className='grid-column--1-1 grid-column--medium-1-4'>
                                    <TextField
                                        className='first-name'
                                        {...this._textFieldProps(passenger, 'firstName', edit)} />
                                </div>
                                <div className='grid-column--1-1 grid-column--medium-2-4'>
                                    <TextField
                                        className='last-name'
                                        {...this._textFieldProps(passenger, 'lastName', edit)} />
                                </div>
                            </div>
                        </fieldset>
                        <fieldset>
                            <div className='grid-row'>
                                <div className='grid-column--1-1 grid-column--medium-1-3'>
                                    <TextField
                                        className='date-of-birth'
                                        {...this._maskedInputProps(passenger, 'dateOfBirth', [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/], edit)} />
                                </div>
                            </div>
                        </fieldset>
                    </form>
                </div>
            </div>
        )
    },

    renderPassengerRequireDateOfBirth (passenger, edit) {
        if (!passenger.fields.requireDateOfBirth) {
            return null
        }

        return (
            <fieldset>
                <div className='grid-row'>
                    <div className='grid-column--1-1'>
                        <div className='check-radio-wrapper'>
                            <Checkbox {...this._checkboxFieldProps(passenger, 'requireDateOfBirth', edit)} />
                        </div>
                    </div>
                </div>
            </fieldset>
        )
    },

    _radioGroupProps (passenger, fieldName, edit) {
        const kebabCase = _.kebabCase(fieldName)

        return {
            id: passenger.fields[fieldName].id,
            ref: passenger.fields[fieldName].id,
            selectedValue: passenger.fields[fieldName].value,
            required: passenger.fields[fieldName].isRequired,
            errorText: this._prefixFieldLabel(kebabCase, passenger.fields[fieldName].errorText),
            onChange: this.onRadioChecked,
            disabled: !edit
        }
    },

    _selectFieldProps (passenger, fieldName, edit) {
        const kebabCase = _.kebabCase(fieldName)

        return {
            id: passenger.fields[fieldName].id,
            ref: passenger.fields[fieldName].id,
            labelText: this._prefixFieldLabel(kebabCase, 'label'),
            labelClassName: 'is-invisible',
            value: passenger.fields[fieldName].value,
            required: passenger.fields[fieldName].isRequired,
            errorText: this._prefixFieldLabel(kebabCase, passenger.fields[fieldName].errorText),
            onChange: this.onBlur,
            disabled: !edit
        }
    },

    _textFieldProps (passenger, fieldName, edit) {
        const kebabCase = _.kebabCase(fieldName)
        const readOnly = !edit || passenger.fields[fieldName].isDisabled

        return {
            id: passenger.fields[fieldName].id,
            ref: fieldName,
            placeholder: this._prefixFieldLabel(kebabCase, 'placeholder'),
            defaultValue: passenger.fields[fieldName].value,
            required: passenger.fields[fieldName].isRequired,
            errorText: this._prefixFieldLabel(kebabCase, passenger.fields[fieldName].errorText),
            onBlur: this.onBlur,
            readOnly: readOnly
        }
    },

    _maskedInputProps (passenger, fieldName, mask, edit) {
        if (!device.supportsInputMask()) {
            return this._textFieldProps(passenger, fieldName, edit)
        }

        const kebabCase = _.kebabCase(fieldName)
        const readOnly = !edit || passenger.fields[fieldName].isDisabled

        return {
            id: passenger.fields[fieldName].id,
            ref: passenger.fields[fieldName].id,
            mask: mask,
            placeholder: this._prefixFieldLabel(kebabCase, 'placeholder'),
            value: passenger.fields[fieldName].value,
            required: passenger.fields[fieldName].isRequired,
            errorText: this._prefixFieldLabel(kebabCase, passenger.fields[fieldName].errorText),
            onBlur: this.onBlur,
            readOnly: readOnly
        }
    },

    _checkboxFieldProps (passenger, fieldName, edit) {
        let kebabCase = _.kebabCase(fieldName)
        return {
            id: passenger.fields[fieldName].id,
            ref: passenger.fields[fieldName].id,
            labelText: this._prefixFieldLabel(kebabCase, 'label'),
            labelPosition: 'after',
            checked: passenger.fields[fieldName].value === true,
            required: passenger.fields[fieldName].isRequired,
            errorText: this._prefixFieldLabel(kebabCase, passenger.fields[fieldName].errorText),
            onChange: this.onCheckboxClicked,
            disabled: !edit
        }
    },

    _prefixFieldLabel (field, label) {
        return label ? `passenger-details-form.${field}.${label}` : null
    },

    onBlur (event) {
        actions.processUpdatePassengerDetailsData([{
            id: event.target.id,
            value: event.target.value
        }])
    },

    onRadioChecked (id, value) {
        actions.processUpdatePassengerDetailsData([{
            id: id,
            value: value
        }])
    },

    onCheckboxClicked (event) {
        actions.processUpdatePassengerDetailsData([{
            id: event.target.id,
            value: event.target.checked
        }])
    },

    onUploadComplete (passengerValues) {
        actions.processUpdatePassengerDetailsData(passengerValues)
        this.state.formData.passengers.forEach(passenger => {
            passenger.timestamp = moment().format('x')
            this.props.onEditPassenger(passenger.id)
        })
    }
})
