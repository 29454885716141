// libraries
import React, { Component } from 'react'
import _t from '../translate'

import TextLabel from '../element/text-label'

export default class OuiplusAd extends Component {
    render () {
        return (
            <div className='ouiplus-ad'>
                <div className='panel'>
                    <header>
                        <h1>{_t.getIntlMessage('ouiplus-ad.title')}</h1>
                    </header>
                    <div className='content'>
                        <ul>
                            <li className='ouiplus-usp comfort'>
                                <span className='text-label ouiplus-usp-name'>
                                    <TextLabel text={_t.getIntlMessage('ouiplus-ad.usp.comfort.name')} />
                                </span>
                                <span className='text-label ouiplus-usp-description'>
                                    <TextLabel text={_t.getIntlMessage('ouiplus-ad.usp.comfort.description')} />
                                </span>
                            </li>
                            <li className='ouiplus-usp wifi'>
                                <span className='text-label ouiplus-usp-name'>
                                    <TextLabel text={_t.getIntlMessage('ouiplus-ad.usp.wifi.name')} />
                                </span>
                                <span className='text-label ouiplus-usp-description'>
                                    <TextLabel text={_t.getIntlMessage('ouiplus-ad.usp.wifi.description')} />
                                </span>
                            </li>
                            <li className='ouiplus-usp sockets'>
                                <span className='text-label ouiplus-usp-name'>
                                    <TextLabel text={_t.getIntlMessage('ouiplus-ad.usp.sockets.name')} />
                                </span>
                                <span className='text-label ouiplus-usp-description'>
                                    <TextLabel text={_t.getIntlMessage('ouiplus-ad.usp.sockets.description')} />
                                </span>
                            </li>
                            <li className='ouiplus-usp ticketless'>
                                <span className='text-label ouiplus-usp-name'>
                                    <TextLabel text={_t.getIntlMessage('ouiplus-ad.usp.ticketless.name')} />
                                </span>
                                <span className='text-label ouiplus-usp-description'>
                                    <TextLabel text={_t.getIntlMessage('ouiplus-ad.usp.ticketless.description')} />
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}
