import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Passenger from './passenger-container'
import {sortPassengers} from '../../../misc/helpers'

export default class Passengers extends Component {
    static propTypes = {
        legId: PropTypes.string.isRequired,
        onRemoveSeat: PropTypes.func.isRequired,
        onSelectPassenger: PropTypes.func.isRequired,
        passengers: PropTypes.array.isRequired,
        selectedDirection: PropTypes.string.isRequired,
        selectedSeats: PropTypes.array.isRequired,
        passengerFaresSeat: PropTypes.array,
        passengerFaresSeatPlus: PropTypes.array,
        readOnly: PropTypes.any,
        editOnly: PropTypes.any,
        selectedLeg: PropTypes.any,
        selectedPassenger: PropTypes.any
    }

    constructor (props) {
        super(props)
        this.handleClickSelect = this.handleClickSelect.bind(this)
        this.handleClickRemove = this.handleClickRemove.bind(this)
    }

    render () {
        const passengers = sortPassengers(this.props.passengers)
        return (
            <div className='passengers'>
                <ul>
                    {passengers.map((passenger, index) => this.renderPassenger(passenger, index))}
                </ul>
            </div>
        )
    }

    handleClickSelect (passenger) {
        this.props.onSelectPassenger(
            passenger,
            this.props.selectedDirection,
            this.props.legId
        )
    }

    handleClickRemove (passenger) {
        this.props.onRemoveSeat(
            passenger,
            this.props.legId
        )
    }

    renderPassenger (passenger, index) {
        const selectedSeat = this.props.selectedSeats
            .find(seat => seat.passenger_id === passenger.id && seat.leg_id === this.props.legId)

        return (
            <Passenger
                key={passenger.id}
                index={index}
                passenger={passenger}
                selectedPassenger={this.props.selectedPassenger}
                legId={this.props.legId}
                selectedLeg={this.props.selectedLeg}
                selectedSeat={selectedSeat}
                readOnly={this.props.readOnly}
                editOnly={this.props.editOnly}
                onClickSelect={this.handleClickSelect}
                onClickRemove={this.handleClickRemove}
                passengerFaresSeat={this.props.passengerFaresSeat}
                passengerFaresSeatPlus={this.props.passengerFaresSeatPlus}
            />
        )
    }
}
