import PropTypes from 'prop-types'
import React, { Component} from 'react'
import {withRouter} from 'react-router'
import _t from '../../../../translate'
import Icon from '../../../../element/icon'
import TextLabel from '../../../../element/text-label'

class BookSeats extends Component {
    static propTypes = {
        legId: PropTypes.string.isRequired,
        isAvailable: PropTypes.bool,
        router: PropTypes.object
    }

    constructor (...args) {
        super(...args)
        this.onClick = this.onClick.bind(this)
    }

    onClick (event) {
        event.preventDefault()
        this.props.router.push(`/${_t.getLocales()}/mys3/seat-selector/${this.props.legId}`)
    }

    render () {
        return this.props.isAvailable
            ? (
                <a href='' className='text-label seat-selector-options book-seats' onClick={this.onClick}>
                    <Icon type='seat-allocation' className='small align-left' />
                    <TextLabel text={_t.message('seat-selector.book-seats-button')} />
                </a>
            )
            : null
    }
}

export default withRouter(BookSeats)
