import React from 'react'
import {Route} from 'react-router'
import authCheck from '../../mixins/auth'
import BookingOptionsLayout from '../../layouts/booking/options'

export default (
    <Route path='/:lang/booking/options'
           component={BookingOptionsLayout}
           onEnter={(nextState, replaceState, callback) => authCheck(nextState, replaceState, callback)}
    />
)
