import Reflux from 'reflux'
import omitBy from 'lodash/omitBy'
import clone from 'lodash/clone'
import findIndex from 'lodash/findIndex'
import Moment from 'moment-timezone'
import actions from '../actions'
import UserAwareMixin from '../mixin/user-aware-mixin'
import FormValidationMixin from '../mixin/form-validation-mixin'
import {isBlank} from '../../misc/helpers'

export default Reflux.createStore({

    listenables: actions,

    mixins: [FormValidationMixin, UserAwareMixin],

    init () {
        this._resetData()

        this.validators = {
            email: [this._validateEmail]
        }
    },

    _resetData () {
        this.data = {
            favoritePassengersChanges: [],
            mePassenger: {
                id: null
            }
        }
    },

    resetData () {
        this._resetData()
        this.trigger(this.data)
    },

    getInitialState () {
        return this.data
    },

    onMyS3GetFavoritePassengers () {
        this.data.loading = true
        this.data.success = true
        this.data.error = ''
        this.trigger(this.data)
    },

    onMyS3GetFavoritePassengersCompleted (data) {
        this.data.loading = false
        this.data.favoritePassengers = data.passengers
        this.data.favoritePassengersChanges = []
        this.trigger(this.data)
    },

    onMyS3GetFavoritePassengersFailed (error) {
        this.data.loading = false
        this.data.success = false
        this.data.error = error
        this.trigger(this.data)
    },

    onMyS3UpdateFavoritePassenger (favoritePassenger) {
        const index = this.data.favoritePassengers.passengers.findIndex({ref_id: favoritePassenger.ref_id})
        if (index !== -1) {
            let data = clone(this.data)
            data.favoritePassengers.passengers[index].active = favoritePassenger.active
            this.trigger(data)
        }
    },

    onMyS3UpdateFavoritePassengerCompleted (favoritePassenger) {
        const index = this.data.favoritePassengers.passengers.findIndex({ref_id: favoritePassenger.ref_id})
        if (index !== -1) {
            this.data.favoritePassengers.passengers[index] = favoritePassenger
            this.trigger(this.data)
        }
    },

    onMyS3CreateFavoritePassenger () {
        this.data.creating = true
        this.data.success = true
        this.data.error = ''
        this.trigger(this.data)
    },

    onMyS3CreateFavoritePassengerFailed (error) {
        this.data.creating = false
        this.data.success = false
        this.data.error = error
        this.trigger(this.data)
    },

    onProcessFavoritePassenger (newData) {
        const favoritePassengersChanges = this.data.favoritePassengersChanges
        const index = findIndex(favoritePassengersChanges, {ref_id: newData.ref_id})
        if (index !== -1) {
            favoritePassengersChanges[index] = newData
        } else {
            const previousIndex = favoritePassengersChanges.findIndex(favoritePassengerChanges =>
                favoritePassengerChanges.passenger_id === newData.passenger_id
            )
            if (previousIndex !== -1) {
                favoritePassengersChanges.splice(previousIndex, 1)
            }
            favoritePassengersChanges.push(newData)
        }
        this.trigger(this.data)
    },

    isExistingFavoritePassenger (passenger) {
        const favoritePassengers = this.data.favoritePassengers || []
        return favoritePassengers.find(favoritePassenger => (
            favoritePassenger.first_name === passenger.first_name &&
            favoritePassenger.last_name === passenger.last_name &&
            Moment(favoritePassenger.birth_date, ['YYYY-MM-DD'])
                .isSame(Moment(passenger.birth_date, ['DD/MM/YYYY', 'YYYY-MM-DD'])) &&
            favoritePassenger.gender === passenger.gender
        )) || false
    },

    getCreateFavoritePassengersForApiCall () {
        return {
            passengers: this.data.favoritePassengersChanges
                .filter(favoritePassenger => favoritePassenger.add === true)
                .map(favoritePassenger => this.formatForApiCall(favoritePassenger))
        }
    },

    formatForApiCall (favoritePassenger) {
        return omitBy({
            ref_id: favoritePassenger.ref_id,
            first_name: favoritePassenger.first_name,
            last_name: favoritePassenger.last_name,
            birth_date: Moment(favoritePassenger.birth_date, ['DD/MM/YYYY', 'YYYY-MM-DD']).format('YYYY-MM-DD'),
            type: favoritePassenger.type,
            gender: favoritePassenger.gender
        }, isBlank)
    },

    changeMePassenger (passengerId) {
        this.data.mePassenger.id = passengerId
        this.trigger(this.data)
    }
})
