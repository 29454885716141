import PropTypes from 'prop-types'
import React, {Component} from 'react'
import _ from 'lodash'
import _t from '../../translate'
import BookingModel from '../../models/booking-model'
import TextLabel from '../../element/text-label'
import SegmentInformation from '../booking-information/segment-information'
import SegmentInboundOutbound from '../booking-information/segment-inbound-outbound'
import SegmentFee from './cancellation-specification/segment-fee'
import SegmentProductInformation from '../my-s3-cancellation/cancel-individual-items/segment-product-information'
import Footer from './cancellation-specification/footer'

export default class CancellationSpecification extends Component {
    static propTypes = {
        booking: PropTypes.instanceOf(BookingModel).isRequired
    }

    render () {
        return (
            <div className='panel'>
                <header>
                    <span className='text-label heading'>
                        <TextLabel text={_t.message('my-s3-cancellation.confirm.specification-header')} />
                    </span>
                </header>
                <div className='content'>
                    <div className='detailed-specification'>
                        <table>
                            <colgroup>
                                <col className='col-segment' />
                                <col className='col-product' />
                                <col className='col-passenger-type' />
                                <col className='col-price' />
                            </colgroup>
                            <tbody>
                                {this._renderContent()}
                            </tbody>
                            <Footer booking={this.props.booking} />
                        </table>
                    </div>
                </div>
            </div>
        )
    }

    _renderContent () {
        const productMapper = (key, product, passenger, required) => <SegmentProductInformation
            passenger={passenger}
            product={product}
            key={key}
            required={required}
        />

        const getPassengerById = passengerId =>
            _(this.props.booking.passengersWithCancelled).find(passenger => passenger.id === passengerId)

        let previousTariffSegment = null
        const segmentInformationAndProducts = (tariffSegment, key) => {
            let data =
                _(tariffSegment.required_products)
                    .filter(product => product.provisional && product.cancelled)
                    .map(product => productMapper(
                        `product_${product.item_id}`,
                        product,
                        getPassengerById(product.passenger_id),
                        true
                    )).concat(_(tariffSegment.additional_products)
                        .filter(product => product.provisional && product.cancelled)
                        .map(product => productMapper(
                            `addon_${product.item_id}`,
                            product,
                            getPassengerById(product.passenger_id),
                            false
                        )).value()
                    ).value()

            if (data.length) {
                data.unshift(
                    <SegmentInformation
                        key={`segment_${key}`}
                        tariffSegment={tariffSegment}
                        previousTariffSegment={previousTariffSegment} />
                )
                previousTariffSegment = tariffSegment
            }

            return data
        }

        let data = []
        const outbound = _(this.props.booking.outboundTariffSegments)
            .map(segmentInformationAndProducts).flattenDeep().value()

        if (outbound.length) {
            data.push(
                <SegmentInboundOutbound
                    key='outbound-header'
                    type='outbound'
                    header={_t.message('booking-information.outbound-header')}
                />,
                outbound
            )
        }

        const provisionalCancelledFees = this.props.booking.provisionalCancelledFees
        if (provisionalCancelledFees.length > 0) {
            data = data.concat(
                provisionalCancelledFees.map(fee => <SegmentFee key={`segment_fee_${fee.item_id}`} fee={fee} />)
            )
        }

        const inbound = _(this.props.booking.inboundTariffSegments)
            .map(segmentInformationAndProducts).flattenDeep().value()
        if (inbound.length) {
            data.push(
                <SegmentInboundOutbound
                    key='inbound-header'
                    type='inbound'
                    header={_t.message('booking-information.inbound-header')}
                />,
                inbound
            )
        }

        return data
    }
}
