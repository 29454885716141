import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from '../../../translate'
import TextLabel from '../../../element/text-label'
import Icon from '../../../element/icon'
import Button from '../../../element/button'
import Passengers from './passengers'

export default class SectionPassengerSelector extends Component {
    constructor (...args) {
        super(...args)

        this.handleClick = this.handleClick.bind(this)
        this.renderLeg = this.renderLeg.bind(this)
    }

    static propTypes = {
        direction: PropTypes.string.isRequired,
        collapsed: PropTypes.object.isRequired,
        collapseSection: PropTypes.func.isRequired,
        availableSections: PropTypes.array.isRequired,
        travel: PropTypes.array.isRequired,
        readOnly: PropTypes.bool,
        editOnly: PropTypes.bool,
        passengerFaresSeat: PropTypes.array,
        passengerFaresSeatPlus: PropTypes.array,
        onRemoveSeat: PropTypes.any,
        onSelectPassenger: PropTypes.any,
        passengers: PropTypes.any,
        selectedLegId: PropTypes.any,
        selectedPassenger: PropTypes.any,
        selectedSeats: PropTypes.any
    }

    handleClick (event) {
        event && event.preventDefault()
        event && event.stopPropagation()
        this.props.collapseSection(this.props.direction)
    }

    render () {
        const direction = this.props.direction
        const isCollapsed = this.props.collapsed[direction]
        return (
            <div className={`passenger-selector ${direction}`}>
                <div className='summary'>
                    <span className='text-label summary-title'>
                        <TextLabel text={_t.getIntlMessage(`seat-selector.directions.${direction}`)} />
                        <Icon
                            className='medium align-right'
                            type={`fleche-${direction === 'inbound' ? 'left' : 'right'}`}
                        />
                        <Button
                            type='button'
                            className='button seat-specification-details clear'
                            onClick={this.handleClick}
                        >
                            <Icon className='medium' type={`chevron-${isCollapsed ? 'up' : 'down'}`} />
                        </Button>
                    </span>
                </div>
                {isCollapsed
                    ? <div className='schedule'>{this.props.travel.map(this.renderLeg)}</div>
                    : null
                }
            </div>
        )
    }

    renderLeg (direction, index) {
        const leg = direction.leg
        const busNumber = `${_t.getIntlMessage('seat-selector.bus')} ${index + 1}`
        return (
            <div className='journey-section' key={busNumber}>
                <div className='journey-section-title'>
                    <span className='text-label journey-section-coach'>
                        <TextLabel text={busNumber} />
                    </span>
                    <span className='text-label journey-section-origin-destination'>
                        <TextLabel text={`${leg.departure_station.name} > ${leg.arrival_station.name} `} />
                    </span>
                </div>
                {this._legHasSeatSelection(leg.id) || this.props.readOnly
                    ? <Passengers
                        legId={leg.id}
                        onRemoveSeat={this.props.onRemoveSeat}
                        onSelectPassenger={this.props.onSelectPassenger}
                        passengers={this.props.passengers}
                        selectedBusNumber={busNumber}
                        selectedDirection={direction.direction}
                        selectedSeats={this.props.selectedSeats}
                        selectedLeg={this.props.selectedLegId}
                        selectedPassenger={this.props.selectedPassenger}
                        passengerFaresSeat={this.props.passengerFaresSeat}
                        passengerFaresSeatPlus={this.props.passengerFaresSeatPlus}
                        readOnly={this.props.readOnly}
                        editOnly={this.props.editOnly}
                    />
                    : <span className='text-label seat-selection-unavailable'>
                        <TextLabel text={_t.getIntlMessage(`seat-selector.seat-unavailable`)} />
                    </span>
                }
            </div>
        )
    }

    _legHasSeatSelection (legId) {
        return this.props.availableSections.includes(legId) || this.props.selectedSeats.some(seat => legId === seat.leg_id)
    }
}
