import _t from '../translate'
import BookingStore from '../reflux/stores/booking-store'
import DeepLinkStore from '../reflux/stores/deep-link-store'
import OfferStore from '../reflux/stores/offer-store'
import {createQueryFromValues, getJourneySearchFromBooking} from './journey-search-url-query'
import moment from 'moment-timezone'
import omitBy from 'lodash/omitBy'
import {selectedBundlesSelector} from '../models/selectors/components/orientation/journey-search'
import {getState} from '../reflux/bridge/connect-state'
import {getUrl, isBlank} from './helpers'

const getJourneySearchValues = () => {
    const journeySearch = getJourneySearchFromBooking(BookingStore.getBookingModel())
    const bookingModel = BookingStore.getBookingModel()
    const selectedBundles = selectedBundlesSelector(getState())
    const deepLink = DeepLinkStore.getData()

    if (deepLink && deepLink.origin) {
        journeySearch.origin = deepLink.origin || journeySearch.origin
        journeySearch.destination = deepLink.destination || journeySearch.destination
        if (bookingModel && bookingModel.isReturnTrip() && selectedBundles && journeySearch.selectedOutbound) {
            const selectedOutboundBundle = selectedBundles.find(bundle => bundle.direction === 'outbound')
            // this was some big ID, now it is another big ID, both from journey-search
            journeySearch.selectedOutbound.route = selectedOutboundBundle.routeId
            journeySearch.selectedOutbound.travelIndex = selectedOutboundBundle.travelId
        }
    }

    return journeySearch
}

const getPreviousJourneySearchValues = () => {
    const journeySearch = OfferStore.getPreviousJourneySearchData()

    return journeySearch && omitBy({
        origin: journeySearch.origin,
        destination: journeySearch.destination,
        outboundDate: moment(journeySearch.outboundDate).format('YYYY-MM-DD'),
        inboundDate: journeySearch.inboundDate ? moment(journeySearch.inboundDate).format('YYYY-MM-DD') : null,
        passengers: journeySearch.passengers
    }, isBlank)
}

export const isAbsoluteUrl = url => /^https?:\/\//i.test(url)

export const newOuibusSearchUrl = reInitiateSearch => {
    const newOuibusSearchUrl = process.env.NODE_ENV === 'development'
        ? `/${_t.getLocales()}/orientation/offer`
        : `${getUrl()}`

    return newOuibusSearchUrl + '?' + createQueryFromValues(reInitiateSearch ? getPreviousJourneySearchValues() : getJourneySearchValues())
}

export const goToOuibusSearchPage = () => {
    const ouibusSearchUrl = newOuibusSearchUrl()
    isAbsoluteUrl(ouibusSearchUrl)
        ? window.location.href = ouibusSearchUrl
        : window.reacthistory.push(ouibusSearchUrl)
}

