import React, {Component} from 'react'
import PropTypes from 'prop-types'
import _t from '../../translate'
import SeatSummaryPassenger from './seat-summary-passenger-container'
import TextLabel from '../../element/text-label'
import {withRouter} from 'react-router'

class SeatSummaryLeg extends Component {
    static propTypes = {
        busNumber: PropTypes.string.isRequired,
        direction: PropTypes.object.isRequired,
        legHasSeatSelection: PropTypes.bool.isRequired,
        passengers: PropTypes.array.isRequired,
        selectedSeats: PropTypes.array.isRequired,
        seatSelectorRoute: PropTypes.string,
        onClickRemove: PropTypes.func.isRequired,
        showPrice: PropTypes.bool,
        readOnly: PropTypes.bool,
        router: PropTypes.object
    }

    constructor (...args) {
        super(...args)
        this.handleClickEdit = this.handleClickEdit.bind(this)
    }

    handleClickEdit (passengerId) {
        const {direction, seatSelectorRoute} = this.props
        this.props.router.push(
            `/${_t.getLocales()}${seatSelectorRoute}?legId=${direction.leg && direction.leg.id}&passengerId=${passengerId}&direction=${direction.direction}`
        )
    }

    render () {
        const {direction, busNumber, legHasSeatSelection, readOnly} = this.props
        return (
            <div className='journey-section'>
                <div className='journey-section-title'>
                    <span className='text-label journey-section-coach'>
                        <TextLabel text={busNumber} />
                    </span>
                    <span className='text-label journey-section-origin-destination'>
                        <TextLabel
                            text={`${direction.leg.departure_station.name} > ${direction.leg.arrival_station.name} `}
                        />
                    </span>
                </div>
                {legHasSeatSelection || readOnly
                    ? this._renderPassengers()
                    : <span className='text-label seat-selection-unavailable'>
                        <TextLabel text={_t.getIntlMessage(`seat-selector.seat-unavailable`)} />
                    </span>
                }
            </div>
        )
    }

    _renderPassengers () {
        const {passengers, selectedSeats, direction: {leg}, showPrice, readOnly, onClickRemove} = this.props

        return (
            <div className='passengers'>
                <ul>
                    {passengers.map((passenger, index) => {
                        const selectedSeat = selectedSeats.find(selectedSeat => selectedSeat.leg_id === leg.id && selectedSeat.passenger_id === passenger.id)
                        return (
                            <SeatSummaryPassenger
                                key={passenger.id}
                                leg={leg}
                                index={index}
                                passenger={passenger}
                                selectedSeat={selectedSeat}
                                onClickEdit={this.handleClickEdit}
                                onClickRemove={onClickRemove}
                                showPrice={showPrice}
                                readOnly={readOnly}
                            />
                        )
                    })}
                </ul>
            </div>
        )
    }
}

export default withRouter(SeatSummaryLeg)
