import PropTypes from 'prop-types'
import React from 'react'
import get from 'lodash/get'
import _t from '../../../../translate'
import TabContent from '../../../../element/tab-content'
import TextLabel from '../../../../element/text-label'
import {
    PAYMENT_METHOD_FLOW_TYPE_PAYPAL,
    PAYMENT_METHOD_FLOW_TYPE_SOFORT
} from '../../../../constants'
import PaymentMethodsStore from '../../../../reflux/stores/payment-methods-store'
import PaymentTerms from '../payment-terms'

const ADYEN_FLOW_TYPES = [
    PAYMENT_METHOD_FLOW_TYPE_PAYPAL,
    PAYMENT_METHOD_FLOW_TYPE_SOFORT
]

const Redirect = ({code}) => {
    const flowType = get(PaymentMethodsStore.getPaymentMethodByCode(code), 'flow_type')
    const description = get(PaymentMethodsStore.getPaymentMethodByCode(code), 'description')
    return (
        <TabContent visible>
            <span className='text-label payment-option-description'>
                <div className='grid-row'>
                    {ADYEN_FLOW_TYPES.includes(flowType) ? (
                        <div className='grid-column--1-1'>
                            <div className='payment-method-logo'>
                                <span className={`logo ${flowType.toLowerCase()}`} />
                            </div>
                        </div>
                    ) : null}
                    <div className='grid-column--1-1 payment-method-redirect'>
                        <TextLabel text={_t.formatIntlMessage('payment.method-redirect', {method: description})} />
                    </div>
                </div>
                <PaymentTerms />
            </span>
        </TabContent>
    )
}
Redirect.propTypes = {
    code: PropTypes.string.isRequired
}

export default Redirect
