import Reflux from 'reflux'

export default Reflux.createStore({

    init () {
        this._resetData()
    },

    _resetData () {
        this.data = {'basket_seat_option': 'no'}
    },

    getInitialState () {
        return this.data
    },

    onShowSeatSelector (visible) {
        this.data = {'basket_seat_option': visible}
        this.trigger(this.data)
    }
})
