import PropTypes from 'prop-types'
import React from 'react'
import moment from 'moment-timezone'
import _ from 'lodash'
import classNames from 'classnames'
import _t from '../../../translate'
import {toUtcDateMoment} from '../../../misc/date'
import SegmentCollection from '../../../models/segment-collection'
import Reflux from 'reflux'
import JourneyDetailsStore from '../../../reflux/stores/journey-details-store'
import Icon from '../../../element/icon'
import Button from '../../../element/button'
import TextLabel from '../../../element/text-label'
import JourneySection from '../../journey-section-details/journey-section'
import actions from '../../../reflux/actions'
import {stationMapper} from '../../../models/selectors/api/v2/meta/stations'
import createReactClass from 'create-react-class'

export default createReactClass({
    displayName: 'TariffSegmentSummary',

    mixins: [
        Reflux.listenTo(JourneyDetailsStore, 'onJourneyDetails')
    ],

    propTypes: {
        direction: PropTypes.oneOf(['outbound', 'inbound']).isRequired,
        segments: PropTypes.instanceOf(SegmentCollection).isRequired
    },

    getInitialState () {
        return {
            collapsed: true,
            viaStations: []
        }
    },

    componentDidMount () {
        if (this.props.segments.journeySegments.length) {
            const services = this.props.segments.journeySegments
            services.map(service => {
                actions.getServices(
                    toUtcDateMoment(service.travel_date).format('YYYY-MM-DD'),
                    this.props.segments.departureStation._u_i_c_station_code,
                    this.props.segments.arrivalStation._u_i_c_station_code,
                    service.service_type.code,
                    service.service_name
                )
            })
        }
    },

    componentWillUnmount () {
        this.setState({collapsed: false})
    },

    toggleCollapsed () {
        this.setState({collapsed: !this.state.collapsed})
    },

    render () {
        return this.props.segments.journeySegments.length > 0
            ? (
                <div className={classNames('journey-specification', this.props.direction)}>
                    <div className='summary'>
                        <div className='grid-row'>
                            <div className='grid-column--1-2 grid-column--medium-1-1 pok'>
                                <span className='text-label summary-title'>
                                    <TextLabel
                                        text={_t.getIntlMessage(`booking-specification.${this.props.direction}`)} />
                                    {this.props.direction === 'outbound'
                                        ? <Icon
                                            className='fleche-right medium align-right'
                                            type='fleche-right' />
                                        : <Icon className='fleche-left medium align-right' type='fleche-left' />
                                    }
                                    {this._hasViaStations() ? (
                                        <Button
                                            type='button'
                                            className='button journey-specification-details clear'
                                            onClick={this.toggleCollapsed}
                                        >
                                            {this.state.collapsed
                                                ? <Icon className='chevron-down medium' type='chevron-down' />
                                                : <Icon className='chevron-up medium' type='chevron-up' />
                                            }
                                        </Button>
                                    ) : null}
                                </span>
                            </div>
                            <div className='grid-column--1-2 grid-column--medium-1-1'>
                                <span className={`text-label ${this.props.direction}-date`}>
                                    <TextLabel text={this.props.segments.travelDate} />
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className='schedule'>
                        <div className='journey-section-details'>
                            {this.props.segments.journeySegments.map(this._renderJourneySegment)}
                        </div>
                        {this.renderTravelTime(this.props.segments)}
                    </div>
                </div>
            ) : null
    },

    _renderJourneySegment (segment, index, segments) {
        let type = index === 0 ? 'origin' : 'other'
        let sections = []
        sections.push(
            <JourneySection
                hasOtherService={segments.some(segment => segment.service_type.code !== 'OUIBUS')}
                key={`${type}-${index}`}
                type={type}
                station={stationMapper(segment.departure_station)}
                serviceType={segment.service_type}
                serviceName={segment.service_name}
                time={this._getDepartureTime(segment)}
                travelTime={this._formatTravelDuration(this._getTravelDuration(segment))}
                showMapPin={false}
                showModalityLabel={!this.props.segments.isOuibusOnlySegmentCollection()}
                viaStations={this._getViaStations(
                    segment.service_name,
                    segment.departure_station,
                    segment.arrival_station
                )}
                collapsed={this.state.collapsed}
            />
        )

        const nextIndex = index + 1
        const nextSegment = nextIndex in segments ? segments[nextIndex] : null

        if (nextSegment) {
            sections.push(
                <JourneySection
                    key={`transfer-${index}`}
                    type='transfer'
                    station={stationMapper(segment.arrival_station)}
                    time={this._getArrivalTime(segment)}
                    travelTime={this._formatTransferDuration(this._getTransferDuration(segment, nextSegment))}
                    showMapPin={false}
                />
            )
        } else {
            sections.push(
                <JourneySection
                    hasOtherService
                    key='destination'
                    type='destination'
                    time={this._getArrivalTime(segment)}
                    station={stationMapper(segment.arrival_station)}
                    showMapPin={false}
                />
            )
        }

        return sections
    },

    onJourneyDetails (data) {
        if (data.services.length) {
            this.setState({services: data.services})
        }
    },

    _hasViaStations () {
        return this.props.segments.journeySegments.some(segment => {
            const viaStations = this._getViaStations(
                segment.service_name,
                segment.departure_station,
                segment.arrival_station
            )
            return viaStations && viaStations.length > 0
        })
    },

    _getViaStations (serviceName, departureStation, arrivalStation) {
        const service = this.state.services && this.state.services.find(service => service.name === serviceName)
        if (service) {
            const startStationIndex = service.via_stations.findIndex(station => station.name === departureStation.name)
            const endStationIndex = service.via_stations.findIndex(station => station.name === arrivalStation.name)
            return service.via_stations
                .slice(
                    (startStationIndex >= 0 ? startStationIndex + 1 : 0),
                    (endStationIndex >= 0 ? endStationIndex : service.via_stations.length)
                )
        } else {
            return null
        }
    },

    _getDepartureTime (segment) {
        const date = moment(segment['departure_date_time'])
        const timezone = segment['departure_station']['timezone']

        return moment.tz.zone(timezone) ? date.tz(timezone).format('LT') : date.format('LT')
    },

    _getArrivalTime (segment) {
        const date = moment(segment['arrival_date_time'])
        const timezone = segment['arrival_station']['timezone']

        return moment.tz.zone(timezone) ? date.tz(timezone).format('LT') : date.format('LT')
    },

    _getTravelDuration (segment) {
        const departureDate = moment(segment['departure_date_time'])
        const arrivalDate = moment(segment['arrival_date_time'])

        return moment.duration(arrivalDate.diff(departureDate))
    },

    _getTransferDuration (segment1, segment2) {
        const startDate = moment(segment1['arrival_date_time'])
        const endDate = moment(segment2['departure_date_time'])

        return moment.duration(endDate.diff(startDate))
    },

    _formatTravelDuration (duration) {
        return _t.formatIntlMessage('journey-section.travel-time', this._getDurationValues(duration))
    },

    _formatTransferDuration (duration) {
        return _t.formatIntlMessage('journey-section.transfer-time', this._getDurationValues(duration))
    },

    _getDurationValues (duration) {
        return {
            hours: Math.floor(duration.asHours()),
            minutes: duration.minutes(),
            hoursPadded: _.padStart(Math.floor(duration.asHours()), 2, '0'),
            minutesPadded: _.padStart(duration.minutes(), 2, '0')
        }
    },

    renderTravelTime (segments) {
        const travelDuration = segments.travelDuration

        return travelDuration
            ? (
                <div className='grid-row'>
                    <div className='grid-column--1-1'>
                        <div className='travel-time'>
                            <span
                                className='text-label travel-time-description'
                                title={_t.getIntlMessage('booking-specification.segment.duration.title')}>
                                <TextLabel text={_t.getIntlMessage('booking-specification.segment.duration.label')} />
                            </span>
                            <span className='text-label travel-time-value'>
                                <TextLabel
                                    text={_t.formatIntlMessage(
                                        'booking-specification.segment.duration.format',
                                        {
                                            hours: Math.floor(travelDuration.asHours()),
                                            minutes: travelDuration.minutes()
                                        }
                                    )} />
                            </span>
                        </div>
                    </div>
                </div>
            ) : null
    }
})
