import React, {Component} from 'react'
import PropTypes from 'prop-types'
import _t from '../../translate'

import Icon from '../../element/icon'
import TextLabel from '../../element/text-label'
import BookingSpecificationPanel from './panel'
import {isBlablabusDomain} from '../../misc/blablabus-helpers'

export default class PanelDeskTop extends Component {
    static propTypes = {
        afterSales: PropTypes.bool
    }

    static defaultProps = {
        afterSales: false
    }

    render () {
        return (
            <BookingSpecificationPanel afterSales={this.props.afterSales}>
                <div className='panel'>
                    <header>
                        <h1 className='text-label journey-title'>
                            {!isBlablabusDomain() && <Icon className='large align-left' type='recap' />}
                            <TextLabel text={_t.getIntlMessage('booking-specification.journey-title')} />
                        </h1>
                    </header>
                    {this.props.children}
                </div>
            </BookingSpecificationPanel>
        )
    }
}
