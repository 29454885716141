import PropTypes from 'prop-types'
import React, { Component } from 'react'
import BookingLayoutContainer from '../booking-layout-container'
import {restoreBookingCompose} from '../../components/restore/restore-booking'
import {restoreJourneySearchCompose} from '../../components/restore/restore-journey-search'
import compose from '../../misc/compose'
import InteractiveFlashMessage from '../../components/interactive-flash-message'
import BikeSelector from '../../components/bike-selector/bike-selector'

class BikeSelectorContainer extends Component {
    static propTypes = {
        location: PropTypes.object
    }

    render () {
        return (
            <BookingLayoutContainer name='bike-selection-container'>
                <InteractiveFlashMessage />
                <BikeSelector returnUrl='/booking/options' />
            </BookingLayoutContainer>
        )
    }
}

const container = compose(
    restoreJourneySearchCompose({withBlockingLoader: true}),
    restoreBookingCompose({withBlockingLoader: true})
)

export default container(BikeSelectorContainer)
