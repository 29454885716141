import React from 'react'
import PropTypes from 'prop-types'
import Reflux from 'reflux'
import device from '../../device'
import {withRouter} from 'react-router'
import BookingLayoutContainer from '../booking-layout-container'
import BookingDetailsCustomer from '../../components/booking-customer-details/details'
import ProgressNavigation from '../../components/booking-customer-details/progress-navigation'
import BookingStore from '../../reflux/stores/booking-store'
import cmsComponent from '../../components/cms/cms-component'
import HelpButton from '../../base/help-button'
import SalesChannelManager from '../../data/sales-channel-manager'
import restoreBooking from '../../components/restore/restore-booking'
import Message from '../../element/message'
import _t from '../../translate'
import Loader from '../../element/loader'
import createReactClass from 'create-react-class'

const message = {content: _t.getIntlMessage('passenger-feedback.passenger-is-required'), severity: 'warning'}

export default withRouter(restoreBooking(cmsComponent('passenger-under-sixteen', 'BOOKING-CONTACT', 'checkbox-newsletter-opt-out')(createReactClass({

    displayName: 'BookingCustomerDetailsLayout',

    propTypes: {
        router: PropTypes.object
    },

    mixins: [
        Reflux.connectFilter(BookingStore, 'booking', data => data.booking),
        Reflux.connectFilter(BookingStore, 'loading', data => data.loading && data.voucherAction === null)
    ],

    getInitialState () {
        return {
            completed: false,
            handleClick: () => {}
        }
    },

    render () {
        const previousButtonProps = {
            className: 'button previous default',
            icon: {
                type: 'chevron-left',
                className: 'xsmall align-left'
            },
            onClick: this.onPrev
        }

        const nextButtonProps = {
            id: 'booking-details-next',
            className: 'button next primary',
            disabled: this.hasNoRequiredPassenger(),
            icon: {
                type: 'chevron-right',
                className: 'xsmall align-right'
            }
        }

        return (
            this.state.loading
                ? <Loader blocking />
                : <BookingLayoutContainer name='booking-details' hideBookingNumber title='booking-customer-details'>
                    {this.hasNoRequiredPassenger() ? <Message message={message} /> : null}
                    {this.state.booking.booking_number
                        ? (
                            <BookingDetailsCustomer
                                clickButton={this.setClickHandler}
                                onComplete={this.onComplete}
                                booking={this.state.booking}
                            />
                        )
                        : null}
                    <ProgressNavigation
                        previousButtonProps={previousButtonProps}
                        completed={this.state.completed}
                        nextButtonProps={nextButtonProps}
                        handleClick={this.state.handleClick}
                    />
                    {device.isMobile() ? <HelpButton /> : null}
                </BookingLayoutContainer>
        )
    },

    hasNoRequiredPassenger () {
        return SalesChannelManager.requestPassenger && this.state.booking.passengers.some(passenger => passenger.last_name === '')
    },

    setClickHandler (callback) {
        this.setState({handleClick: callback})
    },

    onComplete (completed) {
        this.setState({completed: completed})
    },

    onPrev () {
        this.props.router.goBack()
    }

}))))
