import PropTypes from 'prop-types'
import React from 'react'
import Reflux from 'reflux'
import JourneyResultSetList from './journey-result-set/list'
import NoResults from './journey-result-set/no-results'
import OfferStore from '../reflux/stores/offer-store'
import cmsComponent from './cms/cms-component'
import createReactClass from 'create-react-class'

const cmsBlockNames = [
    'BOOKING-SS-FLEX-DESCRIPTION',
    'BOOKING-SS-FLEX-DESCRIPTION10',
    'BOOKING-SS-FLEX-MARKET',
    'BOOKING-SS-UNFLEX-MARKET',
    'BOOKING-SS-FLEX-POP',
    'BOOKING-STATIC-POP',
    'BOOKING-STATIC_2-POP',
    'PRODUCT-STATIC',
    'PRODUCT-STATIC_2',
    'PRODUCT-STATIC-DESCRIPTION',
    'PRODUCT-STATIC_2-DESCRIPTION'
]

export default Component => cmsComponent(...cmsBlockNames)(createReactClass({

    displayName: 'JourneyResultSet',

    propTypes: {
        travels: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string
        })),
        selectedBundles: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string,
            direction: PropTypes.string,
            routeId: PropTypes.string
        })),
        hasOffer: PropTypes.bool,
        onOptionClick: PropTypes.func.isRequired,
        onSearch: PropTypes.func
    },

    mixins: [
        Reflux.connectFilter(OfferStore, 'loading', data => data.loading)
    ],

    getDefaultProps () {
        return {
            onOptionClick: () => {
            },
            onSearch: () => {
            }
        }
    },

    _getListOptions () {
        if (this.props.travels.length > 0) {
            return this.props.travels.map(travel => {
                const selectedBundleForDirection = this.props.selectedBundles
                    .find(bundle => bundle.direction === travel.direction)
                return (
                    <JourneyResultSetList
                        key={travel.id}
                        travelId={travel.id}
                        selectedBundle={selectedBundleForDirection}
                        direction={travel.direction}
                        onOptionClick={this.props.onOptionClick}
                        onSearch={this.props.onSearch}
                    />
                )
            })
        } else if (this.props.hasOffer) {
            return <NoResults />
        }
        return null
    },

    render () {
        return <Component {...this.props} loading={this.state.loading}>{this._getListOptions()}</Component>
    }

}))
