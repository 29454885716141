import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from '../../translate'
import TextLabel from '../../element/text-label'

export default class SeatLegendProduct extends Component {
    static propTypes = {
        type: PropTypes.string.isRequired,
        price: PropTypes.number
    }

    render () {
        return (
            this.props.price
                ? <div className='seat-legend-product'>
                    <div className={`seat-legend-indicator seat-selection-${this.props.type}`} />
                    <span className='text-label seat-price'>
                        <TextLabel text={_t.formatCurrency(this.props.price)} />
                    </span>
                    <span className='text-label per-seat'>
                        <TextLabel text={_t.getIntlMessage('seat-selector.per-seat')} />
                    </span>
                </div>
                : null
        )
    }
}
