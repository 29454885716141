import PropTypes from 'prop-types'
import React, { Component } from 'react'
import SeatSelectorNavigation from './seat-selector-navigation'
import SeatLegend from './seat-legend'
import SeatLayout from './seat-layout'
import _t from '../../translate'
import FormattedHTMLMessage from '../../translate/formatted-html-message'
import device from '../../device'
import SectionPassengerSelector from './passenger-selector/section-passenger-selector'
import TextLabel from '../../element/text-label'
import {
    passengerFareSeatPlusSelector,
    passengerFareSeatSelector,
    totalSeatPriceSelector
} from '../../models/selectors/components/booking/seat-selection'
import {
    connectState,
    getState
} from '../../reflux/bridge/connect-state'
import BookingStore from '../../reflux/stores/booking-store'
import ProductsStore from '../../reflux/stores/products-store'

class SeatSelector extends Component {
    static propTypes = {
        totalPrice: PropTypes.number.isRequired,
        selectedSeats: PropTypes.array.isRequired,
        selectedPassenger: PropTypes.shape({
            id: PropTypes.string,
            type: PropTypes.string
        }).isRequired,
        selectedCarriage: PropTypes.object.isRequired,
        selected: PropTypes.shape({
            legId: PropTypes.string.isRequired,
            direction: PropTypes.string.isRequired,
            busNumber: PropTypes.number.isRequired
        }).isRequired,
        travels: PropTypes.object.isRequired,
        readOnly: PropTypes.bool,
        editOnly: PropTypes.bool,
        selectedSeatsCurrentLeg: PropTypes.any,
        onSelectPassenger: PropTypes.any,
        onSelectSeat: PropTypes.any,
        onRemoveSeatBySeat: PropTypes.any,
        onRemoveSeatByPassenger: PropTypes.any,
        collapsed: PropTypes.any,
        collapseSection: PropTypes.any,
        onClickNext: PropTypes.any,
        onClickPrevious: PropTypes.any,
        passengers: PropTypes.any,
        availableSections: PropTypes.array.isRequired,
        hasDeletedSeats: PropTypes.bool,
        passengerFaresSeat: PropTypes.array,
        passengerFaresSeatPlus: PropTypes.array
    }

    static defaultProps = {
        editOnly: false,
        passengerFaresSeat: [],
        passengerFaresSeatPlus: []
    }

    render () {
        const {
            passengerFaresSeat,
            passengerFaresSeatPlus,
            selectedCarriage,
            selected,
            selectedSeatsCurrentLeg,
            selectedPassenger,
            onSelectSeat,
            onRemoveSeatBySeat,
            readOnly,
            availableSections
        } = this.props

        return (
            <div className='seat-selector'>
                <div className='panel'>
                    <header>
                        <h2>{_t.getIntlMessage('seat-selector.header')}</h2>
                    </header>
                    {device.isMobile()
                        ? <div className='grid-row'>{this._renderPassengerSelector()}</div>
                        : null}
                    <div className='content'>
                        <span className='text-label current-selection-indicator'>
                            <FormattedHTMLMessage
                                message={_t.formatIntlMessage('seat-selector.current-selection-indicator', {
                                    passenger: this._getPassengerName(),
                                    direction: selected.direction,
                                    bus: selected.busNumber
                                })}
                            />
                        </span>
                        <span className='text-label seat-plus-info'>
                            <TextLabel text={_t.getIntlMessage('seat-selector.seat-plus-info')} />
                        </span>
                        <div className='grid-row'>
                            <div className='grid-column--1-1 grid-column--medium-1-2'>
                                <SeatLegend
                                    passengerFaresSeat={passengerFaresSeat}
                                    passengerFaresSeatPlus={passengerFaresSeatPlus}
                                />
                                <SeatLayout
                                    key={selectedCarriage && selectedCarriage.template}
                                    carriage={selectedCarriage}
                                    selected={selected}
                                    selectedSeats={selectedSeatsCurrentLeg}
                                    selectedPassenger={selectedPassenger}
                                    onSelectSeat={onSelectSeat}
                                    onRemoveSeat={onRemoveSeatBySeat}
                                    readOnly={readOnly}
                                    availableSections={availableSections}
                                    availableSeat={Boolean(passengerFaresSeat && passengerFaresSeat.length)}
                                    availableSeatPlus={Boolean(passengerFaresSeatPlus && passengerFaresSeatPlus.length)}
                                />
                            </div>
                            {!device.isMobile() ? this._renderPassengerSelector() : null}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    _getPassengerName () {
        const {selectedPassenger} = this.props
        if (selectedPassenger) {
            return selectedPassenger.first_name
        }

        return ''
    }

    _renderPassengerSelector () {
        return (
            <div className='grid-column--1-1 grid-column--medium-1-2'>
                <div className='passenger-selection'>
                    {Object.keys(this.props.travels).map(direction => (
                        <SectionPassengerSelector
                            key={direction}
                            direction={direction}
                            collapsed={this.props.collapsed}
                            collapseSection={this.props.collapseSection}
                            travel={this.props.travels[direction]}
                            onRemoveSeat={this.props.onRemoveSeatByPassenger}
                            onSelectPassenger={this.props.onSelectPassenger}
                            passengers={this.props.passengers}
                            selectedLegId={this.props.selected.legId}
                            selectedPassenger={this.props.selectedPassenger}
                            selectedSeats={this.props.selectedSeats}
                            availableSections={this.props.availableSections}
                            passengerFaresSeat={this.props.passengerFaresSeat}
                            passengerFaresSeatPlus={this.props.passengerFaresSeatPlus}
                            readOnly={this.props.readOnly}
                            editOnly={this.props.editOnly}
                        />
                    ))}
                </div>
                {!this.props.readOnly
                    ? <SeatSelectorNavigation
                        totalPrice={this.props.totalPrice}
                        previousButtonProps={{onClick: this.props.onClickPrevious}}
                        nextButtonEnabled={this.props.selectedSeats.some(seat => seat.provisional !== false && seat.cancelled !== true) || Boolean(this.props.hasDeletedSeats)}
                        nextButtonProps={{onClick: this.props.onClickNext}}
                    />
                    : null}
            </div>
        )
    }
}

const mapPropsToProps = ownProps => {
    const state = getState()
    const passengerFaresSeatPlus = passengerFareSeatPlusSelector(state)
    const passengerFaresSeat = passengerFareSeatSelector(state)
    const totalPrice = totalSeatPriceSelector(ownProps.selectedSeats)(state)

    return {
        passengerFaresSeat,
        passengerFaresSeatPlus,
        totalPrice
    }
}

export default connectState(mapPropsToProps, [BookingStore, ProductsStore])(SeatSelector)
