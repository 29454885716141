// Libraries
import PropTypes from 'prop-types'

import React from 'react'
import Reflux from 'reflux'
import {withRouter} from 'react-router'
import createReactClass from 'create-react-class'

// Components
import ProgressNavigation from '../../base/main/progress-navigation-container'

// Stores
import SelectBundleStore from '../../reflux/stores/select-bundle-store'
import OfferStore from '../../reflux/stores/offer-store'

const component = createReactClass({

    displayName: 'OrientationOfferProgressNavigation',

    mixins: [
        Reflux.listenTo(SelectBundleStore, 'bundleSelectionUpdated'),
        Reflux.listenTo(OfferStore, 'offerUpdated')
    ],

    propTypes: {
        nextButtonProps: PropTypes.object
    },

    getInitialState () {
        return {
            isReturnTrip: OfferStore.hasReturnDate(),
            selectedBundles: SelectBundleStore.getSelectedBundles()
        }
    },

    offerUpdated (data) {
        if (!data.loading && data.error.length === 0) {
            this.setState({isReturnTrip: OfferStore.hasReturnDate()})
        }
    },

    bundleSelectionUpdated (data) {
        this.setState({selectedBundles: data.bundles})
    },

    render () {
        return (
            <ProgressNavigation
                previousButtonEnabled={false}
                nextButtonProps={this.props.nextButtonProps}
                nextButtonEnabled={(this.state.isReturnTrip ? 2 : 1) === this.state.selectedBundles.length}
            />
        )
    }

})
export default withRouter(component)
