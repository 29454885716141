/* global window */

import _ from 'lodash'

export default function (config) {
    const bodyNode = document.getElementsByTagName('body')[0]

    this.config = _.merge({
        src: '',
        trailingRemoval: false,
        leadingRemoval: false
    }, config)

    this.scriptNode = null

    const removeScript = () => {
        if (this.scriptNode) {
            bodyNode.removeChild(this.scriptNode)
            this.scriptNode = null
        }
    }

    this.loadScript = () => {
        if (this.config.leadingRemoval) {
            removeScript()
        }

        return new Promise(resolve => {
            if (this.scriptNode) {
                resolve()
                return
            }

            const scriptTag = document.createElement('script')
            this.scriptNode = scriptTag

            scriptTag.onload = () => {
                resolve()
                if (this.config.trailingRemoval) {
                    _.defer(removeScript)
                }
            }

            scriptTag.async = 1
            scriptTag.src = this.config.src
            bodyNode.appendChild(scriptTag)
        })
    }
};
