// Libraries
import React, { Component } from 'react'
import CmsBlockContent from '../../components/cms/cms-block-content'

export default class AdPanel extends Component {
    render () {
        return (
            <div className='ad-panel'>
                <div className='panel'>
                    <div className='content'>
                        <CmsBlockContent name='ad-panel-1' />
                        <CmsBlockContent name='ad-panel-2' />
                        <CmsBlockContent name='ad-panel-3' />
                    </div>
                </div>
            </div>
        )
    }
}
