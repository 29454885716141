// Libraries
import React, {Component} from 'react'
import _ from 'lodash'
import _t from '../translate'
import classNames from 'classnames'
import {withRouter, Link} from 'react-router'
import PropTypes from 'prop-types'

// Components
import Icon from '../element/icon'
import TextLabel from '../element/text-label'

class ProgressBar extends Component {
    static propTypes = {
        progressRouteMap: PropTypes.arrayOf(PropTypes.shape({
            path: PropTypes.string.isRequired,
            externalUrl: PropTypes.string,
            enabled: PropTypes.bool.isRequired
        })).isRequired,
        router: PropTypes.object
    }

    render () {
        return (
            <nav className='progress-bar'>
                <ol>
                    {this.renderItems()}
                </ol>
            </nav>
        )
    }

    renderItems () {
        let elements = []
        let passed = true

        _.forEach(this.props.progressRouteMap, (route, index) => {
            const active = this.props.router.isActive(route.path)
            const subPathActive = route.subPaths && route.subPaths.some(subPath => this.props.router.isActive(subPath))
            if (active) {
                passed = false
                elements.push(this.renderActiveItem(route, index))
            } else if (subPathActive && route.enabled) {
                passed = false
                elements.push(this.renderPassedItem(route, index))
            } else if (passed && route.enabled) {
                elements.push(this.renderPassedItem(route, index))
            } else {
                elements.push(this.renderDisabledItem(route, index, passed))
            }
        })

        return elements
    }

    renderPassedItem (route, key) {
        const className = classNames(this.columnClass(), 'passed')
        return (
            <li className={className} key={key}>
                {this.renderLink(route)}
            </li>
        )
    }

    renderLink (route) {
        return route.externalUrl
            ? (
                <a href={route.externalUrl}>
                    <Icon type='tick' className='medium' />
                    {this.renderLabel(route.path)}
                </a>
            )
            : (
                <Link to={route.path}>
                    <Icon type='tick' className='medium' />
                    {this.renderLabel(route.path)}
                </Link>
            )
    }

    renderActiveItem (route, key) {
        const className = classNames(this.columnClass(), 'active')
        return (
            <li className={className} key={key}>
                {this.renderLabel(route.path)}
            </li>
        )
    }

    columnClass () {
        return `column-${this.props.progressRouteMap.length}`
    }

    renderDisabledItem (route, key, passed) {
        const className = classNames(this.columnClass(), {
            disabled: passed
        })

        let icon = passed ? <Icon type='tick' className='medium' /> : null

        return (
            <li aria-disabled='true' key={key} className={className}>
                {icon}
                {this.renderLabel(route.path)}
            </li>
        )
    }

    renderLabel (path) {
        const parsedRoute = path.replace(/\/(.*?)\//, '').replace(/\//g, '-')
        return (
            <span className='text-label step-title'>
                <TextLabel text={_t.getIntlMessage(`progress-bar.${parsedRoute}`)} />
            </span>
        )
    }
}

export default withRouter(ProgressBar)
