// Libraries
import React from 'react'
import {Route} from 'react-router'

import authCheck from '../../../mixins/auth'

// Layouts
import RebookingDetailsLayout from '../../../layouts/mys3/re-booking/details-container'

export default (
    <Route path='/:lang/mys3/re-booking/details'
           component={RebookingDetailsLayout}
           onEnter={(nextState, replaceState, callback) => authCheck(nextState, replaceState, callback)}
        />
)
