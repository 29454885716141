import PropTypes from 'prop-types'
import React from 'react'
import Reflux from 'reflux'
import isEmpty from 'lodash/isEmpty'
import actions from '../reflux/actions'
import VoucherStore from '../reflux/stores/voucher-store'
import BookingStore from '../reflux/stores/booking-store'
import BookingModel from '../models/booking-model'
import Storage from '../storage'
import AccessManager from '../data/access-manager'
import {trimString} from '../misc/helpers'
import {voucherResponseCodes} from '../misc/vouchers'
import createReactClass from 'create-react-class'

export const containVouchers = Component => createReactClass({

    displayName: 'Vouchers',

    mixins: [
        Reflux.listenTo(BookingStore, 'onUpdateBooking')
    ],

    propTypes: {
        readOnly: PropTypes.bool
    },

    getDefaultProps () {
        return {readOnly: false}
    },

    getInitialState () {
        return {
            ...this._getStateFromBookingStore(),
            removedVoucherCode: null,
            addedVoucherCode: null
        }
    },

    componentWillMount () {
        if (Storage.has('voucherCode')) {
            this._addVoucher(Storage.get('voucherCode'), () => Storage.clear('voucherCode'))
        }
    },

    onUpdateBooking () {
        this.setState(this._getStateFromBookingStore())
    },

    _getStateFromBookingStore () {
        return {
            booking: this._getBooking(),
            voucherAction: BookingStore.getVoucherAction(),
            messages: BookingStore.getMessages().filter(message => voucherResponseCodes.includes(message.code)),
            loading: BookingStore.isLoading(),
            disabled: BookingStore.isPaid() && !AccessManager.isCrmUser()
        }
    },

    _getBooking () {
        return BookingModel.create(BookingStore.getBooking())
    },

    render () {
        if (!this.state.booking) {
            return null
        }

        return (
            <Component
                {...this.props}
                voucherAction={this.state.voucherAction}
                onRemoveVoucher={this._removeVoucher}
                onAddVoucher={this._addVoucher}
                removedVoucherCode={this.state.removedVoucherCode}
                addedVoucherCode={this.state.addedVoucherCode}
                booking={this.state.booking}
                loading={this.state.loading}
                messages={this.state.messages}
                disabled={this.state.disabled}
            />
        )
    },

    _addVoucher (voucherCode, callback = () => {}) {
        if (VoucherStore.isVoucherValid(voucherCode)) {
            this.setState({addedVoucherCode: voucherCode, removedVoucherCode: null}, () => {
                actions.addVoucher({
                    booking_number: this.state.booking.bookingNumber,
                    voucher_code: trimString(voucherCode).substring(0, 30)
                })
                callback()
            })
        }
    },

    _removeVoucher (voucherCode) {
        if (!isEmpty(voucherCode)) {
            actions.removeVoucher({
                booking_number: this.state.booking.bookingNumber,
                voucher_code: voucherCode
            })
            this.setState({addedVoucherCode: null, removedVoucherCode: voucherCode})
        }
    }
})
