import _ from 'lodash'
import moment from 'moment-timezone'
import {
    SERVICE_TYPE_MODALITY_BUS,
    SERVICE_TYPE_MODALITY_TER,
    NON_OUIBUS_SERVICE_TYPE_NAMES,
    PRODUCT_CODE_PROMO, INSURANCE_PRODUCT_REGEX, PRODUCT_CODE_LUGGAGE, PRODUCT_CODE_BIKE
} from '../constants'
import SegmentModel from './segment-model'
import {hasModalityInItems} from '../misc/modality-helper'
import {createLegId} from '../misc/segment'
import {isSeatProductCode} from '../misc/helpers'

export default class SegmentCollection {
    constructor (data) {
        if (!_.isArray(data)) {
            throw Error('SegmentCollection should be instantiated with an array')
        }
        this._data = _(data)
    }

    /** @deprecated use model to get segment collection data */
    getRawData () {
        return this._data.value()
    }

    getData () {
        return this._data
    }

    filter (callback = () => true) {
        return new SegmentCollection(this._data.filter(callback).value())
    }

    withRequiredProducts () {
        return this.filter(segment => segment && segment.required_products && segment.required_products.length)
    }

    withAdditionalProducts () {
        return this.filter(segment => segment.additional_products.length)
    }

    withoutCancelled () {
        return this.filter(segment => _(segment.required_products).some(product => !product.cancelled))
    }

    withProvisionalNonCancelledProducts () {
        return this.filter(segment => _(segment.required_products).some(product => !product.cancelled && product.provisional))
    }

    hasProvisionalNonCancelledProducts () {
        return this.filter(segment => _(segment.required_products).some(product => !product.cancelled && product.provisional)).getData().size() > 0
    }

    get canBeRebooked () {
        let nonCancelledProducts = this.requiredProducts.filter(product => !product.cancelled)

        return nonCancelledProducts.length > 0 && nonCancelledProducts.every(product => product.can_be_rebooked)
    }

    get summaryGroupedByDate () {
        return this._data
            .map(segment => {
                let result = {
                    departureStation: segment.departure_station.name || segment.departure_station._u_i_c_station_code,
                    arrivalStation: segment.arrival_station.name || segment.arrival_station._u_i_c_station_code
                }
                if (!_.isEmpty(segment.booking_journey_segments) &&
                    segment.booking_journey_segments[0].departure_date_time &&
                    _.last(segment.booking_journey_segments).arrival_date_time
                ) {
                    result.departureDate = moment(segment.booking_journey_segments[0].departure_date_time).toDate()
                    result.arrivalDate = moment(_.last(segment.booking_journey_segments).arrival_date_time).toDate()
                } else {
                    result.validityStartDate = moment(segment.validity_start_date).toDate()
                }

                return result
            })
            .groupBy(segment => moment(segment.departureDate || segment.validityStartDate).format('YYYYMMDD'))
            .sortBy(segment => moment(segment.departureDate || segment.validityStartDate).format('YYYYMMDD'))
    }

    get journeySegments () {
        return this._data.reduce((bookingJourneySegments, segment) => {
            if (segment.booking_journey_segments) {
                const journeySegments = segment.booking_journey_segments.map(journeySegment => ({
                    ...journeySegment,
                    travel_date: segment.travel_date
                }))
                bookingJourneySegments.push(...journeySegments)
            }
            return bookingJourneySegments
        }, [])
    }

    get services () {
        return this.journeySegments.reduce((services, journeySegment) => {
            if (journeySegment.departure_station && journeySegment.arrival_station) {
                services.push({
                    serviceName: journeySegment.service_name,
                    fromStationUIC: journeySegment.departure_station._u_i_c_station_code,
                    toStationUIC: journeySegment.arrival_station._u_i_c_station_code,
                    travelDate: journeySegment.travel_date
                })
            }

            return services
        }, [])
    }

    get requiredProducts () {
        return this._data.reduce(
            (products, rawSegment) => {
                const segment = new SegmentModel(rawSegment)
                products.push(...segment.requiredProducts.map(product => ({...product, segment})))
                return products
            },
            []
        )
    }

    get additionalProducts () {
        return this._data.reduce((additionalProducts, segment) => {
            if (segment.additional_products) {
                additionalProducts.push(...segment.additional_products)
            }
            return additionalProducts
        }, [])
    }

    get products () {
        return _(this.requiredProducts).concat(this.additionalProducts).value()
    }

    get seats () {
        return _(this.products).map(product => product.seat).compact()
    }

    get hasSeats () {
        return this.seats.size() > 0
    }

    get fees () {
        return this._data.map('fees').flatten().value()
    }

    get first () {
        return this._data.first()
    }

    get hasSegments () {
        return this._data.size() > 0
    }

    get firstJourneySegment () {
        return _.first(this.journeySegments)
    }

    get last () {
        return this._data.last()
    }

    get length () {
        return this._data.size()
    }

    get lastJourneySegment () {
        return _.last(this.journeySegments)
    }

    get travelDate () {
        const timezone = _.get(this.firstJourneySegment, 'departure_station.timezone')

        return moment.tz.zone(timezone)
            ? moment(this.departureDateTime).tz(timezone).format('LL') : moment(this.departureDateTime).format('LL')
    }

    get departureStation () {
        return this._data.first().departure_station
    }

    get arrivalStation () {
        return this._data.last().arrival_station
    }

    get departureDateTime () {
        const departureDateTime = _.get(this.firstJourneySegment, 'departure_date_time')
        return departureDateTime ? moment(departureDateTime).toDate() : null
    }

    get arrivalDateTime () {
        const arrivalDateTime = _.get(this.lastJourneySegment, 'arrival_date_time')
        return arrivalDateTime ? moment(arrivalDateTime).toDate() : null
    }

    get travelDuration () {
        const departureDateTime = this.departureDateTime
        const arrivalDateTime = this.arrivalDateTime

        if (!departureDateTime || !arrivalDateTime) {
            return null
        }

        return moment.duration(moment(arrivalDateTime).diff(departureDateTime))
    }

    hasPromoTariff () {
        return this.requiredProducts.some(product => PRODUCT_CODE_PROMO === product.code)
    }

    hasSegmentsWithModality (modality, includeNames = [], excludeNames = []) {
        return hasModalityInItems(this.journeySegments, modality, includeNames, excludeNames)
    }

    isOuibusOnlySegmentCollection () {
        return !this.hasSegmentsWithModality(SERVICE_TYPE_MODALITY_TER) &&
            !this.hasSegmentsWithModality(SERVICE_TYPE_MODALITY_BUS, NON_OUIBUS_SERVICE_TYPE_NAMES)
    }

    get totalPrice () {
        return this.products.filter(product => !product.cancelled)
            .map(product => {
                const discountAmount = product.discounts.filter(discount => discount.type === 'V')
                    .reduce((sum, discount) => sum + discount.amount, 0)

                return product.price + discountAmount
            })
            .reduce((sum, price) => sum + price, 0)
    }

    get autoAssignedSeats () {
        return this.withRequiredProducts().getRawData().reduce(
            (seats, tariffSegment) => {
                const journeySegment = tariffSegment.booking_journey_segments && tariffSegment.booking_journey_segments[0]

                const newSeats = tariffSegment.required_products.reduce(
                    (_seats, requiredProduct) => {
                        const hasManuallySelectedSeat = this._data.some(
                            segment =>
                                segment.additional_products.length &&
                                segment.departure_station &&
                                segment.arrival_station &&
                                tariffSegment.departure_station &&
                                tariffSegment.arrival_station &&
                                segment.departure_station._u_i_c_station_code === tariffSegment.departure_station._u_i_c_station_code &&
                                segment.arrival_station._u_i_c_station_code === tariffSegment.arrival_station._u_i_c_station_code &&
                                segment.validity_start_date === tariffSegment.validity_start_date &&
                                segment.additional_products.some(product =>
                                    isSeatProductCode(product.code) &&
                                    product.passenger_id === requiredProduct.passenger_id &&
                                    product.provisional === false
                                )
                        )

                        if (!hasManuallySelectedSeat && requiredProduct.cancelled !== true) {
                            _seats.push({
                                direction: tariffSegment.direction,
                                carriage_number: requiredProduct.seat.carriage,
                                seat_number: requiredProduct.seat.number,
                                passenger_id: requiredProduct.passenger_id,
                                leg_id: createLegId(
                                    journeySegment.service_name,
                                    tariffSegment.travel_date,
                                    journeySegment.departure_station.short_code,
                                    journeySegment.arrival_station.short_code
                                )
                            })
                        }
                        return _seats
                    },
                    []
                )

                seats.push(...newSeats)
                return seats
            },
            []
        )
    }

    get selectedManualSelectedSeats () {
        return this.withRequiredProducts().getRawData().reduce(
            (seats, tariffSegment) => {
                const journeySegment = tariffSegment.booking_journey_segments &&
                    tariffSegment.booking_journey_segments[0]
                const tariffSegmentAdditionalProducts = this._data.find(
                    segment =>
                        segment.additional_products.length &&
                        segment.departure_station &&
                        segment.arrival_station &&
                        tariffSegment.departure_station &&
                        tariffSegment.arrival_station &&
                        segment.departure_station._u_i_c_station_code === tariffSegment.departure_station._u_i_c_station_code &&
                        segment.arrival_station._u_i_c_station_code === tariffSegment.arrival_station._u_i_c_station_code &&
                        segment.validity_start_date === tariffSegment.validity_start_date &&
                        segment.additional_products.some(product =>
                            isSeatProductCode(product.code) &&
                            product.cancelled !== true
                        )
                )

                if (tariffSegmentAdditionalProducts && journeySegment) {
                    const newSeats = tariffSegmentAdditionalProducts.additional_products.reduce(
                        (_seats, product) => {
                            if (isSeatProductCode(product.code)) {
                                const requiredProduct = tariffSegment.required_products.find(
                                    _product => _product.passenger_id === product.passenger_id
                                )

                                if (requiredProduct && requiredProduct.seat) {
                                    _seats.push({
                                        direction: tariffSegment.direction,
                                        cancelled: product.cancelled,
                                        provisional: product.provisional,
                                        additionalProductItemRef: product.item_ref,
                                        carriage_number: requiredProduct.seat.carriage,
                                        seat_number: requiredProduct.seat.number,
                                        seat_product_code: product.code,
                                        passenger_id: requiredProduct.passenger_id,
                                        leg_id: createLegId(
                                            journeySegment.service_name,
                                            tariffSegment.travel_date,
                                            journeySegment.departure_station.short_code,
                                            journeySegment.arrival_station.short_code
                                        )
                                    })
                                }
                            }

                            return _seats
                        },
                        []
                    )

                    seats.push(...newSeats)
                }

                return seats
            },
            []
        )
    }

    get seatProducts () {
        return (this.additionalProducts || []).filter(product =>
            !product.cancelled &&
            isSeatProductCode(product.product_code || product.code)
        )
    }

    get insuranceProduct () {
        return this.additionalProducts.find(
            product => (
                !product.cancelled &&
                (product.product_code || product.code || '').match(INSURANCE_PRODUCT_REGEX)
            )
        )
    }

    get luggageProducts () {
        return (this.additionalProducts || []).filter(
            product => !product.cancelled && product.code === PRODUCT_CODE_LUGGAGE
        )
    }

    get hasProvisionalLuggageProducts () {
        return this.luggageProducts.some(({provisional}) => provisional)
    }

    get hasSummaryLuggageProducts () {
        return this.hasProvisionalNonCancelledProducts()
            ? this.hasProvisionalLuggageProducts // book or rebook flow
            : this.luggageProducts.some(({provisional, cancelled}) => !provisional && !cancelled) // after sales options
    }

    get bikeProducts () {
        return (this.additionalProducts || []).filter(
            product => !product.cancelled && product.code === PRODUCT_CODE_BIKE
        )
    }

    get hasProvisionalBikeProducts () {
        return this.bikeProducts.some(({provisional}) => provisional)
    }

    get hasSummaryBikeProducts () {
        return this.hasProvisionalNonCancelledProducts()
            ? this.hasProvisionalBikeProducts
            : this.bikeProducts.some(({provisional, cancelled}) => !provisional && !cancelled)
    }
}
