import React from 'react'
import PropTypes from 'prop-types'
import {withRouter} from 'react-router'
import Reflux from 'reflux'
import _ from 'lodash'
import _t from '../translate'
import AccessManager from '../data/access-manager'
import BookingModel from '../models/booking-model'
import actions from '../reflux/actions'
import BookingStore from '../reflux/stores/booking-store'
import GenericLayout from './generic-layout'
import MyS3CancellationLayoutContainer from './my-s3-cancellation-layout-container'
import Loader from '../element/loader'
import {CANCEL_REASON_MISTAKE, CANCEL_OPTION_ENTIRE_BOOKING} from '../constants'
import restoreBooking from '../components/restore/restore-booking'
import createReactClass from 'create-react-class'

export default withRouter(restoreBooking(createReactClass({
    displayName: 'MyS3CancellationLayout',

    propTypes: {
        router: PropTypes.object
    },

    mixins: [
        Reflux.listenTo(BookingStore, 'onBookingUpdate'),
        Reflux.connectFilter(BookingStore, 'loading', data => data.loading)
    ],

    getInitialState () {
        return {
            cancelItemRefs: [],
            cancelReason: '',
            selectedCancellationOption: '',
            booking: BookingStore.getBookingModel(),
            showConfirmDialog: false
        }
    },

    onBookingUpdate (data) {
        this.setState({
            booking: BookingModel.create(data.booking)
        })
    },

    onEntireBookingAction (selected) {
        const itemRefs = this.state.booking.products
            .filter('can_be_cancelled')
            .map('item_ref')
            .value()

        this.updatedCancelItemRefs(itemRefs, selected)
        this.setState({selectedCancellationOption: CANCEL_OPTION_ENTIRE_BOOKING})
    },

    onJourneyAction (direction, selected) {
        const products = direction === 'outbound'
            ? this.state.booking.outboundProducts : this.state.booking.inboundProducts

        const itemRefs = products
            .filter('can_be_cancelled')
            .map('item_ref')
            .value()

        this.updatedCancelItemRefs(itemRefs, selected)
    },

    onPassengerAction (passengerId, selected) {
        const itemRefs = this.state.booking.products
            .filter({can_be_cancelled: true, passenger_id: passengerId})
            .map('item_ref')
            .value()

        this.updatedCancelItemRefs(itemRefs, selected)
    },

    onSelectCancellationOption (selected) {
        this.setState({
            selectedCancellationOption: selected,
            cancelItemRefs: []
        })
    },

    updatedCancelItemRefs (itemRefs, selected) {
        let cancelItemRefs = _(this.state.cancelItemRefs)

        if (selected) {
            cancelItemRefs = cancelItemRefs.concat(itemRefs)
        } else {
            cancelItemRefs = cancelItemRefs.without(cancelItemRefs, ...itemRefs)
        }

        this.setState({cancelItemRefs: cancelItemRefs.uniq().value()})
    },

    handleProceed (event) {
        event && event.preventDefault()
        this.setState({showConfirmDialog: true})
    },

    handleCancel (event) {
        event && event.preventDefault()
        this.setState({showConfirmDialog: false})
    },

    handleConfirm (event) {
        event && event.preventDefault()
        actions.cancelItems(this.state.booking.bookingNumber, {
            cancelItemRefs: this.state.cancelItemRefs,
            cancellationReason: this.state.cancelReason
        }).then(() => {
            this.props.router.push(`/${_t.getLocales()}/mys3/cancellation/confirm`)
        })
    },

    onSelectCancelReason (cancelReason) {
        const newState = {cancelReason}
        if (this._isCancelReasonMistake(cancelReason)) {
            this.onEntireBookingAction(true)
        }
        this.setState(newState)
    },

    render () {
        if (this.state.booking === null) {
            return <GenericLayout><Loader blocking /></GenericLayout>
        }

        return (
            <MyS3CancellationLayoutContainer
                booking={this.state.booking}
                loading={this.state.loading}
                allowPartialCancellation={this._isPartialCancellationAllowed()}
                cancelItemIds={this.state.cancelItemRefs}
                showConfirmDialog={this.state.showConfirmDialog}
                onProceed={this.handleConfirm}
                onCancel={this.handleCancel}
                onConfirm={this.handleConfirm}
                onEntireBookingAction={this.onEntireBookingAction}
                onJourneyAction={this.onJourneyAction}
                onPassengerAction={this.onPassengerAction}
                onSelectCancelReason={this.onSelectCancelReason}
                cancelReason={this.state.cancelReason}
                disableCancelPerJourney={this._arePartialCancellationCheckboxesDisabled()}
                disableCancelPerPassenger={this._arePartialCancellationCheckboxesDisabled()}
                onSelectCancellationOption={this.onSelectCancellationOption}
                selectedCancellationOption={this.state.selectedCancellationOption}
                nextButtonEnabled={this._isNextButtonEnabled()}
            />
        )
    },

    _isNextButtonEnabled () {
        return _.without(
            this.state.cancelItemRefs,
            ...this.state.booking.getFeesPaid().map('item_ref')
        ).length > 0
    },

    _isCancelReasonMistake (cancelReason) {
        return cancelReason === CANCEL_REASON_MISTAKE
    },

    _isPartialCancellationAllowed () {
        return AccessManager.hasAccessToAfterSalesPartialCancellation()
    },

    _arePartialCancellationCheckboxesDisabled () {
        return !this._isPartialCancellationAllowed() || this._isCancelReasonMistake(this.state.cancelReason)
    }
}), {withBlockingLoader: true}))
