import {createSelector} from 'reselect'
import {additionalProductsSelector} from '../../../../models/selectors/api/v2/booking/products'
import {DISABILITY_PRODUCT} from '../../constants'
import {selectedBundlesSelector} from './journey-search'

export const selectedDisabilityProductsSelector = createSelector(
    [additionalProductsSelector],
    products => (products || []).filter(({type}) => type === DISABILITY_PRODUCT)
)

export const wheelchairProductsSelector = createSelector(
    [selectedBundlesSelector],
    bundles => bundles.reduce((products, bundle) => {
        const items = bundle.items
            .filter(item => item.productType === DISABILITY_PRODUCT)
            .map(item => ({...item, direction: bundle.direction}))
        products.push(...items)
        return products
    }, [])
)

export const hasAvailabilitySelector = createSelector(
    [wheelchairProductsSelector],
    products => products.some(product => product.passengerFares.some(fare => fare.logicalAvailability > 0))
)
