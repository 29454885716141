import React, {Component} from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import _t from '../../../translate'
import classNames from 'classnames'
import TextLabel from '../../../element/text-label'
import IconPayment from '../../../element/icon-payment'
import BookingSpecificationPanelVoucherItem from './voucher-item'

export default class AdditionalCosts extends Component {
    static propTypes = {
        payments: PropTypes.array,
        vouchers: PropTypes.array,
        className: PropTypes.string
    }

    constructor (...args) {
        super(...args)
        this._renderVoucherItem = this._renderVoucherItem.bind(this)
    }

    render () {
        if (_.isEmpty(this.props.payments)) {
            return null
        }

        const className = classNames('additional-costs', this.props.className)

        return (
            <div className={className}>
                {this.props.payments.filter(p => p.payment_status === 'S' ||
                    (p.payment_status !== 'F' && p.method === 'voucher')).map(
                    (payment, index) => this._renderPaymentItem(payment, index))}
            </div>
        )
    }

    _renderPaymentItem (payment, index) {
        return payment.method.toLowerCase() === 'voucher'
            ? this._renderVoucherItem(payment, index)
            : this._renderAdditionalItem(payment, index)
    }

    _renderVoucherItem (payment, index) {
        const voucher = _(this.props.vouchers).find({id: payment.voucher_id})

        const text = payment.description ||
          _t.safeFormatIntlMessage(`payment.method-names.${payment.method.toLowerCase()}`) ||
          _t.formatIntlMessage('payment.method-names.other')

        return (
            <BookingSpecificationPanelVoucherItem
                key={`payment_${index}`}
                label={text}
                price={voucher.amount}
            />
        )
    }

    _renderAdditionalItem (payment, index) {
        const classNameCostPrice = classNames(
            'text-label is-display-block text-align-right',
            payment.amount === 0 ? 'no-additional-costs' : 'additional-costs-price'
        )

        return (
            <div key={`payment_${index}`} className='additional-costs'>
                <div className='additional-costs-row'>
                    <div className='grid-row'>
                        <div className='grid-column--2-3'>
                            <span className='text-label'>
                                <IconPayment code={payment.method} className='small align-left' />
                                <TextLabel text={_t.formatIntlMessage(
                                    'payment.method-name',
                                    {method: payment.method.toLowerCase()}
                                )}
                                />
                            </span>
                        </div>
                        <div className='grid-column--1-3'>
                            <span className={classNameCostPrice}>
                                <TextLabel text={_t.formatCurrency(payment.amount)} />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
