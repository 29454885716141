import Reflux from 'reflux'
import actions from '../../reflux/actions'
import PassengerDetailsMixin from '../../reflux/mixin/passenger-details-mixin'
import UserAwareMixin from '../../reflux/mixin/user-aware-mixin'
import BookingStore from '../../reflux/stores/booking-store'

export default Reflux.createStore({

    listenables: actions,

    mixins: [PassengerDetailsMixin, UserAwareMixin],

    _initListenTo () {
        this.listenTo(BookingStore, data => {
            if (!data.loading && data.error.length === 0 && data.booking.booking_number !== null) {
                this._resetData()
                this.data.bookingNumber = data.booking.booking_number
                this._loadPassengers(data.booking)
            }
        })

        this.listenTo(actions.initiatePayment.completed, this.resetData)
        this.listenTo(actions.confirmBooking.completed, this.resetData)
    },

    onProcessPassengerDetailsData (newData) {
        this._processData(newData)
    }

})
