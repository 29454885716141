import {createSelector} from 'reselect'
import groupBy from 'lodash/groupBy'
import {productSearchSelector} from '../../../../../reflux/bridge/product-search'
import {
    indexByKey,
    isSeatProductCode
} from '../../../../../misc/helpers'

export const faresSelector = createSelector(
    [productSearchSelector],
    productSearch => {
        if (!productSearch || !productSearch.fares.length) {
            return null
        }
        return productSearch.fares
    }
)

export const itemsSelector = createSelector(
    [productSearchSelector],
    productSearch => {
        if (!productSearch || !productSearch.items.length) {
            return null
        }
        return productSearch.items
    }
)

const productSeatPassengerFaresSelector = createSelector(
    [itemsSelector, faresSelector],
    (items, fares) => {
        if (!items || !fares) {
            return null
        }

        const faresByKey = indexByKey(fares, 'id')
        return items
            .filter(item => isSeatProductCode(item.product_code))
            .reduce((seatPassengerFares, item) => {
                if (isSeatProductCode(item.product_code) && item.fare_id in faresByKey) {
                    const passengerFares = faresByKey[item.fare_id].passenger_fares || []
                    seatPassengerFares.push(...passengerFares.map(fare => ({
                        ...fare,
                        segment_id: item.segment_ids[0]
                    })))
                }

                return seatPassengerFares
            }, [])
    }
)

const getLowestPrice = fares => fares.reduce(
    (min, fare) => fare.price < min ? fare.price : min,
    fares[0].price
)

export const lowestSeatPriceSelector = createSelector(
    [productSeatPassengerFaresSelector],
    seatFares => seatFares && seatFares.length ? getLowestPrice(seatFares) : null
)

export const lowestSeatPricePerSegmentSelector = createSelector(
    [productSeatPassengerFaresSelector],
    seatFares => {
        if (seatFares && seatFares.length) {
            const faresPerSegment = groupBy(seatFares, 'segment_id')

            return Object.keys(faresPerSegment).reduce((pricePerSegment, segmentId) => {
                pricePerSegment[segmentId] = getLowestPrice(faresPerSegment[segmentId])

                return pricePerSegment
            }, {})
        }

        return null
    }
)
