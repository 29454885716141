import PropTypes from 'prop-types'
import React, {Component} from 'react'
import ReactDOM from 'react-dom'
import DatePicker from '../../../../element/jquery/date-picker'
import _t from '../../../../translate'

export default class OutboundDate extends Component {
    constructor (...args) {
        super(...args)
        this.onCheckBoxClicked = this.onCheckBoxClicked.bind(this)
        this.state = {
            disabled: !(this.props.date instanceof Date)
        }
    }

    static propTypes = {
        date: PropTypes.instanceOf(Date),
        maxDate: PropTypes.instanceOf(Date),
        enabled: PropTypes.bool.isRequired,
        onChange: PropTypes.func.isRequired,
        dateFormat: PropTypes.string
    }

    static defaultProps = {
        dateFormat: 'dd-mm-yy'
    }

    render () {
        let datePickerOptions = {minDate: 0, dateFormat: this.props.dateFormat}
        if (this.props.maxDate) {
            datePickerOptions.maxDate = this.props.maxDate
        }

        return (
            <div className='outbound-date'>
                <div className='check-radio-wrapper'>
                    <input
                        type='checkbox'
                        id='rebooking-outbound-date-change'
                        name='rebooking-outbound-date-change'
                        defaultChecked={this.props.date instanceof Date}
                        disabled={!this.props.enabled}
                        onClick={this.onCheckBoxClicked}
                    />
                    <label htmlFor='rebooking-outbound-date-change' className='text-label'>
                        {_t.getIntlMessage('my-s3-re-booking.re-booking-journey-search.outbound-date.label')}
                    </label>
                </div>
                <DatePicker
                    value={this.props.date}
                    id='rebooking-outbound-date'
                    name='rebooking-outbound-date'
                    className='rebooking-outbound-date'
                    placeholder={_t.getIntlMessage('my-s3-re-booking.re-booking-journey-search.outbound-date.placeholder')}
                    autoComplete='off'
                    required
                    readOnly
                    appendToClassName='journey-search-outbound-datepicker'
                    datePickerOptions={datePickerOptions}
                    disabled={!this.props.enabled || this.state.disabled}
                    onChange={this.props.onChange}
                />
            </div>
        )
    }

    onCheckBoxClicked (event) {
        this.setState(
            {disabled: !ReactDOM.findDOMNode(event.target).checked},
            () => this.state.disabled && this.props.onChange(null)
        )
    }
}
