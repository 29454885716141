import Reflux from 'reflux'
import GoogleTagManagerClient from '../../misc/google-tag-manager-client'
import actions from '../actions'

let previousPathname = ''
let bookingComfirmed = false

export default Reflux.createStore({

    init () {
        this.listenTo(actions.updateGTMTags, 'onUpdateTags')
    },

    _getPageName (location) {
        return location ? location.pathname.substr(location.pathname.lastIndexOf('/') + 1) : ''
    },

    onUpdateTags (currentTags) {
        const gtmTags = {}

        if ((this._getPageName(window.location) === 'passenger-details' || this._getPageName(window.location) === 'confirmation') &&
            (previousPathname !== window.location.pathname || bookingComfirmed !== currentTags.trans_booking_confirmed)) {
            gtmTags.name = `${currentTags.trans_departure_station_full}_${currentTags.trans_return_station_full}`
            gtmTags.id = `${currentTags.trans_service_name}-${currentTags.trans_departure_station}-${currentTags.trans_return_station}`
            gtmTags.ProductBrand = currentTags.trans_outbound_service_type
            gtmTags.route_type = currentTags.trans_route_type
            gtmTags.promo = currentTags.trans_price_class_outbound === 'promo' || currentTags.trans_price_class_inbound === 'promo' ? 'promo' : 'standard'
            gtmTags.is_return = currentTags.trans_is_return_journey
            gtmTags.totalPrice = currentTags.trans_total_with_VAT
            gtmTags.nb_seats = currentTags.trans_passenger
            gtmTags.travel_startdate = currentTags.travel_startdate
            gtmTags.travel_enddate = currentTags.travel_enddate
            gtmTags.origin_city = currentTags.trans_departure_city
            gtmTags.destination_city = currentTags.trans_return_city
            gtmTags.origin_city_id = currentTags.trans_departure_station
            gtmTags.destination_city_id = currentTags.trans_return_station
            gtmTags.origin_country = currentTags.trans_departure_country_code
            gtmTags.destination_country = currentTags.trans_return_country_code

            if (this._getPageName(window.location) === 'passenger-details') {
                gtmTags.event = 'BookStep1'
                gtmTags.ticketPrice = currentTags.trans_total_with_VAT / currentTags.trans_total_passengers
                gtmTags.basket_days_to_departure = currentTags.trans_days_to_departure
                GoogleTagManagerClient.pushVars(gtmTags)
            }

            if (this._getPageName(window.location) === 'confirmation' && currentTags.trans_booking_confirmed) {
                gtmTags.event = 'BookSuccess'
                gtmTags.bookingId = currentTags.trans_booking_number
                bookingComfirmed = currentTags.trans_booking_confirmed
                const outboundProducts = (currentTags.trans_outbound_products || []).map(product => ({
                    name: `${currentTags.trans_departure_city}_${currentTags.trans_return_city}`,
                    id: `${currentTags.trans_departure_station}_${currentTags.trans_return_station}`,
                    price: product.price,
                    brand: currentTags.trans_outbound_service_type,
                    category: 'bus',
                    quantity: '1',
                    coupon: currentTags.trans_price_class_outbound === 'promo' ? 'promo' : ''
                }))

                const inboundProducts = (currentTags.trans_inbound_products || []).map(product => ({
                    name: `${currentTags.trans_return_city}_${currentTags.trans_departure_city}`,
                    id: `${currentTags.trans_return_station}_${currentTags.trans_departure_station}`,
                    price: product.price,
                    brand: currentTags.trans_inbound_service_type,
                    category: 'bus',
                    quantity: '1',
                    coupon: currentTags.trans_price_class_inbound === 'promo' ? 'promo' : ''
                }))
                gtmTags.ecommerce = {
                    currencyCode: currentTags.trans_currency,
                    purchase: {
                        actionField: {
                            list: currentTags.trans_route_type,
                            revenue: currentTags.trans_total_with_VAT,
                            shipping: '',
                            coupon: currentTags.trans_price_class_outbound === 'promo' || currentTags.trans_price_class_inbound === 'promo' ? 'promo' : ''
                        },
                        products: outboundProducts.concat(inboundProducts)
                    }
                }
                GoogleTagManagerClient.pushVars(gtmTags)
            }
            previousPathname = window.location.pathname
        }
    },

    onStartPassengerDetails () {
        this.data = {'event': 'startPassengerDetails'}
        GoogleTagManagerClient.pushVars(this.data)
    },

    onCompletePassengerDetails () {
        this.data = {'event': 'completePassengerDetails'}
        GoogleTagManagerClient.pushVars(this.data)
    },

    onProcessDeepLink (data) {
        GoogleTagManagerClient.pushVars(data)
    },

    onBookingFailure (error) {
        this.data = {'event': 'BookFailure', 'error': error}
        GoogleTagManagerClient.pushVars(this.data)
    }
})
