import PropTypes from 'prop-types'
import React from 'react'
import {withRouter} from 'react-router'
import Reflux from 'reflux'
import _ from 'lodash'
import _t from '../translate'
import AccessManager from '../data/access-manager'
import Icon from '../element/icon'
import TextLabel from '../element/text-label'
import ConfirmDialog from '../element/confirm-dialog'
import BookingStore from '../reflux/stores/booking-store'
import actions from '../reflux/actions'
import BookingModel from '../models/booking-model'
import createReactClass from 'create-react-class'

export default withRouter(createReactClass({
    displayName: 'AfterSalesNavigation',

    mixins: [
        Reflux.connectFilter(BookingStore, 'booking', data => BookingModel.create(data.booking))
    ],

    propTypes: {
        router: PropTypes.object,
        location: PropTypes.object
    },

    getInitialState () {
        return {
            action: null,
            showConfirmDialog: false
        }
    },

    _onNavigationClick (action) {
        if (this.state.booking && !this._isOnOverview() && this.state.booking.provisionalProducts.value().length) {
            this.setState({
                action,
                showConfirmDialog: true
            })
        } else {
            action()
        }
    },

    onCancelButtonClick () {
        this.setState({
            action: null,
            showConfirmDialog: false
        })
    },

    onConfirmButtonClick () {
        this.state.action()
    },

    _shouldRevert () {
        return this._isOnNameChange() || this._isOnCancelItems() || this._isOnOptions()
    },

    async _onMyBooking () {
        if (this.state.booking) {
            actions.clearBooking()

            if (this._shouldRevert() || this.state.booking.provisionalProducts.value().length) {
                await actions.revertBooking(this.state.booking.bookingNumber)
            }
        }
        return this._redirectToMyS3Booking()
    },

    _redirectToMyS3Booking () {
        this.props.router.push(`/${_t.getLocales()}/mys3/booking/${this.state.booking.bookingNumber}`)
    },

    _onRebook () {
        this.props.router.push(`/${_t.getLocales()}/mys3/re-booking/offer`)
        actions.clearOffer()
        actions.getBooking(this.state.booking.bookingNumber)
    },

    _onCancel () {
        this.props.router.push(`/${_t.getLocales()}/mys3/cancellation`)
    },

    _onOptions () {
        this.props.router.push(`/${_t.getLocales()}/mys3/my-options`)
    },

    _onNameChange () {
        this.props.router.push(`/${_t.getLocales()}/mys3/update-passengers`)
    },

    _isOnNameChange () {
        return _.includes(this.props.location.pathname, '/mys3/update-passengers')
    },

    _isOnOptions () {
        return _.includes(this.props.location.pathname, '/mys3/my-options')
    },

    _isOnCancelItems () {
        return _.includes(this.props.location.pathname, '/mys3/cancellation')
    },

    render () {
        if (!this.state.booking) {
            return null
        }

        return (
            <div>
                <div className='row contains-after-sales-manage-my-booking'>
                    <div className='wrap'>
                        <div className='manage-my-booking'>
                            <span className='text-label manage-my-booking-title'>
                                <TextLabel text={_t.message('my-s3-booking.manage-my-booking')} />
                            </span>
                            <span className='text-label manage-my-booking-booking-number'>
                                <TextLabel text={this.state.booking.bookingNumber} />
                            </span>
                        </div>
                    </div>
                </div>
                <div className='row contains-after-sales-navigation'>
                    <div className='wrap'>
                        <div className='my-s3-after-sales-navigation'>
                            <nav>
                                <ul>
                                    {this._renderBooking()}
                                    {this._renderRebook()}
                                    {this._renderNameChange()}
                                    {this._renderViewSeats()}
                                    {this._renderCancel()}
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
                {this.state.showConfirmDialog ? this._renderConfirmDialog() : null}
            </div>
        )
    },

    _renderBooking () {
        const icon = 'ticket'
        const text = _t.message('my-s3-after-sales-navigation.booking-options.your-booking')
        return _.includes(this.props.location.pathname, '/mys3/booking')
            ? this._renderActive(icon, text)
            : this._renderInactive(icon, text, this._onMyBooking)
    },

    _renderRebook () {
        if (!this.state.booking.canBeRebooked || this.state.booking.provisional ||
            this.state.booking.isFullyCancelled() ||
            !AccessManager.hasAccessToAfterSalesRebooking()) {
            return null
        }

        const icon = 'rebook'
        const text = _t.message('my-s3-after-sales-navigation.booking-options.rebook')

        return _.includes(this.props.location.pathname, '/mys3/re-booking')
            ? this._renderActive(icon, text)
            : this._renderInactive(icon, text, this._onRebook)
    },

    _renderNameChange () {
        if (this.state.booking.provisional || !this.state.booking.canChangeName ||
            this.state.booking.isFullyCancelled()) {
            return null
        }

        const icon = 'edit'
        const text = _t.message('my-s3-after-sales-navigation.booking-options.change-passengers')

        return _.includes(this.props.location.pathname, '/mys3/update-passengers')
            ? this._renderActive(icon, text)
            : this._renderInactive(icon, text, this._onNameChange)
    },

    _renderViewSeats () {
        if (!this.state.booking.isConfirmedAndNotFullInThePast() ||
            this.state.booking.isFullyCancelled()
        ) {
            return null
        }

        const icon = 'system-preferences'
        const text = _t.message('my-s3-after-sales-navigation.booking-options.options')

        return _.includes(this.props.location.pathname, '/mys3/my-options')
            ? this._renderActive(icon, text)
            : this._renderInactive(icon, text, this._onOptions)
    },

    _renderCancel () {
        if (this.state.booking.provisional || !this.state.booking.canBeCancelled ||
            !AccessManager.salesChannelAllowsCancellation()
        ) {
            return null
        }

        const icon = 'cancel-ticket'
        const text = _t.message('my-s3-after-sales-navigation.booking-options.refund')

        return _.includes(this.props.location.pathname, '/mys3/cancellation')
            ? this._renderActive(icon, text)
            : this._renderInactive(icon, text, this._onCancel)
    },

    _isOnOverview () {
        return _.includes(this.props.location.pathname, `/mys3/booking/${this.state.booking.bookingNumber}`)
    },

    _renderActive (icon, text) {
        return (
            <li className='active'>
                <span className='text-label'>
                    <Icon type={icon} className='large align-left' />
                    <TextLabel text={text} />
                </span>
            </li>
        )
    },

    _renderInactive (icon, text, action) {
        return (
            <li>
                <a href='#' className='link' onClick={e => {
                    e.preventDefault()
                    this._onNavigationClick(action)
                }}>
                    <span className='text-label'>
                        <Icon type={icon} className='large align-left' />
                        <TextLabel text={text} />
                    </span>
                </a>
            </li>
        )
    },

    _renderConfirmDialog () {
        return (
            <ConfirmDialog
                onClose={this.onCancelButtonClick}
                onCancelButtonClick={this.onCancelButtonClick}
                onConfirmButtonClick={this.onConfirmButtonClick}
                labelPrefix='my-s3-after-sales-navigation.confirm-dialog'
            />
        )
    }
}))
