/* global window */

import ScriptLoader from './script-loader'
// eslint-disable-next-line import/extensions
import Config from '../data/google-tag-commander-config.json'

export const loaderBlablabaus = new ScriptLoader({
    src: `//www.googletagmanager.com/gtm.js?id=${Config.client.codeBlablabus}&l=gtmDataLayer`,
    leadingRemoval: false,
    trailingRemoval: false
})

export const loaderOuibus = new ScriptLoader({
    src: `//www.googletagmanager.com/gtm.js?id=${Config.client.codeOuibus}&l=gtmDataLayer`,
    leadingRemoval: false,
    trailingRemoval: false
})

export default {
    pushVars (vars) {
        if (vars) {
            window.gtmDataLayer ? window.gtmDataLayer.push(vars) : window.gtmDataLayer = [vars]
        }
        loaderBlablabaus.loadScript()
        loaderOuibus.loadScript()
    }
}
