import PropTypes from 'prop-types'
import React, { Component } from 'react'
import device from '../../../device'
import RebookingLayoutContainer from '../../re-booking-layout-container'
import BookingDetails from '../../../components/mys3/re-booking/details'
import ProgressNavigation from '../../../base/main/progress-navigation-container'
import Help from '../../../components/my-s3-booking/help'
import BookingModel from '../../../models/booking-model'

export default class Details extends Component {
    static propTypes = {
        booking: PropTypes.instanceOf(BookingModel).isRequired,
        onClickPrevious: PropTypes.func.isRequired,
        onClickNext: PropTypes.func.isRequired
    }

    render () {
        return (
            <RebookingLayoutContainer name='re-booking-details'>
                {this.props.booking ? <BookingDetails booking={this.props.booking} /> : null}
                <ProgressNavigation
                    previousButtonProps={{
                        onClick: this.props.onClickPrevious,
                        className: 'button previous default',
                        icon: {
                            type: 'chevron-left',
                            className: 'xsmall align-left'
                        }
                    }}
                    nextButtonProps={{
                        onClick: this.props.onClickNext,
                        className: 'button next primary',
                        icon: {
                            type: 'chevron-right',
                            className: 'xsmall align-right'
                        }
                    }}
                />
                {device.isMobile() ? <Help /> : null}
            </RebookingLayoutContainer>
        )
    }
}
