import React, {Component} from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import _t from '../../translate'
import BookingModel from '../../models/booking-model'
import TextLabel from '../../element/text-label'
import TriStateCheckboxField from '../../element/form/tri-state-checkbox-field'
import {CANCEL_OPTION_PASSENGER} from '../../constants'
import {sortPassengers} from '../../misc/helpers'

export default class CancelPerPassenger extends Component {
    static propTypes = {
        onAction: PropTypes.func.isRequired,
        cancelItemIds: PropTypes.arrayOf(PropTypes.number).isRequired,
        booking: PropTypes.instanceOf(BookingModel),
        disabled: PropTypes.bool,
        onSelectCancellationOption: PropTypes.func.isRequired,
        selectedCancellationOption: PropTypes.string
    }

    static defaultProps = {
        disabled: false
    }

    constructor (...args) {
        super(...args)
        this.renderPassenger = this.renderPassenger.bind(this)
        this.onChange = this.onChange.bind(this)
    }

    onChange () {
        if (this.props.selectedCancellationOption !== CANCEL_OPTION_PASSENGER) {
            this.props.onSelectCancellationOption(CANCEL_OPTION_PASSENGER)
        }
    }

    render () {
        const children = sortPassengers(this.props.booking.passengers.value()).map(this.renderPassenger)
        const selected = this.props.selectedCancellationOption === CANCEL_OPTION_PASSENGER

        return children.length > 0 ? (
            <div className='callout cancel-passenger'>
                <span className='text-label heading'>
                    <input
                        type='radio'
                        checked={selected}
                        onChange={this.onChange}
                        disabled={this.props.disabled}
                    />
                    <TextLabel text={_t.getIntlMessage('my-s3-cancellation.per-passenger.header')} />
                </span>
                {selected
                    ? <div className='grid-row'>
                        {children}
                    </div>
                    : null}
            </div>
        ) : null
    }

    renderPassenger (passenger) {
        const products = this.props.booking.products.filter({can_be_cancelled: true, passenger_id: passenger.id})

        if (products.size() < 1) {
            return null
        }

        let state = -1
        if (!products.find(product => _.indexOf(this.props.cancelItemIds, product.item_ref) < 0)) {
            state = 1
        }

        return (
            <div className='grid-column--1-1 grid-column--medium-1-2' key={passenger.id}>
                <div className='form-row'>
                    <div className='check-radio-wrapper'>
                        <TriStateCheckboxField
                            id={`cancel-passenger-${passenger.id}`}
                            state={state}
                            disabled={this.props.disabled}
                            onChange={e => this.props.onAction(passenger.id, e.target.checked)} />
                        <label htmlFor={`cancel-passenger-${passenger.id}`} className='text-label'>
                            <TextLabel text={`${passenger.first_name} ${passenger.last_name}`} />
                        </label>
                        <span className='text-label passenger-type'>
                            <TextLabel text={_t.message(`passenger-types.${passenger.passengerType.toLowerCase()}`)} />
                        </span>
                    </div>
                </div>
            </div>
        )
    }
}
