import PropTypes from 'prop-types'
import React, {Component} from 'react'
import _t from '../../translate'
import actions from '../../reflux/actions'
import Button from '../../element/button'
import TextLabel from '../../element/text-label'
import TextField from '../../element/form/text-field'
import EmailField from '../../element/form/email-field'
import SelectField from '../../element/form/select-field'
import {fieldPropsGenerator} from '../../forms/field-props'

const PropTypeField = PropTypes.shape({
    value: PropTypes.string,
    errorText: PropTypes.string,
    isRequired: PropTypes.bool.isRequired
}).isRequired

export default class Form extends Component {
    static propTypes = {
        onSubmit: PropTypes.func.isRequired,
        formData: PropTypes.shape({
            loading: PropTypes.bool,
            fields: PropTypes.shape({
                partnerOrganisation: PropTypeField,
                contactPerson: PropTypeField,
                contactEmailAddress: PropTypeField,
                contactPhoneNumber: PropTypeField,
                applicationDescription: PropTypeField

            }).isRequired
        })
    }

    _elements = {}

    render () {
        return this.props.formData ? (
            <form action='#' noValidate onSubmit={this.onSubmit}>
                <div className='grid-row'>
                    <div className='grid-column--1-1'>
                        <TextField className='first-name' {...this._textFieldProps('partnerOrganisation')} />
                    </div>
                </div>
                <div className='grid-row'>
                    <div className='grid-column--1-1'>
                        <TextField className='last-name' {...this._textFieldProps('contactPerson')} />
                    </div>
                </div>
                <div className='grid-row'>
                    <div className='grid-column--1-1'>
                        <EmailField className='email-address' {...this._textFieldProps('contactEmailAddress')} />
                    </div>
                </div>
                <div className='grid-row'>
                    <div className='grid-column--1-1'>
                        <TextField className='phone-number' {...this._textFieldProps('contactPhoneNumber')} />
                    </div>
                </div>
                <div className='grid-row'>
                    <div className='grid-column--1-1'>
                        <TextField className='last-name' {...this._textFieldProps('applicationDescription')} />
                    </div>
                </div>
                <div className='grid-row'>
                    <div className='grid-column--1-1'>
                        <SelectField className='last-name' {...this._selectFieldProps('grantCategory')} />
                    </div>
                </div>
                <div className='grid-row'>
                    <div className='grid-column--1-1'>
                        <Button
                            type='submit'
                            className='button primary'
                            onClick={this.onSubmit}
                            loading={this.props.formData.loading}
                        >
                            <TextLabel text={_t.getIntlMessage('request-json-api-key.button')} />
                        </Button>
                    </div>
                </div>
            </form>
        ) : null
    }

    _textFieldProps (fieldName) {
        return this._fieldProps(fieldName, {type: 'text'})
    }

    _selectFieldProps (fieldName) {
        return this._fieldProps(fieldName, {type: 'select'})
    }

    _fieldProps (fieldName, props) {
        return fieldPropsGenerator(
            {
                data: this.props.formData,
                ref: element => (this._elements[fieldName] = element),
                prefix: 'request-json-api-key',
                includeLabel: true,
                labelPosition: 'before',
                onBlur: this.onBlur,
                ...props
            },
            fieldName
        )
    }

    onBlur (event) {
        actions.processRegisterJsonApiKeyData([{
            id: event.target.id,
            value: event.target.value
        }])
    }

    onSubmit (e) {
        e.preventDefault()

        this.props.onSubmit(() => actions.processRegisterJsonApiKeyData([
            {id: 'partnerOrganisation', value: this._elements['partnerOrganisation'].getValue()},
            {id: 'contactPerson', value: this._elements['contactPerson'].getValue()},
            {id: 'contactEmailAddress', value: this._elements['contactEmailAddress'].getValue()},
            {id: 'contactPhoneNumber', value: this._elements['contactPhoneNumber'].getValue()},
            {id: 'applicationDescription', value: this._elements['applicationDescription'].getValue()},
            {id: 'grantCategory', value: this._elements['grantCategory'].getValue()}
        ]))
    }
}
