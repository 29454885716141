import React, {Component} from 'react'
import PropTypes from 'prop-types'
import Icon from './icon'
import classNames from 'classnames'

export default class Button extends Component {
    static propTypes = {
        text: PropTypes.string,
        textClass: PropTypes.string,
        type: PropTypes.oneOf(['submit', 'button']),
        icon: PropTypes.shape({
            type: PropTypes.string.isRequired,
            position: PropTypes.oneOf(['left', 'right'])
        }),
        loaderIcon: PropTypes.shape({
            type: PropTypes.string.isRequired,
            className: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.array
            ])
        }),
        loading: PropTypes.bool,
        onClick: PropTypes.func,
        className: PropTypes.string,
        disabled: PropTypes.bool
    }

    static defaultProps = {
        type: 'submit',
        style: {},
        textClass: '',
        icon: {
            type: '',
            position: 'left'
        },
        loaderIcon: {
            type: 'button-loader',
            className: 'medium loader-animation'
        },
        loading: false
    }

    constructor (...args) {
        super(...args)
        this.state = {visible: true}
        this.onClick = this.onClick.bind(this)
        this.getButtonBody = this.getButtonBody.bind(this)
        this.hasChildren = this.hasChildren.bind(this)
        this.getButtonText = this.getButtonText.bind(this)
        this.addIcon = this.addIcon.bind(this)
    }

    onClick (e) {
        e.preventDefault()
        if (!this.props.loading && this.props.onClick) {
            this.props.onClick(e)
        }
    }

    render () {
        const {className, ...other} = this.props
        delete other.text
        delete other.icon
        delete other.loaderIcon
        delete other.onClick
        delete other.loading
        delete other.textClass

        const buttonClassName = classNames(
            className,
            {
                'state--is-loading': this.props.loading,
                'is-visibility-hidden': !this.state.visible,
                'disabled': this.props.disabled
            }
        )

        return <button className={buttonClassName} onClick={this.onClick} {...other}>{this.getButtonBody()}</button>
    }

    getButtonBody () {
        if (this.props.loading) {
            return <Icon key='loaderIcon' {...this.props.loaderIcon} />
        }

        let body = [this.hasChildren() ? this.props.children : this.getButtonText()]

        return this.addIcon(body)
    }

    hasChildren () {
        return React.Children.count(this.props.children) > 0
    }

    getButtonText () {
        return <span key='buttonText' className={`text ${this.props.textClass}`}>{this.props.text || ''}</span>
    }

    addIcon (body) {
        if (this.props.icon.type) {
            const {position, ...props} = this.props.icon
            const icon = <Icon key='buttonIcon' {...props} />

            if (position && position === 'right') {
                body.push(icon)
            } else {
                body.unshift(icon)
            }
        }

        return body
    }
}
