/* globals sessionStorage */

let sessionStorageExists

module.exports = {

    set (key, value) {
        if (typeof value === 'undefined' || value === null) {
            this.clear(key)
            return
        }

        if (this.sessionStorageExists()) {
            sessionStorage.setItem(key, JSON.stringify(value))
        }
    },

    clear (key) {
        if (this.sessionStorageExists()) {
            sessionStorage.removeItem(key)
        }
    },

    has (key) {
        return this.sessionStorageExists() && sessionStorage.getItem(key) !== null
    },

    get (key, defaultValue) {
        if (this.sessionStorageExists()) {
            var value = sessionStorage.getItem(key)
            if (value !== null) {
                value = JSON.parse(value)
            }

            return value !== null ? value : defaultValue
        }
    },

    sessionStorageExists () {
        if (typeof sessionStorageExists !== 'boolean') {
            try {
                if (typeof sessionStorage !== 'object') {
                    sessionStorageExists = false
                } else {
                    // local storage exists, but can we write to it?
                    try {
                        sessionStorage.setItem('test', 'test')
                        sessionStorage.removeItem('test')
                        sessionStorageExists = true
                    } catch (error) {
                        sessionStorageExists = false
                    }
                }
            } catch (error) {
                sessionStorageExists = false
            }
        }

        return sessionStorageExists
    }

}
