import PropTypes from 'prop-types'
import React, {Component} from 'react'
import {withRouter} from 'react-router'
import _t from '../translate'
import Icon from '../element/icon'
import Button from '../element/button'
import TextLabel from '../element/text-label'
import AsyncDownloadButton from '../element/async-download-button'
import Footer from './booking-information/footer'
import BookingModel from '../models/booking-model'
import BookingInformation from './booking-information'
import actions from '../reflux/actions'
import cmsComponent from '../components/cms/cms-component'
import {CMS_BLOCK_AFTERSALES_VIEW_SEAT_NUMBER} from '../constants'

class BookingInformationContainer extends Component {
    static propTypes = {
        booking: PropTypes.instanceOf(BookingModel),
        router: PropTypes.object
    }

    constructor (...args) {
        super(...args)
        this._renderOptionsButton = this._renderOptionsButton.bind(this)
        this._renderDownloadInvoiceButton = this._renderDownloadInvoiceButton.bind(this)
        this._renderActionButtons = this._renderActionButtons.bind(this)
        this.onDownloadInvoice = this.onDownloadInvoice.bind(this)
        this.onGoToOptions = this.onGoToOptions.bind(this)
        this._renderDownloadTicketButton = this._renderDownloadTicketButton.bind(this)
        this.onResendTickets = this.onResendTickets.bind(this)
    }

    render () {
        return (
            <div className='detailed-specification'>
                <table className='responsive-table'>
                    <colgroup>
                        <col className='col-product' />
                        <col className='col-passenger-type' />
                        <col className='col-price' />
                    </colgroup>
                    <thead>
                        <tr>
                            <th scope='col'>{_t.message('booking-information.columns.product')}</th>
                            <th scope='col'>{_t.message('booking-information.columns.passenger')}</th>
                            <th scope='col'>{_t.message('booking-information.columns.price')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.children}
                    </tbody>
                    <Footer
                        totalPrice={this.props.booking.totalPrice}
                        totalVat={this.props.booking.totalVat}
                    />
                </table>
                {this._renderActionButtons()}
            </div>
        )
    }

    _renderActionButtons () {
        const booking = this.props.booking
        const isNotCancelled = !booking.isFullyCancelled()

        return !booking.provisional ? (
            <div>
                {isNotCancelled ? (
                    <div className='button-bar'>
                        {this._renderOptionsButton(booking)}
                    </div>
                ) : null}
                <div className='button-bar'>
                    {isNotCancelled ? this._renderDownloadTicketButton(booking) : null}
                    {this._renderDownloadInvoiceButton(booking)}
                </div>
            </div>
        ) : null
    }

    _renderOptionsButton (booking) {
        if (!booking.isConfirmedAndNotFullInThePast()) {
            return null
        }

        return (
            <Button type='button' className='button secondary manage-seats' onClick={this.onGoToOptions}>
                <TextLabel text={_t.message('my-s3-after-sales-navigation.booking-options.options')} />
            </Button>
        )
    }

    _renderDownloadInvoiceButton (booking) {
        const props = {
            downloadUrl: booking.invoiceUrl,
            onRetrieveDownLoadUrlClick: this.onDownloadInvoice,
            buttonProps: {
                className: 'button primary download-invoice'
            }
        }

        return (
            <AsyncDownloadButton {...props}>
                <Icon type='document-pdf' className='medium align-left' />
                <TextLabel text={_t.message('my-s3-booking.booking-options.download-invoice')} />
            </AsyncDownloadButton>
        )
    }

    onDownloadInvoice () {
        actions.myS3GetInvoiceUrl(this.props.booking.bookingNumber)
    }

    onGoToOptions () {
        this.props.router.push(`/${_t.getLocales()}/mys3/my-options`)
    }

    _renderDownloadTicketButton (booking) {
        const props = {
            downloadUrl: booking.ticketUrl,
            onRetrieveDownLoadUrlClick: this.onResendTickets,
            buttonProps: {
                className: 'button primary download-ticket'
            }
        }

        return (
            <AsyncDownloadButton {...props}>
                <Icon type='document-pdf' className='medium align-left' />
                <TextLabel text={_t.message('my-s3-booking.booking-options.download-ticket')} />
            </AsyncDownloadButton>
        )
    }

    async onResendTickets () {
        const booking = this.props.booking
        await actions.resendTickets({
            email: booking.customer.email,
            departure_date: booking.outboundTravelDate.toISOString().slice(0, 19) + 'Z',
            origin: booking.outboundOrigin._u_i_c_station_code,
            destination: booking.outboundDestination._u_i_c_station_code
        })

        actions.getBooking(booking.bookingNumber)
    }
}

export default cmsComponent(CMS_BLOCK_AFTERSALES_VIEW_SEAT_NUMBER)(BookingInformation(withRouter(BookingInformationContainer)))
